import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';

const TextView = React.forwardRef((props, ref) => (
    <TextField
    ref={ref}
    {...props}
    multiline={true}
    expandWithText={true} />
))
export default TextView;
