import Request from 'files/Request.js';

class BankAccountClass {

    id = null;
    name = null;
    routing = null;
    last4 = null;
    default = false;
    account_id = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.bank_name || props.name;
        this.routing = props.routing_number || props.routing;
        this.last4 = props.last4 || props.last_4;
        this.default = props.default_for_currency || props.default;
        this.account_id = props.account;
        return this;
    }

    summary = () => {
        return this.name + ' (' + this.last4 + ')';
    }

    type = () => {
        return this.name;
    }
    subType = () => {
        return 'Ending in (' + this.last4 + ')'
    }

    icon = () => {
        return 'fas fa-university';
    }

    setAsDefault = async (utils, client) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/saas/', {
                    type: 'set_default_bank_account',
                    app_id: client.client_id,
                    account_id: this.id
                });
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    remove = async (utils, client) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/saas/', {
                    type: 'remove_bank_account',
                    app_id: client.client_id,
                    account_id: this.id
                });
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }
}
export default {
    new: () => new BankAccountClass(),
    create: props => new BankAccountClass().create(props)
};
