import moment from 'moment-timezone';
import Company from 'classes/Company.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class SubscriptionClass {

    id = null;
    user_id = null;
    customer = null;
    plan = null;
    date = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user_id = props.user_id;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.date = props.date;
        this.plan = props.plan ? new SubscriptionPlanClass().create(props.plan) : null;
        this.active = props.active;
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            customer: this.customer,
            plan: this.plan,
            should_activate: true
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            customer: props.customer || this.edits.customer,
            plan: props.plan || this.edits.plan,
            should_activate: typeof(props.should_activate) === 'boolean' ? props.should_activate : this.edits.should_activate
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { active, date, id } = await Request.post(utils, '/subscription/', {
                    type: 'new',
                    user_id: edits.customer ? edits.customer.user_id : null,
                    plan_id: edits.plan ? edits.plan.id : null,
                    activate: edits.should_activate
                });

                this.id = id;
                this.user_id = edits.customer.user_id;
                this.customer = edits.customer;
                this.date = moment(date);
                this.active = active;

                utils.content.fetch('subscriptions');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class SubscriptionPlanClass {

    id = null;
    name = null;
    description = null;
    companies = null;
    cost = null;
    billing = null;
    options = null;
    rewards = null;
    active = null;
    image = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.description = props.description;
        this.companies = props.companies ? props.companies.map(company => Company.create(company)) : null;
        this.cost = props.cost;
        this.billing = props.billing;
        this.options = props.options;
        this.rewards = props.rewards;
        this.image = props.image;
        this.active = Boolean(props.active);
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            name: this.name,
            description: this.description,
            companies: this.companies,
            cost: this.cost,
            billing: this.billing,
            rewards: this.rewards,
            options: this.options || this.getDefaultOptions(),
            image: this.image
        }
        return this.edits;
    }

    set = props => {
        let prevOptions = (this.edits.options || []).find(option => option.default);
        this.edits = {
            name: props.name || this.edits.name,
            description: props.description || this.edits.description,
            companies: props.companies || this.edits.companies,
            cost: props.cost || this.edits.cost,
            billing: props.billing || this.edits.billing,
            options: props.options || this.edits.options,
            rewards: props.rewards || this.edits.rewards && {
                ...this.edits.rewards,
                ...props.rewards
            },
            image: this.edits.image,
            tmpImage: props.tmpImage ? props.tmpImage : this.edits.tmpImage
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id, image } = await Request.post(utils, '/subscription/', {
                    type: 'new_plan',
                    name: edits.name,
                    description: edits.description,
                    companies: edits.companies ? edits.companies.map(company => company.id) : null,
                    cost: edits.cost,
                    billing: edits.billing,
                    options: edits.options,
                    rewards: edits.rewards,
                    image: edits.tmpImage
                });

                this.id = id;
                this.name = edits.name;
                this.description = edits.description;
                this.companies = edits.companies;
                this.cost = edits.cost;
                this.billing = edits.billing;
                this.options = edits.options;
                this.rewards = edits.rewards;
                this.image = image;

                utils.content.fetch('subscriptionPlans');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { image } = await Request.post(utils, '/subscription/', {
                    type: 'update_plan',
                    id: this.id,
                    name: edits.name,
                    description: edits.description,
                    companies: edits.companies ? edits.companies.map(company => company.id) : null,
                    cost: edits.cost,
                    billing: edits.billing,
                    options: edits.options,
                    rewards: edits.rewards,
                    image: edits.tmpImage
                });

                this.name = edits.name;
                this.description = edits.description;
                this.companies = edits.companies;
                this.cost = edits.cost;
                this.billing = edits.billing;
                this.options = edits.options;
                this.rewards = edits.rewards;
                this.image = image || this.image;

                utils.content.update({
                    type: 'subscriptionPlans',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getDefaultOptions = () => {
        return [{
            default: true,
            discounts: {
                orders: 0,
                reservations: 0
            }
        }];
    }
}

const planTypes = {
    daily: 1,
    weekly: 2,
    biWeekly: 3,
    monthly: 4,
    quarterly: 5,
    yearly: 6
}

const getBillingText = (code, prefix = false) => {
    switch(code) {
        case planTypes.daily:
        return `${prefix ? 'per ' : ''}Day`;

        case planTypes.weekly:
        return `${prefix ? 'per ' : ''}Week`;

        case planTypes.biWeekly:
        return `${prefix ? 'every ' : ''}Two Weeks`;

        case planTypes.monthly:
        return `${prefix ? 'per ' : ''}Month`;

        case planTypes.quarterly:
        return `${prefix ? 'every ' : ''}Quarter`;

        case planTypes.yearly:
        return `${prefix ? 'per ' : ''}Year`;

        default:
        return 'Unknown billing type';
    }
}

const fetchPlan = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { plan } = await Request.get(utils, '/subscription/', {
                type: 'plan_details',
                id: id
            });
            let target = new SubscriptionPlanClass().create(plan);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new SubscriptionClass(),
    create: props => new SubscriptionClass().create(props),
    Plan: {
        new: () => new SubscriptionPlanClass(),
        get: fetchPlan,
        create: props => new SubscriptionPlanClass().create(props),
        getBillingText: getBillingText
    }
}
