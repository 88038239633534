import React, { useEffect, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import ImagePickerField from 'views/ImagePickerField.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Note from 'classes/Note.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

/*
This has been fixed. However, there should never be a scenario where there is not at least one bookable service. You see an error for route calculation when booking if you do not have at least one active service in the system.
*/
export const NoteEntry = ({ key, lastItem, note, onDelete, options, utils }) => {

    const { showDeleteButton } = options || {};

    const getContent = () => {
        if(!note) {
            return null;
        }
        return (
            <div
            key={key}
            style={{
                ...Appearance.styles.unstyledPanel(),
                flexGrow: 1,
                borderRadius: 10,
                overflow: 'hidden',
                marginBottom: lastItem === false ? 12 : 0,
            }}>
                {Views.entry({
                    title: note.title || 'Title Not Available',
                    subTitle: `Submitted by ${note.submitted_by ? note.submitted_by.full_name : 'an unknown account'}`,
                    icon: {
                        path: note.submitted_by ? note.submitted_by.avatar : 'images/star-icon-clear.png'
                    },
                    badge: {
                        text: Utils.formatDate(note.date),
                        color: Appearance.colors.primary()
                    },
                    bottomBorder: true,
                    onClick: note.submitted_by ? Utils.users.details.bind(this, utils, note.submitted_by) : null,
                    rightContent: showDeleteButton !== false && typeof(onDelete) === 'function' && (
                        <img
                        src='images/delete-button-small.png'
                        className={'image-button'}
                        onClick={onDelete}
                        style={{
                            width: 20,
                            height: 20
                        }} />
                    )
                })}
                <div style={{
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        display: 'block',
                        whiteSpace: 'normal',
                        color: Appearance.colors.text()
                    }}>{note.message}</span>
                </div>
                {note.attachment && (
                    <div
                    className={'text-button'}
                    onClick={() => {
                        window.open(note.attachment.url)
                    }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: 8,
                        backgroundColor: Appearance.colors.divider()
                    }}>
                        <i
                        className={'fas fa-image'}
                        style={{
                            fontSize: 16,
                            color: Appearance.colors.subText()
                        }}/>
                        <span style={{
                            ...Appearance.textStyles.supportingText(),
                            marginLeft: 8,
                            flexGrow: 1
                        }}>
                            {note.attachment.file_name}
                        </span>
                    </div>
                )}
            </div>
        )
    }
    return getContent();
}

export const NotesManager = ({ abstract, index, options, utils }) => {

    const layerID = `notes-manager-${abstract.getTag()}`;
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState(null);
    const [notes, setNotes] = useState([]);
    const [title, setTitle] = useState(null);

    const onAddNote = async () => {
        try {
            let { attachment, id } = await Request.post(utils, '/notes/', {
                type: 'new',
                title: title,
                message: message,
                image: image,
                object: {
                    type: abstract.type,
                    id: abstract.getID()
                }
            });

            let note = Note.create({
                id: id,
                title: title,
                message: message,
                date: moment().format('YYYY-MM-DD HH:mm:ss'),
                deleted: false,
                attachment: attachment,
                submitted_by: utils.user.get()
            });
            abstract.object.seeds = {
                ...abstract.object.seeds,
                notes: update(notes || [], {
                    $unshift: [note]
                }),
            }
            utils.content.update(abstract);

            setTitle('');
            setMessage('');
            setImage(null);
            setNotes(abstract.object.seeds.notes);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding your note. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteNote = note => {
        utils.alert.show({
            title: 'Delete Note',
            message: 'Are you sure that you want to delete this note? This can not be undone.',
            buttons: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'delete') {
                    onDeleteNoteConfirm(note);
                    return;
                }
            }
        })
    }

    const onDeleteNoteConfirm = async note => {
        try {
            await Request.post(utils, '/notes/', {
                type: 'delete',
                note_id: note.id,
                object: {
                    type: abstract.type,
                    id: abstract.getID()
                }
            });
            abstract.object.seeds.notes = update(notes || [], {
                $apply: notes => notes.filter(prev_note => prev_note.id !== note.id)
            });
            utils.content.update(abstract);
            setNotes(abstract.object.seeds.notes);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting your note. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setNotes(abstract.object.seeds && abstract.object.seeds.notes ? abstract.object.seeds.notes : [])
    }, [abstract.object.seeds]);

    console.log(utils);
    return (
        <Layer
        id={layerID}
        title={`Notes for ${abstract.getTitle()}`}
        utils={utils}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            removePadding: true
        }}>
            <div style={{
                padding: 12
            }}>
                <LayerItem
                title={'New Note'}
                childrenStyle={{
                    padding: 15
                }}>
                    <TextField
                    value={title}
                    autoCapitalize={'words'}
                    placeholder={'Title'}
                    onChange={text => setTitle(text)}
                    containerStyle={{
                        marginBottom: 8
                    }}/>
                    <TextView
                    value={message}
                    autoCapitalize={'sentences'}
                    placeholder={'What would you like to say?'}
                    onChange={text => setMessage(text)}
                    containerStyle={{
                        marginBottom: 8
                    }}/>
                    <ImagePickerField
                    value={image}
                    utils={utils}
                    onChange={image => setImage(image)}/>
                </LayerItem>

                <LayerItem
                title={'Past Notes'}
                shouldStyle={notes.length === 0}
                childrenStyle={{
                    padding: notes.length === 0 ? 15 : 0
                }}>
                    {notes.length === 0
                        ?
                        <span style={Appearance.textStyles.key()}>{`No notes have been left for "${abstract.getTitle()}"`}</span>
                        :
                        notes.map((note, index) => {
                            return (
                                <NoteEntry
                                key={index}
                                utils={utils}
                                note={note}
                                onDelete={onDeleteNote.bind(this, note)}
                                lastItem={index === notes.length - 1}/>
                            )
                        })
                    }
                </LayerItem>

                {typeof(title) === 'string' && typeof(message) === 'string' && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        marginTop: 15
                    }}>
                        <Button
                        type={'large'}
                        label={'Add Note'}
                        color={'dark'}
                        onClick={onAddNote}/>
                    </div>
                )}
            </div>
        </Layer>
    )
}
