import React, { useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import Views from 'views/Main.js';

export const SendPushNotification = ({ abstract, index, options, utils }) => {

    const [title, setTitle] = useState(null);
    const [message, setMessage] = useState(null);
    const [layerState, setLayerState] = useState(null);

    const onDiscardClick = () => {
        utils.alert.show({
            title: 'Discard Content',
            message: 'Are you sure that you want to discard your content?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Discard',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setTitle('');
                    setMessage('');
                }
            }
        });
    }

    const onSendPushClick = async () => {
        if(!title || !message) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please add a title and message for the notification to continue.'
            });
            return;
        }
        try {
            await Request.post(utils, '/notifications/', {
                type: 'send',
                user_id: abstract.getID(),
                title: title,
                message: message
            });
            utils.alert.show({
                title: 'Sent!',
                message: `Your notification has been sent to ${abstract.getTitle()}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending a notification to ${abstract.getTitle()}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getSubTitle = () => {
        if(!abstract.object.version || !abstract.object.version.platform || !abstract.object.version.version) {
            return 'Unknown App Version';
        }
        return `${abstract.object.version.platform} Version ${abstract.object.version.version}`;
    }

    return (
        <Layer
        id={`user-notification-${abstract.getID()}`}
        title={'New Push Notification'}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'discard',
            text: 'Discard',
            color: 'grey',
            onClick: onDiscardClick
        },{
            key: 'send',
            text: 'Send',
            color: 'primary',
            onClick: onSendPushClick
        }]}>
            <LayerItem title={'Sending To'}>
                {Views.entry({
                    title: abstract.getTitle(),
                    subTitle: getSubTitle(),
                    icon: {
                        path: abstract.object.avatar
                    },
                    bottomBorder: false
                })}
            </LayerItem>

            <LayerItem
            title={'Title'}
            shouldStyle={false}>
                <TextField
                value={title}
                onChange={text => setTitle(text)}/>
            </LayerItem>

            <LayerItem
            title={'Message'}
            lastItem={true}
            shouldStyle={false}>
                <TextView
                value={message}
                onChange={text => setMessage(text)}/>
            </LayerItem>
        </Layer>
    )
}
