import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import update from 'immutability-helper';
import Utils from 'files/Utils.js';
import Appearance from 'styles/Appearance';
import TextField from 'views/TextField.js';
import { VelocityComponent } from 'velocity-react';
import { MobileCalendar } from 'views/Calendar.js'

const Alert = ({ id, buttons, component, icon, index, message, onClick, onClose, textFields, title, twoFactor }) => {

    const alertRef = useRef(null);
    const [alertButtons, setAlertButtons] = useState([]);
    const [alertTextFields, setAlertTextFields] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [twoFactorText, setTwoFactorText] = useState(null);
    const [scale, setScale] = useState(0);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [visible, setVisible] = useState(false);

    const onButtonClick = key => {
        onHideAlert(key, () => {
            if(alertTextFields) {
                onParseTextFields(key);
                return;
            }
            if(twoFactor) {
                if(twoFactorText.length === 6) {
                    if(typeof(onClick) === 'function') {
                        onClick({ twoFactor: twoFactorText });
                    }
                }
                return;
            }
            if(typeof(onClick) === 'function') {
                onClick(key);
            }
        });
    }

    const onHandleKeyDown = evt => {

        // close alert if the escape key pressed
        if(evt.keyCode === 27) {
            evt.preventDefault();
            onHideAlert();
            return;
        }

        // select the primary action button if only one primary action button was provided
        if(evt.keyCode === 13) {
            evt.preventDefault();
            let buttons = alertButtons.filter(button => button.style === 'default');
            if(buttons.length === 1) {
                onButtonClick(buttons[0].key);
            }
        }
    }

    const onHideAlert = (key, callback) => {
        setScale(0);
        setOpacity(0);
        setTimeout(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose(id, key);
            }
            if(typeof(callback) === 'function') {
                callback();
            }
        }, 250);
    }

    const onParseTextFields = key => {
        if(typeof(onClose) === 'function') {
            onClose(id);
        }
        if(key !== 'cancel' && key !== 'dismiss') {
            if(onClick && typeof(onClick) === 'function') {
                let textFieldValues = {};
                alertTextFields.forEach(t => {
                    textFieldValues[t.field.key] = t.value;
                });
                textFieldValues.key = key;
                onClick(textFieldValues);
            }
        }
    }

    const onShowAlert = () => {
        let interval = setInterval(() => {
            if(!alertRef.current) {
                return;
            }
            clearInterval(interval);
            setScale(index > 3 ? 0 : 1 - (index / 4));
            setOpacity(index === 0 ? 1 : 0);
        }, 100);
    }

    const onWindowSizeChange = (e) => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    const setupButtons = () => {
        let targets = buttons || [];
        if(message && message.includes('authorization token')) {
            targets.push({
                key: 'renew_authorization_token',
                title: 'Login Again',
                style: 'default'
            });
        }
        if(!targets || targets.length === 0) {
            targets.push({
                key: 'dismiss',
                title: 'Dismiss',
                style: 'cancel'
            });
        }
        setAlertButtons(targets);
    }

    useEffect(() => {
        let fields = textFields && textFields.map(t => ({
            value: t.value,
            field: t
        }));
        setAlertTextFields(fields);
    }, [textFields]);

    useEffect(() => {
        setVisible(true);
        onShowAlert();
    }, [index]);

    useEffect(() => {
        if(alertTextFields) {
            document.addEventListener('keydown', onHandleKeyDown);
            return () => {
                document.removeEventListener('keydown', onHandleKeyDown);
            }
        }
    }, [alertTextFields]);

    useEffect(() => {
        setupButtons();
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, []);

    return visible ? (
        <div
        ref={alertRef}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <VelocityComponent
            easing={[250, 20]}
            duration={500}
            animation={{
                opacity: opacity,
                scale: scale
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.alert(),
                    overflow:'hidden',
                    maxHeight: size.height - 30,
                    width: size.width > 300 ? (component ? 400 : 300) : (size.width - 30),
                    alignItems: 'center',
                    textAlign: 'center',
                    zIndex: 9999
                }}>
                    {icon && icon.path && (
                        <div style={{
                            marginTop: 15,
                            borderRadius: 25,
                            backgroundColor: Appearance.colors.primary(),
                            overflow: 'hidden',
                            width: 50,
                            height: 50,
                            ...icon.style
                        }}>
                            <img
                            src={icon.path}
                            style={{
                                width: '100%',
                                height: '100%',
                                resizeMode: 'contain'
                            }}/>
                        </div>
                    )}
                    <span style={{
                        ...Appearance.textStyles.panelTitle(),
                        display: 'block',
                        paddingTop: icon ? 8 : 15,
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginBottom: 8
                    }}>{title}</span>

                    <div style={{
                        marginBottom: 15,
                        maxHeight: 500,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        maxWidth: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal',
                            display: 'block',
                            maxWidth: '100%',
                            paddingLeft: 15,
                            paddingRight: 15,
                            wordWrap: 'break-word'
                        }}>{message}</span>
                    </div>
                    {component}
                    {alertTextFields && (
                        <div style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            width: '100%',
                            marginBottom: 15
                        }}>
                            {alertTextFields.map((item, index) => {
                                return (
                                    <TextField
                                    key={index}
                                    focused={index === 0}
                                    value={item.value}
                                    useDelay={false}
                                    isSecure={item.field.secure}
                                    placeholder={item.field.placeholder}
                                    fieldStyle={{
                                        textAlign: 'center'
                                    }}
                                    onChange={text => {
                                        setAlertTextFields(alertTextFields => update(alertTextFields, {
                                            [index]: {
                                                value: {
                                                    $set: text
                                                }
                                            }
                                        }))
                                    }} />
                                )
                            })}
                        </div>
                    )}
                    {twoFactor && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            paddingLeft: 12,
                            paddingRight: 12,
                            marginBottom: 20
                        }}>
                            <TextField
                            placeholder={'Type your code here...'}
                            onChange={text => setTwoFactorText(text)}
                            containerStyle={{
                                width: '100%'
                            }}
                            fieldStyle={{
                                textAlign: 'center'
                            }}/>
                        </div>
                    )}
                    {alertButtons.map((item, index) => {
                        return (
                            <div
                            key={index}
                            className={`alert-item ${window.theme}`}
                            onClick={onButtonClick.bind(this, item.key)}
                            style={{
                                height: 50,
                                width: '100%'
                            }}>
                                <div style={{
                                    height: 50
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: 1,
                                        backgroundColor: Appearance.colors.divider()
                                    }}/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 50
                                    }}>
                                        <span style={{
                                            ...Appearance.textStyles.subTitle(),
                                            color: item.style === 'destructive' ? Appearance.colors.red : Appearance.colors.text(),
                                            textAlign:'center'
                                        }}>{item.title}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </VelocityComponent>
        </div>
    ) : null
}

export default Alert;
