import React, { useEffect, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import PromoCode from 'classes/PromoCode.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

const PromoCodeLookupField = ({ defaultPromoCode, utils, userID, onChange }) => {

    const [code, setCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState(null);
    const [valid, setValid] = useState(null);

    const onTextChange = text => {
        setValid(null);
        setCode(null);
        setText(text);
        if(typeof(onChange) === 'function') {
            onChange();
        }
    }

    const onVerify = async () => {
        if(!text) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please enter a promo code to continue'
            });
            return;
        }
        try {
            setLoading(true);
            let { message, promo_code, is_valid } = await Request.post(utils, '/promotion/', {
                type: 'verification',
                user_id: userID,
                code: text
            });

            setLoading(false);
            if(!is_valid) {
                setValid(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: message
                })
                return;
            }

            let code = PromoCode.create(promo_code);
            if(code.discount_type === PromoCode.types.credits) {
                setValid(false);
                if(typeof(onChange) === 'function') {
                    onChange(undefined);
                }
                utils.alert.show({
                    title: 'Oops!',
                    message: 'This promo code is not meant to apply a discount to a reservation'
                })
                return;
            }

            setValid(true);
            if(typeof(onChange) === 'function') {
                onChange(code);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue verifying your promo code. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setText(defaultPromoCode ? defaultPromoCode.code : null);
    }, [defaultPromoCode])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'left',
            alignItems: 'center',
            width: '100%'
        }}>
            <TextField
            value={text}
            loading={loading}
            onChange={onTextChange}
            autoComplete={false}
            autoCorrect={false}
            autoCapitalize={false}
            spellCheck={false}
            containerStyle={{
                flexGrow: 1
            }}/>
            {valid !== null
                ?
                <img
                src={valid === true ? 'images/checkmark-green.png' : 'images/rejected-red-small.png'}
                style={{
                    width: 25,
                    height: 25,
                    marginLeft: 8
                }} />
                :
                <img
                className={'text-button'}
                onClick={onVerify}
                src={'images/next-icon-clear-small.png'}
                style={{
                    width: 25,
                    height: 25,
                    minWidth: 25,
                    minHeight: 25,
                    borderRadius: 12.5,
                    overflow: 'hidden',
                    marginLeft: 8,
                    backgroundColor: Appearance.colors.primary()
                }} />
            }

        </div>
    )
}
export default PromoCodeLookupField;
