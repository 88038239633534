import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import HistoryEvent from 'classes/HistoryEvent.js';
import Lead from 'classes/Lead.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class DriveExperienceClass {

    active = null;
    created = null;
    date = null;
    id = null;
    primary = null;
    reservation = null;
    status = null;
    transportation = null;
    vehicle_category = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    close = () => {
        this.date = this.edits.date || this.date;
        this.primary = this.edits.primary || this.primary;
        this.reservation = this.edits.reservation !== undefined ? this.edits.reservation : this.reservation;
        this.transportation = this.edits.transportation !== undefined ? this.edits.transportation : this.transportation;
        this.vehicle_category = this.edits.vehicle_category || this.vehicle_category;
        this.edits = null;
    }

    create = props => {
        this.active = props.active;
        this.created = props.created && moment.utc(props.created).local();
        this.date = props.date && moment.utc(props.date).local();
        this.id = props.id;
        this.primary = props.primary;
        this.reservation = props.reservation;
        this.status = props.status;
        this.transportation = props.transportation;
        this.vehicle_category = props.vehicle_category;
        return this;
    }

    getHistoryEvents = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { events } = await Request.get(utils, '/drive_experiences/', {
                    id: this.id,
                    type: 'history_log'
                });
                resolve({
                    events: events.map(e => {
                        return HistoryEvent.create({ ...e, status: formatStatus(e.status) })
                    })
                });
            } catch(e) {
                reject(e);
            }
        });
    }

    open = props => {
        this.edits = {
            date: this.date,
            primary: this.primary,
            reservation: this.reservation,
            transportation: this.transportation,
            vehicle_category: this.vehicle_category,
            ...props
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            date: props.date || this.edits.date,
            primary: props.primary || this.edits.primary,
            reservation: props.reservation !== undefined ? props.reservation : this.edits.reservation,
            transportation: props.transportation !== undefined ? props.transportation : this.edits.transportation,
            vehicle_category: props.vehicle_category || this.edits.vehicle_category,
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { customer_user_id, id, reservation } = await Request.post(utils, '/drive_experiences/', {
                    ...this.toJSON(),
                    ...props,
                    type: 'new'
                });

                // set customer user id, experience id, primary type, and reservation
                // customer and reservation id can be null
                this.edits = {
                    ...this.edits,
                    customer_user_id: customer_user_id,
                    id: id,
                    primary: {
                        ...this.edits.primary,
                        type: customer_user_id ? 'customer' : 'lead'
                    },
                    reservation: reservation && {
                        ...reservation,
                        pickup_date: moment.utc(reservation.pickup_date).local()
                    }
                }

                // end editing and notify subscribers of data change
                this.close();
                utils.content.fetch('drive_experiences');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = () => ({
        date: moment(this.edits.date).utc().unix(),
        ...this.edits.primary.type === 'customer' && {
            customer_user_id: this.edits.primary.user_id
        },
        ...this.edits.primary.type === 'lead' && {
            lead: this.edits.primary
        },
        id: this.id,
        reservation: this.edits.transportation && this.edits.reservation && {
            id: this.edits.reservation.id,
            origin: this.edits.reservation.origin && {
                address: this.edits.reservation.origin.address,
                name: this.edits.reservation.origin.name,
                lat: this.edits.reservation.origin.location.latitude,
                long: this.edits.reservation.origin.location.longitude
            },
            special_requests: this.edits.reservation.special_requests
        },
        transportation: this.edits.transportation,
        vehicle_category_id: this.edits.vehicle_category.id
    })

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { customer_user_id, reservation } = await Request.post(utils, '/drive_experiences/', {
                    ...this.toJSON(),
                    ...props,
                    type: 'update'
                });

                // set customer user id, experience id, primary ty pe, and reservation 
                // customer and reservation id can be null
                this.edits = {
                    ...this.edits,
                    customer_user_id: customer_user_id,
                    primary: {
                        ...this.edits.primary,
                        type: customer_user_id ? 'customer' : 'lead'
                    },
                    reservation: reservation && {
                        ...reservation,
                        pickup_date: moment.utc(reservation.pickup_date).local()
                    }
                }

                // end editing and notify subscribers of data change
                this.close();
                utils.content.fetch('drive_experiences');
                utils.content.update({
                    object: this,
                    type: 'reservations'
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const statusCodes = {
    pending: null,
    rejected: 0,
    approved: 1,
    returned: 2,
    cancelled: 3,
    to_progress: 4,
    completed: 5,
    admin_updated: 6,
    driver_updated: 7,
    customer_edited: 8
}

const formatStatus = (c) => {

    let code = c === null || c === undefined ? null : parseInt(c);
    let status = {
        code: code
    };

    if(code === statusCodes.rejected) {
        status.text = 'Declined';
        status.realText = 'Declined';
        status.color = Appearance ? Appearance.colors.red : null;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance ? Appearance.colors.primary() : null;
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.returned) {
        status.text = 'Returned to Queue';
        status.realText = 'Returned to Queue';
        status.color = Appearance ? Appearance.colors.primary() : null;
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';
        status.image = 'images/status-cancelled.png';

    } else if(code === statusCodes.to_progress) {
        status.text = 'In Progress';
        status.realText = 'In Progress';
        status.color = Appearance ? Appearance.colors.blue : null;
        status.image = 'images/status-active.png';

    } else if(code === statusCodes.completed) {
        status.text = 'Completed';
        status.realText = 'Completed';
        status.color = Appearance ? Appearance.colors.darkGrey : null;
        status.image = 'images/status-completed.png';

    } else if(code === statusCodes.admin_updated) {
        status.text = 'Admin Updated';
        status.realText = 'Admin Updated';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.driver_updated) {
        status.text = 'Driver Updated';
        status.realText = 'Driver Updated';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.customer_edited) {
        status.text = 'Customer Edited';
        status.realText = 'Customer Edited';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';
    }

    return status;
}

export default {
    create: props => new DriveExperienceClass().create(props),
    formatStatus: formatStatus,
    new: () => new DriveExperienceClass(),
    status: statusCodes
}