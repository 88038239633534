import Appearance from 'styles/Appearance.js';
import EmailStatusCodes from 'files/EmailStatusCodes.js';
import Request from 'files/Request.js';

class EmailHistoryEventClass {

    id = null;
    title = null;
    type = null;
    date = null;
    user_id = null;
    reservation_id = null;
    email_address = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = this.getTitle(props.type);
        this.type = props.type;
        this.date = props.date;
        this.user_id = props.user_id;
        this.reservation_id = props.reservation_id;
        this.email_address = props.email_address;
        this.status = props.status ? formatStatus(props.status) : null;
        return this;
    }

    getTitle = type => {
        switch(type) {
            case 'custom_payment_receipt':
            return 'Custom Payment Receipt';

            case 'reservation_receipt':
            return 'Reservation Receipt';

            case 'reservation_refunded':
            return 'Reservation Refund';

            case 'reservation_confirmation':
            return 'Reservation Confirmation';

            case 'reservation_rejection':
            return 'Reservation Rejection';

            case 'reservation_cancelled':
            return 'Reservation Cancellation';

            case 'email_verification':
            return 'Password Reset Verification';

            case 'email_verification':
            return 'New User Email Verification';

            case 'new_user':
            return 'Welcome Email';

            default:
            return 'Unknown Email Type';
        }
    }
}

const statusCodes = {
    undetermined: -1,
    sent: 0,
    delivered: 1,
    opened: 2,
    clicked: 3,
    temporary_fail: 4,
    permanent_fail: 5,
    complained: 6,
    unsubscribed: 7
}

const formatStatus = status => {
    if(isNaN(status.code)) {
        return;
    }
    switch(status.code) {
        case statusCodes.sent:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.lightGrey
        }

        case statusCodes.delivered:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.green
        }

        case statusCodes.opened:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.blue
        }

        case statusCodes.clicked:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.blue
        }

        case statusCodes.temporaryFail:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.orange
        }

        case statusCodes.permanentFail:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.red
        }

        case statusCodes.complained:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.red
        }

        case statusCodes.unsubscribed:
        return {
            text: status.text,
            code: status.code,
            color: Appearance.colors.darkGrey
        }

        default:
        return {
            text: 'Unknown',
            code: status.code,
            color: Appearance.colors.lightGrey
        }
    }
}

export default {
    new: () => new EmailHistoryEventClass(),
    create: props => new EmailHistoryEventClass().create(props),
    formatStatus: formatStatus
};
