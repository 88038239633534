import React, { useEffect, useRef, useState } from 'react';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import PageControl from 'views/PageControl';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils, { useLoading } from 'files/Utils.js';
import Views from 'views/Main.js';

export const AddEditLead = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_lead' : `edit_lead_${abstract.getID()}`;

    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(null);
    const [leadTypes, setLeadTypes] = useState([]);
    const [loading, setLoading] = useLoading();
    const [statusCodes, setStatusCodes] = useState([]);

    const onSubmit = async () => {
        try {

            // start loading and validate required fields
            setLoading('submit');
            await validateRequiredFields(getFields);

            // submit request to create or update target
            await abstract.object.apply(utils, isNewTarget);

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The lead for ${abstract.object.first_name} ${abstract.object.last_name} has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setLead(edits);
    }

    const getButtons = () => {
        return [{
            color: 'grey',
            key: 'discard',
            onClick: utils.alert.discard.bind(this, setupEdits),
            text: 'Discard',
        },{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmit,
            text: 'Submit',
        }]
    }

    const getFields = () => {
        if(!lead) {
            return [];
        }
        return [{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                component: 'textfield',
                description: 'This should be the first name for the individual represented by this lead.',
                key: 'first_name',
                onChange: text => onUpdateTarget({ first_name: text }),
                title: 'First Name',
                value: lead.first_name
            },{
                component: 'textfield',
                description: 'This should be the last name for the individual represented by this lead.',
                key: 'last_name',
                onChange: text => onUpdateTarget({ last_name: text }),
                title: 'Last Name',
                value: lead.last_name
            },{
                component: 'textfield',
                description: 'This should be the email address for the individual represented by this lead. We use the email address to help you contact the lead when appropriate.',
                key: 'email_address',
                onChange: text => onUpdateTarget({ email_address: text }),
                title: 'Email Address',
                value: lead.email_address
            },{
                component: 'textfield',
                description: 'This should be the phone number for the individual represented by this lead. We use the email address to help you contact the lead when appropriate.',
                key: 'phone_number',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: { format: 'phone_number' },
                title: 'Phone Number',
                value: lead.phone_number
            },{
                component: 'address_lookup',
                description: 'This should be the mailing address for the individual represented by this lead. We use the mailing address to help you visualize the growth and coverage of your lead base.',
                key: 'address',
                onChange: result => {
                    onUpdateTarget({ 
                        address: result && result.address,
                        location: result && result.location
                    })
                },
                required: false,
                title: 'Mailing Address',
                value: lead.address
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'list',
                description: 'This will be a descriptor that is used to determine which type of lead was generated.',
                items: leadTypes,
                key: 'type',
                onChange: item => {
                    onUpdateTarget({ 
                        type: item && {
                            code: item.id,
                            text: item.title
                        } 
                    });
                },
                title: 'Type',
                value: lead.type && lead.type.text
            },{
                component: 'list',
                description: 'This status will be used to help categorize the lead and will quickly illustrate the state of the lead.',
                items: statusCodes,
                key: 'status',
                onChange: item => {
                    onUpdateTarget({ 
                        status: item && {
                            code: item.id,
                            text: item.title
                        } 
                    });
                },
                title: 'Status',
                value: lead.status && lead.status.text
            }]
        }];
    }

    const fetchLeadTypes = () => {
        // TODO => fetch list of lead types for client from database
        setLeadTypes([{
            id: 1,
            title: 'Drive Experience'
        },{
            id: 2,
            title: 'General'
        }]);
    }

    const fetchStatusCodes = () => {
        // TODO => fetch list of status code for client from database
        setStatusCodes(getStatusCodes());
    }

    const setupEdits = () => {
        let edits = abstract.object.open();
        setLead(edits);
    }

    useEffect(() => {
        setupEdits();
        fetchLeadTypes();
        fetchStatusCodes();
    }, [])

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Lead' : `Editing Lead for ${abstract.object.first_name} ${abstract.object.last_name}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const LeadsList = ({ index, options, utils }) => {
     
    const panelID = 'lead_list';
    const limit = 10;

    const offset = useRef(0);
    const status = useRef(null);

    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useLoading();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selected, setSelected] = useState([]);
    const [selectedOffset, setSelectedOffset] = useState(0);
    const [selectedPaging, setSelectedPaging] = useState(null);
    const [selecting, setSelecting] = useState(false);
    const [statusCodes, setStatusCodes] = useState([]);

    const onChangeSearchText = text => {
        setLoading(true);
        setSearchText(text);
    }

    const onClearLeads = () => {
        utils.alert.show({
            title: 'Clear Leads',
            message: 'Are you sure that you want to clear your list of selected leads?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Clear',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setSelected([]);
                    return;
                }
            }
        })
    }

    const onContinueClick = () => {
        utils.layer.open({
            id: 'contact_leads',
            Component: ContactLeads.bind(this, {
                leads: selected
            })
        });
    }

    const onLeadClick = lead => {

        // add to list of selected leads if "selecting" is enabled
        if(selecting) {

            // prevent moving forward if lead is already selected
            let current = selected.find(target => target.id === lead.id);
            if(current) {
                return;
            }

            // update list of selected leads
            let next = update(selected, { $unshift: [lead] });
            setSelected(next);
            return;
        }

        // fallback to opening details for the lead
        utils.layer.open({
            id: `lead_details_${lead.id}`,
            abstract: Abstract.create({
                object: lead,
                type: 'leads'
            }),
            Component: LeadDetails
        });
    }

    const onNewLeadClick = () => {
        utils.layer.open({
            id: 'new_lead',
            abstract: Abstract.create({
                object: Lead.new(),
                type: 'leads'
            }),
            Component: AddEditLead.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onRemoveLead = lead => {
        let next = update(selected, {
            $apply: leads => leads.filter(target => {
                return lead.id !== target.id;
            })
        });
        setSelected(next);
    }

    const getSearchAccessoryComponents = () => {
        return Utils.isMobile() === false && (
            <div style={{
                borderLeft: `1px solid ${Appearance.colors.divider()}`,
                paddingLeft: 8
            }}>
                <select
                className={`custom-select ${window.theme}`}
                defaultValue={status.current ? status.current.title : null}
                onChange={evt => {
                    let id = parseInt(Utils.attributeForKey.select(evt, 'id'));
                    status.current = statusCodes.find(status => id === status.id);
                    setLoading(true);
                    fetchLeads();
                }}>
                    <option>{'Choose a Status'}</option>
                    {statusCodes.map((status, index) => {
                        return <option key={index} id={status.id}>{status.title}</option>
                    })}
                </select>
            </div>
        )
    }

    const getAssistProps = () => {
        return {
            message: 'Leads represent potential customers who have submitted their information to you through one or many possible channels.'
        }
    }

    const getButtons = () => {
        if(leads.length === 0) {
            return [{
                key: 'confirm',
                style: 'primary',
                title: 'New Lead',
                onClick: onNewLeadClick
            }]
        }
        return [{
            key: 'confirm',
            style: 'primary',
            title: 'New Lead',
            onClick: onNewLeadClick
        },{
            key: 'selecting',
            title: `${selecting ? 'Select One' : 'Select Batches'}`,
            style: selecting ? 'cancel' : 'default',
            onClick: () => setSelecting(val => !val)
        }];
    }

    const getContent = () => {

        // show loading placeholder if content is initializing
        if(loading === 'init') {
            return Views.loader();
        }

        // return none-found placeholder if no leads have been submitted
        if(leads.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'There are no leads available to view',
                    title: 'No Leads Found',
                })
            )
        }

        // return standard list of leads if "selecting" is not enabled
        if(selecting === false) {
            return (
                <div className={`col-12 p-0 m-0`}>
                    {getLeads()}
                    <PageControl
                    data={paging}
                    limit={limit}
                    offset={offset.current}
                    onClick={next => {
                        offset.current = next;
                        fetchLeads();
                    }}/>
                </div>
            )
        }

        // return selection resources if "selecting" is enabled
        return (
            <div 
            className={'row m-0'}
            style={{
                padding: 12
            }}>
                <div className={`col-12 col-md-6 col-lg-8 pr-md-1 p-0 m-0`}>
                    <div style={Appearance.styles.unstyledPanel()}>
                        {getLeads()}
                        <PageControl
                        data={paging}
                        limit={limit}
                        offset={offset.current}
                        onClick={next => {
                            offset.current = next;
                            fetchLeads();
                        }}/>
                    </div>
                </div>

                <div className={'col-12 col-md-6 col-lg-4 p-0 pl-md-1 m-0'}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        height: '100%'
                    }}>
                        <div
                        className={'px-3 py-2'}
                        style={{
                            borderBottom: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                            }}>{'Selected Leads'}</span>
                        </div>
                        <div style={{
                            flexGrow: 1
                        }}>
                            {getSelectedLeads()}
                        </div>
                        <PageControl
                        data={selectedPaging}
                        limit={limit}
                        offset={selectedOffset}
                        onClick={setSelectedOffset}/>

                        {selected.length > 0 && (
                            <div
                            className={'row m-0'}
                            style={{
                                borderTop: `1px solid ${Appearance.colors.divider()}`,
                                padding: 12,
                                width: '100%'
                            }}>
                                <div className={`col-12 col-md-6 p-1 px-md-1 py-md-0`}>
                                    <Button
                                    color={'dark'}
                                    label={`Clear`}
                                    onClick={onClearLeads}
                                    type={'large'}/>
                                </div>
                                <div className={'col-12 col-md-6 p-1 px-md-1 py-md-0'}>
                                    <Button
                                    color={'primary'}
                                    label={`Contact ${selected.length} ${selected.length === 1 ? 'Lead' : 'Leads'}`}
                                    onClick={onContinueClick}
                                    type={'large'}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const getLeads = () => {
        return leads.map((lead, index) => {
            return (
                Views.entry({
                    badge: {
                        color: lead.status.color,
                        text: lead.status.text
                    },
                    bottomBorder: index !== leads.length - 1,
                    icon: {
                        path: 'images/user-icon-grey.png'
                    },
                    key: index,
                    onClick: onLeadClick.bind(this, lead),
                    subTitle: Utils.formatDate(lead.date),
                    title: lead.full_name
                })
            )
        })
    }

    const getSelectedLeads = () => {
        if(selected.length === 0) {
            return (
                <div
                className={'px-3 py-2'}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    height: 36,
                    width: '100%',
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{'No Leads Selected'}</span>
                </div>
            )
        }

        return selected.filter((_, index) => {
            if(selectedPaging.number_of_pages === 1) {
                return true;
            }
            let min = (limit * selectedPaging.current_page) - limit;
            let max = limit * selectedPaging.current_page;
            return index >= min && index < max;

        }).map((lead, index, leads) => {
            return (
                <div
                key={index}
                className={'px-3 py-2'}
                style={{
                    alignItems: 'center',
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    height: 36,
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        flexGrow: 1
                    }}>{lead.full_name}</span>
                    <img
                    className={'text-button'}
                    src={'images/red-x-icon.png'}
                    onClick={onRemoveLead.bind(this, lead)}
                    style={{
                        height: 15,
                        marginLeft: 8,
                        objectFit: 'contain',
                        width: 15
                    }}/>
                </div>
            )
        })
    }

    const fetchLeads = async () => {
        try {
             let { leads, paging } = await Request.get(utils, '/leads/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText, 
                status: status.current && status.current.id,
                type: 'all'
             });

             setLoading(false);
             setLeads(leads.map(lead => Lead.create(lead)));
             setPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of leads. ${e.message || 'An unknown error occurred'}`,
            });
        }
    }

    useEffect(() => {
        fetchLeads();
    }, [searchText]);

    useEffect(() => {
        setSelectedPaging({
            current_page: (selectedOffset / limit) + 1,
            number_of_pages: selected.length > limit ? Math.ceil(selected.length / limit) : 1
        })
    }, [selected, selectedOffset]);

    useEffect(() => {
        if(selecting === false) {
            setSelected([]);
        }
    }, [selecting]);

    useEffect(() => {

        setStatusCodes(getStatusCodes());
        utils.content.subscribe(panelID, 'leads', {
            onFetch: fetchLeads,
            onUpdate: abstract => {
                setLeads(leads => {
                    return leads.map(lead => {
                        return lead.id === abstract.getID() ? abstract.object : lead
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Leads'}
        utils={utils}
        options={{
            ...options,
            assist: {
                props: getAssistProps()
            },
            buttons: getButtons(),
            loading: loading,
            paging: paging && {
                description: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchLeads();
                }
            },
            removePadding: true,
            search: {
                placeholder: 'Search by lead name, address, or id...',
                onChange: onChangeSearchText,
                rightContent: getSearchAccessoryComponents()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ContactLeads = ({ leads }, { index, options, utils }) => {

    const layerID = 'contact_leads';

    const [edits, setEdits] = useState({ method: 'sms' });
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDiscardClick = () => {
        utils.alert.show({
            title: 'Discard Content',
            message: 'Are you sure that you want to discard your content?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Discard',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setEdits({ method: 'sms' })
                    return;
                }
            }
        });
    }

    const onSendEmailClick = async () => {
        try {

            // verify that all required fields have been filled out
            await validateRequiredFields(getFields);

            // start loading and send message to server
            setLoading('submit');
            await Request.post(utils, '/leads/', {
                ...edits,
                ids: leads.map(lead => lead.id),
                type: 'new_email'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'Sent!',
                message: `Your email has been sent to ${leads.length === 1 ? leads[0].full_name : `${leads.length} leads`}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your email to ${leads.length === 1 ? leads[0].full_name : 'these leads'}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSendSMSClick = async () => {
        try {

            // verify that all required fields have been filled out
            await validateRequiredFields(getFields);

            // start loading and send message to server
            setLoading('submit');
            await Request.post(utils, '/leads/', {
                ...edits,
                ids: leads.map(lead => lead.id),
                type: 'new_sms'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'Sent!',
                message: `Your text message has been sent to ${leads.length === 1 ? leads[0].full_name : `${leads.length} leads`}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your text message to ${leads.length === 1 ? leads[0].full_name : 'these leads'}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits({ ...edits, ...props });
    }

    const getButtons = () => {
        if(edits.method === 'email') {
            return [{
                color: 'grey',
                key: 'discard',
                onClick: onDiscardClick,
                text: 'Discard'
            },{
                color: 'primary',
                key: 'send',
                loading: loading === 'submit',
                onClick: onSendEmailClick,
                text: 'Send Email'
            }];
        }
        return [{
            color: 'grey',
            key: 'discard',
            onClick: onDiscardClick,
            text: 'Discard'
        },{
            color: 'primary',
            key: 'send',
            loading: loading === 'submit',
            onClick: onSendSMSClick,
            text: 'Send Text Message'
        }];
    }

    const getEmailContentPreview = () => {
        let first_name = leads.length === 1 ? leads[0].first_name : '{{first_name}}';
        return edits.method === 'email' && (
            <iframe 
            src={`${API.server}/system/templates/emails/preview.php?type=lead-message&client_id=${utils.client.get().id}&first_name=${first_name}&body=${edits.body || 'This%20is%20where%20the%20body%20content%20will%20appear'}`} 
            style={{
                border: 'none',
                borderRadius: 12,
                height: 350,
                marginBottom: 24,
                width: '100%'
            }}/>
        )
    }

    const getFields = () => {

        // prepare list field for method selection
        let items = [{
            component: 'list',
            description: 'This is how we will deliver your content to the customer.',
            key: 'method',
            items: [{
                id: 'email',
                title: 'Email'
            },{
                id: 'sms',
                title: 'Text Message'
            }],
            onChange: item => onUpdateTarget({ method: item && item.id }),
            props: { deselect: false },
            title: 'Method',
            value: edits.method === 'email' ? 'Email' : 'Text Message'
        }];

        // determine if the selected method is sms
        if(edits.method === 'sms') {
            return [{
                key: 'details',
                title: 'Details',
                items: items.concat([{
                    component: 'textfield',
                    description: 'This is the content that will appear in the text message.',
                    key: 'content',
                    onChange: text => onUpdateTarget({ content: text }),
                    title: 'Content',
                    value: edits.content
                }])
            }];
        }

        // fallback to email as the preferred method
        return [{
            key: 'details',
            title: 'Details',
            items: items.concat([{
                component: 'textfield',
                description: 'This is the content that will appear in the subject line of the email.',
                key: 'subject',
                onChange: text => onUpdateTarget({ subject: text }),
                title: 'Subject',
                value: edits.subject
            },{
                component: 'textview',
                description: 'This is the content that will appear in the body of the email.',
                key: 'body',
                onChange: text => onUpdateTarget({ body: text }),
                title: 'Body',
                value: edits.body
            }])
        }];
    }

    const getRecipients = () => {
        return leads.length === 1 && (
            <LayerItem title={'Sending To'}>
                {Views.entry({
                    badge: {
                        color: leads[0].status.color,
                        text: leads[0].status.text
                    },
                    bottomBorder: false,
                    icon: {
                        path: 'images/user-icon-grey.png'
                    },
                    subTitle: Utils.formatDate(leads[0].date),
                    title: leads[0].full_name
                })}
            </LayerItem>
        )
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Contact ${leads.length === 1 ? leads[0].first_name : 'Leads'}`}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>

            {getEmailContentPreview()}
            {getRecipients()}

            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
            
        </Layer>
    )
}

export const LeadDetails = ({ abstract, index, options, utils }) => {

    const layerID = `lead_details_${abstract.getID()}`;
    const emailLimit = 5;
    const emailOffset = useRef(0);
    const smsLimit = 5;
    const smsOffset = useRef(0);

    const [emails, setEmails] = useState([]);
    const [emailPaging, setEmailPaging] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(abstract.object);
    const [loading, setLoading] = useState(false);
    const [sms, setSms] = useState([]);
    const [smsPaging, setSmsPaging] = useState(null);

    const onContactLead = () => {
        utils.layer.open({
            id: 'contact_leads',
            Component: ContactLeads.bind(this, {
                leads: [abstract.object]
            })
        });
    }

    const onConvertToCustomer = () => {
        utils.alert.show({
            title: 'Convert to Customer',
            message: `Are you sure that you want to convert this lead to a customer? This will create an account for the lead and send them a text message with a link to create their account password.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Convert',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onConvertToCustomerConfirm();
                    return;
                }
            }
        });
    }

    const onConvertToCustomerConfirm = async () => {
        try {

            // start loading with a brief pre-request timeout
            setLoading('options');
            await Utils.sleep(0.25);

            // send request to server
            let { user_id } = await Request.post(utils, '/leads/', {
                id: abstract.getID(),
                type: 'convert_to_customer'
            });

            // end loading and notify subscribers that user data has changed
            setLoading(false);
            utils.content.fetch('users');

            // update abstract target and notify users that target has changed
            abstract.object.customer_user_id = user_id;
            utils.content.update(abstract);

            // show confirmation alert to user with option to view user details
            utils.alert.show({
                title: 'All Done!',
                message: `The customer account for ${abstract.object.full_name} has been created.`,
                buttons: [{
                    key: 'view-account',
                    title: 'View Account',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'view-account') {
                        onViewNewCustomerAccount(user_id);
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue converting this lead to a customer. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteLead = () => {
        utils.alert.dev();
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_lead_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditLead.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'contact',
                style: 'default',
                title: `Contact ${lead.first_name}`
            },{
                key: 'convert_to_customer',
                style: 'default',
                title: 'Convert to Customer',
                visible: lead.customer_user_id ? false : true
            },{
                key: 'delete',
                style: 'destructive',
                title: 'Delete'
            }]
        }, key => {
            if(key === 'contact') {
                onContactLead();
                return;
            }
            if(key === 'convert_to_customer') {
                onConvertToCustomer();
                return;
            }
            if(key === 'delete') {
                onDeleteLead();
                return;
            }
        });
    }

    const onViewNewCustomerAccount = async userID => {
        try {
            setLoading('options');
            await Utils.sleep(0.25);
            let user = await User.get(utils, userID);

            setLoading(false);
            utils.layer.open({
                id: `user-details-${user.user_id}`,
                abstract: Abstract.create({
                    object: user,
                    type: 'users'
                }),
                Component: UserDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for the customer account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options',
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            text: 'Edit',
        }]
    }

    const getEmails = () => {
        return emails.length > 0 && (
            <LayerItem title={'Emails'}>
                {emails.map((email, index) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== emails.length - 1,
                            icon: { 
                                path: 'images/email-icon.png',
                                style: {
                                    backgroundColor: Appearance.colors.transparent
                                } 
                            },
                            key: index,
                            subTitle: email.body,
                            title: email.subject,
                            textStyles: {
                                subTitle: {
                                    whiteSpace: 'normal'
                                }
                            }
                        })
                    )
                })}
                {emailPaging && (
                    <PageControl
                    description={emailPaging}
                    limit={emailLimit}
                    loading={loading === 'emails'}
                    offset={emailOffset.current}
                    onClick={next => {
                        emailOffset.current = next;
                        fetchEmails();
                    }} />
                )}
            </LayerItem>
        )
    }

    const getFields = () => {
        if(!lead) {
            return [];
        }
        let items = [{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                key: 'first_name',
                title: 'First Name',
                value: lead.first_name
            },{
                key: 'last_name',
                title: 'Last Name',
                value: lead.last_name
            },{
                key: 'email_address',
                title: 'Email Address',
                value: lead.email_address
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: Utils.formatPhoneNumber(lead.phone_number)
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(lead.date)
            },{
                key: 'customer_user_id',
                title: 'Customer User ID',
                value: lead.customer_user_id,
                visible: lead.customer_user_id ? true : false
            },{
                key: 'id',
                title: 'ID',
                value: lead.id
            },{
                key: 'status',
                title: 'Status',
                value: lead.status.text
            },{
                key: 'type',
                title: 'Type',
                value: lead.type.text
            }]
        }];

        // add reservation fields if transportation is needed
        if(lead.location) {
            items.push({
                key: 'location',
                title: 'Location',
                items: [{
                    component: 'map',
                    key: 'location',
                    title: 'Map',
                    value: lead.location
                },{
                    key: 'address',
                    title: 'Address',
                    value: Utils.formatAddress(lead.address)
                }]
            })
        }
        return items;
    }

    const getSms = () => {
        return sms.length > 0 && (
            <LayerItem title={'Text Messages'}>
                {sms.map((entry, index) => {
                    return (
                        Views.entry({
                            bottomBorder: index !== sms.length - 1,
                            icon: { 
                                path: 'images/sms-icon.png',
                                style: {
                                    backgroundColor: Appearance.colors.transparent
                                } 
                            },
                            key: index,
                            subTitle: entry.content,
                            title: Utils.formatDate(entry.date),
                            textStyles: {
                                subTitle: {
                                    whiteSpace: 'normal'
                                }
                            }
                        })
                    )
                })}
                {smsPaging && (
                    <PageControl
                    description={smsPaging}
                    limit={smsLimit}
                    loading={loading === 'sms'}
                    offset={smsOffset.current}
                    onClick={next => {
                        smsOffset.current = next;
                        fetchSms();
                    }} />
                )}
            </LayerItem>
        )
    }

    const fetchCommunications = async () => {
        try {
            setLoading(true);
            let { emails, sms } = await Request.get(utils, '/leads/', {
                id: abstract.getID(),
                type: 'communications'
            });

            setLoading(false);
            setEmails(emails.results);
            setEmailPaging(emails.paging);
            setSms(sms.results);
            setSmsPaging(sms.paging)

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the communications for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchEmails = async () => {
        try {
            setLoading('emails');
            let { emails, paging } = await Request.get(utils, '/leads/', {
                id: abstract.getID(),
                limit: emailLimit,
                offset: emailOffset.current,
                type: 'emails'
            });

            setLoading(false);
            setEmails(emails);
            setEmailPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the emails for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchSms = async () => {
        try {
            setLoading('sms');
            let { sms, paging } = await Request.get(utils, '/leads/', {
                id: abstract.getID(),
                limit: smsLimit,
                offset: smsOffset.current,
                type: 'sms'
            });

            setLoading(false);
            setSms(sms);
            setSmsPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the sms for this lead. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchCommunications();
        utils.content.subscribe(layerID, 'leads', {
            onUpdate: next => {
                setLead(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID, 'leads');
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Lead for ${abstract.object.full_name}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <FieldMapper
            fields={getFields()}
            utils={utils} />

            {getEmails()}
            {getSms()}
        </Layer>
    )
}

export const getStatusCodes = () => {
    return [{
        id: 1,
        title: 'New'
    },{
        id: 100,
        title: 'Set'
    },{
        id: 125,
        title: 'Assigned'
    },{
        id: 150,
        title: 'Confirmed'
    },{
        id: 200,
        title: 'Sale'
    },{
        id: 250,
        title: 'Recall'
    },{
        id: 275,
        title: 'Do Not Call'
    },{
        id: 300,
        title: 'Did Not Sell'
    },{
        id: 400,
        title: 'Not Home'
    },{
        id: 450,
        title: 'Not Interested'
    },{
        id: 500,
        title: 'Incomplete'
    },{
        id: 525,
        title: 'Wrong Number'
    },{
        id: 550,
        title: 'Left Voicemail'
    },{
        id: 600,
        title: 'Reset'
    },{
        id: 700,
        title: 'Rescheduled'
    },{
        id: 800,
        title: 'Cancelled'
    },{
        id: 900,
        title: 'Turndown'
    },{
        id: 1000,
        title: 'Unqualified'
    }];
}