import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Appearance from 'styles/Appearance.js';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = props => {

    const Component = withStyles({
        colorPrimary: {
            backgroundColor: Appearance.colors.primary(),
        },
        barColorPrimary: {
            backgroundColor: Appearance.colors.soft()
        }
    })(LinearProgress);

    return <Component {...props} />
}

export default ProgressBar;
