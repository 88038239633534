import React, { useRef, useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import LoaderWhite from 'files/lottie/loader-white.json';
import LottieView from 'views/Lottie.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const AnimatedLoader = ({ loading, onLoadComplete, style }) => {

    const loader = useRef(null);
    const loadingRef = useRef(false);
    const [opacity, setOpacity] = useState(0);

    const onAnimationFinish = async () => {
        try {
            if(loadingRef.current === false) {
                setOpacity(0);
                await Utils.sleep(0.5);
                onLoadComplete();
                return;
            }
            if(loader.current) {
                loader.current.play();
            }
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        loadingRef.current = loading;
    }, [loading]);

    useEffect(() => {
        setOpacity(1);
    }, []);

    return (
        <VelocityComponent
        duration={250}
        animation={{
            opacity: opacity
        }}>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ...style
            }}>
                <LottieView
                ref={loader}
                autoPlay={true}
                loop={false}
                onAnimationFinish={onAnimationFinish}
                source={LoaderWhite}
                style={{
                    width: 150,
                    height: 150
                }}/>
            </div>
        </VelocityComponent>
    )
}

export default AnimatedLoader;
