import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import PaymentMethod from 'classes/PaymentMethod.js';
import ProgressBar from 'views/ProgressBar.js';
import Request from 'files/Request.js';
import Utils, { useLoading } from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

const PaymentMethodManager = ({ addMethodToUser, email_address, defaultMethod, manageMethods, onChange, onMethodsChange, target, utils }) => {

    const [elements, setElements] = useState(null);
    const [loading, setLoading] = useLoading();
    const [methods, setMethods] = useState([]);
    const [methodCompleted, setMethodCompleted] = useState(false);
    const [selected, setSelected] = useState(null);
    const [stripe, setStripe] = useState(null);

    const onAddNewMethod = async () => {
        try {
            setLoading(true);
            let element = elements.getElement(CardElement);
            let { token } = await stripe.createToken(element);
            if(addMethodToUser === false) {

                let method = PaymentMethod.create({
                    ...token.card,
                    token: token.id
                });

                element.clear();
                setLoading(false);
                setMethods(update(methods, {
                    $push: [method]
                }));

                if(typeof(onChange) === 'function') {
                    setSelected(method);
                    onChange(method);
                }
                return;
            }

            // update existing stripe account with new payment method if applicable
            if(target && target.object.stripe_customer_id) {
                try {
                    await Request.post(utils, '/payment/', {
                        type: 'add_card',
                        stripe_id: target.object.stripe_customer_id,
                        token: token.id,
                        source_id: token.card.id
                    });

                    let method = PaymentMethod.create(token.card);
                    target.object.payment_methods = [
                        ...target.object.payment_methods,
                        method
                    ];
                    if(typeof(onMethodsChange) === 'function') {
                        setSelected(method);
                        onMethodsChange(target.object.payment_methods);
                    }

                    element.clear();
                    setLoading(false);
                    setMethods(methods => update(methods, {
                        $push: [method]
                    }));

                } catch(e) {
                    setLoading(false);
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue updating ${target.getTitle()}'s payment methods. ${e.message || 'An unknown error occurred'}`
                    });
                }
                return;
            }

            // create new stripe account if no stripe id is found for the target
            try {
                let { stripe_id } = await Request.post(utils, '/payment/', {
                    type: 'new_customer',
                    company_id: target.type === 'companies' && target.getID(),
                    email_address: email_address,
                    full_name: target.type === 'users' ? target.object.full_name : target.object.name,
                    token: token.id,
                    user_id: target.type === 'users' && target.getID()
                });

                // clear out stripe elements field
                setLoading(false);
                element.clear();

                // update abstract target with new stripe id
                target.object.payment_methods = undefined;
                target.object.stripe_customer_id = stripe_id;

                // fetch new list of payment methods for target
                let { methods } = await target.object.getPaymentMethods(utils);
                setMethods(methods);

                // notify subscribers of methods change if applicable
                if(typeof(onMethodsChange) === 'function') {
                    onMethodsChange(methods);
                }

                // notify subscribers that abstract target has been updated
                utils.content.update(target);

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue creating ${target.getTitle()}'s new payment method. ${e.message || 'An unknown error occurred'}`
                });
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing the credentials for this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onClick = method => {
        let selectedMethod = selected && selected.id === method.id ? null : method;
        setSelected(selectedMethod);
        onChange(selectedMethod);
    }

    const onRemoveMethod = (method, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Remove Payment Method',
            message: 'Are you sure that you want to remove this payment method? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveMethodConfirm(method);
                    return;
                }
            }
        })
    }

    const onRemoveMethodConfirm = async method => {
        try {
            setLoading(method.id);
            await Utils.sleep(1);
            await method.removeMethod(utils, target);

            setLoading(false);
            setMethods(methods => methods.filter(prev => prev.id !== method.id));

            // update abstract target
            target.object.payment_methods = target.object.payment_methods.filter(prev => prev.id !== method.id);
            utils.content.update(target);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this payment method. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getCardComponents = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div style={{
                position: 'relative',
                textAlign: 'center',
                width: '100%',
                padding: 8,
                backgroundColor: Appearance.colors.panelBackground(),
                borderBottomLeftRadius: 7,
                borderBottomRightRadius: 7,
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                {loading === true && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 2,
                        borderRadius: 1,
                        overflow: 'hidden'
                    }}>
                        <ProgressBar />
                    </div>
                )}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    padding: 8,
                    borderRadius: 6,
                    border: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div style={{
                        flexGrow: 1
                    }}>
                        <Elements stripe={utils.stripe}>
                            <ElementsConsumer>
                                {({elements, stripe}) => {
                                    setStripe(stripe);
                                    setElements(elements);
                                    return (
                                        <CardElement
                                        onChange={e => setMethodCompleted(e.complete)}
                                        options={{
                                            hidePostalCode: true,
                                            style: {
                                                invalid: {
                                                    color: Appearance.colors.red,
                                                },
                                                base: {
                                                    fontSize: 12,
                                                    color: Appearance.colors.text(),
                                                    '::placeholder': {
                                                        color: Appearance.colors.subText(),
                                                    },
                                                }
                                            }
                                        }} />
                                    )
                                }}
                            </ElementsConsumer>
                        </Elements>
                    </div>
                    {methodCompleted && (
                        <AltBadge
                        button={true}
                        onClick={onAddNewMethod}
                        content={{
                            color: Appearance.colors.primary(),
                            text: 'Submit'
                        }} />
                    )}
                </div>
            </div>
        )
    }

    const getMethods = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(methods.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing Found',
                    subTitle: `No payment methods are setup for ${target.getTitle()}`,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: 'images/rejected-red-small.png'
                    },
                    bottomBorder: false
                })
            )
        }
        return methods.map((method, index) => {
            return Views.entry({
                key: method.id,
                title: method.type(),
                subTitle: method.subType(),
                loading: loading === method.id,
                badge: method.default && {
                    text: 'Default',
                    color: Appearance.colors.grey()
                },
                icon: {
                    path: 'images/payment-icon-clear-small.png',
                    style: {
                        backgroundColor: selected && selected.id === method.id ? Appearance.colors.primary() : Appearance.colors.grey()
                    }
                },
                bottomBorder: index !== methods.length - 1,
                onClick: manageMethods !== true && typeof(onChange) === 'function' ? onClick.bind(this, method) : null,
                rightContent: target && method.default === false && (
                    <img
                    className={'text-button'}
                    src={'images/red-x-icon.png'}
                    onClick={onRemoveMethod.bind(this, method)}
                    style={{
                        width: 20,
                        height: 20,
                        objectFit: 'contain',
                        marginLeft: 8
                    }} />
                )
            })
        });
    }

    const setupTarget = async () => {
        try {
            if(target) {
                await Utils.sleep(1);
                let { methods } = await target.object.getPaymentMethods(utils);

                setLoading(false);
                setMethods(methods || []);
                if(typeof(onChange) === 'function') {
                    setSelected((methods || []).find(m => defaultMethod ? defaultMethod.id === m.id : false) || []);
                }
            }
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the payment methods. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <div style={{
            width: '100%',
            textAlign: 'left'
        }}>
            <div style={Appearance.styles.unstyledPanel()}>
                {getMethods()}
                {getCardComponents()}
            </div>
        </div>
    )
}
export default PaymentMethodManager;
