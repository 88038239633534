import React, { useState } from 'react';
import moment from 'moment-timezone';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Layer from 'structure/Layer.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';

export const ResetPassword = ({ abstract, index, options, utils }) => {

    const [decrypted, setDecrypted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState(null);
    const [newPasswordConfirm, setNewPasswordConfirm] = useState(null);
    const [password, setPassword] = useState(abstract.object.password);
    const [resetting, setResetting] = useState(false);

    const onResetPassword = async () => {
        if(!newPassword || !newPasswordConfirm) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please enter a new password and confirm it to continue.'
            })
            return;
        }
        if(newPassword !== newPasswordConfirm) {
            utils.alert.show({
                title: 'Oops!',
                message: 'It looks like the new passwords do not match.'
            })
            return;
        }
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { encrypted_password } = await Request.post(utils, '/user/', {
                type: 'reset_password',
                channel: 'seeds',
                password: newPassword,
                user_id: abstract.getID()
            });

            setLoading(false);
            setDecrypted(false);
            setResetting(false);
            setPassword(encrypted_password);
            abstract.object.password = encrypted_password;
            utils.alert.show({
                title: 'All Done!',
                message: `The password for ${abstract.getTitle()} has been reset`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue resetting the password for ${abstract.getTitle()}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDecryptPassword = () => {
        if(abstract.object.level < utils.user.get().level) {
            utils.alert.show({
                title: 'Oops!',
                message: `You are not authorized to view the password for this account.`
            });
            return;
        }
        utils.alert.show({
            title: 'Verification',
            message: 'Please enter your account password to continue',
            textFields: [{
                key: 'password',
                placeholder: 'Password',
                secure: true
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: ({ password }) => {
                if(password !== utils.user.get().password) {
                    setTimeout(() => {
                        utils.alert.show({
                            title: 'Oops!',
                            message: 'The password that you entered is incorrect'
                        })
                    }, 500);
                    return;
                }
                onDecryptPasswordConfirm();
                return;
            }
        })
    }

    const onDecryptPasswordConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { decrypted_password } = await Request.post(utils, '/user/', {
                type: 'decrypt_password',
                password: encodeURI(password)
            });

            setLoading(false);
            setPassword(decrypted_password);
            setDecrypted(true);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue decrypting the password for ${abstract.getTitle()}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    return (
        <Layer
        id={`reset-password-${abstract.getID()}`}
        title={`Password for ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'small',
            loading: loading,
            removePadding: true
        }}>
            <div style={{
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center'
                }}>
                    <div style={{
                        padding: 12,
                        textAlign: 'center'
                    }}>
                        <img
                        src={abstract.object.avatar}
                        style={{
                            ...Appearance.icons.standard(),
                            width: 60,
                            height: 60,
                            borderRadius: 30,
                            marginBottom: 8
                        }} />
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block'
                        }}>{abstract.object.full_name}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            display: 'block'
                        }}>{decrypted ? password : '********'}</span>
                    </div>

                    {resetting
                        ?
                        <div style={{
                            width: '100%',
                        }}>
                            <div style={{
                                padding: '0px 12px 12px 12px',
                            }}>
                                <TextField
                                placeholder={'New Password'}
                                isSecure={true}
                                onChange={text => setNewPassword(text)}/>
                                <TextField
                                placeholder={'Confirm New Password'}
                                isSecure={true}
                                onChange={text => setNewPasswordConfirm(text)}
                                containerStyle={{
                                    marginTop: 8,
                                }}/>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                padding: 12,
                                justifyContent: 'center',
                                borderTop: `1px solid ${Appearance.colors.divider()}`
                            }}>
                                <Button
                                type={'large'}
                                label={'Cancel'}
                                color={'grey'}
                                onClick={() => setResetting(false)}
                                style={{
                                    marginRight: 4
                                }}/>
                                <Button
                                type={'large'}
                                label={'Done'}
                                color={'primary'}
                                onClick={onResetPassword}
                                style={{
                                    marginLeft: 4
                                }}/>
                            </div>
                        </div>
                        :
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            padding: 12,
                            justifyContent: 'center',
                            borderTop: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            <Button
                            type={'large'}
                            label={'Reveal'}
                            color={'grey'}
                            onClick={onDecryptPassword}
                            style={{
                                marginRight: 4
                            }}/>
                            <Button
                            type={'large'}
                            label={'Reset'}
                            color={'primary'}
                            onClick={() => setResetting(true)}
                            style={{
                                marginLeft: 4
                            }}/>
                        </div>
                    }
                </div>
            </div>
        </Layer>
    )
}
