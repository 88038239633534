import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper';
import Appearance from 'styles/Appearance.js';
import { ContactLeads, LeadDetails } from 'managers/Leads.js';
import { ContactUser, UserDetails } from 'managers/Users.js';
import DriveExperience from 'classes/DriveExperience.js';
import EventManager from 'views/EventManager.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import Notification from 'classes/Notification.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import { ReservationDetails } from 'managers/Reservations.js';
import User from 'classes/User.js';
import Utils, { useLoading } from 'files/Utils.js';
import Views from 'views/Main.js';

export const AddEditDriveExperience = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_drive_experience' : `edit_drive_experience_${abstract.getID()}`;

    const [categories, setCategories] = useState([]);
    const [createPrimaryAccount, setCreatePrimaryAccount] = useState(false);
    const [experience, setExperience] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useLoading();
    const [showLeadFields, setShowLeadFields] = useState(false);

    const onSubmit = async () => {
        try {

            // start loading and validate required fields
            setLoading('submit');
            await validateRequiredFields(getFields);

            // submit request to create or update target
            await abstract.object.apply(utils, isNewTarget, { create_primary_account: createPrimaryAccount });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The drive experience for ${abstract.object.primary.first_name} ${abstract.object.primary.last_name} with the ${abstract.object.vehicle_category.name} has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this drive experience. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setExperience(edits);
    }

    const getButtons = () => {
        return [{
            color: 'grey',
            key: 'discard',
            onClick: utils.alert.discard.bind(this, setupEdits),
            text: 'Discard',
        },{
            color: 'primary',
            key: 'submit',
            loading: loading === 'submit',
            onClick: onSubmit,
            text: 'Submit',
        }]
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    padding: 20
                }}>
                    {Views.loader()}
                </div>
            )
        }
        return (
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        )
    }

    const getFields = () => {
        if(!experience) {
            return [];
        }
        let items = [{
            key: 'customer',
            title: 'Existing Customer',
            items: [{
                component: 'user_lookup',
                description: `This should be the customer who will be the primary for the experience. Customers who have already registered with your company will show up when searching by first or last name. You can create a new lead below if no existing customers are found.`,
                key: 'primary',
                onChange: user => {
                    setShowLeadFields(user ? false : true);
                    onUpdateTarget({ 
                        primary: {
                            ...user,
                            type: user ? 'customer' : 'lead'
                        } 
                    });
                },
                required: false,
                title: 'First and Last Name',
                value: experience.primary && experience.primary.type === 'customer' ? User.create(experience.primary) : null
            }]
        },{
            key: 'lead',
            title: 'Contact Information',
            visible: showLeadFields,
            items: [{
                component: 'textfield',
                description: 'This should be the first name for the customer who will be the primary for the experience.',
                key: 'first_name',
                onChange: text => {
                    onUpdateTarget({ 
                        primary: {
                            ...experience.primary,
                            first_name: text
                        } 
                    });
                },
                title: 'First Name',
                value: experience.primary && experience.primary.first_name
            },{
                component: 'textfield',
                description: 'This should be the last name for the customer who will be the primary for the experience.',
                key: 'last_name',
                onChange: text => {
                    onUpdateTarget({ 
                        primary: {
                            ...experience.primary,
                            last_name: text
                        } 
                    });
                },
                title: 'Last Name',
                value: experience.primary && experience.primary.last_name
            },{
                component: 'textfield',
                description: 'This should be the email address for the customer who will be the primary for the experience. We use the email address to keep the primary up to date with the life cycle of their drive experience.',
                key: 'email_address',
                onChange: text => {
                    onUpdateTarget({ 
                        primary: {
                            ...experience.primary,
                            email_address: text
                        } 
                    });
                },
                title: 'Email Address',
                value: experience.primary && experience.primary.email_address
            },{
                component: 'textfield',
                description: 'This should be the phone number for the customer who will be the primary for the experience. We use the phone number to keep the primary up to date with the life cycle of their drive experience.',
                key: 'phone_number',
                onChange: text => {
                    onUpdateTarget({ 
                        primary: {
                            ...experience.primary,
                            phone_number: text
                        } 
                    });
                },
                props: { format: 'phone_number' },
                title: 'Phone Number',
                value: experience.primary && experience.primary.phone_number
            },{
                component: 'bool_list',
                description: 'Creating an account for the primary will allow them to login and manage their drive experience. Accounts are automatically created for primaries who require transporation to the drive experience. We use this account to automatically book a scheduled ride to the drive experience location.',
                key: 'create_account',
                onChange: setCreatePrimaryAccount,
                required: false,
                title: 'Create Account for Primary',
                value: createPrimaryAccount,
                visible: experience.primary && experience.primary.type === 'lead'
            }]
        },{
            key: 'experience',
            title: 'Experience',
            items: [{
                component: 'date_time_picker',
                description: 'This will be the date and time the primary will begin their drive experience.',
                key: 'date',
                onChange: date => onUpdateTarget({ date: date }),
                title: 'Date and Time',
                value: experience.date
            },{
                component: 'list',
                description: 'This will be the vehicle type the primary will be using during the drive experience.',
                items: categories,
                key: 'vehicle_category',
                onChange: item => {
                    onUpdateTarget({ 
                        vehicle_category: item && {
                            id: item.id,
                            name: item.title
                        } 
                    });
                },
                title: 'Vehicle',
                value: experience.vehicle_category && experience.vehicle_category.name
            },{
                component: 'bool_list',
                description: 'Requesting transportation to the drive experience will automatically book a scheduled ride for the primary. The ride will be scheduled to drop the primary off at the drive experience location 15 minutes before the experience begins.',
                key: 'transportation',
                onChange: val => {
                    onUpdateTarget({ 
                        reservation: val ? experience.reservation : null,
                        transportation: val, 
                    })
                },
                required: false,
                title: 'Transportation',
                value: experience.transportation
            }]
        }];

        // add reservation fields if transportation is needed
        if(experience.transportation === true) {
            items.push({
                key: 'transportation',
                title: 'Transportation',
                items: [{
                    component: 'address_lookup',
                    description: 'This will be the location where the primary will be met by the driver.',
                    key: 'reservation.origin',
                    onChange: result => {
                        onUpdateTarget({ 
                            reservation: {
                                ...experience.reservation,
                                origin: result && {
                                    ...result,
                                    address: Utils.formatAddress(result.address)
                                }
                            } 
                        })
                    },
                    title: 'Pickup Location',
                    value: experience.reservation && experience.reservation.origin && experience.reservation.origin.address
                },{
                    component: 'textview',
                    description: 'This is where any requests or messages for the driver can be left. The driver will be able to read this information when interacting with the scheduled ride for this experience.',
                    key: 'reservation.origin',
                    onChange: result => {
                        onUpdateTarget({ 
                            reservation: {
                                ...experience.reservation,
                                special_requests: {
                                    ...experience.reservation && experience.reservation.special_requests,
                                    message: result
                                }
                            } 
                        })
                    },
                    required: false,
                    title: 'Message for Driver',
                    value: experience.reservation && experience.reservation.special_requests && experience.reservation.special_requests.message
                }]
            })
        }
        return items;
    }

    const fetchVehicleCategories = async () => {
        try {
             let { categories } = await Request.get(utils, '/vehicles/', {
                drive_experience: true,
                show_unavailable: true,
                type: 'list_categories'
             });
             setCategories(categories.map(cat => ({
                id: cat.id,
                title: cat.name
             })));
             setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of available drive experience vehicle types. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }
    
    const setupEdits = () => {

        // set flag for showing lead fields
        setShowLeadFields(isNewTarget ? true : (abstract.object.primary && abstract.object.primary.type === 'lead'));

        // setup default edits
        let edits = abstract.object.open();

        // set default date and primary type for new targets
        if(isNewTarget) {
            edits = abstract.object.set({ 
                date: moment().add(1, 'days'),
                primary: {type: 'lead'} 
            });
        }
        setExperience(edits);
    }

    useEffect(() => {
        fetchVehicleCategories();
        setupEdits();
    }, [])

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Drive Experience' : `Editing Drive Experience #${abstract.getID()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const DriveExperiences = ({ index, options, utils }) => {
     
    const panelID = 'drive_experiences';
    const limit = 10;

    const category = useRef(null);
    const dates = useRef({});
    const offset = useRef(0);

    const [categories, setCategories] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [loading, setLoading] = useLoading();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        switch(key) {
            case 'qr-code':
            onQRCodeClick();
            break;
        
            case 'web-link':
            onWebLinkClick();
            break;
        }
    }

    const onExperienceClick = exp => {
        utils.layer.open({
            id: `drive_experience_details_${exp.id}`,
            abstract: Abstract.create({
                object: exp,
                type: 'drive_experiences'
            }),
            Component: DriveExperienceDetails
        });
    }

    const onNewDriveExperienceClick = () => {
        utils.layer.open({
            id: 'new_drive_experience',
            abstract: Abstract.create({
                object: DriveExperience.new(),
                type: 'driverExperiennces'
            }),
            Component: AddEditDriveExperience.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onQRCodeClick = async () => {
        try {
            setLoading(true);
            let { url } = await Request.get(utils, '/drive_experiences/', {
                type: 'general_qr_code'
            });

            setLoading(false);
            utils.alert.show({
                title: 'Dealership QR Code',
                message: `A dealership QR code will allow a customer to request a drive experience for any one of your available vehicle categories. QR codes for specific vehicle categories can be found on the vehicle category details screen in the "Drive Experiences" section.`,
                buttons: [{
                    key: 'download',
                    title: 'Download QR Code',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'download') {
                        window.open(url);
                        return;
                    }
                }
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your qr code. ${e.message || 'An unknown error occurred'}`
            });
        }
    }   

    const onWebLinkClick = async () => {
        try {
            setLoading(true);
            let { url } = await Request.get(utils, '/drive_experiences/', {
                type: 'general_web_link'
            });

            setLoading(false);
            utils.alert.show({
                title: 'Dealership Web Link',
                message: `A dealership web link will allow a customer to request a drive experience for any one of your available vehicle categories. Web links for specific vehicle categories can be found on the vehicle category details screen in the "Drive Experiences" section.`,
                buttons: [{
                    key: 'copy',
                    title: 'Copy Link to Clipboard',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'copy') {
                        
                        // copy url to clipboard
                        navigator.clipboard.writeText(url);

                        // show notification confirmation
                        let notification = Notification.create({ 
                            message: 'The dealership drive experience web link has been copied to your clipboard',
                            title: 'Drive Experience Web Link Copied'
                        });
                        utils.notification.show(notification);
                    }
                }
            });
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your web link. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getAssistProps = () => {
        return {
            items: [{
                key: 'qr-code',
                title: 'Get Dealership QR Code',
                style: 'default'
            },{
                key: 'web-link',
                title: 'Get Dealership Web Link',
                style: 'default'
            }],
            message: 'Drive experiences represent customers who have expressed and interest to ride in a specific vehicle so they can experience what the vehicle has to offer. These experiences create an intimate and open environment where customers can ask questions and learn more about what they want and need in a vehicle.'
        }
    }

    const getButtons = () => {
        return [{
            key: 'confirm',
            style: 'primary',
            title: 'New Drive Experience',
            onClick: onNewDriveExperienceClick
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(experiences.length === 0) {
            return (
                Views.entry({
                    title: 'No Drive Experiences Found',
                    subTitle: 'There are no drive experience available to view',
                    bottomBorder: false
                })
            )
        }
        return experiences.map(exp => {
            return (
                Views.entry({
                    badge: [{
                        color: Appearance.colors.darkGreen,
                        text: exp.reservation ? 'Transportation Scheduled' : null
                    },{
                        color: exp.status.color,
                        text: exp.status.text
                    }],
                    bottomBorder: index !== experiences.length - 1,
                    icon: { 
                        path: exp.primary.avatar || 'images/user-icon-grey.png'
                    },
                    onClick: onExperienceClick.bind(this, exp),
                    subTitle: `${Utils.formatDate(exp.date)} for the ${exp.vehicle_category.name}`,
                    title: exp.primary.full_name
                })
            )
        });
    }

    const getSearchAccessoryComponents = () => {
        return Utils.isMobile() === false && (
            <div style={{
                borderLeft: `1px solid ${Appearance.colors.divider()}`,
                paddingLeft: 8
            }}>
                <select
                className={`custom-select ${window.theme}`}
                defaultValue={category.current ? category.current.name : null}
                onChange={evt => {
                    let id = parseInt(Utils.attributeForKey.select(evt, 'id'));
                    category.current = categories.find(category => id === category.id);
                    fetchExperiences();
                }}>
                    <option>{'Choose a Vehicle'}</option>
                    {categories.map((category, index) => {
                        return <option key={index} id={category.id}>{category.name}</option>
                    })}
                </select>
            </div>
        )
    }

    const fetchVehicleCategories = async () => {
        try {
             let { categories } = await Request.get(utils, '/vehicles/', {
                drive_experience: true,
                end_date: dates.end_date && moment(dates.end_date).utc().unix(),
                show_unavailable: true,
                start_date: dates.start_date && moment(dates.start_date).utc().unix(),
                type: 'list_categories',
                vehicle_category_id: category.current && category.current.id
             });
             setCategories(categories);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of available drive experience vehicle types. ${e.message || 'An unknown error occurred'}`,
            });
        }
    }

    const fetchExperiences = async () => {
        try {
            setLoading(true);
            let { experiences, paging } = await Request.get(utils, '/drive_experiences/', {
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'all_admin'
            });

            setLoading(false);
            setExperiences(experiences.map(exp => DriveExperience.create(exp)));
            setPaging(paging);
            
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the drive experience list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchExperiences();
    }, [searchText]);

    useEffect(() => {

        fetchVehicleCategories();
        utils.content.subscribe(panelID, 'drive_experiences', {
            onFetch: fetchExperiences,
            onUpdate: abstract => {
                setExperiences(experiences => {
                    return experiences.map(exp => {
                        return exp.id === abstract.getID() ? abstract.object : exp
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        id={panelID}
        index={index}
        name={'Drive Experiences'}
        utils={utils}
        options={{
            ...options,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: getButtons(),
            loading: loading,
            paging: paging && {
                description: paging,
                limit: limit,
                offset: offset.current,
                onClick: next => {
                    offset.current = next;
                    fetchExperiences();
                }
            },
            removePadding: true,
            search: {
                placeholder: 'Search by drive experience id, lead name, or customer name...',
                onChange: setSearchText,
                rightContent: getSearchAccessoryComponents()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const DriveExperienceDetails = ({ abstract, index, options, utils }) => {

    const layerID = `drive_experience_details_${abstract.getID()}`;

    const [dropDown, setDropDown] = useState(null);
    const [experience, setExperience] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onContactPrimary = async () => {
        try {

            // determine if primary is a lead
            if(experience.primary.type === 'lead') {

                setLoading('options');
                let lead = await Lead.get(utils, experience.primary.id);

                setLoading(false);
                utils.layer.open({
                    id: `contact_lead_${lead.id}`,
                    Component: ContactLeads.bind(this, {
                        leads: [lead]
                    })
                });
                return;
            }

            // fallback to assuming primary is a user
            setLoading('options');
            let user = await User.get(utils, experience.primary.user_id);

            setLoading(false);
            utils.layer.open({
                id: `contact_user_${user.user_id}`,
                abstract: Abstract.create({
                    object: user,
                    type: 'users'
                }),
                Component: ContactUser
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for the primary. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = async () => {
        try {

            // fetch details for reservation if applicable
            if(abstract.object.reservation) {
                setLoading('edit');
                abstract.object.reservation = await Reservation.get(utils, abstract.object.reservation.id);
                setLoading(false);
            }

            // present editing layer for experience
            utils.layer.open({
                id: `edit_drive_experience_${abstract.getID()}`,
                abstract: abstract,
                Component: AddEditDriveExperience.bind(this, {
                    isNewTarget: false
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the transportation details. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'approve',
                style: 'default',
                title: 'Approve',
                visible: experience.status.code === null
            },{
                key: 'contact',
                style: 'default',
                title: `Contact ${experience.primary.first_name}`
            },{
                key: 'decline',
                style: 'destructive',
                title: 'Decline',
                visible: experience.status.code === null
            },{
                key: 'status',
                style: 'default',
                title: 'Set Status'
            }]
        }, key => {
            if(key === 'approve' || key === 'decline') {
                onSetApprovalStatus(key === 'approve');
                return;
            }
            if(key === 'contact') {
                onContactPrimary();
                return;
            }
            if(key === 'status') {
                onSetStatus();
                return;
            }
        });
    }

    const onPrimaryClick = async () => {
        try {

            // show details layer for lead if applicable
            if(experience.primary.type === 'lead') {
                utils.layer.open({
                    id: `lead_details_${experience.primary.id}`,
                    abstract: Abstract.create({
                        type: 'leads',
                        object: experience.primary
                    }),
                    Component: LeadDetails
                });
                return;
            }

            // fetch details for user
            setLoading('primary');
            let user = await User.get(utils, experience.primary.user_id);

            // fallback to showing details layer for user
            setLoading(false);
            utils.layer.open({
                id: `user-details-${user.user_id}`,
                abstract: Abstract.create({
                    type: 'users',
                    object: user
                }),
                Component: UserDetails
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for the primary. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onReservationClick = async () => {
        try {

            setLoading('reservation');
            let reservation = await Reservation.get(utils, abstract.object.reservation.id);

            setLoading(false);
            utils.layer.open({
                id: `reservation-details-${reservation.id}`,
                abstract: Abstract.create({
                    type: 'reservations',
                    object: reservation
                }),
                Component: ReservationDetails
            }); 

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the transportation details. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetApprovalStatus = approved => {
        utils.alert.show({
            title: `${approved ? 'Approve' : 'Decline'} Drive Experience`,
            message: `Are you sure that you want to ${approved ? 'approve' : 'decline'} this drive experience? We will notify the primary with your decision.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: approved ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${approved ? 'Approve' : 'Decline'}`,
                style: approved ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetApprovalStatusConfirm(approved);
                    return;
                }
            }
        })
    }

    const onSetApprovalStatusConfirm = async approved => {
        try {

            setLoading(true);
            await Utils.sleep(0.25);
            let { status } = await Request.post(utils, '/drive_experiences/', {
                id: abstract.getID(),
                status: approved ? 1 : 0,
                type: 'set_status'
            });

            // end loading and update status for local state
            setLoading(false);
            abstract.object.status = status;
            setExperience(abstract.object);

            // notify subscribers that data has changed
            utils.content.update(abstract);

            // show confirmation alert to user
            utils.alert.show({
                title: 'All Done!',
                message: `This drive experience has been ${approved ? 'approved' : 'declined'} and the primary has been notified`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${approved ? 'approving' : 'declining'} this drive experience. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetStatus = () => {
        setDropDown({
            title: 'Events and Status Changes',
            message: 'The drive experience event log shows the journey of the drive experience from booking to completion and includes additional information about log events when applicable.',
            content: (
                <EventManager
                driveExperience={experience} 
                utils={utils}/>
            )
        })
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            loading: loading === 'options',
            onClick: onOptionsClick,
            text: 'Options',
        },{
            color: 'primary',
            key: 'edit',
            loading: loading === 'edit',
            onClick: onEditClick,
            text: 'Edit',
        }];
    }

    const getPrimary = () => {
        return (
            <LayerItem title={'Primary'}>
                {Views.entry({
                    bottomBorder: false,
                    icon: { path: experience.primary.type === 'customer' && experience.primary.avatar || 'images/user-icon-grey.png' },
                    loading: loading === 'primary',
                    onClick: onPrimaryClick,
                    subTitle: experience.primary.email_address || 'Email address not available',
                    title: experience.primary.full_name
                })}
            </LayerItem>
        )
    }

    const getFields = () => {
        if(!experience) {
            return [];
        }
        let items = [{
            key: 'contact',
            title: 'Contact Information',
            items: [{
                key: 'first_name',
                title: 'First Name',
                value: experience.primary && experience.primary.first_name
            },{
                key: 'last_name',
                title: 'Last Name',
                value: experience.primary && experience.primary.last_name
            },{
                key: 'email_address',
                title: 'Email Address',
                value: experience.primary && experience.primary.email_address
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: experience.primary && Utils.formatPhoneNumber(experience.primary.phone_number)
            }]
        },{
            key: 'experience',
            title: 'Experience',
            items: [{
                key: 'date',
                title: 'Date and Time',
                value: experience.date && Utils.formatDate(experience.date)
            },{
                key: 'id',
                title: 'ID',
                value: experience.id
            },{
                key: 'vehicle_category',
                title: 'Vehicle',
                value: experience.vehicle_category && experience.vehicle_category.name
            },{
                key: 'transportation',
                title: 'Transportation Requested',
                value: 'No',
                visible: experience.transportation === false
            },{
                key: 'status',
                title: 'Status',
                value: experience.status.text
            }]
        }];

        return items;
    }

    const getReservation = () => {
        let { reservation } = abstract.object;
        return reservation && (
            <LayerItem title={'Transportation'}>
                {Views.entry({
                    bottomBorder: false,
                    icon: { path: experience.primary.avatar },
                    loading: loading === 'reservation',
                    onClick: onReservationClick,
                    subTitle: Utils.formatDate(reservation.pickup_date),
                    title: experience.primary.full_name
                })}
            </LayerItem>
        )
    }

    useEffect(() => {
        utils.content.subscribe(layerID, 'drive_experiences', {
            onUpdate: next => {
                setExperience(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID, 'drive_experiences');
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        dropDown={dropDown}
        id={layerID}
        index={index}
        title={`Drive Experience for ${abstract.object.primary.full_name}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>

            {getPrimary()}
            {getReservation()}

            <FieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}