import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import AddressLookupField from 'views/AddressLookupField.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const MultipleAddressLookupField = ({ autoComplete, autoCorrect, autoCapitalize, className, containerStyle, fieldStyle, icon, isSecure, utils, hideIcon, label, locations, onChange, onRenderResult, placeholder, spellCheck }) => {

    const [searching, setSearching] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState('');

    const onLocationClick = index => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                let updatedLocations = update(selectedLocations, {
                    $splice: [
                        [index, 1]
                    ]
                });
                setSelectedLocations(updatedLocations);
                if(typeof(onChange) === 'function') {
                    onChange(updatedLocations);
                }
            }
        })
    }

    const onMoveLocation = (location, index, value, evt) => {
        evt.stopPropagation();
        let next = index + value;
        if(next < 0 || next > selectedLocations.length - 1) {
            return;
        }
        let updatedLocations = update(selectedLocations, { $splice: [[ index, 1 ], [ next, 0, location ]] });
        setSelectedLocations(updatedLocations);
        if(typeof(onChange) === 'function') {
            onChange(updatedLocations);
        }
    }

    const onRemoveLocation = (index, evt) => {
        evt.stopPropagation();
        let updatedLocations = update(selectedLocations, { $splice: [[ index, 1 ]] });
        setSelectedLocations(updatedLocations);
        if(typeof(onChange) === 'function') {
            onChange(updatedLocations);
        }
    }

    const onSelectLocation = location => {
        let updatedLocations = update(selectedLocations, {
            $push: [location]
        });
        setText('');
        setSelectedLocations(updatedLocations);
        if(typeof(onChange) === 'function') {
            onChange(updatedLocations);
        }
    }

    useEffect(() => {
        setSelectedLocations(locations || []);
    }, [locations]);

    return (
        <div style={{
            textAlign: 'left',
            width: '100%'
        }}>
            <AddressLookupField
            utils={utils}
            value={text}
            geocode={true}
            loading={searching}
            onChange={onSelectLocation}
            preserveResult={false}
            containerStyle={{
                width: '100%'
            }} />
            {selectedLocations.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {selectedLocations.map((result, index, results) => {
                        if(typeof(onRenderResult) === 'function') {
                            return onRenderResult(result, index, results, onRemoveLocation.bind(this, index));
                        }
                        return (
                            Views.entry({
                                key: index,
                                title: result.name || 'Name Not Available',
                                subTitle: Utils.formatAddress(result),
                                hideIcon: true,
                                bottomBorder: index !== results.length - 1,
                                rightContent: (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <img
                                        className={'text-button'}
                                        src={'images/up-directional-arrow-small.png'}
                                        onClick={onMoveLocation.bind(this, result, index, -1)}
                                        style={{
                                            width: 18,
                                            height: 18,
                                            objectFit: 'contain',
                                            marginLeft: 8,
                                            opacity: index > 0 ? 1 : 0.25
                                        }} />
                                        <img
                                        className={'text-button'}
                                        src={'images/down-directional-arrow-small.png'}
                                        onClick={onMoveLocation.bind(this, result, index, 1)}
                                        style={{
                                            width: 18,
                                            height: 18,
                                            objectFit: 'contain',
                                            marginLeft: 8,
                                            opacity: index < results.length - 1 ? 1 : 0.25
                                        }} />
                                        <img
                                        className={'text-button'}
                                        src={'images/red-x-icon.png'}
                                        onClick={onRemoveLocation.bind(this, index)}
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: 8
                                        }} />
                                    </div>
                                )
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default MultipleAddressLookupField;
