import React, { useEffect, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import AltFieldMapper from 'views/AltFieldMapper.js';

const BusinessHoursPicker = ({ onChange, times, utils }) => {

    const days = [0,1,2,3,4,5,6];
    const [timeEntries, setTimeEntries] = useState([]);

    const onPrepareTimes = times => {
        return times.filter(time => {
            return time.enabled !== false && time.start && time.end;
        }).map(time => ({
            day: time.day,
            end: moment(time.end, 'HH:mm:ss'),
            formatted: `${moment(time.start, 'HH:mm:ss').format('h:mma')} to ${moment(time.end, 'HH:mm:ss').format('h:mma')}`,
            start: moment(time.start, 'HH:mm:ss')
        }));
    }

    const onToggleStatus = (day, enabled) => {
        let index = timeEntries.findIndex(time => time.day === day);
        let updatedTimes = update(timeEntries, {
            [index]: {
                enabled: {
                    $set: enabled
                }
            }
        });
        setTimeEntries(updatedTimes);
        if(typeof(onChange) === 'function') {
            let results = onPrepareTimes(updatedTimes);
            onChange(results);
        }
    }

    const onUpdateTime = (day, field, time) => {
        let index = timeEntries.findIndex(time => time.day === day);
        let updatedTimes = update(timeEntries, {
            [index]: {
                [field]: {
                    $set: moment(time, 'HH:mm:ss')
                }
            }
        });
        setTimeEntries(updatedTimes);
        if(typeof(onChange) === 'function') {
            let results = onPrepareTimes(updatedTimes);
            onChange(results);
        }
    }

    const getFields = () => {
        if(timeEntries.length === 0) {
            return [];
        }
        return days.map((day, index) => {
            let entry = timeEntries.find(entry => entry.day === day);
            return {
                key: index,
                title: entry.title,
                items: [{
                    key: 'enabled',
                    title: 'Accepts Orders',
                    description: 'This indicates whether or not the order host will accept orders placed on this day.',
                    value: entry.enabled,
                    component: 'bool_list',
                    onChange: val => onToggleStatus(day, val)
                },{
                    key: index,
                    required: entry.enabled === true,
                    visible: entry.enabled === true,
                    title: 'Start and End Time',
                    description: `These will be the hours that this host will accept orders on ${moment().startOf('week').add(day, 'days').format('dddd')}s`,
                    component: 'time_range_picker',
                    value: entry.end && entry.start,
                    props: {
                        selectedStartTime: moment(entry.start, 'HH:mm:ss'),
                        selectedEndTime: moment(entry.end, 'HH:mm:ss'),
                        onStartTimeChange: time => onUpdateTime(day, 'start', time),
                        onEndTimeChange: time => onUpdateTime(day, 'end', time)
                    }
                }]
            }
        });
    }

    useEffect(() => {
        setTimeEntries(days.map(day => {
            let selection = times && times.find(time => time.day === day);
            return {
                day: day,
                title: moment().startOf('week').add(day, 'days').format('dddd'),
                enabled: false,
                start: moment().startOf('day').add(8, 'hours'),
                end: moment().startOf('day').add(17, 'hours'),
                ...selection,
                ...selection && {
                    enabled: true
                }
            }
        }));
    }, [times]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </div>
    )
}
export default BusinessHoursPicker;
