import Order from 'classes/Order.js';
import Request from 'files/Request.js';

class ServiceClass {

    id = null;
    name = null;
    information = null;
    billing = null;
    disclaimer = null;
    preauthorize = null;
    payment_methods = [];
    options = [];
    locations = [];
    required_options = [];
    channel = null;
    order_channel = null;
    default_for_channel = false;
    active = null;
    on_demand = [];

    delivery = null;
    should_tip_driver = true;
    should_book_return_trip = true;
    should_preauthorize = false;
    should_decline_on_preauthorization_failure = false;

    auto_reject = null;
    fixed_amount = 0.0;
    max_distance = null;
    min_distance = null;
    min_booking_time = null;
    max_radius = null;
    radius_center = null;
    waitTime = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.information = props.information;
        this.active = props.active;
        this.channel = props.channel || 'standard';
        this.order_channel = props.order_channel ? Order.Channel.create(props.order_channel) : null;
        this.default_for_channel = props.default_for_channel || false;

        let { auto_reject, billing, delivery, options, on_demand, restrictions } = props.parameters || {};
        this.auto_reject = auto_reject || {};
        this.delivery = delivery;
        this.preauthorize = options.preauthorize;
        this.disclaimer = options.disclaimer;
        this.required_options = options.required_items;
        this.should_tip_driver = options.should_tip_driver || false;
        this.should_book_return_trip = options.should_book_return_trip || false;
        this.should_preauthorize = options.should_preauthorize;
        this.should_decline_on_preauthorization_failure = options.should_decline_preauth_failure;
        this.payment_methods = billing.allowed || [];
        this.billing = billing.type;
        this.min_distance = restrictions && restrictions.min_distance;
        this.max_distance = restrictions && restrictions.max_distance;
        this.min_booking_time = restrictions && restrictions.min_booking_time;
        this.waitTime = restrictions && restrictions.wait_time;
        this.fixed_amount = billing.type === 'fixed' && billing.amount;
        this.on_demand = on_demand || {};

        let optionsType = options.type;
        if(optionsType === 'variable') {
            this.options = options.items || [];
        }
        if(optionsType === 'all') {
            this.options = [ 'id', 'service', 'vehicle', 'origin', 'destination', 'pickup_date', 'passengers_and_luggage', 'payment', 'mood_and_music', 'flight_number', 'requests', 'promo_code' ];
        }
        return this;
    }

    getDefaultOptions = () => {
        return [ 'id', 'origin', 'destination', 'pickup_date', 'payment' ];
    }

    optionText = type => {
        switch(type) {
            case 'id':
            return 'Reservation ID';

            case 'service':
            return 'Service';

            case 'vehicle':
            return 'Vehicle';

            case 'pickup_date':
            return 'Pickup Time and Date';

            case 'origin':
            return 'Pickup Location';

            case 'stops':
            return 'Stops Along the Way';

            case 'destination':
            return 'Drop-Off Location';

            case 'passengers_and_luggage':
            return 'Passengers and Luggage';

            case 'payment':
            return 'Payment Method';

            case 'mood_and_music':
            return 'Mood and Music';

            case 'flight_number':
            return 'Flight Number';

            case 'requests':
            return 'Special Requests';

            case 'promo_code':
            return 'Promo Code';

            case 'driver_tip':
            return 'Driver Tip';

            default:
            return type || 'Unknown Booking Option';
        }
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    open = () => {
        this.edits = {
            name: this.name,
            channel: this.channel || 'standard',
            order_channel: this.order_channel,
            default_for_channel: this.default_for_channel,
            information: this.information,
            billing: this.billing,
            disclaimer: this.disclaimer,
            preauthorize: this.preauthorize,
            auto_reject: this.auto_reject,
            on_demand: this.on_demand,
            should_preauthorize: this.should_preauthorize,
            payment_methods: this.payment_methods.length > 0 ? this.payment_methods : ['card','credits'],
            options: this.options && this.options.length > 0 ? this.options : this.getDefaultOptions(),
            required_options: this.required_options && this.required_options.length > 0 ? this.required_options : this.getDefaultOptions(),
            delivery: this.delivery,
            should_tip_driver: this.should_tip_driver,
            should_book_return_trip: this.should_book_return_trip,
            should_preauthorize: this.should_preauthorize,
            should_decline_on_preauthorization_failure: this.should_decline_on_preauthorization_failure,
            max_distance: this.max_distance,
            min_distance: this.min_distance,
            min_booking_time: this.min_booking_time ? this.min_booking_time / 60 : null,
            max_radius: this.max_radius,
            radius_center: this.radius_center
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            name: props.name || this.edits.name,
            channel: props.channel !== undefined ? props.channel : this.edits.channel,
            order_channel: props.order_channel !== undefined ? props.order_channel : this.edits.order_channel,
            default_for_channel: props.default_for_channel !== undefined ? props.default_for_channel : this.edits.default_for_channel,
            information: props.information || this.edits.information,
            billing: props.billing || this.edits.billing,
            disclaimer: props.disclaimer || this.edits.disclaimer,
            preauthorize: props.preauthorize || this.edits.preauthorize,
            auto_reject: props.auto_reject || this.edits.auto_reject,
            on_demand: props.on_demand || this.edits.on_demand,
            should_preauthorize: props.should_preauthorize == undefined ? props.should_preauthorize : this.edits.should_preauthorize,
            payment_methods: props.payment_methods || this.edits.payment_methods,
            options: props.options || this.edits.options,
            required_options: props.required_options || this.edits.required_options,
            delivery: props.delivery || this.edits.delivery && {
                ...this.edits.delivery,
                ...props.delivery
            },
            should_tip_driver: props.should_tip_driver !== undefined ? props.should_tip_driver : this.edits.should_tip_driver,
            should_book_return_trip: props.should_book_return_trip !== undefined
             ? props.should_book_return_trip : this.edits.should_book_return_trip,
            should_preauthorize: props.should_preauthorize !== undefined ? props.should_preauthorize : this.edits.should_preauthorize,
            should_decline_on_preauthorization_failure: props.should_decline_on_preauthorization_failure !== undefined ? props.should_decline_on_preauthorization_failure : this.edits.should_decline_on_preauthorization_failure,
            max_distance: props.max_distance !== undefined ? props.max_distance : this.edits.max_distance,
            min_distance: props.min_distance !== undefined ? props.min_distance : this.edits.min_distance,
            min_booking_time: props.min_booking_time !== undefined ? props.min_booking_time : this.edits.min_booking_time,
            max_radius: props.max_radius || this.edits.max_radius,
            radius_center: props.radius_center || this.edits.radius_center
        }
        return this.edits;
    }

    close = props => {
        let target = props || this.edits;
        this.name = target.name || this.name;
        this.channel = target.channel || this.channel;
        this.order_channel = target.order_channel || this.order_channel;
        this.default_for_channel = target.default_for_channel !== undefined ? target.default_for_channel : this.default_for_channel;
        this.information = target.information || this.information;
        this.billing = target.billing || this.billing;
        this.disclaimer = target.disclaimer || this.disclaimer;
        this.preauthorize = target.preauthorize || this.preauthorize;
        this.on_demand = target.on_demand || this.on_demand;
        this.auto_reject = target.auto_reject || this.auto_reject;
        this.should_preauthorize = target.should_preauthorize || this.should_preauthorize;
        this.payment_methods = target.payment_methods || this.payment_methods;
        this.options = target.options || this.options;
        this.required_options = target.required_options || this.required_options;
        this.delivery = target.delivery || this.delivery;
        this.should_tip_driver = target.should_tip_driver !== undefined ? target.should_tip_driver : this.should_tip_driver;
        this.should_book_return_trip = target.should_book_return_trip !== undefined ? target.should_book_return_trip : this.should_book_return_trip;
        this.should_preauthorize = target.should_preauthorize !== undefined ? target.should_preauthorize : this.should_preauthorize;
        this.should_decline_on_preauthorization_failure = target.should_decline_on_preauthorization_failure !== undefined ? target.should_decline_on_preauthorization_failure : this.should_decline_on_preauthorization_failure;
        this.max_distance = target.max_distance !== undefined ? target.max_distance : this.max_distance;
        this.min_distance = target.min_distance !== undefined ? target.min_distance : this.min_distance;
        this.min_booking_time = target.min_booking_time !== undefined ? (target.min_booking_time * 60) : this.min_booking_time;
        this.max_radius = target.max_radius != undefined ? target.max_radius : this.max_radius;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/reservations/', {
                    type: 'new_service',
                    name: edits.name,
                    channel: edits.channel,
                    order_channel: edits.order_channel ? edits.order_channel.id : null,
                    default_for_channel: edits.default_for_channel,
                    information: edits.information,
                    billing: edits.billing,
                    disclaimer: edits.disclaimer,
                    preauthorize: edits.preauthorize,
                    on_demand: edits.on_demand,
                    auto_reject: edits.auto_reject,
                    should_preauthorize: edits.should_preauthorize,
                    payment_methods: edits.payment_methods,
                    options: edits.options,
                    required_options: edits.required_options,
                    delivery: edits.delivery,
                    driver_tip: edits.should_tip_driver,
                    return_trip: edits.should_book_return_trip,
                    preauthorize: {
                        allowed: edits.should_preauthorize,
                        decline_on_failure: edits.should_decline_on_preauthorization_failure,
                        message: edits.preauthorize
                    },
                    max_distance: edits.max_distance,
                    min_distance: edits.min_distance,
                    min_booking_time: edits.min_booking_time ? edits.min_booking_time * 60 : null,
                    max_radius: edits.max_radius,
                    ...props
                });

                this.id = id;
                this.close();

                utils.content.fetch('services');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/reservations/', {
                    type: 'update_service',
                    id: this.id,
                    name: edits.name,
                    channel: edits.channel,
                    order_channel: edits.order_channel ? edits.order_channel.id : null,
                    default_for_channel: edits.default_for_channel,
                    information: edits.information,
                    billing: edits.billing,
                    disclaimer: edits.disclaimer,
                    preauthorize: edits.preauthorize,
                    on_demand: edits.on_demand,
                    auto_reject: edits.auto_reject,
                    should_preauthorize: edits.should_preauthorize,
                    payment_methods: edits.payment_methods,
                    options: edits.options,
                    required_options: edits.required_options,
                    delivery: edits.delivery,
                    driver_tip: edits.should_tip_driver,
                    return_trip: edits.should_book_return_trip,
                    preauthorize: {
                        allowed: edits.should_preauthorize,
                        decline_on_failure: edits.should_decline_on_preauthorization_failure,
                        message: edits.preauthorize
                    },
                    max_distance: edits.max_distance,
                    min_distance: edits.min_distance,
                    min_booking_time: edits.min_booking_time ? edits.min_booking_time * 60 : null,
                    max_radius: edits.max_radius,
                    ...props
                });

                this.close();
                if(edits.default_for_channel === true) {
                    utils.content.fetch('services');
                }
                utils.content.update({
                    type: 'services',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const fetchService = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { service } = await Request.get(utils, '/reservations/', {
                type: 'service_details',
                id: id
            });
            let target = new ServiceClass().create(service);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new ServiceClass(),
    get: fetchService,
    create: props => new ServiceClass().create(props)
}
