import React, { useState, useEffect } from 'react';
import Utils from 'files/Utils.js';

const ListField = ({ deselect, items, onAddNew, onChange, placeholder, returnKey, style, value }) => {

    const [selected, setSelected] = useState(value);

    const onListChange = evt => {

        // prepare selected item id
        let id = Utils.attributeForKey.select(evt, 'id');
        let val = isNaN(id) ? id : parseInt(id);

        // find matching item in list of items using the selected id
        let match = items.find(item => item.id === val || item.code === val);
        setSelected(match ? (match.title || match.text) : null);

        if(typeof(onChange) === 'function') {
            if(!match) {
                onChange(null); // used for set edits
                return;
            }
            onChange(returnKey === false ? (match.title || match.text) : match);
        }
    }

    const onUpdateSelectedValue = () => {
        if(!value) {
            setSelected(null);
            return;
        }
        let match = items.find(item => {
            if(typeof(value) === 'object') {
                return item.id === value.id || item.title === value.title;
            }
            return item.id === value || item.title === value || item.text === value;
        });
        setSelected(match ? (match.title || match.text) : value);
    }

    const getAccessoryButton = () => {
        if(typeof(onAddNew) === 'function') {
            return (
                <div style={{
                    width: 25,
                    height: 25,
                    minWidth: 25,
                    minHeight: 25,
                    marginLeft: 12
                }}>
                    <img
                    className={'text-button'}
                    src={'images/plus-button-blue-small.png'}
                    onClick={onAddNew}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }} />
                </div>
            )
        }
        return null;
    }

    const getItems = () => {
        if(!items || !Array.isArray(items)) {
            return null;
        }
        return items.filter(item => {
            return item.visible !== false;
        }).map((item, index) => {
            return (
                <option
                key={index}
                id={item.id || item.code}
                disabled={item.disabled || false}>{item.title || item.text}</option>
            )
        })
    }

    useEffect(() => {
        onUpdateSelectedValue();
    }, [items, value]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            ...style
        }}>
            <select
            className={`custom-select ${window.theme}`}
            value={selected || `${Utils.isMobile() ? 'Tap' : 'Click'} to choose an item...`}
            onChange={onListChange}
            style={{
                flexGrow: 1
            }}>
                {deselect !== false && (
                    <option>{placeholder || `${Utils.isMobile() ? 'Tap' : 'Click'} to choose an item...`}</option>
                )}
                {getItems()}
            </select>
            {getAccessoryButton()}
        </div>
    )
}

export default ListField;
