import React, { useEffect, useRef, useState } from 'react';

import { fetchCompanies } from 'managers/Companies.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import { AddBankAccount } from 'managers/Resources.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Assignments from 'managers/Assignments.js';
import BankAccount from 'classes/BankAccount.js';
import { Bar, Line } from 'react-chartjs-2';
import Button from 'views/Button.js';
import Campaign from 'classes/Campaign.js';
import Company from 'classes/Company.js';
import CreditsCard from 'classes/CreditsCard.js';
import DatePickerField from 'views/DatePickerField.js';
import { DndProvider } from 'react-dnd';
import Driver from 'classes/Driver.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import { EditDirectPaymentPreferences } from 'managers/Payments.js';
import FeedbackResponse from 'classes/FeedbackResponse.js';
import FieldMapper from 'views/FieldMapper.js';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ImagePickerField from 'views/ImagePickerField.js';
import Layer, { LayerItem, LayerNote, LayerShell } from 'structure/Layer.js';
import LottieView from 'views/Lottie.js';
import { Map } from 'views/MapElements.js';
import NoDataFound from 'views/NoDataFound.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import PaymentMethod from 'classes/PaymentMethod.js';
import PaymentMethodManager from 'views/PaymentMethodManager.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import { ResetPassword } from 'views/PasswordManager.js';
import SaasAccount from 'classes/SaasAccount.js';
import SearchableReservationsList from 'views/SearchableReservationsList.js';
import { SendPushNotification } from 'views/UserNotification.js';
import SupportEventManager from 'views/SupportEventManager.js';
import SupportTicket from 'classes/SupportTicket.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import TextField from 'views/TextField.js';
import TimeRangePicker from 'views/TimeRangePicker.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views, { AltBadge } from 'views/Main.js';

// panels
export const ActiveVerifications = ({ index, utils }) => {

    const panelID = `activeVerifications`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [verifications, setVerifications] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Active Verifications',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/users/', {
                    type: 'active_verifications',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onDeleteVerification = verification => {
        utils.alert.show({
            title: 'Delete Verification',
            message: `Are you sure that you want to delete this account verification? This will deactivate the code and require the customer to request a new verification email or text message.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteVerificationConfirm(verification);
                    return;
                }
            }
        });
    }

    const onDeleteVerificationConfirm = async verification => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/users/', {
                id: verification.id,
                type: 'delete_account_verification'
            });

            setLoading(false);
            fetchVerifications();
            utils.alert.show({
                title: 'All Done!',
                message: 'The account verification has been deleted'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this account verification. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onVerificationClick = verification => {
        utils.sheet.show({
            items: [{
                key: 'phone',
                title: 'Call Customer',
                style: 'default',
                visible: verification.phone_number ? true : false
            },{
                key: 'email',
                title: 'Email Customer',
                style: 'default',
                visible: verification.email_address ? true : false
            },{
                key: 'delete',
                title: 'Delete Verification',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'email') {
                window.open(`mailto:${verification.email_address}?subject=${encodeURIComponent(`Your ${utils.client.get().name} Account`)}&body=${encodeURIComponent(`It looks like you started to sign up for an account with ${utils.client.get().name} but were unable to complete the onboarding process.\nThe verification code that we sent to ${verification.email_address} is ${verification.code}.\n\nPlease use this code if you still have the signup process open on your device.\nFeel free to respond to this email if you have any issues completing the account signup process.`)}`);
                return;
            }
            if(key === 'phone') {
                window.open(`tel:${verification.phone_number}`);
                return;
            }
            if(key === 'delete') {
                onDeleteVerification(verification);
                return;
            }
        })
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all of the active account verifications in the system. An account verification is created when a customer attempts to sign up for an account. We will send the customer an email or text message with a 6 digit code that they must use in the mobile app to complete the signup process. This list represents all the customers who received a verification email or text message but did not continue with the signup process.`,
            items: [{
                key: 'download',
                title: 'Download Verifications',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(verifications.length === 0) {
            return (
                Views.entry({
                    title: `No Verifications Found`,
                    subTitle: 'There are no active verifications available to view',
                    icon: {
                        path: 'images/user-icon-grey.png'
                    }
                })
            )
        }
        return verifications.map((verification, index) => {
            return (
                Views.entry({
                    key: index,
                    title: verification.email_address || verification.phone_number,
                    subTitle: Utils.formatDate(verification.date),
                    icon: {
                        path: 'images/user-icon-grey.png'
                    },
                    badge: {
                        text: `Code ${verification.code}`,
                        color: Appearance.colors.grey()
                    },
                    bottomBorder: index !== verifications.length - 1,
                    onClick: onVerificationClick.bind(this, verification)
                })
            )
        });
    }

    const fetchVerifications = async () => {
        try {
            let { verifications, paging } = await Request.get(utils, '/users/', {
                type: 'active_verifications',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setVerifications(verifications);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the account verifications list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchVerifications();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Active Account Verifications`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by email address...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const CampaignsList = ({ index, utils }) => {

    const panelID = 'campaignsList';
    const limit = 5;

    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Campaigns',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/campaigns/', {
                    type: 'list',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewCampaign = () => {
        utils.layer.open({
            id: 'new-campaign',
            abstract: Abstract.create({
                type: 'campaigns',
                object: Campaign.new()
            }),
            Component: AddEditCampaign.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `A campaign is a digital piece of marketing sent to one or more users. Campaigns are shown as push notifications and are store for later viewing in the customer's notification section in the mobile app.`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Campaigns'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(campaigns.length === 0) {
            return (
                Views.entry({
                    title: 'No Campaigns Found',
                    subTitle: 'There are no campaigns available to view',
                    borderBottom: false
                })
            )
        }
        return campaigns.map((campaign, index) => {
            return (
                Views.entry({
                    key: index,
                    title: campaign.title,
                    subTitle: campaign.message,
                    bottomBorder: index !== campaigns.length - 1,
                    onClick: Utils.campaigns.details.bind(this, utils, campaign)
                })
            )
        });
    }

    const fetchCampaigns = async () => {
        try {
            let { campaigns, paging } = await Request.get(utils, '/campaigns/', {
                type: 'list',
                limit: limit,
                ...manager
            });
            setLoading(false);
            setPaging(paging);
            setCampaigns(campaigns.map(campaign => Campaign.create(campaign)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the campaigns list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading === 'init') {
            setLoading(true);
        }
        fetchCampaigns();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'campaigns', {
            onFetch: fetchCampaigns,
            onRemove: abstract => {
                setCampaigns(campaigns => {
                    return campaigns.filter(campaign => campaign.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setCampaigns(campaigns => {
                    return campaigns.map(campaign => abstract.compare(campaign));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Campaigns'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Campaign',
                style: 'default',
                onClick: onNewCampaign
            }],
            search: {
                placeholder: 'Search by campaign name or id...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ContactUser = ({ abstract, index, options, utils }) => {

    const layerID = `contact_user_${abstract.getID()}`

    const [edits, setEdits] = useState({ method: 'sms' });
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onDiscardClick = () => {
        utils.alert.show({
            title: 'Discard Content',
            message: 'Are you sure that you want to discard your content?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Discard',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setEdits({ method: 'sms' })
                    return;
                }
            }
        });
    }

    const onSendEmailClick = async () => {
        try {

            // verify that all required fields have been filled out
            await validateRequiredFields(getFields);

            // start loading and send message to server
            setLoading('submit');
            await Request.post(utils, '/user/', {
                ...edits,
                customer_user_id: abstract.getID(),
                type: 'new_email'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'Sent!',
                message: `Your email has been sent to ${abstract.object.full_name}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your email to ${abstract.object.full_name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSendSMSClick = async () => {
        try {

            // verify that all required fields have been filled out
            await validateRequiredFields(getFields);

            // start loading and send message to server
            setLoading('submit');
            await Request.post(utils, '/user/', {
                ...edits,
                customer_user_id: abstract.getID(),
                type: 'new_sms'
            });

            // end loading and show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'Sent!',
                message: `Your text message has been sent to ${abstract.object.full_name}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending your text message to ${abstract.object.full_name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits({ ...edits, ...props });
    }

    const getButtons = () => {
        if(edits.method === 'email') {
            return [{
                color: 'grey',
                key: 'discard',
                onClick: onDiscardClick,
                text: 'Discard'
            },{
                color: 'primary',
                key: 'send',
                loading: loading === 'submit',
                onClick: onSendEmailClick,
                text: 'Send Email'
            }];
        }
        return [{
            color: 'grey',
            key: 'discard',
            onClick: onDiscardClick,
            text: 'Discard'
        },{
            color: 'primary',
            key: 'send',
            loading: loading === 'submit',
            onClick: onSendSMSClick,
            text: 'Send Text Message'
        }];
    }

    const getEmailContentPreview = () => {
        return edits.method === 'email' && (
            <iframe 
            src={`${API.server}/system/templates/emails/preview.php?type=lead-message&client_id=${utils.client.get().id}&first_name=${abstract.object.first_name}&body=${edits.body || 'This%20is%20where%20the%20body%20content%20will%20appear'}`} 
            style={{
                border: 'none',
                borderRadius: 12,
                height: 350,
                marginBottom: 24,
                width: '100%'
            }}/>
        )
    }

    const getFields = () => {

        // prepare list field for method selection
        let items = [{
            component: 'list',
            description: 'This is how we will deliver your content to the customer.',
            key: 'method',
            items: [{
                id: 'email',
                title: 'Email'
            },{
                id: 'sms',
                title: 'Text Message'
            }],
            onChange: item => onUpdateTarget({ method: item && item.id }),
            props: { deselect: false },
            title: 'Method',
            value: edits.method === 'email' ? 'Email' : 'Text Message'
        }];

        // determine if the selected method is sms
        if(edits.method === 'sms') {
            return [{
                key: 'details',
                title: 'Details',
                items: items.concat([{
                    component: 'textfield',
                    description: 'This is the content that will appear in the text message.',
                    key: 'content',
                    onChange: text => onUpdateTarget({ content: text }),
                    title: 'Content',
                    value: edits.content
                }])
            }];
        }

        // fallback to email as the preferred method
        return [{
            key: 'details',
            title: 'Details',
            items: items.concat([{
                component: 'textfield',
                description: 'This is the content that will appear in the subject line of the email.',
                key: 'subject',
                onChange: text => onUpdateTarget({ subject: text }),
                title: 'Subject',
                value: edits.subject
            },{
                component: 'textview',
                description: 'This is the content that will appear in the body of the email.',
                key: 'body',
                onChange: text => onUpdateTarget({ body: text }),
                title: 'Body',
                value: edits.body
            }])
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Contact ${abstract.object.full_name}`}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>

            {getEmailContentPreview()}
            <LayerItem title={'Sending To'}>
                {Views.entry({
                    bottomBorder: false,
                    icon: {
                        path: abstract.object.avatar
                    },
                    subTitle: abstract.object.email_address || 'No email address provided',
                    title: abstract.object.full_name
                })}
            </LayerItem>

            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
            
        </Layer>
    )
}

export const DailyActiveUsers = ({ index, utils }) => {

    const panelID = 'dailyActiveUsers';
    const chartRef = useRef(null);

    const [chart, setChart] = useState(null);
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Daily Active Users',
                chart: chart,
                extendedFeatures: [ 'chart' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/users/', {
                    type: 'daily_active',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows the activity for daily active users sorted by time of day. The left side shows the number of users and the bottom shows the time of day. A daily active user is considered someone who logs into their account. Multiple logins are not recorded',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div style={{
                padding: 10
            }}>
                <div style={{
                    display: 'block',
                    height: 350
                }}>
                    {!chart || chart.labels.length < 3
                        ?
                        <NoDataFound message={'At least 3 entries worth of users activity is needed for the graph'}/>
                        :
                        <Line
                        ref={chartRef}
                        data={{
                            labels: chart.labels,
                            datasets: chart.dataset
                        }}
                        width={500}
                        height={100}
                        options={{
                            title: {
                                display: false
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (tooltipItem, data) => {
                                        return tooltipItem.yLabel + (parseInt(tooltipItem.yLabel) === 1 ? ' User' : ' Users');
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        display: true,
                                        fontColor: 'rgba(0,0,0,0)',
                                        stepSize: 1
                                    }
                                }]
                            }
                        }} />
                    }
                </div>
                <div className={'row p-0 m-0 mt-3'}>
                    {chart && chart.items.map((item, index) => {
                        return (
                            <div
                            key={item.key}
                            className={'col-12 col-md-4 p-1 text-center'}>
                                <div style={{
                                    ...Appearance.styles.unstyledPanel(),
                                    padding: 8
                                }}>
                                    <img
                                    src={`/images/${item.image}`}
                                    className={'my-1'}
                                    style={{
                                        width: '50%',
                                        maxWidth: 55,
                                        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
                                        borderRadius: '50%',
                                        backgroundColor: index === 1 ? Appearance.colors.secondary() : Appearance.colors.primary()
                                    }} />
                                    <span
                                    className={'d-block mt-2'}
                                    style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                    <span
                                    className={'d-block'}
                                    style={Appearance.textStyles.subTitle()}>{item.title}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const fetchDailyActive = async () => {
        try {
            let { today, yesterday } = await Request.get(utils, '/users/', {
                type: 'daily_active'
            });

            let data = [];
            let labels = [];
            let highest = 0;
            let highestTime = false;

            for(var i in today.grouped) {
                let count = today.grouped[i];
                labels.push(moment(i, 'YYYY-MM-DD HH').format('ha'));
                data.push(count);
                if(count > highest) {
                    highest = count;
                    highestTime = moment(i, 'YYYY-MM-DD HH').format('h a').toUpperCase();
                }
            }

            setLoading(false);
            setChart({
                labels: labels,
                dataset: [{
                    data: data,
                    fill: true,
                    borderColor: Appearance.colors.primary(),
                    backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    pointRadius: 5
                }],
                items: [{
                    key: 'yesterday',
                    title: 'Yesterdays\'s Active Users',
                    placeholder: 'Not Available',
                    value: isNaN(yesterday.total) ? null : yesterday.total.toString(),
                    image: 'calendar-increase-clear.png'
                },{
                    key: 'today',
                    title: 'Today\'s Active Users',
                    placeholder: 'Not Available',
                    value: isNaN(today.total) ? null : today.total.toString(),
                    image: 'popular-icon-clear.png'
                },{
                    key: 'highest',
                    title: 'Highest Traffic',
                    placeholder: 'Not Available',
                    value: highestTime,
                    image: 'traffic-time-clear.png'
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the daily active users breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchDailyActive();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Daily Active Users'}
        index={index}
        utils={utils}
        options={{
            removePadding: true,
            loading: loading,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const DriverAssignments = ({ index, utils }) => {

    const panelID = 'reservationAssignments';

    const [date, setDate] = useState(moment());
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useLoading();
    const [reservations, setReservations] = useState([]);
    const [showAdmin, setShowAdmin] = useState(false);

    const onAssistClick = key => {
        if(key === 'toggle-admin') {
            setShowAdmin(!showAdmin);
            return;
        }
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Driver Reservation Assignments',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onAssignReservation = async ({ driver, reservation }) => {
        try {
            setLoading(true);
            await Request.post(utils, '/reservation/', {
                type: 'driver_assign',
                driver_user_id: driver.user_id,
                reservation_id: reservation.id
            });
            setLoading(false);
            setReservations(reservations => {
                return reservations.map(prev => {
                    if(prev.id === reservation.id) {
                        prev.assigned_to = driver.user_id;
                    }
                    return prev;
                })
            });
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue assigning reservation #${reservation.id} to ${driver.full_name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onChangeDay = val => {
        setLoading(true);
        setDate(date => moment(date).add(val, 'days'));
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/reservations/', {
                    type: 'calendar',
                    on_date: moment(date).utc().unix(),
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onRemoveReservationAssignment = async ({ driver, reservation }) => {
        try {
            setLoading(true);
            await Request.post(utils, '/reservation/', {
                type: 'driver_unassign',
                driver_user_id: driver.user_id,
                reservation_id: reservation.id
            });
            setLoading(false);
            setReservations(reservations => {
                return reservations.map(prev => {
                    if(prev.id === reservation.id) {
                        prev.assigned_to = null;
                    }
                    return prev;
                })
            });
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing the assignment for reservation #${reservation.id}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onWarningClick = () => {
        utils.alert.show({
            title: 'Reservations',
            message: `We found ${getRemainingReservations().length} ${getRemainingReservations().length === 1 ? 'reservation' : 'reservations'} that are assigned to users that are not currently shown`,
            buttons: [{
                key: 'show',
                title: 'Show All Users',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'show') {
                    setLoading(true);
                    setShowAdmin(true);
                }
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'Assigning a reservation is quite easy. You can assign a reservation to a driver by dragging the reservation to their picture. You\'ll see their reservations ordered by the customer pickup time.',
            items: [{
                key: 'download',
                title: 'Download Assignments',
                style: 'default'
            },{
                key: 'toggle-admin',
                title: `${showAdmin ? 'Hide' : 'Show'} Administators`,
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        return [{
            key: 'back',
            title: 'Previous Day',
            style: 'secondary',
            onClick: onChangeDay.bind(this, -1)
        },{
            key: 'next',
            title: 'Next Day',
            style: 'primary',
            onClick: onChangeDay.bind(this, 1)
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 200,
                    padding: 20
                }}>
                    <NoDataFound
                    title={'Mobile Not Supported'}
                    message={'This feature is not available on mobile devices'} />
                </div>
            )
        }
        return (
            <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 12,
                    paddingBottom: 0
                }}>
                    {showAdmin !== true && getRemainingReservations().length > 0 && (
                        <img
                        src={'images/rejected-red-small.png'}
                        className={'text-button'}
                        onClick={onWarningClick}
                        style={{
                            ...Appearance.icons.standard(),
                            marginRight: 8
                        }}/>
                    )}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={Appearance.textStyles.title()}>{Utils.formatDate(date, true)}</span>
                        <span style={Appearance.textStyles.subTitle()}>{`${reservations.length} ${reservations.length === 1 ? ' Reservation' : ' Reservations'}`}</span>
                    </div>
                </div>
                <div style={{
                    padding: 12,
                    paddingBottom: 0
                }}>
                    <DatePickerField
                    utils={utils}
                    selected={date}
                    onDateChange={date => setDate(date)} />
                </div>
            </div>
            <DndProvider backend={HTML5Backend}>
                <Assignments
                drivers={drivers}
                onAssign={onAssignReservation}
                onUnassign={onRemoveReservationAssignment}
                reservations={reservations}
                utils={utils} />
            </DndProvider>
            </>
        )
    }

    const getRemainingReservations = () => {
        return reservations.filter(reservation => reservation.assigned_to && !drivers.find(driver => driver.user_id === reservation.assigned_to));
    }

    const fetchAssignments = async () => {
        try {
            let { events } = await Request.get(utils, '/reservations/', {
                type: 'calendar',
                on_date: moment(date).utc().unix()
            });
            setLoading(false);
            setReservations(events.map(reservation => Reservation.create(reservation)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the assignments list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchAllDrivers = async () => {
        try {
            let { drivers } = await fetchDrivers(utils, {show_admin: showAdmin});
            setLoading(false);
            setDrivers(drivers);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the assignments list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchAllDrivers();
    }, [showAdmin]);

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchAssignments();
    }, [date]);

    useEffect(() => {
        utils.content.subscribe(panelID, [ 'users', 'reservations' ], {
            onFetch: fetchAssignments,
            onRemove: abstract => {
                switch(abstract.type) {
                    case 'users':
                    fetchAssignments();
                    break;

                    case 'reservations':
                    setReservations(reservations => {
                        return reservations.filter(reservation => reservation.id !== abstract.getID());
                    });

                    default:
                    return;
                }
            },
            onUpdate: abstract => {
                switch(abstract.type) {
                    case 'users':
                    fetchAssignments();
                    break;

                    case 'reservations':
                    setReservations(reservations => {
                        return reservations.map(reservation => abstract.compare(reservation));
                    });

                    default:
                    return;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Driver Reservation Assignments'}
        index={index}
        utils={utils}
        options={{
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: getButtons(),
            loading: loading,
            removePadding: true,
            removeOverflow: true
        }}>
            {getContent()}
        </Panel>
    )
}

export const DriverOnboardingApps = ({ index, utils }) => {

    const panelID = `onboardingDriverApps`;
    const limit = 5;

    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `Driver Applications`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/driver/', {
                    type: 'onboarding_applications_admin',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all the driver applications that have been submitted. You can view the details of an application by clicking the Details button on the right.`,
            items: [{
                key: 'download',
                title: 'Download Applications',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(applications.length === 0) {
            return (
                Views.entry({
                    title: `No Driver Applications Found`,
                    subTitle: 'There are no applications available to view',
                    icon: {
                        path: 'images/user-icon-grey.png'
                    }
                })
            )
        }
        return applications.map((application, index) => {
            return (
                Views.entry({
                    key: index,
                    title: application.customer ? application.customer.full_name : 'Name Not Available',
                    subTitle: moment(application.date).format('[Submitted] MMMM Do, YYYY [at] h:mma'),
                    icon: {
                        path: application.customer ? application.customer.avatar : 'images/user-icon-grey.png'
                    },
                    badge: application.status,
                    bottomBorder: index !== applications.length - 1,
                    onClick: Utils.users.drivers.applications.details.bind(this, utils, application)
                })
            )
        });
    }

    const fetchApplications = async () => {
        try {
            let { applications, paging } = await Request.get(utils, '/driver/', {
                type: 'onboarding_applications_admin',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setApplications(applications.map(app => Driver.Application.create(app)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the driver applications list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchApplications();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'doApps', {
            onFetch: fetchApplications,
            onUpdate: abstract => {
                setApplications(applications => {
                    return applications.map(app => abstract.compare(app));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Driver Applications`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by application ID or customer name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const DriverPerformanceBreakdown = ({ index, utils }) => {

    const panelID = 'driverPerformanceBreakdown';
    const [chart, setChart] = useState(null);
    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useLoading();
    const [year, setYear] = useState(moment().format('YYYY'));
    const [years, setYears] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title:'Driver Tips and Feedback',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/users/', {
                    end_date: moment(`${year}-12-31`).utc().unix(),
                    start_date: moment(`${year}-01-01`).utc().unix(),
                    type: 'driver_performance',
                    user_id: driver ? driver.user_id : null,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This breakdown shows the overall performance for each Driver. Performance results include feedback from customers, driver NPS scores, and customer tips',
            items: [{
                key: 'download',
                title: 'Download Performance',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div
            className={'row m-0'}
            style={{
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg px-0 pt-0 pb-2 p-lg-0'}>
                    <UserLookupField
                    utils={utils}
                    placeholder={'Search for a driver by first or last name...'}
                    levels={[ User.level.admin, User.level.driver ]}
                    onChange={user => setDriver(user)}
                    style={{
                        maxWidth: Utils.isMobile() ? null : 250
                    }}/>
                </div>
                <div className={'col-12 col-lg-2 p-0 pl-lg-2'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    defaultValue={year}
                    onChange={evt => {
                        setLoading(true);
                        setYear(Utils.attributeForKey.select(evt, 'id'));
                    }}>
                        <option disabled={true}>{'Choose a Year'}</option>
                        {years.map((year, index) => {
                            return <option key={index} id={year}>{year}</option>
                        })}
                    </select>
                </div>
            </div>
            {getContentComponents()}
            </>
        )
    }

    const getContentComponents = () => {
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    padding: 12
                }}>
                    {chart && chart.data.map((entry, index, entries) => {
                        let fields = [{
                            title: 'Number of Responses',
                            value: entry.feedback || 0
                        },{
                            title: 'Promoters',
                            value: entry.promoters || 0
                        },{
                            title: 'Passive',
                            value: entry.passive || 0
                        },{
                            title: 'Detractors',
                            value: entry.detractors || 0
                        },{
                            title: 'NPS',
                            value: entry.nps || 0
                        },{
                            title: 'Tips',
                            value: Utils.toCurrency(entry.tips)
                        }];
                        return (
                            <div
                            key={index}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginBottom: index !== entries.length - 1 ? 8 : 0
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.title(),
                                    padding: '8px 12px 8px 12px',
                                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                                }}>{moment(entry.date, 'YYYY-MM').format('MMMM')}</span>
                                {fields.map((field, index) => {
                                    return (
                                        Views.row({
                                            key: index,
                                            label: field.title,
                                            bottomBorder: index !== fields.length - 1,
                                            value: field.value
                                        })
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )
        }
        return (
            <div style={{
                padding: 12
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        textAlign: 'left'
                    }}>
                        {labels.map((label, index) => {
                            return (
                                <span
                                key={index}
                                style={{
                                    ...Appearance.textStyles.supportingText(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`,
                                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                                    backgroundColor: Appearance.colors.divider()
                                }}>{label}</span>
                            )
                        })}
                    </div>
                    {chart && chart.data.map((entry, index) => {
                        return (
                            <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-between',
                                textAlign: 'left',
                                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                                backgroundColor: index % 2 === 0 ? Appearance.colors.panelBackground() : Appearance.colors.divider()
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.standard(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`
                                }}>{moment(entry.date, 'YYYY-MM').format('MMMM')}</span>
                                <span style={{
                                    ...Appearance.textStyles.standard(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`
                                }}>{entry.feedback}</span>
                                <span style={{
                                    ...Appearance.textStyles.standard(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`
                                }}>{entry.promoters}</span>
                                <span style={{
                                    ...Appearance.textStyles.standard(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`
                                }}>{entry.passive}</span>
                                <span style={{
                                    ...Appearance.textStyles.standard(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`
                                }}>{entry.detractors}</span>
                                <span style={{
                                    ...Appearance.textStyles.standard(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`
                                }}>{entry.nps}</span>
                                <span style={{
                                    ...Appearance.textStyles.standard(),
                                    width: '100%',
                                    padding: '8px 12px 8px 12px',
                                    borderRight: `1px solid ${Appearance.colors.divider()}`
                                }}>{Utils.toCurrency(entry.tips)}</span>
                            </div>
                        )
                    })}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        textAlign: 'left',
                        backgroundColor: chart && Object.keys(chart.data).length % 2 !== 0 ? Appearance.colors.divider() : Appearance.colors.panelBackground()
                    }}>
                        <span style={{
                            ...Appearance.textStyles.standard(),
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            borderRight: `1px solid ${Appearance.colors.divider()}`
                        }}>{'Total'}</span>
                        <span style={{
                            ...Appearance.textStyles.standard(),
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            borderRight: `1px solid ${Appearance.colors.divider()}`
                        }}>{getTotals('feedback')}</span>
                        <span style={{
                            ...Appearance.textStyles.standard(),
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            borderRight: `1px solid ${Appearance.colors.divider()}`
                        }}>{getTotals('promoters')}</span>
                        <span style={{
                            ...Appearance.textStyles.standard(),
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            borderRight: `1px solid ${Appearance.colors.divider()}`
                        }}>{getTotals('passive')}</span>
                        <span style={{
                            ...Appearance.textStyles.standard(),
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            borderRight: `1px solid ${Appearance.colors.divider()}`
                        }}>{getTotals('detractors')}</span>
                        <span style={{
                            ...Appearance.textStyles.standard(),
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            borderRight: `1px solid ${Appearance.colors.divider()}`
                        }}>{chart ? chart.nps : '...'}</span>
                        <span style={{
                            ...Appearance.textStyles.standard(),
                            width: '100%',
                            padding: '8px 12px 8px 12px',
                            borderRight: `1px solid ${Appearance.colors.divider()}`
                        }}>{Utils.toCurrency(getTotals('tips'))}</span>
                    </div>
                </div>
            </div>
        )
    }

    const getTotals = searchKey => {
        if(!chart) {
            return 0;
        }
        if(['promoters','passive','detractors'].includes(searchKey)) {
            return chart.data.reduce((total, entry) => total + entry[searchKey], 0);
        }
        return Object.keys(chart.data).reduce((total, key) => total + chart.data[key][searchKey], 0);
    }

    const fetchPerformance = async () => {
        try {
            setLoading(true);
            let { chart } = await Request.get(utils, '/users/', {
                end_date: moment(`${year}-12-31`).utc().unix(),
                start_date: moment(`${year}-01-01`).utc().unix(),
                type: 'driver_performance',
                user_id: driver ? driver.user_id : null
            });

            let { drivers } = await fetchDrivers(utils, { show_admin: true });
            setLoading(false);
            setChart(chart);
            setDrivers(drivers);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the schedule and availability list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupTarget = () => {
        var years = [];
        let dif = moment().diff(moment('2018-01-01'), 'years');
        for (let i = 0; i <= dif; i++) {
            years.push(moment().startOf('year').subtract(i, 'years').format('YYYY'));
        }
        setYears(years);
        setLabels(['Month', 'Customer Feedback', 'Promoters', 'Passive', 'Detractors', 'Net Promoter Score', 'Customer Tips']);
    }

    useEffect(() => {
        if(loading === 'init') {
            setLoading(true);
        }
        fetchPerformance();
    }, [year, driver]);

    useEffect(() => {
        setupTarget();
        utils.content.subscribe(panelID, 'users', {
            onFetch: fetchPerformance,
            onRemove: abstract => {
                setDrivers(drivers => {
                    return drivers.filter(driver => driver.user_id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setDrivers(drivers => {
                    return drivers.map(driver => {
                        if(driver.user_id !== abstract.getID()) {
                            return driver;
                        }
                        return {
                            ...driver,
                            ...abstract.object
                        };
                    })
                })
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Driver Tips and Feedback'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const DriverReservationBreakdown = ({ index, utils }) => {

    const panelID = 'driverReservationsBreakdown';
    const limit = 10;

    const [dates, setDates] = useState({ end_date: moment().endOf('week'), start_date: moment().startOf('week') });
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useLoading();
    const [paging, setPaging] = useState(null);
    const [offset, setOffset] = useState(0);
    const [searchText, setSearchText] = useState(null);
    const [showAdmin, setShowAdmin] = useState(false);

    const onAssistClick = key => {
        if(key === 'toggle-admin') {
            setLoading(true);
            setShowAdmin(!showAdmin);
            return;
        }
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Driver Reservations Breakdown',
                dates: dates,
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onChangeWeek = val => {
        setDrivers(drivers => {
            return drivers.map(driver => {
                driver.driver_breakdown = [];
                return driver;
            })
        });
        setDates(dates => {
            return {
                end_date: moment(dates.end_date).add(val, 'weeks'),
                start_date: moment(dates.start_date).add(val, 'weeks')
            }
        });
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/driver/', {
                    type: 'breakdown',
                    show_admin: showAdmin,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onFormatReservation = reservation => {
        return {
            key: reservation.id,
            title: `${reservation.customer.full_name} (${reservation.id})`,
            subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
            badge: reservation.status,
            icon: {
                path: reservation.customer.avatar
            }
        }
    }

    const onOverflowReservations = ({ date, driver, reservations }) => {

        const layerID = `driver-reservation-breakdown-overview-${driver.user_id}`;
        utils.layer.open({
            id: layerID,
            abstract: Abstract.create({
                type: 'object',
                object: {
                    date: date,
                    driver: driver,
                    reservations: reservations
                }
            }),
            Component: ({ abstract, index, options, utils }) => {
                return (
                    <Layer
                    id={layerID}
                    title={abstract.object.driver.full_name}
                    index={index}
                    options={{
                        ...options,
                        sizing: 'medium',
                        removePadding: true
                    }}>
                        <SearchableReservationsList
                        utils={utils}
                        format={onFormatReservation}
                        reservations={abstract.object.reservations || []}
                        onClick={Utils.reservations.details}/>
                    </Layer>
                )
            }
        })
    }

    const getAssistProps = () => {
        return {
            message: `This feature allows you to monitor a driver's overall activity throughout the week. Information for each driver will include how many reservations they have completed, their total distance driven, and the total amount of time spent driving.`,
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            },{
                key: 'toggle-admin',
                title: `${showAdmin ? 'Hide' : 'Show'} Administators`,
                style: 'default'
            }]
        }
    }

    const getBreakdownForDay = (reservations, index) => {
        return reservations.filter((reservation) => moment(dates.start_date).add(index, 'days').isSame(moment(reservation.pickup_date), 'day')).sort((a, b) => moment(a.pickup_date).unix() > moment(b.pickup_date).unix())
    }

    const getButtons = () => {
        return [{
            key: 'back',
            title: 'Previous Week',
            style: 'secondary',
            onClick: onChangeWeek.bind(this, -1)
        },{
            key: 'next',
            title: 'Next Week',
            style: 'primary',
            onClick: onChangeWeek.bind(this, 1)
        }]
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 200,
                    padding: 20
                }}>
                    <NoDataFound
                    title={'Mobile Not Supported'}
                    message={'This feature is not available on mobile devices'} />
                </div>
            )
        }
        return (
            <>
            <div
            className={'mb-2'}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                padding: 12,
                paddingBottom: 3
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={Appearance.textStyles.title()}>{`${Utils.formatDate(dates.start_date, true)} to ${Utils.formatDate(dates.end_date, true)}`}</span>
                    <span style={Appearance.textStyles.subTitle()}>{`${drivers.length} ${drivers.length === 1 ? ' Driver' : ' Drivers'}`}</span>
                </div>

                <DualDatePickerField
                utils={utils}
                blockStyle={'week'}
                selectedStartDate={dates.start_date}
                selectedEndDate={dates.end_date}
                style={{
                    maxWidth: 350
                }}
                onStartDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            start_date: date
                        }
                    })
                }}
                onEndDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            end_date: date
                        }
                    })
                }} />
            </div>
            <div style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div style={{
                    width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 8,
                        marginBottom: 8,
                        height: 20,
                        paddingLeft: 250
                    }}>
                        {getHeaders.map((header, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    width: (100 / getHeaders.length) + '%',
                                    textAlign: 'center'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle()
                                    }}>{header}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{
                        overflow: 'hidden',
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                    }}>
                        <div style={{
                            borderBottom: `1px solid ${Appearance.colors.divider()}`,
                            padding: 12
                        }}>
                            <TextField
                            icon={'search'}
                            placeholder={'Search by first or last name...'}
                            onChange={text => setSearchText(text && text.toLowerCase())} />
                        </div>
                        {getDrivers().filter((_, index) => {
                            if(paging && (index < offset || index > offset + limit)) {
                                return false;
                            }
                            return true;
                        }).map((driver, index, drivers) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    minHeight: 51,
                                    borderBottom: index !== drivers.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {Views.entry({
                                            title: driver.full_name,
                                            subTitle: driver.phone_number,
                                            bottomBorder: false,
                                            icon: {
                                                path: driver.avatar,
                                                onClick: Utils.users.details.bind(this, utils, driver)
                                            },
                                            style: {
                                                width: 250
                                            }
                                        })}
                                        {getDriverTotals(driver.driver_breakdown)}
                                    </div>
                                    {getHeaders.map((header, index) => {
                                        let reservations = getBreakdownForDay(driver.driver_breakdown, index);
                                        return (
                                            <div
                                            key={index}
                                            style={{
                                                padding: 5,
                                                width: (100 / 7) + '%',
                                                textAlign: 'center',
                                                minHeight: 51,
                                                borderLeft: `1px solid ${Appearance.colors.divider()}`
                                            }}>
                                                <div style={{
                                                    height: '100%'
                                                }}>
                                                    {reservations.map((reservation, i) => {
                                                        if(i === 2) {
                                                            return (
                                                                <div
                                                                key={i}
                                                                className={'text-button'}
                                                                style={{
                                                                    padding: '0px 5px 10px 5px',
                                                                    textAlign: 'center'
                                                                }}
                                                                onClick={onOverflowReservations.bind(this, {
                                                                    driver: driver,
                                                                    reservations: getBreakdownForDay(driver.driver_breakdown, index),
                                                                    date: moment(dates.start_date).add(index, 'days')
                                                                })}>
                                                                    <span style={{
                                                                        ...Appearance.textStyles.subTitle()
                                                                    }}>{`View ${reservations.length - 2} More`}</span>
                                                                </div>
                                                            )
                                                        }
                                                        if(i < 2) {
                                                            return getReservationEntry(reservation);
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            </>
        )
    }

    const getDrivers = () =>  {
        return searchText ? drivers.filter(driver => driver.full_name.toLowerCase().includes(searchText)) : drivers;
    }

    const getDriverTotals = reservations => {

        if(reservations.length === 0) {
            return null;
        }

        let revenue = reservations.map(reservation => {
            return reservation.cost;
        }).reduce((a, b) => {
            return a + b;
        }, 0);

        let distance = reservations.map(reservation => {
            return reservation.distance.real || 0;
        }).reduce((a, b) => {
            return a + b
        }, 0);
        let duration = reservations.map(reservation => {
            return reservation.duration.real || 0;
        }).reduce((a, b) => {
            return a + b;
        }, 0);

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 12,
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 2
                }}>{`${reservations.length} ${reservations.length === 1 ? 'Reservation' : 'Reservations'}`}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 2
                }}>{`${Utils.toCurrency(revenue)} in revenue`}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    marginBottom: 2
                }}>{`${Utils.distanceConversion(distance)} driven`}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle()
                }}>{Utils.parseDuration(duration)}</span>
            </div>
        )
    }

    const getHeaders = [0,1,2,3,4,5,6].map((i) => {
        return moment(dates.start_date).add(i, 'days').format('dddd Do')
    })

    const getReservationEntry = reservation => {

        let in_progress = reservation.status.code === Reservation.status.to_pickup || reservation.status.code === Reservation.status.to_destination;
        return (
            <div
            key={reservation.id}
            className={'text-button'}
            onClick={Utils.reservations.details.bind(this, utils, reservation)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: reservation.status.color,
                borderRadius: 5,
                marginBottom: 12,
                padding: 2,
                width: '100%'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    padding: '3px 6px 3px 6px'
                }}>{`Reservation #${reservation.id}`}</span>
                <div style={{
                    marginTop: 3,
                    borderBottomLeftRadius: 3.5,
                    borderBottomRightRadius: 3.5,
                    padding: '4px 6px 4px 6px',
                    backgroundColor: Appearance.colors.panelBackground()
                }}>
                    {in_progress
                        ?
                        <span style={{
                            display: 'block',
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.subText()
                        }}>{'In Progress'}</span>
                        :
                        <>
                        <span style={{
                            display: 'block',
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.subText()
                        }}>{Utils.toCurrency(reservation.cost)}</span>
                        <span style={{
                            display: 'block',
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.subText()
                        }}>{Utils.distanceConversion(reservation.distance.real)}</span>
                        <span style={{
                            display: 'block',
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.subText()
                        }}>{Utils.parseDuration(reservation.duration.real)}</span>
                        </>
                    }
                </div>
            </div>
        )
    }

    const fetchBreakdowns = async () => {
        try {
            let { users } = await Request.get(utils, '/driver/', {
                end_date: moment(dates.end_date).utc().unix(),
                show_admin: showAdmin,
                start_date: moment(dates.start_date).utc().unix(),
                type: 'breakdown'
            });

            let { drivers } = await fetchDrivers(utils, { show_admin: showAdmin });
            setLoading(false);
            setPaging({
                current_page: parseInt(offset / limit) + 1,
                number_of_pages: drivers.length > limit ? Math.ceil(drivers.length / limit) : 1,
                results: drivers.length
            });
            setDrivers(drivers.map(props => {
                let driver = User.create(props);
                let { driver_breakdown } = users.find(user => user.user_id === driver.user_id) || {};
                if(driver_breakdown) {
                    driver.driver_breakdown = driver_breakdown.map(reservation => Reservation.create(reservation));
                }
                return driver;
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the driver reservations breakdown list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        let drivers = getDrivers();
        setPaging({
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: drivers.length > limit ? Math.ceil(drivers.length / limit) : 1,
            results: drivers.length
        });
    }, [offset, searchText]);

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdowns();
    }, [dates, showAdmin]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'users', {
            onFetch: fetchBreakdowns,
            onRemove: abstract => {
                setDrivers(drivers => {
                    return drivers.filter(driver => driver.user_id !== abstract.getID())
                });
            },
            onUpdate: abstract => {
                setDrivers(drivers => {
                    return drivers.map(driver => {
                        if(driver.user_id !== abstract.getID()) {
                            return driver;
                        }
                        return {
                            ...driver,
                            ...abstract.object
                        };
                    })
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Driver Reservations Breakdown'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: getButtons(),
            paging: paging && {
                limit: limit,
                offset: offset,
                description: paging,
                onClick: setOffset
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const DriverScheduling = ({ index, utils }) => {

    const panelID = 'driverSchedules';
    const limit = 10;

    const [dates, setDates] = useState({ start: moment().startOf('week'), end: moment().endOf('week') });
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useLoading();
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [showAdmin, setShowAdmin] = useState(false);

    const onAssistClick = key => {
        if(key === 'toggle-admin') {
            setShowAdmin(!showAdmin);
            return;
        }
    }

    const onChangeWeek = val => {
        setDates(dates => {
            return {
                end: moment(dates.end).add(val, 'weeks'),
                start: moment(dates.start).add(val, 'weeks')
            }
        });
    }

    const onNewEntry = props => {
        let { end, entryID, driver, start } = props || {};
        utils.layer.open({
            id: `new-driver-timeslot-${driver.user_id}`,
            abstract: Abstract.create({
                type: 'users',
                object: driver
            }),
            Component: NewDriverTimeslot.bind(this, {
                start: start,
                end: end,
                prevEntry: entryID,
                onSelectTimes: onUpdateTimes.bind(this, props)
            })
        });
    }

    const onRemoveEntry = props => {
        utils.alert.show({
            title: 'Remove Timeslot',
            message: 'Are you sure that you want to remove this timeslot? This can not be undone',
            buttons: [{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'remove') {
                    onRemoveEntryConfirm(props);
                    return;
                }
            }
        })
    }

    const onRemoveEntryConfirm = async props => {
        try {
            
            setLoading(true);
            await Utils.sleep(1);

            let { entryID, date, driver } = props;
            await Request.post(utils, '/driver/', {
                date: moment(date).utc().unix(),
                id: entryID,
                type: 'schedule_delete',
                user_id: driver.user_id
            });

            setLoading(false);
            setDrivers(drivers => update(drivers, {
                $apply: drivers => drivers.map(d => {
                    if(d.user_id === driver.user_id) {
                        let dayIndex = d.schedule.findIndex(e => e.date === moment(date).format('YYYY-MM-DD'));
                        if(dayIndex >= 0) {
                            d.schedule[dayIndex].times = d.schedule[dayIndex].times.filter(t => {
                                return t.id !== entryID
                            })
                        }
                    }
                    return d;
                })
            }))
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the schedule for ${props.driver.full_name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onTimeSlotClick = (driver, timeslot) => {
        utils.sheet.show({
            items: [{
                key: 'change',
                title: 'Change',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'change') {
                onNewEntry({
                    entryID: timeslot.id,
                    start: moment(timeslot.start),
                    end: moment(timeslot.end),
                    date: moment(dates.start).add(offset, 'days'),
                    driver: driver
                });
                return;
            }
            if(key === 'remove') {
                onRemoveEntry({
                    entryID: timeslot.id,
                    date: moment(dates.start).add(offset, 'days'),
                    driver: driver
                });
                return;
            }
        })
    }

    const onUpdateTimes = async (props, times) => {
        try {
            if(!times.start || !times.end) {
                throw new Error('It looks like either the start time or end time is missing');
            }

            let { entryID, date, driver, start, end } = props || {};
            let startTime = moment(date).format('YYYY-MM-DD') + ' ' + times.start.format('HH:mm:ss');
            let endTime = moment(date).format('YYYY-MM-DD') + ' ' + times.end.format('HH:mm:ss');

            setLoading(true);
            await Utils.sleep(1);
            let { id } = await Request.post(utils, '/driver/', {
                date: moment(date).utc().unix(),
                end_time: moment(endTime).utc().unix(),
                id: entryID,
                start_time: moment(startTime).utc().unix(),
                type: 'schedule_add',
                user_id: driver.user_id
            });

            setLoading(false);
            setDrivers(drivers => update(drivers, {
                $apply: drivers => drivers.map(d => {
                    if(d.user_id === driver.user_id) {

                        let dayIndex = d.schedule.findIndex(e => e.date === moment(date).format('YYYY-MM-DD'));
                        if(dayIndex >= 0) {

                            // update an existing entry
                            if(entryID) {
                                for(var i in d.schedule[dayIndex].times) {
                                    if(d.schedule[dayIndex].times[i].id === entryID) {

                                        //console.log('updated');
                                        d.schedule[dayIndex].times[i].start = startTime;
                                        d.schedule[dayIndex].times[i].end = endTime;
                                        d.schedule[dayIndex].times.sort((a, b) => {
                                            return moment(a.start).unix() > moment(b.start).unix()
                                        })
                                        return d;
                                    }
                                }
                            }

                            // first entry ever
                            if(!d.schedule[dayIndex].times) {
                                d.schedule[dayIndex].times = [];
                            }
                            d.schedule[dayIndex].times.push({
                                id: id,
                                start: startTime,
                                end: endTime
                            });
                            d.schedule[dayIndex].times.sort((a, b) => {
                                return moment(a.start).unix() > moment(b.start).unix()
                            })
                            return d;
                        }

                        // first rntry for the day
                        if(!d.schedule) {
                            d.schedule = [];
                        }
                        d.schedule.push({
                            date: moment(date).format('YYYY-MM-DD'),
                            times: [{
                                id: id,
                                start: startTime,
                                end: endTime
                            }]
                        })
                    }
                    return d;
                })
            }));
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the schedule for ${props.driver.full_name}. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getAssistProps = () => {
        return {
            message: `This feature allows you to setup a driver's schedule and set their real and perferred availability. Their schedule can be auto-scheduled based on the driver's availability or manually scheduled by an administrative user.`,
            items: [{
                key: 'toggle-admin',
                title: `${showAdmin ? 'Hide' : 'Show'} Administators`,
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        return [{
            key: 'back',
            title: 'Previous Week',
            style: 'secondary',
            onClick: onChangeWeek.bind(this, -1)
        },{
            key: 'next',
            title: 'Next Week',
            style: 'primary',
            onClick: onChangeWeek.bind(this, 1)
        }]
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 200,
                    padding: 20
                }}>
                    <NoDataFound
                    title={'Mobile Not Supported'}
                    message={'This feature is not available on mobile devices'} />
                </div>
            )
        }
        return (
            <>
            <div
            className={'mb-2'}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                padding: 12,
                paddingBottom: 3
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={Appearance.textStyles.title()}>{`${Utils.formatDate(dates.start, true)} to ${Utils.formatDate(dates.end, true)}`}</span>
                    <span style={Appearance.textStyles.subTitle()}>{`${drivers.length} ${drivers.length === 1 ?  'Driver' : 'Drivers'}`}</span>
                </div>
                <DualDatePickerField
                utils={utils}
                blockStyle={'week'}
                selectedStartDate={dates.start}
                selectedEndDate={dates.end}
                style={{
                    maxWidth: 350
                }}
                onStartDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            start: date
                        }
                    });
                }}
                onEndDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            end: date
                        }
                    });
                }} />
            </div>
            <div style={{
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div style={{
                    width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 8,
                        marginBottom: 8,
                        height: 20,
                        paddingLeft: 225
                    }}>
                        {getHeaders.map((header, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    width: (100 / getHeaders.length) + '%',
                                    textAlign: 'center'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle()
                                    }}>{header}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{
                        overflow: 'hidden',
                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                    }}>
                        <div style={{
                            borderBottom: `1px solid ${Appearance.colors.divider()}`,
                            padding: 12
                        }}>
                            <TextField
                            icon={'search'}
                            placeholder={'Search by first or last name...'}
                            onChange={text => setSearchText(text && text.toLowerCase())} />
                        </div>
                        {getDrivers().filter((_, index) => {
                            if(paging && (index < offset || index > offset + limit)) {
                                return false;
                            }
                            return true;
                        }).map((driver, index, drivers) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    minHeight: 51
                                }}>
                                    {Views.entry({
                                        title: driver.full_name,
                                        subTitle: driver.phone_number,
                                        ...getDurationComponents(driver),
                                        bottomBorder: index !== drivers.length - 1,
                                        icon: {
                                            path: driver.avatar,
                                            onClick: Utils.users.details.bind(this, utils, driver)
                                        },
                                        style: {
                                            width: 250
                                        }
                                    })}
                                    {getHeaders.map((header, i) => {
                                        let entries = driver.schedule && getDriverScheduleEntries({
                                            offset: i,
                                            driver: driver,
                                            dates: dates,
                                        });
                                        return (
                                            <div
                                            key={i}
                                            style={{
                                                padding: 5,
                                                width: (100 / 7) + '%',
                                                textAlign: 'center',
                                                minHeight: 51,
                                                borderLeft: `1px solid ${Appearance.colors.divider()}`,
                                                borderBottom: index !== drivers.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                            }}>
                                                <div
                                                className={entries.length === 0 ? `view-entry ${window.theme}` : ''}
                                                style={{
                                                    height: '100%'
                                                }}
                                                onClick={entries.length > 0 ? null : onNewEntry.bind(this, {
                                                    driver: driver,
                                                    date: moment(dates.start).add(i, 'days')
                                                })}>
                                                    {getDriverScheduleEntryComponents(driver, entries)}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            </>
        )
    }

    const getDrivers = () =>  {
        return searchText ? drivers.filter(driver => driver.full_name.toLowerCase().includes(searchText)) : drivers;
    }

    const getDriverScheduleDuration = ({ driver }) => {
        let seconds = driver.schedule.reduce((total, entry) => {
            return total + entry.times.reduce((t, time) => {
                return t + (moment(time.end).unix() - moment(time.start).unix());
            }, 0);
        }, 0);
        return seconds ? Utils.parseDuration(seconds) : null;
    }

    const getDriverScheduleEntries = ({ offset, dates, driver }) => {
        // filter for selected day and return matches
        return driver.schedule.filter(e => {
            return e.date === moment(dates.start).add(offset, 'days').format('YYYY-MM-DD') && e.times && e.times.length > 0
        });
    }

    const getDriverScheduleEntryComponents = (driver, entries) => {
        return entries.map(entry => {
            return (
                <div
                key={offset}
                style={{
                    position: 'relative'
                }}>
                    {entry.times.map((timeslot, index) => {
                        return (
                            <div
                            key={index}
                            className={'text-button'}
                            onClick={onTimeSlotClick.bind(this, driver, timeslot)}
                            style={{
                                borderRadius: 4,
                                padding: '5px 7px 5px 7px',
                                textAlign: 'center',
                                backgroundColor: Appearance.colors.primary(),
                                marginBottom: index == entry.times.length - 1 ? 3 : 5
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    display: 'block',
                                    color: 'white'
                                }}>{`${moment(timeslot.start).format('h:mma')} to ${moment(timeslot.end).format('h:mma')}`}</span>
                            </div>
                        )
                    })}
                    <img
                    className={'image-button'}
                    src={'images/new-icon-grey-small.png'}
                    onClick={onNewEntry.bind(this, {
                        date: moment(dates.start).add(offset, 'days'),
                        driver: driver
                    })}
                    style={{
                        opacity: 0.5,
                        width: 17,
                        height: 17
                    }}/>
                </div>
            )
        })
    }

    const getDurationComponents = driver => {
        let duration = getDriverScheduleDuration({ driver: driver });
        return {
            supportingTitle: duration ? `Scheduled for ${duration}` : null,
            textStyles: {
                supportingTitle: {
                    color: duration > 40 ? Appearance.colors.red : Appearance.colors.grey()
                }
            }
        }
    }

    const getHeaders = [0,1,2,3,4,5,6].map((i) => {
        return moment(dates.start).add(i, 'days').format('dddd Do')
    });

    const fetchSchedules = async () => {
        try {
            let response = await Request.get(utils, '/driver/', {
                end_date: moment(dates.end).utc().unix(),
                show_admin: showAdmin,
                start_date: moment(dates.start).utc().unix(),
                type: 'schedules'
            });

            let { drivers } = await fetchDrivers(utils, {
                show_admin: showAdmin,
                show_availability: true,
                show_schedules: {
                    end_date: dates.end.format('YYYY-MM-DD'),
                    start_date: dates.start.format('YYYY-MM-DD')
                }
            });

            setLoading(false);
            setPaging({
                results: drivers.length,
                current_page: parseInt(offset / limit) + 1,
                number_of_pages: drivers.length > limit ? Math.ceil(drivers.length / limit) : 1
            });
            setDrivers(response.map(entry => {
                return {
                    ...drivers.find(driver => {
                        return driver.user_id === entry.user_id;
                    }),
                    ...entry
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the schedule and availability list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchSchedules();
    }, [dates, showAdmin]);

    useEffect(() => {
        let drivers = getDrivers();
        setPaging({
            results: drivers.length,
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: drivers.length > limit ? Math.ceil(drivers.length / limit) : 1
        });
    }, [offset, searchText]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'users', {
            onFetch: fetchSchedules,
            onRemove: abstract => {
                setDrivers(drivers => {
                    return drivers.filter(driver => driver.user_id !== abstract.getID())
                });
            },
            onUpdate: abstract => {
                setDrivers(drivers => {
                    return drivers.map(driver => {
                        if(driver.user_id !== abstract.getID()) {
                            return driver;
                        }
                        return {
                            ...driver,
                            ...abstract.object
                        };
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID)
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Driver Scheduling and Availability'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: getButtons(),
            paging: paging && {
                limit: limit,
                offset: offset,
                description: paging,
                onClick: setOffset
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const MobileAppVersions = ({ index, utils }) => {

    const panelID = 'mobileAppVersions';
    const chartRef = useRef(null);
    const limit = 5;

    const [chart, setChart] = useState(null);
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Mobile App Versions Breakdown',
                chart: chartRef,
                extendedFeatures: [ 'chart' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/users/', {
                    type: 'version_report',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: `This version report shows how many users are on a specific version of the ${utils.client.get().name} mobile app. This area requires at least 2 mobile app updates to produce a breakdown`,
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div style={{
                padding: 10
            }}>
                <div style={{
                    display: 'block',
                    height: 350
                }}>
                    {!chart || chart.labels.length < 3
                        ?
                        <NoDataFound message={'Insufficient Data for Mobile App Versions'} />
                        :
                        <Line
                        ref={chartRef}
                        width={500}
                        height={100}
                        data={{
                            labels: chart.labels,
                            datasets: [{
                                data: chart.ios,
                                fill: false,
                                label: 'iOS Users',
                                pointBackgroundColor: 'white',
                                pointBorderWidth: 2,
                                pointRadius: 5,
                                borderColor: '#4A90E2',
                                backgroundColor: Utils.hexToRGBA('#4A90E2', 0.25),
                            },{
                                fill: false,
                                data: chart.android,
                                label: 'Android Users',
                                pointBackgroundColor: 'white',
                                pointBorderWidth: 2,
                                pointRadius: 5,
                                borderColor: '#6CBF66',
                                backgroundColor: Utils.hexToRGBA('#6CBF66', 0.25)
                            }]
                        }}
                        options={{
                            legend: {
                                display: true
                            },
                            title: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                               mode: 'label',
                               label: 'mylabel',
                               callbacks: {
                                   label: function(tooltipItem, data) {
                                       return tooltipItem.yLabel + (tooltipItem.datasetIndex == 0 ? ' iOS' : ' Android') + (tooltipItem.yLabel === 1 ? ' User' : ' Users');
                                   },
                               },
                            },
                            scales: {
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }],
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    }
                                }]
                            }
                        }} />
                    }
                </div>
            </div>
        )
    }

    const fetchVersions = async () => {
        try {
            let { versions } = await Request.get(utils, '/users/', {
                type: 'version_report'
            });

            setLoading(false);
            setChart({
                labels: versions.map(entry => entry.version),
                ios: versions.map(entry => entry.ios),
                android: versions.map(entry => entry.android)
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the mobile app versions breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchVersions();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Mobile App Versions Breakdown'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const MobileDeviceBreakdown = ({ index, utils }) => {

    const panelID = 'mobileDeviceBreakdown';
    const chartRef = useRef(null);

    const [chart, setChart] = useState(null);
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Mobile Device Breakdown',
                chart: chartRef,
                extendedFeatures: [ 'chart' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/users/', {
                    type: 'device_breakdown',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This chart shows the breakdown of device type for all eCarra users. It is sorted by iOS, Android, and Customer and/or Admin Portal (Web)',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(!chart) {
            return (
                <div style={{
                    minHeight: 270,
                    position: 'relative'
                }}>
                    <NoDataFound message={'Insufficient Data for Mobile Devices'} />
                </div>
            )
        }
        return (
            <div style={{
                minHeight: 270,
                position: 'relative'
            }}>
                <div
                className={'chart active-users-chart'}
                style={{
                    height: 270
                }}>
                    <Bar
                    ref={chartRef}
                    width={500}
                    height={100}
                    data={{
                        labels: [ 'Web', 'Android', 'iOS' ],
                        datasets: [{
                            data: chart ? [ chart.other, chart.android, chart.ios ] : [],
                            borderWidth: 2,
                            borderColor: ({ dataIndex }) => {
                                return getDeviceColor(dataIndex);
                            },
                            backgroundColor: ({ dataIndex }) => {
                                let color = getDeviceColor(dataIndex);
                                return Utils.hexToRGBA(color, 0.5);
                            }
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: item => `${item.yLabel} ${parseInt(item.yLabel) === 1 ? 'User' : 'Users'}`
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: value => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                </div>
            </div>
        )
    }

    const getDeviceColor = index => {
        let colors = [ '#4A4A4A', '#6CBF66', '#4A90E2' ];
        return colors[index];
    }

    const fetchDevices = async () => {
        try {
            let { results } = await Request.get(utils, '/users/', {
                type: 'device_breakdown'
            });
            setLoading(false);
            setChart(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the device breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    useEffect(() => {
        fetchDevices();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Mobile Device Breakdown'}
        className={'col-12 col-xl-6'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const NotificationReachability = ({ index, utils }) => {

    const panelID = 'notificationReachability';
    const chartRef = useRef(null);

    const [chart, setChart] = useState(null);
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Notification Reachability',
                chart: chartRef,
                extendedFeatures: [ 'chart' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/users/', {
                    type: 'push_engagement',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This chart shows the amount of eCarra users that have push notifications enabled for their mobile app',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getColor = index => {
        let colors = [ Appearance.colors.grey(), Appearance.colors.primary() ];
        return colors[index];
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(!chart) {
            return (
                <div style={{
                    minHeight: 270,
                    position: 'relative'
                }}>
                    <NoDataFound message={'Insufficient Data for Push Notifications'} />
                </div>
            )
        }
        return (
            <div style={{
                minHeight: 270,
                position: 'relative'
            }}>
                <div
                className={'chart active-users-chart'}
                style={{
                    height: 270
                }}>
                    <Bar
                    ref={chartRef}
                    width={500}
                    height={100}
                    data={{
                        labels: [ 'Not Enabled', 'Enabled' ],
                        datasets: [{
                            data: chart ? [ chart.not_enabled, chart.enabled ] : [],
                            borderWidth: 2,
                            borderColor: ({ dataIndex }) => {
                                return getColor(dataIndex);
                            },
                            backgroundColor: ({ dataIndex }) => {
                                let color = getColor(dataIndex);
                                return Utils.hexToRGBA(color, 0.5);
                            }
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: item => `${item.yLabel} ${parseInt(item.yLabel) === 1 ? 'User' : 'Users'}`
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: value => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                </div>
            </div>
        )
    }

    const fetchReachability = async () => {
        try {
            let { results } = await Request.get(utils, '/users/', {
                type: 'push_engagement'
            });
            setLoading(false);
            setChart(results);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the notification reachability breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReachability();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Notification Reachability'}
        className={'col-12 col-xl-6'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SaaSAccounts = ({ index, utils }) => {

    const panelID = 'allSaaSAccounts';
    const limit = 5;

    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'SaaS Client Accounts',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/saas/',  {
                    type: 'accounts',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewAccount = () => {
        // TODO => backend needs to be updated to support automated client creation
        utils.alert.dev();
        return;
        /*
        utils.layer.open({
            id: 'new-saas-client-account',
            abstract: Abstract.create({
                type: 'saas',
                object: SaasAccount.new()
            }),
            Component: AddEditSaaSClientAccount.bind(this, {
                isNewTarget: true
            })
        });
        */
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'These are all the SaaS client accounts in the system. These each represent individual clients that utilize the eCarra platform.',
            items: [{
                key: 'download',
                title: 'Download Client Accounts',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(accounts.length === 0) {
            return (
                Views.entry({
                    title: 'No Accounts Found',
                    subTitle: 'There are no accounts available to view',
                    borderBottom: false
                })
            )
        }
        return accounts.map((account, index) => {
            return (
                Views.entry({
                    key: account.id,
                    title: account.name,
                    subTitle: account.tagline,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: account.logos.mobile
                    },
                    badge: {
                        text: account.active ? null : 'Deactivated',
                        color: Appearance.colors.red
                    },
                    bottomBorder: index !== accounts.length - 1,
                    onClick: Utils.saas.details.bind(this, utils, account)
                })
            )
        });
    }

    const fetchAccounts = async () => {
        try {
            let { accounts, paging } = await Request.get(utils, '/saas/',  {
                type: 'accounts',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setAccounts(accounts.map(act => SaasAccount.create(act)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the accounts list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchAccounts();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'SaaS Clients'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Client Account',
                style: 'default',
                onClick: onNewAccount
            }],
            search: {
                placeholder: 'Search by account name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SaaSEmissionsOverview = ({ index, utils }) => {

    const panelID = 'emissionsOverview';
    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [manager, setManager, formatResults] = useResultsManager({ end_date: moment(), start_date: moment().subtract(1, 'years') });
    const [emissions, setEmissions] = useState(null);
    const [loading, setLoading] = useLoading();

    const getAssistProps = () => {
        return {
            message: 'These are the emission savings for the current year. These savings include saved gallons of gas, trees planted, and grams of carbon reduced for all of the completed rides. You can adjust the date for the overview by selecting a new start or end date on the right'
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-0 pt-0 pb-2 p-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    value={account ? account.name : 'Choose an Account'}
                    style={{
                        width: Utils.isMobile() ? '100%' : 200
                    }}
                    onChange={evt => {
                        let id = Utils.attributeForKey.select(evt, 'id');
                        setAccount(accounts.find(act => act.id === parseInt(id)));
                    }}>
                        <option disabled={true}>{'Choose an Account'}</option>
                        {accounts.map((account, index) => {
                            return (
                                <option key={index} id={account.id}>{account.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }} />
                    </div>
                </div>
            </div>
            <div className={'row p-1 m-0'}>
                {getItems().map((item) => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-sm-6 col-lg-3 p-2'}>
                            <div style={Appearance.styles.unstyledPanel()}>
                                <div className='card-body p-2 text-center'>
                                    <img
                                    src={`/images/${item.image}`}
                                    className={'my-1'}
                                    style={{
                                        width: '50%',
                                        maxWidth: 40,
                                        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
                                        borderRadius: '50%',
                                        backgroundColor: Appearance.colors.primary()
                                    }} />
                                    <span
                                    className={'d-block mt-2'}
                                    style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                    <span
                                    className={'d-block'}
                                    style={Appearance.textStyles.subTitle()}>{item.title}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const getItems = () => {
        return [{
            key: 'trees',
            title: 'Trees Planted',
            placeholder: '0',
            value: emissions ? emissions.trees : null,
            image: 'trees-icon-clear.png'
        },{
            key: 'gas',
            title: 'Gallons of Gas',
            placeholder: '0',
            value: emissions ? emissions.gas : null,
            image: 'gas-icon-clear.png'
        },{
            key: 'carbon',
            title: 'Grams of CO2',
            placeholder: '0',
            value: emissions ? emissions.carbon : null,
            image: 'carbon-icon-clear.png'
        },{
            key: 'distance',
            title: 'Miles Driven',
            placeholder: '0',
            value: emissions ? emissions.distance : null,
            image: 'miles-driven-icon-clear.png'
        }]
    }

    const fetchAccounts = async () => {
        try {
            let { accounts } = await fetchSaaSAccounts(utils);
            setAccounts(accounts);
            setAccount(accounts.length > 0 ? accounts[0] : null)

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the list of saas clients. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchEmissions = async () => {
        if(!account) {
            return;
        }
        try {
            let { carbon, distance, gas, trees } = await Request.get(utils, '/saas/',  {
                type: 'emissions',
                app_id: account.client_id,
                ...formatResults(utils)
            });

            let mil = parseFloat(carbon.values.year) > 1000000;
            setLoading(false);
            setEmissions({
                trees: trees.values.year,
                gas: parseFloat(gas.values.year).toFixed(1),
                carbon: parseFloat(carbon.values.year / (mil ? 1000000 : 1000)).toFixed(mil ? 1:0) + (mil ? 'M' : 'K'),
                distance: parseFloat(distance.toFixed(0)).toLocaleString()
            });
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the emissions overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchEmissions();
    }, [account, manager]);

    useEffect(() => {
        fetchAccounts();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'SaaS Client Emissions Overview'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SaaSPerformanceOverview = ({ index, utils }) => {

    const panelID = 'saasPerformanceOverview';
    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ end_date: moment(), start_date: moment().subtract(1, 'years') });
    const [payments, setPayments] = useState(null);
    const [reservations, setReservations] = useState(null);
    const [users, setUsers] = useState(null);

    const getAssistProps = () => {
        return {
            message: 'This overview shows the total reservations booked, the payment totals, and the new user accounts that have been created from the beginning of the month to today'
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-0 pt-0 pb-2 p-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    value={account ? account.name : 'Choose an Account'}
                    style={{
                        width: Utils.isMobile() ? '100%' : 200
                    }}
                    onChange={evt => {
                        let id = Utils.attributeForKey.select(evt, 'id');
                        setAccount(accounts.find(act => act.id === parseInt(id)));
                    }}>
                        <option disabled={true}>{'Choose an Account'}</option>
                        {accounts.map((account, index) => {
                            return (
                                <option key={index} id={account.id}>{account.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }} />
                    </div>
                </div>
            </div>
            <div className={'row p-1 m-0'}>
                {getItems().map(item => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-md-4 p-2 m-0 text-center'}>
                            <div
                            className={'px-0 py-2'}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                height: 185,
                                position: 'relative'
                            }}>
                                <div
                                className={'pr-2'}
                                style={{
                                    height: 125
                                }}>
                                    {!item.chart || item.chart.labels.length < 3
                                        ?
                                        <NoDataFound
                                        title={item.title}
                                        message={'Insufficient Data'} />
                                        :
                                        <Line
                                        width={500}
                                        height={125}
                                        data={{
                                            labels: item.chart.labels,
                                            datasets: [{
                                                borderColor: Appearance.colors.primary(),
                                                fill: false,
                                                pointRadius: 0,
                                                data: item.chart.data
                                            }]
                                        }}
                                        options={{
                                            title: {
                                                display: false
                                            },
                                            legend: {
                                                display: false
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        color: Appearance.colors.transparent,
                                                        display: false
                                                    },
                                                    ticks: {
                                                        display: false
                                                    }
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        color: Appearance.colors.transparent,
                                                        display: false
                                                    },
                                                    ticks: {
                                                        display: false
                                                    }
                                                }]
                                            }
                                        }} />
                                    }
                                </div>
                                {item.chart && item.chart.data.length > 3 && (
                                    <>
                                    <span className={'d-block mt-2'} style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                    <span className={'d-block'} style={Appearance.textStyles.subTitle()}>{item.title}</span>
                                    </>
                                )}
                            </div>
                            <div
                            id={`overview-${item.key}-none-found`}
                            className={'text-center w-100'}
                            style={{
                                display: 'none',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 40
                            }}>
                              <img
                              src={'images/no-data-found.png'}
                              className={'no-data-found'}
                              style={{
                                  height: '100%',
                                  maxWidth: 75,
                                  objectFit: 'contain'
                              }} />
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const getItems = () => {
        return [{
            key: 'reservations',
            title: 'Reservations',
            placeholder: '0',
            value: reservations ? reservations.total : null,
            chart: reservations
        },{
            key: 'payments',
            title: 'Payments',
            placeholder: '$0.00',
            value: payments ? Utils.toCurrency(payments.total) : null,
            chart: payments
        },{
            key: 'users',
            title: 'New User Accounts',
            placeholder: '0',
            value: users ? users.total : null,
            chart: users
        }]
    }

    const fetchAccounts = async () => {
        try {
            let { accounts } = await fetchSaaSAccounts(utils);
            setAccounts(accounts);
            setAccount(accounts.length > 0 ? accounts[0] : null)

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the list of saas clients. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchOverview = () => {
        fetchPayments();
        fetchReservations();
        fetchUsers();
    }

    const fetchPayments = async () => {
        if(!account) {
            return;
        }
        try {
            let { data } = await Request.get(utils, '/saas/',  {
                type: 'payment_performance_overview',
                app_id: account.client_id,
                ...formatResults(utils)
            });
            setLoading(false);
            setPayments({
                total: data.reduce((t, d) => t += d.total, 0),
                labels: data.map(d => d.date),
                data: data.map(d => d.total)
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payments overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchReservations = async () => {
        if(!account) {
            return;
        }
        try {
            let { data } = await Request.get(utils, '/saas/',  {
                type: 'reservation_performance_overview',
                app_id: account.client_id,
                ...formatResults(utils)
            });
            setLoading(false);
            setReservations({
                total: data.reduce((t, d) => t += d.total, 0),
                labels: data.map(d => d.date),
                data: data.map(d => d.total)
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the reservations overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchUsers = async () => {
        if(!account) {
            return;
        }
        try {
            let { data } = await Request.get(utils, '/saas/',  {
                type: 'user_performance_overview',
                app_id: account.client_id,
                ...formatResults(utils)
            });
            setLoading(false);
            setUsers({
                total: data.reduce((t, d) => t += d.total, 0),
                labels: data.map(d => d.date),
                data: data.map(d => d.total)
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the users overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOverview();
    }, [account, manager]);

    useEffect(() => {
        fetchAccounts();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'SaaS Client Performance Overview'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SaaSReport = ({ category }, { index, utils }) => {

    const chartRef = useRef(null);
    const panelID = `saas${Utils.ucFirst(category)}Report`;

    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState(null);
    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(1, 'years') });
    const [filter, setFilter] = useState(null);
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `SaaS ${getInfo().title}`,
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/saas/', {
                    type: getInfo().endpoint,
                    modifier: filter || (getInfo().options.items ? getInfo().options.items[0].key : null),
                    account_id: account ? account.id : null,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: `This graph shows the ${category} report for the selected account within the specified date range. The date range defaults to 6 months in the past to today's date`,
            items: [{
                key: 'download',
                title: 'Download Report',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div
            className={'row p-0 m-0'}
            style={{
                padding: 10
            }}>
                <div
                className={'col-12 col-xl-8 p-3'}
                style={{
                    height: 350,
                    borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
                }}>
                    {!chart || chart.labels.length < 3
                        ?
                        <NoDataFound message={'At least 3 entries worth of client activity is needed for the graph'}/>
                        :
                        <Line
                        ref={chartRef}
                        width={400}
                        height={100}
                        data={{
                            labels: chart.labels,
                            datasets: chart.dataset
                        }}
                        options={{
                            title: {
                                display: false
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: item => {
                                        if(category === 'payments') {
                                            return Utils.toCurrency(item.yLabel);
                                        }
                                        let label = '';
                                        switch(category) {
                                            case 'reservations':
                                            label = (parseInt(item.yLabel) === 1 ? 'Reservation' :' Reservations');
                                            break;
                                            case 'users':
                                            label = (parseInt(item.yLabel) === 1 ? 'New User' : 'New Users');
                                            break;
                                        }
                                        return parseInt(item.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + label;
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: value => Utils.numberFormat(value)
                                    }
                                }]
                            }
                        }}/>
                    }
                </div>
                <div
                className={'col-12 col-xl-4'}
                style={{
                    padding: '8px 12px 8px 12px',
                    borderLeft: Utils.isMobile() ? null : `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div style={{
                        marginBottom: 8
                    }}>
                        <span style={Appearance.textStyles.title()}>{'Account'}</span>
                        <select
                        className={`mt-1 mb-3 custom-select ${window.theme}`}
                        value={account ? account.name : 'Choose an Account'}
                        onChange={evt => {
                            let id = Utils.attributeForKey.select(evt, 'id');
                            setAccount(accounts.find(act => act.id === parseInt(id)));
                        }}>
                            <option disabled={true}>{'Choose an Account'}</option>
                            {accounts.map((account, index) => {
                                return (
                                    <option key={index} id={account.id}>{account.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    {getInfo().options.items && (
                        <div style={{
                            marginBottom: 8
                        }}>
                            <span style={Appearance.textStyles.title()}>{getInfo().options.title}</span>
                            <select
                            className={`mt-1 mb-3 custom-select ${window.theme}`}
                            onChange={evt => {
                                let id = Utils.attributeForKey.select(evt, 'id');
                                setFilter(id);
                            }}>
                                {getInfo().options.items.map((option, index) => {
                                    return (
                                        <option key={index} id={option.key}>{option.title}</option>
                                    )
                                })}
                            </select>
                        </div>
                    )}
                    <div style={{
                        marginBottom: 8
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            marginBottom: '.25rem'
                        }}>{'Date Range'}</span>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={dates.start_date}
                        selectedEndDate={dates.end_date}
                        style={{
                            width: '100%'
                        }}
                        fieldStyle={{
                            width: '100%'
                        }}
                        onStartDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    start_date: date
                                }
                            })
                        }}
                        onEndDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    end_date: date
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
        )
    }

    const getInfo = () => {
        switch(category) {
            case 'payments':
            return {
                endpoint: 'payment_performance',
                title: 'Payments Breakdown',
                options: {
                    title: 'Transaction Outcome',
                    items: [{
                        key: 'date',
                        title: 'Completed'
                    },{
                        key: 'refunded',
                        title: 'Refunded'
                    }]
                }
            }

            case 'reservations':
            return {
                endpoint: 'reservation_performance',
                title: 'Reservations Breakdown',
                filter: 1,
                options: {
                    title: 'Status Type',
                    items: [{
                        key: 1,
                        title: 'Approved'
                    },{
                        key: 0,
                        title: 'Rejected'
                    },{
                        key: 3,
                        title: 'Cancelled'
                    },{
                        key: 4,
                        title: 'Started'
                    },{
                        key: 5,
                        title: 'Picked Up'
                    },{
                        key: 6,
                        title: 'Completed'
                    },{
                        key: 13,
                        title: 'Edited by Customer'
                    }]
                }
            }

            case 'users':
            return {
                endpoint: 'user_performance',
                title: 'New Users Breakdown',
                options: {}
            }

            default:
            return {
                title: 'SaaS Breakdown',
                options: {}
            }
        }
    }

    const fetchAccounts = async () => {
        try {
            let { accounts } = await fetchSaaSAccounts(utils);
            setAccounts(accounts);
            setAccount(accounts.length > 0 ? accounts[0] : null)

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the list of saas clients. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchPerformance = async () => {
        try {
            if(!getInfo().endpoint || !account) {
                return;
            }
            let { client } = await Request.get(utils, '/saas/', {
                type: getInfo().endpoint,
                modifier: filter || (getInfo().options.items ? getInfo().options.items[0].key : null),
                account_id: account ? account.id : null,
                start_date: moment(dates.start_date).utc().unix(),
                end_date: moment(dates.end_date).utc().unix()
            });

            setLoading(false);
            setChart({
                labels: client.performance.map(e => {
                    return moment(e.date, 'YYYY-MM').format('MMM YYYY')
                }),
                dataset: [{
                    fill: true,
                    pointRadius: 5,
                    pointBorderWidth: 2,
                    label: client.name,
                    pointBackgroundColor: 'white',
                    borderColor: category === 'payments' ? Appearance.colors.secondary() : Appearance.colors.primary(),
                    backgroundColor: Utils.hexToRGBA(category === 'payments' ? Appearance.colors.secondary() : Appearance.colors.primary(), 0.25),
                    data: client.performance.map(entry => entry.total)
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${category.toLowerCase()} report. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupFilters = () => {
        let items = getInfo().options.items;
        setFilter(items ? items[0].key : null);
    }

    useEffect(() => {
        if(account && loading !== 'init') {
            setLoading(true);
        }
        fetchPerformance();
    }, [account, dates, filter]);

    useEffect(() => {
        fetchAccounts();
        setupFilters();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`SaaS Client ${getInfo().title}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SaaSSupportTickets = ({ index, utils }) => {

    const panelID = 'saasSupportTickets';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [tickets, setTickets] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'SaaS Support Tickets',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/saas/',  {
                    type: 'tickets',
                    show_clients: true,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewTicket = () => {
        utils.layer.open({
            id: 'new-support-ticket',
            abstract: Abstract.create({
                type: 'supportTickets',
                object: SupportTicket.new()
            }),
            Component: AddEditSupportTicket.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onTicketClick = ticket => {
        utils.layer.open({
            id: `support-ticket-details-${ticket.id}`,
            abstract: Abstract.create({
                type: 'supportTickets',
                object: ticket
            }),
            Component: SupportTicketDetails
        });
    }

    const getAssistProps = () => {
        return {
            message: 'These are support tickets issued from SaaS clients in the system. Tickets may include questions, issues, bugs, or other actionable elements.',
            items: [{
                key: 'download',
                title: 'Download Support Tickets',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(tickets.length === 0) {
            return (
                Views.entry({
                    title: 'No Tickets Found',
                    subTitle: 'There are no support tickets available to view',
                    borderBottom: false
                })
            )
        }
        return tickets.map((ticket, index) => {
            return (
                Views.entry({
                    key: index,
                    title: ticket.parameters.name,
                    subTitle: ticket.title,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: ticket.parameters && ticket.parameters.logo ? ticket.parameters.logo.mobile : null
                    },
                    badge: ticket.tagsAndStatus(),
                    bottomBorder: index !== tickets.length - 1,
                    onClick: onTicketClick.bind(this, ticket)
                })
            )
        });
    }

    const fetchTickets = async () => {
        try {
            let { paging, tickets } = await Request.get(utils, '/saas/',  {
                type: 'tickets',
                limit: limit,
                show_clients: true,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setTickets(tickets.map(ticket => SupportTicket.create(ticket)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the support tickets list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchTickets();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, [ 'saas', 'supportTickets' ], {
            onFetch: fetchTickets,
            onRemove: abstract => {
                if(abstract.type !== 'supportTickets') {
                    return;
                }
                setTickets(tickets => {
                    return tickets.filter(ticket => ticket.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                if(abstract.type === 'saas') {
                    fetchTickets();
                    return;
                }
                setTickets(tickets => {
                    return tickets.map(ticket => abstract.compare(ticket));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'SaaS Support Tickets'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Support Ticket',
                style: 'default',
                onClick: onNewTicket
            }],
            search: {
                placeholder: 'Search by ticket, account, or tag...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SupportTickets = ({ index, utils }) => {

    const panelID = 'supportTickets';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [tickets, setTickets] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'SaaS Support Tickets',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/saas/',  {
                    type: 'tickets',
                    app_id: window.client_id,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewTicket = () => {
        utils.layer.open({
            id: 'new-support-ticket',
            abstract: Abstract.create({
                type: 'supportTickets',
                object: SupportTicket.new()
            }),
            Component: AddEditSupportTicket.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onTicketClick = ticket => {
        utils.layer.open({
            id: `support-ticket-details-${ticket.id}`,
            abstract: Abstract.create({
                type: 'supportTickets',
                object: ticket
            }),
            Component: SupportTicketDetails
        });
    }

    const getAssistProps = () => {
        return {
            message: 'These are all support tickets in the system issued from eCarra. Tickets may include questions, issues, bugs, or other actionable elements.',
            items: [{
                key: 'download',
                title: 'Download Support Tickets',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(tickets.length === 0) {
            return (
                Views.entry({
                    title: 'No Tickets Found',
                    subTitle: 'There are no support tickets available to view',
                    borderBottom: false
                })
            )
        }
        return tickets.map((ticket, index) => {
            return (
                Views.entry({
                    key: index,
                    title: ticket.submitted_by ? ticket.submitted_by.full_name : 'Name Unavailable',
                    subTitle: ticket.title,
                    icon: ticket.submitted_by && {
                        style: Appearance.icons.standard(),
                        path: ticket.submitted_by.avatar
                    },
                    badge: ticket.tagsAndStatus(),
                    bottomBorder: index !== tickets.length - 1,
                    onClick: onTicketClick.bind(this, ticket)
                })
            )
        });
    }

    const fetchTickets = async () => {
        try {
            let { paging, tickets } = await Request.get(utils, '/saas/',  {
                type: 'tickets',
                app_id: window.client_id,
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setTickets(tickets.map(ticket => SupportTicket.create(ticket)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the Saas Ticketing list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchTickets();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'supportTickets', {
            onFetch: fetchTickets,
            onRemove: abstract => {
                setTickets(tickets => {
                    return tickets.filter(ticket => ticket.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setTickets(tickets => {
                    return tickets.map(ticket => abstract.compare(ticket));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Support Tickets'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Support Ticket',
                style: 'default',
                onClick: onNewTicket
            }],
            search: {
                placeholder: 'Search by ticket, account, or tag...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const UserGrowth = ({ index, utils }) => {

    const panelID = 'userGrowth';
    const limit = 5;

    const [annotations, setAnnotations] = useState([]);
    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ start: moment().startOf('year'), end: moment() });
    const [loading, setLoading] = useLoading();
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [visibleCallout, setVisibleCallout] = useState(null);

    const onAnnotationClick = annotation => {
        if(Utils.isMobile() === false) {
            setVisibleCallout(annotation);
            return;
        }
        utils.alert.show({
            title: annotation.title,
            message: `There have been ${annotation.total} user ${annotation.total === 1 ? 'account' : 'accounts'} registered in ${annotation.title} between ${dates.start.format('MMMM Do, YYYY')} and ${dates.end.format('MMMM Do, YYYY')}. View this information on a tablet, laptop, or desktop computer to learn more.`
        });
    }

    const getAnnotations = () => {
        return annotations.filter((annotation, index) => {
            if(!paging) {
                return true;
            }
            return index >= offset && index < (offset + limit);
        }).map((annotation, index, annotations) => {
            return (
                Views.entry({
                    key: index,
                    title: annotation.title,
                    subTitle: annotation.subTitle,
                    bottomBorder: index !== annotations.length - 1,
                    onClick: onAnnotationClick.bind(this, annotation)
                })
            )
        })
    }

    const onResetViewport = () => {
        setVisibleCallout(null);
        setAnnotations([...annotations]);
    }

    const getAssistProps = () => {
        return {
            message: 'This map and chart show the new users that have signed up within the specified date range. They are grouped by their city and state. You can click on a map pin to show the amount of users for that area and can do the same by clicking on a colored section of the pie chart',
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(Utils.isMobile() === true) {
            return (
                <>
                <div style={{
                    padding: 12,
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={dates.start}
                    selectedEndDate={dates.end}
                    onStartDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                start: date
                            }
                        })
                    }}
                    onEndDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                end: date
                            }
                        })
                    }}/>
                </div>
                {getAnnotations()}
                </>
            )
        }
        return (
            <div
            className={'row p-0 m-0'}
            style={{
                position: 'relative'
            }}>
                <div
                className={'col-12 col-lg-4'}
                style={{
                    padding: 12,
                    height: '100%',
                    maxHeight: 400
                }}>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={dates.start}
                    selectedEndDate={dates.end}
                    onStartDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                start: date
                            }
                        })
                    }}
                    onEndDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                end: date
                            }
                        })
                    }}/>
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        marginTop: 8,
                        maxHeight: 358
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            padding: 8,
                            textAlign: 'center',
                            borderBottom: `1px solid ${Appearance.colors.divider()}`
                        }}>{annotations.reduce((t, a) => t + a.total, 0) + ' ' + (annotations.reduce((t, a) => t + a.total, 0) === 1 ? 'New Account' : 'New Accounts')}</span>
                        <div style={{
                            maxHeight: 315,
                            overflowY: 'scroll'
                        }}>
                            {getAnnotations()}
                        </div>
                    </div>
                </div>

                <div
                className={'col-12 col-lg-8 mt-2 mt-lg-0'}
                style={{
                    padding: 12
                }}>
                    <Map
                    utils={utils}
                    center={window.userLocation}
                    isZoomEnabled={true}
                    isRotationEnabled={true}
                    isScrollEnabled={true}
                    visibleCallout={visibleCallout}
                    annotations={annotations}
                    style={{
                        height: 400
                    }}/>
                </div>
            </div>
        )
    }

    const getPagingProps = () => {
        if(!paging) {
            return null;
        }
        return {
            limit: limit,
            offset: offset,
            description: paging,
            onClick: next => setOffset(next)
        }
    }

    const fetchUserGrowth = async () => {
        try {
            setLoading(true);
            let { locations } = await Request.get(utils, '/users/',  {
                end_date: moment(dates.end).utc().unix(),
                start_date: moment(dates.start).utc().unix(),
                type: 'new'
            });

            setLoading(false);
            if(Utils.isMobile() === true) {
                setPaging({
                    results: locations.length,
                    current_page: parseInt(offset / 5) + 1,
                    number_of_pages: locations.length > 5 ? Math.ceil(locations.length / 5) : 1
                });
            }
            setAnnotations(locations.map(location => ({
                total: location.total,
                title: location.name,
                subTitle: `${location.total} ${location.total === 1 ? 'User' : 'Users'}`,
                icon: { type: 'static-broadcast' },
                location: {
                    latitude: location.coordinate.lat,
                    longitude: location.coordinate.long
                }
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the new users breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchUserGrowth();
    }, [dates]);

    useEffect(() => {
        if(Utils.isMobile() === true) {
            setPaging({
                results: annotations.length,
                current_page: parseInt(offset / 5) + 1,
                number_of_pages: annotations.length > 5 ? Math.ceil(annotations.length / 5) : 1
            });
        }
    }, [offset]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'User Growth'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            paging: getPagingProps(),
            assist: {
                props: getAssistProps()
            },
            buttons: visibleCallout && [{
                key: 'show-all',
                title: 'Show All Annotations',
                style: 'default',
                onClick: onResetViewport
            }]
        }}>
            {getContent()}
        </Panel>
    )
}

export const UserList = ({ category, level, levels }, { index, utils }) => {

    const panelID = `${category}UserList`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [users, setUsers] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: getInfo().title,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/users/', {
                    type: 'all',
                    levels: levels,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewUser = () => {
        let user = User.new();
        user.level = level;
        utils.layer.open({
            id: 'new-user',
            abstract: Abstract.create({
                type: 'users',
                object: user
            }),
            Component: AddEditUser.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all ${getInfo().title.toLowerCase()}s sorted by their last name. You can search for a specific user by typing in their first and last name in the search bar. You can edit a users's information by clicking on them`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : `${getInfo().title.slice(0, -1)}s`}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(users.length === 0) {
            return (
                Views.entry({
                    title: `No ${getInfo().title} Found`,
                    subTitle: `There are no ${getInfo().title} available to view`
                })
            )
        }
        return users.map((user, index) => {
            return (
                Views.entry({
                    key: user.user_id,
                    title: user.full_name,
                    subTitle: user.email_address || 'Email address not available',
                    badge: User.formatLevel(user.level),
                    icon: {
                        style: Appearance.icons.standard(),
                        path: user.avatar
                    },
                    bottomBorder: index !== users.length - 1,
                    onClick: Utils.users.details.bind(this, utils, user)
                })
            )
        });
    }

    const getInfo = () => {
        switch(category) {
            case 'admin':
            return {
                title: 'Administators',
                level: User.level.admin
            };

            case 'drivers':
            return {
                title: 'Drivers',
                level: User.level.driver
            };

            case 'customers':
            return {
                title: 'Customers',
                level: User.level.customer
            };

            default:
            return {
                title: 'Users',
                level: User.level.customer
            };
        }
    }

    const fetchUsers = async () => {
        try {
            let { paging, users } = await Request.get(utils, '/users/', {
                type: 'all',
                levels: levels,
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setUsers(users.map(user => User.create(user)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${getInfo().title.toLowerCase()} list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchUsers();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'users', {
            onFetch: fetchUsers,
            onRemove: abstract => {
                setUsers(users => {
                    return users.filter(user => user.user_id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setUsers(users => {
                    return users.map(user => abstract.compare(user));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getInfo().title}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: `New ${getInfo().title.slice(0, -1)}`,
                style: 'default',
                onClick: onNewUser
            }],
            search: {
                placeholder: 'Search by first or last name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const UserLoginBreakdown = ({ index, utils }) => {

    const panelID = 'userLoginBreakdown';
    const chartRef = useRef(null);

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().startOf('year') });
    const [focus, setFocus] = useState(null);
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'User Login Breakdown',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/users/', {
                    type: 'login_report',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'The breakdown outlines groups of users based on their last login to the mobile app. The breakdown defaults to the start of the month to today',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-2 pt-1 pb-2 p-lg-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={Appearance.textStyles.title()}>{`${Utils.formatDate(dates.start_date, true)} to ${Utils.formatDate(dates.end_date, true)}`}</span>
                        <span style={Appearance.textStyles.subTitle()}>{getOverviewText()}</span>
                    </div>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={dates.start_date}
                        selectedEndDate={dates.end_date}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }}
                        onStartDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    start_date: date
                                }
                            })
                        }}
                        onEndDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    end_date: date
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
            <div style={{
                position: 'relative',
                display: 'block',
                height: 350,
                padding: 10
            }}>
                {!chart || !chart.data || chart.labels.length < 3
                    ?
                    <NoDataFound message={'Insufficient Data for User Logins'}/>
                    :
                    <Line
                    ref={chartRef}
                    width={500}
                    height={100}
                    data={{
                        labels: chart.labels,
                        datasets: [{
                            fill: false,
                            pointRadius: 5,
                            data: chart.data,
                            pointBorderWidth: 2,
                            borderColor: Appearance.colors.primary(),
                            pointBackgroundColor: Appearance.colors.background(),
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                        }]
                    }}
                    options={{
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                           mode: 'label',
                           label: 'mylabel',
                           callbacks: {
                               label: item => {
                                   return `${item.yLabel} ${parseInt(item.yLabel) === 1 ? ' User' : ' Users'}`;
                               }
                           }
                        },
                        scales: {
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: 0
                                }
                            }],
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }]
                        }
                    }} />
                }
            </div>
            </>
        )
    }

    const getOverviewText = () => {
        let elapsedDays = moment(dates.end_date).diff(moment(dates.start_date), 'days');
        return `${elapsedDays} full ${elapsedDays === 1 ? 'day' : 'days'} included in breakdown`;
    }

    const fetchBreakdown = async () => {
        try {
            let { data, labels } = await Request.get(utils, '/users/', {
                type: 'login_report',
                end_date: moment(dates.end_date).utc().unix(),
                start_date: moment(dates.start_date).utc().unix(),
            });
            setLoading(false);
            setChart({
                data: data.map(d => d.total),
                labels: labels.map(l => moment(l).isSame(moment(), 'day') ? 'Today' : moment(l).format('MM/DD/YYYY'))
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the users login breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdown();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'User Last Login Breakdown'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const UserReferrals = ({ index, utils }) => {

    const panelID = 'userReferrals';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onUserClick = user => {
        utils.sheet.show({
            items: [{
                key: 'about',
                title: `About ${user.first_name}`,
                style: 'default'
            },{
                key: 'referrals',
                title: 'View Referrals',
                style: 'default'
            }]
        }, key => {
            if(key === 'about') {
                Utils.users.details(utils, user);
                return;
            }
            if(key === 'referrals') {
                utils.layer.open({
                    id: `user-referrals-${user.user_id}`,
                    abstract: Abstract.create({
                        type: 'users',
                        object: user
                    }),
                    Component: UserReferralDetails
                });
                return;
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This map and chart show the new users that have signed up within the specified date range. They are grouped by their city and state. You can click on a map pin to show the amount of users for that area and can do the same by clicking on a colored section of the pie chart',
            items: [{
                key: 'export',
                title: 'Export Referrals',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(users.length === 0) {
            return (
                Views.entry({
                    title: 'No Referrals Found',
                    subTitle: 'There are no referrals available to view',
                    borderBottom: false
                })
            )
        }
        return users.map((user, index) => {
            return (
                Views.entry({
                    key: user.user_id,
                    title: user.full_name,
                    subTitle: `Member Since: ${moment(user.member_since).format('MMMM Do, YYYY [at] h:mma')}`,
                    badge: {
                        color: Appearance.colors.grey(),
                        text: `${user.referrals.length} ${user.referrals.length === 1 ? ' Referral' : 'Referrals'}`
                    },
                    icon: {
                        style: Appearance.icons.standard(),
                        path: user.avatar
                    },
                    bottomBorder: index !== users.length - 1,
                    onClick: onUserClick.bind(this, user)
                })
            )
        });
    }

    const fetchReferrals = async () => {
        try {
            let { paging, users } = await Request.get(utils, '/users/',  {
                type: 'referral_tree',
                limit: limit,
                ...manager
            });
            setLoading(false);
            setPaging(paging);
            setUsers(users.map(user => User.create(user)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the user referrals breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchReferrals();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'users', {
            onFetch: fetchReferrals,
            onRemove: abstract => {
                setUsers(users => {
                    return users.filter(user => user.user_id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setUsers(users => {
                    return users.map(user => abstract.compare(user));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'User Referrals'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps()
            },
            search: {
                placeholder: 'Search by first or last name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditCampaign = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-campaign' : `edit-campaign-${abstract.getID()}`;
    const [campaign, setCampaign] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useLoading();

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" campaign has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this campaign. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setCampaign(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {Views.loader()}
                </div>
            )
        }
        return (
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        )
    }

    const getFields = () => {
        if(!campaign) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for a campaign is shown when a push notification is received on a mobile device. The title should be a few words describing what the notification is about.',
                component: 'textfield',
                value: campaign.title,
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'message',
                title: 'Message',
                description: `The message for a campaign is shown when a push notification is received on a mobile device. The message would contain the information that you wish to convey to your customers. Mobile devices allow roughly 175 characters for a push notification. Anything longer than that will still be viewable in the notification section of the mobile app.`,
                component: 'textview',
                value: campaign.message,
                onChange: text => onUpdateTarget({ message: text })
            }]
        },{
            key: 'targeting',
            title: 'Targeting',
            items: [{
                key: 'companies',
                required: false,
                title: 'Companies',
                description: 'Campaigns can be targeted to the customers within one or more companies. Leave this area blank if you do not wish to target specific companies.',
                component: 'multiple_list',
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                value: campaign.data && campaign.data.companies && campaign.data.companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: items => {
                    onUpdateTarget({
                        data: update(campaign.data, {
                            companies: {
                                $set: items && companies.filter(company => {
                                    return items.find(item => {
                                        return item.id === company.id;
                                    }) ? true : false;
                                })
                            }
                        })
                    });
                }
            },{
                key: 'platform',
                required: false,
                title: 'Platform',
                description: 'Campaigns can be targeted to the customers on a specific mobile platform. Leave this area blank if you do not wish to target specific platforms.',
                value: campaign.data ? campaign.data.platform : null,
                component: 'list',
                items: [{
                    id: 'ios',
                    title: 'iOS'
                },{
                    id: 'android',
                    title: 'Android'
                }],
                onChange: item => {
                    onUpdateTarget({
                        data: update(campaign.data, {
                            platform: {
                                $set: item && item.id
                            }
                        })
                    });
                }
            },{
                key: 'users',
                required: false,
                title: 'Specific Users',
                description: 'Campaigns can be targeted to one or more specific user accounts. Leave this area blank if you do not wish to target specific user accounts.',
                value: campaign.data ? campaign.data.users : [],
                component: 'multiple_user_lookup',
                onChange: items => {
                    onUpdateTarget({
                        data: update(campaign.data, {
                            users: {
                                $set: items
                            }
                        })
                    });
                }
            },{
                key: 'city',
                required: false,
                title: 'City',
                description: 'Campaigns can be targeted to the city where the customer created their account. Leave this area blank if you do no wish to target specific cities.',
                component: 'textfield',
                value: campaign.data ? campaign.data.city : null,
                onChange: text => {
                    onUpdateTarget({
                        data: update(campaign.data, {
                            city: {
                                $set: text
                            }
                        })
                    });
                }
            },{
                key: 'state',
                required: false,
                title: 'State',
                description: 'Campaigns can be targeted to the state where the customer created their account. Leave this area blank if you do no wish to target specific states.',
                component: 'textfield',
                value: campaign.data ? campaign.data.state : null,
                onChange: text => {
                    onUpdateTarget({
                        data: update(campaign.data, {
                            state: {
                                $set: text
                            }
                        })
                    });
                }
            },{
                key: 'zipcode',
                required: false,
                title: 'Zipcode',
                description: 'Campaigns can be targeted to the zipcode where the customer created their account. Leave this area blank if you do no wish to target specific zipcode.',
                component: 'textfield',
                value: campaign.data ? campaign.data.zipcode : null,
                onChange: text => {
                    onUpdateTarget({
                        data: update(campaign.data, {
                            zipcode: {
                                $set: text
                            }
                        })
                    });
                }
            }]
        }];
    }

    const fetchAllCompanies = async () => {
        try {
            let { companies } = await fetchCompanies(utils);
            setCompanies(companies);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the companies list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchExtDetails = async () => {
        try {
            let edits = abstract.object.open();
            if(!abstract.object.data || !abstract.object.data.users || abstract.object.data.users.length === 0) {
                setCampaign(edits);
                setLoading(false);
                return;
            }
            setLoading('users');
            let { companies, users } = await Request.get(utils, '/campaign/', {
                type: 'ext_details',
                id: abstract.getID()
            });

            // update abstract target edits
            setLoading(false);
            edits = abstract.object.set({
                data: {
                    ...abstract.object.edits.data,
                    companies: companies.map(company => Company.create(company)),
                    users: users.map(user => User.create(user))
                }
            });
            setCampaign(edits);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the list of specific users. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchAllCompanies();
        fetchExtDetails();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Campaign' : `"${abstract.getTitle()}" Details`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setCampaign(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            {getContent()}
        </Layer>
    )
}

export const AddEditSaaSClientAccount = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-saas-client-account' : `edit-saas-client-account-${abstract.getID()}`;
    const [account, setAccount] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onCreateAdmin = async () => {

        let user = User.new();
        user.level = User.level.admin;

        let abstract = Abstract.create({
            type: 'users',
            object: user
        });
        utils.layer.open({
            id: 'new-user',
            abstract: abstract,
            Component: AddEditUser.bind(this, {
                isNewTarget: true,
                level: 'admin',
                onSkipUpload: user => {
                    abstract.object.edits = user;
                    abstract.object.close();
                    abstract.object.set({ owner: abstract.object.edits });
                    setAccount({ ...abstract.object.edits })
                }
            })
        });
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setAccount(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = async () => {

        let required = [{
            title: 'name',
            object: account.name
        },{
            title: 'tagline',
            object: account.tagline
        },{
            title: 'owner',
            object: isNewTarget ? account.owner : true
        },{
            title: 'website',
            object: account.website
        },{
            title: 'location',
            object: account.location
        },{
            title: 'phone number',
            object: account.phone_number
        },{
            title: 'email address',
            object: account.email_address
        },{
            title: 'privacy',
            object: account.privacy
        },{
            title: 'light mode image',
            object: isNewTarget ? true : (account.logos ? account.logos.light : false)
        },{
            title: 'dark mode image',
            object: isNewTarget ? true : (account.logos ? account.logos.dark : false)
        },{
            title: 'white icon',
            object: isNewTarget ? true : (account.logos ? account.logos.icon : false)
        },{
            title: 'mobile app icon',
            object: isNewTarget ? true : (account.logos ? account.logos.mobile : false)
        },{
            title: 'theme color',
            object: account.colors ? account.colors.regular : false
        }]

        let remaining = required.filter(entry => !entry.object)
        if(remaining.length > 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please fill out the "${remaining[0].title}" before moving on`
            })
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: isNewTarget ? `The acccount information for "${account.name}" has been added as a new SaaS client account. They will immediately have access to Seeds and other web based portals. Please follow up with the client account admin to get the mobile app developer account process started.` : `The acccount information for "${account.name}" has been updated.`,
                onClick: () => setLayerState('close')
            });

            if(window.client_id !== 'ecarra') {
                await utils.client.refresh();
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this client account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!account) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a client account will be shown for this client across all pieces of the platform. This name is also the default name of the mobile app for this new account. An example client name would be "eCarra".',
                value: account.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'tagline',
                title: 'Tagline',
                description: `The tagline for a client account will be shown underneath the client's logo in the mobile app. An example client tagline would be "Rides that Matter".`,
                value: account.tagline,
                component: 'textfield',
                onChange: text => onUpdateTarget({ tagline: text })
            },{
                key: 'website',
                title: 'Website',
                description: 'The website for a client account will be shown to customers when they are seeking out support or learning more about the client.',
                value: account.website,
                component: 'textfield',
                onChange: text => onUpdateTarget({ website: text })
            },{
                key: 'location',
                title: 'Location',
                description: 'The location for a client account is used in scenarios where proximity is important but an accurate coordinate is not available. This location should be the main office for the client. This location can be changed at a later date if needed.',
                value: account.location,
                component: 'address_lookup',
                onChange: place => onUpdateTarget({ location: place })
            }]
        },{
            key: 'support',
            title: 'Customer Support',
            items: [{
                key: 'email_address',
                title: 'Support Email Address',
                description: 'Each client must provide a customer support email address that we can pass along to customers in the mobile app. This email address can be changed at a later date if needed.',
                value: account.email_address,
                component: 'textfield',
                onChange: text => onUpdateTarget({ email_address: text })
            },{
                key: 'phone_number',
                title: 'Phone Number',
                description: 'Each client must provide a customer support phone number that we can pass along to customers in the mobile app. This phone numbercan be changed at a later date if needed.',
                value: account.phone_number,
                component: 'textfield',
                onChange: text => onUpdateTarget({ phone_number: text })
            },{
                key: 'privacy',
                title: 'Privacy Policy',
                description: 'Mobile apps on the Apple App Store and Google Play Store are required to a publically available privacy policy. Please provide a url to a privacy policy for this client. This url can be changed at a later date if needed.',
                value: account.privacy,
                component: 'textfield',
                onChange: text => onUpdateTarget({ privacy: text })
            }]
        },{
            key: 'branding',
            title: 'Branding',
            items: [{
                key: 'colors',
                title: 'Theme Color',
                description: 'We use this color to create a collection of lighter and darker variations that are used as accents throughout the mobile app and Seeds. This color can be changed at a later date if needed.',
                value: account.colors ? account.colors.regular : null,
                component: 'color_picker',
                onChange: color => {
                    onUpdateTarget({
                        colors: update(account.colors || {}, {
                            regular: {
                                $set: color
                            }
                        })
                    });
                }
            },{
                key: 'light',
                required: isNewTarget,
                visible: isNewTarget,
                title: 'Standard Logo',
                description: 'We require specific image assets for each client account. The light mode image should be a full logo, with a transparent background, that is geared towards a light user interface.',
                value: account.logos ? account.logos.light : null,
                component: 'image_picker',
                onChange: image => {
                    onUpdateTarget({
                        logos: update(account.logos || {}, {
                            light: {
                                $set: image
                            }
                        })
                    });
                }
            },{
                key: 'dark',
                required: isNewTarget,
                visible: isNewTarget,
                title: 'Dark Mode Logo',
                description: 'We require specific image assets for each client account. The dark mode image should be a full logo, with a transparent background, that is geared towards a dark user interface.',
                value: account.logos ? account.logos.dark : null,
                component: 'image_picker',
                onChange: image => {
                    onUpdateTarget({
                        logos: update(account.logos || {}, {
                            dark: {
                                $set: image
                            }
                        })
                    });
                }
            },{
                key: 'icon',
                required: isNewTarget,
                visible: isNewTarget,
                title: 'Standard Logo',
                description: 'We require specific image assets for each client account. The icon image should be a square assets, with a transparent background, that contains a white and opaque icon',
                value: account.logos ? account.logos.icon : null,
                component: 'image_picker',
                onChange: image => {
                    onUpdateTarget({
                        logos: update(account.logos || {}, {
                            icon: {
                                $set: image
                            }
                        })
                    });
                }
            },{
                key: 'mobile',
                required: isNewTarget,
                visible: isNewTarget,
                title: 'Mobile App Icon',
                description: 'We require specific image assets for each client account. The mobile icon will be the image used for the mobile app when viewed on a mobile device home screen. Please do not provide an image with rounded corners.',
                value: account.logos ? account.logos.mobile : null,
                component: 'image_picker',
                onChange: image => {
                    onUpdateTarget({
                        logos: update(account.logos || {}, {
                            mobile: {
                                $set: image
                            }
                        })
                    });
                }
            }]
        },{
            key: 'social_media',
            title: 'Social Media',
            items: [{
                key: 'facebook',
                title: 'Facebook',
                description: 'The mobile app for a client has dynamic links to social media accounts when available. We provide links to Facebook, Instagram, and Twitter. We will not show a link for an individual social channel if no link is provided. These links can be changed at a later date if needed.',
                value: account.facebook,
                component: 'textfield',
                onChange: text => onUpdateTarget({ facebook: text })
            },{
                key: 'instagram',
                title: 'Instagram',
                description: 'The mobile app for a client has dynamic links to social media accounts when available. We provide links to Facebook, Instagram, and Twitter. We will not show a link for an individual social channel if no link is provided. These links can be changed at a later date if needed.',
                value: account.instagram,
                component: 'textfield',
                onChange: text => onUpdateTarget({ instagram: text })
            },{
                key: 'twitter',
                title: 'Twitter',
                description: 'The mobile app for a client has dynamic links to social media accounts when available. We provide links to Facebook, Instagram, and Twitter. We will not show a link for an individual social channel if no link is provided. These links can be changed at a later date if needed.',
                value: account.twitter,
                component: 'textfield',
                onChange: text => onUpdateTarget({ twitter: text })
            },]
        },{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                component: 'list',
                description: 'Account verifications are required when signing up for a new customer account. Selecting "Email" will tell the system to send an email to the customer with a verification code. Selecting "Text Message" will tell the system to send a text message to the customer with a verification code.',
                items: [{
                    id: 'email',
                    title: 'Email'
                },{
                    id: 'sms',
                    title: 'Text Message'
                }],
                key: 'preferences.mobile.account_verifications',
                onChange: item => {
                    onUpdateTarget({
                        preferences: update(account.preferences, {
                            mobile: {
                                account_verifications: {
                                    $set: item && item.id || 'email'
                                }
                            }
                        })
                    });
                },
                props: { deselect: false },
                title: 'New User Account Verifications',
                value: account.preferences.mobile.account_verifications === 'sms' ? 'Text Message' : 'Email'
            }]
        },{
            key: 'ownership',
            visible: isNewTarget,
            title: 'Ownership',
            items: [{
                key: 'user',
                title: 'Point of Contact',
                description: 'The owner for a client account will be the first login created for this client. This administrator login will be responsible for creating other administrators and getting the client account configured. It is recommended that the administrator be a C-level executive within the client company. We also use this individuals information to setup the client Stripe payouts account.',
                value: 'Click to make changes...',
                onEditClick: onCreateAdmin
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setAccount(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New SaaS Client Account' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setAccount(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditSupportTicket = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-support-ticket' : `edit-support-ticket-${abstract.getID()}`;
    const [accounts, setAccounts] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState(null);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" support ticket has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this support ticket. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setTicket(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getBrowsers = () => {
        return [{
            id: 'chrome',
            title: 'Google Chrome'
        },{
            id: 'ie',
            title: 'Internet Explorer'
        },{
            id: 'edge',
            title: 'Microsoft Edge'
        },{
            id: 'firefox',
            title: 'Mozilla Firefox'
        },{
            id: 'safari',
            title: 'Safari'
        },{
            id: 'other',
            title: 'Other'
        }];
    }

    const getClient = () => {
        if(!ticket.client_id) {
            return null;
        }
        let account = accounts.find(act => act.id === ticket.client_id);
        return account && {
            id: ticket.client_id,
            title: account.name
        }
    }

    const getFields = () => {
        if(!ticket) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for the support ticket should be a brief phrase identifying the purpose of the ticket.',
                value: ticket.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'message',
                title: 'Message',
                description: 'The message for the support ticket should be a brief sentence describing the purpose of the ticket. Information like app version, operating system version, device type, and browser type will be collected in a different section.',
                value: ticket.message,
                component: 'textview',
                onChange: text => onUpdateTarget({ message: text })
            },{
                key: 'client_id',
                visible: window.client_id === 'ecarra',
                required: false,
                title: 'Client',
                description: 'Choosing a client for the ticket helps us understand who was effected by the information included with the ticket. The client will be able to view this support ticket in their version of Seeds.',
                component: 'list',
                value: getClient(),
                onChange: item => onUpdateTarget({ client_id: item && item.id }),
                items: accounts.map(act => ({
                    id: act.client_id,
                    title: act.name
                }))
            }]
        },{
            key: 'browser',
            title: 'Web Browser',
            items: [{
                key: 'type',
                required: false,
                title: 'Type',
                description: `We'll need to know the browser that you were using when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'list',
                items: getBrowsers(),
                value: ticket.browser ? ticket.browser.type : null,
                onChange: item => {
                    onUpdateTarget({
                        browser: {
                            ...ticket.browser,
                            type: item && item.title
                        }
                    });
                }
            },{
                key: 'version',
                required: false,
                title: 'Version',
                description: `We'll need to know the browser that you were using when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'textfield',
                value: ticket.browser ? ticket.browser.version : null,
                onChange: text => {
                    onUpdateTarget({
                        browser: {
                            ...ticket.browser,
                            version: text
                        }
                    });
                }
            }]
        },{
            key: 'os',
            title: 'Operating System',
            items: [{
                key: 'type',
                required: false,
                title: 'Type',
                description: `We'll need to know the operating system that you were using when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'list',
                items: getOperatingSystems(),
                value: ticket.os ? ticket.os.type : null,
                onChange: item => {
                    onUpdateTarget({
                        os: {
                            ...ticket.os,
                            type: item && item.title
                        }
                    });
                }
            },{
                key: 'version',
                required: false,
                title: 'Version',
                description: `We'll need to know the operating system version that you were using when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'textfield',
                value: ticket.os ? ticket.os.version : null,
                onChange: text => {
                    onUpdateTarget({
                        os: {
                            ...ticket.os,
                            version: text
                        }
                    });
                }
            }]
        },{
            key: 'mobile_app',
            title: 'Mobile App',
            items: [{
                key: 'platform',
                required: false,
                title: 'Platform',
                description: `We'll need to know the platform of the device that you were using when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'list',
                items: getMobilePlatforms(),
                value: ticket.mobile_app ? ticket.mobile_app.platform : null,
                onChange: item => {
                    onUpdateTarget({
                        mobile_app: {
                            ...ticket.mobile_app,
                            platform: item && item.title
                        }
                    });
                }
            },{
                key: 'version',
                required: false,
                title: 'Version',
                description: `We'll need to know the mobile app version that you were using when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'textfield',
                value: ticket.mobile_app ? ticket.mobile_app.version : null,
                onChange: text => {
                    onUpdateTarget({
                        mobile_app: {
                            ...ticket.mobile_app,
                            version: text
                        }
                    });
                }
            }]
        },{
            key: 'behavior',
            title: 'Behavior',
            items: [{
                key: 'reproduce',
                required: false,
                title: 'Steps to Reproduce',
                description: `We'll need to know what steps we can take to reproduce your issue. Leave this blank if it does not apply to your situation.`,
                component: 'textview',
                value: ticket.reproduce,
                onChange: text => onUpdateTarget({ reproduce: text })
            },{
                key: 'expected',
                required: false,
                title: 'Expected',
                description: `We'll need to know what you expected to happen when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'textview',
                value: ticket.behavior ? ticket.behavior.expected : null,
                onChange: text => {
                    onUpdateTarget({
                        behavior: {
                            ...ticket.behavior,
                            expected: text
                        }
                    });
                }
            },{
                key: 'actual',
                required: false,
                title: 'Actual',
                description: `We'll need to know what happen when your issue occurred. Leave this blank if it does not apply to your situation.`,
                component: 'textview',
                value: ticket.behavior ? ticket.behavior.actual : null,
                onChange: text => {
                    onUpdateTarget({
                        behavior: {
                            ...ticket.behavior,
                            actual: text
                        }
                    });
                }
            },{
                key: 'attachments',
                required: false,
                title: 'Attachments',
                description: 'Attaching screenshots and screen recording to a ticket gives us more information about your specific issue. Screenshots and screen recordings are highly recommended.',
                component: 'multiple_file_picker',
                value: ticket.attachments,
                onChange: files => onUpdateTarget({ attachments: files })
            }]
        }];
    }

    const getMobilePlatforms = () => {
        return [{
            id: 'android',
            title: 'Android'
        },{
            id: 'ios',
            title: 'iOS'
        }];
    }

    const getOperatingSystems = () => {
        return [{
            id: 'android',
            title: 'Android'
        },{
            id: 'ipad',
            title: 'iPadOS'
        },{
            id: 'ios',
            title: 'iOS'
        },{
            id: 'mac',
            title: 'macOS'
        },{
            id: 'windows',
            title: 'Windows'
        },{
            id: 'other',
            title: 'Other'
        }];
    }

    const fetchAccounts = async () => {
        try {
            let { accounts } = await fetchSaaSAccounts(utils);
            setAccounts(accounts);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the list of saas clients. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setTicket(edits);
    }

    useEffect(() => {
        setupTarget();
        fetchAccounts();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Support Ticket' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setTicket(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditUser = ({ isNewTarget, onAddUser, onSkipUpload }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-user' : `edit-user-${abstract.getID()}`;
    const [companies, setCompanies] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [usernameAvailable, setUsernameAvailable] = useState(null);

    const getAvailableLevelCodes = () => {
        let codes = Object.values(User.level).reduce((array, code) => {

            // do not provide system admin as an account type option
            if(code === User.level.system_admin) {
                return array;
            }
            // do not provide driver as an account type option if a company is set
            if(user.company && code === User.level.driver) {
                return array;
            }
            // do not provide standard admin as an account type option if a company is set
            if(user.company && code === User.level.admin) {
                return array;
            }
            // do not provide standard admin as an account type option if the current user is not an admin
            if(utils.user.get().level > User.level.admin && code === User.level.admin) {
                return array;
            }
            // do not provide company admin as an account type option if no company is set
            if(!user.company && code === User.level.company_admin) {
                return array;
            }
            return array.concat([{
                id: code,
                title: User.formatLevel(code).text
            }]);

        }, []);
        return codes;
    };

    const onCheckUsernameAvailability = async () => {
        try {
            if(!user.username) {
                setUsernameAvailable(null);
                return;
            }
            if(isNewTarget === false && user.username === abstract.object.username) {
                setUsernameAvailable(null);
                return;
            }
            let { available } = await Request.get(utils, '/user/',  {
                type: 'username_available_quiet',
                username: user.username
            });
            setUsernameAvailable(available || false);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue checking if the username is available. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditDirectPaymentPreferences = () => {
        utils.layer.open({
            id: 'edit_direct_payment_preferences',
            abstract: abstract,
            Component: EditDirectPaymentPreferences.bind(this, {
                account: user.direct_payments_account || {},
                onChange: ({ account }) => {
                    onUpdateTarget({
                        direct_payments_account: update(user.direct_payments_account, {
                            commission: {
                                $set: account.commission
                            },
                            revenue_share: {
                                $set: account.revenue_share
                            }
                        })
                    });
                }
            })
        });
    }

    const onSubmit = async () => {
        try {
            await validateRequiredFields(getFields);
            if(typeof(onSkipUpload) === 'function') {
                onSkipUpload(abstract.object.edits);
                setLayerState('close');
                return;
            }

            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The account for ${abstract.object.full_name} has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => {
                    setLayerState('close');
                    if(typeof(onAddUser) === 'function') {
                        onAddUser(abstract.object);
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setUser(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setUser(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'center',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <LottieView
                    autoPlay={true}
                    loop={true}
                    source={require('files/lottie/dots-grey.json')}
                    style={{
                        height: 45,
                        width: 45
                    }}/>
                </div>
            )
        }
        return (
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        )
    }

    const getFields = () => {
        if(!user) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'first_name',
                title: 'First Name',
                description: 'The first name for an account is shown when representing this account in the system.',
                value: user.first_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ first_name: text })
            },{
                key: 'last_name',
                title: 'Last Name',
                description: 'The last name for an account is shown when representing this account in the system.',
                value: user.last_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ last_name: text })
            },{
                key: 'avatar',
                required: false,
                title: 'Photo',
                description: `The photo for an account is shown when representing this account in the system. Adding a photo to an account helps personalize the accounts presence in the system.`,
                value: user.avatar,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ avatar: image })
            },{
                key: 'company',
                required: false,
                visible: utils.user.get().level <= User.level.admin,
                title: 'Company',
                description: `Assigning a company to this User will apply any discounts available for that company and will allow this account to appear in Seeds for the selected company's administrators.`,
                value: user.company,
                component: 'list',
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        company: item && companies.find(company => {
                            return company.id === item.id
                        })
                    });
                }
            }]
        },{
            key: 'account_details',
            title: 'Account Details',
            items: [{
                key: 'username',
                required: false,
                title: 'Username',
                description: 'The username for this account will be required when logging into all web and mobile interations of the platform. You do not need to provide a username or password if you do not need this customer to have access to their account.',
                value: user.username,
                component: 'textfield',
                onChange: text => onUpdateTarget({ username: text }),
                props: {
                    useDelay: true
                },
                invalid: usernameAvailable === false && {
                    text: 'Not Available',
                    value: usernameAvailable === false
                }
            },{
                key: 'password',
                required: false,
                visible: isNewTarget,
                title: 'Password',
                description: 'The password for this account will be required when logging into all web and mobile interations of the platform. You do not need to provide a username or password if you do not need this customer to have access to their account.',
                value: user.password,
                component: 'textfield',
                onChange: text => onUpdateTarget({ password: text }),
                props: {
                    isSecure: true
                }
            },{
                key: 'level',
                visible: utils.user.get().level <= User.level.admin,
                title: 'Account Type',
                description: `The account type for a user dictates the privileges for that account. Changing the account type for a user can revoke the their current abilities or grant new abilities.`,
                component: 'list',
                onChange: item => onUpdateTarget({ level: item && item.id }),
                items: getAvailableLevelCodes(),
                value: user.level && {
                    id: user.level,
                    title: User.formatLevel(user.level).text
                }
            },{
                key: 'referral_code',
                title: 'Referral Code',
                description: 'The referral code for this account can be used by the account owner to earn revenue share when their referral code is used on an eligible ride or delivery.',
                value: user.referral_code,
                component: 'textfield',
                onChange: text => onUpdateTarget({ referral_code: text })
            }]
        },{
            key: 'contact',
            title: 'Contact',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                description: 'A valid and up-to-date email address is required for invoices, marketing, support, and other important internal communications.',
                value: user.email_address,
                component: 'textfield',
                onChange: text => onUpdateTarget({ email_address: text })
            },{
                key: 'phone_number',
                title: 'Phone Number',
                description: 'A valid and up-to-date phone number is required for all user accounts. We use this number for order support, reservation support, marketing, and communication needs.',
                value: user.phone_number,
                component: 'textfield',
                onChange: text => onUpdateTarget({ phone_number: text }),
                props: {
                    format: 'phone_number'
                }
            },{
                key: 'location',
                title: 'Location',
                description: 'The location for a user account helps us recommend relevant points of interest around when searches are submitted in the mobile app.',
                value: abstract.object.getAddressComponents(),
                component: 'address_lookup',
                onChange: place => {
                    // do not spread result
                    // some results contain incomplete values
                    // we need to overrite the previous result for the user target
                    onUpdateTarget({
                        address: place ? place.address.address : null,
                        city: place ? place.address.city : null,
                        state: place ? place.address.state : null,
                        zipcode: place ? place.address.zipcode : null,
                        country: place ? place.address.country : null,
                        location: place ? place.location : null
                    });
                }
            }]
        },{
            key: 'direct_payments_account',
            visible: user.direct_payments_account ? true : false,
            title: 'Direct Payments',
            items: [{
                key: 'enabled',
                required: false,
                title: 'Enabled',
                description: 'Enabling direct payments for this user will allow them to receive funds from the platform when applicable.',
                component: 'bool_list',
                value: user.direct_payments_account && user.direct_payments_account.enabled,
                onChange: val => {
                    onUpdateTarget({
                        direct_payments_account: update(user.direct_payments_account, {
                            enabled: {
                                $set: val
                            }
                        })
                    });
                }
            },{
                key: 'rates',
                required: false,
                title: 'Commission and Revenue Share Preferences',
                description: 'Setting commission and revenue share rates will determine how and when we automatically transfer funds to this user when an eligible delivery or ride has been completed.',
                onEditClick: onEditDirectPaymentPreferences
            }]
        }];
    }

    const setupTargets = async () => {
        try {

            // set loading flag so edits can not be made while targets are being configured
            setLoading('init');

            // fetch companies
            if(utils.user.get().level <= User.level.admin) {
                let { companies } = await fetchCompanies(utils);
                setCompanies(companies);
            }

            // setup editing for target 
            abstract.object.open();

            // apply company props if current user is a company admin
            // format payments account if payments account is setup
            let edits = abstract.object.set({
                company: utils.user.get().company,
                ...utils.user.get().company && user.level === User.level.admin && {
                    level: utils.user.get().company.id
                },
                direct_payments_account: abstract.object.direct_payments_account && {
                    enabled: abstract.object.direct_payments_account.enabled,
                    commission: abstract.object.direct_payments_account.commission,
                    revenue_share: abstract.object.direct_payments_account.revenue_share
                }
            });

            // end loading flag and set user edits
            setLoading(false);
            setUser(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the editing process. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        onCheckUsernameAvailability();
    }, [user.username]);

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New ${user ? User.formatLevel(user.level).text : 'User'} Account` : `Editing "${abstract.getTitle()}"`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            {getContent()}
        </Layer>
    )
}

export const CampaignDetails = ({ abstract, index, options, utils }) => {

    const layerID = `campaign-details-${abstract.getID()}`;
    const limit = 5;

    const [campaign, setCampaign] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useLoading();
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [users, setUsers] = useState([]);

    const onDeleteCampaign = () => {
        utils.alert.show({
            title: 'Delete Campaign',
            message: `Are you sure that you want to delete "${campaign.title}"? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCampaignConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteCampaignConfirm = async () => {
        try {
            setLoading(true);
            await Request.post(utils, '/campaign/', {
                type: 'delete',
                id: campaign.id
            });

            setLoading(false);
            utils.content.fetch('campaigns');
            utils.alert.show({
                title: 'All Done!',
                message: `"${campaign.title}" has been deleted from the system`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this campaign. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-campaign-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCampaign.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'run',
                title: 'Run Campaign',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete Campaign',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'delete') {
                onDeleteCampaign();
                return;
            }
            if(key === 'run') {
                onRunCampaign();
                return;
            }
        });
    }

    const onRunCampaign = async () => {
        try {
            setLoading(true);
            await abstract.object.run(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `"${campaign.title}" has been submitted and will start delivering notifications shortly`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue running this campaign. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = async userID => {
        try {
            // fetch user account details
            setLoading(true);
            let user = await User.get(utils, userID);

            // show user details layer
            setLoading(false);
            Utils.users.details(utils, user);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: campaign.id
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(campaign.date)
            },{
                key: 'title',
                title: 'Title',
                value: campaign.title
            },{
                key: 'message',
                title: 'Message',
                value: campaign.message
            }]
        }];
    }

    const getUsers = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(users.length === 0) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    display: 'block'
                }}>{'No users have been targeted for this campaign'}</span>
            )
        }
        return (
            <>
            {users.length > 0 && (
                users.map((user, index) => {
                    return Views.entry({
                        key: index,
                        title: user.full_name,
                        subTitle: user.email_address,
                        icon: {
                            path: user.avatar
                        },
                        bottomBorder: index !== users.length - 1,
                        onClick: onUserClick.bind(this, user.user_id)
                    })
                })
            )}
            {paging && (
                <PageControl
                limit={limit}
                offset={offset}
                loading={loading === true}
                description={paging}
                onClick={next => {
                    setLoading(true);
                    setOffset(next);
                }} />
            )}
            </>
        )
    }

    const fetchUsers = async () => {
        try {
            if(utils.fetching.get(layerID) === true) {
                return;
            }
            utils.fetching.set(layerID, true);
            let { paging, users } = await Request.get(utils, '/campaign/', {
                type: 'users_list',
                id: campaign.id,
                limit: limit,
                offset: offset
            });

            utils.fetching.set(layerID, false);
            setLoading(false);
            setPaging(paging);
            setUsers(users.map(user => User.create(user)));

        } catch(e) {
            setLoading(false);
            utils.fetching.set(layerID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the users that will be reached by this campaign. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [campaign.data, offset]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'campaigns', {
            onUpdate: next => {
                setCampaign(next.compare(abstract.object));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`"${abstract.getTitle()}" Details`}
        index={index}
        options={{
            ...options,
            loading: loading === 'init',
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>

            <FieldMapper
            utils={utils}
            fields={getFields()}/>

            <LayerItem
            title={'Targeted Users'}
            childrenStyle={{
                padding: users.length === 0 ? '8px 12px 8px 12px' : null
            }}>
                {getUsers()}
            </LayerItem>
        </Layer>
    )
}

export const DriverApplicationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `do-app-details-${abstract.getID()}`;
    const [application, setApplication] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);

    let tempContent = undefined;
    const [dropDown, setDropDown] = useState(null);
    const dropDownMethods = {
        visible: true,
        onClose: () => setDropDown(null)
    }

    const onDeleteApplication = () => {
        utils.alert.show({
            title: `Delete Application`,
            message: `Are you sure that you want to delete this application? Applications should only be deleted when they were submitted by mistake. Please approve or reject the application if possible.`,
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteApplicationConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteApplicationConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/driver/', {
                type: 'delete_onboarding_application',
                application_id: application.id
            });

            setLoading(false);
            utils.content.fetch('doApps');
            utils.alert.show({
                title: 'All Done!',
                message: `This application has been deleted`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'approve',
                title: 'Approve',
                style: 'default'
            },{
                key: 'reject',
                title: 'Reject',
                style: 'destructive'
            },{
                key: 'info',
                title: 'Request a Follow Up',
                style: 'destructive'
            },]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'approve' || key === 'reject') {
                onSetStatus(key === 'approve');
                return;
            }
            if(key === 'info') {
                onRequestFollowUp();
                return;
            }
        });
    }

    const onRequestFollowUp = () => {
        utils.alert.show({
            title: `Request a Follow Up`,
            message: `Are you sure that you want to request a follow up for this application? This will notify ${application.company ? application.company.name : application.customer.full_name} that you will be reaching out to speak with them about their application.`,
            buttons: [{
                key: 'confirm',
                title: 'Send Request',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Send Request',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRequestFollowUpConfirm();
                    return;
                }
            }
        })
    }

    const onRequestFollowUpConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { response } = await Request.post(utils, '/driver/', {
                type: 'set_application_status',
                id: application.id,
                status: Driver.Application.status.infoNeeded
            });

            abstract.object.status = Driver.Application.formatStatus(response.status);
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The follow up request for this application has been sent. Would you like to start your email follow up with ${application.customer.full_name}?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        let body = `Hello ${application.customer.full_name}, \nWe had a some questions about your driver onboarding application.`;
                        window.open(`mailto:${application.customer.email_address}?subject=${encodeURIComponent('Your Driver Application')}&body=${encodeURIComponent(body)}`)
                    }
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending the follow up request for this application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetStatus = approved => {
        utils.alert.show({
            title: `${approved ? 'Approve' : 'Reject'} Application`,
            message: `Are you sure that you want to ${approved ? 'approve' : 'reject'} this application? We will update ${application.customer.full_name} with your decision`,
            buttons: [{
                key: 'confirm',
                title: approved ? 'Approve' : 'Reject',
                style: approved ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: approved ? 'Do Not Approve' : 'Do Not Reject',
                style: approved ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm(approved);
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async approved => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { response } = await Request.post(utils, '/driver/', {
                type: 'set_application_status',
                id: application.id,
                status: approved ? Driver.Application.status.approved : Driver.Application.status.rejected
            });

            abstract.object.status = Driver.Application.formatStatus(response.status);
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This application has been ${approved ? 'approved' : 'rejected'}. ${approved ? `${application.customer.full_name}'s account has been automatically transitioned to a driver account.` : ''}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${approved ? 'approving' : 'rejecting'} this application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchLineItems = async () => {
        try {
            setLoading(true);
            let { line_items } = await Request.get(utils, '/driver/', {
                type: 'onboarding_line_items',
                application_id: abstract ? abstract.getID() : null
            });

            setLoading(false);
            setLineItems(line_items ? line_items.sections : null);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the onboarding application options. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    useEffect(() => {
        if(application.seeds && application.seeds.notes) {
            setNote(application.seeds.notes.find(note => note.deleted !== true));
        }
    }, [application]);

    useEffect(() => {
        fetchLineItems();
        utils.content.subscribe(layerID, 'doApps', {
            onUpdate: next => {
                setApplication(next.compare(abstract, fetchLineItems));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            layerState: layerState,
            loading: loading
        }}
        buttons={[{
            key: 'delete',
            text: 'Delete',
            color: 'danger',
            onClick: onDeleteApplication
        },{
            key: 'options',
            text: 'Options',
            color: 'primary',
            onClick: onOptionsClick
        }]}>
            {application.customer && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: application.customer.full_name,
                        subTitle: application.customer.phone_number,
                        icon: {
                            path: application.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, application.customer)
                    })}
                </LayerItem>
            )}

            {lineItems && lineItems.map((section, index) => {
                if(section.key === 'photos') {
                    return (
                        <LayerItem
                        key={index}
                        title={section.title}
                        childrenStyle={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            padding: '8px 12px 8px 12px'
                        }}>
                            {section.items.map((item, index) => {
                                return (
                                    <div
                                    key={index}
                                    className={'text-button'}
                                    onClick={() => window.open(item.url)}
                                    style={{
                                        ...Appearance.styles.unstyledPanel(),
                                        backgroundColor: Appearance.colors.softBorder(),
                                        marginTop: 4,
                                        marginBottom: 4,
                                        marginRight: 8,
                                        height: 40,
                                        overflow: 'hidden'
                                    }}>
                                        <img
                                        src={item.url || 'images/image-not-found.png'}
                                        style={{
                                            width: 'auto',
                                            height: '100%'
                                        }}/>
                                    </div>
                                )
                            })}
                        </LayerItem>
                    )
                }
                return (
                    <LayerItem
                    key={index}
                    title={section.title}>
                        {section.items.map((item, index, items) => {
                            return (
                                Views.row({
                                    key: index,
                                    label: item.title,
                                    value: item.formatted,
                                    bottomBorder: index !== items.length - 1
                                })
                            )
                        })}
                    </LayerItem>
                )
            })}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const ImageAssetPicker = ({ imageKey, onChange, title }, { abstract, index, options, utils }) => {

    const layerID = `image-asset-picker-${imageKey}`;
    const [image, setImage] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onChangeAsset = () => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(image);
        }
    }

    return (
        <Layer
        id={layerID}
        title={title || 'Image Asset Picker'}
        index={index}
        options={{
            ...options,
            sizing: 'small',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onChangeAsset
        }]}>
            <ImagePickerField
            utils={utils}
            fileTypes={['png']}
            onChange={image => setImage(image)} />
        </Layer>
    )
}

export const NewDriverTimeslot = ({ end, onSelectTimes, prevEntry, start }, { abstract, index, options, utils }) => {

    const layerID = `new-driver-timeslot-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [times, setTimes] = useState({
        start: start ? moment(start) : moment().startOf('day').add(8, 'hours'),
        end: end ? moment(end) : moment().startOf('day').add(17, 'hours')
    });
    const [user, setUser] = useState(abstract.object);

    const onSaveClick = () => {
        onSelectTimes(times);
        setLayerState('close');
    }

    const getAvailability = () => {
        if(!user.availability || !user.availability.available || user.availability.available.length === 0) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    whiteSpace: 'normal'
                }}>{`${abstract.getTitle()}'s available days have not been setup`}</span>
            )
        }
        return user.availability.available.map((a, index) => {
            return (
                Views.row({
                    key: index,
                    label: moment().startOf('week').add(a.day, 'days').format('dddd'),
                    bottomBorder: index !== user.availability.available.length - 1,
                    value: Utils.oxfordImplode(a.times.map(t => {
                        return `${moment(t.start, 'HH:mm:ss').format('h:mma')} to ${moment(t.end, 'HH:mm:ss').format('h:mma')}`
                    }))
                })
            )
        })
    }
    const getNonAvailability = () => {
        if(!user.availability || !user.availability.not_available || user.availability.not_available.length === 0) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    whiteSpace: 'normal'
                }}>{`${abstract.getTitle()}'s non-available days have not been setup`}</span>
            )
        }
        return Object.keys(user.availability.not_available).map((day, index) => {
            return (
                Views.row({
                    key: index,
                    value: 'All Day',
                    label: moment().startOf('week').add(day, 'days').format('dddd'),
                    bottomBorder: index !== Object.keys(user.availability.not_available).length - 1
                })
            )
        })
    }

    const getPreferredAvailability = () => {
        if(!user.availability || !user.availability.preferred || user.availability.preferred.length === 0) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    whiteSpace: 'normal'
                }}>{`${abstract.getTitle()}'s preferred availability have not been setup`}</span>
            )
        }
        return user.availability.preferred.map((a, index) => {
            return (
                Views.row({
                    key: index,
                    label: moment().startOf('week').add(a.day, 'days').format('dddd'),
                    bottomBorder: index !== user.availability.preferred.length - 1,
                    value: Utils.oxfordImplode(a.times.map(t => {
                        return `${moment(t.start, 'HH:mm:ss').format('h:mma')} to ${moment(t.end, 'HH:mm:ss').format('h:mma')}`
                    }))
                })
            )
        })
    }

    return (
        <Layer
        id={layerID}
        title={`${prevEntry ? 'Edit' : 'New'} Timeslot for ${abstract.object.first_name}`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            removeOverflow: true,
            layerState: layerState
        }}
        buttons={[{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSaveClick
        }]}>
            <LayerItem
            title={'Timeslot'}
            shouldStyle={false}>
                <TimeRangePicker
                utils={utils}
                selectedStartTime={times.start ? moment(times.start).format('HH:mm:ss') : null}
                selectedEndTime={times.end ? moment(times.end).format('HH:mm:ss') : null}
                onStartTimeChange={time => {
                    setTimes(times => {
                        return {
                            ...times,
                            start: moment(time, 'HH:mm:ss')
                        }
                    })
                }}
                onEndTimeChange={time => {
                    setTimes(times => {
                        return {
                            ...times,
                            end: moment(time, 'HH:mm:ss')
                        }
                    })
                }}/>
            </LayerItem>

            <LayerItem title={'Driver'}>
                {Views.entry({
                    title: user.full_name,
                    subTitle: user.phone_number,
                    icon: {
                        path: user.avatar
                    },
                    bottomBorder: false,
                    onClick: Utils.users.details.bind(this, utils, user)
                })}
            </LayerItem>

            <LayerItem
            title={'Available'}
            childrenStyle={{
                padding: '8px 12px 8px 12px'
            }}>
                {getAvailability()}
            </LayerItem>

            <LayerItem
            title={'Not Available'}
            childrenStyle={{
                padding: '8px 12px 8px 12px'
            }}>
                {getNonAvailability()}
            </LayerItem>

            <LayerItem
            title={'Preferred Availability'}
            childrenStyle={{
                padding: '8px 12px 8px 12px'
            }}>
                {getPreferredAvailability()}
            </LayerItem>
        </Layer>
    )
}

export const SaaSAccountDetails = ({ abstract, index, options, utils }) => {

    const layerID = `saas-account-details-${abstract.getID()}`;
    const [account, setAccount] = useState(abstract.object);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [stripeData, setStripeData] = useState(null);

    const onAddAccount = () => {
        utils.layer.open({
            id: 'add-bank-account',
            Component: AddBankAccount.bind(this, {
                client: account,
                onSubmit: onSubmitNewAccount
            })
        });
    }

    const onAssetPress = imageKey => {

        let title = null;
        let message = null;
        switch(imageKey) {
            case 'light':
            title = 'Light Mode Logo';
            message = 'Full logo, with a transparent background, that is geared towards a light user interface';
            break;

            case 'dark':
            title = 'Dark Mode Logo';
            message = 'Full logo, with a transparent background, that is geared towards a dark user interface';
            break;

            case 'icon':
            title = 'White Icon Logo';
            message = 'Square logo, with a transparent background, that is fully white and opaque';
            break;

            case 'mobile':
            title = 'Mobile App Icon';
            message = 'Image used for the mobile app when viewed on a mobile device home screen';
            break;
        }

        utils.sheet.show({
            title: title,
            message: message,
            items: [{
                key: 'view',
                title: 'View Asset',
                style: 'default'
            },{
                key: 'change',
                title: 'Change Asset',
                style: 'default'
            }]
        }, key => {
            if(key === 'view') {
                window.open(account.logos[imageKey]);
                return;
            }
            if(key === 'change') {
                utils.layer.open({
                    id: `image-asset-picker-${imageKey}`,
                    Component: ImageAssetPicker.bind(this, {
                        title: title,
                        imageKey: imageKey,
                        onChange: image => onChangeAsset(imageKey, image)
                    })
                });
                return;
            }
        })
    }

    const onBankAccountClick = bankAccount => {
        utils.sheet.show({
            title: bankAccount.name,
            message: `Ending in ${bankAccount.last4}`,
            items: [{
                key: 'set-default',
                title: 'Set as Default',
                visible: bankAccount.default !== true,
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove Account',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                onRemoveAccount(bankAccount);
                return;
            }
            if(key === 'set-default') {
                onSetAsDefault(bankAccount);
                return;
            }
        })
    }

    const onChangeAsset = async (imageKey, image) => {
        try {
            setLoading(true);
            let { url } = await Request.post(utils, '/saas/', {
                type: 'update_asset',
                app_id: account.client_id,
                image_type: imageKey,
                image: image
            });

            setLoading(false);
            abstract.object.logos[imageKey] = url;
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this image asset. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditAccount = () => {
        utils.layer.open({
            id: `edit-saas-client-account-${account.id}`,
            abstract: Abstract.create({
                type: 'saas',
                object: account
            }),
            Component: AddEditSaaSClientAccount.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'payment',
                title: 'Make a Payment',
                style: 'default'
            },{
                key: 'add-bank',
                title: 'Add a Bank Account',
                style: 'default'
            },{
                key: 'toggle-active',
                title: `${account.active ? 'Deactivate' : 'Activate'} Account`,
                style: account.active ? 'destructive' : 'default',
                visible: window.clientParameters ? false : true
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'payment') {
                Utils.payments.make(utils, abstract);
                return;
            }
            if(key === 'add-bank') {
                onAddAccount();
                return;
            }
            if(key === 'toggle-active') {
                onSetClientStatus();
                return;
            }
        });
    }

    const onRemoveAccount = bankAccount => {
        if(bankAccount.default) {
            utils.alert.show({
                title: 'Just a Second',
                message: `You can not remove the default bank account. Please add another account and set it as the default account if you wish to remove this account.`
            });
            return;
        }

        if(stripeData.external_accounts.length === 1) {
            utils.alert.show({
                title: 'Just a Second',
                message: `It looks like you only have one account added. You'll need to add another account before you can remove this account.`
            });
            return;
        }

        utils.alert.show({
            title: 'Remove Account',
            message: 'Are you sure that you want to remove this account? You will no longer be able to receive future funds using this account but any scheduled payouts will still be deposited',
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveAccountConfirm(bankAccount);
                    return;
                }
            }
        })
    }

    const onRemoveAccountConfirm = async bankAccount => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await bankAccount.remove(utils, account);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The account "${bankAccount.name}" has been removed and will no longer be available as a payment source or destination for ${account.name}.`
            });
            fetchStripeDetails();

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this bank account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetClientStatus = () => {
        utils.alert.show({
            title: `${account.active ? 'Deactivate' : 'Activate'} Account`,
            message: `Are you sure that you want to ${account.active ? 'deactivate' : 'activate'} this client account? ${account.active ? 'Deactivating this client will revoke their access to the platform and disable their mobile app for all users':`Activating this client will open up access for ${account.name} to the platform, Seeds, and the mobile app`}`,
            buttons: [{
                key: 'confirm',
                title: account.active ? 'Deactivate' : 'Activate',
                style: account.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: account.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetClientStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetClientStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/saas/', {
                type: 'set_account_status',
                id: account.id,
                status: next
            });

            utils.content.fetch('saas');
            abstract.object.active = next;
            setAccount(abstract.object);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This account has been ${next ? 'activated' : 'deactivated'}. These changes will take effect immediately.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetAsDefault = bankAccount => {
        utils.alert.show({
            title: 'Set as Default Account',
            message: 'Setting this account as your default bank account will route all future payments and payouts to this account. This includes all revenue from Orders and Reservations as well as any platform specific service charges or fees.',
            buttons: [{
                key: 'confirm',
                title: 'Set as Default',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetAsDefaultConfirm(bankAccount);
                    return;
                }
            }
        })
    }

    const onSetAsDefaultConfirm = async bankAccount => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await bankAccount.setAsDefault(utils, account);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The account "${bankAccount.name}" has been set as the default payment source and destination for ${account.name}.`
            });
            fetchStripeDetails();

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSubmitNewAccount = async (props, callback) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { name } = await Request.post(utils, '/saas/', {
                    type: 'add_bank_account',
                    app_id: account.client_id,
                    ...props
                });
                utils.alert.show({
                    title: 'All Done!',
                    message: `The account "${name}" has been added and is now available as a payment source and destination for ${account.name}.`
                });
                fetchStripeDetails();
                resolve();

            } catch(e) {
                reject(e);
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue adding this bank account. ${e.message || 'An unknown error occurred'}`
                })
            }
        });
    }

    const getAccountVerificationPreferences = () => {
        return account.preferences.mobile.account_verifications || 'email';
    }

    const getFields = () => {
        let fields = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: account.id
            },{
                key: 'client_id',
                title: 'Client ID',
                value: account.client_id
            },{
                key: 'name',
                title: 'Name',
                value: account.name
            },{
                key: 'tagline',
                title: 'Tagline',
                value: account.tagline
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(account.date)
            },{
                key: 'login_url',
                title: 'Login URL',
                value: 'Click to Open',
                onClick: () => window.open(account.login_url)
            },{
                key: 'active',
                title: 'Status',
                value: account.active ? 'Activated' : 'Deactivated'
            }]
        },{
            key: 'contact',
            title: 'Contact',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                value: account.email_address
            },{
                key: 'phone_number',
                title: 'Phone Number',
                value: account.phone_number
            },{
                key: 'location',
                title: 'Location',
                value: abstract.object.getFormattedAddress()
            }]
        },{
            key: 'about',
            title: `About ${account.name}`,
            items: [{
                key: 'subsription',
                title: 'Subscription',
                value: account.subscription ? account.subscription.name : 'Not Available'
            },{
                key: 'theme_color',
                title: 'Theme Color',
                value: (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.value(),
                            marginRight: 8
                        }}>{account.colors.regular}</span>
                        <div style={{
                            width: 19,
                            height: 19,
                            borderRadius: '50%',
                            backgroundColor: account.colors.regular
                        }} />
                    </div>
                )
            }]
        },{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                key: 'account_verifications',
                title: 'New User Account Verifications',
                value: getAccountVerificationPreferences() === 'sms' ? 'Text Message' : 'Email'
            }]
        },{
            key: 'service_fees',
            title: 'Service Fees',
            items: [{
                key: 'campaigns',
                title: 'Campaign Push Notifications',
                value: getServiceFee('campaign_push_notifications')
            },{
                key: 'credits',
                title: 'Credits',
                value: getServiceFee('credits')
            },{
                key: 'general',
                title: 'General',
                value: getServiceFee('general')
            },{
                key: 'news_items',
                title: 'News Items (Per Click)',
                value: getServiceFee('news_items')
            },{
                key: 'on_demand',
                title: 'On Demand Rides',
                value: getServiceFee('on_demand')
            },{
                key: 'orders',
                title: 'Orders',
                value: getServiceFee('orders')
            },{
                key: 'reservations',
                title: 'Reservations',
                value: getServiceFee('reservations')
            },{
                key: 'rewards',
                title: 'Rewards',
                value: getServiceFee('rewards')
            },{
                key: 'quick_scan',
                title: 'Routes',
                value: getServiceFee('quick_scan')
            },{
                key: 'subscriptions',
                title: 'Subscriptions',
                value: getServiceFee('subscriptions')
            }]
        }]

        if(stripeData && stripeData.legal_entity) {
            fields = fields.concat([{
                key: 'stripe',
                title: 'Stripe Entity',
                items: [{
                    key: 'name',
                    title: 'Busines Name',
                    value: stripeData.legal_entity.business_name || 'Not Setup'
                },{
                    key: 'address',
                    title: 'Address',
                    value: stripeData.legal_entity.address ? `${stripeData.legal_entity.address.line1} ${stripeData.legal_entity.address.city}  ${stripeData.legal_entity.address.state} ${stripeData.legal_entity.address.postal_code}` : 'Not Setup'
                },{
                    key: 'phone_number',
                    title: 'Phone Number',
                    value: stripeData.legal_entity.phone_number ? Utils.formatPhoneNumber(stripeData.legal_entity.phone_number) : 'Not Setup'
                },{
                    key: 'tax_id',
                    title: 'Tax ID',
                    value: stripeData.legal_entity.tax_id_provided ? 'Provided' : 'Not Provided'
                },{
                    key: 'owners',
                    title: 'Owners',
                    value: stripeData.legal_entity.owners_provided ? 'Provided' : 'Not Provided'
                }]
            }])
        }
        if(stripeData) {
            fields = fields.concat([{
                key: 'stripe_details',
                title: 'Stripe Details',
                items: [{
                    key: 'charges',
                    title: 'Charges',
                    value: stripeData.charges_enabled ? 'Enabled' : 'Disabled',
                    color: stripeData.charges_enabled ? null : Appearance.colors.red
                },{
                    key: 'payouts',
                    title: 'Payouts',
                    value: stripeData.payouts_enabled ? 'Enabled' : 'Disabled',
                    color: stripeData.payouts_enabled ? null : Appearance.colors.red
                },{
                    key: 'negative_balance',
                    title: 'Account Health',
                    value: stripeData.negative_balance ? 'Overdrawn' : 'Good Standing',
                    color: stripeData.negative_balance ? Appearance.colors.red : null
                },{
                    key: 'statement_descriptor',
                    title: 'Statement Descriptor',
                    value: stripeData.statement_descriptor
                },{
                    key: 'external_accounts',
                    title: 'External Accounts',
                    value: stripeData.external_accounts.map((account, index) => (
                        <div
                        key={index}
                        className={'text-button'}
                        onClick={onBankAccountClick.bind(this, account)}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            paddingBottom: index === stripeData.external_accounts.length - 1 ? 0:4
                        }}>
                            <span style={Appearance.textStyles.value()}>{account.summary()}</span>
                            <i
                            className={account.icon()}
                            style={{
                                fontSize: 20,
                                paddingLeft: 8,
                                color: Appearance.colors.text()
                            }}/>
                        </div>
                    ))
                }]
            }])
        }
        return fields;
    }

    const getServiceFee = key => {
        if(!account || !account.subscription || !account.subscription.fees) {
            return 'Not Available';
        }
        if(key === 'news_items') {
            return account.subscription.fees[key] ? `${Utils.toCurrency(account.subscription.fees[key])}` : 'Not Available';
        }
        return account.subscription.fees[key] ? `${parseFloat(account.subscription.fees[key] * 100).toFixed(1)}%` : 'Not Available';
    }

    const fetchStripeDetails = async () => {
        try {
            let { stripe_account } = await Request.get(utils, '/saas/',  {
                type: 'stripe_account',
                app_id: account.client_id
            });
            setLoading(false);
            setStripeData(stripe_account && {
                ...stripe_account,
                external_accounts: stripe_account.external_accounts.map(account => BankAccount.create(account))
            });

        } catch(e) {
            setLoading(false);
            console.error(e.message);
        }
    }

    useEffect(() => {
        if(account.seeds && account.seeds.notes) {
            setNote(account.seeds.notes.find(note => !note.deleted));
        }
    }, [account]);

    useEffect(() => {
        fetchStripeDetails();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditAccount
        }]}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 25
            }}>
                {Views.entry({
                    title: account.name,
                    subTitle: account.email_address || 'Phone Number Not Available',
                    icon: {
                        path: account.logos.mobile
                    },
                    bottomBorder: false
                })}
            </div>

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerItem
            title={'Image Assets'}
            childrenStyle={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
                padding: 8
            }}>
                {Object.keys(account.logos).map((key, index) => (
                    <div
                    key={key}
                    className={'text-button'}
                    onClick={onAssetPress.bind(this, key)}
                    style={{
                        padding: key === 'mobile' ? 0 : 5,
                        borderRadius: 10,
                        backgroundColor: key === 'dark' || key === 'icon' ? Appearance.colors.softBorder() : Appearance.colors.panelBackground(),
                        border: key !== 'mobile' ? `1px solid ${Appearance.colors.softBorder()}` : null,
                        marginRight: 8,
                        height: 40,
                        overflow: 'hidden'
                    }}>
                        <img
                        src={account.logos[key]}
                        style={{
                            width: 'auto',
                            height: '100%'
                        }}/>
                    </div>
                ))}
            </LayerItem>

            {!window.clientParameters && (
                <LayerNote
                note={note}
                utils={utils}
                abstract={abstract} />
            )}
        </Layer>
    )
}

export const SupportTicketDetails = ({ abstract, index, options, utils }) => {

    const layerID = `support-ticket-details-${abstract.getID()}`;
    const [dropDown, setDropDown] = useState(null);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [stripeData, setStripeData] = useState(null);
    const [ticket, setTicket] = useState(abstract.object);

    const onAddTag = () => {
        utils.sheet.show({
            items: Object.values(SupportTicket.tags).map(t => {
                let tag = SupportTicket.formatTag(t);
                return {
                    key: t,
                    title: tag ? tag.text : '',
                    style: 'default'
                };
            }).sort((a, b) => a.title > b.title)
        }, key => {
            if(key !== 'cancel') {
                onAddTagConfirm(key);
                return;
            }
        })
    }

    const onAddTagConfirm = async text => {
        try {
            setLoading(true);
            await Request.post(utils, '/saas/', {
                type: 'add_support_ticket_tag',
                id: ticket.id,
                tag: text
            });

            setLoading(false);
            if(!abstract.object.tags) {
                abstract.object.tags = [];
            }
            abstract.object.tags.push(SupportTicket.formatTag(text));
            setTicket({ ...abstract.object });
            utils.content.update(abstract);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding this tag. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-support-ticket-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditSupportTicket.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'status',
                title: 'Set Status',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'status') {
                onShowEvents();
                return;
            }
        });
    }

    const onRemoveTagConfirm = async tag => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/saas/', {
                type: 'remove_support_ticket_tag',
                id: ticket.id,
                tag: tag
            });

            setLoading(false);
            abstract.object.tags = abstract.object.tags.filter(t => t.tag !== tag);
            setTicket({ ...abstract.object });
            utils.content.update(abstract);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this tag. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowEvents = () => {
        setDropDown({
            title: 'Events and Status Changes',
            message: 'The support ticket event log shows the journey of the ticket from submission to completion. Events should only be manually added by someone working on the ticket.',
            content: (
                <SupportEventManager
                utils={utils}
                ticket={abstract.object} />
            )
        });
    }

    const onTagClick = tag => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove Tag',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                onRemoveTagConfirm(tag);
                return;
            }
        })
    }

    const getBottomFields = () => {
        return [{
            key: 'web_browser',
            title: 'Web Browser',
            items: [{
                key: 'type',
                title: 'Name',
                value: ticket.content && ticket.content.browser ? ticket.content.browser.type : 'Not Added'
            },{
                key: 'version',
                title: 'Version',
                value: ticket.content && ticket.content.browser ? ticket.content.browser.version : 'Not Added'
            }]
        },{
            key: 'operating_system',
            title: 'Operating System',
            items: [{
                key: 'type',
                title: 'Name',
                value: ticket.content && ticket.content.os ? ticket.content.os.type : 'Not Added'
            },{
                key: 'version',
                title: 'Version',
                value: ticket.content && ticket.content.os ? ticket.content.os.version : 'Not Added'
            }]
        },{
            key: 'mobile_app',
            title: 'Mobile App',
            items: [{
                key: 'platform',
                title: 'Platform',
                value: ticket.content && ticket.content.mobile_app ? ticket.content.mobile_app.platform : 'Not Added'
            },{
                key: 'version',
                title: 'Version',
                value: ticket.content && ticket.content.mobile_app ? ticket.content.mobile_app.version : 'Not Added'
            }]
        },{
            key: 'behavior',
            title: 'Behavior',
            items: [{
                key: 'type',
                title: 'Expected',
                value: ticket.content && ticket.content.behavior ? ticket.content.behavior.expected : 'Not Added'
            },{
                key: 'actual',
                title: 'Actual',
                value: ticket.content && ticket.content.behavior ? ticket.content.behavior.actual : 'Not Added'
            }]
        }];
    }

    const getTags = () => {
        if(!ticket.tags || ticket.tags.length === 0) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    display: 'block'
                }}>{`No tags have been added for "${abstract.getTitle()}"`}</span>
            )
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                {ticket.tags && ticket.tags.map((ticket, index) => {
                    return (
                        <AltBadge
                        key={index}
                        onClick={onTagClick.bind(this, ticket.tag)}
                        style={{
                            marginRight: 8
                        }}
                        content={{
                            text: ticket.text,
                            color: ticket.color
                        }} />
                    )
                })}
            </div>
        );
    }

    const getTopFields = () => {
        if(!ticket) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: ticket.id
            },{
                key: 'client_id',
                title: 'Client',
                value: ticket.client_id !== 'ecarra' && ticket.parameters ? ticket.parameters.name : null
            },{
                key: 'status',
                title: 'Status',
                value: ticket.status ? ticket.status.text : null
            },{
                key: 'date',
                title: 'Date Added',
                value: ticket.date ? moment(ticket.date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'message',
                title: 'Message',
                value: ticket.message
            },{
                key: 'events',
                title: 'Events and Status Changes',
                value: 'Click to View',
                onClick: onShowEvents
            }]
        }]
    }

    useEffect(() => {
        if(ticket.seeds && ticket.seeds.notes) {
            setNote(ticket.seeds.notes.find(note => !note.deleted))
        }
    }, [ticket]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'supportTickets', {
            onUpdate: next => {
                setTicket(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID, 'supportTickets');
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            {ticket.submitted_by && (
                <LayerItem title={'Submitted By'}>
                    {Views.entry({
                        title: ticket.submitted_by.full_name,
                        subTitle: ticket.submitted_by.email_address,
                        icon: {
                            path: ticket.submitted_by.avatar
                        },
                        bottomBorder: false
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getTopFields()} />

            <LayerItem
            title={'Tags'}
            childrenStyle={{
                padding: '8px 12px 8px 12px'
            }}
            rightContent={(
                <Button
                type={'small'}
                label={'Add Tag'}
                color={'primary'}
                onClick={onAddTag} />
            )}>
                {getTags()}
            </LayerItem>

            <FieldMapper
            utils={utils}
            fields={getBottomFields()} />

            <LayerItem
            title={'Steps to Reproduce'}
            childrenStyle={{
                padding: '8px 12px 8px 12px'
            }}>
                <span style={{
                    ...Appearance.textStyles.key(),
                    display: 'block',
                    wordWrap: 'break-word',
                    whiteSpace: 'break-spaces'
                }}>{ticket.content && ticket.content.reproduce ? ticket.content.reproduce : 'No steps to reproduce were left for this ticket'}</span>
            </LayerItem>

            {window.client_id !== 'ecarra' && (
                <LayerItem
                title={'Tags'}
                rightContent={(
                    <img
                    src={'images/new-icon-grey-small.png'}
                    className={'text-button'}
                    onClick={onAddTag}
                    style={{
                        width: 20,
                        height: 20,
                        marginLeft: 8
                    }}/>
                )}>
                    {!ticket.tags || ticket.tags.length === 0
                        ?
                        <span style={Appearance.textStyles.key()}>{`No tags have been selected for "${abstract.getTitle()}"`}</span>
                        :
                        ticket.tags.map((ticket, index) => {
                            return (
                                <div
                                key={index}
                                className={'text-button'}
                                onClick={onTagClick.bind(this, ticket.tag)}
                                style={{
                                    display: 'flex',
                                    padding: '1.5px 7px 1.5px 7px',
                                    borderRadius: 20,
                                    backgroundColor: ticket.color,
                                    alignItems: 'center',
                                    marginRight: 4,
                                    boxShadow: '3px 3px 4px rgba(174,174,174,0.35), -3px -3px 4px #ffffff'
                                }}>
                                    <span style={{
                                        fontSize: 8,
                                        color: 'white',
                                        fontWeight: '600'
                                    }}>{ticket.text.toUpperCase()}</span>
                                </div>
                            )
                        })
                    }
                </LayerItem>
            )}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />

            {ticket.attachments && ticket.attachments.length > 0 && (
                <LayerItem
                title={'Attachments'}
                childrenStyle={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%',
                    padding: '8px 12px 8px 12px'
                }}>
                    {ticket.attachments.map((attachment, index) => {
                        return (
                            <div
                            key={index}
                            className={'text-button'}
                            onClick={() => window.open(attachment.url)}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                backgroundColor: Appearance.colors.panelBackground(),
                                marginRight: 8,
                                marginTop: 4,
                                marginBottom: 4,
                                height: 50,
                                overflow: 'hidden'
                            }}>
                                <img
                                src={attachment.url}
                                style={{
                                    width: 'auto',
                                    height: '100%'
                                }}/>
                            </div>
                        )
                    })}
                </LayerItem>
            )}
        </Layer>
    )
}

export const UserDetails = ({ abstract, index, options, utils }) => {

    const layerID = `user-details-${abstract.getID()}`;
    const limit = 5;

    const [creditMethods, setCreditMethods] = useState(null);
    const [dropDown, setDropDown] = useState(null);
    const [emissions, setEmissions] = useState(null);
    const [events, setEvents] = useState([]);
    const [feedbackResponses, setFeedbackResponses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [online, setOnline] = useState(false);
    const [paging, setPaging] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [user, setUser] = useState(abstract.object);
    const [version, setVersion] = useState(abstract.object.version);

    const onCreditsMethodClick = async method => {
        try {
            setLoading(true);
            let response = await Request.get(utils, '/credits/',  {
                type: 'details',
                user_id: user.user_id,
                card_id: method.card.id
            });

            setLoading(false);
            if(!response || !response.card) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `We were unable to locate the account for ${abstract.getTitle()}`
                })
                return;
            }
            let card = CreditsCard.create(response);
            Utils.credits.details(utils, card);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the details for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-user-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditUser.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onManagePaymentMethods = () => {
        setDropDown({
            title: 'Payment Methods',
            message: `The payment methods attached to an account are used for anything payment related across the platform. Every account must have at least one payment method.`,
            content: (
                <PaymentMethodManager
                utils={utils}
                target={abstract} />
            )
        });
    }

    const onOnlineStatusChange = data => {
        try {
            let { online } = JSON.parse(data);
            setOnline(online ? true : false);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin
            },{
                key: 'orders',
                title: 'View Orders',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin || utils.user.get().order_host
            },{
                key: 'reservations',
                title: 'View Reservations',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin || !utils.user.get().order_host
            },{
                key: 'payment',
                title: 'Make a Payment',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin
            },{
                key: 'methods',
                title: 'Manage Payment Methods',
                style: 'default'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'payment') {
                Utils.payments.make(utils, abstract);
                return;
            }
            if(key === 'methods') {
                onManagePaymentMethods();
                return;
            }
            if(key === 'orders') {
                onViewOrders();
                return;
            }
            if(key === 'reservations') {
                onViewReservations();
                return;
            }
        })
    }

    const onPaymentMethodClick = async method => {
        try {
            await Utils.getPaymentMethodOptions(utils, abstract, method);
            setPaymentMethods(abstract.object.payment_methods);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onRequestOnlineStatus = () => {
        utils.sockets.emit('system', 'isUserOnline', { user_id: user.user_id });
    }

    const onSendPushNotification = user => {
        utils.layer.open({
            id: `user-notification-${user.user_id}`,
            abstract: Abstract.create({
                type: 'users',
                object: user
            }),
            Component: SendPushNotification
        })
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onViewOrders = async () => {
        try {
            setLoading(true);
            let { orders } = await user.getOrders(utils);
            if(!orders || orders.length === 0) {
                setLoading(false);
                utils.alert.show({
                    title: 'Orders',
                    message: `It doesn't look like ${user.full_name} has placed any Orders`
                });
                return;
            }

            setLoading(false);
            utils.layer.open({
                id: `all-user-orders-${user.user_id}`,
                abstract: user,
                Component: LayerShell.bind(this, {
                    layerID: `all-user-orders-${user.user_id}`,
                    title: `All Orders for ${abstract.getTitle()}`,
                    layerOptions: {
                        sizing: 'medium'
                    },
                    children: orders.map((order, index) => (
                        Views.entry({
                            key: order.id,
                            title: order.customer.full_name,
                            subTitle: order.host ? `Ordered from ${order.host.name}` : 'Destination Not Available',
                            supportingTitle: moment(order.drop_off_date).format('MMMM Do, YYYY [at] h:mma'),
                            badge: order.status,
                            onClick: Utils.orders.details.bind(this, utils, order),
                            bottomBorder: index !== orders.length - 1,
                            icon: {
                                path: order.customer.avatar,
                                style: {
                                    ...Appearance.icons.standard(),
                                    alignSelf: 'flex-start'
                                }
                            }
                        })
                    ))
                })
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue locating the Orders for ${user.full_name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onViewReservations = async () => {
        try {
            setLoading(true);
            let { reservations } = await user.getReservations(utils);
            if(!reservations || reservations.length === 0) {
                setLoading(false);
                utils.alert.show({
                    title: 'Orders',
                    message: `It doesn't look like ${user.full_name} has booked any Reservations`
                });
                return;
            }

            setLoading(false);
            utils.layer.open({
                id: `all-user-reservations-${user.user_id}`,
                abstract: user,
                Component: LayerShell.bind(this, {
                    layerID: `all-user-reservations-${user.user_id}`,
                    title: `All Reservations for ${abstract.getTitle()}`,
                    layerOptions: {
                        sizing: 'medium'
                    },
                    children: reservations.map((reservation, index) => (
                        Views.entry({
                            key: reservation.id,
                            title: reservation.customer.full_name + ' (' + reservation.id + ')',
                            subTitle: reservation.destination.address || 'Destination Not Chosen',
                            supportingTitle: moment(reservation.pickup_date).format('MMMM Do [at] h:mma'),
                            badge: {
                                text: reservation.status.text,
                                color: reservation.status.color
                            },
                            icon: {
                                style: Appearance.icons.standard(),
                                path: reservation.customer.avatar
                            },
                            reservation: reservation,
                            bottomBorder: (index !== reservations.length - 1),
                            onClick: Utils.reservations.details.bind(this, utils, reservation)
                        }, utils)
                    ))
                })
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue locating the Reservations for ${user.full_name}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onViewAccountPassword = () => {
        utils.layer.open({
            id: `reset-password-${user.user_id}`,
            abstract: Abstract.create({
                type: 'users',
                object: user
            }),
            Component: ResetPassword
        });
    }

    const getBottomFields = () => {
        let fields = [];
        if(utils.user.get().level <= User.level.admin) {
            fields = fields.concat([{
                key: 'resources',
                title: 'Resources',
                items: [{
                    key: 'password',
                    title: 'Reset Password',
                    value: 'Click to Reset',
                    onClick: onViewAccountPassword
                },{
                    key: 'send_push',
                    title: 'Send Push Notification',
                    value: 'Click to Create',
                    onClick: onSendPushNotification.bind(this, user)
                }]
            },{
                key: 'feedback',
                title: 'Recent Feedback',
                items: feedbackResponses.map((response, index) => ({
                    key: index,
                    title: Utils.formatDate(response.date),
                    value: response.recommendation ? Utils.ucFirst(response.recommendation.text) : null,
                    onClick: Utils.feedback.details.bind(this, utils, response)
                }))
            }])
        }
        return fields;
    }

    const getCommissionRate = category => {

        // prevent moving forward if no payments account or commissions are setup
        if(!user.direct_payments_account || !user.direct_payments_account.commission) {
            return 'Not Setup';
        }

        // define default commission rate key
        let key = user.direct_payments_account.commission[category].default;
        return `${user.direct_payments_account.commission[category][key] ? user.direct_payments_account.commission[category][key] * 100 : 0}%`;
    }

    const getCreditsComponents = () => {
        if(!creditMethods) {
            return Views.loader({ justifyContent: 'flex-end' });
        }
        if(creditMethods.length === 0) {
            return 'No Accounts Found';
        }
        return creditMethods.map((method, index, methods) => {
            return (
                <div
                key={index}
                className={'text-button'}
                onClick={onCreditsMethodClick.bind(this, method)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingBottom: index === methods.length - 1 ? 0:4
                }}>
                    <span style={Appearance.textStyles.value()}>{method.getSummary()}</span>
                    <i
                    className={method.icon()}
                    style={{
                        fontSize: 20,
                        paddingLeft: 8,
                        color: Appearance.colors.text()
                    }}/>
                </div>
            )
        });
    }

    const getEmissions = () => {
        if(user.level <= User.level.driver) {
            return null;
        }
        if(!emissions) {
            return (
                <LayerItem title={'Emissions'}>
                    {Views.loader()}
                </LayerItem>
            )
        }
        return (
            <LayerItem
            title={'Emissions'}
            shouldStyle={false}>
                <div className={'row p-0 m-0'}>
                    {emissions.items.map(item => {
                        let min = item.graph ? Math.min(...item.graph.data) : 0;
                        let max = item.graph ? Math.max(...item.graph.data) : 0;
                        let hasChart = item.graph && item.graph.labels.length > 3;
                        return (
                            <div
                            key={item.key}
                            className='col-12 col-md-4 p-1 m-0 text-center'>
                                <div
                                className={'px-0 py-2'}
                                style={{
                                    ...Appearance.styles.unstyledPanel(),
                                    overflow: 'visible'
                                }}>
                                    <div
                                    className='px-2 pt-2 pb-0'
                                    style={{
                                        height: 75
                                    }}>
                                        {hasChart && (
                                            <Line
                                            width={100}
                                            height={40}
                                            data={{
                                                labels: item.graph.labels,
                                                datasets: [{
                                                    borderColor: Appearance.colors.primary(),
                                                    fill: false,
                                                    pointRadius: 0,
                                                    data: item.graph.data
                                                }]
                                            }}
                                            options={{
                                                title: {
                                                    display: false
                                                },
                                                legend: {
                                                    display: false
                                                },
                                                responsive: true,
                                                maintainAspectRatio: true,
                                                scales: {
                                                    xAxes: [{
                                                        gridLines: {
                                                            color: Appearance.colors.transparent,
                                                            display: false
                                                        },
                                                        ticks: {
                                                            display: false
                                                        }
                                                    }],
                                                    yAxes: [{
                                                        gridLines: {
                                                            color: Appearance.colors.transparent,
                                                            display: false
                                                        },
                                                        ticks: {
                                                            display: false,
                                                            max: max - (min - max / 50)
                                                        }
                                                    }]
                                                }
                                            }} />
                                        )}
                                    </div>
                                    <div
                                    className={'text-center w-100'}
                                    style={{
                                        display: hasChart ? 'none' : 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 40
                                    }}>
                                      <img
                                      src={'images/no-data-found.png'}
                                      className={'no-data-found'}
                                      style={{
                                          height: '100%',
                                          maxWidth: 40,
                                          objectFit: 'contain'
                                      }} />
                                    </div>
                                    <span
                                    className={'d-block'}
                                    style={Appearance.textStyles.title()}>{item.label || item.placeholder}</span>
                                    <span
                                    className={'d-block'}
                                    style={Appearance.textStyles.subTitle()}>{item.value}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </LayerItem>
        )
    }

    const getMobileApp = () => {
        if(!version) {
            return (
                <LayerItem
                title={'Mobile App'}
                childrenStyle={{
                    padding: '8px 12px 8px 12px'
                }}>
                    {Views.loader()}
                </LayerItem>
            )
        }
        let fields = [{
            key: 'mobile_app',
            title: 'Mobile App',
            items: [{
                key: 'status',
                title: 'Status',
                color: online && Appearance.colors.green,
                value: online ? 'Online' : 'Not Online'
            },{
                key: 'device',
                title: 'Device',
                value: version.device
            },{
                key: 'platform',
                title: 'Platform',
                value: version.platform
            },{
                key: 'version',
                title: 'Version',
                value: version.version
            },{
                key: 'system_version',
                title: 'System Version',
                value: version.system_version
            }]
        }];
        return (
            <FieldMapper
            utils={utils}
            fields={fields} />
        )
    }

    const getPaymentMethodComponents = () => {
        if(!paymentMethods) {
            return Views.loader({ justifyContent: 'flex-end' });
        }
        if(paymentMethods.length === 0) {
            return 'No Methods Found';
        }
        return paymentMethods.map((method, index, methods) => {
            return (
                <div
                key={method.id}
                className={'text-button'}
                onClick={onPaymentMethodClick.bind(this, method)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingBottom: index === methods.length - 1 ? 0:4
                }}>
                    <span style={Appearance.textStyles.value()}>{method.summary()}</span>
                    <i
                    className={method.icon()}
                    style={{
                        fontSize: 20,
                        paddingLeft: 8,
                        color: Appearance.colors.text()
                    }}/>
                </div>
            )
        });
    }

    const getRevenueShareRate = category => {

        // prevent moving forward if no payments account or revenue shares are setup
        if(!user.direct_payments_account || !user.direct_payments_account.revenue_share) {
            return 'Not Setup';
        }

        // define default revenue share rate key
        let key = user.direct_payments_account.revenue_share[category].default;
        return `${user.direct_payments_account.revenue_share[category][key] ? user.direct_payments_account.revenue_share[category][key] * 100 : 0}%`;
    }

    const getTopFields = () => {

        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'user_id',
                title: 'User ID',
                value: user.user_id
            },{
                key: 'username',
                title: 'Username',
                value: user.username
            },{
                key: 'password',
                title: 'Password',
                value: 'Click to View',
                visible: utils.user.get().level <= User.level.admin,
                onClick: onViewAccountPassword
            },{
                key: 'level',
                title: 'Account Type',
                value: user.level ? User.formatLevel(user.level).text : null
            },{
                key: 'member_since',
                title: 'Member Since',
                value: Utils.formatDate(user.member_since)
            },{
                key: 'last_login',
                title: 'Last Login',
                value: Utils.formatDate(user.last_login)
            },{
                key: 'referral_code',
                title: 'Referral Code',
                value: user.referral_code
            }]
        },{
            key: 'contact',
            title: 'Contact',
            items: [{
                key: 'phone_number',
                title: 'Phone Number',
                value: user.phone_number
            },{
                key: 'email_address',
                title: 'Email Address',
                value: user.email_address
            },{
                key: 'location',
                title: 'Location',
                value: Utils.formatAddress(user)
            }]
        },{
            key: 'payments',
            title: 'Payments',
            items: [{
                key: 'total_spent',
                title: 'Total Spent',
                value: Utils.toCurrency(user.total_spent || 0)
            },{
                key: 'credits',
                title: 'Credits',
                value: getCreditsComponents()
            },{
                key: 'methods',
                title: 'Methods',
                value: getPaymentMethodComponents()
            }]
        }];

        // determine if a direct payments entry should be created
        if(user.direct_payments_account) {

            let { balance, commission, enabled, external_accounts, revenue_share } = user.direct_payments_account;
            items.push({
                key: 'direct_payments_account',
                title: 'Direct Payments Preferences',
                visible: utils.user.get().level <= User.level.company_admin ? true : false,
                items: [{
                    key: 'external_accounts',
                    title: 'Destinations',
                    value: external_accounts && external_accounts.length > 0 ? 'Setup' : 'Not Setup'
                },{
                    key: 'enabled',
                    title: 'Status',
                    value: enabled ? 'Enabled' : 'Disabled'
                }]
            });
    
            // add entry for direct payments account balances
            items.push({
                key: 'direct_payments_account_balances',
                title: 'Direct Payments Account Balance',
                visible: utils.user.get().level <= User.level.company_admin ? true : false,
                items: [{
                    key: 'available_balance',
                    title: 'Available Balance',
                    value: balance && Utils.toCurrency(balance.available),
                    visible: balance && isNaN(balance.available) === false ? true : false,
                    ...balance && balance.available < 0 && {
                        color: Appearance.colors.red
                    }
                },{
                    key: 'instant_balance',
                    title: 'Instant Balance',
                    value: balance && Utils.toCurrency(balance.instant),
                    visible: balance && isNaN(balance.instant) === false ? true : false,
                    ...balance && balance.instant < 0 && {
                        color: Appearance.colors.red
                    }
                },{
                    key: 'pending_balance',
                    title: 'Pending Balance',
                    value: balance && Utils.toCurrency(balance.pending),
                    visible: balance && isNaN(balance.pending) === false ? true : false,
                    ...balance && balance.pending < 0 && {
                        color: Appearance.colors.red
                    }
                }]
            })
    
            // add direct payments commission if applicable
            if(commission) {
                items.push({
                    key: 'commission',
                    title: 'Commission',
                    items: [{
                        key: 'enabled',
                        title: 'Enabled',
                        value: commission.enabled ? 'Yes' : 'No'
                    },{
                        key: 'order_commission',
                        title: 'Deliveries',
                        value: getCommissionRate('orders')
                    },{
                        key: 'reservation_commission',
                        title: 'Rides',
                        value: getCommissionRate('reservations')
                    }]
                });
            }
    
             // add direct payments revenue share if applicable
             if(revenue_share) {
                items.push({
                    key: 'revenue_share',
                    title: 'Referral Revenue Share',
                    items: [{
                        key: 'enabled',
                        title: 'Enabled',
                        value: revenue_share.enabled ? 'Yes' : 'No'
                    },{
                        key: 'orders',
                        title: 'Delivery Revenue Share',
                        value: getRevenueShareRate('orders')
                    },{
                        key: 'reservations',
                        title: 'Ride Revenue Share',
                        value: getRevenueShareRate('reservations')
                    }]
                });
            }
        }
        return items;
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = async () => {
        try {

            let { emissions, feedback, mobile_app_version, direct_payments_account, payment_sources } = await Request.get(utils, '/user/', {
                type: 'overview',
                user_id: abstract.getID(),
            });

            // set most recent mobile app version details
            setVersion(mobile_app_version);

            // set direct payments account details
            abstract.object.direct_payments_account = direct_payments_account;
            setUser(abstract.object);

            // set most recent feedback responses if applicable
            setFeedbackResponses(feedback && feedback.map(response => FeedbackResponse.create(response)));

            // fetch standard payments methods and credit accounts
            setCreditMethods(payment_sources.credits && payment_sources.credits.map(acct => CreditsCard.create(acct)) || []);
            setPaymentMethods(payment_sources.sources && payment_sources.sources.data && payment_sources.sources.data.map(source => {
                return PaymentMethod.create({
                    ...source,
                    default: payment_sources.default_source === source.id
                })
            }) || []);

            // prepare emissions data if user is a customer or company admin
            setEmissions(emissions && {
                chart: emissions,
                items: [{
                    key: 'carbon',
                    label: 'Carbon',
                    raw: emissions ? emissions.carbon.all_time : 0,
                    value: emissions ? (parseInt(emissions.carbon.all_time).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Grams') : 'Unknown',
                    graph: emissions ? emissions.graphing.carbon.all_time : null
                },{
                    key: 'gas',
                    label: 'Gasoline',
                    raw: emissions ? emissions.gas.all_time : 0,
                    value: emissions ? (parseFloat(emissions.gas.all_time).toFixed(1) + ' Gallons') : 'Unknown',
                    graph: emissions ? emissions.graphing.gas.all_time : null
                },{
                    key: 'trees',
                    label: 'Trees',
                    raw: emissions ? emissions.trees.all_time : 0,
                    value: emissions ? (emissions.trees.all_time + (emissions.trees.all_time === 1 ? ' Tree' : ' Trees') + ' Planted') : 'Unknown',
                    graph: emissions ? emissions.graphing.trees.all_time : null
                }]
            });
            
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up some of the details for this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(user.seeds && user.seeds.notes) {
            setNote(user.seeds.notes.find(note => !note.deleted))
        }
    }, [user]);

    useEffect(() => {
        setupTarget();
        onRequestOnlineStatus();
        utils.sockets.on('system', `user_online_${user.user_id}`, onOnlineStatusChange);
        utils.content.subscribe(layerID, 'users', {
            onUpdate: next => {
                setUser(next.compare(abstract, () => {
                    setupTarget();
                    fetchSystemEvents();
                }));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
            utils.sockets.off('system', `user_online_${user.user_id}`, onOnlineStatusChange);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for ${user.full_name}`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 25
            }}>
                {Views.entry({
                    title: user.full_name,
                    subTitle: user.phone_number || 'Phone Number Not Available',
                    icon: {
                        path: user.avatar
                    },
                    bottomBorder: false
                })}
            </div>

            {user.company && utils.user.get().level <= User.level.admin && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: user.company.name,
                        subTitle: user.company.address || 'Address Not Available',
                        icon: {
                            path: user.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, user.company)
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getTopFields()} />

            {getEmissions()}
            {getMobileApp()}

            <FieldMapper
            utils={utils}
            fields={getBottomFields()} />

            {getSystemEvents()}

            {utils.user.get().level <= User.level.admin && (
                <LayerNote
                note={note}
                utils={utils}
                abstract={abstract} />
            )}
        </Layer>
    )
}

export const UserReferralDetails = ({ abstract, index, options, utils }) => {

    const layerID = `user-referrals-${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(abstract.object);

    useEffect(() => {
        utils.content.subscribe(layerID, 'users', {
            onUpdate: next => {
                setUser(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Referrals from ${abstract.getTitle()}`}
        index={index}
        options={options}>

            <div style={Appearance.styles.unstyledPanel()}>
                {Views.entry({
                    title: user.full_name,
                    subTitle: user.phone_number || 'Phone Number Not Available',
                    icon: {
                        path: user.avatar
                    },
                    bottomBorder: false,
                    onClick: Utils.users.details.bind(this, utils, user)
                })}
            </div>
            {user.company && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: user.company.name,
                        subTitle: user.company.address || 'Address Not Available',
                        icon: {
                            path: user.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, user.company)
                    })}
                </LayerItem>
            )}

            <LayerItem title={'Referrals'}>
                {user.referrals.length === 0
                    ?
                    <span style={Appearance.textStyles.key()}>{abstract.getTitle() + ' has not referred anyone'}</span>
                    :
                    user.referrals.map((u, index) => {
                        return (
                            Views.entry({
                                key: u.user_id,
                                title: u.full_name,
                                subTitle: moment(u.member_since).format('MMMM Do, YYYY [at] h:mma'),
                                icon: {
                                    path: u.avatar
                                },
                                bottomBorder: index !== user.referrals.length - 1,
                                onClick: Utils.users.details.bind(this, utils, u)
                            })
                        )
                    })
                }
            </LayerItem>
        </Layer>
    )
}

// utils
export const fetchDrivers = (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { paging, users } = await Request.get(utils, '/users/', {
                type: 'drivers',
                ...props
            });
            resolve({
                paging: paging,
                drivers: users.map(user => User.create(user))
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchSaaSAccounts = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { accounts, paging } = await Request.get(utils, '/saas/', {
                type: 'accounts',
                ...props
            });
            resolve({
                paging: paging,
                accounts: accounts.map(act => SaasAccount.create(act))
            });
        } catch(e) {
            reject(e);
        }
    })
}
