import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import Appearance from 'styles/Appearance.js';
import FullCalendarOverflow from 'views/FullCalendarOverflow.js';
import Utils from 'files/Utils.js';

export const Calendar = ({ defaultDate, channel, events, showOverflow, utils, onClick }) => {

    const ref = useRef(null);
    const [calEvents, setCalEvents] = useState(null);

    const onEventClick = object => {
        if(typeof(onClick) === 'function') {
            switch(channel) {
                case 'orders':
                onClick(object.event.extendedProps.order);
                break;

                case 'quick_scan':
                onClick(object.event.extendedProps.route);
                break;

                default:
                onClick(object.event.extendedProps.reservation);
            }
        }
    }

    const onLimitClick = evt => {
        utils.layer.open({
            id: `calendar-overflow-${moment(evt.date).unix()}`,
            Component: FullCalendarOverflow.bind(this, {
                date: evt.date,
                segments: evt.segs,
                utils: utils
            })
        });
    }

    const onRenderEvent = object => {

        let { arrow, flex, container, text } = getStyleProps();
        let { order, reservation, route } = object.event.extendedProps || {};

        switch(channel) {
            case 'orders':
            object.el.innerHTML = `
            <div class="text-button" style="${container}; ${getBackground(order.status.color)}">
                <div style="${flex}">
                    <span style="${text}">${order.customer.full_name}</span>
                    <span style="${text}">${order.drop_off_date.format('h:mma')} Drop-Off</span>
                    <span style="${text}">${order.status.text}</span>
                </div>
                <img src="images/next-arrow-white-small.png" style="${arrow}" />
            </div>
            `;
            break;

            case 'quick_scan':
            object.el.innerHTML = `
                <div class="text-button" style="${container}; ${getBackground(route.status.color)}">
                    <div style="${flex}">
                        <span style="${text}">${route.name || `Route #${route.id}`}</span>
                        <span style="${text}">${route.status.text}</span>
                        <span style="${text}">Created ${route.date.format('h:mma')}</span>
                    </div>
                    <img src="images/next-arrow-white-small.png" style="${arrow}" />
                </div>
            `;
            break;

            default:
            let isOnDemand = reservation.special_requests.on_demand ? true : false;
            object.el.innerHTML = `
                <div class="text-button" style="${container}; ${getBackground(reservation.status.color, isOnDemand)}">
                    <div style="${flex}">
                        <span style="${text}">${reservation.customer.full_name}</span>
                        <span style="${text}; white-space:nowrap; text-overflow:ellipsis; max-width:100%">${isOnDemand ? 'Immediate Pickup' : `${reservation.pickup_date.format('h:mma')} Pickup`}</span>
                        <span style="${text}">${reservation.status.text}</span>
                    </div>
                    <img src="images/${isOnDemand ? 'on-demand-icon-white' : 'scheduled-ride-icon-white'}.png" style="${arrow}; height:20px; width:20px" />
                </div>
            `;
        }
        return object.el;
    }

    const getBackground = (color, isOnDemand) => {
        return Appearance.serialize({
            background: `linear-gradient(45deg, ${Utils.hexToRGBA(color, 0.85)}, ${Utils.hexToRGBA(color, 0.7)})`,
            border: `2px solid ${color}`,
            ...isOnDemand === true && {
                boxShadow: `inset 0px 0px 0px 1.5px ${Appearance.colors.background()}`
            }
        });
    }

    const getStyleProps = () => {
        return {
            arrow: Appearance.serialize({
                height: '15px',
                marginLeft: '8px',
                objectFit: 'contain',
                opacity: 1,
                width: '15px'
            }),
            container: Appearance.serialize({
                alignItems: 'center',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '6px',
                overflow: 'hidden',
                padding: '8px 12px 8px 12px'
            }),
            flex: Appearance.serialize({
                display: 'flex',
                flexDirection: 'column',
                minWidth: '0px'
            }),
            text: Appearance.serialize({
                color: 'white',
                display: 'block',
                fontSize: '12px',
                fontWeight: 600,
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            })
        }
    }

    useEffect(() => {
        if(ref.current) {
            ref.current.calendar.gotoDate(defaultDate);
        }
    }, [defaultDate]);

    useEffect(() => {
        setCalEvents(events);
    }, [events]);

    return (
        <FullCalendar
        ref={ref}
        defaultDate={defaultDate || moment().format('YYYY-MM-DD')}
        defaultView={'dayGridWeek'}
        plugins={[ dayGridPlugin ]}
        themeSystem={'bootstrap4'}
        events={calEvents}
        eventLimit={showOverflow !== true}
        header={false}
        height={showOverflow === true ? window.innerHeight - 80 : 380}
        eventClick={onEventClick}
        eventLimitText={value => `View ${value} more`}
        eventLimitClick={onLimitClick}
        eventRender={onRenderEvent}
        views={showOverflow !== true && {
            dayGridWeek: { eventLimit: 4 },
            month: { eventLimit: 3 }
        }}/>
    )
}

export const MobileCalendar = ({ activeDate, events, eventFilter, utils, onClick, onDateChange }) => {

    const weeks = [ 0, 1, 2, 3, 4 ];
    const [days, setDays] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment());
    const headers = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];

    useEffect(() => {

        // 42 days accounts for 6 full rows of 7 day weeks if first of the month is thursday or later
        let startOfMonth = parseInt(moment(activeDate).startOf('month').format('e'));
        let totalDays = startOfMonth >= 5 ? 42 : 35;

        let days = [...new Array(totalDays)].map((_, day) => {
            return day < startOfMonth ? moment(activeDate).startOf('month').subtract(startOfMonth - day, 'days') : moment(activeDate).startOf('month').add(day - startOfMonth, 'days');
        });

        let chunks = days.reduce((array, day, index) => {
            let i = Math.floor(index / headers.length);
            if(!array[i]) {
                array[i] = [];
            }
            array[i].push({
                date: day,
                events: events ? (events.find(e => {
                    return eventFilter(day, e);
                }) ? true : false) : false
            });
            return array;
        }, []);

        setDays(chunks);

    }, [activeDate, events]);

    return (
        <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-around',
                alignItems: 'center',
                textAlign: 'center',
                height: 40,
                marginTop: Utils.isMobile() ? 0 : 25,
                marginBottom: Utils.isMobile() ? 0 : 25
            }}>
                {/* header days */}
                {headers.map((d, i) => (
                    <span key={i} style={{
                        ...Appearance.textStyles.supportingText(),
                        textAlign: 'center',
                        fontWeight: '600',
                        color: Appearance.colors.lightGrey,
                        flexGrow: 1,
                        width: '100%'
                    }}>{d.toUpperCase()}</span>
                ))}
            </div>
            {days.map((week, index) => {
                return (
                    <div key={index} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        height: 45,
                        marginBottom: Utils.isMobile() ? 0 : 25
                    }}>
                        {week.map((entry, i) => {

                            return (
                                <div key={i}
                                className={Utils.isMobile() || !entry.date.isSame(moment(activeDate), 'month') ? '' : 'text-button'}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    justifyContent: 'center',
                                    height: '100%',
                                    width: '100%',
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    opacity: entry.date.isSame(moment(activeDate), 'month') ? 1:0.25
                                }}>
                                    <div onClick={entry.date.isSame(moment(activeDate), 'month') ? () => {
                                        setSelectedDate(entry.date.format('YYYY-MM-DD'));
                                        if(typeof(onDateChange) === 'function') {
                                            onDateChange(entry.date);
                                        }
                                    } : null}
                                    style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            borderRadius: 8,
                                            paddingTop: 6,
                                            width: '100%',
                                            height: '100%',
                                            maxWidth: 38,
                                            backgroundColor: selectedDate && entry.date.isSame(moment(selectedDate), 'day') ? Appearance.colors.primary() : null
                                        }}>
                                        <span style={{
                                            ...Appearance.textStyles.title(),
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            width: '100%',
                                            fontSize: 16,
                                            ...(selectedDate && entry.date.isSame(moment(selectedDate), 'day') ? {
                                                color: 'white'
                                            } : null)
                                        }}>{entry.date.format('D')}</span>
                                        <div style={{
                                            width: 5,
                                            height: 5,
                                            borderRadius: 2.5,
                                            marginTop: 3,
                                            backgroundColor: selectedDate && entry.date.isSame(moment(selectedDate), 'day') ? 'white' : Appearance.colors.grey(),
                                            opacity: entry.events ? 1:0
                                        }}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </>
    )
}
