import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance';
import DatePickerField from 'views/DatePickerField.js';
import Layer from 'structure/Layer.js';
import TimePicker from 'views/TimePicker.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const DatePicker = ({ adminOveride, id, blockStyle, date, dateTime, utils, filterDate, filterDateValue, highlightDates, onClose, onDateChange, onRemoveDate, removeable, time }) => {

    const alertRef = useRef(null);
    const [alertButtons, setAlertButtons] = useState([]);
    const [days, setDays] = useState([]);
    const [dimOpacity, setDimOpacity] = useState(0);
    const headers = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
    const [offset, setOffset] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [scale, setScale] = useState(0);
    const [selectedDate, setSelectedDate] = useState(date ? moment(date) : moment());
    const [selectedTime, setSelectedTime] = useState(null);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [target, _] = useState(date ? moment(date) : moment());
    const [visible, setVisible] = useState(false);

    const onConfirmDate = key => {
        if(key === 'remove') {
            if(typeof(onRemoveDate) === 'function') {
                onRemoveDate(null);
            }
            hide();
            return;
        }

        let dateTarget = moment(selectedDate);
        if(dateTime || time) {
            if(!selectedTime) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'Please select a time before confirming your date'
                });
                return;
            }
            dateTarget = moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedTime.format('HH:mm:ss')}`)
        }

        if(typeof(filterDate) === 'function' && filterDate(dateTarget) === false) {
            if(!(adminOveride === true && utils.user.get().level <= User.level.admin)) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `The date you have selected is invalid. ${filterDateValue ? `Please select a date after ${moment(filterDateValue).format('MMMM Do, YYYY [at] h:mma')}` : ''}`
                });
                return;
            }
        }

        if(typeof(onDateChange) === 'function') {
            onDateChange(dateTarget);
        }
        hide();
    }

    const hide = callback => {
        setScale(0);
        setOpacity(0);
        setDimOpacity(0);
        setTimeout(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(callback) === 'function') {
                callback();
            }
        }, 250);
    }

    const onShowAlert = () => {
        let interval = setInterval(() => {
            if(!alertRef.current) {
                return;
            }
            clearInterval(interval);
            setScale(1);
            setOpacity(1);
            setDimOpacity(1);
        }, 100);
    }

    const onWindowSizeChange = (e) => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    const isDateAvailable = newDate => {
        if(typeof(filterDate) === 'function') {
            if(!(adminOveride === true && utils.user.get().level <= User.level.admin)) {
                return filterDate(newDate.endOf('day'));
            }
        }
        return newDate.isSame(moment(target).add(offset, 'months'), 'month');
    }

    const shouldHighlightDate = date => {
        if(!highlightDates) {
            return false;
        }
        if(!blockStyle) {
            return highlightDates.find(d => moment(d).unix() === moment(date).unix())
        }
        return moment(date).unix() >= moment(selectedDate).startOf(blockStyle).unix() && moment(date).unix() <= moment(selectedDate).endOf(blockStyle).unix()
    }

    const getMonth = () => {
        return moment(target).startOf('month').add(offset, 'months').format('MMMM YYYY')
    }

    const getDateStyles = (i, length, date) => {

        let highlightDate = shouldHighlightDate(date);
        if(highlightDate && blockStyle) {
            return {
                opacity: 1,
                backgroundColor: Appearance.colors.primary(),
                borderTopLeftRadius: i === 0 ? 10 : 0,
                borderBottomLeftRadius: i === 0 ? 10 : 0,
                borderTopRightRadius: i === length - 1 ? 10 : 0,
                borderBottomRightRadius: i === length - 1 ? 10 : 0
            };
        }
        let available = isDateAvailable(date);
        return {
            opacity: available ? 1 : 0.25
        }
    }

    const getDateTextStyles = date => {

        let highlightDate = shouldHighlightDate(date);
        if(highlightDate && blockStyle) {
            return {
                ...Appearance.textStyles.title(),
                fontWeight: '500',
                textAlign: 'center',
                width: '100%',
                fontSize: 14,
                color: 'white'
            }
        }
        return {
            ...Appearance.textStyles.title(),
            fontWeight: highlightDate ? '600' : '500',
            textAlign: 'center',
            width: '100%',
            fontSize: 14,
            color: selectedDate && date.isSame(moment(selectedDate), 'day') ? 'white' : (highlightDate ? Appearance.colors.primary() : Appearance.textStyles.title().color)
        }
    }

    useEffect(() => {

        if(!target) {
            return;
        }

        // Dates
        // 42 days accounts for 6 full rows of 7 day weeks if first of the month is thursday or later
        let startOfMonth = parseInt(moment(target).startOf('month').add(offset, 'months').format('e'));
        let totalDays = startOfMonth >= 5 ? 42 : 35;

        let days = [...new Array(totalDays)].map((_, day) => {
            return day < startOfMonth ? moment(target).startOf('month').add(offset, 'months').subtract(startOfMonth - day, 'days') : moment(target).startOf('month').add(offset, 'months').add(day - startOfMonth, 'days');
        });

        let weeks = days.reduce((array, date, index) => {
            let i = Math.floor(index / headers.length);
            if(!array[i]) {
                array[i] = [];
            }
            array[i].push({ date: date });
            return array;
        }, []);
        setDays(weeks);

        // Times
        if(!dateTime && !time) {
            return;
        }

        let date = Utils.conformDate(target, 5);
        setSelectedTime(date);

    }, [target, offset]);

    useEffect(() => {

        setAlertButtons([{
            key: 'done',
            title: removeable ? 'Use Date' : 'Done',
            style: 'default'
        },{
            key: 'remove',
            title: 'Remove Date',
            style: 'destructive',
            visible: removeable ? true : false
        }]);

        setVisible(true);
        onShowAlert();

        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, []);

    return visible ? (
        <div
        ref={alertRef}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <VelocityComponent
            duration={250}
            animation={{
                opacity: dimOpacity
            }}>
                <div style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                    backgroundColor: Appearance.colors.dim
                }} />
            </VelocityComponent>

            <VelocityComponent
                easing={[250, 20]}
                duration={500}
                animation={{
                    opacity: opacity,
                    scale: scale
                }}>
                <div style={{
                    borderRadius: 10,
                    backgroundColor: Appearance.colors.alert(),
                    overflow:'hidden',
                    maxHeight: size.height - 30,
                    width: size.width > 400 ? 400 : (size.width - 30),
                    alignItems: 'center',
                    textAlign: 'center',
                    zIndex: 9999
                }}>

                    <div style={{
                        display: 'block',
                        padding: 15
                    }}>
                        {time !== true && (
                            <div style={{
                                padding: 15,
                                borderRadius: 10,
                                border: `1px solid ${Appearance.colors.divider()}`
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingLeft: 8,
                                    paddingRight: 8
                                }}>
                                    <img
                                    className={'text-button'}
                                    onClick={() => setOffset(offset => offset - 12)}
                                    src={'images/back-double-arrow-light-grey-small.png'}
                                    style={{
                                        width: 15,
                                        height: 15,
                                        minWidth: 15,
                                        minHeight: 15,
                                        objectFit: 'contain',
                                        marginRight: 4,
                                    }} />

                                    <img
                                    className={'text-button'}
                                    onClick={() => setOffset(offset => offset - 1)}
                                    src={'images/back-arrow-light-grey-small.png'}
                                    style={{
                                        width: 15,
                                        height: 15,
                                        minWidth: 15,
                                        minHeight: 15,
                                        objectFit: 'contain',
                                        marginRight: 8,
                                    }} />

                                    <span style={{
                                        ...Appearance.textStyles.title(),
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        width: '100%',
                                        fontSize: 16,
                                    }}>{getMonth()}</span>

                                    <img
                                    className={'text-button'}
                                    onClick={() => setOffset(offset => offset + 1)}
                                    src={'images/next-arrow-light-grey-small.png'}
                                    style={{
                                        width: 15,
                                        height: 15,
                                        minWidth: 15,
                                        minHeight: 15,
                                        objectFit: 'contain',
                                        marginLeft: 8,
                                    }} />

                                    <img
                                    className={'text-button'}
                                    onClick={() => setOffset(offset => offset + 12)}
                                    src={'images/next-double-arrow-light-grey-small.png'}
                                    style={{
                                        width: 15,
                                        height: 15,
                                        minWidth: 15,
                                        minHeight: 15,
                                        objectFit: 'contain',
                                        marginLeft: 4,
                                    }} />

                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    height: 40,
                                    marginBottom: 8
                                }}>
                                    {/* header days */}
                                    {headers.map((d, i) => (
                                        <span key={i}
                                        style={{
                                            ...Appearance.textStyles.supportingText(),
                                            textAlign: 'center',
                                            fontWeight: '600',
                                            color: Appearance.colors.lightGrey,
                                            flexGrow: 1,
                                            width: '100%'
                                        }}>{d.toUpperCase()}</span>
                                    ))}
                                </div>
                                {days.map((week, index) => {
                                    return (
                                        <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginBottom: index !== days.length - 1 ? 4 : 0,
                                        }}>
                                            {week.map((entry, i) => {
                                                return (
                                                    <div
                                                    key={i}
                                                    className={Utils.isMobile() || !entry.date.isSame(target, 'month') ? '' : 'text-button'}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        flexGrow: 1,
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                        width: '100%',
                                                        paddingLeft: 5,
                                                        paddingRight: 5,
                                                        ...getDateStyles(i, week.length, entry.date)
                                                    }}>
                                                        <div onClick={entry.date.isSame(moment(target).add(offset, 'months'), 'month') ? () => {
                                                            setSelectedDate(entry.date.format('YYYY-MM-DD'));
                                                            if(typeof(onDateChange) === 'function') {
                                                                onDateChange(entry.date);
                                                            }
                                                        } : null}
                                                        style={{
                                                            display: 'flex',
                                                            flexGrow: 1,
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                            width: '100%'
                                                        }}>
                                                            <div
                                                            className={'cursor-pointer'}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                borderRadius: 8,
                                                                width: 30,
                                                                height: 30,
                                                                padding: 4,
                                                                backgroundColor: selectedDate && entry.date.isSame(moment(selectedDate), 'day') ? Appearance.colors.primary() : null
                                                            }}>
                                                                <span style={getDateTextStyles(entry.date)}>{entry.date.format('D')}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        {(dateTime || time) && (
                            <div className={'text-center'}>
                                {time && (
                                    <>
                                    <span
                                    className={'d-block mb-1'}
                                    style={
                                        Appearance.textStyles.standard()
                                    }>{'Time'}</span>
                                    <span
                                    className={'d-block mb-3'}
                                    style={{
                                        ...Appearance.textStyles.supportingText(),
                                        display: 'block'
                                    }}>{'Please choose a time from the list below'}</span>
                                    </>
                                )}
                                <TimePicker
                                utils={utils}
                                selected={selectedTime}
                                onChange={date => setSelectedTime(date)}
                                style={{
                                    marginTop: 8
                                }}/>
                            </div>
                        )}
                    </div>

                    {alertButtons.map(item => {

                        if(item.visible === false) {
                            return null;
                        }
                        return (
                            <div
                            key={item.key}
                            className={`alert-item ${window.theme}`}
                            onClick={onConfirmDate.bind(this, item.key)}
                            style={{
                                height: 50,
                                width: '100%'
                            }}>
                                <div style={{
                                    height: 50
                                }}>
                                    <div style={{
                                        width: '100%',
                                        height: 1,
                                        backgroundColor: Appearance.colors.divider()
                                    }}/>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 50
                                    }}>
                                        <span style={{
                                            color: item.style === 'destructive' ? Appearance.colors.red : Appearance.colors.text(),
                                            fontSize: 13,
                                            fontWeight: '400',
                                            textAlign:'center'
                                        }}>{item.title}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </VelocityComponent>
        </div>
    ) : null
}

export default DatePicker;

export const DualDatePicker = (props, { index, options, utils }) => {

    const layerID = 'dual-date-picker-alert';
    const [layerState, setLayerState] = useState(null);
    const [startDate, setStartDate] = useState(props.start_date);
    const [endDate, setEndDate] = useState(props.end_date);

    const onStartDateChange = date => {
        setStartDate(date);
    }

    const onEndDateChange = date => {
        setEndDate(date);
    }

    const onConfirmDates = () => {
        setLayerState('close');
        if(typeof(props.onDateChange) === 'function') {
            props.onDateChange({
                start: startDate,
                end: endDate
            })
        }
    }

    return (
        <Layer
        id={layerID}
        title={props.title || 'Date Picker'}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onConfirmDates
        }]}>

            <span className={'d-block mb-1'}
            style={Appearance.textStyles.title()}>{'Start Date'}</span>
            <DatePickerField
            {...props}
            utils={utils}
            selected={startDate}
            onDateChange={onStartDateChange} />

            <span className={'d-block mt-3 mb-1'}
            style={Appearance.textStyles.title()}>{'End Date'}</span>
            <DatePickerField
            {...props}
            utils={utils}
            selected={endDate}
            onDateChange={onEndDateChange} />
        </Layer>
    )
}
