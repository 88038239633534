import React, { useCallback, useEffect, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';
import { useDrop, useDrag } from 'react-dnd';

import Appearance from 'styles/Appearance.js';
import PageControl from 'views/PageControl';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const DragElement = ({ id, type, object, isDropped }) => {

    const [{ opacity }, drag] = useDrag({
        type,
        item: { id, type, object },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        })
    });

    const getContent = () => {
        if(isDropped) {
            return null;
        }
        return (
            <div
            ref={drag}
            style={{
                ...opacity,
                paddingTop: 15, // accounts for strange cropping on top and left sides
                paddingLeft: 15 // accounts for strange cropping on top and left sides
            }}>
                {type === 'reservation' && (
                    <ReservationEntry reservation={object} />
                )}
            </div>
        )
    }
    return getContent();
}

const DropElement = ({ accept, droppedItems, onDrop, className, style, content, onClick }) => {

    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const isActive = isOver && canDrop
    return (
        <div
        ref={drop}
        className={className}
        style={{
            width: '100%',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflowX: 'scroll',
            overflowY: 'hidden',
            backgroundColor: isActive ? Utils.hexToRGBA(Appearance.colors.primary(), 0.25) : Appearance.colors.panelBackground()
         }}>
            <div style={{
                width: '100%',
                height: '100%',
                padding: '15px 15px 0px 15px'
            }}>
                {droppedItems.length > 0 && (
                    droppedItems.map(({ type, object }, index) => {
                        if(type !== 'reservation') {
                            return null;
                        }
                        return (
                            <div
                            key={object.id}
                            className={'text-button text-center'}
                            onClick={onClick.bind(this, {
                                id: object.id,
                                index: index
                            })}
                            style={{
                                display: 'inline-block',
                                backgroundColor: object.status.color,
                                borderRadius: 5,
                                marginRight: 12,
                                padding: 2,
                                width: 175
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: 'white',
                                    padding: '3px 6px 3px 6px'
                                }}>{`Reservation #${object.id}`}</span>
                                <div style={{
                                    marginTop: 3,
                                    borderBottomLeftRadius: 3.5,
                                    borderBottomRightRadius: 3.5,
                                    padding: '4px 6px 4px 6px',
                                    backgroundColor: Appearance.colors.panelBackground()
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.subTitle(),
                                        color: Appearance.colors.subText()
                                    }}>{`${moment(object.pickup_date).format('h:mmA')} to ${moment(object.pickup_date).add(object.duration.estimate, 'seconds').format('h:mmA')}`}</span>
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}

const Assignments = ({ drivers, onAssign, onUnassign, reservations, utils }) => {

    const limit = 10;

    const [droppedBoxNames, setDroppedBoxNames] = useState([]);
    const [dropzones, setDropzones] = useState([]);
    const [draggables, setDraggables] = useState([]);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const isDropped = (boxName) => {
        return droppedBoxNames.indexOf(boxName) > -1
    }

    const onDraggableClick = ({ id, index }) => {

        let reservation = reservations.find(reservation => reservation.id === id);
        if(reservation && !isDropped(id)) {
            Utils.reservations.details(utils, reservation);
            return;
        }

        utils.sheet.show({
            items: [{
                key: 'unassign',
                title: 'Unassign',
                style: 'destructive'
            },{
                key: 'view',
                title: 'View Reservation',
                style: 'default'
            }]
        }, key => {
            if(key === 'view') {
                Utils.reservations.details(utils, reservation);
                return;
            }
            if(key === 'unassign') {
                setDroppedBoxNames(
                    update(droppedBoxNames, { $apply: (ids) => {
                        return ids.filter(item => item !== id)
                    }}),
                )
                setDropzones(
                    update(dropzones, {
                        [index]: {
                            droppedItems: {
                                $apply: (items) => {
                                    return items.filter(item => item.id !== id)
                                },
                            },
                        },
                    }),
                )
                if(typeof(onUnassign) === 'function') {
                    onUnassign({
                        driver: dropzones[index].driver,
                        reservation: reservation
                    });
                }
                return;
            }
        })
    }

    const handleDrop = useCallback((index, item) => {

        const { id } = item;
        setDroppedBoxNames(
            update(droppedBoxNames, id ? { $push: [id] } : { $push: [] }),
        )
        setDropzones(
            update(dropzones, {
                [index]: {
                    droppedItems: {
                        $apply: (items) => {
                            var newItems = [...items];
                            newItems.push(item);
                            return newItems.sort((a, b) => moment(a.object.pickup_date).unix() > moment(b.object.pickup_date).unix())
                        },
                    },
                },
            }),
        )

        if(onAssign && typeof(onAssign) === 'function') {
            onAssign({
                driver: dropzones[index].driver,
                reservation: item.object
            });
        }
    }, [droppedBoxNames, dropzones]);

    const getDrivers = () =>  {
        return searchText ? drivers.filter(driver => driver.full_name.toLowerCase().includes(searchText)) : drivers;
    }

    const parseDropzones = () => {
        return getDrivers().filter((_, index) => {
            if(index < offset || index >= (offset + limit)) {
                return false;
            }
            return true;
        }).map(driver => ({
            accepts: ['reservation'],
            driver: driver,
            droppedItems: reservations.filter(reservation => reservation.assigned_to === driver.user_id).map((reservation) => {
                return {
                    type: 'reservation',
                    id: reservation.id,
                    object: reservation
                }
            }).sort((a, b) => {
                return moment(a.pickup_date).unix() < moment(b.pickup_date).unix();
            })
        }));
    }

    const parseDraggables = useCallback(() => {
        if(!reservations || reservations.length === 0) {
            return [];
        }
        return reservations.filter(reservation => {
            return reservation.assigned_to ? false : true;
        }).sort((a, b) => {
            return a.pickup_date.unix() > b.pickup_date.unix();
        }).map(reservation => ({
            type: 'reservation',
            id: reservation.id,
            reservation: reservation
        }));
    })

    useEffect(() => {
        setOffset(0);
    }, [searchText]);

    useEffect(() => {
        setPaging({
            current_page: parseInt(offset / limit) + 1,
            number_of_pages: drivers.length > limit ? Math.ceil(drivers.length / limit) : 1,
            results: drivers.length
        });
    }, [drivers, offset]);

    useEffect(() => {

        let ids = reservations.filter(reservation => {
            return reservation.assigned_to && getDrivers().find(driver => driver.user_id === reservation.assigned_to);
        }).map(reservation => reservation.id);

        setDroppedBoxNames(ids);
        setDropzones(parseDropzones());
        setDraggables(parseDraggables());

    }, [drivers, paging, reservations, searchText]);

    return (
        <>
        <div style={{
            padding: 12,
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }}>
            <div
            className={'no-animations'}
            style={{
                ...Appearance.styles.unstyledPanel(),
                height: 138,
                overflow: 'hidden'
            }}>
                {draggables.length === 0
                    ?
                    <div style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '8px 12px 8px 12px',
                            textAlign: 'center',
                            border: `1px solid ${Appearance.colors.softBorder()}`,
                            backgroundColor: Appearance.colors.panelBackground(),
                            borderRadius: 5
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                            }}>{'Nothing to see here'}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle()
                            }}>{'All of the Reservations have been assigned'}</span>
                        </div>
                    </div>
                    :
                    <div style={{
                        width: '100%',
                        maxWidth: '100%',
                        whiteSpace: 'nowrap',
                        overflowX: 'scroll',
                        textAlign: 'center'
                    }}>
                        {draggables.map(({ id, type, reservation }, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    display: 'inline-block',
                                    marginRight: index === draggables.length - 1 ? 15:0
                                }}
                                onClick={onDraggableClick.bind(this, {
                                    id: id,
                                    index: index
                                })}>
                                    <DragElement
                                    id={id}
                                    type={type}
                                    object={reservation}
                                    isDropped={isDropped(id)} />
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
        <div style={{
            overflow: 'hidden',
            clear: 'both',
            width: '100%'
        }}>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12
            }}>
                <TextField
                icon={'search'}
                placeholder={'Search by first or last name...'}
                onChange={text => setSearchText(text && text.toLowerCase())} />
            </div>
            {dropzones.map(({ accepts, driver, droppedItems }, index, reservations) => {
                return (
                    <div
                    key={driver.user_id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: 51,
                        borderBottom: index !== reservations.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                    }}>
                        {Views.entry({
                            title: driver.full_name,
                            subTitle: driver.phone_number,
                            icon: {
                                path: driver.avatar,
                                onClick: Utils.users.details.bind(this, utils, driver)
                            },
                            style: {
                                width: 250,
                                borderRight: `1px solid ${Appearance.colors.divider()}`
                            }
                        })}
                        <DropElement
                        accept={accepts}
                        droppedItems={droppedItems}
                        onDrop={item => handleDrop(index, item)}
                        onClick={props => onDraggableClick({
                            ...props,
                            index: index
                        })}/>
                    </div>
                )
            })}
            {paging && (
                <PageControl
                limit={limit}
                offset={offset}
                description={paging}
                onClick={setOffset} />
            )}
        </div>
        </>
    )
}

const ReservationEntry = ({ reservation }) => {

    const getReservationTimeDescription = reservation => {
        if(reservation.special_requests.on_demand === true) {
            return 'Immediate Pickup';
        }
        return `${moment(reservation.pickup_date).format('h:mmA')} to ${moment(reservation.pickup_date).add(reservation.duration.estimate, 'seconds').format('h:mmA')}`;
    }
    
    return (
        <div
        className={'text-button mb-1'}
        style={{
            borderRadius: 15,
            overflow: 'hidden',
            backgroundColor: reservation.status.color,
            width: 225,
            textAlign: 'left',
            border: `3px solid ${reservation.status.color}`
        }}>
            {Views.entry({
                title: reservation.customer.full_name,
                subTitle: reservation.status.text,
                icon: {
                    path: reservation.customer.avatar,
                    style: {
                        boxShadow: null,
                        border: `1.5px solid white`,
                        borderRadius: '50%'
                    }
                },
                textStyles: {
                    title: {
                        color: 'white'
                    },
                    subTitle: {
                        color: 'white'
                    }
                }
            })}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: Appearance.colors.panelBackground(),
                borderBottomLeftRadius: 13,
                borderBottomRightRadius: 13,
                borderTop: '1px solid white',
                padding: '5px 8px 8px 8px'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: Appearance.colors.text()
                }}>{`Reservation #${reservation.id}`}</span>
                <span style={{
                    ...Appearance.textStyles.subTitle()
                }}>{getReservationTimeDescription(reservation)}</span>
            </div>
        </div>
    )
}

export default Assignments;
