import User from 'classes/User.js';

import { ActiveOrders, NewOrder, OrdersCalendar, OrderCategories, OrderHostsList, OrdersList, OrderOptions, OrderServiceActivity, OrderServices, fetchOrderChannels } from 'managers/Orders.js';
import { ActiveReservations, Services, NewReservation, OnDemandBroadcasts, ReservationAutoApprovals, ReservationCalendar, ReservationFlights, ReservationGuidelines, ReservationsList, ReservationMoods, ReservationPremiumLocations, RestrictedDatesTimes, ServiceActivity } from 'managers/Reservations.js';
import { ActiveUsers, EmissionsOverview, PerformanceOverview } from 'managers/Dashboard.js';
import { ActiveVerifications, CampaignsList, DailyActiveUsers, DriverAssignments, DriverOnboardingApps, DriverPerformanceBreakdown, DriverReservationBreakdown, DriverScheduling, MobileAppVersions, MobileDeviceBreakdown, NotificationReachability, SaaSAccounts, SaaSEmissionsOverview, SaaSPerformanceOverview, SaaSReport, SaaSSupportTickets, SupportTickets, UserGrowth, UserList, UserLoginBreakdown } from 'managers/Users.js';
import { Payments, PromoCodes, Subscriptions, SubscriptionPlans, CreditsActivity, CreditsList, OrdersWithCredits, OrdersWithSubscriptions, PaymentsMonthlyBreakdown, PaymentPreferences, PromoCodesActivity, ReservationsWithCredits, ReservationsWithSubscriptions, SubscriptionActivity, SubscriptionPlansAcitivty, UnpaidOrders, UnpaidReservations } from 'managers/Payments.js';
import { StreetLinkIncidents, StreetLinkTraffic, Vehicles, VehicleCategories, VehicleDemand, VehicleOnboardingApps } from 'managers/Vehicles.js';
import { EmissionRewards, DailyEmissions, MonthlyEmissions, SeedPodAirQuality, TreePlantingRequests } from 'managers/Emissions.js';
import { Companies, CompaniesReport } from 'managers/Companies.js';
import { FeedbackResponses, NetPromoter, RatingsBreakdown, RecentFeedback } from 'managers/Feedback.js';
import { NewsActivity, NewsCategories, NewsItems, fetchNewsChannels } from 'managers/News.js';
import { NotificationsList } from 'managers/Notifications.js';
import { QuickScanServices, QuickScanCategories, QuickScanRoutesCalendar, QuickScanRoutesList, QuickScanRouteOptions, QuickScanServiceActivity } from 'managers/Routes.js';
import { SaaSAccountSettings, SaaSUsageStatistics, Zones } from 'managers/Resources.js';
import { DriveExperiences } from 'managers/DriveExperiences.js';
import { LeadsList } from 'managers/Leads.js';

export const getContent = async (utils) => {
    return new Promise(async (resolve, reject) => {
        try {

            // fetch order and news channels
            let orderChannels = await fetchOrderChannels(utils);
            let newsChannels = await fetchNewsChannels(utils);

            const clientHasFlavor = key => {
                return window.client_id === 'ecarra' ? true : window.clientParameters.flavors.includes(key);
            }

            resolve({
                workspace: {
                    key: 'workspace',
                    title: 'Workspace',
                    icon: 'fas fa-user-astronaut',
                    panels: []
                },
                dashboard: {
                    key: 'dashboard',
                    title: 'Dashboard',
                    icon: 'navigation-icon-dashboard',
                    panels: [{
                        key: 'dashboardNewReservation',
                        title: 'Book a Ride',
                        visible: clientHasFlavor('rides.scheduled'),
                        Component: NewReservation
                    },{
                        key: 'reservationsZones',
                        title: 'Reservation Zones',
                        visible: clientHasFlavor('rides.scheduled') && utils.user.get().level <= User.level.admin,
                        Component: Zones.bind(this, {
                            category: 'reservations'
                        })
                    },{
                        key: 'activeReservations',
                        title: 'Active Reservations',
                        visible: clientHasFlavor('rides.scheduled'),
                        Component: ActiveReservations
                    },{
                        key: 'performanceOverview',
                        title: 'Performance Overview',
                        visible: clientHasFlavor('payments') && clientHasFlavor('rides'),
                        Component: PerformanceOverview
                    },{
                        key: 'emissionsOverview',
                        title: 'Emissions Overview',
                        visible: clientHasFlavor('emissions'),
                        Component: EmissionsOverview
                    },{
                        key: 'activeUsers',
                        title: 'Active Users',
                        visible: utils.user.get().level <= User.level.admin,
                        column: 'col-12 col-xl-6 px-2',
                        Component: ActiveUsers
                    },{
                        key: 'recentFeedback',
                        title: 'Recent Feedback',
                        visible: utils.user.get().level <= User.level.admin,
                        column: 'col-12 col-xl-6 px-2',
                        Component: RecentFeedback
                    }]
                },
                ...clientHasFlavor('orders') && orderChannels.channels.reduce((object, channel) => {
                    return {
                        ...object,
                        [`orders_${channel.id}`]: {
                            key: `orders_${channel.id}`,
                            title: channel.name,
                            visible: utils.user.get().level <= User.level.admin,
                            icon: channel.icon.seeds || 'navigation-icon-orders',
                            subViews: {
                                overview: {
                                    key: 'overview',
                                    title: 'Overview',
                                    panels: [{
                                        key: `newOrder${channel.id}`,
                                        title: `New ${channel.name} Order`,
                                        Component: NewOrder.bind(this, {
                                            channel: channel
                                        })
                                    },{
                                        key: `activeOrders${channel.id}`,
                                        title: `Active ${channel.name} Orders`,
                                        Component: ActiveOrders.bind(this, {
                                            channel: channel
                                        })
                                    },{
                                        key: `ordersCalendar${channel.id}`,
                                        title: `${channel.name} Calendar`,
                                        Component: OrdersCalendar.bind(this, {
                                            channel: channel
                                        })
                                    },{
                                        key: `todayOrders${channel.id}`,
                                        title: `Today\'s Orders from ${channel.name}`,
                                        Component: OrdersList.bind(this, {
                                            category: 'today',
                                            channel: channel
                                        })
                                    },{
                                        key: `pendingOrders${channel.id}`,
                                        title: `Pending Orders from ${channel.name}`,
                                        Component: OrdersList.bind(this, {
                                            category: 'pending',
                                            channel: channel
                                        })
                                    },{
                                        key: `allOrders${channel.id}`,
                                        title: `All Orders from ${channel.name}`,
                                        Component: OrdersList.bind(this, {
                                            category: 'all',
                                            channel: channel
                                        })
                                    },{
                                        key: `cancelledOrders${channel.id}`,
                                        title: `Cancelled Orders from ${channel.name}`,
                                        Component: OrdersList.bind(this, {
                                            category: 'cancelled',
                                            channel: channel
                                        })
                                    }]
                                },
                                hosts: {
                                    key: 'hosts',
                                    title: 'Hosts',
                                    panels: [{
                                        key: `ordersHostsList${channel.id}`,
                                        title: `Hosts for ${channel.name}`,
                                        Component: OrderHostsList.bind(this, {
                                            category: 'all',
                                            channel: channel
                                        })
                                    }]
                                },
                                services: {
                                    key: 'services',
                                    title: 'Services',
                                    panels: [{
                                        key: `ordersServiceActivity${channel.id}`,
                                        title: `${channel.name} Service Activity`,
                                        Component: OrderServiceActivity.bind(this, {
                                            channel: channel
                                        })
                                    },{
                                        key: `orderServices${channel.id}`,
                                        title: `Services for ${channel.name}`,
                                        Component: OrderServices.bind(this, {
                                            channel: channel
                                        })
                                    }]
                                },
                                categories: {
                                    key: `categories`,
                                    title: 'Categories and Options',
                                    panels: [{
                                        key: `orderCategories${channel.id}`,
                                        title: `Categories for ${channel.name}`,
                                        Component: OrderCategories.bind(this, {
                                            channel: channel
                                        })
                                    },{
                                        key: `orderOptions${channel.id}`,
                                        title: `Options for ${channel.name}`,
                                        Component: OrderOptions.bind(this, {
                                            channel: channel
                                        })
                                    }]
                                }
                            }
                        },
                    }
                }, { }),
                ...clientHasFlavor('rides.routes') && {
                    routes: {
                        key: 'routes',
                        title: 'Routes',
                        icon: 'navigation-icon-routes',
                        visible: utils.user.get().level <= User.level.admin,
                        subViews: {
                            overview: {
                                key: 'overview',
                                title: 'Overview',
                                panels: [{
                                    key: 'quickScanCalendar',
                                    title: 'QuickScan Calendar',
                                    Component: QuickScanRoutesCalendar
                                },{
                                    key: 'allQuickScanRoutes',
                                    title: 'All QuickScan Routes',
                                    Component: QuickScanRoutesList.bind(this, {
                                        category: 'all'
                                    })
                                },{
                                    key: 'newQuickScanRoutes',
                                    title: 'New QuickScan Routes',
                                    Component: QuickScanRoutesList.bind(this, {
                                        category: 'new'
                                    })
                                },{
                                    key: 'activeQuickScanRoutes',
                                    title: 'Active QuickScan Routes',
                                    Component: QuickScanRoutesList.bind(this, {
                                        category: 'active'
                                    })
                                }]
                            },
                            services: {
                                key: 'services',
                                title: 'Services',
                                panels: [{
                                    key: 'quickScanServiceActivity',
                                    title: 'QuickScan Service Activity',
                                    Component: QuickScanServiceActivity
                                },{
                                    key: 'quickScanServices',
                                    title: 'All QuickScan Services',
                                    Component: QuickScanServices
                                }]
                            },
                            categories: {
                                key: 'categories',
                                title: 'Categories and Options',
                                panels: [{
                                    key: 'quickScanRouteCategories',
                                    title: 'QuickScan Route Categories',
                                    Component: QuickScanCategories
                                },{
                                    key: 'quickScanRouteOptions',
                                    title: 'QuickScan Route Options',
                                    Component: QuickScanRouteOptions
                                }]
                            }
                        }
                    }
                },
                ...clientHasFlavor('rides') && {
                    ...clientHasFlavor('rides.scheduled') && {
                        reservations: {
                            key: 'reservations',
                            title: 'Scheduled Rides',
                            icon: 'navigation-icon-reservations',
                            subViews: {
                                overview: {
                                    key: 'overview',
                                    title: 'Overview',
                                    panels: [{
                                        key: 'reservationsNewReservation',
                                        title: 'Book a Ride',
                                        Component: NewReservation
                                    },{
                                        key: 'activeReservations',
                                        title: 'Active Rides',
                                        Component: ActiveReservations
                                    },{
                                        key: 'reservationCalendar',
                                        title: 'Scheduled Rides Calendar',
                                        Component: ReservationCalendar
                                    },{
                                        key: 'todaysFlights',
                                        title: `Today's Flights`,
                                        visible: utils.user.get().level <= User.level.admin,
                                        Component: ReservationFlights
                                    },{
                                        key: 'todayReservations',
                                        title: `Today's Rides`,
                                        Component: ReservationsList.bind(this, {
                                            category: 'today'
                                        })
                                    },{
                                        key: 'appClipsReservations',
                                        title: `App Clip Rides`,
                                        visible: utils.user.get().level <= User.level.admin,
                                        Component: ReservationsList.bind(this, {
                                            category: 'app_clips'
                                        })
                                    },{
                                        key: 'pendingReservations',
                                        title: 'Pending Rides',
                                        Component: ReservationsList.bind(this, {
                                            category: 'pending'
                                        })
                                    },{
                                        key: 'allReservations',
                                        title: 'All Rides',
                                        Component: ReservationsList.bind(this, {
                                            category: 'all'
                                        })
                                    },{
                                        key: 'cancelledReservations',
                                        title: 'Cancelled Rides',
                                        Component: ReservationsList.bind(this, {
                                            category: 'cancelled'
                                        })
                                    }]
                                },
                                services: {
                                    key: 'services',
                                    title: 'Services',
                                    visible: utils.user.get().level <= User.level.admin,
                                    panels: [{
                                        key: 'servicesActivity',
                                        title: 'Activity',
                                        Component: ServiceActivity
                                    },{
                                        key: 'services',
                                        title: 'All Services',
                                        Component: Services.bind(this, { 
                                            channel: null,
                                            panelID: 'services' 
                                        })
                                    }]
                                },
                                settings: {
                                    key: 'settings',
                                    title: 'Settings',
                                    visible: utils.user.get().level <= User.level.admin,
                                    panels: [{
                                        key: 'autoApprovals',
                                        title: 'Automatic Reservation Approvals',
                                        Component: ReservationAutoApprovals
                                    },{
                                        key: 'defaultGuidelines',
                                        title: 'Default Guidelines',
                                        Component: ReservationGuidelines
                                    },{
                                        key: 'reservationPremiumLocations',
                                        title: 'Premium Locations',
                                        Component: ReservationPremiumLocations
                                    },{
                                        key: 'restrictedDatesTimes',
                                        title: 'Restricted Dates and Times',
                                        Component: RestrictedDatesTimes
                                    },{
                                        key: 'reservationMoods',
                                        title: 'Reservation Moods',
                                        Component: ReservationMoods
                                    }]
                                }
                            }
                        }
                    },
                    ...clientHasFlavor('rides.on_demand') && {
                        on_demand: {
                            key: 'on_demand',
                            title: 'On-Demand Rides',
                            icon: 'navigation-icon-on-demand',
                            visible: utils.user.get().level <= User.level.admin,
                            panels: [{
                                key: 'onDemandBroadcasts',
                                title: 'On-Demand Broadcasts',
                                Component: OnDemandBroadcasts
                            }]
                        }
                    }
                },
                users: {
                    key: 'users',
                    title: 'Users',
                    icon: 'navigation-icon-users',
                    subViews: {
                        overview: {
                            key: 'overview',
                            title: 'Overview',
                            panels: [{
                                key: 'adminUserList',
                                title: 'Administrators',
                                Component: UserList.bind(this, {
                                    category: 'admin',
                                    level: User.level.admin,
                                    levels: [ User.level.admin, User.level.company_admin ]
                                })
                            },{
                                key: 'driversUserList',
                                title: 'Drivers',
                                visible: utils.user.get().level <= User.level.admin,
                                Component: UserList.bind(this, {
                                    category: 'drivers',
                                    level: User.level.driver,
                                    levels: [ User.level.driver ]
                                })
                            },{
                                key: 'customersUserList',
                                title: 'Customers',
                                Component: UserList.bind(this, {
                                    category: 'customers',
                                    level: User.level.customer,
                                    levels: [ User.level.customer ]
                                })
                            },{
                                key: 'leads_list',
                                title: 'Leads',
                                Component: LeadsList
                            },{
                                key: 'activeVerifications',
                                title: 'Active Account Verifications',
                                visible: utils.user.get().level <= User.level.admin,
                                Component: ActiveVerifications
                            }]
                        },
                        ...clientHasFlavor('users.drivers') && {
                            drivers: {
                                key: 'drivers',
                                title: 'Drivers',
                                visible: utils.user.get().level <= User.level.admin,
                                panels: [{
                                    key: 'onboardingDriverApps',
                                    title: 'Driver Applications',
                                    Component: DriverOnboardingApps
                                },{
                                    key: 'reservationAssignments',
                                    title: 'Reservation Assignments',
                                    Component: DriverAssignments
                                },{
                                    key: 'driverSchedules',
                                    title: 'Scheduling and Availability',
                                    Component: DriverScheduling
                                },{
                                    key: 'driverReservationsBreakdown',
                                    title: 'Reservations Breakdown',
                                    Component: DriverReservationBreakdown
                                },{
                                    key: 'driverPerformanceBreakdown',
                                    title: 'Performance Breakdown',
                                    Component: DriverPerformanceBreakdown
                                }]
                            }
                        },
                        analytics: {
                            key: 'analytics',
                            title: 'Analytics',
                            visible: utils.user.get().level <= User.level.admin,
                            panels: [{
                                key: 'dailyActiveUsers',
                                title: 'Daily Active Users',
                                Component: DailyActiveUsers
                            },{
                                key: 'userGrowth',
                                title: 'User Growth',
                                Component: UserGrowth
                            },{
                                key: 'notificationReachability',
                                title: 'Notification Reachability',
                                column: 'col-12 col-lg-6 pr-0 pr-lg-2',
                                Component: NotificationReachability
                            },{
                                key: 'mobileDeviceBreakdown',
                                title: 'Mobile Device Breakdown',
                                column: 'col-12 col-lg-6 pl-0 pl-lg-2',
                                Component: MobileDeviceBreakdown
                            },{
                                key: 'userLoginBreakdown',
                                title: 'User Last Login Breakdown',
                                Component: UserLoginBreakdown
                            },{
                                key: 'mobileAppVersions',
                                title: 'Mobile App Versions',
                                Component: MobileAppVersions
                            },{
                                key: 'campaignsList',
                                title: 'Campaigns',
                                Component: CampaignsList
                            }]
                        },
                        saasAccounts: {
                            key: 'saasAccounts',
                            title: 'SaaS Clients',
                            visible: utils.user.get().level === User.level.admin && window.client_id === 'ecarra',
                            panels: [{
                                key: 'allSaaSAccounts',
                                title: 'All Accounts',
                                Component: SaaSAccounts
                            },{
                                key: 'saasPerformanceOverview',
                                title: 'Performance Overview',
                                Component: SaaSPerformanceOverview
                            },{
                                key: 'saasEmissionsOverview',
                                title: 'Emissions Overview',
                                Component: SaaSEmissionsOverview
                            },{
                                key: 'saasReservationsReport',
                                title: 'Reservations Report',
                                Component: SaaSReport.bind(this, {
                                    category: 'reservations'
                                })
                            },{
                                key: 'saasPaymentsReport',
                                title: 'Payments Report',
                                Component: SaaSReport.bind(this, {
                                    category: 'payments'
                                })
                            },{
                                key: 'saasUsersReport',
                                title: 'Users Report',
                                Component: SaaSReport.bind(this, {
                                    category: 'users'
                                })
                            },{
                                key: 'saasStatsGoogle',
                                title: 'Google Autocomplete Requests',
                                Component: SaaSUsageStatistics.bind(this, {
                                    allClients: true,
                                    category: 'google'
                                })
                            },{
                                key: 'saasStatsHere',
                                title: 'Here Flow and Incident Requests',
                                Component: SaaSUsageStatistics.bind(this, {
                                    allClients: true,
                                    category: 'here'
                                })
                            }]
                        }
                    }
                },
                ...clientHasFlavor('emissions') && {
                    emissions: {
                        key: 'emissions',
                        title: 'Emissions',
                        icon: 'navigation-icon-emissions',
                        panels: [{
                            key: 'seedPodAirQuality',
                            title: 'SeedPod Air Quality',
                            visible: window.client_id === 'ecarra',
                            Component: SeedPodAirQuality
                        },{
                            key: 'dailyEmissionsBreakdown',
                            title: 'Daily Breakdown',
                            Component: DailyEmissions
                        },{
                            key: 'monthlyEmissionsBreakdown',
                            title: 'Monthly Breakdown',
                            Component: MonthlyEmissions
                        },{
                            key: 'treePlantingRequests',
                            title: 'Tree Planting Requests',
                            visible: utils.user.get().level <= User.level.admin,
                            Component: TreePlantingRequests
                        },{
                            key: 'emissionRewards',
                            title: 'All Rewards',
                            visible: utils.user.get().level <= User.level.admin,
                            Component: EmissionRewards
                        }]
                    }
                },
                ...clientHasFlavor('companies') && {
                    companies: {
                        key: 'companies',
                        title: 'Companies',
                        icon: 'navigation-icon-companies',
                        visible: utils.user.get().level <= User.level.admin,
                        panels: [{
                            key: 'companies',
                            title: 'Companies',
                            Component: Companies
                        },{
                            key: 'companiesReservationsReport',
                            title: 'Reservations Report',
                            Component: CompaniesReport.bind(this, {
                                category: 'reservations'
                            })
                        },{
                            key: 'companiesEmissionsReport',
                            title: 'Emissions Report',
                            Component: CompaniesReport.bind(this, {
                                category: 'emissions'
                            })
                        },{
                            key: 'companiesPaymentsReport',
                            title: 'Payments Report',
                            Component: CompaniesReport.bind(this, {
                                category: 'payments'
                            })
                        }]
                    }
                },
                ...clientHasFlavor('payments') && {
                    payments: {
                        key: 'payments',
                        title: 'Payments',
                        icon: 'navigation-icon-payments',
                        subViews: {
                            overview: {
                                key: 'overview',
                                title: 'Overview',
                                panels: [{
                                    key: 'paymentPreferences',
                                    title: 'Payment Preferences',
                                    visible: utils.user.get().level <= User.level.admin,
                                    Component: PaymentPreferences
                                },{
                                    key: 'payments',
                                    title: 'All Payments',
                                    Component: Payments
                                },
                                ...orderChannels.channels.map(channel => {
                                    return {
                                        key: `unpaidOrders${channel.id}`,
                                        title: `Unpaid ${channel.name} Orders`,
                                        Component: UnpaidOrders.bind(this, {
                                            channel: channel
                                        })
                                    }
                                }), {
                                    key: 'unpaidReservations',
                                    title: 'Unpaid Reservations',
                                    Component: UnpaidReservations
                                },{
                                    key: 'paymentsMothlyBreakdown',
                                    title: 'Monthly Totals Breakdown',
                                    Component: PaymentsMonthlyBreakdown
                                }]
                            },
                            ...clientHasFlavor('payments.credits') && {
                                credits: {
                                    key: 'credits',
                                    title: 'Credits',
                                    panels: [{
                                        key: 'creditsActivity',
                                        title: 'Activity',
                                        Component: CreditsActivity
                                    },{
                                        key: 'userCredits',
                                        title: 'Customer Credits',
                                        Component: CreditsList.bind(this, {
                                            category: 'user'
                                        })
                                    },{
                                        key: 'companyCredits',
                                        title: 'Company Credits',
                                        Component: CreditsList.bind(this, {
                                            category: 'company'
                                        })
                                    },
                                    ...orderChannels.channels.map(channel => {
                                        return {
                                            key: `orders${channel.id}WithCredits`,
                                            title: `${channel.name} Orders with Credits`,
                                            Component: OrdersWithCredits.bind(this, {
                                                channel: channel
                                            })
                                        }
                                    }),{
                                        key: 'reservationsWithCredits',
                                        title: 'Reservations with Credits',
                                        Component: ReservationsWithCredits
                                    }]
                                }
                            },
                            ...clientHasFlavor('payments.promotions') && {
                                promotions: {
                                    key: 'promotions',
                                    title: 'Promotions',
                                    visible: utils.user.get().level <= User.level.admin,
                                    panels: [{
                                        key: 'promoCodesAcitivty',
                                        title: 'Promotion Activity',
                                        Component: PromoCodesActivity
                                    },{
                                        key: 'promotions',
                                        title: 'Promotions',
                                        Component: PromoCodes
                                    }]
                                }
                            },
                            ...clientHasFlavor('payments.subscriptions') && {
                                subscriptions: {
                                    key: 'subscriptions',
                                    title: 'Subscriptions',
                                    visible: utils.user.get().level <= User.level.admin,
                                    panels: [{
                                        key: 'subscriptionAcitivty',
                                        title: 'Subscription Activity',
                                        Component: SubscriptionActivity
                                    },{
                                        key: 'subscriptionPlansActivity',
                                        title: 'Subscription Plan Activity',
                                        Component: SubscriptionPlansAcitivty
                                    },{
                                        key: 'subscriptions',
                                        title: 'All Subscriptions',
                                        Component: Subscriptions
                                    },{
                                        key: 'subscriptionPlans',
                                        title: 'All Subscription Plans',
                                        Component: SubscriptionPlans
                                    },
                                    ...orderChannels.channels.map(channel => {
                                        return {
                                            key: `orders${channel.id}WithSubscriptions`,
                                            title: `${channel.name} Orders with Subscriptions`,
                                            Component: OrdersWithSubscriptions.bind(this, {
                                                channel: channel
                                            })
                                        }
                                    }),{
                                        key: 'reservationsWithSubscriptions',
                                        title: 'Reservations with Subscriptions',
                                        Component: ReservationsWithSubscriptions
                                    }]
                                }
                            }
                        }
                    }
                },
                ...clientHasFlavor('vehicles') && {
                    vehicles: {
                        key: 'vehicles',
                        title: 'Vehicles',
                        icon: 'navigation-icon-vehicles',
                        visible: utils.user.get().level <= User.level.admin,
                        subViews: {
                            overview: {
                                key: 'overview',
                                title: 'Overview',
                                panels: [{
                                    key: 'vehicles',
                                    title: 'Vehicles',
                                    Component: Vehicles
                                },{
                                    key: 'vehicleCategories',
                                    title: 'Vehicle Categories',
                                    Component: VehicleCategories
                                },{
                                    key: 'vehicleDemandBreakdown',
                                    title: 'Vehicle Demand Breakdown',
                                    Component: VehicleDemand
                                }]
                            },
                            ...clientHasFlavor('vehicles.drive_experiences') && {
                                driveExperiences: {
                                    key: 'driveExperiences',
                                    title: 'Drive Experiences',
                                    panels: [{
                                        key: 'drive_experiences',
                                        title: 'Drive Experiences',
                                        Component: DriveExperiences
                                    },{
                                        key: 'drive_experience_services',
                                        title: 'Services',
                                        Component: Services.bind(this, { 
                                            channel: 'vehicles.drive_experiences',
                                            panelID: 'drive_experience_services'
                                        })
                                    }]
                                }
                            },
                            ...clientHasFlavor('vehicles.onboarding') && {
                                vehicleOnboarding: {
                                    key: 'vehicleOnboarding',
                                    title: 'Onboarding',
                                    panels: [{
                                        key: 'onboardingCustomerVehicleApps',
                                        title: 'Customer Applications',
                                        Component: VehicleOnboardingApps.bind(this, {
                                            channel: 'customer'
                                        })
                                    },{
                                        key: 'onboardingCompanyVehicleApps',
                                        title: 'Company Applications',
                                        Component: VehicleOnboardingApps.bind(this, {
                                            channel: 'company'
                                        })
                                    }]
                                }
                            },
                            ...clientHasFlavor('vehicles.street_link') && {
                                streetLink: {
                                    key: 'streetLink',
                                    title: 'Street Link',
                                    panels: [{
                                        key: 'streetLinkIncidents',
                                        title: 'Street Link Incidents',
                                        Component: StreetLinkIncidents
                                    },{
                                        key: 'streetdLinkTraffic',
                                        title: 'Street Link Traffic',
                                        Component: StreetLinkTraffic
                                    }]
                                }
                            }
                        }
                    }
                },
                feedback: {
                    key: 'feedback',
                    title: 'Feedback',
                    icon: 'navigation-icon-feedback',
                    visible: utils.user.get().level <= User.level.admin,
                    panels: [{
                        key: 'netPromoterBreakdown',
                        title: 'Net Promoter Breakdown',
                        Component: NetPromoter
                    },{
                        key: 'feedbackResponses',
                        title: 'All Responses',
                        Component: FeedbackResponses
                    },{
                        key: 'ratingsBreakdown',
                        title: 'Ratings Breakdown',
                        Component: RatingsBreakdown
                    }/*,{
                        key: 'allSurveys',
                        title: 'All Surveys',
                        Component: FeedbackSurveys
                    },{
                        key: 'allSurveyQuestions',
                        title: 'All Survey Questions',
                        Component: FeedbackQuestions
                    }*/]
                },
                ...clientHasFlavor('news') && newsChannels.channels.reduce((object, channel) => {
                    return {
                        ...object,
                        [`news_${channel.id}`]: {
                            key: `news_${channel.id}`,
                            title: channel.name,
                            visible: utils.user.get().level <= User.level.admin,
                            icon: 'navigation-icon-news' || channel.icon.seeds,
                            panels: [{
                                key: `news${channel.id}Activity`,
                                title: `${channel.name} Activity`,
                                Component: NewsActivity.bind(this, {
                                    channel: channel
                                })
                            },{
                                key: `news${channel.id}Categories`,
                                title: `Categories for ${channel.name}`,
                                Component: NewsCategories.bind(this, {
                                    channel: channel
                                })
                            },{
                                key: `news${channel.id}Items`,
                                title: `Items for ${channel.name}`,
                                Component: NewsItems.bind(this, {
                                    channel: channel
                                })
                            }]
                        },
                    }
                }, { } ),
                notifications: {
                    key: 'notifications',
                    title: 'Notifications',
                    icon: 'navigation-icon-notifications',
                    visible: utils.user.get().level <= User.level.admin,
                    panels: [{
                        key:'todayNotifications',
                        title: 'Today\'s Notifications',
                        Component: NotificationsList.bind(this, {
                            today: true
                        })
                    },{
                        key: 'pastNotifications',
                        title: 'Past Notifications',
                        Component: NotificationsList.bind(this, {
                            today: false
                        })
                    }]
                },
                ...window.client_id === 'ecarra' && {
                    resources: {
                        key: 'resources',
                        title: 'Resources',
                        icon: 'navigation-icon-resources',
                        visible: utils.user.get().level <= User.level.admin,
                        panels: [{
                            key: 'supportTickets',
                            title: 'Support Tickets',
                            Component: SupportTickets
                        },{
                            key: 'saasSupportTickets',
                            title: 'SaaS Support Tickets',
                            Component: SaaSSupportTickets
                        }]
                    }
                },
                ...window.client_id !== 'ecarra' && {
                    resources: {
                        key: 'resources',
                        title: 'Resources',
                        icon: 'navigation-icon-resources',
                        visible: utils.user.get().level <= User.level.admin,
                        subViews: {
                            support: {
                                key: 'support',
                                title: 'Support',
                                panels: [{
                                    key: 'supportTickets',
                                    title: 'Support Tickets',
                                    Component: SupportTickets
                                }]
                            },
                            account: {
                                key: 'account',
                                title: `${window.clientParameters.name} Account`,
                                panels: [{
                                    key: 'saasAccountSettings',
                                    title: 'Account Settings',
                                    Component: SaaSAccountSettings
                                },{
                                    key: 'saasStatsGoogle',
                                    title: 'Google Autocomplete Requests',
                                    Component: SaaSUsageStatistics.bind(this, {
                                        category: 'google'
                                    })
                                },{
                                    key: 'saasStatsHere',
                                    title: 'Here Flow and Incident Requests',
                                    Component: SaaSUsageStatistics.bind(this, {
                                        category: 'here'
                                    })
                                }]
                            }
                        }
                    }
                }
            });

        } catch(e) {
            reject(e);
        }
    })
}
