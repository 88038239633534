import React, { useCallback, useState } from 'react';
import update from 'immutability-helper';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { CampaignDetails } from 'managers/Users.js';
import { CompanyDetails } from 'managers/Companies.js';
import { CreditsCardDetails, ICHMethodDetails, PaymentDetails, PromoCodeDetails, SubscriptionDetails } from 'managers/Payments.js';
import DownloadManager from 'views/DownloadManager.js';
import { DriverApplicationDetails, SaaSAccountDetails, UserDetails } from 'managers/Users.js';
import { FeedbackDetails } from 'managers/Feedback.js';
import Logo from 'files/lottie/logo.json';
import LogoIcon from 'files/lottie/logo-icon.json';
import { MakePayment } from 'views/PaymentManager.js';
import { NotesManager } from 'views/NotesManager.js';
import Order from 'classes/Order.js';
import { OrderCustomerMap, OrderDetails, OrderHostCatalog, OrderHostCatalogOption, OrderHostDetails } from 'managers/Orders.js';
import { QuickScanRouteDetails } from 'managers/Routes.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import { ReservationDetails } from 'managers/Reservations.js';
import Route from 'classes/Route.js';
import Subscription from 'classes/Subscription.js';
import SystemEvent from 'classes/SystemEvent.js';
import User from 'classes/User.js';
import { VehicleApplicationDetails, VehicleCategoryDetails, VehicleDetails } from 'managers/Vehicles.js';
import Views from 'views/Main.js';

const Utils = {
    addressLookup: async (utils, text, session) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { places } = await Request.get(utils, '/resources/', {
                    type: 'address_lookup',
                    search_text: text,
                    session: session,
                    autocomplete: true
                })

                resolve(places.map((result, index) => {
                    return {
                        key: index,
                        place_id: result.place_id,
                        name: result.name || 'Name Unavailable',
                        address: result.address
                    }
                }))

            } catch(e) {
                reject(e);
            }
        })
    },
    attributeForKey: {
        select: (e, key) => {
            let optionElement = e.target.childNodes[e.target.selectedIndex];
            return optionElement.getAttribute(key);
        }
    },
    apply: (target, object) => {
        let key = Object.keys(object).find(k => isNaN(k) ? k === target : parseFloat(k) === target);
        return key && typeof(object[key]) === 'function' ? object[key]() : (typeof(object.default) === 'function' ? object.default() : null);
    },
    campaigns: {
        details: (utils, campaign) => {
            utils.layer.open({
                id: `campaign-details-${campaign.id}`,
                abstract: Abstract.create({
                    type: 'campaigns',
                    object: campaign
                }),
                Component: CampaignDetails
            });
        }
    },
    companies: {
        details: (utils, company) => {
            utils.layer.open({
                id: `company-details-${company.id}`,
                abstract: Abstract.create({
                    type: 'companies',
                    object: company
                }),
                Component: CompanyDetails
            });
        }
    },
    conformDate: (date, interval, nearest_neighbor) => {
        let targetDate = date ? moment(date) : moment();
        let minutes = parseInt(moment(targetDate).format('mm'));
        if(minutes % interval === 0) {
            return moment(targetDate);
        }

        // force date to interval offset and zero out seconds
        let decimal = minutes % interval;
        let offset = interval - decimal;
        if(!nearest_neighbor || interval / 2 > offset) {
            return moment(`${targetDate.format('YYYY-MM-DD HH:mm')}:00`).add(offset, 'minutes');
        }

        // round down to interval when nearest_neighbor is enabled
        return moment(`${targetDate.format('YYYY-MM-DD HH:mm')}:00`).subtract(interval - offset, 'minutes');
    },
    create: async (utils, props = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                switch(props.type) {
                    case 'orders':
                    let order = await Order.get(utils, props.id);
                    resolve({
                        order: order,
                        abstract: Abstract.create({
                            type: 'orders',
                            object: order
                        })
                    })
                    break;

                    case 'routes':
                    let route = await Route.get(utils, props.id);
                    resolve({
                        route: route,
                        abstract: Abstract.create({
                            type: 'routes',
                            object: route
                        })
                    })
                    break;

                    case 'reservations':
                    let reservation = await Reservation.get(utils, props.id);
                    resolve({
                        reservation: reservation,
                        abstract: Abstract.create({
                            type: 'reservations',
                            object: reservation
                        })
                    })
                    break;
                }
            } catch(e) {
                reject(e);
            }
        })
    },
    createMultiple: async (utils, { orderID, routeID, reservationID, subscriptionID }) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { order, reservation, route, subscription } = await Request.get(utils, '/resources/', {
                    type: 'details_multiple',
                    order_id: orderID,
                    route_id: routeID,
                    reservation_id: reservationID,
                    subscription_id: subscriptionID
                });
                resolve({
                    order: order ? Order.create(order) : null,
                    route: route ? Route.create(route) : null,
                    reservation: reservation ? Reservation.create(reservation) : null,
                    subscription: subscription ? Subscription.create(subscription) : null
                });

            } catch(e) {
                reject(e);
            }
        })
    },
    credits: {
        details: (utils, card) => {
            utils.layer.open({
                id: `credits-card-details-${card.id}`,
                abstract: Abstract.create({
                    type: 'credits',
                    object: card
                }),
                Component: CreditsCardDetails
            });
        }
    },
    decodePolyline: str => {
        if(Array.isArray(str)) {
            return str; // return if already decoded
        }
        //@mapbox/polyline
        let precision = 6;
        var index = 0,
            lat = 0,
            lng = 0,
            coordinates = [],
            shift = 0,
            result = 0,
            byte = null,
            latitude_change,
            longitude_change,
            factor = Math.pow(10, precision || 6);

        // Coordinates have variable length when encoded, so just keep
        // track of whether we've hit the end of the string. In each
        // loop iteration, a single coordinate is decoded.
        while (index < str.length) {

            // Reset shift, result, and byte
            byte = null;
            shift = 0;
            result = 0;

            do {
                byte = str.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

            shift = result = 0;

            do {
                byte = str.charCodeAt(index++) - 63;
                result |= (byte & 0x1f) << shift;
                shift += 5;
            } while (byte >= 0x20);

            longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

            lat += latitude_change;
            lng += longitude_change;

            coordinates.push([lat / factor, lng / factor]);
        }
        return coordinates;
    },
    distanceConversion: value => {
        let feet = parseFloat(value || 0) * 5280;
        return feet > 999 ? (parseFloat(value || 0).toFixed(1) + (value === 1 ? ' mile' : ' miles')) : (feet.toFixed(0) + (feet === 1 ? ' foot' : ' feet'));
    },
    downloads: {
        content: (utils, props) => {
            utils.layer.open({
                id: `downloading-${props.id}`,
                Component: DownloadManager.bind(this, {
                    ...props,
                    id: `downloading-${props.id}`
                })
            });
        },
        text: (fileName, content) => {
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
            element.setAttribute('download', fileName);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    },
    encodePolyline: (current, previous, factor) => {
        const py2_round = value => {
            // Google's polyline algorithm uses the same rounding strategy as Python 2, which is different from JS for negative values
            return Math.floor(Math.abs(value) + 0.5) * (value >= 0 ? 1 : -1);
        }

        //@mapbox/polyline
        current = this.py2_round(current * factor);
        previous = this.py2_round(previous * factor);
        var coordinate = current - previous;
        coordinate <<= 1;
        if (current - previous < 0) {
            coordinate = ~coordinate;
        }
        var output = '';
        while (coordinate >= 0x20) {
            output += String.fromCharCode((0x20 | (coordinate & 0x1f)) + 63);
            coordinate >>= 5;
        }
        output += String.fromCharCode(coordinate + 63);
        return output;
    },
    feedback: {
        details: (utils, response) => {
            utils.layer.open({
                id: `feedback-details-${response.id}`,
                abstract: Abstract.create({
                    type: 'feedback',
                    object: response
                }),
                Component: FeedbackDetails
            });
        }
    },
    formatAddress: props => {
        // return ala-carte address components as they are found
        let { address, country, city, name, state, zipcode } = props || {};
        return [ address, city, state, zipcode, country ].filter(val => {
            return val && val.toString().length > 1 ? true : false;
        }).join(', ');
    },
    formatCardNumber: value => {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || ''
        var parts = []
        for(var i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i+4))
        }
        return parts.length ? parts.join(' ') : value
    },
    formatDate: (date, withoutTime = false) => {
        if(!date ) {
            return 'Unknown';
        }
        let next_date = moment(date);
        if(next_date.isValid() !== true) {
            return 'Date is not valid';
        }
        if(moment().isSame(next_date, 'day')) {
            return moment(date).format(withoutTime ? '[Today] MMMM Do' : '[Today at] h:mma');
        }
        if(moment().subtract(1, 'days').isSame(next_date, 'day')) {
            return moment(date).format(withoutTime ? '[Yesterday] MMMM Do' : '[Yesterday at] h:mma');
        }
        if(next_date > moment() && next_date <= moment().add(6, 'days')) {
            return moment(date).format(withoutTime ? 'dddd MMMM Do' : 'dddd [at] h:mma');
        }
        if(moment().isSame(next_date, 'year')) {
            return moment(date).format(withoutTime ? 'MMMM Do' : 'MMM Do [at] h:mma');
        }
        return moment(date).format('MM/DD/YYYY');
    },
    formatLocation: props => {
        const truncate = n => {
            return n > 0 ? Math.floor(n) : Math.ceil(n);
        }
        const getDMS = (dd, longOrLat) => {
            let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
            ? dd < 0
              ? "W"
              : "E"
            : dd < 0
              ? "S"
              : "N";

            const absDD = Math.abs(dd);
            const degrees = truncate(absDD);
            const minutes = truncate((absDD - degrees) * 60);
            const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);

            let dmsArray = [degrees, minutes, seconds, hemisphere];
            return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
        }
        let lat = props.lat || props.latitude;
        let long = props.long || props.longitude;
        return `${getDMS(lat, 'lat')} by ${getDMS(long, 'long')}`
    },
    formatPhoneNumber: phone_number => {
        var cleaned = ('' + phone_number).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '')
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return phone_number
    },
    geocode: async (utils, location, session) => {
        return new Promise(async (resolve,reject) => {
            try {
                let response = await Request.get(utils, '/resources/', {
                    type: 'geocode_location',
                    session: session,
                    ...location
                });
                resolve(response);
            } catch(e) {
                reject(e);
            }
        })
    },
    getCenterFromAnnotations: annotations => {
        if(annotations.length === 0) {
            return;
        }
        if(annotations.length === 1) {
            return [annotations[0].location.latitude, annotations[0].location.longitude];
        }

        var num_coords = annotations.length;

        var X = 0.0;
        var Y = 0.0;
        var Z = 0.0;

        for(var i = 0; i < annotations.length; i++) {
            var lat = annotations[i].location.latitude * Math.PI / 180;
            var lon = annotations[i].location.longitude * Math.PI / 180;

            var a = Math.cos(lat) * Math.cos(lon);
            var b = Math.cos(lat) * Math.sin(lon);
            var c = Math.sin(lat);

            X += a;
            Y += b;
            Z += c;
        }

        X /= num_coords;
        Y /= num_coords;
        Z /= num_coords;

        var newLon = Math.atan2(Y, X);
        var hyp = Math.sqrt(X * X + Y * Y);
        var newLat = Math.atan2(Z, hyp);

        var newX = (newLat * 180 / Math.PI);
        var newY = (newLon * 180 / Math.PI);

        return [newX, newY];
    },
    getClientLottieLogo: utils => {
        if(window.client_id === 'ecarra') {
            return Logo;
        }
        return {
            ...Logo,
            assets: Logo.assets.map(asset => {
                // replace white icon
                if(asset.id === 'image_0' && window.client_id !== 'ecarra') {
                    asset.u = '';
                    asset.p = utils.client.get().logos.icon;
                }
                return asset;
            }),
            layers: Logo.layers.map(layer => {
                // replace colors in gradient
                if(layer.nm !== 'gradient-layer') {
                    return layer;
                }
                try {
                    let { dark, light, regular } = utils.client.get().parameters.colors;
                    let [r1, g1, b1] = Utils.hexToRGBComponents(dark);
                    let [r2, g2, b2] = Utils.hexToRGBComponents(regular);
                    let [r3, g3, b3] = Utils.hexToRGBComponents(light);
                    layer.shapes[0].it[1].g.k.k = [ 0, r1 / 255, g1 / 255, b1 / 255, 0.5, r2 / 255, g2 / 255, b2 / 255, 1, r3 / 255, g3 / 255, b3 / 255 ];
                } catch(e) {
                    console.error(e.message);
                }
                return layer;
            })
        }
    },
    getClientLottiePanelIcon: utils => {
        if(window.client_id === 'ecarra') {
            return LogoIcon;
        }
        return {
            ...LogoIcon,
            layers: LogoIcon.layers.map(layer => {
                // replace colors in gradient
                if(layer.nm !== 'gradient-layer') {
                    return layer;
                }
                try {
                    let { dark, light, regular } = utils.client.get().parameters.colors;
                    let [r1, g1, b1] = Utils.hexToRGBComponents(dark);
                    let [r2, g2, b2] = Utils.hexToRGBComponents(regular);
                    let [r3, g3, b3] = Utils.hexToRGBComponents(light);
                    layer.shapes[0].it[1].g.k.k = [ 0, r1 / 255, g1 / 255, b1 / 255, 0.5, r2 / 255, g2 / 255, b2 / 255, 1, r3 / 255, g3 / 255, b3 / 255 ];
                } catch(e) {
                    console.error(e.message);
                }
                return layer;
            })
        }
    },
    getICHMethodOptions: async (utils, abstract, method) => {
        return new Promise((resolve, reject) => {
            utils.sheet.show({
                title: method.type(),
                message: method.subType(),
                items: [{
                    key: 'status',
                    title: method.status === 'active' ? 'Deactivate' : 'Activate',
                    style: method.status === 'active' ? 'destructive' : 'default'
                },{
                    key: 'lost',
                    title: 'Report as Lost',
                    style: 'destructive'
                },{
                    key: 'stolen',
                    title: 'Report as Stolen',
                    style: 'destructive'
                },{
                    key: 'close',
                    title: 'Close Card',
                    style: 'destructive'
                }]
            }, key => {
                // TODO => implement callbacks
                resolve();
            })
        })
    },
    getPagingOffset: (offset, direction) => {
        let newOffset = 0;
        if(direction == 'next') {
            newOffset = offset + 5;
        } else if(direction == 'back') {
            newOffset = offset - 5;
        } else if(!isNaN(direction)) {
            newOffset = (direction - 1) * 5;
        }
        return newOffset < 0 ? 0 : newOffset;
    },
    getPaymentMethodOptions: (utils, abstract, method, options) => {
        return new Promise((resolve, reject) => {
            if(method.default) {
                utils.alert.show({
                    title: method.type(),
                    message: 'This payment method is currently set as the default payment method. Another payment method must be set as the default payment method before this payment method can be removed',
                    onClick: resolve
                })
                return;
            }
            utils.sheet.show({
                title: method.type(),
                message: method.subType(),
                items: [{
                    key: 'default',
                    title: 'Set as Default',
                    style: 'default'
                },{
                    key: 'remove',
                    title: 'Remove Payment Method',
                    style: 'destructive'
                }]
            }, async key => {
                if(key === 'default') {
                    try {
                        await method.setAsDefault(utils, abstract);
                        if(options && typeof(options.onLoad === 'function')) {
                            options.onLoad();
                        }
                        resolve();
                    } catch(e) {
                        reject(e);
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue setting this payment method as the default payment method. ${e.message || 'An unknown error occurred'}`
                        });
                    }
                    return;
                }
                if(key === 'remove') {
                    utils.alert.show({
                        title: 'Remove Payment Method',
                        message: 'Are you sure that you want to remove this payment method? This can not be undone.',
                        buttons: [{
                            key: 'remove',
                            title: 'Remove',
                            style: 'destructive'
                        },{
                            key: 'cancel',
                            title: 'Do Not Remove',
                            style: 'default'
                        }],
                        onClick: async key => {
                            if(key === 'remove') {
                                try {
                                    await method.removeMethod(utils, abstract);
                                    if(options && typeof(options.onLoad === 'function')) {
                                        options.onLoad();
                                    }
                                    resolve();
                                } catch(e) {

                                    reject(e);
                                    utils.alert.show({
                                        title: 'Oops!',
                                        message: `There was an issue removing this payment method. ${e.message || 'An unknown error occurred'}`
                                    });
                                }
                                return;
                            }
                            resolve();
                        }
                    });
                    return;
                }
                resolve();
            })
        });
    },
    getRegionFromAnnotations: coordinates => {

        let northWest = {
            latitude: -90,
            longitude: 180
        };
        let southEast = {
            latitude: 90,
            longitude: -180
        };

        coordinates.forEach((coordinate) => {
            northWest.longitude = Math.min(northWest.longitude, isNaN(coordinate[1]) ? coordinate.location.longitude : coordinate[1]);
            northWest.latitude = Math.max(northWest.latitude, isNaN(coordinate[0]) ? coordinate.location.latitude : coordinate[0]);

            southEast.longitude = Math.max(southEast.longitude, isNaN(coordinate[1]) ? coordinate.location.longitude : coordinate[1]);
            southEast.latitude = Math.min(southEast.latitude, isNaN(coordinate[0]) ? coordinate.location.latitude : coordinate[0]);
        })
        return [
            [ northWest.longitude, northWest.latitude ],
            [ southEast.longitude, southEast.latitude ]
        ];
    },
    getRegionForCircle: (coords, miles, reverse) => {

        let points = 64;
        let km = miles * 1.609;

        var ret = [];
        var distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
        var distanceY = km / 110.574;

        var theta, x, y;
        for(var i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX*Math.cos(theta);
            y = distanceY*Math.sin(theta);

            let lat = coords.latitude + y;
            let long = coords.longitude + x;
            if(lat < -90) {
                lat = -90;
            }
            if(lat > 90) {
                lat = 90
            }
            if(long < -180) {
                lat = -180;
            }
            if(lat > 180) {
                lat = 180
            }
            ret.push(reverse === false ? [ lat, long ] : [ long, lat ]);
        }
        ret.push(ret[0]);
        return ret;
    },
    getSaaSParameters: async (utils, client_id, fullParameters) => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!client_id) {
                    resolve();
                    return;
                }
                let { parameters} = await Request.get(utils, '/saas/',  {
                    type: fullParameters ? 'full_parameters' : 'client_parameters',
                    app_id: client_id
                });
                resolve(parameters);

            } catch(e) {
                reject(e);
            }
        })
    },
    getSpanFromAnnotations: annotations => {
        if(annotations.length === 1) {
            return {
                latitudeDelta: 0.1,
                longitudeDelta: 0.1
            }
        }

        let northWest = {
            latitude: -90,
            longitude: 180
        };
        let southEast = {
            latitude: 90,
            longitude: -180
        };

        annotations.forEach((annotation) => {
            northWest.longitude = Math.min(northWest.longitude, annotation.location.longitude);
            northWest.latitude = Math.max(northWest.latitude, annotation.location.latitude);

            southEast.longitude = Math.max(southEast.longitude, annotation.location.longitude);
            southEast.latitude = Math.min(southEast.latitude, annotation.location.latitude);
        })
        return {
            latitudeDelta: parseFloat(northWest.latitude - southEast.latitude) * 1.5,
            longitudeDelta: parseFloat(southEast.longitude - northWest.longitude) * 1.5
        };
    },
    getSystemEventBadges: evt => {
        let badges = [];
        switch(evt.action.code) {
            case SystemEvent.actions.create:
            badges.push({
                text: 'Created',
                color: Appearance.colors.primary()
            });
            break;

            case SystemEvent.actions.update:
            badges.push({
                text: 'Updated',
                color: Appearance.colors.secondary()
            });
            break;

            case SystemEvent.actions.delete:
            badges.push({
                text: 'Deleted',
                color: Appearance.colors.red
            });
            break;

            case SystemEvent.actions.warning:
            badges.push({
                text: 'Warning',
                color: Appearance.colors.orange
            });
            break;

            case SystemEvent.actions.note:
            badges.push({
                text: 'Note',
                color: Appearance.colors.tertiary()
            });
            break;
        }
        return badges.concat([{
            text: Utils.formatDate(evt.date),
            color: Appearance.colors.grey()
        }]);
    },
    getSystemEventsList: (events, onSystemEventClick) => {
        return events.map((evt, index) => {
            let { values } = Utils.getSystemEventProps(evt);
            if(!values) {
                return null;
            }
            return (
                Views.entry({
                    key: index,
                    title: evt.user ? evt.user.full_name : 'Name Not Available',
                    subTitle: values.length > 0 ? `${values[0]}${values.length > 1 ? ` and ${values.length - 1} other ${values.length - 1 === 1 ? 'change':'changes'}` : ''}` : 'No overview available',
                    badge: Utils.getSystemEventBadges(evt),
                    icon: {
                        path: evt.user.avatar
                    },
                    onClick: onSystemEventClick.bind(this, evt),
                    bottomBorder: index !== events.length - 1
                })
            )
        })
    },
    getSystemEventProps: evt => {

        // create => use abstract generated title information
        if(evt.action.code === SystemEvent.actions.create) {
            let val = `${evt.user.full_name} created ${evt.title || `"title not available"`}`;
            if(evt.target.type === 'users') {
                val = `${evt.user.full_name} created an account for ${evt.title || `"name not available"`}`;
            }
            return {
                values: [ val ],
                components: (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: 8,
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'normal'
                        }}>
                            <span style={{
                                fontWeight: 700,
                                color: Appearance.colors.text()
                            }}>
                                {evt.user.full_name}
                            </span>
                            {evt.target.type === 'users' ? ` created an account for ${evt.title || `"title not available"`}` : ` created ${evt.title || `"title not available"`}`}
                        </span>
                    </div>
                )
            }
        }

        // note or warning => use abstract generated title information
        let { message } = evt.props || {};
        if([ SystemEvent.actions.note, SystemEvent.actions.warning ].includes(evt.action.code)) {
            return {
                values: message ? [ message ] : [],
                components: (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: 8,
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'normal'
                        }}>
                            {message || 'Message is no longer available'}
                        </span>
                    </div>
                )
            }
        }

        // delete => use abstract generated title information
        if(evt.action.code === SystemEvent.actions.delete) {
            let val = `${evt.user.full_name} deleted the ${evt.title}`;
            return {
                values: [ val ],
                components: (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: 8,
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'normal'
                        }}>
                            <span style={{
                                fontWeight: 700,
                                color: Appearance.colors.text()
                            }}>
                                {evt.user.full_name}
                            </span>
                            {` deleted the ${evt.title}`}
                        </span>
                    </div>
                )
            }
        }

        // check for specific color based on data point or value
        const getValueColor = (key, val) => {
            if(!val) {
                return Appearance.colors.grey();
            }
            // set color based on key
            switch(key) {
                case 'status':
                switch(evt.target.type) {
                    case 'orders':
                    let order_status = Order.formatStatus(val)
                    return order_status ? order_status.color : Appearance.colors.grey();

                    case 'reservations':
                    let reservation_status = Reservation.formatStatus(val)
                    return reservation_status ? reservation_status.color : Appearance.colors.grey();
                }
                break;

                case 'active':
                switch(evt.target.type) {
                    default:
                    return val === 'Yes' ? Appearance.colors.green : Appearance.colors.red;
                }
                break;
            }
            return null;
        }

        // create components with text overview of system event
        let values = evt.diff && evt.diff.values || [];
        let components = values.map((entry, index) => {
            if(entry.image === true) {
                return (
                    <div
                    key={index}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        width: '100%',
                        marginBottom: 8,
                        padding: 12
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            display: 'block',
                            color: Appearance.colors.text(),
                            whiteSpace: 'normal',
                            marginBottom: 8
                        }}>
                            {`Changed the ${entry.title.toLowerCase()}`}
                        </span>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <img
                            src={entry.original || 'images/system-event-no-image.png'}
                            style={{
                                width: 50,
                                height: 50,
                                minWidth: 50,
                                minHeight: 50,
                                borderRadius: 10,
                                overflow: 'hidden',
                                objectFit: 'cover',
                                border: `1px solid ${Appearance.colors.divider()}`,
                                backgroundColor: Appearance.colors.primary()
                            }}/>
                            <img
                            src={'images/next-arrow-grey.png'}
                            style={{
                                width: 35,
                                height: 35,
                                minWidth: 35,
                                minHeight: 35,
                                padding: 10,
                                objectFit: 'contain'
                            }} />
                            <img
                            src={entry.current || 'images/system-event-no-image.png'}
                            style={{
                                width: 50,
                                height: 50,
                                minWidth: 50,
                                minHeight: 50,
                                borderRadius: 10,
                                overflow: 'hidden',
                                objectFit: 'cover',
                                border: `1px solid ${Appearance.colors.divider()}`,
                                backgroundColor: Appearance.colors.primary()
                            }}/>
                        </div>
                    </div>
                )
            }
            if(entry.category === 'mutated_collection') {
                return (
                    <div
                    key={index}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: 8,
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'normal'
                        }}>
                            <span style={{
                                fontWeight: 700,
                                color: entry.removal === true ? Appearance.colors.red : Appearance.colors.green
                            }}>
                                {entry.message}
                            </span>
                            {` ${entry.removal === true ? 'from' : 'to'} ${entry.title.toLowerCase()}. `}
                        </span>
                    </div>
                )
            }
            if(entry.category === 'plain_text') {
                return (
                    <div
                    key={index}
                    style={{
                        ...Appearance.styles.unstyledPanel(),
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: 8,
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text(),
                            whiteSpace: 'normal'
                        }}>
                            {entry.text || 'No overview available'}
                        </span>
                    </div>
                )
            }

            return (
                <div
                key={index}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: 8,
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        whiteSpace: 'normal'
                    }}>
                        {`Changed ${entry.title.toLowerCase()} from `}
                        <span style={{
                            fontWeight: 700,
                            color: getValueColor(entry.key, entry.original) || Appearance.colors.text()
                        }}>
                            {entry.original || 'no value'}
                        </span>
                        {` to `}
                        <span style={{
                            fontWeight: 700,
                            color: getValueColor(entry.key, entry.current) || Appearance.colors.green
                        }}>
                            {entry.current || 'no value'}
                        </span>
                    </span>
                </div>
            )
        })

        return {
            components: components,
            values: values.map(entry => {
                if(entry.category === 'mutated_collection') {
                    return `${entry.message} ${entry.removal ? 'from' : 'to'} ${entry.title.toLowerCase()}`;
                }
                if(entry.category === 'plain_text') {
                    return entry.text || 'No Overview Available';
                }
                return `Changed ${entry.title.toLowerCase()} from ${entry.original || 'no value'} to ${entry.current || 'no value'}`
            })
        }
    },
    handleDownload: ({ file_type, url }) => {
        if(!url) {
            return;
        }
        if(file_type === 'xls') {
            window.location = url;
        }
        window.open(url);
    },
    hexToRGBA: (hex, alpha) => {
        var c;
    	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    		c= hex.substring(1).split('');
    		if(c.length === 3){
    			c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    		}
    		c= '0x'+c.join('');
    		return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')';
    	}
    	return hex;
    },
    hexToRGBComponents: hex => {
        var c;
    	if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    		c= hex.substring(1).split('');
    		if(c.length === 3){
    			c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    		}
    		c= '0x'+c.join('');
    		return [(c>>16)&255, (c>>8)&255, c&255];
    	}
    	return [];
    },
    ichMethod: {
        details: (utils, method) => {
            utils.layer.open({
                id: `ich-method-details-${method.id}`,
                abstract: Abstract.create({
                    type: 'ichMethod',
                    object: method
                }),
                Component: ICHMethodDetails
            });
        }
    },
    integerToOrdinal: i => {
        let j = i % 10;
        let k = i % 100;

        if(j === 1 && k !== 11) {
            return i + 'st';
        } else if (j === 2 && k !== 12) {
            return i + 'nd';
        } else if (j === 3 && k !== 13) {
            return i + 'rd';
        }
        return i + 'th';
    },
    isMobile: () => {
        return window.innerWidth < 767.98;
    },
    linearDistance: (coordinate, center) => {
        var radlat1 = Math.PI * (coordinate.lat || coordinate.latitude) / 180;
        var radlat2 = Math.PI * (center.lat || center.latitude) / 180;
        var theta = (coordinate.long || coordinate.longitude) - (center.long || center.longitude);
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        return dist
    },
    notes: (utils, target) => {
        let abstract = Abstract.create(target);
        utils.layer.open({
            id: `notes-manager-${abstract.getTag()}`,
            abstract: abstract,
            Component: NotesManager
        });
    },
    numberFormat: value => {
        if(value > 1000000) {
            return `${(parseFloat(value) / 1000000).toFixed(2)} M`;
        }
        if(value > 1000) {
            return `${(parseFloat(value) / 1000).toFixed(2)} K`;
        }
        return value % 1 !== 0 ? parseFloat(value).toFixed(1) : value;
    },
    orders: {
        details: (utils, order) => {
            utils.layer.open({
                id: `order-details-${order.id}`,
                abstract: Abstract.create({
                    type: 'orders',
                    object: order
                }),
                Component: OrderDetails
            });
        },
        host: {
            details: (utils, host) => {
                utils.layer.open({
                    id: `order-host-details-${host.id}`,
                    abstract: Abstract.create({
                        type: 'orderHosts',
                        object: host
                    }),
                    Component: OrderHostDetails.bind(this, {
                        channel: host.channel
                    })
                });
            }
        }
    },
    oxfordImplode: items => {
        if(!items || items.length === 0) {
            return null;
        }
        if(items.length === 1) {
            return items[0];
        }
        if(items.length === 2) {
            return `${items[0]} and ${items[1]}`;
        }
        let string = '';
        for(var i in items) {
            if(i > 0) {
                string += parseInt(i) === items.length - 1 ? ', and ' : ', ';
            }
            string += items[i];
        }
        return string;
    },
    parseDuration: (duration = 0) => {

        // convert duration to integrer
        // calculate how many hours, minutes, and seconds are in the duration
        duration = parseInt(duration);
        let h = Math.floor(duration / 3600);
        let m = Math.floor(duration % 3600 / 60);
        let s = Math.floor(duration % 3600 % 60);

        // format labels based on above calculations
        let hours = h > 0 ? `${h} ${h === 1 ? 'hour' : 'hours'}` : 0;
        let minutes = m > 0 ? `${m} ${m === 1 ? 'minute' : 'minutes'}` : 0;
        if(h > 0 && m > 0) {
            return `${hours} and ${minutes}`;
        }
        if(h > 0) {
            return hours;
        }
        if(m > 0) {
            return minutes;
        }
        return `${s} seconds`;
    },
    payments: {
        details: (utils, payment) => {
            utils.layer.open({
                id: `payment-details-${payment.id}`,
                abstract: Abstract.create({
                    type: 'payments',
                    object: payment
                }),
                Component: PaymentDetails
            });
        },
        make: (utils, target) => {
            let abstract = Abstract.create(target);
            utils.layer.open({
                id: `make-payment-${abstract.getTag()}`,
                abstract: abstract,
                Component: MakePayment
            });
        }
    },
    promotions: {
        details: (utils, code) => {
            utils.layer.open({
                id: `promo-code-details-${code.id}`,
                abstract: Abstract.create({
                    type: 'promotions',
                    object: code
                }),
                Component: PromoCodeDetails
            });
        }
    },
    randomString: () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    reservations: {
        details: (utils, reservation) => {
            utils.layer.open({
                id: `reservation-details-${reservation.id}`,
                abstract: Abstract.create({
                    type: 'reservations',
                    object: reservation
                }),
                Component: ReservationDetails
            });
        }
    },
    routes: {
        details: (utils, route) => {
            utils.layer.open({
                id: `route-details-${route.id}`,
                abstract: Abstract.create({
                    type: 'routes',
                    object: route
                }),
                Component: QuickScanRouteDetails
            });
        }
    },
    saas: {
        details: (utils, act) => {
            utils.layer.open({
                id: `saas-account-details-${act.id}`,
                abstract: Abstract.create({
                    type: 'saas',
                    object: act
                }),
                Component: SaaSAccountDetails
            })
        }
    },
    safeArea: () => {
        let doc = getComputedStyle(document.documentElement)
        return {
            top: doc.getPropertyValue('--sat'),
            bottom: doc.getPropertyValue('--sab'),
            left: doc.getPropertyValue('--sal'),
            right: doc.getPropertyValue('--sar')
        }
    },
    sleep: async seconds => {
        return new Promise(resolve => {
            setTimeout(resolve, seconds * 1000);
        })
    },
    softNumberFormat: (val, digits = 0) => {
        return parseFloat(val).toLocaleString('en-US', { minimumFractionDigits: digits })
    },
    subscriptions: {
        details: (utils, subscription) => {
            utils.layer.open({
                id: `subscription-details-${subscription.id}`,
                abstract: Abstract.create({
                    type: 'subscriptions',
                    object: subscription
                }),
                Component: SubscriptionDetails
            });
        }
    },
    timecode: duration => {
        let value = parseInt(duration);
        let sec_num = parseInt(value, 10); // don't forget the second param
        let hours   = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes + ' : ' + seconds;
    },
    toCurrency: (value, currency) => {
        return parseFloat(value || 0).toLocaleString('en-US', {
            style: 'currency',
            currency: currency || 'USD'
        })
    },
    ucFirst: text => {
        return text ? (text.charAt(0).toUpperCase() + text.substring(1)) : '';
    },
    users: {
        details: (utils, user) => {
            utils.layer.open({
                id: `user-details-${user.user_id}`,
                abstract: Abstract.create({
                    type: 'users',
                    object: user
                }),
                Component: UserDetails
            });
        },
        drivers: {
            applications: {
                details: (utils, app) => {
                    utils.layer.open({
                        id: `do-app-details-${app.id}`,
                        abstract: Abstract.create({
                            type: 'doApps',
                            object: app
                        }),
                        Component: DriverApplicationDetails
                    });
                }
            }
        }
    },
    vehicles: {
        application: {
            details: (utils, app) => {
                utils.layer.open({
                    id: `vo-app-details-${app.id}`,
                    abstract: Abstract.create({
                        type: 'voApps',
                        object: app
                    }),
                    Component: VehicleApplicationDetails
                })
            }
        },
        category: {
            details: (utils, category) => {
                utils.layer.open({
                    id: `vehicle-category-details-${category.id}`,
                    abstract: Abstract.create({
                        type: 'vehicleCategories',
                        object: category
                    }),
                    Component: VehicleCategoryDetails
                });
            }
        },
        details: (utils, vehicle) => {
            utils.layer.open({
                id: `vehicle-details-${vehicle.id}`,
                abstract: Abstract.create({
                    type: 'vehicles',
                    object: vehicle
                }),
                Component: VehicleDetails
            });
        }
    }
}
export default Utils;

export const useLoading = () => {
    const [state, setState] = useState('init');
    const onSetState = useCallback(val => {
        setState(val);
    }, []);
    return [state, onSetState]
}

export const useResultsManager = initialState => {

    const [state, setState] = useState({
        ...initialState,
        offset: 0
    });

    // state update accepts a props objects or a key, value pair
    const onSetState = useCallback((key, value) => {
        if(typeof(key) === 'string') {
            setState(props => update(props, {
                [key]: {
                    $set: value
                }
            }));
            return;
        }
        setState(key);
    }, []);

    // optional function to format results for query
    const formatResults = (utils, callback) => {
        let results = {
            ...state,
            ...state.end_date && {
                end_date: moment(state.end_date).utc().unix()
            },
            ...state.start_date && {
                start_date: moment(state.start_date).utc().unix()
            }
        }
        // check if callback was provided for specialty formatting
        if(typeof(callback) === 'function') {
            results = callback(results);
        }
        return results;
    }

    return [state, onSetState, formatResults]
}
