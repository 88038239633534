import React, { useEffect, useState } from 'react';

import Appearance from 'styles/Appearance.js';
import { LayerItem } from 'structure/Layer.js';
import Utils from 'files/Utils';
import Views from 'views/Main.js';

const CreditsManager = ({ defaultAmount, defaultCardID, utils, user, onChange, maxAmount }) => {

    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [methods, setMethods] = useState([]);
    const [selected, setSelected] = useState(null);

    const onClick = method => {

        // prevent moving forward if credits account does not have a sufficient balance
        if(method.balance() <= 0) {
            utils.alert.show({
                title: 'Oops!',
                message: 'This credits account does not have a usable balance'
            });
            return;
        }

        // update account selection state, deselect the account if the account is currently selected
        let next = selected && selected.id === method.id ? null : method;
        setSelected(next);

        // notify subscribers of account selection change
        if(typeof(onChange) === 'function') {
            onChange(next && {
                card_id: next.card.id,
                ...amount > 0 && {
                    amount: parseFloat(amount)
                }
            });
        }
    }

    const getContent = () => {
        if(loading === true) {
            return Views.loader();
        }
        return (
            <LayerItem
            title={'Accounts'}
            lastItem={true}>
                {methods.length == 0
                    ?
                    Views.entry({
                        title: 'Nothing Found',
                        subTitle: 'Credits are not setup for your account',
                        bottomBorder: false,
                        icon: {
                            style: Appearance.icons.standard(),
                            path: 'images/rejected-red-small.png'
                        }
                    })
                    :
                    methods.map((method, index) => {
                        return (
                            Views.entry({
                                key: method.id,
                                title: method.getType(),
                                subTitle: `Balance: ${Utils.toCurrency(method.balance())}`,
                                icon: {
                                    path: method.balance() <= 0 ? 'images/red-x-icon.png' : 'images/payment-icon-clear-small.png',
                                    style: {
                                        ...Appearance.icons.standard(),
                                        backgroundColor: selected && selected.id === method.id ? Appearance.colors.primary() : Appearance.colors.grey()
                                    }
                                },
                                bottomBorder: index !== methods.length - 1,
                                onClick: onClick.bind(this, method)
                            })
                        )
                    })
                }
            </LayerItem>
        )
    }

    const fetchMethods = async () => {
        try {
            setLoading(true);
            let { credits } = await user.getPaymentMethods(utils);

            setLoading(false);
            setMethods(credits);
            setAmount(defaultAmount || '0.00');
            setSelected(credits.find(m => defaultCardID === m.card.id));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the list of credits accounts. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchMethods();
    }, [])

    return (
        <div style={{
            textAlign: 'left',
            width: '100%'
        }}>
            {getContent()}
        </div>
    )
}
export default CreditsManager;
