import moment from 'moment-timezone';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class PromoCodeClass {

    id = null;
    title = null;
    description = null;
    company = null;
    code = null;
    discount = null;
    discount_type = null;
    image = null;
    start_date = null;
    end_date = null;
    submitted_by = null;
    submission_date = null;
    redeemed = null;
    redeemedBy = [];
    max_redemption = null;
    max_redemption_per_user = null;
    public = null;
    for_users = null;
    active = null;
    notes = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.company = props.company;
        this.code = props.code ? props.code.toUpperCase() : null;
        this.discount = props.discount;
        this.discount_type = props.discount_type;
        this.image = props.image;
        this.start_date = props.start_date;
        this.end_date = props.end_date;
        this.submission_date = props.submission_date;
        this.redeemed = props.redeemed;
        this.redeemedBy = props.redeemd_by;
        this.max_redemption = props.max_redemption;
        this.max_redemption_per_user = props.max_redemption_per_user;
        this.public = props.public;
        this.active = props.active;
        this.notes = props.notes;
        this.submitted_by = props.submitted_by ? User.create(props.submitted_by) : null;
        this.for_users = props.for_users ? props.for_users.map(u => User.create(u)) : null;
        return this;
    }

    getSummary = () => {
        return `${this.title}: ${this.getSummaryContent()}`;
    }

    getSummaryContent = () => {
        switch(this.discount_type) {
            case types.fixed_cost:
            return `${Utils.toCurrency(this.discount)} Discount`;

            case types.percentage:
            return `${(parseFloat(this.discount) * 100).toFixed(2)}% Discount`;

            case types.full_cost:
            return '100% Discount';

            case types.credits:
            return `${Utils.toCurrency(this.discount)} worth of Credits`;

            default:
            return 'Unknown discount type';
        }
    }

    getDiscountInformation = () => {
        switch(this.discount_type) {
            case types.fixed_cost:
            return {
                text: 'Fixed Cost',
                amount: Utils.toCurrency(this.discount)
            }

            case types.percentage:
            return {
                text: 'Percentage',
                amount: `${parseInt(this.discount > 1 ? this.discount : (this.discount * 100))}%`
            }

            case types.full_cost:
            return {
                text: 'Full Cost',
                amount: '100%'
            }

            case types.credits:
            return {
                text: 'Credits Redemption',
                amount: Utils.toCurrency(this.discount)
            }

            default:
            return {
                text: 'Unknown',
                amount: 'Unknown'
            }
        }
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            description: this.description,
            company: this.company,
            code: this.code,
            discount: this.discount <= 1 ? parseFloat(this.discount) * 100 : this.discount,
            discount_type: this.discount_type,
            start_date: this.start_date,
            end_date: this.end_date,
            max_redemption: this.max_redemption,
            max_redemption_per_user: this.max_redemption_per_user,
            public: this.public,
            for_users: this.for_users
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            title: props.title || this.edits.title,
            description: props.description || this.edits.description,
            company: props.company !== undefined ? props.company : this.edits.company,
            code: props.code || this.edits.code,
            discount: props.discount || this.edits.discount,
            discount_type: props.discount_type || this.edits.discount_type,
            start_date: props.start_date || this.edits.start_date,
            end_date: props.end_date || this.edits.end_date,
            max_redemption: props.max_redemption || this.edits.max_redemption,
            max_redemption_per_user: props.max_redemption_per_user || this.edits.max_redemption_per_user,
            public: props.public !== undefined ? props.public : this.edits.public,
            for_users: props.for_users || this.edits.for_users
        }
        if(this.edits.discount_type === types.full_cost) {
            this.edits.discount = 100;
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { amount, id } = await Request.post(utils, '/promotion/', {
                    type: 'new',
                    title: edits.title,
                    description: edits.description,
                    company: edits.company ? edits.company.id : null,
                    code: edits.code,
                    discount: {
                        type: edits.discount_type,
                        amount: edits.discount <= 1 ? parseFloat(edits.discount) / 100 : edits.discount
                    },
                    start_date: edits.start_date && moment(edits.start_date).utc().unix(),
                    end_date: edits.end_date && moment(edits.end_date).utc().unix(),
                    max_redemption: {
                        total: edits.max_redemption,
                        user: edits.max_redemption_per_user
                    },
                    public: edits.public || false,
                    for_users: edits.for_users ? edits.for_users.map(u => u.user_id) : null
                });

                this.id = id;
                this.title =  edits.title;
                this.description =  edits.description;
                this.company =  edits.company;
                this.code =  edits.code;
                this.discount = amount;
                this.discount_type =  edits.discount_type;
                this.start_date =  edits.start_date;
                this.end_date =  edits.end_date;
                this.max_redemption =  edits.max_redemption;
                this.max_redemption_per_user =  edits.max_redemption_per_user;
                this.public =  edits.public;
                this.for_users =  edits.for_users;

                utils.content.fetch('promotions');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { amount } = await Request.post(utils, '/promotion/', {
                    type: 'update',
                    id: this.id,
                    title: edits.title,
                    description: edits.description,
                    company: edits.company ? edits.company.id : null,
                    code: edits.code,
                    discount: {
                        type: edits.discount_type,
                        amount: edits.discount <= 1 ? parseFloat(edits.discount) / 100 : edits.discount
                    },
                    start_date: edits.start_date && moment(edits.start_date).utc().unix(),
                    end_date: edits.end_date && moment(edits.end_date).utc().unix(),
                    max_redemption: {
                        total: edits.max_redemption,
                        user: edits.max_redemption_per_user
                    },
                    public: edits.public || false,
                    for_users: edits.for_users ? edits.for_users.map(u => u.user_id) : null
                });

                this.title =  edits.title;
                this.description =  edits.description;
                this.company =  edits.company;
                this.code =  edits.code;
                this.discount = amount;
                this.discount_type =  edits.discount_type;
                this.start_date =  edits.start_date;
                this.end_date =  edits.end_date;
                this.max_redemption =  edits.max_redemption;
                this.max_redemption_per_user =  edits.max_redemption_per_user;
                this.public =  edits.public;
                this.for_users =  edits.for_users;

                utils.content.update({
                    type: 'promotions',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const types = {
    fixed_cost: 1,
    percentage: 2,
    full_cost: 3,
    credits: 4
}

const fetchPromoCode = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { promotion } = await Request.get(utils, '/promotion/', {
                type: 'details',
                id: id
            });
            let target = new PromoCodeClass().create(promotion);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new PromoCodeClass(),
    get: fetchPromoCode,
    create: props => new PromoCodeClass().create(props),
    types: types
}
