import React, { useEffect, useRef, useState } from 'react';

import { fetchCompanies } from 'managers/Companies.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import { AddEditService, ServiceDetails } from 'managers/Reservations.js';
import Appearance from 'styles/Appearance.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import BoolToggle from 'views/BoolToggle.js';
import BusinessHoursPicker from 'views/BusinessHoursPicker.js';
import Button from 'views/Button.js';
import { Calendar, MobileCalendar } from 'views/Calendar.js';
import Checkbox from 'views/Checkbox.js';
import CreditsManager from 'views/CreditsManager.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import EventManager from 'views/EventManager.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerEmissions, LayerItem, LayerMap, LayerNote } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import { Map } from 'views/MapElements.js';
import MultipleAddressLookupField from 'views/MultipleAddressLookupField.js';
import NoDataFound from 'views/NoDataFound.js';
import NumberStepper from 'views/NumberStepper.js';
import Order from 'classes/Order.js';
import OrderHostLookupField from 'views/OrderHostLookupField.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Payment from 'classes/Payment.js';
import PaymentMethodManager from 'views/PaymentMethodManager.js';
import PreauthorizationManager from 'views/PreauthorizationManager.js';
import PromoCodeLookupField from 'views/PromoCodeLookupField.js';
import Request from 'files/Request.js';
import Service from 'classes/Service.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Valhalla from 'classes/Valhalla.js';
import Views, { AltBadge } from 'views/Main.js';

// panels
export const ActiveOrders = ({ channel }, { index, utils }) => {

    const panelID = `activeOrders${channel.id}`;
    const limit = 5;

    const [annotations, setAnnotations] = useState([]);
    const [followPref, setFollowPref] = useState(true);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [orders, setOrders] = useState([]);
    const [overlays, setOverlays] = useState([]);
    const [paging, setPaging] = useState(null);

    const onAssistClick = key => {
        if(key === 'reset-follow') {
            setFollowPref(true);
            return;
        }
    }

    const onCreateDriver = async (driverID, orderID) => {
        try {
            let driver = await User.get(utils, driverID);
            setAnnotations(annotations => {
                let index = annotations.findIndex(annotation => annotation.data.key === `order-${orderID}`);
                if(index < 0) {
                    return annotations;
                }
                return update(annotations, {
                    [index]: {
                        title: {
                            $set: driver.full_name
                        },
                        icon: {
                            $set: {
                                ...annotations[index].icon,
                                path: driver.avatar
                            }
                        }
                    }
                });
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onOrderOptionsClick = order => {
        utils.sheet.show({
            title: `Active ${channel.name} Order Options`,
            message: `What would you like to do with this ${channel.name} Order?`,
            items: [{
                key: 'driver',
                title: 'Follow Driver',
                style: 'default'
            },{
                key: 'view',
                title: `View ${channel.name} Order`,
                style: 'default'
            }]
        }, key => {
            if(key === 'view') {
                Utils.orders.details(utils, order);
                return;
            }
            if(key === 'driver') {
                let annotation = annotations.find(annotation => annotation.data.order_id === order.id);
                if(!annotation || !annotation.data.driver) {
                    utils.alert.show({
                        title: 'Driver Location',
                        message:' It looks like the live location for this driver is not currently available. This can happen if the vehicle is not currently moving, the driver has quit their app, or the order has ended.'
                    })
                    return;
                }
                setFollowPref({
                    type: key,
                    target: order.id
                });
                return;
            }
        })
    }

    const onUpdateDriverLocation = async (index, orderID, location) => {
        try {
            setAnnotations(annotations => {
                if(annotations[index].data.driver) {
                    annotations[index].data.driver.location = location;
                }
                return annotations;
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateLocation = async data => {
        try {
            let { driver_id, location, order_id } = JSON.parse(data);
            setAnnotations(annotations => {
                let index = annotations.findIndex(annotation => annotation.data.key === `order-${order_id}`);
                if(index >= 0) {
                    return update(annotations, {
                        [index]: {
                            location: {
                                $set: {
                                    latitude: location.lat,
                                    longitude: location.long,
                                    heading: location.heading,
                                    speed: location.speed
                                }
                            }
                        }
                    });
                }

                onCreateDriver(driver_id, order_id);
                return annotations.concat([{
                    title: 'Driver Location',
                    subTitle: `Arriving in ${Utils.parseDuration(location.time_to_arrival)}`,
                    supportingTitle: `Order #${order_id}`,
                    data: { key: `order-${order_id}` },
                    location: {
                        latitude: location.lat,
                        longitude: location.long,
                        heading: location.heading,
                        speed: location.speed
                    }
                }]);
            })

        } catch(e) {
            console.error(e.message);
        }
    }

    const getAssistProps = () => {
        return {
            message: `This map shows the routes for all the currently active ${channel.name} orders. Driver locations and directional vehicles are shown when available`,
            ...followPref && followPref.type && {
                items: [{
                    key: 'reset-follow',
                    title: 'Reset Follow Preferences',
                    style: 'default'
                }]
            }
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(orders.length === 0) {
            return (
                Views.entry({
                    title: `No ${channel.name} Orders Found`,
                    subTitle: `There are no Active ${channel.name} Orders available to view`,
                    bottomBorder: false
                })
            )
        }
        return (
            <div style={{
                display: 'block',
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                {orders.map((order, index) => {
                    return (
                        Views.entry({
                            key: order.id,
                            title: `${order.customer.full_name} (${order.id})`,
                            subTitle: `Heading to ${order.status.code === Order.status.to_destination ? order.destination.address : order.host.name}`,
                            icon: {
                                style: Appearance.icons.standard(),
                                path: order.customer.avatar
                            },
                            badge: {
                                text: order.status.code === Order.status.to_destination ? 'To Destination' : 'To Pickup',
                                color: Appearance.colors.blue
                            },
                            bottomBorder: index !== orders.length - 1,
                            onClick: onOrderOptionsClick.bind(this, order)
                        })
                    )
                })}
            </div>
        )
    }

    const getMap = () => {
        if(loading === 'init' || orders.length === 0) {
            return null;
        }
        return (
            <div style={{
                padding: 12
            }}>
                <Map
                utils={utils}
                center={window.userLocation}
                showUserLocation={false}
                isZoomEnabled={true}
                isRotationEnabled={true}
                isScrollEnabled={Utils.isMobile() ? false : true}
                followPref={followPref}
                annotations={annotations}
                overlays={overlays}
                overlayHoverColor={true}
                style={{
                    height: 500
                }}/>
            </div>
        )
    }

    const fetchOrders = async () => {
        try {
            let { orders, paging } = await Request.get(utils, '/orders/',  {
                type: 'all_orders_admin',
                limit: limit,
                category: 'active',
                order_channel: channel.id,
                ...manager
            });

            let targets = orders.map(order => Order.create(order));
            setLoading(false);
            setPaging(paging);
            setOrders(orders => {
                orders.forEach(order => {
                    utils.sockets.off('seeds', `on_order_location_update_${order.id}`, onUpdateLocation);
                });
                targets.forEach(target => {
                    utils.sockets.on('seeds', `on_order_location_update_${target.id}`, onUpdateLocation);
                });
                return targets;
            });
            setOverlays(targets.reduce((array, order) => {
                if(order.polyline) {
                    array.push({
                        id: `order_${order.id}`,
                        data_key: order.id,
                        coordinates: order.polyline
                    });
                }
                return array;
            }, []));
            setAnnotations(targets.reduce((array, order) => {
                return array.concat([{
                    title: `${order.host.name} (${order.id})`,
                    subTitle: order.origin.address,
                    location: order.origin.location,
                    icon: {
                        color: order.status.code === Order.status.to_pickup ? Appearance.colors.blue : Appearance.colors.grey(),
                        type: 'broadcast'
                    },
                    data: {
                        key: `pickup-${order.id}`,
                        target: order.id
                    }
                },{
                    title: `Drop Off Location (${order.id})`,
                    subTitle: order.destination.address,
                    location: order.destination.location,
                    icon: {
                        color: order.status.code === Order.status.to_destination ? Appearance.colors.blue : Appearance.colors.grey(),
                        type: 'broadcast'
                    },
                    data: {
                        key: `drop-off-${order.id}`,
                        target: order.id
                    }
                }]);

            }, []));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the active ${channel.name} Order list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchOrders();
        utils.content.subscribe(panelID, 'orders', {
            onFetch: fetchOrders,
            onUpdate: abstract => {
                setOrders(orders => {
                    return orders.map(order => abstract.compare(order));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
            orders.forEach(order => {
                utils.sockets.off('seeds', `on_order_location_update_${order.id}`, onUpdateLocation);
            });
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Active ${channel.name} Orders`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getMap()}
            {getContent()}
        </Panel>
    )
}

export const NewOrder = ({ channel }, { index, utils }) => {

    const panelID = `newOrder${channel.id}`;
    const [annotations, setAnnotations] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [destination, setDestination] = useState(null);
    const [dropOffDate, setDropOffDate] = useState(moment().add(1, 'days'));
    const [host, setHost] = useState(null);
    const [loading, setLoading] = useState(false);
    const [origin, setOrigin] = useState(null);
    const [overlays, setOverlays] = useState([]);

    const onChangeHost = async host => {
        try {
            // check that host has at least one valid service location
            let location = host.locations && host.locations.length > 0 ? host.locations[0] : null;
            if(!location) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `It looks like ${host.name} does not have any active locations available for ${channel.name} orders`
                });
                return;
            }

            // load host categories and set selected host
            await host.getCategories(utils);
            setHost(host);

            // set host location as order origin
            setOrigin({
                name: location.name,
                address: location.address,
                location: location.location,
                icon: { type: 'broadcast' }
            });

            // update annotations
            onUpdateAnnotations({
                id: 'origin',
                title: location.name,
                subTitle: location.address,
                location: location.location,
                icon: getHostIcon(host)
            });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing this host. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onConfirmOrder = () => {
        if(!host) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please select the host location for this ${channel.name} order`
            })
            return;
        }
        if(!origin) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please select the host location for this ${channel.name} order`
            })
            return;
        }
        if(!destination) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please choose a drop off location for this ${channel.name} order`
            })
            return;
        }
        if(!customer) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please choose a customer for this ${channel.name} order`
            })
            return;
        }
        if(!dropOffDate) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please choose a drop off time and date for this ${channel.name} order`
            })
            return;
        }

        let order = Order.new();
        order.tempID = moment().unix();
        order.user_id = customer.user_id;
        order.channel = channel;
        order.customer = customer;
        order.company = customer.company;
        order.drop_off_date = dropOffDate;
        order.payment_method = customer.payment_methods ? customer.payment_methods[0] : null;
        order.host = host;
        order.origin = origin;
        order.destination = destination;
        order.service = host.service;

        utils.layer.open({
            id: `new-order-confirm-${order.tempID}`,
            abstract: Abstract.create({
                type: 'orders',
                object: order
            }),
            Component: AddEditOrder.bind(this, {
                channel: channel,
                isNewTarget: true,
                onOrderSubmit: () => {
                    setLoading(false);
                    setOverlays([]);
                    setAnnotations([]);
                    setHost(null);
                    setOrigin(null);
                    setDestination(null);
                    setCustomer(null);
                    setDropOffDate(moment());
                }
            })
        });
    }

    const onUpdateAnnotations = async props => {
        setAnnotations(annotations => {
            let targets = annotations.filter(annotation => {
                return annotation.id !== props.id;
            });
            return update(targets, {
                $push: [props]
            });
        });
    }

    const onUpdateRoute = async () => {
        try {
            if(!origin || !destination) {
                return;
            }
            let route = await Valhalla.getRoute(utils, [ origin, destination ]);
            setOverlays([{
                key: 'new-order-overlay',
                coordinates: route.polyline
            }]);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating the route for this ${channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getAssistProps = () => {
        return {
            message: `Start booking your next ${channel.name} order with eCarra. Choose your ${channel.name} Host to get started`
        }
    }

    const getHostIcon = host => {
        if(!host) {
            return null;
        }
        return {
            path: host.image,
            style: {
                width: 30,
                height: 30,
                borderRadius: '50%',
                objectFit: 'cover',
                border: `2px solid white`,
                offset: {
                    offsetTop: -15,
                    offsetLeft: -15
                }
            }
        }
    }

    useEffect(() => {
        onUpdateRoute();
    }, [destination, origin]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Book a ${channel.name} Order`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            <div className={'row p-0 m-0'}>
                <div
                className={'col-12 col-lg-6 col-xl-7' }
                style={{
                    padding: 4
                }}>
                    <Map
                    utils={utils}
                    isZoomEnabled={true}
                    isScrollEnabled={Utils.isMobile() ? false : true}
                    overlays={overlays}
                    annotations={annotations ? Object.values(annotations) : null}
                    style={{
                        height: 410
                    }}/>
                </div>
                <div className={'col-12 col-lg-6 col-xl-5 p-0 pr-lg-1 pl-lg-3 pt-lg-1'}>

                    <span
                    className={'d-block mt-3 mt-lg-0 mb-1'}
                    style={Appearance.textStyles.title()}>{`${channel.name} Host`}</span>
                    <OrderHostLookupField
                    utils={utils}
                    host={host}
                    channel={channel}
                    inline={true}
                    showLocations={true}
                    onChange={onChangeHost}
                    containerStyle={{
                        marginTop: 4
                    }}/>

                    <span
                    className={'d-block mt-3 mb-1'}
                    style={Appearance.textStyles.title()}>{'Pickup'}</span>
                    <AddressLookupField
                    icon={'house'}
                    utils={utils}
                    inline={true}
                    value={origin ? origin.address : null}
                    onChange={place => {
                        setOrigin(place);
                        onUpdateAnnotations({
                            key: 'origin',
                            title: place.name,
                            subTitle: place.address,
                            location: place.location,
                            icon: getHostIcon(host)
                        });
                    }}
                    containerStyle={{
                        marginTop: 4
                    }}/>

                    <span
                    className={'d-block mt-3 mb-1'}
                    style={Appearance.textStyles.title()}>{'Drop-Off'}</span>
                    <AddressLookupField
                    icon={'house'}
                    utils={utils}
                    inline={true}
                    value={destination ? destination.address : null}
                    onChange={place => {
                        setDestination(place);
                        onUpdateAnnotations({
                            key: 'destination',
                            title: place.name,
                            subTitle: place.address,
                            location: place.location,
                            icon: { type: 'broadcast' }
                        });
                    }}
                    containerStyle={{
                        marginTop: 4
                    }}/>

                    <span
                    className={'d-block mt-3 mb-1'}
                    style={Appearance.textStyles.title()}>{'Customer'}</span>
                    <UserLookupField
                    utils={utils}
                    user={customer}
                    inline={true}
                    onChange={user => setCustomer(user)}
                    containerStyle={{
                        marginTop: 4
                    }}/>

                    <span
                    className={'d-block mt-3 mb-1'}
                    style={Appearance.textStyles.title()}>{'Drop Off Date and Time'}</span>
                    <DateDurationPickerField
                    utils={utils}
                    selected={dropOffDate}
                    placeholder={'Date'}
                    onChange={date => setDropOffDate(date)}
                    style={{
                        width: '100%'
                    }} />

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: 15
                    }}>
                        <Button
                        label={'Confirm Order'}
                        type={'large'}
                        onClick={onConfirmOrder}/>
                    </div>
                </div>
            </div>
        </Panel>
    )
}

export const OrdersCalendar = ({ channel }, { index, options, utils }) => {

    const panelID = `ordersCalendar${channel.id}`;
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ calendar_style: Utils.isMobile() ? 'month' : 'week', calendar_offset: 0 });
    const [monthDateFilter, setMonthDateFilter] = useState(moment());
    const [windowState, setWindowState] = useState(null);

    const onAssistClick = key => {
        if(key === 'toggle') {
            setLoading(true);
            setManager('calendar_style', manager.calendar_style === 'week' ? 'month' : 'week');
            return;
        }
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `${channel.name} Calendar`,
                dates: getDates(),
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onChangeCalendarOffset = val => {
        setLoading(true);
        setManager('calendar_offset', manager.calendar_offset + val);
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/orders/', {
                    type: 'calendar',
                    order_channel: channel.id,
                    ...formatResults(utils, props => ({
                        ...props,
                        ...getDates()
                    })),
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: `Your ${channel.name} calendar includes all applicable orders sorted by their drop off date and time`,
            items: [{
                key: 'download',
                title: `Download Orders from ${channel.name}`,
                style: 'default'
            },{
                key: 'toggle',
                title: `Switch to ${manager.calendar_style === 'week' ? 'Month' : 'Week'} View`,
                visible: !Utils.isMobile(),
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        if(manager.calendar_style !== 'week') {
            return [{
                key: 'previous',
                title: 'Previous Month',
                style: 'secondary',
                onClick: onChangeCalendarOffset.bind(this, -1)
            },{
                key: 'next',
                title: 'Next Month',
                style: 'primary',
                onClick: onChangeCalendarOffset.bind(this, 1)
            }]
        }
        return [{
            key: 'previous',
            title: 'Previous Week',
            style: 'secondary',
            onClick: onChangeCalendarOffset.bind(this, -1)
        },{
            key: 'next',
            title: 'Next Week',
            style: 'primary',
            onClick: onChangeCalendarOffset.bind(this, 1)
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        let targets = getEvents();
        return (
            <div style={{
                padding: manager.calendar_style === 'month' ? 0 : 10,
                position: 'relative'
            }}>
                {manager.calendar_style === 'month'
                    ?
                    <>
                    <MobileCalendar
                    utils={utils}
                    events={events}
                    activeDate={moment().startOf('month').add(manager.calendar_offset, 'months').format('YYYY-MM-DD')}
                    showOverflow={windowState === 'fullscreen'}
                    eventFilter={(day, { order }) => day.isSame(order.drop_off_date, 'day')}
                    onClick={order => Utils.orders.details(utils, order)}
                    onDateChange={date => setMonthDateFilter(date)} />
                    {targets.length > 0 && (
                        <div style={{
                            borderTop: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            {targets.map(({ order }, index) => {
                                return (
                                    Views.entry({
                                        key: order.id,
                                        title: order.customer.full_name,
                                        subTitle: order.drop_off_date.format('MMMM Do, YYYY [at] h:mma'),
                                        badge: [{
                                            text: order.status
                                        }, {
                                            text: order.host.name,
                                            color: Appearance.colors.primary()
                                        }],
                                        onClick: Utils.orders.details.bind(this, utils, order),
                                        bottomBorder: index !== targets.length - 1,
                                        icon: {
                                            path: order.customer.avatar,
                                            style: {
                                                ...Appearance.icons.standard(),
                                                alignSelf: 'flex-start'
                                            }
                                        }
                                    })
                                )
                            })}
                        </div>
                    )}
                    </>
                    :
                    <div style={{
                        position: 'relative'
                    }}>
                        <Calendar
                        defaultDate={moment().startOf('week').add(manager.calendar_offset, 'weeks').format('YYYY-MM-DD')}
                        events={events}
                        utils={utils}
                        channel={'orders'}
                        eventType={'orders'}
                        showOverflow={windowState === 'fullscreen'}
                        onClick={order => Utils.orders.details(utils, order)}/>
                        {events.length === 0 && (
                            <NoDataFound message={`No ${channel.name} Orders are booked for this week`}/>
                        )}
                    </div>
                }
            </div>
        )
    }

    const getDates = () => {
        let { calendar_offset, calendar_style } = manager || {};
        return {
            ...calendar_style === 'month' && {
                start_date: moment().startOf('month').add(calendar_offset, 'months').format('YYYY-MM-DD'),
                end_date: moment().endOf('month').add(calendar_offset, 'months').format('YYYY-MM-DD')
            },
            ...calendar_style === 'week' && {
                start_date: moment().startOf('week').add(calendar_offset, 'weeks').format('YYYY-MM-DD'),
                end_date: moment().endOf('week').add(calendar_offset, 'weeks').format('YYYY-MM-DD')
            }
        }
    }

    const getEvents = () => {
        if(manager.calendar_style === 'month') {
            return events.filter(({ order }) => {
                return order && order.drop_off_date.isSame(monthDateFilter, 'day');
            })
        }
        let startDate = moment().startOf('week').add(manager.calendar_offset, 'weeks');
        let endDate = moment().endOf('week').add(manager.calendar_offset, 'weeks');
        return events.filter(({ order }) => {
            return order && order.drop_off_date >= startDate && order.drop_off_date <= endDate;
        })
    }

    const getTitle = () => {
        if(manager.calendar_style === 'month') {
            return `${channel.name} Orders for ${moment().startOf('month').add(manager.calendar_offset, 'months').format('MMMM YYYY')}`
        }
        return `${channel.name} Calendar`;
    }

    const fetchOrders = async download => {
        try {
            let { events } = await Request.get(utils, '/orders/', {
                type: 'calendar',
                order_channel: channel.id,
                ...formatResults(utils, props => ({
                    ...props,
                    ...getDates()
                }))
            });

            setLoading(false);
            setEvents(events.map(entry => {
                let order = Order.create(entry);
                return {
                    allDay: true,
                    color: Appearance.colors.transparent,
                    id: order.id,
                    order: order,
                    start: order.drop_off_date.format('YYYY-MM-DD HH:mm:ss'),
                    stick: true,
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the calendar. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOrders();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'orders', {
            onFetch: fetchOrders,
            onRemove: abstract => {
                setEvents(events => {
                    return events.filter(evt => evt.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setEvents(events => {
                    // filter out cancelled and rejected events
                    if(abstract.object.status && [Order.status.rejected, Order.status.cancelled].includes(abstract.object.status.code)) {
                        return events.filter(evt => {
                            return evt.id !== abstract.getID();
                        })
                    }
                    // check if event exists locally
                    let index = events.findIndex(evt => {
                        return evt.id === abstract.getID();
                    });
                    // update event if found
                    if(index >= 0) {
                        return update(events, {
                            [index]: {
                                order: {
                                    $set: abstract.object
                                },
                                start: {
                                    $set: abstract.object.drop_off_date.format('YYYY-MM-DD HH:mm:ss')
                                }
                            }
                        })
                    }
                    // add event to events array
                    events.push({
                        allDay: true,
                        color: Appearance.colors.transparent,
                        id: abstract.getID(),
                        order: abstract.object,
                        start: abstract.object.drop_off_date.format('YYYY-MM-DD HH:mm:ss'),
                        stick: true
                    });
                    return events;
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getTitle()}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: Utils.isMobile(),
            onWindowStateChange: state => setWindowState(state),
            buttons: getButtons(),
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrderCategories = ({ channel }, { index, utils }) => {

    const panelID = `orderCategories${channel.id}`;
    const limit = 5;

    const [categories, setCategories] = useState([]);
    const [hosts, setHosts] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedHost, setSelectedHost] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `Categories for ${channel.name}`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onCategoryClick = category => {
        utils.layer.open({
            id: `order-category-details-${category.id}`,
            abstract: Abstract.create({
                type: 'orderCategories',
                object: category
            }),
            Component: OrderCategoryDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchOrderCategories(utils, {
                    host_id: selectedHost ? selectedHost.id : null,
                    order_channel: channel.id,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewCategory = () => {
        let category = Order.Category.new();
        category.channel = channel;
        utils.layer.open({
            id: 'new-order-category',
            abstract: Abstract.create({
                type: 'orderCategories',
                object: category
            }),
            Component: AddEditOrderCategory.bind(this, {
                isNewTarget: true,
                channel: channel
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all the categories for ${channel.name} that are available during a ${channel.name} booking. A ${channel.name} category is a collection of ${channel.name} options that are available during booking`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Categories'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(categories.length === 0) {
            return (
                Views.entry({
                    key: 'none-found',
                    title: `No ${selectedHost ? selectedHost.name : channel.name} Categories Found`,
                    subTitle: `There are no ${selectedHost ? selectedHost.name : channel.name} categories available to view`,
                    borderBottom: false
                })
            )
        }
        return categories.map((category, index) => {
            return (
                Views.entry({
                    key: category.id,
                    title: `${category.host.name} ${category.title}`,
                    subTitle: category.options && category.options.length > 0 ? `${category.options.length} ${channel.name} ${category.options.length === 1 ? 'Option' : 'Options'}` : `No ${channel.name} options have been added`,
                    badge: !category.active && {
                        text: 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    bottomBorder: index !== categories.length - 1,
                    onClick: onCategoryClick.bind(this, category)
                })
            )
        });
    }

    const getHostSelector = () => {
        return (
            <select
            className={`custom-select ${utils.user.get().theme}`}
            style={{
                height: 35
            }}
            onChange={evt => {
                let index = Utils.attributeForKey.select(evt, 'index');
                setSelectedHost(parseInt(index) >= 0 ? hosts[parseInt(index)] : null);
            }}>
                <option>{'All Order Hosts'}</option>
                {hosts.map((host, index) => {
                    return (
                        <option key={index} index={index}>{host.name}</option>
                    )
                })}
            </select>
        )
    }

    const fetchCategories = async () => {
        try {
            let { categories, paging } = await fetchOrderCategories(utils, {
                limit: limit,
                host_id: selectedHost ? selectedHost.id : null,
                order_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCategories(categories);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the order categories list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchHosts = async () => {
        try {
            let { hosts } = await fetchOrderHosts(utils, { order_channel: channel.id });
            setHosts(hosts);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the order hosts list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setManager('offset', 0);
    }, [selectedHost]);

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchCategories();
    }, [manager, selectedHost]);

    useEffect(() => {
        fetchHosts();
        utils.content.subscribe(panelID, ['orderCategories', 'orderOptions'], {
            onFetch: fetchCategories,
            onRemove: abstract => {
                if(abstract.type === 'orderCategories') {
                    setCategories(categories => {
                        return categories.filter(category => category.id !== abstract.getID());
                    });
                }
            },
            onUpdate: abstract => {
                if(abstract.type === 'orderCategories') {
                    setCategories(categories => {
                        return categories.map(category => abstract.compare(category));
                    });
                }
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Categories for ${channel.name}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Category',
                style: 'default',
                onClick: onNewCategory
            }],
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            },
            search: {
                placeholder: 'Search by category name..',
                rightContent: getHostSelector(),
                onChange: onSearchTextChange
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrderHostsList = ({ category, channel }, { index, utils }) => {

    const panelID = `ordersHostsList${channel.id}`;
    const limit = 5;

    const [hosts, setHosts] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: getInfo().title,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchOrderHosts(utils, {
                    order_channel: channel.id,
                    ...manager,
                    ...props
                });

                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    const onHostClick = host => {
        utils.layer.open({
            id: `order-host-details-${host.id}`,
            abstract: Abstract.create({
                type: 'orderHosts',
                object: host
            }),
            Component: OrderHostDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onNewHost = () => {
        let host = Order.Host.new()
        host.channel = channel;
        utils.layer.open({
            id: 'new-order-host',
            abstract: Abstract.create({
                type: 'orderHosts',
                object: host
            }),
            Component: AddEditOrderHost.bind(this, {
                isNewTarget: true,
                channel: channel
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all ${category} ${channel.name} hosts in the system. You can view more information about a host from ${channel.name} by clicking on them`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : `${channel.name} Hosts`}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(hosts.length === 0) {
            return (
                Views.entry({
                    title: `No ${channel.name} Hosts Found`,
                    subTitle: `'There are no ${category === 'all' ? '' : category} ${channel.name} hosts available to view'`,
                    borderBottom: false
                })
            )
        }
        return hosts.map((host, index) => {
            return (
                Views.entry({
                    key: host.id,
                    title: host.name,
                    subTitle: host.description,
                    badge: !host.active && {
                        text: 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    bottomBorder: index !== hosts.length - 1,
                    icon: {
                        path: host.image
                    },
                    onClick: onHostClick.bind(this, host)
                })
            )
        })
    }

    const getInfo = () => {
        switch(category) {
            case 'all':
            return {
                title: `Hosts for ${channel.name}`,
                message: ''
            }

            case 'new':
            return {
                title: `Newly Created Hosts for ${channel.name}`,
                message: ''
            }

            default:
            return {
                title: `Orders from ${channel.name}`,
                message: ''
            }
        }
    }

    const fetchHosts = async () => {
        try {
            let { paging, hosts } = await fetchOrderHosts(utils, {
                limit: limit,
                order_channel: channel.id,
                ...manager
            });
            setLoading(false);
            setPaging(paging);
            setHosts(hosts);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue laoding the order hosts. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchHosts();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'orderHosts', {
            onFetch: fetchHosts,
            onRemove: abstract => {
                setHosts(hosts => {
                    return hosts.filter(host => host.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setHosts(hosts => {
                    return hosts.map(host => abstract.compare(host));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getInfo().title}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Host',
                style: 'default',
                onClick: onNewHost
            }],
            search: {
                placeholder: 'Search by id or name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrdersList = ({ category, channel }, { index, utils }) => {

    const panelID = `${category}Orders${channel.id}`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [orders, setOrders] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: getInfo().title,
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/orders/', {
                    type: 'all_orders_admin',
                    order_channel: channel.id,
                    category: category,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onPendingClick = order => {
        if(utils.user.get().level > User.level.admin) {
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'approve',
                title: `Approve ${order.channel.name} Order`,
                style: 'default'
            },{
                key: 'decline',
                title: `Decline ${order.channel.name} Order`,
                style: 'destructive'
            }]
        }, key => {
            if(key === 'approve' || key === 'decline') {
                onSetApprovalStatus(order, key === 'approve');
                return;
            }
        })
    }

    const onSetApprovalStatus = (order, approve) => {
        utils.alert.show({
            title: `${approve ? 'Approve' : 'Decline'} Reservation`,
            message: `Are you sure that you want to ${approve ? 'approve' : 'decline'} this ${order.channel.name} order? This will notify the customer via email and push notification with your decision.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: approve ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${approve ? 'Approve' : 'Decline'}`,
                style: approve ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetApprovalStatusConfirm(approve);
                    return;
                }
            }
        });
    }

    const onSetApprovalStatusConfirm = async (order, approve, confirm) => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/order/', {
                type: 'set_approval',
                status: approve ? Order.status.approved : Order.status.rejected,
                order_id: order.id,
                approve_unpaid_orders: confirm
            });

            setLoading(false);
            order.status = Order.formatStatus(approve ? Order.status.approved : Order.status.rejected);
            utils.content.update({
                type: 'orders',
                object: order
            });
            utils.alert.show({
                title: 'All Done!',
                message: `${order.channel.name} order #${order.id} has been ${approve ? 'approved' : 'declined'}`
            });

        } catch(e) {
            setLoading(false);
            if(e.code === 402) {
                utils.alert.show({
                    title: 'Oops!',
                    message: e.message,
                    buttons: [{
                        key: 'approve',
                        title: 'Approve',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Approve',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'approve') {
                            onSetApprovalStatusConfirm(order, key, true);
                            return;
                        }
                    }
                })
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this ${order.channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all ${category} ${channel.name} orders in the system. You can view more information about an order from ${channel.name} by clicking on it`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : `Orders from ${channel.name}`}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(orders.length === 0) {
            return (
                Views.entry({
                    title: `No Orders Found`,
                    subTitle: `There are no ${category === 'all' || category === 'today' ? '' : Utils.ucFirst(category)} orders from ${channel.name} available to view`,
                    borderBottom: false
                })
            )
        }
        return orders.map((order, index) => {
            return (
                Views.entry({
                    key: order.id,
                    title: order.customer.full_name,
                    subTitle: `${order.host.name}: ${Utils.formatDate(order.drop_off_date)}`,
                    icon: {
                        path: order.customer.avatar
                    },
                    badge: [{
                        text: order.status.text,
                        color: order.status.color,
                        onClick: !order.status || order.status.code === Order.status.pending ? onPendingClick.bind(this, order) : null
                    }],
                    bottomBorder: index !== orders.length - 1,
                    onClick: Utils.orders.details.bind(this, utils, order)
                })
            )
        });
    }

    const getInfo = () => {
        switch(category) {
            case 'today':
            return {
                title: `${channel.name}: Today's Orders`,
                message: ''
            }
            case 'pending':
            return {
                title: `${channel.name}: Pending Orders`,
                message: ''
            }
            case 'all':
            return {
                title: `${channel.name}: All Orders`,
                message: ''
            }
            case 'new':
            return {
                title: `${channel.name}: Newly Created Orders`,
                message: ''
            }
            case 'cancelled':
            return {
                title: `${channel.name}: Cancelled Orders`,
                message: ''
            }
            default:
            return {
                title: `${channel.name}: Orders`,
                message: ''
            }
        }
    }

    const fetchOrders = async download => {
        try {
            let { orders, paging } = await Request.get(utils, '/orders/', {
                type: 'all_orders_admin',
                order_channel: channel.id,
                limit: limit,
                category: category,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setOrders(orders.map(order => Order.create(order)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${category} orders list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOrders();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'orders', {
            onFetch: fetchOrders,
            onUpdate: abstract => {
                setOrders(orders => {
                    let index = orders.findIndex(order => {
                        return order.id === abstract.getID();
                    });
                    if(index >= 0) {
                        orders[index] = abstract.object;
                    } else {
                        orders.push(abstract.object);
                    }

                    // filter out non-valid targets from current list of orders
                    return orders.filter(order => {
                        switch(category) {
                            case 'today':
                            return moment().isSame(moment(order.drop_off_date), 'day') && (order.status && ![Order.status.rejected, Order.status.cancelled].includes(order.status.code));

                            case 'pending':
                            return !order.status || order.status.code === Order.status.pending;

                            case 'cancelled':
                            return order.status && order.status.code === Order.status.cancelled;

                            default:
                            return true;
                        }
                    });
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getInfo().title}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by order id or customer name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrderOptions = ({ channel }, { index, utils }) => {

    const panelID = `onOrderOptionsClick${channel.id}`;
    const limit = 5;

    const [hosts, setHosts] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [options, setOptions] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selectedHost, setSelectedHost] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `Options for ${channel.name}`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchOrderOptions(utils, {
                    host_id: selectedHost ? selectedHost.id : null,
                    order_channel: channel.id,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewOption = () => {
        let option = Order.Option.new();
        option.channel = channel;
        utils.layer.open({
            id: 'new-order-option',
            abstract: Abstract.create({
                type: 'orderOptions',
                object: option
            }),
            Component: AddEditOrderOption.bind(this, {
                isNewTarget: true,
                channel: channel
            })
        });
    }

    const onOptionClick = option => {
        utils.layer.open({
            id: `order-option-details-${option.id}`,
            abstract: Abstract.create({
                type: 'orderOptions',
                object: option
            }),
            Component: OrderOptionDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all the options for ${channel.name} that are available during a ${channel.name} booking. A ${channel.name} option normally holds a name, description, cost, and customization options.`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : `Options for ${channel.name}`}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(options.length === 0) {
            return (
                Views.entry({
                    title: `No ${selectedHost ? selectedHost.name : channel.name} Options Found`,
                    subTitle: `There are no ${selectedHost ? selectedHost.name : channel.name} options available to view`,
                    borderBottom: false
                })
            )
        }
        return options.map((option, index) => {
            return (
                Views.entry({
                    key: option.id,
                    title: option.name,
                    subTitle: option.category ? option.category.title : 'Unknown Category',
                    icon: {
                        path: option.image || 'images/order-category-icon.png'
                    },
                    badge: [{
                        text: option.category ? option.category.host : null,
                        color: Appearance.colors.primary()
                    },{
                        text: option.featured ? 'Featured' : null,
                        color: Appearance.colors.primary()
                    },{
                        text: option.category && option.category.id === -1 ? 'Uncategorized' : null,
                        color: Appearance.colors.red
                    },{
                        text: Utils.toCurrency(option.cost),
                        color: Appearance.colors.grey()
                    }],
                    bottomBorder: index !== options.length - 1,
                    onClick: onOptionClick.bind(this, option)
                })
            )
        });
    }

    const getHostSelector = () => {
        return (
            <select
            className={`custom-select ${utils.user.get().theme}`}
            style={{
                height: 35
            }}
            onChange={evt => {
                let index = Utils.attributeForKey.select(evt, 'index');
                setSelectedHost(parseInt(index) >= 0 ? hosts[parseInt(index)] : null);
            }}>
                <option>{'All Order Hosts'}</option>
                {hosts.map((host, index) => {
                    return (
                        <option key={index} index={index}>{host.name}</option>
                    )
                })}
            </select>
        )
    }

    const fetchHosts = async () => {
        try {
            let { hosts } = await fetchOrderHosts(utils, { order_channel: channel.id });
            setHosts(hosts);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the order hosts list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchOptions = async () => {
        try {
            let { options, paging } = await fetchOrderOptions(utils, {
                limit: limit,
                host_id: selectedHost ? selectedHost.id : null,
                order_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setOptions(options);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the order options list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setManager('offset', 0);
    }, [selectedHost]);

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOptions();
    }, [manager, selectedHost]);

    useEffect(() => {
        fetchHosts();
        utils.content.subscribe(panelID, 'orderOptions', {
            onFetch: fetchOptions,
            onRemove: abstract => {
                setOptions(options => update(options, {
                    $apply: options => options.filter(o => o.id !== abstract.getID())
                }));
            },
            onUpdate: abstract => {
                setOptions(opts => {
                    return opts.map(opt => abstract.compare(opt));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Options for ${channel.name}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: `New ${channel.name} Option`,
                style: 'default',
                onClick: onNewOption
            }],
            search: {
                placeholder: 'Search by category name..',
                rightContent: getHostSelector(),
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrdersPerformanceOverview = ({ index, utils }) => {

    const panelID = 'ordersPerformanceOverview';
    const limit = 5;

    const [dates, setDates] = useState({ start: moment().startOf('month'), end: moment().endOf('month') });
    const [loading, setLoading] = useLoading();
    const [orders, setOrders] = useState(null);
    const [payments, setPayments] = useState(null);
    const [users, setUsers] = useState(null);

    const getAssistProps = () => {
        return {
            message: 'This overview shows the total orders placed, the payment totals, and the new user accounts that have been created from the beginning of the month to today'
        }
    }

    const getItems = () => {
        return [{
            key: 'orders',
            title: 'Orders',
            placeholder: '0',
            value: orders ? orders.total : null,
            chart: orders
        },{
            key: 'payments',
            title: 'Payments',
            placeholder: '$0.00',
            value: payments ? Utils.toCurrency(payments.total) : null,
            chart: payments
        },{
            key: 'users',
            title: 'New User Accounts',
            placeholder: '0',
            value: users ? users.total : null,
            chart: users
        }];
    }

    const fetchOrders = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            setLoading(true);
            utils.fetching.set(panelID, true);
            let { data } = await Request.get(utils, '/orders/',  {
                end_date: moment(dates.end).utc().unix(),
                limit: limit,
                start_date: moment(dates.start).utc().unix(),
                type: 'monthly_orders_overview'
            });

            utils.fetching.set(panelID, false);
            setLoading(false);
            setOrders({
                total: data.reduce((t, d) => t += d.total, 0),
                labels: data.map(d => d.date),
                data: data.map(d => d.total)
            });
        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the orders overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchOverview = () => {
        fetchOrders();
        fetchPayments();
        fetchUsers();
    }

    const fetchPayments = async () => {
        try {
            let { data } = await Request.get(utils, '/payments/',  {
                end_date: moment(dates.end).utc().unix(),
                limit: limit,
                start_date: moment(dates.start).utc().unix(),
                type: 'monthly_payments_overview'
            });
            setLoading(false);
            setPayments({
                total: data.reduce((t, d) => t += d.total, 0),
                labels: data.map(d => d.date),
                data: data.map(d => d.total)
            });
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payments overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchUsers = async () => {
        try {
            let { data } = await Request.get(utils, '/users/',  {
                end_date: moment(dates.end).utc().unix(),
                limit: limit,
                start_date: moment(dates.start).utc().unix(),
                type: 'monthly_users_overview'
            });
            setLoading(false);
            setUsers({
                total: data.reduce((t, d) => t += d.total, 0),
                labels: data.map(d => d.date),
                data: data.map(d => d.total)
            });
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the users overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(dates) {
            fetchOverview();
        }
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Performance Overview'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-2 pt-1 pb-2 p-lg-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        <span style={Appearance.textStyles.title()}>{`${Utils.formatDate(dates.start, true)} to ${Utils.formatDate(dates.end, true)}}`}</span>
                        <span style={Appearance.textStyles.subTitle()}>{`${moment(dates.end).diff(moment(dates.start), 'days')} full ${moment(dates.end).diff(moment(dates.start), 'days') === 1 ? 'day' : 'days'} included in overview`}</span>
                    </div>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={dates.start}
                        selectedEndDate={dates.end}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }}
                        onStartDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    start: date
                                }
                            })
                        }}
                        onEndDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    end: date
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
            <div className={'row p-1 m-0'}>
                {getItems().map(item => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-md-4 p-2 m-0 text-center'}>
                            <div
                            className={'px-0 py-2'}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                height: 185,
                                position: 'relative'
                            }}>
                                <div
                                className={'pr-2'}
                                style={{
                                    height: 125
                                }}>
                                    {!item.chart || item.chart.data.length > 3
                                        ?
                                        <NoDataFound
                                        title={item.title}
                                        message={'Insufficient Data'} />
                                        :
                                        <Line
                                        width={500}
                                        height={125}
                                        data={{
                                            labels: item.chart.labels,
                                            datasets: [{
                                                borderColor: '#6CBF66',
                                                fill: false,
                                                pointRadius: 0,
                                                data: item.chart.data
                                            }]
                                        }}
                                        options={{
                                            title: {
                                                display: false
                                            },
                                            legend: {
                                                display: false
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        color: Appearance.colors.transparent,
                                                        display: false
                                                    },
                                                    ticks: {
                                                        display: false
                                                    }
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        color: Appearance.colors.transparent,
                                                        display: false
                                                    },
                                                    ticks: {
                                                        display: false
                                                    }
                                                }]
                                            }
                                        }} />
                                    }
                                </div>
                                {item.chart && item.chart.data.length > 3 && (
                                    <>
                                    <span className={'d-block mt-2'}
                                    style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                    <span className={'d-block'}
                                    style={Appearance.textStyles.subTitle()}>{item.title}</span>
                                    </>
                                )}
                            </div>
                            <div
                            id={`overview-${item.key}-none-found`}
                            className={'text-center w-100'}
                            style={{
                                display: 'none',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 40
                            }}>
                              <img
                              src={'images/no-data-found.png'}
                              className={'no-data-found'}
                              style={{
                                  height: '100%',
                                  maxWidth: 75,
                                  objectFit: 'contain'
                              }} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </Panel>
    )
}

export const OrderServices = ({ channel }, { index, utils }) => {

    const panelID = `orderServices${channel.id}`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [services, setServices] = useState([]);

    const onAssistClick = key => {
        if(key === 'new') {
            onNewService();
            return;
        }
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title:`Servics for ${channel.name}`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchOrderServices(utils, {
                    order_channel: channel.id,
                    ...manager,
                    ...props
                });

                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewService = () => {
        let service = Service.new();
        service.channel = 'orders';
        utils.layer.open({
            id: 'new-service',
            abstract: Abstract.create({
                type: 'services',
                object: service
            }),
            Component: AddEditService.bind(this, {
                isNewTarget: true,
                channel: channel
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const onServiceClick = service => {
        utils.layer.open({
            id: `service-details-${service.id}`,
            abstract: Abstract.create({
                type: 'services',
                object: service
            }),
            Component: ServiceDetails
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all active and inactive services for ${channel.name} in the system. A ${channel.name} service is a set of guidelines for movements made during a ${channel.name} order when a customer has requested an order.`,
            items: [{
                key: 'download',
                title: 'Download Services',
                style: 'default'
            }]
        }
    }

    const getBadge = service => {
        if(service.default_for_channel) {
            return {
                text: 'Default',
                color: Appearance.colors.primary()
            }
        }
        if(service.active === false) {
            return {
                text: 'Not Active',
                color: Appearance.colors.grey()
            }
        }
        return null;
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(services.length === 0) {
            return (
                Views.entry({
                    title: 'No Services Found',
                    subTitle: `There are no ${channel.name} services available to view`,
                    borderBottom: false
                })
            )
        }
        return services.map((service, index) => (
            Views.entry({
                key: index,
                title: service.name,
                subTitle: service.information,
                badge: getBadge(service),
                bottomBorder: index !== services.length - 1,
                onClick: onServiceClick.bind(this, service)
            })
        ));
    }

    const fetchServices = async () => {
        try {
            let { paging, services } = await fetchOrderServices(utils, {
                limit: limit,
                order_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setServices(services);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchServices();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'services', {
            onFetch: fetchServices,
            onRemove: abstract => {
                setServices(services => {
                    return services.filter(service => service.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setServices(services => {
                    return services.map(service => abstract.compare(service));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Services for ${channel.name}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Service',
                style: 'default',
                onClick: onNewService
            }],
            search: {
                placeholder: 'Search by service name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrderServiceActivity = ({ channel }, { methods, index, utils }) => {

    const chart = useRef(null);
    const panelID = `ordersServiceActivity${channel.id}`;

    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ end_date: moment(), start_date: moment().subtract(12, 'months') });
    const [service, setService] = useState(null);
    const [services, setServices] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `${channel.name} Service Activity`,
                chart: chart,
                dates: manager,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onChangeService = evt => {
        setService(services.find(service => {
            return service.id === parseInt(Utils.attributeForKey.select(evt, 'id'))
        }));
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/orders/', {
                    type: 'services_activity',
                    id: service ? service.id : services[0].id,
                    order_channel: channel.id,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    const getAssistProps = () => {
        return {
            message: 'The graph shows the overall service usage sorted by the month they were used. The left side shows the amount a service was used and the bottom shows the month they were used. The graph shows the last 12 months',
            items: [{
                key: 'download',
                title: 'Download Activity',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div style={{
                display: 'block',
                height: 350,
                padding: 10,
                position: 'relative'
            }}>
                {!chartData || chartData.data.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of service usage are needed for the graph'} />
                    :
                    <Line
                    ref={chart}
                    width={500}
                    height={100}
                    data={{
                        labels: chartData.labels,
                        datasets: [{
                            data: chartData.data,
                            borderColor: Appearance.colors.primary(),
                            fill: true,
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                            pointRadius: 5,
                            pointBorderWidth: 2,
                            pointBackgroundColor: 'white'
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    let val = parseInt(tooltipItem.yLabel);
                                    return `${val} ${val === 1 ? 'Booking' : 'Bookings'}`;
                                }
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 12
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value, index, values) => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
        )
    }

    const getSearchField = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div
            className={'row m-0'}
            style={{
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-0 pt-0 pb-2 p-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    value={service ? service.name : 'Choose a Service'}
                    onChange={onChangeService}
                    style={{
                        width: Utils.isMobile() ? '100%' : 150,
                        opacity: services && services.length > 0 ? 1 : 0
                    }}>
                        <option disabled={true}>{'Choose a Service'}</option>
                        {services.map((service, index) => (
                            <option key={index} id={service.id}>{service.name}</option>
                        ))}
                    </select>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }} />
                    </div>
                </div>
            </div>
        )
    }

    const fetchActivity = async () => {
        try {
            if(!service && services.length === 0){
                return;
            }
            let { results } = await Request.get(utils, '/orders/', {
                type: 'services_activity',
                id: service ? service.id : services[0].id,
                order_channel: channel.id,
                ...formatResults(utils)
            });

            setLoading(false);
            setChartData({
                data: results.map(entry => entry.total),
                labels: results.map(entry => moment.utc(entry.date).local().format('MMM YYYY'))
            });

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services activity breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchServices = async () => {
        try {
            let { services } = await fetchOrderServices(utils, { order_channel: channel.id });
            setServices(services);
            setService(services && services.length > 0 ? services[0] : null);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services list. ${e.message ||' An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchActivity();
    }, [manager, service]);

    useEffect(() => {
        fetchServices();
        utils.content.subscribe(panelID, ['orders', 'services'], {
            onFetch: fetchActivity,
            onUpdate: abstract => {
                if(abstract.type !== 'services') {
                    return;
                }
                setServices(services => {
                    return services.map(service => abstract.compare(service));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${channel.name} Service Activity`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getSearchField()}
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditOrder = ({ channel, isNewTarget, onOrderSubmit }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new-order-confirm-${abstract.object.tempID}` : `edit-order-${abstract.getID()}`;
    const tempContent = useRef(null);

    const [companies, setCompanies] = useState([]);
    const [costBreakdown, setCostBreakdown] = useState(null);
    const [dropDown, setDropDown] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState({});
    const [overrides, setOverrides] = useState([]);

    const onManageOrderItems = () => {
        utils.layer.open({
            id: `order-host-catalog-${order.host.id}`,
            abstract: Abstract.create({
                type: 'orderHosts',
                object: order.host
            }),
            Component: OrderHostCatalog.bind(this, {
                host: order.host,
                channel: channel,
                orderOptions: order.options,
                onChange: options => {
                    let edits = abstract.object.set({ options: options });
                    setOrder(edits);
                }
            })
        });
    }

    const onManageStops = () => {

        let stops = order.stops || {};
        let locations = order.stops && order.stops.locations ? order.stops.locations : [];
        setDropDown({
            title: 'Stops Along the Way',
            message: `We're here to help if you need to make some stops during your ${channel.name} order. Depending on your needs, we can make these stops in the order that you like or we can try to visit all stops in the fastest and more efficient way possible.`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => {
                    onUpdateTarget({
                        stops: {
                            ...stops,
                            locations: locations
                        }
                    });
                }
            }],
            content: (
                <>
                {locations.length > 1 && (
                    <BoolToggle
                    enabled={'Fastest'}
                    disabled={'In Order'}
                    isEnabled={stops && stops.optimized}
                    style={{
                        width: '100%',
                        marginBottom: 12
                    }}
                    onChange={enabled => {
                        stops = {
                            ...stops,
                            optimized: enabled
                        }
                    }} />
                )}
                <MultipleAddressLookupField
                utils={utils}
                label={'Stops Along the Way'}
                locations={locations}
                autoCorrect={false}
                spellCheck={false}
                placeholder={'Search for a place or address...'}
                onChange={results => {
                    locations = results;
                }}
                style={{
                    width: '100%'
                }}/>
                </>
            )
        })
    }

    const onSelectCreditsMethod = () => {
        let credits = order.credits;
        setDropDown({
            title: 'Credits',
            message: 'Credits allows a customer to book a ride without using their credit or debit card. Credits will be used to cover as much of the order as possible and any remaining costs will be charged to the payment method on file with the order.',
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ credits: credits })
            }],
            content: (
                <CreditsManager
                utils={utils}
                maxAmount={costBreakdown ? costBreakdown.physicalCost : null}
                defaultAmount={order.credits ? order.credits.amount : null}
                defaultCardID={order.credits ? order.credits.card_id : null}
                user={order.customer}
                onChange={result => {
                    credits = result;
                }}/>
            )
        })
    }

    const onSelectedPaymentMethod = () => {

        let method = order.payment_method;
        let target = Abstract.create({
            type: 'users',
            object: order.customer
        });
        setDropDown({
            title: 'Payment Method',
            message: `The payment method for this ${channel.name} order is used to process the final cost after discounts, promotions, and credits. The customer's default payment method will be used if a specific method is not chosen. Click on a payment method to assign it to this order`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ payment_method: method })
            }],
            content: (
                <PaymentMethodManager
                utils={utils}
                target={target}
                defaultMethod={order.payment_method}
                onChange={result => {
                    method = result;
                }} />
            )
        })
    }

    const onSelectPromoCode = () => {
        if(!order.promo_code) {
            onSelectPromoCodeConfirm();
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'change',
                title: 'Change',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'change') {
                onSelectPromoCodeConfirm();
                return;
            }
            if(key === 'remove') {
                onUpdateTarget({ promo_code: null });
                return;
            }
        });
    }

    const onSelectPromoCodeConfirm = () => {

        let code = order.promo_code;
        setDropDown({
            title: 'Promo Code',
            message: `Adding a promo code to a ${channel.name} order can discount or cover the final cost of the order. Only one promo code may be used per order and promo codes do not apply towards driver tips`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ promo_code: code })
            }],
            content: (
                <PromoCodeLookupField
                userID={order.customer.user_id}
                defaultPromoCode={order.promo_code ? order.promo_code.code : null}
                utils={utils}
                autoCorrect={false}
                spellCheck={false}
                onChange={result => {
                    code = result;
                }}/>
            )
        })
    }

    const onSubmit = async () => {
        if(!costBreakdown || (costBreakdown.total > 0 && !order.payment_method)) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like there is a cost for this order but no payment method has been selected. Please choose a payment method for this Order or use Credits to cover the remaining cost'
            });
            return;
        }
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The ${channel.name} order for ${abstract.object.drop_off_date.format('MMMM Do, YYYY [at] h:mma')} has been ${isNewTarget ? 'submitted' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this ${channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateCostBreakdown = async () => {
        let fetchID = `${layerID}:onUpdateCostBreakdown`;
        try {
            if(!order.service) {
                return;
            }
            if(utils.fetching.get(fetchID) === true) {
                return;
            }
            utils.fetching.set(fetchID, true);
            let { breakdown } = await abstract.object.getCostBreakdown(utils);

            utils.fetching.set(fetchID, false);
            setCostBreakdown(breakdown);
            setOrder({ ...abstract.object.edits });

        } catch(e) {
            utils.fetching.set(fetchID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating the estimated cost for this ${channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdatePaymentMethod = async () => {
        try {
            let method = await abstract.object.translatePaymentMethod(utils);
            let edits = abstract.object.set({ payment_method: method });
            setOrder(edits);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setOrder(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(!order || !order.host) {
            return (
                <div style={{
                    padding: 20
                }}>
                    {Views.loader()}
                </div>
            )
        }
        return (
            <>
            <LayerItem title={'Cost Estimate'}>
                {getCostBreakdownComponents()}
            </LayerItem>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
            </>
        )
    }

    const getCostBreakdownComponents = () => {
        if(!costBreakdown) {
            return (
                <div style={{
                    padding: 20
                }}>
                    {Views.loader()}
                </div>
            )
        }
        return costBreakdown.lineItems.map((item, index) => {
            return (
                <div
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '5px 10px 5px 10px',
                    borderBottom: index !== costBreakdown.lineItems.length - 1 ? (`1px solid ${Appearance.colors.divider()}`) : null
                }}>
                    <span style={Appearance.textStyles.key()}>{item.title}</span>
                    <span style={Appearance.textStyles.value()}>{item.message}</span>
                </div>
            )
        })
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'host',
                title: `${channel.name} Host`,
                description: `Changing the ${channel.name} order host will return new ordering options and will result in the estimated and final cost for this ${channel.name} order to change from the cost presented during booking`,
                value: order.host,
                component: 'order_host_lookup',
                onChange: host => onUpdateTarget({ host: host }),
                props: {
                    channel: channel
                }
            },{
                key: 'customer',
                title: 'Customer',
                description: `The customer selected for this ${channel.name} order should be the individual who is receiving the order.`,
                value: order.customer,
                component: 'user_lookup',
                invalid: getRequirements.customer(),
                onChange: user => onUpdateTarget({ customer: user })
            },{
                key: 'company',
                required: false,
                title: 'Company',
                description: `Assigning a company to this ${channel.name} order will apply any discounts available for that company and will allow this ${channel.name} order to appear in Seeds for the selected company's administrators`,
                value: order.company,
                component: 'list',
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: company => {
                    if(company && order.customer.company && company.id !== order.customer.company.id) {
                        utils.alert.show({
                            title: 'Company',
                            message: `${order.customer.full_name} is part of ${order.customer.company.name}. ${channel.name} orders booked for ${order.customer.first_name} are automatically tied to ${order.customer.company.name}. If you need to book this ${channel.name} order without attaching ${order.customer.company.name} then you'll need to remove ${order.customer.first_name} from ${order.customer.company.name}.`
                        });
                        return;
                    }
                    onUpdateTarget({ company: company });
                }
            },{
                key: 'options',
                title: 'Delivery Items',
                description: `These will be the items that the driver will deliver for this ${channel.name} order`,
                value: order.options && order.options.length > 0 ? Utils.oxfordImplode(order.options.map(opt => opt.name)) : 'Click to make changes...',
                onEditClick: onManageOrderItems,
                invalid: getRequirements.orderItems()
            },{
                key: 'message',
                required: false,
                title: 'Message for Driver',
                description: `This message is shown to the driver when they begin the customer's ${channel.name} order`,
                value: order.requests ? order.requests.message : null,
                component: 'textview',
                onChange: text => {
                    onUpdateTarget({
                        requests: update(order.requests, {
                            message: {
                                $set: text
                            }
                        })
                    });
                }
            }]
        },{
            key: 'locations_and_time',
            title: 'Locations and Time',
            items: [{
                key: 'drop_off_date',
                title: 'Date and Time',
                description: `This will be the date and time that the driver will arrive with the ${channel.name} order.`,
                value: order.drop_off_date,
                component: 'date_time_picker',
                invalid: getRequirements.dropOffDate(),
                onChange: date => onUpdateTarget({ drop_off_date: date }),
                props: {
                    filterDateValue: moment().add(order.service.min_booking_time || 0, 'seconds'),
                    filterDate: date => {
                        return moment(date) >= moment().add(order.service.min_booking_time || 0, 'seconds')
                    }
                }
            },{
                key: 'origin',
                required: utils.user.get().level <= User.level.admin,
                visible: utils.user.get().level <= User.level.admin,
                title: 'Host Location',
                description: isNewTarget ? `This should be the location where the ${channel.name} order will be picked up by the driver.` : 'Changing the pickup location will result in the estimated and final cost for this order to change from the cost presented during booking.',
                value: order.host,
                component: 'order_host_lookup',
                props: {
                    channel: channel,
                    showLocations: true,
                    showLocationText: true
                },
                onChange: host => {
                    onUpdateTarget({
                        host: host,
                        origin: host.locations[0]
                    });
                }
            },{
                key: 'stops',
                required: false,
                title: 'Stops Along the Way',
                description: `We're here to help if you need to make some stops during your ${channel.name} order. Depending on your needs, we can make these stops in the order that you like or we can try to visit all stops in the fastest and more efficient way possible.`,
                ...getStopsOverview(),
                onEditClick: onManageStops
            },{
                key: 'destination',
                title: 'Drop-Off',
                description: isNewTarget ? `This should be the location where the ${channel.name} order will be dropped off by the driver.` : 'Changing the drop-off location will result in the estimated and final cost for this order to change from the cost presented during booking.',
                value: order.destination,
                component: 'address_lookup',
                onChange: place => {
                    onUpdateTarget({
                        destination: place && {
                            id: place.address.id,
                            name: place.address.name,
                            address: Utils.formatAddress(place.address),
                            location: place.location
                        }
                    });
                }
            }]
        },{
            key: 'payments',
            title: 'Payments',
            items: [{
                key: 'method',
                title: 'Method',
                description: `This will be the payment method used to process the final cost of this ${channel.name} order.`,
                value: order.payment_method ? order.payment_method.type() : 'Not Selected',
                onEditClick: onSelectedPaymentMethod
            },{
                key: 'credits',
                required: false,
                title: 'Credits',
                description: `We're here to help if you need to make some stops during your ${channel.name} order. Depending on your needs, we can make these stops in the order that you like or we can try to visit all stops in the fastest and more efficient way possible. Specifying stops is usefull when you need parts of your order dropped off at specific locations.`,
                value: order.credits ? 'Added' : 'Not Added',
                invalid: getRequirements.credits(),
                onEditClick: onSelectCreditsMethod
            },{
                key: 'promo_code',
                required: false,
                title: 'Promo Code',
                description: `Attaching a promo code to your ${order.channel} order can help reduce the final billable cost.`,
                value: order.promo_code ? order.promo_code.getSummary() : null,
                onEditClick: onSelectPromoCode
            }]
        }];
    }

    const getOverrideButtons = val => {
        if(utils.user.get().level > User.level.admin) {
            return null;
        }
        return {
            buttons: [{
                key: 'override',
                title: 'Admin Override',
                style: 'destructive'
            },{
                key: 'dismiss',
                title: 'Dismiss',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'override') {
                    setOverrides([
                        ...overrides,
                        val
                    ]);
                }
            }
        }
    }

    const getRequirements = {
        customer: () => {
            // verify that company selected for the ride matches the on-file company for the customer if applicable
            if(overrides.includes('customer-company')) {
                return null;
            }
            let { companies } = order.host || {};
            if(companies && companies.length > 0) {
                if(!order.customer.company || !order.host.companies.find(c => c.id === order.customer.company.id)) {
                    return {
                        title: order.host.name,
                        message: `The ${order.channel.name} order host "${order.host.name}" has chosen to provide their services to specific companies. They are currently providing services for ${Utils.oxfordImplode(order.host.companies.map(company => company.name))}. Please choose an eligable company customer to continue booking with this Host.`,
                        ...getOverrideButtons('customer-company')
                    }
                }
            }
            return null;
        },
        dropOffLocation: () => {
            // verify that distance is greater than the minimum required distance
            if(order.distance && order.service.min_distance && order.distance.estimate < order.service.min_distance) {
                if(!overrides.includes('service-min-distance')) {
                    return {
                        title: order.host.name,
                        message: `This service requires a minimum distance ${Utils.distanceConversion(order.service.min_distance)} from pickup to drop-off`,
                        ...getOverrideButtons('service-min-distance')
                    }
                }
            }
            // verify that distance is less than the maximum allowed distance
            if(order.distance && order.service.billing === 'fixed' && order.distance.estimate > order.service.max_distance) {
                if(!overrides.includes('service-fixed-max-distance')) {
                    return {
                        title: order.host.name,
                        message: `This service allows a maximum distance of ${order.service.max_distance} ${order.service.max_distance === 1 ? 'mile' : 'miles'} and your trip is estimated at ${parseFloat(order.distance.estimate).toFixed(2)} ${parseFloat(order.distance.estimate).toFixed(2) === 1.00 ? 'mile' : 'miles'}. Please choose a different drop-off Location`,
                        ...getOverrideButtons('service-fixed-max-distance')
                    }
                }
            }
            return null;
        },
        dropOffDate: () => {
            // verify that drop off date adhrers to the required booking lead time
            if(order.service && order.service.min_booking_time && moment().add(order.service.min_booking_time, 'seconds') > moment(order.drop_off_date)) {
                if(!overrides.includes('service-min-booking-time')) {
                    return {
                        title: order.host.name,
                        message: `This service requires a minimum of ${Utils.parseDuration(order.service.min_booking_time)} of notice before the drop off time and date`,
                        ...getOverrideButtons('service-min-booking-time')
                    }
                }
            }
            return null;
        },
        credits: () => {
            // prevent credits from being used on services that are marked as hourly
            if(order.credits && order.credits.amount && order.service.billing === 'hourly') {
                if(!overrides.includes('credits-hourly')) {
                    return {
                        title: order.host.name,
                        message: `This service is an hourly based service and does not support credits. Please remove the credits from this ${channel.name} order or choose a different service`,
                        ...getOverrideButtons('credits-hourly')
                    }
                }
            }
            return null;
        },
        orderItems: () => {
            // require at least one item before accepting the order
            if(!order.options || order.options.length === 0) {
                return {
                    title: order.host.name,
                    message: `It looks like you haven't selected any items to order. Please choose at least one item to order before submitting this ${channel.name} order.`,
                    value: true,
                    text: 'No Items Selected'
                }
            }
            return null;
        }
    }

    const getStopsOverview = () => {
        let { locations } = order.stops || {};
        if(!locations || locations.length === 0) {
            return { value: 'Click to make changes...' };
        }
        return {
            value: `${locations.length} ${locations.length === 1 ? 'stop' : 'stops'} selected`,
            content: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {locations.map((location, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: location.name || 'Name Not Available',
                                subTitle: Utils.formatAddress(location),
                                hideIcon: true,
                                bottomBorder: index !== locations.length - 1
                            })
                        )
                    })}
                </div>
            )
        }
    }

    const setupTarget = async () => {
        try {
            let edits = abstract.object.open();
            setOrder(edits);

            if(utils.user.get().level <= User.level.admin) {
                let { companies } = await fetchCompanies(utils);
                setCompanies(companies);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading setting up this ${channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useState(() => {
        onUpdatePaymentMethod();
    }, [order.customer]);

    useEffect(() => {
        onUpdateCostBreakdown();
    }, [order.options, order.origin, order.stops, order.destination, order.stops, order.promo_code, order.credits, order.service, order.company]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New ${channel.name} Order`:`${abstract.getTitle()} Details`}
        index={index}
        utils={utils}
        dropDown={dropDown}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setOrder(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            {getContent()}
        </Layer>
    )
}

export const AddEditOrderCategory = ({ channel, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-order-category': `edit-order-category-${abstract.getID()}`;
    const [category, setCategory] = useState({});
    const [hosts, setHosts] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [opts, setOpts] = useState([]);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" category has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setCategory(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!category) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: `The title for a ${channel.name} category will describe the options listed within. An example would be a category called "Entrees" that contains options like "Turkey and Cheese Sandwich". `,
                value: category.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'description',
                title: 'Description',
                description: `The description for a ${channel.name} category is optional but can be used to describe the options listed within. An example would be a category called "Entrees" with a description that reads "These are the most loved entrees that we offer".`,
                value: category.description,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            },{
                key: 'host',
                title: 'Host',
                description: `The ${channel.name} host is the owner of the ${channel.name} category. A host is typically a restaurant or company that provides delivery related services or has item that they need delivered by a third party.`,
                value: category.host,
                component: 'order_host_lookup',
                onChange: host => onUpdateTarget({ host: host }),
                props: {
                    channel: channel
                }
            },{
                key: 'options',
                required: false,
                visible: opts.length > 0,
                title: 'Options',
                description: `A ${channel.name} option represents an item that can be purchased from a Host. An exmaple item would be "Turkey and Cheese Sandwich". Items can be added or removed at any time but each ${channel.name} category requires at least one item to become visible to the customer.`,
                component: 'multiple_list',
                value: category.options && category.options.map(opt => ({
                    id: opt.id,
                    title: opt.name
                })),
                items: opts.map(opt => ({
                    id: opt.id,
                    title: opt.name
                })),
                onChange: items => {
                    onUpdateTarget({
                        options: items.map(item => {
                            return opts.find(opt => {
                                return opt.id === item.id;
                            })
                        })
                    });
                }
            }]
        }];
    }

    const fetchOptions = async () => {
        try {
            if(!category.host) {
                return;
            }
            let { options } = await fetchOrderOptions(utils, {
                host_id: category.host.id,
                order_channel: channel.id
            });
            setOpts(options);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the order options list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTargets = async () => {
        try {
            let edits = abstract.object.open();
            setCategory(edits);

            let { hosts } = await fetchOrderHosts(utils, { order_channel: channel.id });
            setHosts(hosts);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the company, order hosts, and order services. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchOptions();
    }, [category.host])

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New ${channel.name} Category` : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setCategory(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditOrderHost = ({ channel, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-order-host': `edit-order-host-${abstract.getID()}`;
    const tempContent = useRef(null);

    const [companies, setCompanies] = useState([]);
    const [dropDown, setDropDown] = useState(null);
    const [host, setHost] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [services, setServices] = useState([]);

    const onLocationClick = entry => {
        utils.sheet.show({
            items: [{
                key: 'radius',
                title: 'Change Radius',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'radius') {
                onSelectLocationRadius(entry);
                return;
            }
            if(key === 'remove') {
                onRemoveLocation(entry);
                return;
            }
        })
    }

    const onManageBusinessHours = () => {
        let hours = null;
        setDropDown({
            title: 'Business Hours',
            message: `The business hours for a ${channel.name} host will be shown when customers place an order or search for somewhere to order from. The hours should represent the times throughout the day when orders are accepted.`,
            content: (
                <BusinessHoursPicker
                utils={utils}
                times={host.parameters && host.parameters.operations ? host.parameters.operations.hours : null}
                onChange={result => {
                    hours = result;
                }}/>
            ),
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => {
                    onUpdateTarget({
                        parameters: update(host.parameters || {}, {
                            operations: {
                                $set: {
                                    ...host.parameters && host.parameters.operations,
                                    ...hours && {
                                        hours: hours.map(entry => ({
                                            day: entry.day,
                                            start: moment(entry.start, 'HH:mm:ss').format('HH:mm:ss'),
                                            end: moment(entry.end, 'HH:mm:ss').format('HH:mm:ss')
                                        }))
                                    }
                                }
                            }
                        })
                    });
                }
            }]
        });
    }

    const onRemoveLocation = entry => {
        utils.alert.show({
            title: 'Remove Location',
            message: 'Are you sure that you want to remove this location?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setLocations(location => locations.filter(location => {
                        return location.id !== entry.id;
                    }));
                    return;
                }
            }
        })
    }

    const onSelectLocationRadius = entry => {
        let miles = entry.radius;
        utils.alert.show({
            title: 'Location Radius',
            message: 'We use the radius to draw a circle around your service area so we can accept or prevent orders and rides from undesired areas. The radius for a location is measured in miles.',
            component: (
                <div style={{
                    padding: 15,
                    width: '100%'
                }}>
                    <NumberStepper
                    min={0}
                    startingValue={entry.radius}
                    onChange={value => miles = value}/>
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'done') {
                    setLocations(locations => locations.map(location => {
                        if(location.id === entry.id) {
                            location.radius = miles;
                        }
                        return location;
                    }))
                    return;
                }
            }
        })
    }

    const onSetupServiceLocations = targets => {
        if(!targets || targets.length === 0) {
            return;
        }
        setLocations(locations => {
            targets.forEach(entry => {
                let match = locations.find(prev_entry => {
                    return prev_entry.location.latitude === entry.location.latitude && prev_entry.location.longitude === entry.location.longitude;
                });
                if(!match) {
                    locations.push(entry);
                }
            })
            return locations;
        })
    }

    const onServiceAreaClick = index => {
        let location = locations[index];
        utils.alert.show({
            title: 'Change Radius',
            message: 'The radius is used to measure the size of your operating area. Please input a radius value in miles below.',
            textFields: [{
                key: 'radius',
                placeholder: '25 Miles',
                value: `${locations[index].radius} ${locations[index].radius === 1 ? 'Mile' : 'Miles'}`
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: ({ radius }) => {
                if(!radius) {
                    return;
                }
                let value = parseFloat(radius.toString().replace(/[^\d.-]/g,''));
                if(value >= 0) {
                    setLocations(locations => update(locations, {
                        [index]: {
                            radius: {
                                $set: value
                            }
                        }
                    }))
                }
            }
        })
    }

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget, {
                service_locations: locations.map(entry => ({
                    ...entry,
                    location: {
                        lat: entry.location.latitude,
                        long: entry.location.longitude
                    }
                }))
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" order host has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this host. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setHost(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getBusinessHoursOverview = () => {
        let hours = host.parameters && host.parameters.operations && host.parameters.operations.hours || [];
        return `${hours.length} ${hours.length === 1 ? 'Day' : 'Days'} of Operation`
    }

    const getFields = () => {
        if(!host) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: `The name for a ${channel.name} host will be shown when customers place an order or search for somewhere to order from. An example for a host could be a restaurant called "Acai SuperFoods".`,
                value: host.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'description',
                title: 'Description',
                description: `The description for a ${channel.name} host should describe the type of services that are offered. An example for a ${channel.name} host named "Acai SuperFoods" could be "Serving healthy and delicious snacks since 2018". `,
                value: host.description,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            }]
        },{
            key: 'branding',
            title: 'Branding',
            items: [{
                key: 'image',
                title: 'Image',
                description: `The image for a ${channel.name} host is shown to customers when selecting the host as their delivery choice. The image should ideally be the host's logo or an image that represents the services that the host offers.`,
                value: host.image,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ image: image }),
                props: {
                    requirements: {
                        dimensions: {
                            width: 750,
                            height: 750
                        }
                    }
                }
            },{
                key: 'cover_image',
                title: 'Cover Image',
                description: `The cover image for a ${channel.name} host is shown to customers when selecting the host as their devliery choice. The image should either be a photo of a popular item, promotional graphic, or a photo of their physical location.`,
                value: host.cover_image,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ cover_image: image }),
                props: {
                    requirements: {
                        dimensions: {
                            width: 1000,
                            height: 750
                        }
                    }
                }
            }]
        },{
            key: 'about',
            title: `About ${host.name || 'This Host'}`,
            items: [{
                key: 'website',
                title: 'Website',
                description: `The website for a ${channel.name} host will be shown when customers place an order or search for somewhere to order from. The website should be somewhere that the customer can learn more about the host.`,
                value: host.parameters ? host.parameters.website : null,
                component: 'textfield',
                onChange: text => {
                    onUpdateTarget({
                        parameters: update(host.parameters || {}, {
                            website: {
                                $set: text
                            }
                        })
                    });
                }
            },{
                key: 'email_address',
                title: 'Email Address',
                description: `The email address for a ${channel.name} host will be used when our platform needs to contact the order host. The email address should be an address that the host monitors regularly.`,
                value: host.parameters ? host.parameters.email_address : null,
                component: 'textfield',
                onChange: text => {
                    onUpdateTarget({
                        parameters: update(host.parameters || {}, {
                            email_address: {
                                $set: text
                            }
                        })
                    });
                }
            },{
                key: 'business_hours',
                title: 'Business Hours',
                description: `The business hours for a ${channel.name} host will be shown when customers place an order or search for somewhere to order from. The hours should represent the times throughout the day when orders are accepted.`,
                value: getBusinessHoursOverview(),
                onEditClick: onManageBusinessHours
            },{
                key: 'service',
                title: 'Service',
                description: `The service for a ${channel.name} host dicates how ${channel.name} orders are processed. Services typically revolve around billing by the mile or by the minute.`,
                value: host.service,
                component: 'list',
                items: services.map(service => ({
                    id: service.id,
                    title: service.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        service: item && services.find(service => {
                            return service.id === item.id;
                        })
                    });
                }
            },{
                key: 'companies',
                required: false,
                title: 'Companies',
                description: `A ${channel.name} host can be restricted to customers from specific companies. ${channel.name} hosts are shown to all customers within an acceptable distance by default.`,
                value: host.companies && host.companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                component: 'multiple_list',
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: items => {
                    onUpdateTarget({
                        companies: items.map(item => {
                            return companies.find(company => {
                                return company.id === item.id
                            });
                        })
                    });
                }
            },{
                key: 'service_areas',
                title: 'Service Areas',
                description: `These locations represent your areas of operation. When an order is submitted we'll check to see if the pickup and drop-off locations fall within one of the service areas for this host. If no services areas are found we'll check the areas for the order's devliery service.`,
                value: locations,
                component: 'multiple_address_lookup',
                onChange: items => {
                    if(!items) {
                        setLocations([]);
                        return;
                    }
                    setLocations(items.map(entry => ({
                        id: `${Utils.randomString()}_${moment().unix()}`,
                        radius: 25,
                        ...entry
                    })));
                },
                props: {
                    onRenderResult: (location, index, locations, onRemoveClick) => (
                        Views.entry({
                            key: index,
                            title: location.name || 'Name Not Available',
                            subTitle: Utils.formatAddress(location),
                            hideIcon: true,
                            bottomBorder: index !== locations.length - 1,
                            badge: {
                                color: Appearance.colors.primary(),
                                text: `${location.radius} ${location.radius === 1 ? 'Mile' : 'Miles'}`
                            },
                            onClick: onServiceAreaClick.bind(this, index),
                            rightContent: (
                                <img
                                className={'text-button'}
                                src={'images/red-x-icon.png'}
                                onClick={onRemoveClick}
                                style={{
                                    width: 20,
                                    height: 20,
                                    marginLeft: 12
                                }} />
                            )
                        })
                    )
                }
            },{
                key: 'locations',
                title: 'Operating Locations',
                description: `An operating location for a ${channel.name} host should represent where the driver needs to go to receive an order. The location should be as accurate as possible to ensure quick delivery.`,
                value: host.locations,
                component: 'multiple_address_lookup',
                onChange: items => onUpdateTarget({ locations: items })
            }]
        },{
            key: 'direct_payments',
            visible: isNewTarget === false && utils.user.get().level <= User.level.admin,
            title: 'Direct Payments',
            items: [{
                key: 'user',
                required: false,
                title: 'Account Holder',
                description: `The account holder is tasked with managing the direct payments account. This means the account holder is required to provide a bank account or a debit card, registered in their name, for external account transfers.`,
                value: host.direct_payments && host.direct_payments.user,
                component: 'user_lookup',
                onChange: user => {
                    onUpdateTarget({ 
                        direct_payments: update(host.direct_payments || {}, {
                            account: {
                                $set: host.direct_payments && host.direct_payments.account || { enabled: true }
                            },
                            user: {
                                $set: user
                            }
                        }) 
                    });
                }
            },{
                key: 'enabled',
                required: false,
                visible: host.direct_payments && host.direct_payments.account ? true : false,
                title: 'Enabled',
                description: `Enabling direct payments will allow this order host to automatically received revenue generated from their orders.`,
                value: host.direct_payments && host.direct_payments.account && host.direct_payments.account.enabled,
                component: 'bool_list',
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(host.direct_payments, {
                            account: {
                                enabled: {
                                    $set: val
                                }
                            }
                        }) 
                    });
                }
            }]
        }];
    }

    const fetchLocations = async () => {
        try {
            if(isNewTarget === true) {
                return;
            }
            let { locations } = await Request.get(utils, '/orders/', {
                type: 'host_service_locations',
                id: abstract.getID()
            });
            onSetupServiceLocations(locations.map(entry => ({
                ...entry,
                location: {
                    latitude: entry.location.lat,
                    longitude: entry.location.long
                }
            })));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retriving the locations radius restrictions for this host. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTargets = async () => {
        try {
            let edits = abstract.object.open();
            setHost(edits);

            let { companies } = await fetchCompanies(utils);
            setCompanies(companies);

            let { services } = await fetchOrderServices(utils, { order_channel: channel.id });
            setServices(services);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the company, order hosts, and order services. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchLocations();
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New ${channel.name} Host` : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        dropDown={dropDown}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setHost(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditOrderOption = ({ channel, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-order-option': `edit-order-option-${abstract.getID()}`;
    const [categories, setCategories] = useState([]);
    const [hosts, setHosts] = useState();
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState({});

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" option has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this option. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setOption(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!option) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: `The name for a ${channel.name} option will be shown to the customer when selecting this item. An example would be a option could be "Turkey and Cheese Sandwich".`,
                value: option.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'description',
                title: 'Description',
                description: `The description for a ${channel.name} option is ahown to the customer when ordering and should describe what the item comes with. An example would be an option named "Turkey and Cheese Sandwich" with a description saying "Two pieces of rye bread, cheddar cheese, and cajun smoked turkey breast".`,
                value: option.description,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            },{
                key: 'cost',
                title: 'Cost',
                description: `The cost for a ${channel.name} option will be shown to the customer when selecting this item. This cost should represent the base cost of the item without any customizations or additions.`,
                value: option.cost,
                component: 'textfield',
                onChange: text => onUpdateTarget({ cost: text })
            },{
                key: 'host',
                title: 'Host',
                description: `The host is the owner of the ${channel.name} category. A host is typically a restaurant or company that provides delivery related services or has item that they need delivered by a third party.`,
                value: option.host,
                component: 'order_host_lookup',
                onChange: host => onUpdateTarget({ host: host }),
                props: {
                    channel: channel,
                    showLocations: false
                }
            },{
                key: 'category',
                visible: option.host ? true : false,
                title: 'Category',
                description: `The category for a ${channel.name} option will determine when and how the option will be shown  An example would be a category named "Entrees" that contains an option called "Turkey and Cheese Sandwich".`,
                value: option.category,
                component: 'list',
                items: categories.map(category => ({
                    id: category.id,
                    title: category.title
                })),
                onChange: item => {
                    onUpdateTarget({
                        category: item && categories.find(category => {
                            return category.id === item.id;
                        })
                    });
                }
            },{
                key: 'featured',
                title: 'Show as Featured Item',
                description: `You can show special or popular items at the top of the list by selecting it as a featured ${channel.name} option. Featured items will be shown as a large entry at the top of the list of ${channel.name} entries.`,
                value: option.featured,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ featured: val })
            },{
                key: 'image',
                title: 'Image',
                description: `The image for a ${channel.name} option is shown to customers when selecting the option for their delivery. The image should be an accurate photo of the item offered to the customer.`,
                value: option.image,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ image: image })
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setOption(edits);
    }

    const setupTargets = async () => {
        try {
            let { hosts } = await fetchOrderHosts(utils, { order_channel: channel.id });
            setHosts(hosts);

            let { categories } = await fetchOrderCategories(utils, {
                host_id: option.host ? option.host.id : null,
                order_channel: channel.id
            });
            setCategories(categories);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the order hosts and order categories. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setupTargets();
    }, [option.host]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New ${channel.name} Option` : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setOption(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const OrderHostCatalog = ({ channel, onOrderOptionsClick, onChange, orderOptions = [] }, { abstract, index, options, utils }) => {

    const layerID = `order-host-catalog-${abstract.getID()}`;
    const [categories, setCategories] = useState([]);
    const [estimateState, setEstimateState] = useState(null);
    const [featuredItems, setFeaturedItems] = useState([]);
    const [host, setHost] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [opts, setOpts] = useState(orderOptions);

    const onHostClick = () => {
        utils.layer.open({
            id: `order-host-details-${host.id}`,
            abstract: Abstract.create({
                type: 'orderHosts',
                object: host
            }),
            Component: OrderHostDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onOptionItemClick = props => {
        const { option, isEditing } = props;
        if(isEditing === false) {
            onShowItemDetails(props);
            return;
        }
        utils.sheet.show({
            title: option.name,
            message: option.description,
            items: [{
                key: 'edit',
                title: 'Update Item',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove Item',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'edit') {
                onShowItemDetails(props);
                return;
            }
            if(key === 'remove') {
                onRemoveOption(option);
                return;
            }
        });
    }

    const onRemoveOption = opt => {
        utils.alert.show({
            title: 'Remove Item',
            message: `Are you sure that you want to remove "${opt.name}" from your ${channel.name} order?`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setOpts(opts => {
                        let index = opts.findIndex(o => o.copy_id === opt.copy_id);
                        if(index < 0) {
                            return opts;
                        }
                        return update(opts, {
                            $splice: [[ index, 1 ]]
                        });
                    });
                }
            }
        });
    }

    const onRequestClose = () => {
        utils.alert.show({
            title: 'Close Order',
            message: `Are you sure that you want to close this ${channel.name} order? Any unsaved progress will be lost.`,
            buttons: [{
                key: 'confirm',
                title: 'Close',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Close',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setLayerState('close');
                    return;
                }
            }
        });
    }

    const onSetExpandedState = index => {
        setCategories(categories => update(categories, {
            [index]: {
                expanded: {
                    $set: categories[index].expanded ? false : true
                }
            }
        }));
    }

    const onShowItemDetails = ({ option, isEditing }) => {
        let opt = isEditing ? option : Order.Option.copy(option);
        utils.layer.open({
            id: `order-host-catalog-option-${option.id}`,
            abstract: Abstract.create({
                type: 'orderOptions',
                object: option
            }),
            Component: OrderHostCatalogOption.bind(this, {
                option: option,
                channel: channel,
                isEditing: isEditing,
                onAddToOrder: ({ options }) => {
                    opt.options = options;
                    setOpts(opts => update(opts, {
                        $push: [opt]
                    }));
                },
                onUpdateOrder: ({ options }) => {
                    opt.options = options;
                    setOpts(opts => {
                        let index = opts.findIndex(o => o.copy_id === opt.copy_id);
                        if(index < 0) {
                            return opts;
                        }
                        return update(opts, {
                            [index]: {
                                $set: opt
                            }
                        })
                    });
                }
            })
        });
    }

    const onSubmit = async () => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(opts);
        }
    }

    const getBusinessHoursOverview = () => {
        let hours = host.getBusinessHours() || [];
        let today = hours.find(entry => entry.day === moment().day());
        return today ? `Available from ${today.formatted}` : `Closed on ${moment().format('dddd')}`
    }

    const getItems = (items, editing = false) => {
        return items.map((item, index) => {
            let cost = item.getCustomizedCost();
            let customizations = item.getCustomizations();
            return (
                <div
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {Views.entry({
                        title: item.name,
                        subTitle: item.description || 'No Description Provided',
                        badge: editing === false && {
                            color: item.options && item.options.length > 0 ? Appearance.colors.secondary() : Appearance.colors.primary(),
                            text: item.options && item.options.length > 0 ? `Starting at ${Utils.toCurrency(cost)}` : Utils.toCurrency(cost)
                        },
                        icon: {
                            path: item.image,
                            style: {
                                height: 40,
                                minWidth: 40,
                                borderRadius: 10
                            }
                        },
                        bottomBorder: editing === false && index !== items.length - 1,
                        onClick: onOptionItemClick.bind(this, { option: item, isEditing: editing })
                    })}
                    {editing && (
                        <div style={{
                            paddingTop: 4,
                            paddingBottom: 4,
                            borderTop: `1px solid ${Appearance.colors.divider()}`,
                            borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-between',
                                padding: '6px 12px 6px 12px',
                                borderBottom: customizations.length > 0 ? `1px solid ${Appearance.colors.divider()}` : null
                            }}>
                                <span style={Appearance.textStyles.key()}>{'Base Price'}</span>
                                <span style={Appearance.textStyles.value()}>{Utils.toCurrency(cost)}</span>
                            </div>
                            {customizations.map((customization, index, customizations) => {
                                return (
                                    <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        padding: '6px 12px 6px 12px',
                                        borderBottom: index !== customizations.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                                    }}>
                                        <span style={Appearance.textStyles.key()}>{customization.title}</span>
                                        <span style={{
                                            ...Appearance.textStyles.value(),
                                            maxWidth: '50%'
                                        }}>{customization.value || Utils.toCurrency(customization.cost)}</span>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            )
        })
    }

    const getEstimatedTotal = () => {
        return Utils.toCurrency(opts.reduce((total, option) => {
            return total += option.getCustomizedCost();
        }, 0));
    }

    const fetchCategories = async () => {
        try {
            let categories = await abstract.object.getCategories(utils);
            setCategories(categories);
            setFeaturedItems(categories.reduce((array, category) => {
                let opts = category.options.filter(opt => opt.featured && opt.image);
                return array.concat(opts);
            }, []));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading ${channel.name} service categories. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchCategories();
        utils.content.subscribe(layerID, 'orderHosts', {
            onUpdate: next => {
                setHost(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Order from ${abstract.getTitle()}`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'close',
            text: 'Close',
            color: 'grey',
            onClick: onRequestClose
        },{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onSubmit
        }]}>
            {opts.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    width: '100%',
                    overflow: 'hidden',
                    marginBottom: 25
                }}>
                    <div
                    className={'cursor-pointer'}
                    onClick={() => setEstimateState(estimateState === 'expanded' ? null : 'expanded')}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        padding: '8px 12px 8px 12px',
                        backgroundColor: Appearance.colors.secondary()
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            color: 'white'
                        }}>{`${opts.length} ${opts.length === 1 ? 'Item' : 'Items'} Added`}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: 'white'
                        }}>{`Estimated Total: ${getEstimatedTotal()}`}</span>
                    </div>
                    {estimateState === 'expanded' && getItems(opts, true)}
                </div>
            )}

            <LayerItem
            title={`${channel.name} Order Host`}
            subTitle={getBusinessHoursOverview()}>
                {Views.entry({
                    title: host.name,
                    subTitle: host.description,
                    icon: {
                        path: host.image
                    },
                    bottomBorder: false,
                    onClick: onHostClick
                })}
            </LayerItem>

            {featuredItems.length > 0 && (
                <LayerItem title={'Featured Items'}>
                    {getItems(featuredItems)}
                </LayerItem>
            )}

            {categories.map((category, index) => {
                return category.options.length > 0 && (
                    <LayerItem
                    key={index}
                    collapsed={false}
                    title={category.title}
                    subTitle={`${category.options.length} ${category.options.length === 1 ? 'Item' : 'Items'} Available`}
                    lastItem={index === categories.length - 1}>
                        {getItems(category.options)}
                    </LayerItem>
                )
            })}
        </Layer>
    )
}

export const OrderHostCatalogOption = ({ channel, onAddToOrder, onUpdateOrder, isEditing }, { abstract, index, options, utils }) => {

    const layerID = `order-host-catalog-option-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [item, setItem] = useState(abstract.object);

    const onAddItemsToOrder = () => {
        let required = item.options.find(opt => {
            return opt.required && !opt.options.find(o => o.selected);
        });
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please make a selection for "${required.title}" before adding "${item.name}" to your ${channel.name} order`
            });
            return;
        }
        abstract.object = item;
        onAddToOrder(abstract.object);
        setLayerState('close');
    }

    const onCheckboxChange = (index, i, checked) => {
        setItem(item => update(item, {
            options: {
                [index]: {
                    options: {
                        [i]: {
                            selected: {
                                $set: checked
                            }
                        }
                    }
                }
            }
        }))
    }

    const onUpdateOrderItems = () => {

        let required = item.options.find(opt => {
            return opt.required && !opt.options.find(o => o.selected);
        })
        if(required) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please make a selection for "${required.title}" before adding "${item.name}" to your ${channel.name} order`
            });
            return;
        }
        abstract.object = item;
        onUpdateOrder(abstract.object);
        setLayerState('close');
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            lastItem: true,
            items: [{
                key: 'name',
                title: 'Name',
                value: item.name
            },{
                key: 'cost',
                title: 'Cost',
                value: item.options && item.options.length > 0 ? `Starting at ${Utils.toCurrency(item.cost)}` : Utils.toCurrency(item.cost)
            },{
                key: 'description',
                title: 'Description',
                value: item.description
            }]
        }];
    }

    const getItemComponent = (item, index) => {
        switch(item.type) {
            case 'list':
            return getListItem(item, index);

            case 'text-area':
            return getTextAreaItem(item, index);

            default:
            return null;
        }
    }

    const getListItem = (item, index) => {
        let selected = item.options.reduce((total, item) => item.selected ? (total += 1) : total, 0);
        let max = item.restrictions && item.restrictions.max ? item.restrictions.max : null;
        return (
            <div
            key={index}
            style={Appearance.styles.unstyledPanel()}>
                {item.options.map((o, i) => {
                    switch(o.type) {
                        case 'checkbox':
                        return (
                            <div
                            key={i}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '8px 12px 8px 12px',
                                borderBottom: i !== item.options.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                    enabled={o.selected || selected < max}
                                    checked={o.selected}
                                    onChange={onCheckboxChange.bind(this, index, i)} />
                                    <span style={{
                                        ...Appearance.textStyles.key(),
                                        marginLeft: 8
                                    }}>{o.title}</span>
                                </div>

                                {o.cost > 0 && (
                                    <AltBadge content={{
                                        text: Utils.toCurrency(o.cost),
                                        color: Appearance.colors.green
                                    }} />
                                )}
                            </div>
                        )

                        case 'amount':
                        return (
                            <div
                            key={i}
                            style={{
                                padding: '8px 12px 8px 12px',
                                borderBottom: i !== item.options.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                            }}>
                                <span style={Appearance.textStyles.key()}>{o.title}</span>
                            </div>
                        )

                        default:
                        return null;
                    }
                })}
            </div>
        )
    }

    const getTextAreaItem = (item, index) => {
        return (
            <TextView
            value={item.value}
            onChange={text => {
                setItem(item => update(item, {
                    options: {
                        [index]: {
                            value: {
                                $set: text
                            }
                        }
                    }
                }))
            }}/>
        )
    }

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle()}
        options={{
            ...options,
            sizing: 'small',
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: isEditing ? 'Update Item' : 'Add To Order',
            color: 'primary',
            onClick: isEditing ? onUpdateOrderItems : onAddItemsToOrder
        }]}>
            {item.image && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 25
                }}>
                    <img
                    src={item.image}
                    style={{
                        width: '100%',
                        height: 250,
                        objectFit: 'cover'
                    }}/>
                </div>
            )}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {item.options.filter(opt => {
                return opt.title ? true : false;
            }).map((opt, index, opts) => {
                return (
                    <LayerItem
                    key={index}
                    title={opt.title}
                    required={opt.required}
                    lastItem={index === opts.length - 1}
                    shouldStyle={false}
                    style={{
                        marginTop: index === 0 ? 25 : 0
                    }}>
                        {getItemComponent(opt, index)}
                    </LayerItem>
                )
            })}
        </Layer>
    )
}

export const OrderDetails = ({ abstract, index, options, utils }) => {

    const layerID = `order-details-${abstract.getID()}`;
    const limit = 5;
    const orderRef = useRef(abstract.object);

    const [annotations, setAnnotations] = useState([]);
    const [breakdown, setBreakdown] = useState(null);
    const [dropDown, setDropDown] = useState(null);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState(abstract.object);
    const [overlays, setOverlays] = useState({});
    const [paging, setPaging] = useState(null);
    const [paymentLineItems, setPaymentLineItems] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState(abstract.object.customer.payment_methods);

    const onCancelOrder = () => {
        utils.alert.show.bind(this, {
            title: `Cancel ${order.channel.name} Order`,
            message: `Are you sure that you want to cancel this ${order.channel.name} Order?`,
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelOrderConfirm();
                    return;
                }
            }
        })
    }

    const onCancelOrderConfirm = async confirm => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { confirmation } = await Request.post(utils, '/order/', {
                type: 'cancel',
                order_id: order.id,
                approve_fees: confirm
            });

            // confirm cancellation fees if applicable
            if(confirmation) {
                setLoading(false);
                utils.alert.show({
                    title: 'Confirm',
                    message: confirmation,
                    buttons: [{
                        key: 'confirm',
                        title: 'Cancel',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Cancel',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onCancelOrderConfirm(true);
                            return;
                        }
                    }
                })
                return;
            }

            setLoading(false);
            abstract.object.status = Order.formatStatus(Order.status.cancelled);
            utils.content.fetch('orders');
            utils.alert.show({
                title: 'All Done!',
                message: `${order.channel.name} Order #${abstract.getID()} has been cancelled`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this ${order.channel.name} order. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCreateDriver = async (driverID, orderID) => {
        try {
            let driver = await User.get(utils, driverID);
            setAnnotations(annotations => {
                let index = annotations.findIndex(annotation => annotation.data.key === `order-${orderID}`);
                if(index < 0) {
                    return annotations;
                }
                return update(annotations, {
                    [index]: {
                        title: {
                            $set: driver.full_name
                        },
                        icon: {
                            $set: {
                                ...annotations[index].icon,
                                path: driver.avatar
                            }
                        }
                    }
                });
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onDeleteOrder = () => {
        utils.alert.show({
            title: `Delete ${order.channel.name} Order`,
            message: `Are you sure that you want to delete this ${order.channel.name} Order? ${order.channel.name} Orders should only be deleted if they were created by mistake. This can not be undone`,
            buttons: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'delete') {
                    onDeleteOrderConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteOrderConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/order/', {
                type: 'delete',
                order_id: order.id,
                order_channel: order.channel.id
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${order.channel.name} order #${order.id} for ${order.customer.full_name} has been deleted`,
                onClick: () => {
                    setLayerState('close');
                    utils.content.fetch('orders');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this ${order.channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditOrder = () => {
        utils.layer.open({
            id: `edit-order-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditOrder.bind(this, {
                isNewTarget: false,
                channel: order.channel
            })
        });
    }

    const onHostClick = () => {
        utils.layer.open({
            id: `order-host-details-${order.host.id}`,
            abstract: Abstract.create({
                type: 'orderHosts',
                object: order.host
            }),
            Component: OrderHostDetails.bind(this, {
                channel: order.channel
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default',
                visible: utils.security.setRequiredLevels([ 'admin' ])
            },{
                key: 'status',
                title: 'Set Status',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin
            },{
                key: 'approve',
                title: `Approve ${order.channel.name} Order`,
                style: 'default',
                visible: utils.user.get().level <= User.level.admin && (!order.status || order.status.code === Order.status.pending || order.status.code === Order.status.customer_edited)
            },{
                key: 'decline',
                title: `Decline ${order.channel.name} Order`,
                style: 'destructive',
                visible: utils.user.get().level <= User.level.admin && (!order.status || order.status.code === Order.status.pending || order.status.code === Order.status.customer_edited)
            },{
                key: 'edit',
                title: `Edit ${order.channel.name} Order`,
                style: 'default'
            },{
                key: 'delete',
                title: `Delete ${order.channel.name} Order`,
                style: 'destructive',
                visible: utils.user.get().level <= User.level.admin
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'status') {
                onShowEventLog();
                return;
            }
            if(key === 'edit') {
                onEditOrder();
                return;
            }
            if(key === 'delete') {
                onDeleteOrder();
                return;
            }
            if(key === 'approve' || key === 'decline') {
                onSetApprovalStatus(key === 'approve');
                return;
            }
        })
    }

    const onPreauthorizationOptionsClick = () => {

        let buttons = [{
            key: 'cancel',
            text: 'Dismiss',
            color: 'grey'
        }];
        let hasPreauthorization = order.hasPreauthorization();
        if(hasPreauthorization === false) {
            buttons.push({
                key: 'done',
                text: 'Create',
                color: 'primary',
                onAsyncClick: onUpdatePreauthorization
            });
        }
        if(hasPreauthorization === true) {
            buttons = buttons.concat([{
                key: 'remove',
                text: 'Revoke',
                color: 'danger',
                onAsyncClick: onRevokePreauthorization
            },{
                key: 'update',
                text: 'Update',
                color: 'primary',
                onAsyncClick: onUpdatePreauthorization
            }]);
        }

        setDropDown({
            title: 'Preauthorization',
            message: `A hold may be placed on the customer's credit or debit card if the service selected during booking has preauthorization enabled. You can manually create a preauthorization or remove the current authorization if needed. Changing the amount of a preauthorization will cancel the current authorization and create a new one. Preauthorizations are held for no more than 7 days`,
            buttons: buttons,
            content: (
                <PreauthorizationManager
                utils={utils}
                order={order}
                defaultAuthorization={order.data ? order.data.preauthorization : null} />
            )
        })
    }

    const onProcessManualPayment = async ({ amount, method }) => {
         try {
             setLoading(true);
             let { status } = await Request.post(utils, '/payment/', {
                 type: 'process_unpaid',
                 amount: amount,
                 card_id: method.id,
                 customer_stripe_id: order.customer.stripe_customer_id,
                 order_id: order.id,
                 order_channel: order.channel.id
             });

             order.status = status;
             utils.content.fetch('payments');
             utils.content.update({
                 type: 'orders',
                 object: order
             });

             setLoading(false);
             utils.alert.show({
                 title: 'All Done!',
                 message: `The payment for this ${order.channel.name} order has been processed`
             });

         } catch(e) {
             setLoading(false);
             utils.alert.show({
                 title: 'Oops!',
                 message: `There was an issue processing this payment. ${e.message || 'An unknown error occurred'}`
             })
         }
    }

    const onProcessPayment = () => {

        let props = { amount: breakdown.total };
        let target = Abstract.create({
            type: 'users',
            object: order.customer
        });

        setDropDown({
            title: 'Process Payment',
            message: `You can manually process the payment for this ${order.channel.name} order by choosing one of the customer's payment methods and specifying a price for the transaction. The recommended amount for this transaction has been prefilled for you.`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onAsyncClick: async () => {
                    return new Promise((resolve ,reject) => {
                        try {
                            if(!props.method) {
                                throw new Error('Please select a payment method');
                            }
                            if(!props.amount || props.amount <= 0.5) {
                                throw new Error('Please choose a payment method for this transaction and enter an amount greater than $0.50');
                            }
                            resolve();
                            onProcessManualPayment(props);
                        } catch(e) {
                            reject(e);
                        }
                    });
                }
            }],
            content: (
                <>
                <TextField
                prepend={'$'}
                placeholder={'Amount'}
                value={props.amount}
                onChange={text => props = {
                    ...props,
                    amount: isNaN(text) ? props.amount : text
                }}
                containerStyle={{
                    marginBottom: 8
                }} />

                <PaymentMethodManager
                utils={utils}
                target={target}
                defaultMethod={order.payment_method}
                onChange={method => {
                    props = {
                        ...props,
                        method: method
                    }
                }}/>
                </>
            )
        })
    }

    const onRevokePreauthorization = async () => {
        return new Promise(async (resolve ,reject) => {
            try {
                setLoading(true);
                await Utils.sleep(1);
                await order.revokePreauthorization(utils);

                resolve();
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The preauthorization for the ${order.channel.name} order has been revoked`
                });

            } catch(e) {
                reject(e);
                setLoading(false);
            }
        });
    }

    const onSetApprovalStatus = approve => {
        utils.alert.show({
            title: `${approve ? 'Approve' : 'Decline'} Reservation`,
            message: `Are you sure that you want to ${approve ? 'approve' : 'decline'} this ${order.channel.name} order? This will notify the customer via email and push notification with your decision.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: approve ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${approve ? 'Approve' : 'Decline'}`,
                style: approve ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetApprovalStatusConfirm(approve);
                    return;
                }
            }
        });
    }

    const onSetApprovalStatusConfirm = async (approve, confirm) => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/order/', {
                type: 'set_approval',
                status: approve ? Order.status.approved : Order.status.rejected,
                order_id: order.id,
                approve_unpaid_orders: confirm
            });

            setLoading(false);
            abstract.object.status = Order.formatStatus(approve ? Order.status.approved : Order.status.rejected);
            utils.content.fetch('orders');
            utils.alert.show({
                title: 'All Done!',
                message: `${order.channel.name} order #${abstract.getID()} has been ${approve ? 'approved' : 'declined'}`
            });

        } catch(e) {
            setLoading(false);
            if(e.code === 402) {
                utils.alert.show({
                    title: 'Oops!',
                    message: e.message,
                    buttons: [{
                        key: 'approve',
                        title: 'Approve',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Approve',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'approve') {
                            onSetApprovalStatusConfirm(key, true);
                            return;
                        }
                    }
                })
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this ${order.channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowEmailHistory = async () => {
        try {
            setLoading(true);
            let events = await abstract.object.getEmailHistory(utils);

            setLoading(false);
            if(events.length === 0) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: `There are no email history events for this ${order.channel.name} order`
                });
                return;
            }

            setDropDown({
                title: 'Email History',
                message: `When available, email history events represent the life cycle of an automated email. Events will show when specific emails were sent and delivered to the customer's inbox.`,
                content: (
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        maxHeight: 250,
                        overflowY: 'scroll'
                    }}>
                        {events.map((evt, index) => {
                            return (
                                Views.entry({
                                    key: index,
                                    title: evt.title,
                                    subTitle: Utils.formatDate(evt.date),
                                    badge: evt.status,
                                    bottomBorder: index !== events.length - 1
                                })
                            )
                        })}
                    </div>
                )
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the email history events for this ${order.channel.name} order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowEventLog = () => {
        setDropDown({
            title: 'Events and Status Changes',
            message: `The order event log shows the journey of the ${order.channel.name} order from booking to completion and includes additional information about log events when applicable. Events should only be manually added as a last resort.`,
            content: (
                <EventManager
                utils={utils}
                order={order} />
            )
        })
    }

    const onStopCompleted = data => {
        try {
            let { completion_id, stop_id } = JSON.parse(data);
            orderRef.current.stops.location = orderRef.current.stops.locations.map(stop => {
                if(stop.id === stop_id) {
                    stop.completed = completion_id;
                }
                return stop;
            });
            abstract.object = orderRef.current;
            utils.content.update(abstract);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onUpdateCostEstimate = async () => {
        let fetchID = `${layerID}:onUpdateCostEstimate`;
        try {
            if(utils.fetching.get(fetchID) === true) {
                return;
            }
            utils.fetching.set(fetchID, true);
            let { breakdown } = await abstract.object.getCostBreakdown(utils);

            utils.fetching.set(fetchID, false);
            setBreakdown(breakdown);
            setPaymentLineItems(breakdown ? breakdown.lineItems : []);

            // update overlays
            setOverlays(abstract.object.getOverlays());

        } catch(e) {
            utils.fetching.set(fetchID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating your cost estimate. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateLocation = async data => {
        try {
            let { driver_id, location, order_id } = JSON.parse(data);
            setAnnotations(annotations => {
                let index = annotations.findIndex(annotation => annotation.data.key === `order-${order_id}`);
                if(index >= 0) {
                    return update(annotations, {
                        [index]: {
                            location: {
                                $set: {
                                    latitude: location.lat,
                                    longitude: location.long,
                                    heading: location.heading,
                                    speed: location.speed
                                }
                            }
                        }
                    });
                }

                onCreateDriver(driver_id, order_id);
                return annotations.concat([{
                    title: 'Driver Location',
                    subTitle: `Arriving in ${Utils.parseDuration(location.time_to_arrival)}`,
                    supportingTitle: `Order #${order_id}`,
                    data: { key: `order-${order_id}` },
                    location: {
                        latitude: location.lat,
                        longitude: location.long,
                        heading: location.heading,
                        speed: location.speed
                    }
                }]);
            })

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateLocations = async () => {
        setAnnotations(abstract.object.getLocations());
        setOverlays(abstract.object.getOverlays());
    }

    const onUpdatePreauthorization = async () => {
        return new Promise(async (resolve ,reject) => {
            try {
                setLoading(true);
                await Utils.sleep(1);
                let hasPrevious = order.hasPreauthorization();
                await order.setPreauthorization(utils);

                resolve();
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `The preauthorization for the ${order.channel.name} order has been ${hasPrevious ? 'updated' : 'created'}`
                });

            } catch(e) {
                reject(e);
                setLoading(false);
            }
        });
    }

    const onViewMessages = () => {
        // TODO => implement logic for viewing messages
        utils.alert.dev();
    }

    const getButtons = () => {
        let buttons = [];
        if([ Order.status.unpaid, Order.status.charge_issue, Order.status.charge_failed ].includes(order.status.code)) {
            buttons.push({
                key: 'unpaid',
                text: 'Process Payment',
                color: 'secondary',
                onClick: onProcessPayment
            });
        }
        if(order.status.code < Order.status.completed && order.status.code !== Order.status.cancelled) {
            buttons.push({
                key: 'cancel',
                text: 'Cancel',
                color: 'danger',
                onClick: onCancelOrder
            });
        }
        return buttons.concat([{
            key: 'options',
            text: 'Options',
            color: 'primary',
            visible: utils.user.get().level <= User.level.admin || utils.user.get().order_host,
            onClick: onOptionsClick
        }]);
    }

    const getFields = () => {
        return [{
            key: 'dates_and_time',
            title: 'Dates and Times',
            items: [{
                key: 'drop_off_date',
                title: 'Drop Off Date',
                value: Utils.formatDate(order.drop_off_date)
            },{
                key: 'date_submitted',
                title: 'Booking Date',
                value: Utils.formatDate(order.date_submitted)
            }]
        },{
            key: 'details',
            title: `About this ${order.channel.name} Order`,
            items: [{
                key: 'id',
                title: 'ID',
                value: order.id
            },{
                key: 'status',
                title: 'Status',
                value: order.status ? order.status.text : null
            },{
                key: 'service',
                title: 'Service',
                value: order.service ? order.service.name : null
            },{
                key: 'message',
                title: 'Message for Driver',
                value: order.requests ? order.requests.message : null
            }]
        },{
            key: 'payments',
            title: 'Payments',
            items: [{
                key: 'preauthorization',
                title: 'Preauthorization',
                visible: utils.user.get().level <= User.level.admin,
                value: order.hasPreauthorization() ? 'Click to View' : 'Click to Create',
                onClick: onPreauthorizationOptionsClick
            },{
                key: 'payment_history',
                title: 'History',
                visible: [ Order.status.completed, Order.status.unpaid, Order.status.charge_processing, Order.status.charge_posted, Order.status.charge_failed ].includes(order.status.code),
                value: 'Click to View',
                onClick: getPaymentHistory
            },{
                key: 'payment_method',
                title: 'Method',
                value: getPaymentMethodComponent()

            },{
                key: 'promo_code',
                title: 'Promo Code',
                value: order.promo_code ? order.promo_code.getSummary() : 'Not Added'
            }]
        },{
            key: 'invoice',
            title: 'Invoice',
            items: paymentLineItems.map((lineItem, index) => ({
                key: index,
                title: lineItem.title,
                value: lineItem.message
            }))
        },{
            key: 'resources',
            title: 'Resources',
            items: [{
                key: 'status_codes',
                title: 'Events and Status Changes',
                visible: utils.user.get().level <= User.level.admin,
                value: 'Click to View',
                onClick: onShowEventLog
            },{
                key: 'messages',
                title: 'Driver and Customer Messages',
                visible: utils.user.get().level <= User.level.admin,
                value: 'Click to View',
                onClick: onViewMessages

            }]
        }];
    }

    const getLocations = () => {
        let locations = [{
            key: 'pickup',
            title: 'Pickup',
            value: order.origin ? order.origin.address : null,
            completed: order.status.code > Order.status.to_pickup
        }];
        if(order.stops && order.stops.locations && order.stops.locations.length > 0) {
            locations = locations.concat(order.stops.locations.map((stop, index) => ({
                key: index,
                title: `${Utils.integerToOrdinal(index + 1)} Stop`,
                value: stop.address,
                completed: stop.completed ? true : false
            })));
        }
        locations.push({
            key: 'destination',
            title: 'Drop-Off',
            value: order.destination ? order.destination.address : null,
            completed: order.status.code > Order.status.to_destination
        });

        return (
            <LayerItem title={'Locations'}>
                {locations.map((location, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: location.title,
                            subTitle: location.value,
                            hideIcon: true,
                            bottomBorder: index !== locations.length - 1,
                            badge: {
                                text: location.completed ? 'Completed' : 'Not Completed',
                                color: location.completed ? Appearance.colors.green : Appearance.colors.grey()
                            }
                        })
                    )
                })}
            </LayerItem>
        )
    }

    const getPaymentAvatar = payment => {
        if(payment.customer) {
            return payment.customer.avatar;
        }
        if(payment.company){
            return payment.company.image;
        }
        return null;
    }

    const getPaymentBadges = payment => {
        if(payment.credits) {
            return {
               color: Appearance.colors.primary(),
               text: `${Utils.toCurrency(payment.credits.amount)} Credits`
           }
        }
        return {
            color: Appearance.colors.grey(),
            text: Utils.toCurrency(payment.amount)
        }
    }

    const getPaymentMethodComponent = () => {
        if(!paymentMethods) {
            return Views.loader({ justifyContent: 'flex-end' });
        }
        let method = order.getCurrentPaymentMethod();
        if(method) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}>
                    <span style={Appearance.textStyles.value()}>{method.summary()}</span>
                    <i
                    className={method.icon()}
                    style={{
                        fontSize: 20,
                        paddingLeft: 8,
                        color: Appearance.colors.text()
                    }}/>
                </div>
            );
        }
        return (
            <span style={Appearance.textStyles.value()}>{'Not Added'}</span>
        )
    }

    const getPaymentHistory = async () => {
        try {
            setLoading(true);
            let { payments } = await Request.get(utils, '/payments/', {
                type: 'order_payment_history',
                order_id: order.id
            });

            setLoading(false);
            if(!payments || payments.length === 0) {
                utils.alert.show({
                    title: 'Payment History',
                    message: `It looks like no payments have been posted for this ${order.channel.name} order.`
                });
                return;
            }

            let targets = payments.map(payment => Payment.create(payment));
            setDropDown({
                title: 'Payment History',
                message: `Listed below are the payments made for this  order. This can include the standard payment made for the estimated cost of the trip, driver tips, and any extra charges incurred throughout the order.`,
                content: (
                    <div style={{
                        width: '100%'
                    }}>
                        {targets.map((payment, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    ...Appearance.styles.unstyledPanel(),
                                    marginBottom: 8
                                }}>
                                    {Views.entry({
                                        title: payment.getType(),
                                        subTitle: Utils.formatDate(payment.date),
                                        badge: getPaymentBadges(payment),
                                        icon: {
                                            path: getPaymentAvatar(payment)
                                        },
                                        bottomBorder: false,
                                        onClick: Utils.payments.details.bind(this, utils, payment)
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payment history for this order. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchPaymentMethods = async () => {
        try {
            let { methods } = await order.customer.getPaymentMethods(utils);
            setPaymentMethods(methods);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the payment methods for this customer. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        fetchPaymentMethods();
    }, [order.customer]);

    useEffect(() => {
        onUpdateLocations();
    }, [order.origin, order.destination, order.driver]);

    useEffect(() => {
        onUpdateCostEstimate();
    }, [order.origin, order.destination, order.stops, order.service, order.credits, order.driver_tip]);

    useEffect(() => {
        orderRef.current = order;
        if(order.seeds && order.seeds.notes) {
            setNote(order.seeds.notes.find(note => !note.deleted))
        }
    }, [order]);

    useEffect(() => {
        utils.sockets.on('orders', `on_stop_completed_${abstract.getID()}`, onStopCompleted);
        utils.sockets.on('seeds', `on_order_location_update_${abstract.getID()}`, onUpdateLocation);
        utils.content.subscribe(layerID, 'orders', {
            onUpdate: next => {
                setOrder(next.compare(abstract, () => {
                    fetchSystemEvents();
                    onUpdateLocations();
                }));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
            utils.sockets.off('orders', `on_stop_completed_${abstract.getID()}`, onStopCompleted);
            utils.sockets.off('seeds', `on_order_location_update_${abstract.getID()}`, onUpdateLocation);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`${order.channel.name} Order #${order.id} Details`}
        index={index}
        utils={utils}
        dropDown={dropDown}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            removeMobilePadding: true
        }}>
            <LayerMap
            utils={utils}
            annotations={annotations}
            overlays={overlays}
            showUserLocation={false}
            style={{
                marginTop: Utils.isMobile() ? 12 : 0
            }}/>

            {order.emissions && (
                <LayerEmissions items={[{
                    key: 'trees',
                    title: order.emissions.trees === 1 ? ' Tree Planted' : ' Trees Planted',
                    placeholder: '0',
                    value: order.emissions.trees,
                    image: 'trees-icon-clear.png'
                },{
                    key: 'gas',
                    title: 'Gallons of Gas',
                    placeholder: '0',
                    value: order.emissions.gas,
                    image: 'gas-icon-clear.png'
                },{
                    key: 'carbon',
                    title: 'Grams of CO2',
                    placeholder: '0',
                    value: order.emissions.carbon,
                    image: 'carbon-icon-clear.png'
                }]} />
            )}

            {order.customer && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: order.customer.full_name,
                        subTitle: order.customer.phone_number,
                        icon: {
                            path: order.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, order.customer)
                    })}
                </LayerItem>
            )}

            <LayerItem title={`${order.channel.name} Host`}>
                {Views.entry({
                    title: order.host.name,
                    subTitle: order.host.description,
                    icon: {
                        path: order.host.image
                    },
                    bottomBorder: false,
                    onClick: onHostClick
                })}
            </LayerItem>

            <LayerItem title={`${order.channel.name} Order`}>
                {order.options.map((option, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: option.name,
                            subTitle: option.description,
                            badge: option.getCustomizedCost() > 0 && {
                                text: Utils.toCurrency(option.getCustomizedCost()),
                                color: Appearance.colors.grey()
                            },
                            icon: {
                                path: option.image || 'images/order-category-icon.png'
                            },
                            bottomBorder: index !== order.options.length - 1
                        })
                    )
                })}
            </LayerItem>

            {getLocations()}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getSystemEvents()}

            {utils.user.get().level <= User.level.admin && (
                <LayerNote
                note={note}
                utils={utils}
                abstract={abstract} />
            )}

        </Layer>
    )
}

export const OrderCategoryDetails = ({ channel }, { abstract, index, options, utils }) => {

    const layerID = `order-category-details-${abstract.getID()}`;
    const limit = 5;

    const [category, setCategory] = useState(abstract.object);
    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useLoading();
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [opts, setOpts] = useState([]);
    const [paging, setPaging] = useState(null);

    const onDeleteCategory = () => {
        utils.alert.show({
            title: 'Delete Category',
            message: `Are you sure that you want to delete this catgegory? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCategoryConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteCategoryConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/orders/', {
                type: 'delete_category',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('orderCategories');
            utils.alert.show({
                title: 'All Done!',
                message: 'This category has been deleted',
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-order-category-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditOrderCategory.bind(this, {
                isNewTarget: false,
                channel: channel
            })
        })
    }

    const onHostClick = () => {
        utils.layer.open({
            id: `order-host-details-${category.host.id}`,
            abstract: Abstract.create({
                type: 'orderHosts',
                object: category.host
            }),
            Component: OrderHostDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'status',
                title: abstract.object.active ? 'Deactivate' : 'Activate',
                style: abstract.object.active ? 'destructive' : 'default'
            }]
        }, key => {
            if(key === 'delete') {
                onDeleteCategory();
                return;
            }
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'status') {
                onSetStatus();
                return;
            }
        })
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `${abstract.object.active ? 'Deactivate' : 'Activate'} Category`,
            message: `Are you sure that you want to ${abstract.object.active ? 'deactivate' : 'activate'} this category? This will ${abstract.object.active ? 'hide' : 'show'} this category ${abstract.object.active ? 'from' : 'to'} customers and ${abstract.object.active ? 'hide' : 'show'} all order options contained in this categroy.`,
            buttons: [{
                key: 'confirm',
                title: abstract.object.active ? 'Deactivate' : 'Activate',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${abstract.object.active ? 'Deactivate' : 'Activate'}`,
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/orders/', {
                type: 'set_category_status',
                id: abstract.getID(),
                active: next
            });

            setLoading(false);
            abstract.object.active = next;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `This category has been ${next ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: category.id
            },{
                key: 'active',
                title: 'Status',
                value: category.active ? 'Active' : 'Not Active'
            },{
                key: 'date_added',
                title: 'Created',
                value: Utils.formatDate(category.date_added)
            },{
                key: 'title',
                title: 'Title',
                value: category.title
            },{
                key: 'description',
                title: 'Description',
                value: category.description
            }]
        }];
    }

    const getOptions = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    padding: 12
                }}>
                    {Views.loader()}
                </div>
            )
        }
        if(opts.length === 0) {
            return (
                Views.entry({
                    title: `No Order Options Found`,
                    subTitle: `There are no order options available to view`,
                    bottomBorder: false
                })
            )
        }
        return opts.map((option, index, options) => {
            return (
                Views.entry({
                    key: index,
                    title: option.name,
                    subTitle: option.description,
                    badge: !option.active && {
                        text: 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    icon: {
                        path: option.image || 'images/order-category-icon.png'
                    },
                    bottomBorder: index !== options.length - 1,
                    onClick: utils.layer.open.bind(this, {
                        id: `order-option-details-${option.id}`,
                        abstract: Abstract.create({
                            type: 'orderOptions',
                            object: option
                        }),
                        Component: OrderOptionDetails.bind(this, {
                            channel: channel
                        })
                    })
                })
            )
        });
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchOptions = async () => {
        try {
            let opts = await abstract.object.getOptions(utils);
            setOpts(opts);
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading ${channel.name} service options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchSystemEvents = async () => {
        try {
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(category.seeds && category.seeds.notes) {
            setNote(category.seeds.notes.find(note => note.deleted !== true));
        }
    }, [category]);

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        fetchOptions();
        utils.content.subscribe(layerID, 'orderCategories', {
            onUpdate: next => {
                fetchOptions();
                setCategory(next.compare(abstract, fetchSystemEvents));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`"${abstract.getTitle()}" Details`}
        index={index}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerItem title={'Host'}>
                {Views.entry({
                    title: category.host.name,
                    subTitle: category.host.description,
                    icon: {
                        path: category.host.image
                    },
                    bottomBorder: false,
                    onClick: onHostClick
                })}
            </LayerItem>

            <LayerItem title={'Options'}>
                {getOptions()}
            </LayerItem>

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const OrderHostDetails = ({ channel }, { abstract, index, options, utils }) => {

    const layerID = `order-host-details-${abstract.getID()}`;
    const limit = 5;

    const [annotations, setAnnotations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [events, setEvents] = useState([]);
    const [host, setHost] = useState(abstract.object);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);

    const onCategoryClick = category => {
        utils.layer.open({
            id: `order-category-details-${category.id}`,
            abstract: Abstract.create({
                type: 'orderCategories',
                object: category
            }),
            Component: OrderCategoryDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-order-host-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditOrderHost.bind(this, {
                isNewTarget: false,
                channel: channel
            })
        });
    }

    const onLocationClick = location => {
        window.open(`https://maps.apple.com/?address=${encodeURI(location.address)}`);
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit Host',
                style: 'default'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'edit') {
                onEditClick();
                return;
            }
        })
    }

    const onServiceClick = service => {
        utils.layer.open({
            id: `service-details-${service.id}`,
            abstract: Abstract.create({
                type: 'services',
                object: service
            }),
            Component: ServiceDetails
        });
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: host.active ? 'Deactivate' : 'Activate',
            message: `Are you sure that you want to ${host.active ? 'deactivate' : 'activate'} this Host? ${host.active ? 'Deactivating' : 'Activating'} this host will ${host.active ? 'prevent' : 'allow'} them to receive new ${channel.name} Orders`,
            buttons: [{
                key: 'confirm',
                title: host.active ? 'Deactivate' : 'Activate',
                style: host.active ? 'destructive' : 'default',
            },{
                key: 'cancel',
                title: host.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: host.active ? 'default' : 'destructive',
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.5);

            // set request to the server
            let next = host.active ? false : true;
            await Request.post(utils, '/orders/', {
                active: next,
                id: abstract.getID(),
                type: 'set_host_status'
            });

            // update abstract target with new status
            setLoading(false);
            abstract.object.active = next;

            // notify subscribers of target update
            utils.content.update(abstract);

            // show confirmation message
            utils.alert.show({
                title: 'All Done!',
                message: `This Host has been ${next ? 'activated and will now be available for new orders.' : 'deactivated and will no longer be available for new orders'}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this Host. ${e.message || 'An unknown error occurred'}.`
            });
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onUpdateAnnotations = () => {
        if(!host.locations) {
            return;
        }
        setAnnotations(host.locations.map((l, index) => ({
            key: `location-${index}`,
            title: l.address,
            location: l.location,
            icon: { type: 'broadcast' }
        })));
    }

    const onUserClick = async userID => {
        try {
            setLoading(true);
            let user = await User.get(utils, userID);

            setLoading(false);
            Utils.users.details(utils, user);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this user account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getBookingServiceAreas = () => {
        if(locations.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Service Areas'}>
                {locations.map((location, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: location.name,
                            subTitle: location.address,
                            icon: {
                                path: 'images/location-icon-blue-small.png'
                            },
                            badge: {
                                text: Utils.distanceConversion(location.radius),
                                color: Appearance.colors.primary()
                            },
                            bottomBorder: index !== locations.length - 1
                        })
                    )
                })}
            </LayerItem>
        )
    }

    const getButtons = () => {
        return [{
            key: 'active',
            text: host.active ? 'Deactivate' : 'Activate',
            color: host.active ? 'danger' : 'primary',
            onClick: onSetStatus
        },{
            key: 'options',
            text: 'Options',
            color: host.active ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }];
    }

    const getFields = () => {

        // prepare default list of line items
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: host.id
            },{
                key: 'active',
                title: 'Status',
                value: host.active ? 'Active' : 'Not Active'
            },{
                key: 'date_added',
                title: 'Created',
                value: Utils.formatDate(host.date_added)
            }]
        },{
            key: 'about',
            title: `About ${host.name}`,
            items: [{
                key: 'website',
                title: 'Website',
                value: host.parameters && host.parameters.website ? host.parameters.website : null
            },{
                key: 'email_address',
                title: 'Email Address',
                value: host.parameters && host.parameters.email_address ? host.parameters.email_address : null
            },{
                key: 'description',
                title: 'Description',
                value: host.description
            }]
        }];

        // prevent moving forward with direct payment logic if no direct payments account has been setup
        let { account, user } = host.direct_payments || {};
        let { balance, enabled, external_accounts } = account || {};
        items.push({
            key: 'direct_payments_account',
            title: 'Direct Payments Preferences',
            visible: utils.user.get().level <= User.level.company_admin ? true : false,
            items: [{
                key: 'user',
                title: 'Account Holder',
                value: user && user.full_name || 'Not Setup',
                onClick: user && onUserClick.bind(this, user.user_id)
            },{
                key: 'external_accounts',
                title: 'Destinations',
                value: external_accounts && external_accounts.length > 0 ? 'Setup' : 'Not Setup'
            },{
                key: 'enabled',
                title: 'Status',
                value: enabled ? 'Enabled' : 'Disabled'
            }]
        });

        // add entry for direct payments account balances
        items.push({
            key: 'direct_payments_account_balances',
            title: 'Direct Payments Account Balance',
            visible: balance && utils.user.get().level <= User.level.company_admin ? true : false,
            items: [{
                key: 'available_balance',
                title: 'Available Balance',
                value: balance && Utils.toCurrency(balance.available),
                visible: balance && isNaN(balance.available) === false ? true : false,
                ...balance && balance.available < 0 && {
                    color: Appearance.colors.red
                }
            },{
                key: 'instant_balance',
                title: 'Instant Balance',
                value: balance && Utils.toCurrency(balance.instant),
                visible: balance && isNaN(balance.instant) === false ? true : false,
                ...balance && balance.instant < 0 && {
                    color: Appearance.colors.red
                }
            },{
                key: 'pending_balance',
                title: 'Pending Balance',
                value: balance && Utils.toCurrency(balance.pending),
                visible: balance && isNaN(balance.pending) === false ? true : false,
                ...balance && balance.pending < 0 && {
                    color: Appearance.colors.red
                }
            }]
        });

        return items;
    }

    const getLocations = () => {
        if(!host.service.max_distance || host.locations.length === 0) {
            return null;
        }
        return host.locations.map((entry, index) => ({
            key: `location-${index}`,
            coordinate: entry.location,
            radius: host.service.max_distance
        }));
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchCategories = async () => {
        try {
            let categories = await abstract.object.getCategories(utils);
            setCategories(categories);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading ${channel.name} service categories. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchLocations = async () => {
        try {
            let { locations } = await Request.get(utils, '/orders/', {
                type: 'host_service_locations',
                id: abstract.getID()
            });
            setLocations(locations);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retriving the locations radius restrictions for this host. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        onUpdateAnnotations();
    }, [host.locations]);

    useEffect(() => {
        if(host.seeds && host.seeds.notes) {
            setNote(host.seeds.notes.find(note => note.deleted !== true));
        }
    }, [host]);

    useEffect(() => {
        fetchCategories();
        fetchLocations();
        utils.content.subscribe(layerID, 'orderHosts', {
            onFetch: () => {
                fetchLocations();
                fetchSystemEvents();
            },
            onUpdate: next => {
                setHost(next.compare(abstract, () => {
                    fetchLocations();
                    fetchSystemEvents();
                }));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`"${abstract.getTitle()}" Details`}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            <LayerMap
            utils={utils}
            annotations={annotations}
            circles={getLocations()} />

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {host.getBusinessHours() && (
                <LayerItem title={'Business Hours'}>
                    {host.getBusinessHours().map((entry, index, entries) => {
                        return (
                            Views.row({
                                key: index,
                                label: moment().startOf('week').add(entry.day, 'days').format('dddd'),
                                value: entry.formatted,
                                bottomBorder: index !== entries.length - 1
                            })
                        )
                    })}
                </LayerItem>
            )}

            {categories && categories.length > 0 && (
                <LayerItem title={'Categories'}>
                    {categories.map((category, index, categories) => {
                        return (
                            Views.entry({
                                key: index,
                                title: category.title,
                                subTitle: category.options && category.options.length > 0 ? `${category.options.length} ${channel.name} ${category.options.length === 1 ? 'Option' : 'Options'}` : `No ${channel.name} Options have been added`,
                                badge: !category.active && {
                                    text: 'Not Active',
                                    color: Appearance.colors.grey()
                                },
                                icon: {
                                    path: 'images/order-category-icon.png'
                                },
                                bottomBorder: index !== categories.length - 1,
                                onClick: onCategoryClick.bind(this, category)
                            })
                        )
                    })}
                </LayerItem>
            )}

            {host.service && (
                <LayerItem title={'Service'}>
                    {Views.entry({
                        title: host.service.name,
                        subTitle: host.service.information,
                        bottomBorder: false,
                        onClick: onServiceClick.bind(this, host.service)
                    })}
                </LayerItem>
            )}

            {host.companies && host.companies.length > 0 && (
                <LayerItem title={'Companies'}>
                    {host.companies.map((company, index, companies) => {
                        return (
                            Views.entry({
                                key: index,
                                title: company.name,
                                subTitle: company.information,
                                badge: !company.active ? {
                                    text: 'Not Active',
                                    color: Appearance.colors.grey()
                                } : null,
                                icon: {
                                    path: company.image
                                },
                                bottomBorder: index !== companies.length - 1,
                                onClick: Utils.companies.details.bind(this, utils, company)
                            })
                        )
                    })}
                </LayerItem>
            )}

            <LayerItem title={'Locations'}>
                {host.locations.map((location, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: location.name,
                            subTitle: location.address,
                            bottomBorder: index !== host.locations.length - 1,
                            onClick: onLocationClick.bind(this, location),
                            icon: {
                                path: 'images/location-icon-clear.png',
                                style: {
                                    ...Appearance.icons.standard(),
                                    backgroundColor: Appearance.colors.blue
                                }
                            }
                        })
                    )
                })}
            </LayerItem>

            {getBookingServiceAreas()}

            <LayerItem
            title={'Image Assets'}
            childrenStyle={{
                display: 'flex',
                flexDirection: 'row',
                padding: 12
            }}>
                {[ host.image, host.cover_image ].map((image, index) => {
                    return (
                        <div
                        key={index}
                        className={'text-button'}
                        onClick={() => window.open(image)}
                        style={{
                            borderRadius: 5,
                            backgroundColor: Appearance.colors.softBorder(),
                            border: `1px solid ${Appearance.colors.softBorder()}`,
                            marginRight: 8,
                            height: 40,
                            overflow: 'hidden'
                        }}>
                            <img
                            src={image}
                            style={{
                                width: 'auto',
                                height: '100%'
                            }}/>
                        </div>
                    )
                })}
            </LayerItem>

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const OrderOptionDetails = ({ channel }, { abstract, index, options, utils }) => {

    const layerID = `order-option-details-${abstract.getID()}`;
    const limit = 5;

    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [option, setOption] = useState(abstract.object);
    const [paging, setPaging] = useState(null);

    const onCategoryClick = () => {
        if(!option.category || option.category.id === -1) {
            utils.alert.show({
                title: 'Uncategorized',
                message: 'This order option does not have a category assigned to it. This means that the option is not viewable by the public. Please add a category to this item to continue',
                buttons: [{
                    key: 'confirm',
                    title: 'Update Category',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        utils.layer.open({
                            id: `edit-order-option-${abstract.getID()}`,
                            abstract: abstract,
                            Component: AddEditOrderOption.bind(this, {
                                channel: channel,
                                isNewTarget: false
                            })
                        })
                        return;
                    }
                }
            });
            return;
        }
        utils.layer.open({
            id: `order-category-details-${option.category.id}`,
            abstract: Abstract.create({
                type: 'orderCategories',
                object: option.category
            }),
            Component: OrderCategoryDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onDeleteOption = () => {
        utils.alert.show({
            title: 'Delete Option',
            message: `Are you sure that you want to delete this option? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteOptionConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteOptionConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/orders/', {
                type: 'delete_option',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('orderOptions');
            utils.alert.show({
                title: 'All Done!',
                message: 'This order option has been deleted',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this option. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-order-option-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditOrderOption.bind(this, {
                channel: channel,
                isNewTarget: false
            })
        })
    }

    const onEditInteractionItem = (option, index) => {
        utils.layer.open({
            id: `order-option-item-details-${option.id}`,
            abstract: Abstract.create({
                type: 'orderOptionItems',
                object: option
            }),
            Component: OrderOptionItemDetails.bind(this, {
                channel: channel,
                onUpdate: onUpdateOrder.bind(this, index)
            })
        });
    }

    const onMoveInteractionItem = async (option, index, value, evt) => {
        try {
            evt.stopPropagation();
            let next = index + value;
            if(next < 0 || next === options.length - 1) {
                return;
            }

            // update target props
            abstract.object.open();
            let edits = abstract.object.set({
                options: update(abstract.object.edits.options, {
                    $splice: [[ index, 1 ], [ next, 0, option ]]
                })
            });
            setOption(props => update(props, {
                options: {
                    $set: edits.options
                }
            }));

            // submit target changes
            setLoading(true);
            await abstract.object.update(utils);
            utils.content.update(abstract);
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the interaction items for this option. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'delete') {
                onDeleteOption();
                return;
            }
        })
    }

    const onRemoveInteractionItem = (option, index, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Delete Interaction Item',
            message: 'Are you sure that you want to delete this Interaction item? This can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveInteractionItemConfirm(option, index);
                    return;
                }
            }
        });
    }

    const onRemoveInteractionItemConfirm = async (option, index) => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            // update target props
            abstract.object.open();
            abstract.object.set({
                options: update(abstract.object.edits.options, {
                    $splice: [[ index, 1 ]]
                })
            });

            // submit target changes
            await abstract.object.update(utils);
            utils.content.update(abstract);

            // update local values
            setLoading(false);
            setOption({ ...abstract.object });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the interaction items for this option. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onUpdateOrder = async (index, object) => {
        try {

            // apply changes to interaction items
            setLoading(true);
            abstract.object.open();
            let edits = abstract.object.set({
                options: update(abstract.object.edits.options || [], {
                    [index]: {
                        $set: object
                    }
                })
            });
            setOption(edits);

            // update order option target
            await abstract.object.update(utils);
            utils.content.update(abstract);
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the interaction items for this option. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: option.id
            },{
                key: 'active',
                title: 'Status',
                value: option.active ? 'Active' : 'Not Active'
            },{
                key: 'name',
                title: 'Name',
                value: option.name
            },{
                key: 'date_added',
                title: 'Created',
                value: Utils.formatDate(option.date_added)
            },{
                key: 'cost',
                title: 'Cost',
                value: Utils.toCurrency(option.cost)
            },{
                key: 'featured',
                title: 'Featured',
                value: option.featured ? 'Yes' : 'No'
            },{
                key: 'description',
                title: 'Description',
                value: option.description
            }]
        }];
    }

    const getInteractionItems = () => {
        if(!option.options || option.options.length === 0) {
            return (
                Views.entry({
                    title: 'No Items Found',
                    subTitle: `No interaction items have been added for this ${channel.name} option`,
                    bottomBorder: false,
                    icon: {
                        style: {
                            backgroundColor: Appearance.colors.grey()
                        }
                    }
                })
            )
        }

        return option.options.map((option, index, options) => {
            return (
                Views.entry({
                    key: index,
                    title: option.title || 'New Interaction',
                    subTitle: option.getSummary() || 'Click to make changes...',
                    badge: option.required && {
                        text: 'Required',
                        color: Appearance.colors.grey()
                    },
                    icon: {
                        path: 'images/order-category-option-icon.png',
                        style: !option.title && {
                            backgroundColor: Appearance.colors.grey()
                        }
                    },
                    bottomBorder: index !== options.length - 1,
                    onClick: onEditInteractionItem.bind(this, option, index),
                    rightContent: (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <img
                            className={'text-button'}
                            src={'images/up-directional-arrow-small.png'}
                            onClick={onMoveInteractionItem.bind(this, option, index, -1)}
                            style={{
                                width: 18,
                                height: 18,
                                objectFit: 'contain',
                                marginLeft: 8,
                                opacity: index > 0 ? 1 : 0.25
                            }} />
                            <img
                            className={'text-button'}
                            src={'images/down-directional-arrow-small.png'}
                            onClick={onMoveInteractionItem.bind(this, option, index, 1)}
                            style={{
                                width: 18,
                                height: 18,
                                objectFit: 'contain',
                                marginLeft: 8,
                                opacity: index < options.length - 1 ? 1 : 0.25
                            }} />
                            <img
                            className={'text-button'}
                            src={'images/red-x-icon.png'}
                            onClick={onRemoveInteractionItem.bind(this, option, index)}
                            style={{
                                width: 20,
                                height: 20,
                                marginLeft: 8
                            }} />
                        </div>
                    )
                })
            )
        });
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(option.seeds && option.seeds.notes) {
            setNote(option.seeds.notes.find(note => note.deleted !== true));
        }
    }, [option]);

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'orderOptions', {
            onUpdate: next => {
                setOption(next.compare(abstract, fetchSystemEvents));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`"${abstract.getTitle()}" Details`}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerItem title={'Category'}>
                {Views.entry({
                    title: option.category.title,
                    subTitle: option.category.description,
                    bottomBorder: false,
                    onClick: onCategoryClick,
                    icon: {
                        path: 'images/order-category-icon.png',
                        style: option.category.id === -1 && {
                            backgroundColor: Appearance.colors.red
                        }
                    }
                })}
            </LayerItem>

            <LayerItem
            title={'Interaction Items'}
            rightContent={(
                <Button
                label={'Add New Item'}
                type={'small'}
                color={'dark'}
                onClick={() => {
                    let isNewTarget = Order.Option.Item.new();
                    isNewTarget.id = moment().unix();
                    abstract.object.options = update(abstract.object.options, {
                        $push: [isNewTarget]
                    })
                    setOption({ ...abstract.object });
                }}/>
            )}>
                {getInteractionItems()}
            </LayerItem>

            <LayerItem
            title={'Image Assets'}
            childrenStyle={{
                display: 'flex',
                flexDirection: 'row',
                padding: 12
            }}>
                <div
                className={'text-button'}
                onClick={() => window.open(option.image)}
                style={{
                    borderRadius: 5,
                    backgroundColor: Appearance.colors.softBorder(),
                    border: `1px solid ${Appearance.colors.softBorder()}`,
                    marginRight: 8,
                    height: 40,
                    overflow: 'hidden'
                }}>
                    <img
                    src={option.image}
                    style={{
                        width: 'auto',
                        height: '100%'
                    }}/>
                </div>
            </LayerItem>

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const OrderOptionItemDetails = ({ channel, onUpdate }, { abstract, index, options, utils }) => {

    const layerID = `order-option-item-details-${abstract.getID()}`;
    const [dropDown, setDropDown] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [option, setOption] = useState(null);

    const onAddNewItem = () => {
        let edits = abstract.object.set({
            options: update(abstract.object.edits.options || [], {
                $push: [
                    Order.Option.Item.Interaction.create({
                        title: null,
                        description: null,
                        cost: null,
                        type: 'checkbox'
                    })
                ]
            })
        });
        setOption(edits);
    }

    const onEditItem = (option, index) => {
        let layerID = option.id ? `order-option-item-interaction-details-${option.id}` : `new_order-option-item-interaction-details`;
        utils.layer.open({
            id: layerID,
            abstract: Abstract.create({
                type: 'orderOptionItemInteractions',
                object: option
            }),
            Component: OrderOptionItemInteractionDetails.bind(this, {
                layerID: layerID,
                channel: channel,
                onUpdate: response => {
                    let edits = abstract.object.set({
                        options: update(abstract.object.edits.options || {}, {
                            [index]: {
                                $set: option.replace(response)
                            }
                        })
                    });
                    setOption(edits);
                }
            })
        })
    }

    const onManageRestrictions = () => {
        let restrictions = {
            min: 0,
            max: 0,
            ...option.restrictions
        };
        setDropDown({
            title: 'Restrictions',
            message: 'The minimum and maximum for this item will be shown when a customer is selecting customizable options. The minimum vaue represents the least amount of choices that can be made for this item and the maximum value represents the highest amount of choices that can be made for this item.',
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ restrictions: restrictions })
            }],
            content: (
                <>
                <LayerItem
                title={'Minimum'}
                shouldStyle={false}>
                    <NumberStepper
                    min={0}
                    startingValue={option.restrictions ? option.restrictions.min : null}
                    onChange={value => {
                        restrictions = {
                            ...restrictions,
                            min: value
                        }
                    }}/>
                </LayerItem>
                <LayerItem
                title={'Maximum'}
                lastItem={true}
                shouldStyle={false}>
                    <NumberStepper
                    min={0}
                    startingValue={option.restrictions ? option.restrictions.max : null}
                    onChange={value => {
                        restrictions = {
                            ...restrictions,
                            max: value
                        }
                    }}/>
                </LayerItem>
                </>
            )
        });
    }

    const onMoveItem = (option, index, value, evt) => {
        evt.stopPropagation();
        let next = index + value;
        if(next < 0 || next > abstract.object.edits.options.length - 1) {
            return;
        }
        let edits = abstract.object.set({
            options: update(abstract.object.edits.options, {
                $splice: [[ index, 1 ], [ next, 0, option ]]
            })
        })
        setOption(edits);
    }

    const onRemoveItem = (option, index, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Delete Customization',
            message: 'Are you sure that you want to delete this customization?',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    let edits = abstract.object.set({
                        options: update(abstract.object.edits.options, {
                            $splice: [[ index, 1 ]]
                        })
                    })
                    setOption(edits);
                    return;
                }
            }
        })
    }

    const onSaveChanges = () => {

        let required = [{
            title: 'title',
            object: option.title
        },{
            title: 'interaction type',
            object: option.type
        },{
            title: 'interaction restrictions',
            object: option.type !== 'text-area' ? (option.restrictions && option.restrictions.min && option.restrictions.max) : true
        },{
            title: 'interaction items',
            object: option.type !== 'text-area' ? (option.options && option.options.length > 0 && !option.options.some(option => {
                return !option.title || !option.type
            })) : true
        }]

        let remaining = required.filter(entry => !entry.object);
        if(remaining.length > 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please fill out the ${remaining[0].title} before moving on`
            })
            return;
        }

        // apply edits to target object
        abstract.object.close();

        setLayerState('close');
        if(typeof(onUpdate) === 'function') {
            onUpdate(abstract.object);
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setOption(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!option) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for this item will be shown when a customer is selecting customizable options. The title should describe what they are selecting. An example would be "What type of cheese would you like?".',
                value: option.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'description',
                title: 'Description',
                description: 'The description for this item will be shown when a customer is selecting customizable options. The description is should describe what they are selecting. An example would be "We offer only the highest quality cheeses".',
                value: option.description,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            },{
                key: 'required',
                title: 'Required',
                description: `You can require some type of answer for this interaction before the customer adds the ${channel.name} item to their order. All interactions are optional by default.`,
                value: option.required,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ required: val })
            },{
                key: 'type',
                title: 'Interaction',
                description: 'The interaction type for this item will outline how the customer makes a selection. This can include writing a response, choosing the desired amount, or selecting a choice from a list of items.',
                value: option.type,
                component: 'list',
                onChange: item => onUpdateTarget({ type: item && item.id }),
                items: Order.Option.Item.types.map(type => ({
                    id: type.key,
                    title: type.title
                }))
            },{
                key: 'restrictions',
                required: option.type === 'list',
                visible: option.type === 'list',
                title: 'Restrictions',
                description: 'The minimum and maximum for this item will be shown when a customer is selecting customizable options. The minimum vaue represents the least amount of choices that can be made for this item and the maximum value represents the highest amount of choices that can be made for this item.',
                value: getRestrictionValue(),
                onEditClick: onManageRestrictions
            }]
        }];
    }

    const getOptionComponents = () => {
        if(!option.options || option.options.length === 0) {
            return (
                Views.entry({
                    title: 'No Options Found',
                    subTitle: 'No customizable options have been added',
                    bottomBorder: false
                })
            )
        }
        return option.options.map((opt, index, options) => {
            let type = Order.Option.Item.Interaction.types.find(t => t.key === opt.type);
            return (
                Views.entry({
                    key: index,
                    title: opt.title || 'No Title Added',
                    subTitle: type ? type.title : null,
                    badge: {
                        text: opt.cost > 0 && Utils.toCurrency(opt.cost),
                        color: Appearance.colors.secondary()
                    },
                    bottomBorder: index !== options.length - 1,
                    icon: {
                        path: 'images/order-category-option-icon.png',
                        style: !opt.title && {
                            backgroundColor: Appearance.colors.grey()
                        }
                    },
                    onClick: onEditItem.bind(this, opt, index),
                    rightContent: (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <img
                            className={'text-button'}
                            src={'images/up-directional-arrow-small.png'}
                            onClick={onMoveItem.bind(this, opt, index, -1)}
                            style={{
                                width: 18,
                                height: 18,
                                objectFit: 'contain',
                                marginLeft: 8,
                                opacity: index > 0 ? 1 : 0.25
                            }} />
                            <img
                            className={'text-button'}
                            src={'images/down-directional-arrow-small.png'}
                            onClick={onMoveItem.bind(this, opt, index, 1)}
                            style={{
                                width: 18,
                                height: 18,
                                objectFit: 'contain',
                                marginLeft: 8,
                                opacity: index < options.length - 1 ? 1 : 0.25
                            }} />
                            <img
                            className={'text-button'}
                            src={'images/red-x-icon.png'}
                            onClick={onRemoveItem.bind(this, opt, index)}
                            style={{
                                width: 20,
                                height: 20,
                                marginLeft: 8
                            }} />
                        </div>
                    )
                })
            )
        });
    }

    const getRestrictionValue = () => {
        if(!option || !option.restrictions || !option.restrictions.min || !option.restrictions.max) {
            return null;
        }
        let { max, min } = option.restrictions;
        return `Select at least ${min} ${min === 1 ? 'item' : 'items'} and no more than ${max} ${max === 1 ? 'item' : 'items'}`;
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setOption(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle() ? `About "${abstract.getTitle()}"` : 'New Interaction Item'}
        dropDown={dropDown}
        utils={utils}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setOption(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSaveChanges
        }]}>

            <AltFieldMapper
            utils={utils}
            fields={getFields()} />

            {option && option.type === 'list' && (
                <>
                <LayerItem
                title={'Customizations'}
                style={{
                    marginTop: 25
                }}
                rightContent={(
                    <Button
                    label={'Add New Item'}
                    type={'small'}
                    color={'dark'}
                    onClick={onAddNewItem}/>
                )}>
                    {getOptionComponents()}
                </LayerItem>
                </>
            )}
        </Layer>
    )
}

export const OrderOptionItemInteractionDetails = ({ channel, layerID, onUpdate }, { abstract, index, options, utils }) => {

    const [interaction, setInteraction] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);

    const onSaveChanges = () => {

        let required = [{
            title: 'title',
            object: interaction.title
        },{
            title: 'interaction type',
            object: interaction.type
        }]

        let remaining = required.filter(entry => !entry.object)
        if(remaining.length > 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please fill out the ${remaining[0].title} before moving on`
            })
            return;
        }

        setLayerState('close');
        if(typeof(onUpdate) === 'function') {
            onUpdate(abstract.object.edits);
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setInteraction(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for this item will be shown when a customer is selecting customizable options. The title should describe what they are selecting. An example for the question "What type of cheese would you like?" would be "Cheddar".',
                value: interaction.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'cost',
                required: false,
                title: 'Cost',
                description: 'A customizable item can have an additional cost associated with it. By default, a customizeable option does not have a cost.',
                value: interaction.cost,
                component: 'textfield',
                onChange: text => onUpdateTarget({ cost: text }),
                props: {
                    format: 'number',
                    prepend: '$'
                }
            },{
                key: 'type',
                title: 'Interaction',
                description: 'The interaction for a customizable item dictates how the customer can make a selection. You can choose either a checkbox to select this item or provide an area where the customer can specify an amount.',
                value: interaction.type,
                component: 'list',
                onChange: item => onUpdateTarget({ type: item && item.id }),
                items: Order.Option.Item.Interaction.types.map(type => ({
                    id: type.key,
                    title: type.title
                }))
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setInteraction(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'Editing Customization Item'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setInteraction(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSaveChanges
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

// utils
export const fetchHostFromToken = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { host } = await Request.get(utils, '/orders/',  {
                type: 'host_from_token',
                ...props
            });
            resolve(host ? Order.Host.create(host) : null);
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchOrderCategories = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/orders/',  {
                type: 'categories_admin',
                ...props
            });
            if(props && props.download) {
                resolve(response);
                return;
            }
            let { categories, paging } = response;
            resolve({
                paging: paging,
                categories: categories.map(category => Order.Category.create(category))
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchOrderChannel = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { channel, download_props } = await Request.get(utils, '/orders/',  {
                type: 'channel',
                ...props
            });
            if(download_props) {
                resolve(download_props);
                return;
            }
            resolve(channel ? Order.Channel.create(channel) : null);
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchOrderChannels = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { channels, download_props, paging } = await Request.get(utils, '/orders/',  {
                type: 'channels',
                ...props
            });
            if(download_props) {
                resolve(download_props);
                return;
            }
            resolve({
                paging: paging,
                channels: channels.map(channel => Order.Channel.create(channel))
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchOrderHosts = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/orders/',  {
                type: 'all_hosts_admin',
                ...props
            });
         
            if(props && props.download) {
                resolve(response);
                return;
            }
            let { hosts, paging } = response;
            resolve({
                paging: paging,
                hosts: hosts.map(host => Order.Host.create(host))
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchOrderOptions = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/orders/', {
                type: 'options_admin',
                ...props
            });
            if(props && props.download) {
                resolve(response);
                return;
            }
            resolve({
                ...response,
                options: response.options.map(o => {
                    return Order.Option.create({
                        ...o,
                        category: Order.Category.create(o.category)
                    })
                })
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchOrderServices = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/orders/',  {
                type: 'services',
                show_inactive: true,
                ...props
            });
            if(props && props.download) {
                resolve(response);
                return;
            }
            resolve({
                paging: response.paging,
                services: response.services.map(service => Service.create(service))
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const renderOrderCart = ({ order, onItemClick }) => {
    const getContent = () => {
        if(!order) {
            return null;
        }
        return (
            order.options.map((item, index) => (
                <div
                key={index}
                className={'text-button'}
                onClick={onItemClick.bind(this, {
                    option: item,
                    isEditing: true
                })}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 15,
                    paddingBottom: 5,
                    marginBottom: 15
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            flexGrow: 1,
                            paddingRight: 12
                        }}>
                            <span style={{
                                display: 'block',
                                fontSize: Appearance.textStyles.title().fontSize,
                                color: Appearance.textStyles.title().color,
                                fontWeight: Appearance.textStyles.title().fontWeight
                            }}>
                                {item.name}
                            </span>
                            <span style={{
                                display: 'block',
                                fontSize: Appearance.textStyles.subTitle().fontSize,
                                color: Appearance.textStyles.subTitle().color,
                                fontWeight: Appearance.textStyles.subTitle().fontWeight,
                                marginTop: 2
                            }}>
                                {item.description}
                            </span>
                        </div>
                        <div style={{
                            width: 50,
                            height: 50,
                            minWidth: 50,
                            minHeight: 50,
                            borderRadius: 8,
                            overflow: 'hidden',
                            border: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            <img
                            src={item.image}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}/>
                        </div>
                    </div>
                    {item.options.length === 0
                        ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingTop: 8,
                            paddingBottom: 8,
                            borderTop: `1px solid ${Appearance.colors.divider()}`,
                            marginTop: 15
                        }}>
                            <span style={Appearance.textStyles.key()}>{'Price'}</span>
                            <span style={Appearance.textStyles.value()}>{Utils.toCurrency(item.cost)}</span>
                        </div>
                        :
                        <div style={{
                            marginTop: 15,
                            borderTop: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingTop: 8,
                                paddingBottom: 8
                            }}>
                                <span style={Appearance.textStyles.key()}>{'Starting Price'}</span>
                                <span style={Appearance.textStyles.value()}>{Utils.toCurrency(item.cost)}</span>
                            </div>
                            {item.getCustomizations().map((entry, index, customizations) => {
                                return (
                                    <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        borderTop: `1px solid ${Appearance.colors.divider()}`
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flewGrow: 1,
                                            flexDirection: 'column'
                                        }}>
                                            {typeof(entry.name) === 'string' && (
                                                <span style={Appearance.textStyles.key()}>{entry.name}</span>
                                            )}
                                            <span style={Appearance.textStyles.subTitle()}>{entry.title}</span>
                                        </div>
                                        <span style={{
                                            ...Appearance.textStyles.value(),
                                            maxWidth: '50%'
                                        }}>{entry.value || Utils.toCurrency(entry.cost)}</span>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            ))
        )
    }

    return getContent();
}
