import moment from 'moment-timezone';
import Note from 'classes/Note.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class NewsChannelClass {

    id = null;
    name = null;
    description = null;
    icon = null;
    added_by = null;
    date = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.description = props.description;
        this.icon = props.icon;
        this.added_by = props.added_by ? User.create(props.added_by) : null;
        this.date = props.date ? moment.utc(props.date).local() : null;
        this.active = Boolean(props.active);
        return this;
    }
}

class NewsCategoryClass {

    id = null;
    title = null;
    description = null;
    channel = null;
    items = null;
    active = null;
    seeds = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.date_added = props.date_added ? moment.utc(props.date_added).local() : null;
        this.channel = props.channel ? new NewsChannelClass().create(props.channel) : null;
        this.items = props.items ? props.items.map(i => new NewsItemClass().create(i)) : null;
        this.active = Boolean(props.active);

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => n.deleted !== true).map(n => Note.create(n))
        }
        return this;
    }

    getItems = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { items } = await Request.get(utils, '/news/', {
                    type: 'items',
                    category_id: this.id,
                    news_channel: this.channel.id
                });
                resolve({ items: items.map(item => new NewsItemClass().create(item)) });
            } catch(e) {
                reject(e);
            }
        });
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            description: this.description
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            title: props.title || this.edits.title,
            description: props.description || this.edits.description
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/news/', {
                    type: 'new_category',
                    title: edits.title,
                    description: edits.description,
                    news_channel: this.channel.id
                });

                this.id = id;
                this.title = edits.title;
                this.description = edits.description;

                utils.content.fetch('newsCategories');
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/news/', {
                    type: 'update_category',
                    id: this.id,
                    title: edits.title,
                    description: edits.description,
                    news_channel: this.channel.id
                });

                this.title = edits.title;
                this.description = edits.description;

                utils.content.update({
                    type: 'newsCategories',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

class NewsItemClass {

    active = null;
    category = null;
    channel = null;
    content = null;
    copy_id = null;
    description = null;
    featured = null;
    id = null;
    image = null;
    seeds = {};
    title = null;
    url = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.category = props.category && new NewsCategoryClass().create(props.category);
        this.channel = props.channel && new NewsChannelClass().create(props.channel);
        this.content = props.content;
        this.copy_id = `${props.id}-${moment().unix()}`;
        this.date = props.date && moment.utc(props.date).local();
        this.description = props.description;
        this.featured = props.featured;
        this.id = props.id;
        this.image = props.image;
        this.title = props.title;
        this.url = props.url;

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => n.deleted !== true).map(n => Note.create(n))
        }
        return this;
    }

    copy = (props = {}) => {
        this.id = props.id;
        this.copy_id = `${props.id}-${moment().unix()}`;
        this.title = props.title;
        this.description = props.description;
        this.channel = props.channel;
        this.category = props.category;
        this.url = props.url;
        this.content = props.content;
        this.image = props.image;
        this.featured = props.featured;
        this.start_date = props.start_date;
        this.end_date = props.end_date;
        this.active = props.active;
        this.seeds = props.seeds;
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            description: this.description,
            category: this.category,
            url: this.url,
            content: this.content,
            featured: this.featured || false,
            start_date: this.start_date,
            end_date: this.end_date,
            image: this.image
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            title: props.title || this.edits.title,
            description: props.description || this.edits.description,
            category: props.category !== undefined ? props.category : this.edits.category,
            url: props.url || this.edits.url,
            content: props.content || this.edits.content,
            start_date: props.start_date || this.edits.start_date,
            end_date: props.end_date || this.edits.end_date,
            featured: props.featured !== undefined ? props.featured : this.edits.featured,
            image: props.image || this.edits.image
        }
        if(props.image && props.image.data) {
            this.edits.tmp_image = props.image;
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id, image } = await Request.post(utils, '/news/', {
                    type: 'new_item',
                    title: edits.title,
                    description: edits.description,
                    news_channel: this.channel.id,
                    category: edits.category ? edits.category.id : null,
                    url: edits.url,
                    featured: edits.featured,
                    image: edits.tmp_image
                });

                this.id = id;
                this.title = edits.title;
                this.description = edits.description;
                this.category = edits.category;
                this.url = edits.url;
                this.content = edits.content;
                this.featured = edits.featured;
                this.start_date = edits.start_date;
                this.end_date = edits.end_date;
                this.image = image;

                utils.content.fetch('newsItems');
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { image } = await Request.post(utils, '/news/', {
                    type: 'update_item',
                    id: this.id,
                    title: edits.title,
                    description: edits.description,
                    news_channel: this.channel.id,
                    category: edits.category ? edits.category.id : null,
                    url: edits.url,
                    featured: edits.featured,
                    image: edits.tmp_image
                });

                this.title = edits.title;
                this.description = edits.description;
                this.category = edits.category;
                this.url = edits.url;
                this.content = edits.content;
                this.featured = edits.featured;
                this.start_date = edits.start_date;
                this.end_date = edits.end_date;
                this.image = image || this.image;

                utils.content.update({
                    type: 'newsItems',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }
}

const fetchCategory = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { category } = await Request.get(utils, '/news/', {
                type: 'category_details',
                id: id
            });
            let target = new NewsCategoryClass().create(category);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchItem = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { item } = await Request.get(utils, '/news/', {
                type: 'item_details',
                id: id
            });
            let target = new NewsItemClass().create(item);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    Category: {
        new: () => new NewsCategoryClass(),
        get: fetchCategory,
        create: props => new NewsCategoryClass().create(props)
    },
    Channel: {
        new: () => new NewsChannelClass(),
        create: props => new NewsChannelClass().create(props)
    },
    Item: {
        new: () => new NewsItemClass(),
        get: fetchItem,
        create: props => new NewsItemClass().create(props)
    }
};
