import React, { useEffect, useRef, useState } from 'react';

import { fetchCompanies } from 'managers/Companies.js';
import { fetchOrderChannels } from 'managers/Orders.js';
import { fetchVehicleCategories } from 'managers/Vehicles.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import AutoApprove from 'classes/AutoApprove.js';
import BoolToggle from 'views/BoolToggle.js';
import Button from 'views/Button.js';
import { Calendar, MobileCalendar } from 'views/Calendar.js';
import Company from 'classes/Company.js';
import CreditsManager from 'views/CreditsManager.js';
import CustomSlider from 'views/CustomSlider.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import Driver from 'classes/Driver.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import EventManager from 'views/EventManager.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerEmissions, LayerItem, LayerMap, LayerNote } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import { Map } from 'views/MapElements.js';
import Mood from 'classes/Mood.js';
import MultipleAddressLookupField from 'views/MultipleAddressLookupField.js';
import NoDataFound from 'views/NoDataFound.js';
import NumberStepper from 'views/NumberStepper.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Payment from 'classes/Payment.js';
import PaymentMethodManager from 'views/PaymentMethodManager.js';
import PreauthorizationManager from 'views/PreauthorizationManager.js';
import PremiumLocation from 'classes/PremiumLocation.js';
import PromoCodeLookupField from 'views/PromoCodeLookupField.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import ReservationGuideline from 'classes/ReservationGuideline.js';
import { ReservationMessages } from 'managers/Messages.js';
import RestrictedDateTime from 'classes/RestrictedDateTime.js';
import Service from 'classes/Service.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Valhalla from 'classes/Valhalla.js';
import Vehicle from 'classes/Vehicle.js';
import Views from 'views/Main.js';

// panels
export const ActiveReservations = ({ index, utils }) => {

    const panelID = 'activeReservations';
    const annotationsRef = useRef([]);
    const limit = 5;

    const [annotations, setAnnotations] = useState([]);
    const [loading, setLoading] = useLoading();
    const [followProps, setFollowProps] = useState(null);
    const [manager, setManager] = useResultsManager();
    const [overlays, setOverlays] = useState([]);
    const [paging, setPaging] = useState(null);
    const [reservations, setReservations] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Active Reservations',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onCreateDriver = async (driverID, location, reservationID) => {
        try {
            // check if driver entry has already been created
            let index = annotationsRef.current.findIndex(annotation => annotation.data.key === `reservation-${reservationID}`);
            if(index >= 0) {
                return;
            }

            // fetch driver details and update annotation
            let driver = await Driver.get(utils, driverID);
            setAnnotations(annotations => {
                return annotations.concat([
                    getAnnotationForDriver({ driver, location, id: reservationID })
                ]);
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/reservations/', {
                    type: 'all_admin',
                    category: 'active',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onFollowDriver = reservation => {
        let isSet = isDriverLocationSet(reservation);
        if(!isSet) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like the location for this driver is not available at this time. We normally receive a location from the driver every 15 seconds. Please check back later for an updated location.'
            });
            return
        }
        setFollowProps({ key: `reservation-${reservation.id}` });
    }

    const onRemoveReservationSocketListeners = reservations => {
        reservations.forEach(async reservation => {
            try {
                await utils.sockets.off('seeds', `on_reservation_location_update_${reservation.id}`, onUpdateLocation);
            } catch(e) {
                console.error(e.message);
            }
        });
    }

    const onReservationClick = reservation => {
        utils.sheet.show({
            title: 'Active Reservation Options',
            message: 'What would you like to do with this Reservation?',
            items: [{
                key: 'driver',
                title: 'Follow Driver',
                style: 'default'
            },{
                key: 'view',
                title: 'View Reservation',
                style: 'default'
            }]
        }, key => {
            if(key === 'view') {
                Utils.reservations.details(utils, reservation);
                return;
            }
            if(key === 'driver') {
                onFollowDriver(reservation);
                return
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onSetReservationSocketListeners = () => {
        reservations.forEach(async reservation => {
            try {
                await utils.sockets.on('seeds', `on_reservation_location_update_${reservation.id}`, onUpdateLocation);
            } catch(e) {
                console.error(e.message);
            }
        });
    }

    const onUpdateLocation = async data => {
        try {
            let { driver_id, location, reservation_id } = JSON.parse(data);
            onCreateDriver(driver_id, location, reservation_id);
            setReservations(reservations => {
                if(!reservations) {
                    return reservations;
                }
                let index = reservations.findIndex(res => res.id === reservation_id);
                if(index >= 0) {
                    reservations[index].location = {
                        ...reservations[index].location,
                        location: location
                    };
                }
                return reservations;
            });
            setAnnotations(annotations => {
                let index = annotations.findIndex(annotation => annotation.data.key === `reservation-${reservation_id}`);
                if(index < 0) {
                    return annotations;
                }
                return update(annotations, {
                    [index]: {
                        location: {
                            $set: {
                                heading: location.heading,
                                latitude: location.lat,
                                longitude: location.long,
                                speed: location.speed
                            }
                        }
                    }
                });
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const isDriverLocationSet = reservation => {
        return annotations.find(annotation => annotation.data.key === `reservation-${reservation.id}`) ? true : false;
    }

    const getAnnotationForDriver = ({ driver, id, location }) => ({
        data: { key: `reservation-${id}` },
        icon: {
            path: driver.avatar,
            style: {
                borderRadius: '50%',
                border: '2px solid white',
                height: 35,
                offset: {
                    offsetTop: -17.5,
                    offsetLeft: -17.5
                },
                width: 35
            }
        },
        location: {
            heading: location.heading,
            latitude: location.lat || location.latitude,
            longitude: location.long || location.longitude,
            speed: location.speed
        },
        supportingTitle: `Reservation #${id}`,
        subTitle: location.time_to_arrival ? `Arriving in ${Utils.parseDuration(location.time_to_arrival)}` : 'Waiting on estimated arrival...',
        title: driver.full_name
    })

    const getAssistProps = () => {
        return {
            message: 'This map shows the routes for all the currently active reservations. Driver locations and directional vehicles are available where applicable',
            items: [{
                key: 'download',
                title: 'Download Reservations',
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        if(!followProps) {
            return null;
        }
        return [{
            key: 'reset',
            title: 'Stop Following',
            style: 'primary',
            onClick: () => {
                setFollowProps(null);
                setAnnotations([ ...annotations ]);
            }
        }]
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(reservations.length === 0) {
            return (
                Views.entry({
                    title: 'No Reservations Found',
                    subTitle: 'There are no active reservations available to view',
                    bottomBorder: false
                })
            )
        }
        return (
            <div style={{
                display: 'block',
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                {reservations.map((reservation, index) => {
                    return (
                        Views.entry({
                            key: reservation.id,
                            title: `${reservation.customer.full_name} (${reservation.id})`,
                            subTitle: `Heading to ${reservation.status.code === Reservation.status.to_destination ? reservation.destination.address : reservation.origin.address}`,
                            icon: {
                                style: Appearance.icons.standard(),
                                path: reservation.customer.avatar
                            },
                            badge: [{
                                text: isDriverLocationSet(reservation) ? 'Driver Location' : null,
                                color: Appearance.colors.darkBlue
                            },{
                                text: reservation.status.code === Reservation.status.to_destination ? 'To Destination' : 'To Pickup',
                                color: Appearance.colors.blue
                            }],
                            bottomBorder: index !== reservations.length - 1,
                            onClick: onReservationClick.bind(this, reservation)
                        })
                    )
                })}
            </div>
        )
    }

    const getMap = () => {
        if(loading === 'init' || reservations.length === 0) {
            return null;
        }
        return (
            <div style={{
                padding: 12,
                width: '100%'
            }}>
                <Map
                utils={utils}
                center={window.userLocation}
                showUserLocation={false}
                isZoomEnabled={true}
                isRotationEnabled={true}
                isScrollEnabled={Utils.isMobile() ? false : true}
                follow={followProps}
                annotations={annotations}
                overlays={overlays}
                overlayHoverColor={true}
                style={{
                    height: 500
                }}/>
            </div>
        )
    }

    const getSearchField = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div style={{
                width: '100%',
                padding: 12,
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                <TextField
                icon={'search'}
                useDelay={true}
                placeholder={'Search for customer or id...'}
                onChange={onSearchTextChange} />
            </div>
        )
    }

    const fetchReservations = async () => {
        try {
            let { paging, reservations } = await Request.get(utils, '/reservations/',  {
                type: 'all_admin',
                category: 'active',
                limit: limit,
                ...manager
            });

            // prepare reservation targets
            let targets = reservations.map(reservation => Reservation.create(reservation));
            setLoading(false);
            setPaging(paging);

            // loop through current reservations and remove listeners
            setReservations(reservations => {
                onRemoveReservationSocketListeners(reservations);
                return targets;
            });

            // prepare overlays
            setOverlays(targets.reduce((array, reservation) => {
                if(reservation.polyline) {
                    array.push({
                        id: `overlay-${reservation.id}`,
                        data_key: reservation.id,
                        coordinates: reservation.polyline
                    });
                }
                return array;
            }, []));

            // prepare annotations
            setAnnotations(targets.reduce((array, reservation) => {

                // prepare default annotations for reservation
                let annotations = [{
                    title: `Pickup Location (${reservation.id})`,
                    subTitle: reservation.origin.address,
                    location: reservation.origin.location,
                    data: { key: `pickup-${reservation.id}` },
                    icon: { 
                        color: reservation.status.code === Reservation.status.to_pickup ? Appearance.colors.blue : Appearance.colors.grey(),
                        type: 'broadcast'
                    }
                },{
                    title: `Drop Off Location (${reservation.id})`,
                    subTitle: reservation.destination.address,
                    location: reservation.destination.location,
                    data: { key: `drop-off-${reservation.id}` },
                    icon: { 
                        color: reservation.status.code === Reservation.status.to_destination ? Appearance.colors.blue : Appearance.colors.grey(),
                        type: 'broadcast'
                    }
                }];

                // determine if a driver annotation should be created
                if(reservation.location) {
                    annotations.push(getAnnotationForDriver({
                        ...reservation.location,
                        id: reservation.id
                    }));
                }

                // return pickup and drop-off annotations
                return array.concat(annotations);

            }, []));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the active reservations. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        annotationsRef.current = annotations || [];
    }, [annotations]);

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchReservations();
    }, [manager]);

    useEffect(() => {
        onSetReservationSocketListeners();
    }, [reservations]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchReservations,
            onUpdate: abstract => {
                setReservations(reservations => {
                    return reservations.map(reservation => {
                        return abstract.compare(reservation);
                    }).filter(reservation => {
                        return [ Reservation.status.to_pickup, Reservation.status.to_destination ].includes(reservation.status.code);
                    });
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
            setReservations(reservations => {
                onRemoveReservationSocketListeners(reservations);
                return reservations;
            });
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Active Reservations'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: getButtons(),
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getMap()}
            {getSearchField()}
            {getContent()}
        </Panel>
    )
}

export const NewReservation = ({ index, utils }) => {

    const panelID = 'reservationsNewReservation';
    const [annotations, setAnnotations] = useState([]);
    const [annotationValues, setAnnotationValues] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [destination, setDestination] = useState(null);
    const [loading, setLoading] = useState(false);
    const [origin, setOrigin] = useState(null);
    const [overlays, setOverlays] = useState([]);
    const [pickupDate, setPickupDate] = useState(moment().add(1, 'days'));
    const [service, setService] = useState(null);

    const onCalculateRoute = async () => {
        try {
            if(annotations.length < 2) {
                return;
            }
            let route = await Valhalla.getRoute(utils, annotations);
            setOverlays([{
                key: 'new-reservation-overlay',
                coordinates: route.polyline
            }]);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating the route for this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onConfirmRide = async () => {
        if(!origin) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please choose a pickup location for this reservation'
            })
            return;
        }
        if(!customer) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please choose a customer for this reservation'
            })
            return;
        }
        if(!pickupDate) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please choose a pickup time and date for this reservation'
            })
            return;
        }

        let reservation = Reservation.new();
        reservation.tempID = moment().unix();
        reservation.user_id = customer.user_id;
        reservation.customer = customer;
        reservation.company = customer.company;
        reservation.pickup_date = pickupDate;
        reservation.passengers = 1;
        reservation.luggage = 0;
        reservation.payment_method = customer.payment_methods ? customer.payment_methods[0] : null;
        reservation.origin = origin
        reservation.destination = destination;

        if(utils.user.get().level > User.level.admin) {
            reservation.company = utils.user.get().company
        }

        utils.layer.open({
            id: `new-reservation-confirm-${reservation.tempID}`,
            abstract: Abstract.create({
                type: 'reservations',
                object: reservation
            }),
            Component: AddEditReservation.bind(this, {
                isNewTarget: true,
                onRideSubmit: () => {
                    setLoading(false);
                    setOverlays([]);
                    setAnnotations([]);
                    setOrigin(null);
                    setDestination(null);
                    setCustomer(null);
                    setPickupDate(moment());
                    setService(null);
                }
            })
        });
    }

    const onUpdateAnnotations = async props => {
        setAnnotations(annotations => {
            let targets = annotations.filter(annotation => {
                return annotation.id !== props.id;
            });
            return update(targets, {
                $push: [{
                    ...props,
                    icon: { type: 'broadcast' }
                }]
            });
        });
    }

    const getAssistProps = () => {
        return {
            message: 'Start booking your next ride with eCarra. Choose your pickup location to get started'
        }
    }

    const fetchDefaultService = async () => {
        try {
            let { services } = await fetchServices(utils);
            setService(services.find(service => service.default_for_channel));
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        onCalculateRoute();
        setAnnotationValues(annotations && Object.values(annotations));
    }, [annotations]);

    useEffect(() => {
        fetchDefaultService();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Book a Ride'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            <div className={'row p-2 m-0'}>
                <div
                className={'col-12 col-lg-6 col-xl-7'}
                style={{
                    padding: 4
                }}>
                    <Map
                    utils={utils}
                    isZoomEnabled={true}
                    isScrollEnabled={Utils.isMobile() ? false : true}
                    overlays={overlays}
                    annotations={annotationValues}
                    style={{
                        height: 335
                    }}/>
                </div>
                <div className={'col-12 col-lg-6 col-xl-5 p-0 pr-lg-1 pl-lg-3 pt-lg-1'}>
                    <span
                    className={'d-block mt-3 mt-lg-0 mb-1'}
                    style={Appearance.textStyles.title()}>{'Pickup'}</span>
                    <AddressLookupField
                    utils={utils}
                    inline={true}
                    value={origin}
                    containerStyle={{
                        marginTop: 4
                    }}
                    onChange={place => {
                        setOrigin(place);
                        onUpdateAnnotations({
                            id: 'origin',
                            title: place.name,
                            subTitle: place.address,
                            location: place.location
                        })
                    }} />

                    <span
                    className={'d-block mt-3 mb-1'}
                    style={Appearance.textStyles.title()}>{'Drop-Off'}</span>
                    <AddressLookupField
                    utils={utils}
                    inline={true}
                    value={destination}
                    containerStyle={{
                        marginTop: 4
                    }}
                    onChange={place => {
                        setDestination(place);
                        onUpdateAnnotations({
                            id: 'destination',
                            title: place.name,
                            subTitle: place.address,
                            location: place.location
                        })
                    }} />

                    <span
                    className={'d-block mt-3 mb-1'}
                    style={Appearance.textStyles.title()}>{'Customer'}</span>
                    <UserLookupField
                    utils={utils}
                    user={customer}
                    inline={true}
                    onChange={user => setCustomer(user)}
                    containerStyle={{
                        marginTop: 4
                    }}/>

                    <span
                    className={'d-block mt-3 mb-1'}
                    style={Appearance.textStyles.title()}>{'Pickup Date and Time'}</span>
                    <DateDurationPickerField
                    utils={utils}
                    selected={pickupDate}
                    placeholder={'Date'}
                    onChange={date => setPickupDate(date)}
                    style={{
                        width: '100%'
                    }} />

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: 15
                    }}>
                        <Button
                        type={'large'}
                        label={'Confirm Reservation'}
                        onClick={onConfirmRide} />
                    </div>
                </div>
            </div>
        </Panel>
    )
}

export const OnDemandBroadcasts = ({ index, utils }) => {

    const panelID = 'onDemandBroadcasts';
    const filter = useRef(null);
    const offset = useRef(0);
    const limit = 10;

    const [broadcast, setBroadcast] = useState(null);
    const [broadcasts, setBroadcasts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onReservationClick = async id => {
        try {
            setLoading('reservation');
            let reservation = await Reservation.get(utils, id);

            setLoading(false);
            Utils.reservations.details(utils, reservation);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = async userID => {
        try {
            setLoading('driver');
            let user = await User.get(utils, userID);

            setLoading(false);
            Utils.users.details(utils, user);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getAnnotations = () => {

        // no annotations are required if a selected broadcast has not been set
        if(!broadcast) {
            return [];
        }

        // prepare annotations with pickup location as the first annoration
        let items = [{
            icon: { 
                color: Appearance.colors.blue,
                type: 'broadcast' 
            },
            id: 'pickup_location',
            name: 'Pickup Location',
            location: {
                latitude: broadcast.location.geometry.coordinates[1],
                longitude: broadcast.location.geometry.coordinates[0]
            }
        }];

        // determine if driver locations need to be formatted
        if(broadcast.data && broadcast.data.radius_groups) {
            broadcast.data.radius_groups.forEach(group => {
                group.targets.forEach(target => {
                    items.push({
                        id: `driver-${target.user_id}`,
                        name: `Driver #${target.user_id}`,
                        location: {
                            heading: target.location.heading || 1,
                            latitude: target.location.lat,
                            longitude: target.location.long
                        }
                    });
                });
            });
        }
        return items;
    }

    const getAssistProps = () => {
        return {
            message: 'This map shows the on-demand broadcasts sent out by the system when a customer submits a request for an on-demand ride. The blue location icon represents the customer pickup location. The blue circle overlay represents the area (generated using your maximum broadcast radius) used when looking for eligible radius groups. A grey circle overlay represents a radius group that was notified during the broadcast. A vehicle icon represents a driver that was notified during this on-demand broadcast.'
        }
    }

    const getBroadcastDetails = () => {
        return broadcast && (
            <>
            {broadcast.driver && (
                <LayerItem title={'Driver'}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: { path: broadcast.driver.avatar },
                        loading: loading === 'driver',
                        onClick: onUserClick.bind(this, broadcast.driver.user_id),
                        subTitle: broadcast.driver.email_address,
                        title: broadcast.driver.full_name
                    })}
                </LayerItem>
            )}
            {broadcast.user && (
                <LayerItem title={'Reservation'}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: { path: broadcast.user.avatar },
                        loading: loading === 'reservation',
                        onClick: onReservationClick.bind(this, broadcast.reservation_id),
                        subTitle: 'Immediate Pickup',
                        title: `${broadcast.user.full_name} (${broadcast.reservation_id})`
                    })}
                </LayerItem>
            )}
            <FieldMapper
            fields={getFields()}
            utils={utils} />
            </>
        )
    }

    const getCircles = () => {

        // no circles are required if a selected broadcast has not been set
        if(!broadcast || !broadcast.data || !broadcast.data.radius_groups) {
            return [];
        }

        // add circle for pickup location 
        let items = [{
            color: Appearance.colors.blue,
            coordinate: {
                latitude: broadcast.location.geometry.coordinates[1],
                longitude: broadcast.location.geometry.coordinates[0]
            },
            id: 'pickup-location',
            opacity: 0.15,
            radius: broadcast.radius
        }];

        // add circle for the broadcast center of each radius group
        broadcast.data.radius_groups.forEach((group, index) => {
            items.push({
                color: Appearance.colors.grey(),
                coordinate: group.location,
                id: `broadcast-group-${index}`,
                radius: group.radius
            });
        });
        return items
    }

    const getContent = () => {
        if(broadcasts.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    icon: {
                        path: 'images/on-demand-broadcast-icon-clear.png',
                        style: {
                            backgroundColor: Appearance.colors.grey(),
                            borderRadius: '50%'
                        }
                    },
                    subTitle: 'No on-demand broadcast records are available to view',
                    title: 'No Broadcasts Found'
                })
            )
        }
        return broadcasts.map((broadcast, index) => {

            let groups = broadcast.data && broadcast.data.radius_groups || [];
            let avatar = broadcast.user && broadcast.user.avatar;
            return (
                Views.entry({
                    badge: {
                        color: Appearance.colors.red,
                        text: broadcast.accepted_by_user_id ? null : 'Ride Missed'
                    },
                    bottomBorder: index !== broadcasts.length - 1,
                    icon: {
                        path: avatar || 'images/on-demand-broadcast-icon-clear.png',
                        style: {
                            backgroundColor: groups.length > 0 ? Appearance.colors.blue : Appearance.colors.grey(),
                            borderRadius: '50%'
                        }
                    },
                    key: index,
                    onClick: setBroadcast.bind(this, broadcast),
                    subTitle: Utils.formatDate(broadcast.date),
                    title: `${broadcast.user ? broadcast.user.full_name : 'Customer Name Not Available'} (${broadcast.reservation_id})`
                })
            )
        });
    }

    const getFields = () => {
        let groups = broadcast.data && broadcast.data.radius_groups && broadcast.data.radius_groups || [];
        return [{
            key: 'details',
            lastItem: true,
            title: 'About this Broadcast',
            items: [{
                key: 'date',
                title: 'Date Submitted',
                value: Utils.formatDate(broadcast.date)
            },{
                key: 'status',
                title: 'Status',
                value: broadcast.accepted_by_user_id ? 'Accepted' : 'Missed'
            },{
                key: 'broadcast_duration',
                title: 'Broadcast Radius',
                value: Utils.distanceConversion(broadcast.radius)
            },{
                key: 'broadcast_duration',
                title: 'Broadcast Duration',
                value: Utils.parseDuration(broadcast.duration)
            },{
                key: 'radius_groups',
                title: 'Targeted Radius Groups',
                value: groups.length > 0 ? Utils.oxfordImplode(groups.map(group => group.name)) : 'None'
            },{
                key: 'drivers',
                title: 'Targeted Drivers',
                value: `${broadcast.driver_count} ${broadcast.driver_count === 1 ? 'driver' : 'drivers'}`
            },{
                key: 'duration',
                title: 'Estimated Time to Pickup',
                value: broadcast.routing && Utils.parseDuration(broadcast.routing.duration) || 'Unknown',
                visible: broadcast.routing ? true : false
            },{
                key: 'distance',
                title: 'Estimated Distance to Pickup',
                value: broadcast.routing && Utils.distanceConversion(broadcast.routing.distance) || 'Unknown',
                visible: broadcast.routing ? true : false
            }]
        }];
    }

    const getOverlays = () => {
        return broadcast && broadcast.routing && [{
            id: 'estimated-route',
            coordinates: broadcast.routing.polyline
        }];
    }

    const fetchBroadcasts = async () => {
        try {

            setLoading(true);
            let { broadcasts, paging } = await Request.get(utils, '/reservations/', {
                filter: filter.current,
                limit: limit,
                offset: offset.current,
                search_text: searchText,
                type: 'on_demand_broadcasts'
            });

            setLoading(false);
            setPaging(paging);
            setBroadcast(broadcasts.length > 0 ? broadcasts[0] : null);
            setBroadcasts(broadcasts.map(broadcast => {
                if(broadcast.routing && broadcast.routing.polyline) {
                    broadcast.routing.polyline = Utils.decodePolyline(broadcast.routing.polyline, 6);
                }
                return broadcast;
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of on-demand broadcasts. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchBroadcasts();
    }, [searchText]);

    return (
        <Panel
        name={'On-Demand Broadcasts'}
        index={index}
        panelID={panelID}
        utils={utils}
        options={{
            assist: { props: getAssistProps() },
            loading: loading,
            removePadding: true,
            paging: paging && {
                limit: limit,
                offset: offset.current,
                description: paging,
                onClick: next => {
                    offset.current = next;
                    fetchBroadcasts();
                }
            }
        }}>
            <div 
            className={'row m-0'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 15
            }}>
                <div className={`col-12 col-lg-6 col-xl-8 p-0`}>
                    <Map
                    annotations={getAnnotations()}
                    autoViewportUpdates={false}
                    circles={getCircles()}
                    isRotationEnabled={true}
                    isScrollEnabled={true}
                    isZoomEnabled={true}
                    overlays={getOverlays()}
                    showUserLocation={false}
                    utils={utils}
                    style={{
                        height: 527
                    }}/>
                </div>
                <div className={'col-12 col-lg-6 col-xl-4 px-0 pb-1 pt-3 pl-lg-3 pt-lg-0 pr-lg-0 pb-lg-0'}>
                    {getBroadcastDetails()}
                </div>
            </div>
            <div 
            className={'row m-0'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 12,
                width: '100%'
            }}>
                <div className={'col-12 col-lg-10 p-0 pr-lg-2'}>
                    <TextField
                    icon={'search'}
                    onChange={setSearchText}
                    placeholder={'Search by customer user id, driver user id, or reservation id...'}
                    useDelay={true} />
                </div>
                <div className={'col-12 col-lg-2 px-0 pt-2 pb-0 p-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    onChange={evt => {
                        filter.current = Utils.attributeForKey.select(evt, 'id');
                        fetchBroadcasts();
                    }}>
                        <option>{'All Broadcasts'}</option>
                        <option id={'accepted'}>{'Accepted Rides'}</option>
                        <option id={'missed'}>{'Missed Rides'}</option>
                    </select>
                </div>
            </div>
            {getContent()}
        </Panel>
    )
}

export const ReservationAutoApprovals = ({ index, utils }) => {

    const panelID = 'automaticApprovals';
    const [autoApprovals, setAutoApprovals] = useState(null);
    const [loading, setLoading] = useLoading();
    const [options, setOptions] = useState([]);
    const [services, setServices] = useState([]);

    const onAssistClick = key => {
        if(key === 'edit') {
            onEditApprovals();
            return;
        }
    }

    const onEditApprovals = () => {
        utils.layer.open({
            id: 'edit-reservation-auto-approvals',
            Component: EditReservationAutoApprovals.bind(this, {
                approvals: autoApprovals
            })
        });
    }

    const getAssistProps = () => {
        return {
            message: 'The eCarra Platform contains a feature that allows reservations to skip the pending queue and go straight to the approved rides queue. This auto approval feature applies to newly submitted reservations and reservations that have been edited by the customer',
            items: [{
                key: 'edit',
                title: 'Edit Automatic Approvals',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(!autoApprovals) {
            return (
                Views.entry({
                    title: 'No Auto Approval Preferences Found',
                    subTitle: 'We were unable to retrieve your auto approval preferences',
                    bottomBorder: false
                })
            )
        }
        return getItems().map((item, index, items) => {
            return (
                Views.entry({
                    key: index,
                    title: item.title,
                    subTitle: item.value,
                    icon: item.icon,
                    bottomBorder: index !== items.length - 1,
                    onClick: onEditApprovals
                })
            )
        })
    }

    const getItems = () => {
        return [{
            key: 'enabled',
            title: 'Enabled',
            value: autoApprovals.enabled ? 'Enabled' : 'Not Enabled',
            icon: {
                path: autoApprovals.enabled ? 'images/checkmark-green.png' : 'images/red-x-icon.png'
            }
        },{
            key: 'analyze_vehicles',
            title: 'Analyze Vehicle Inventory',
            value: autoApprovals.analyze_vehicles ? 'Enabled' : 'Not Enabled',
            icon: {
                path: 'images/approvals-analyze-vehicles-icon-clear.png',
                style: {
                    backgroundColor: autoApprovals.analyze_vehicles ? Appearance.colors.green : Appearance.colors.grey()
                }
            }
        },{
            key: 'buffer',
            title: 'Buffer',
            description: `Automatic approvals will approve or decline a reservation based on the service selected for the ride. You can choose as many or as few services as you would like.`,
            value: autoApprovals.buffer ? `${autoApprovals.buffer.duration} ${autoApprovals.buffer.type}` : null,
            icon: {
                path: 'images/approvals-buffer-icon-clear.png',
                style: {
                    backgroundColor: autoApprovals.buffer ? Appearance.colors.green : Appearance.colors.grey()
                }
            }
        },{
            key: 'customer_edits',
            title: 'Customer Changes',
            value: autoApprovals.customer_edits ? 'Enabled' : 'Not Enabled',
            icon: {
                path: 'images/approvals-customer-edits-icon-clear.png',
                style: {
                    backgroundColor: autoApprovals.customer_edits ? Appearance.colors.green : Appearance.colors.grey()
                }
            }
        },{
            key: 'reservations',
            title: 'New Bookings',
            value: autoApprovals.reservations ? 'Enabled' : 'Not Enabled',
            icon: {
                path: 'images/approvals-reservations-icon-clear.png',
                style: {
                    backgroundColor: autoApprovals.reservations ? Appearance.colors.green : Appearance.colors.grey()
                }
            }
        },{
            key: 'same_day',
            title: 'Same Day Bookings',
            value: autoApprovals.same_day ? 'Enabled' : 'Not Enabled',
            icon: {
                path: 'images/approvals-same-day-icon-clear.png',
                style: {
                    backgroundColor: autoApprovals.same_day ? Appearance.colors.green : Appearance.colors.grey()
                }
            }
        },{
            key: 'services',
            title: 'Services',
            value: autoApprovals.services && Utils.oxfordImplode(services.filter(service => {
                return autoApprovals.services.includes(service.id);
            }).map(service => service.name)),
            icon: {
                path: 'images/approvals-services-icon-clear.png',
                style: {
                    backgroundColor: autoApprovals.services && autoApprovals.services.length > 0 ? Appearance.colors.green : Appearance.colors.grey()
                }
            },
        }]
    }

    const fetchParameters = async () => {
        try {
            let { parameters } = await Request.get(utils, '/reservations/', {
                type: 'auto_approval_parameters'
            });
            setLoading(false);
            setAutoApprovals(AutoApprove.create(parameters));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the auto approval parameters. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTargets = async () => {
        try {
            let { services } = await fetchServices(utils);
            setServices(services);
            fetchParameters();
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }
    useEffect(() => {
        setupTargets();
    }, []);

    useEffect(() => {
        setOptions(services.map(service => {
            return {
                key: service.id,
                label: service.name,
                selected: autoApprovals ? autoApprovals.services.includes(service.id) : false
            }
        }));
    }, [services, autoApprovals]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'autoApprovals', {
            onFetch: autoApprovals
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Automatic Approval Preferences'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationCalendar = ({ index, options, utils }) => {

    const panelID = 'reservationCalendar';
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ calendar_style: Utils.isMobile() ? 'month' : 'week', calendar_offset: 0 });
    const [monthDateFilter, setMonthDateFilter] = useState(moment());
    const [windowState, setWindowState] = useState(null);

    const onAssistClick = key => {
        if(key === 'toggle') {
            setLoading(true);
            setManager('calendar_style', manager.calendar_style === 'week' ? 'month' : 'week');
            return;
        }
        if(key === 'next-week' || key === 'last-week') {
            setLoading(true);
            onChangeCalendarOffset(key === 'next-week' ? 1 : -1);
            return;
        }
        if(key === 'next-month' || key === 'last-month') {
            setLoading(true);
            onChangeCalendarOffset(key === 'next-month' ? 1 : -1);
            return;
        }
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Reservations Calendar',
                dates: getDates(),
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onChangeCalendarOffset = val => {
        setLoading(true);
        setManager('calendar_offset', manager.calendar_offset + val);
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/reservations/', {
                    type: 'calendar',
                    ...formatResults(utils, props => ({
                        ...props,
                        ...getDates()
                    })),
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'Your Reservation Calendar includes all applicable reservations sorted by their pickup date and time. You can adjust the calendar to show either a weekly view or a month styled view',
            items: [{
                key: 'download',
                title: 'Download',
                style: 'default'
            },{
                key: 'toggle',
                title: `Switch to ${manager.calendar_style === 'week' ? 'Month' : 'Week'} View`,
                visible: !Utils.isMobile(),
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        if(manager.calendar_style !== 'week') {
            return [{
                key: 'previous',
                title: 'Previous Month',
                style: 'secondary',
                onClick: onChangeCalendarOffset.bind(this, -1)
            },{
                key: 'next',
                title: 'Next Month',
                style: 'primary',
                onClick: onChangeCalendarOffset.bind(this, 1)
            }]
        }
        return [{
            key: 'previous',
            title: 'Previous Week',
            style: 'secondary',
            onClick: onChangeCalendarOffset.bind(this, -1)
        },{
            key: 'next',
            title: 'Next Week',
            style: 'primary',
            onClick: onChangeCalendarOffset.bind(this, 1)
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(manager.calendar_style === 'month') {
            let targets = events.filter(({ reservation }) => {
                return moment(reservation.pickup_date).isSame(monthDateFilter, 'day');
            });
            return (
                <div style={{
                    padding: 0,
                    position: 'relative'
                }}>
                    <MobileCalendar
                    activeDate={moment().startOf('month').add(manager.calendar_offset, 'months').format('YYYY-MM-DD')}
                    events={events}
                    utils={utils}
                    channel={'reservations'}
                    eventType={'reservations'}
                    showOverflow={windowState === 'fullscreen'}
                    eventFilter={(day, { reservation }) => day.isSame(reservation.pickup_date, 'day')}
                    onClick={reservation => Utils.reservations.details(utils, reservation)}
                    onDateChange={date => setMonthDateFilter(date)} />
                    {events.filter(({ reservation }) => {
                        return reservation.pickup_date.isSame(monthDateFilter, 'day');
                    }).length > 0 && (
                        <div style={{
                            borderTop: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            {targets.map(({ reservation }, index, reservations) => {
                                return (
                                    Views.entry({
                                        key: reservation.id,
                                        title: reservation.customer.full_name + ' (' + reservation.id + ')',
                                        subTitle: reservation.destination.address || 'Destination Not Chosen',
                                        supportingTitle: reservation.pickup_date.format('MMMM Do [at] h:mma'),
                                        badge: {
                                            text: reservation.status.text,
                                            color: reservation.status.color
                                        },
                                        icon: {
                                            style: Appearance.icons.standard(),
                                            path: reservation.customer.avatar
                                        },
                                        reservation: reservation,
                                        bottomBorder: index !== reservations.length - 1,
                                        onClick: Utils.reservations.details.bind(this, utils, reservation)
                                    })
                                )
                            })}
                        </div>
                    )}
                </div>
            )
        }
        return (
            <div style={{
                position: 'relative'
            }}>
                <Calendar
                defaultDate={moment().startOf('week').add(manager.calendar_offset, 'weeks').format('YYYY-MM-DD')}
                events={events}
                utils={utils}
                channel={'reservations'}
                eventType={'reservations'}
                showOverflow={windowState === 'fullscreen'}
                onClick={reservation => Utils.reservations.details(utils, reservation)}/>
                {events.length === 0 && (
                    <NoDataFound message={`No Reservations are booked for this week`}/>
                )}
            </div>
        )
    }

    const getDates = () => {
        let { calendar_offset, calendar_style } = manager || {};
        return {
            ...calendar_style === 'month' && {
                start_date: moment().startOf('month').add(calendar_offset, 'months').utc().unix(),
                end_date: moment().endOf('month').add(calendar_offset, 'months').utc().unix()
            },
            ...calendar_style === 'week' && {
                start_date: moment().startOf('week').add(calendar_offset, 'weeks').utc().unix(),
                end_date: moment().endOf('week').add(calendar_offset, 'weeks').utc().unix()
            }
        }
    }

    const fetchReservations = async () => {
        try {
            let { events } = await Request.get(utils, '/reservations/', {
                type: 'calendar',
                ...formatResults(utils, props => ({
                    ...props,
                    ...getDates()
                }))
            });

            setLoading(false);
            setEvents(events.map(props => {
                let reservation = Reservation.create(props);
                return {
                    allDay: true,
                    color: Appearance.colors.transparent,
                    id: reservation.id,
                    reservation: reservation,
                    start: reservation.pickup_date.format('YYYY-MM-DD HH:mm:ss'),
                    stick: true
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the calendar. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchReservations();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchReservations,
            onRemove: abstract => {
                setEvents(events => update(events, {
                    $apply: events => events.filter(e => e.id !== abstract.getID())
                }));
            },
            onUpdate: abstract => {
                setEvents(events => {
                    // filter out cancelled and rejected events
                    if(abstract.object.status && [Reservation.status.rejected, Reservation.status.cancelled].includes(abstract.object.status.code)) {
                        return events.filter(evt => {
                            return evt.id !== abstract.getID();
                        });
                    }
                    // check if event exists locally
                    let index = events.findIndex(evt => {
                        return evt.id === abstract.getID();
                    });
                    // update event if found
                    if(index >= 0) {
                        return update(events, {
                            [index]: {
                                reservation: {
                                    $set: abstract.object
                                },
                                start: {
                                    $set: abstract.object.pickup_date.format('YYYY-MM-DD HH:mm:ss')
                                }
                            }
                        })
                    }
                    // add event to events array
                    events.push({
                        allDay: true,
                        color: Appearance.colors.transparent,
                        id: abstract.getID(),
                        reservation: abstract.object,
                        start: abstract.object.pickup_date.format('YYYY-MM-DD HH:mm:ss'),
                        stick: true
                    });
                    return events;
                })
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Calendar'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removePadding: Utils.isMobile(),
            onWindowStateChange: state => setWindowState(state),
            buttons: getButtons(),
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationFlights = ({ index, utils }) => {

    const panelID = 'todaysFlights';
    const limit = 5;

    const [annotations, setAnnotations] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [overlays, setOverlays] = useState([]);
    const [paging, setPaging] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onChangeSearchText = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onReloadClick = () => {
        setLoading(true);
        fetchFlights(false);
    }

    const getAssistProps = () => {
        return {
            message: 'This map and list show the reservations for today where the customer provided a valid flight number. Flight information is provided when available and overall information is cached for each day. You can update the list at any time by pressing the refresh button'
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(reservations.length === 0) {
            return (
                Views.entry({
                    title: 'No Flights Found',
                    subTitle: 'There are no reservations with flights available to view',
                    bottomBorder: false
                })
            )
        }
        return (
            <div className={'row p-0 m-0'}>
                <div
                className={'col-12 col-lg-6'}
                style={{
                    position: 'relative',
                    padding: 12
                }}>
                    <Map
                    utils={utils}
                    center={window.userLocation}
                    overlays={overlays}
                    annotations={annotations}
                    showsUserLocationControl={false}
                    isZoomEnabled={true}
                    isRotationEnabled={true}
                    isScrollEnabled={Utils.isMobile() ? false : true}
                    style={{
                        height: 300
                    }}/>
                    <div style={{
                        position: 'absolute',
                        left: 20,
                        top: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: Appearance.colors.panelBackground(),
                        borderRadius: 8,
                        border: `1px solid ${Appearance.colors.divider()}`,
                        boxShadow: Appearance.boxShadow(),
                        padding: '8px 12px 8px 8px'
                    }}>
                        <img
                        className={`text-button ${loading ? 'icon-rotate' : ''}`}
                        src={'images/grey-spinner.png'}
                        onClick={onReloadClick}
                        style={{
                            width: 20,
                            height: 20,
                            marginRight: 8
                        }}/>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            color: Appearance.colors.text()
                        }}>{
                            loading ? 'Loading flight information...' : `Last Updated ${lastUpdated || '...'}`
                        }</span>
                    </div>
                </div>
                <div
                className={'col-12 col-lg-6 p-2'}
                style={{
                    borderLeft: `1px solid ${Appearance.colors.divider()}`
                }}>
                    {reservations.map((reservation, index) => {
                        return (
                            Views.entry({
                                key: reservation.id,
                                title: `${reservation.customer.full_name} (${reservation.id})`,
                                subTitle: reservation.flight_data.overview,
                                badge: {
                                    text: reservation.special_requests.flight,
                                    color: Appearance.colors.primary()
                                },
                                icon: {
                                    style: Appearance.icons.standard(),
                                    path: reservation.customer.avatar
                                },
                                reservation: reservation,
                                bottomBorder: index !== reservations.length - 1,
                                onClick: Utils.reservations.details.bind(this, utils, reservation)
                            })
                        )
                    })}
                </div>
            </div>
        )
    }

    const fetchFlights = async cache => {
        try {
            let { last_updated, paging, reservations } = await Request.get(utils, '/reservations/', {
                limit: limit,
                type: 'flight_schedules',
                use_cache: typeof(cache) === 'boolean' ? cache : true,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setLastUpdated(moment(last_updated).format('MMMM Do [at] h:mma'));

            let targets = reservations.filter(reservation => {
                return reservation.flight_data && reservation.flight_data.route;
            }).map(props => {

                // build reservation object
                let reservation = Reservation.create(props);
                let { destination, origin } = reservation.flight_data;

                // check if departure or arrival time is closer to the pickup date and time
                reservation.flight_data.overview = moment(origin.departure_time).format('[Departing on ] MMMM Do [at] h:mma');
                if(moment(destination.arrival_time).diff(reservation.pickup_date) < moment(reservation.pickup_date).diff(moment(origin.departure_time))) {
                    reservation.flight_data.overview = moment(destination.arrival_time).format('[Arriving on ] MMMM Do [at] h:mma');
                }
                return reservation;
            });

            setReservations(targets);
            setOverlays(targets.map(reservation => ({
                key: `flight-${reservation.id}`,
                coordinates: reservation.flight_data.route.coordinates.map(coordinate => {
                    return [ coordinate.lat, coordinate.long ];
                })
            })));

            setAnnotations(targets.reduce((array, reservation) => {
                return array.concat([{
                    id: 'origin',
                    title: `${reservation.customer.full_name} (${reservation.id})`,
                    subTitle: reservation.flight_data.route.origin_airport.name,
                    icon: { type: 'broadcast' },
                    location: {
                        latitude: reservation.flight_data.route.origin_airport.lat,
                        longitude: reservation.flight_data.route.origin_airport.long
                    }
                },{
                    id: 'destination',
                    title: `${reservation.customer.full_name} (${reservation.id})`,
                    subTitle: reservation.flight_data.route.destination_airport.name,
                    icon: { type: 'broadcast' },
                    location: {
                        latitude: reservation.flight_data.route.destination_airport.lat,
                        longitude: reservation.flight_data.route.destination_airport.long
                    }
                }])
            }, []));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading today's flights. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchFlights();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchFlights,
            onRemove: abstract => {
                setReservations(reservations => {
                    return reservations.filter(reservation => {
                        return reservation.id !== abstract.getID();
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Today's Flights`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps()
            },
            search: {
                placeholder: 'Search by reservation id or customer...',
                onChange: onChangeSearchText
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationGuidelines = ({ index, utils }) => {

    const panelID = 'defaultGuidelines';
    const [guidelines, setGuidelines] = useState([]);
    const [loading, setLoading] = useLoading();

    const onGuidelineClick = guideline => {
        utils.layer.open({
            id: 'edit-reservation-guideline',
            Component: EditReservationGuideline.bind(this, {
                resGuideline: guideline
            })
        });
    }

    const getAssistProps = () => {
        return {
            message: 'These guidelines are the default values that Reservation Services will use when determining booking eligibilty. Any service without a minimum booking time, minimum ride distance, or maximum booking radius will fall back to their respective defaults that are listed below'
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(guidelines.length === 0) {
            return (
                Views.entry({
                    title: 'No Services Found',
                    subTitle: 'There are no guidelines available to view',
                    bottomBorder: false
                })
            )
        }
        return guidelines.map((item, index) => {
            return (
                Views.entry({
                    key: item.key,
                    title: item.title,
                    subTitle: item.notes || 'No selection has been made',
                    icon: {
                        style: Appearance.icons.standard(),
                        path: 'images/guidelines-icon-clear.png'
                    },
                    badge: {
                        color: Appearance.colors.grey(),
                        text: item.formatValue()
                    },
                    bottomBorder: index !== guidelines.length - 1,
                    onClick: onGuidelineClick.bind(this, item)
                })
            )
        })
    }

    const fetchGuidelines = async () => {
        try {
            let { minimum_ride_distance, minimum_time_before_booking } = await Request.get(utils, '/reservations/',  {
                type: 'requirements'
            });

            let minDistance = minimum_ride_distance.find((_, index, entries) => index === entries.length - 1);
            let minLeadTime = minimum_time_before_booking.find((_, index, entries) => index === entries.length - 1);

            setLoading(false);
            setGuidelines([
                ReservationGuideline.create({
                    key: 'min_distance',
                    title: 'Minimum Distance',
                    value: minDistance.distance,
                    notes: minDistance ? minDistance.notes : null,
                    description: 'The minimum distance guideline dictates the shortest possible ride that will be allowed during booking. The minimum distance for a Service will override this value'
                }),
                ReservationGuideline.create({
                    key: 'min_lead_time',
                    title: 'Minimum Booking Lead Time',
                    value: minLeadTime.seconds,
                    notes: minLeadTime ? minLeadTime.notes : minLeadTime,
                    description: 'The minimum booking lead time guideline dictates the shortest amount of time in minutes required between booking and pickup. The minimum booking lead time for a Service will override this value'
                })
            ]);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the default guidelines list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchGuidelines();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Default Guidelines'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationsList = ({ category }, { index, utils }) => {

    const panelID = `${category}Reservations`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: getInfo().title,
                extendedFeatures: category !== 'today' && ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/reservations/', {
                    type: 'all_admin',
                    category: category,
                    ...category === 'today' && {
                        current_date: moment.utc().unix()
                    },
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: getInfo().message,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Reservations'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(reservations.length === 0) {
            return (
                Views.entry({
                    title: 'No Reservations Found',
                    subTitle: 'There are no reservations available to view',
                    bottomBorder: false
                })
            )
        }
        return reservations.map((reservation, index) => {
            return (
                Views.entry({
                    key: index,
                    title: `${reservation.customer.full_name} (${reservation.id})`,
                    subTitle: reservation.destination.address,
                    supportingTitle: reservation.special_requests.on_demand ? 'Immediate Pickup' : reservation.pickup_date.format('MMMM Do [at] h:mma'),
                    badge: {
                        text: reservation.status.text,
                        color: reservation.status.color
                    },
                    icon: {
                        path: reservation.customer.avatar
                    },
                    reservation: reservation,
                    bottomBorder: index !== reservations.length - 1,
                    onClick: Utils.reservations.details.bind(this, utils, reservation)
                })
            )
        })
    }

    const getInfo = () => {
        switch(category) {
            case 'all':
            return {
                title: 'All Reservations',
                message: 'This list shows all of the reservations in the system. You can view or edit a reservation by clicking on it.'
            }

            case 'app_clips':
            return {
                title: 'App Clips Reservations',
                message: 'This list shows all of the reservations in the system that were booked through an App Clip. You can view or edit a reservation by clicking on it.'
            }

            case 'cancelled':
            return {
                title: 'Cancelled Reservations',
                message: 'This list shows all of the cancelled reservations. You can view or edit a reservation by clicking on it.'
            }

            case 'new':
            return {
                title: 'Newly Added Reservations',
                message: 'This list shows all of the newly added reservations. You can view or edit a reservation by clicking on it.'
            }

            case 'pending':
            return {
                title: 'Pending Reservations',
                message: 'This list shows all of the pending reservations. You can view or edit a reservation by clicking on it.'
            }

            case 'today':
            return {
                title: `Today's Reservations`,
                message: 'This list shows all of the reservations scheduled for today. You can view or edit a reservation by clicking on it.'
            }

            default:
            return {
                title: 'All Reservations',
                message: 'You can view or edit a reservation by clicking on it.'
            }
        }
    }

    const fetchReservations = async () => {
        try {
            let { paging, reservations } = await Request.get(utils, '/reservations/', {
                type: 'all_admin',
                category: category,
                limit: limit,
                offset: 0,
                ...category === 'today' && {
                    current_date: moment.utc().unix()
                },
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setReservations(reservations.map(reservation => Reservation.create(reservation)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading ${getInfo().title.toLowerCase()}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchReservations();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'reservations', {
            onFetch: fetchReservations,
            onRemove: abstract => {
                setReservations(reservations => {
                    return reservations.filter(reservation => reservation.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setReservations(reservations => {
                    let index = reservations.findIndex(reservation => {
                        return reservation.id === abstract.getID();
                    });
                    if(index >= 0) {
                        reservations[index] = abstract.object;
                    } else {
                        reservations.push(abstract.object);
                    }

                    // filter out non-valid targets from current list of reservations
                    return reservations.filter(reservation => {
                        switch(category) {
                            case 'app_clips':
                            return reservation.booking_channel === 'app_clips';

                            case 'cancelled':
                            return reservation.status && reservation.status.code === Reservation.status.cancelled;

                            case 'pending':
                            return !reservation.status || reservation.status.code === Reservation.status.pending;

                            case 'today':
                            return moment().isSame(moment(reservation.pickup_date), 'day') && (reservation.status && ![Reservation.status.rejected, Reservation.status.cancelled].includes(reservation.status.code));

                            default:
                            return true;
                        }
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        name={getInfo().title}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by reservation id or customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationMoods = ({ index, utils }) => {

    const panelID = 'reservationMoods';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [moods, setMoods] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Moods',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/reservations/',  {
                    type: 'moods_admin',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onMoodClick = mood => {
        utils.layer.open({
            id: `reservation-mood-details-${mood.id}`,
            abstract: Abstract.create({
                type: 'moods',
                object: mood
            }),
            Component: ReservationMoodDetails
        });
    }

    const onNewMood = () => {
        utils.layer.open({
            id: 'new-reservation-mood',
            abstract: Abstract.create({
                type: 'moods',
                object: Mood.new()
            }),
            Component: AddEditReservationMood.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `Moods are a helpful tool for customer and drivers. It allows the customer to inform the driver of the experience they are expecting and gives the driver the ability to make the customer's experience even better`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Moods'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(moods.length === 0) {
            return (
                Views.entry({
                    title: 'No Moods Found',
                    subTitle: 'There are no moods available to view',
                    bottomBorder: false
                })
            )
        }
        return moods.map((mood, index) => {
            return (
                Views.entry({
                    key: mood.id,
                    title: mood.title,
                    subTitle: mood.information,
                    badge: mood.active === false && {
                        color: Appearance.colors.grey(),
                        text: 'Not Active'
                    },
                    icon: {
                        path: mood.image
                    },
                    bottomBorder: index !== moods.length - 1,
                    onClick: onMoodClick.bind(this, mood)
                })
            )
        })
    }

    const fetchMoods = async () => {
        try {
            let { moods, paging } = await Request.get(utils, '/reservations/',  {
                type: 'moods_admin',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setMoods(moods.map(mood => Mood.create(mood)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the moods list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchMoods();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'moods', {
            onFetch: fetchMoods,
            onRemove: abstract => {
                setMoods(moods => {
                    return moods.filter(mood => mood.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setMoods(moods => {
                    return moods.map(mood => abstract.compare(mood));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Moods'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Mood',
                style: 'default',
                onClick: onNewMood
            }],
            search: {
                placeholder: 'Search by mood name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationPremiumLocations = ({ index, utils }) => {

    const panelID = 'reservationPremiumLocations';
    const limit = 5;

    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Premium Locations',
                onExport: onDownloadContent
            });
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/reservations/', {
                    type: 'premium_locations',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'These are areas that come with an added cost when a customer books a ride to or from one of these locations. You can use these locations to account for entrance and/or exit fees for specific pickup or drop-off locations',
            items: [{
                key: 'download',
                title: 'Download Premium Locations',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(locations.length === 0) {
            return (
                Views.entry({
                    title: 'No Locations Found',
                    subTitle: 'There are no premium reservation locations in the system',
                    bottomBorder: false
                })
            )
        }
        return locations.map((item, index) => {
            return (
                Views.entry({
                    badge: getBadges(item),
                    bottomBorder: index !== locations.length - 1,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: 'images/location-icon-clear.png'
                    },
                    key: index,
                    onClick: onLocationClick.bind(this, item),
                    subTitle: item.address,
                    title: item.name
                })
            )
        })
    }

    const getBadges = location => {

        let badges = [{
            color: Appearance.colors.grey(),
            text: location.active ? null : 'Not Active'
        }];

        // determine which cost type is set for this location
        switch(location.type.code) {
            case PremiumLocation.types.fixed_cost:
            badges.push({
                color: Appearance.colors.green,
                text: Utils.toCurrency(location.amount)
            });
            break;

            case PremiumLocation.types.no_cost:
            badges.push({
                color: Appearance.colors.darkGrey,
                text: 'No Cost'
            });
            break;

            case PremiumLocation.types.percentage:
            badges.push({
                color: Appearance.colors.blue,
                text: `${parseInt(location.amount * 100)}%`
            });
            break;
        }

        return badges;
    }

    const onLocationClick = location => {
        utils.layer.open({
            id: `premium_location_details_${location.id}`,
            abstract: Abstract.create({
                type: 'premiumLocations',
                object: location
            }),
            Component: PremiumLocationDetails
        });
    }

    const onNewLocationClick = () => {
        let location = PremiumLocation.new();
        location.category = PremiumLocation.categories.reservations;
        utils.layer.open({
            id: 'new_premium_location',
            abstract: Abstract.create({
                type: 'premiumLocations',
                object: location
            }),
            Component: AddEditPremiumLocation.bind(this, {
                isNewTarget: true
            })
        })
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getOptionItems = () => {
        return [{
            key: 'new',
            title: 'New Premium Location',
            style: 'default'
        }]
    }

    const fetchLocations = async () => {
        try {
            let { locations, paging } = await Request.get(utils, '/reservations/', {
                type: 'premium_locations',
                limit: limit,
                ...manager
            });
            setLoading(false);
            setPaging(paging);
            setLocations(locations.map(location => PremiumLocation.create(location)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of premium locations. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchLocations();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'premiumLocations', {
            onFetch: fetchLocations,
            onUpdate: abstract => {
                setLocations(locations => {
                    return locations.map(location => abstract.compare(location));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Premium Locations'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Premium Location',
                style: 'default',
                onClick: onNewLocationClick
            }],
            search: {
                placeholder: 'Search by name or address..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const RestrictedDatesTimes = ({ index, utils }) => {

    const panelID = 'restrictedDatesTimes';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [restrictions, setRestrictions] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Restricted Dates and Times',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/resources/',  {
                    type: 'restricted_dates_times',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewRestriction = () => {
        utils.layer.open({
            id: 'new-restricted-date-time',
            abstract: Abstract.create({
                type: 'restrictedDateTimes',
                object: RestrictedDateTime.new()
            }),
            Component: AddEditRestrictedDateTime.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onRestrictionClick = restriction => {
        utils.layer.open({
            id: `restricted-date-time-details-${restriction.id}`,
            abstract: Abstract.create({
                type: 'restrictedDateTimes',
                object: restriction
            }),
            Component: RestrictedDateTimeDetails
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'These dates and times are either single days or date/time ranges where Reservations are not permitted for booking. This could include holidays, scheduled downtime, company parties, etc',
            items: [{
                key: 'download',
                title: 'Download Dates and Times',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(restrictions.length === 0) {
            return (
                Views.entry({
                    title: 'No Restricted Dates or Times Found',
                    subTitle: 'There are no restricted dates or times available to view',
                    borderBottom: false
                })
            )
        }
        return restrictions.map((restriction, index) => {
            return (
                Views.entry({
                    key: restriction.id,
                    title: restriction.title,
                    subTitle: restriction.message,
                    badge: !restriction.active && {
                        color: Appearance.colors.grey(),
                        text: 'Not Active'
                    },
                    bottomBorder: index !== restrictions.length - 1,
                    onClick: onRestrictionClick.bind(this, restriction)
                })
            )
        });
    }

    const fetchRestrictions = async () => {
        try {
            let { paging, restrictions } = await Request.get(utils, '/resources/',  {
                type: 'restricted_dates_times',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setRestrictions(restrictions.map(props => RestrictedDateTime.create(props)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the restricted dates list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchRestrictions();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'restrictedDateTimes', {
            onFetch: fetchRestrictions,
            onRemove: abstract => {
                setRestrictions(restrictions => {
                    return restrictions.filter(restriction => restriction.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setRestrictions(restrictions => {
                    return restrictions.map(restriction => abstract.compare(restriction));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Restricted Dates and Times'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Restriction',
                style: 'default',
                onClick: onNewRestriction
            }],
            search: {
                placeholder: 'Search by restricted date name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const Services = ({ channel, panelID }, { index, utils }) => {

    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [services, setServices] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Services',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchServices(utils, {
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewService = () => {

        // create new service and attach optional channel
        let service = Service.new();
        service.channel = channel;

        // open layer for service editing
        utils.layer.open({
            id: 'new-service',
            abstract: Abstract.create({
                type: 'services',
                object: service
            }),
            Component: AddEditService.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const onServiceClick = service => {
        utils.layer.open({
            id: `service-details-${service.id}`,
            abstract: Abstract.create({
                type: 'services',
                object: service
            }),
            Component: ServiceDetails
        })
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all active and inactive Services in the system. A Service is a set of guidelines for movements made during a Reservation',
            items: [{
                key: 'download',
                title: 'Download Services',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(services.length === 0) {
            return (
                Views.entry({
                    title: 'No Services Found',
                    subTitle: 'There are no services available to view',
                    bottomBorder: false
                })
            )
        }
        return services.map((service, index) => {
            return (
                Views.entry({
                    key: service.id,
                    title: service.name,
                    subTitle: service.information,
                    badge: [{
                        text: service.default_for_channel ? 'Default' : null,
                        color: Appearance.colors.primary()
                    },{
                        text: service.active ? null : 'Not Active',
                        color: Appearance.colors.grey()
                    }],
                    bottomBorder: index !== services.length - 1,
                    onClick: onServiceClick.bind(this, service)
                })
            )
        });
    }

    const fetchAllServices = async () => {
        try {
            let { paging, services } = await fetchServices(utils, {
                channel: channel,
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging)
            setServices(services);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchAllServices();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'services', {
            onFetch: fetchAllServices,
            onRemove: abstract => {
                setServices(services => {
                    return services.filter(service => {
                        return service.id !== abstract.getID();
                    });
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Services'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Service',
                style: 'default',
                onClick: onNewService
            }],
            search: {
                placeholder: 'Search by service name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ServiceActivity = ({ methods, index, utils }) => {

    const panelID = 'servicesActivity';
    const chart = useRef(null);

    const [chartData, setChartData] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(12, 'months') });
    const [loading, setLoading] = useLoading();
    const [service, setService] = useState(null);
    const [services, setServices] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Services Activity',
                chart: chart,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onChangeService = evt => {
        setService(services.find(service => {
            return service.id === parseInt(Utils.attributeForKey.select(evt, 'id'))
        }));
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/reservations/', {
                    type: 'services_activity',
                    id: service ? service.id : services[0].id,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'The graph shows the overall service usage sorted by the month they were used. The left side shows the amount a service was used and the bottom shows the month they were used. The graph shows the last 12 months',
            items: [{
                key: 'download',
                title: 'Download Activity',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(!chartData || chartData.labels.length < 3) {
            return (
                <div style={{
                    display: 'block',
                    height: 350,
                    padding: 10,
                    position: 'relative'
                }}>
                    <NoDataFound message={'At least 3 entries of Service usage are needed for the graph'}/>
                </div>
            )
        }
        return (
            <div style={{
                display: 'block',
                height: 350,
                padding: 10,
                position: 'relative'
            }}>
                <Line
                ref={chart}
                data={{
                    labels: chartData.labels,
                    datasets: [{
                        data: chartData.data,
                        borderColor: Appearance.colors.primary(),
                        fill: true,
                        backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                        pointRadius: 5,
                        pointBorderWidth: 2,
                        pointBackgroundColor: 'white'
                    }]
                }}
                width={500}
                height={100}
                options={{
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        callbacks: {
                            label: item => {
                                return `${item.yLabel} ${parseInt(item.yLabel) === 1 ? ' Booking' : 'Bookings'}`;
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: value => Utils.numberFormat(value)
                            }
                        }]
                    }
                }} />
            </div>
        )
    }

    const getSearchField = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div
            className={'row m-0'}
            style={{
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-0 pt-0 pb-2 p-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    value={service ? service.name : 'Select a service'}
                    onChange={onChangeService}
                    style={{
                        width: Utils.isMobile() ? '100%' : 150,
                        opacity: services && services.length > 0 ? 1 : 0
                    }}>
                        <option disabled={true}>{'Choose a Service'}</option>
                        {services.map((service, index) => (
                            <option key={index} id={service.id}>{service.name}</option>
                        ))}
                    </select>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={dates.start_date}
                        selectedEndDate={dates.end_date}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }}
                        onStartDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    start_date: date
                                }
                            })
                        }}
                        onEndDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    end_date: date
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
        )
    }

    const fetchActivity = async () => {
        try {
            if(!service || services.length === 0) {
                return;
            }
            let { results } = await Request.get(utils, '/reservations/', {
                end_date: moment(dates.end_date).utc().unix(),
                id: service ? service.id : services[0].id,
                start_date: moment(dates.start_date).utc().unix(),
                type: 'services_activity'
            });

            setLoading(false);
            setChartData({
                data: results.map(entry => entry.total),
                labels: results.map(entry => moment.utc(entry.date).local().format('MMM YYYY'))
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services activity breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchAllServices = async () => {
        try {
            let { services } = await fetchServices(utils);
            setServices(services);
            setService(services && services.length > 0 ? services[0] : null);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchActivity();
    }, [dates, service]);

    useEffect(() => {
        fetchAllServices();
        utils.content.subscribe(panelID, ['reservations', 'services'], {
            onFetch: fetchActivity,
            onUpdate: abstract => {
                if(abstract.type !== 'services') {
                    return;
                }
                setServices(services => {
                    return services.map(service => abstract.compare(service));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        name={'Service Activity'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
        }}>
            {getSearchField()}
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditPremiumLocation = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_premium_location' : `edit_premium_location_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await validateRequiredFields(getFields);

            await Utils.sleep(1);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The premium location for ${abstract.object.name || abstract.object.address} has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this premium location. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setLocation(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getCostTypes = () => {
        return [{
            id: PremiumLocation.types.fixed_cost,
            title: 'Fixed Cost'
        },{
            id: PremiumLocation.types.percentage,
            title: 'Percentage'
        },{
            id: PremiumLocation.types.no_cost,
            title: 'No Cost'
        }];
    }

    const getFields = () => {
        if(!location) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a premium location is used to identify this location across the entire platform. This name is shown to customers as a line item when their ride or order interacts with this location.',
                value: location.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'address',
                title: 'Address',
                description: 'The address for a premium location is used to identify where this location exists in the world. This address may be shown to customers as a line item when their ride or order interacts with this location.',
                value: location.address,
                component: 'address_lookup',
                onChange: place => {
                    onUpdateTarget({
                        address: place && Utils.formatAddress(place.address),
                        location: place && place.location
                    });
                }
            },{
                key: 'radius',
                title: 'Radius',
                description: 'We use the radius to draw a circle around the center of your premium location. Rides and orders that fall within this radius will be considered "inside the premium operating area". The radius for a location is measured in feet.',
                value: location.radius,
                component: 'number_stepper',
                onChange: val => onUpdateTarget({ radius: val })
            }]
        },{
            key: 'cost',
            title: 'Cost Preferences',
            items: [{
                key: 'type',
                title: 'Type',
                description: 'The cost type for a premium location determines how a cost, if any, is applied to rides that interact with this location.',
                value: location.type && location.type.text,
                component: 'list',
                items: getCostTypes(),
                onChange: item => {
                    onUpdateTarget({
                        type: item && {
                            code: item.id,
                            text: item.title
                        }
                    });
                }
            },{
                key: 'amount',
                title: 'Amount',
                description: 'The amount for a premium location should reflect the fee that you wish to charge a customer for inteacting with this location.',
                value: location.amount,
                component: 'textfield',
                onChange: text => onUpdateTarget({ amount: text }),
                visible: location.type && location.type.code === PremiumLocation.types.no_cost ? false : true,
                required: location.type && location.type.code === PremiumLocation.types.no_cost ? false : true,
                props: {
                    append: location.type && location.type.code === PremiumLocation.types.percentage && '%',
                    prepend: location.type && location.type.code === PremiumLocation.types.fixed_cost && '$',
                    format: location.type && location.type.code === PremiumLocation.types.percentage ? 'percentage' : 'number'
                }
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        if(isNewTarget) {
            edits = abstract.object.set({ 
                type: {
                    code: PremiumLocation.types.fixed_cost,
                    text: 'Fixed Cost'
                }
            });
        }
        setLocation(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Premium Location' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setLocation(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditReservation = ({ isNewTarget, onRideSubmit }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new-reservation-confirm-${abstract.object.tempID}` : `reservation-edit-${abstract.getID()}`;

    const [companies, setCompanies] = useState([]);
    const [costBreakdown, setCostBreakdown] = useState(null);
    const [dropDown, setDropDown] = useState(null);
    const [flightNumberValid, setFlightNumberValid] = useState(null);
    const [historyObject, setHistoryObject] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [overrides, setOverrides] = useState([]);
    const [reservation, setReservation] = useState({});
    const [services, setServices] = useState([]);
    const [vehicles, setVehicles] = useState([]);

    const onManageStops = () => {

        let stops = reservation.stops || {};
        let locations = reservation.stops && reservation.stops.locations ? reservation.stops.locations : [];
        setDropDown({
            title: 'Stops Along the Way',
            message: `We're here to help if you need to make some stops during your ride. Depending on your needs, we can make these stops in the order that you like or we can try to visit all stops in the fastest and more efficient way possible`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => {
                    onUpdateTarget({
                        stops: {
                            ...stops,
                            locations: locations
                        }
                    });
                }
            }],
            content: (
                <>
                {locations.length > 1 && (
                    <BoolToggle
                    enabled={'Fastest'}
                    disabled={'In Order'}
                    isEnabled={stops && stops.optimized}
                    style={{
                        width: '100%',
                        marginBottom: 12
                    }}
                    onChange={enabled => {
                        stops = {
                            ...stops,
                            optimized: enabled
                        }
                    }} />
                )}
                <MultipleAddressLookupField
                utils={utils}
                label={'Stops Along the Way'}
                locations={locations}
                autoCorrect={false}
                spellCheck={false}
                placeholder={'Search for a place or address...'}
                onChange={results => {
                    locations = results;
                }}
                style={{
                    width: '100%'
                }}/>
                </>
            )
        })
    }

    const onReservationSubmit = async () => {

        // verify that a payment method was added if a cost is associated with the ride
        // this does not apply to rides that were captured through an in-person card reader
        if(reservation.is_terminal_origin === true && (!costBreakdown || (costBreakdown.total > 0 && !reservation.payment_method))) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'It looks like there is a cost remaining for this reservation but no payment method has been selected. Please choose a payment method for this reservation or use credits to cover the remaining cost of the reservation'
            });
            return;
        }

        // verify that all restrictions have been met
        let requirements = Object.keys(getRequirements).reduce((array, key) => {
            let requirement = getRequirements[key]();
            if(requirement) {
                array.push(requirement);
            }
            return array;
        }, []);

        // prevent moving forward if one or more requirements have not been met
        if(requirements.length > 0){
            utils.alert.show(requirements[0]);
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The reservation for ${abstract.object.pickup_date.format('MMMM Do, YYYY [at] h:mma')} has been ${isNewTarget ? 'submitted' : 'updated'}`,
                onClick: () => {
                    setLayerState('close');
                    if(isNewTarget && typeof(onRideSubmit) === 'function') {
                        onRideSubmit();
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSelectCreditsMethod = () => {
        let credits = reservation.credits;
        setDropDown({
            title: 'Credits',
            message: 'Credits allows a customer to book a ride without using their credit or debit card. Credits will be used to cover as much of the reservation as possible and any remaining costs will be charged to the payment method on file with the reservation.',
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ credits: credits })
            }],
            content: (
                <CreditsManager
                defaultAmount={reservation.credits && reservation.credits.amount}
                defaultCardID={reservation.credits && reservation.credits.card_id}
                maxAmount={costBreakdown && costBreakdown.physicalCost}
                onChange={result => credits = result}
                user={reservation.customer}
                utils={utils}/>
            )
        })
    }

    const onSelectedPaymentMethod = () => {

        let method = reservation.payment_method;
        let target = Abstract.create({
            type: 'users',
            object: reservation.customer
        });
        setDropDown({
            title: 'Payment Method',
            message: `The payment method for this reservation is used to process the final cost after discounts, promotions, and credits. The customer's default payment method will be used if a specific method is not chosen. Click on a payment method to assign it to this reservation`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ payment_method: method })
            }],
            content: (
                <PaymentMethodManager
                utils={utils}
                target={target}
                defaultMethod={reservation.payment_method}
                onChange={result => {
                    method = result;
                }} />
            )
        })
    }

    const onSelectPromoCode = () => {
        if(!reservation.promo_code) {
            onSelectPromoCodeConfirm();
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'change',
                title: 'Change',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'change') {
                onSelectPromoCodeConfirm();
                return;
            }
            if(key === 'remove') {
                onUpdateTarget({ promo_code: null });
                return;
            }
        });
    }

    const onSelectPromoCodeConfirm = () => {

        let code = reservation.promo_code;
        setDropDown({
            title: 'Promo Code',
            message: 'Adding a promo code to a reservation can discount or cover the final cost of the ride. Only one promo code may be used per reservation and promo codes do not apply towards driver tips',
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ promo_code: code })
            }],
            content: (
                <PromoCodeLookupField
                userID={reservation.customer.user_id}
                defaultPromoCode={reservation.promo_code ? reservation.promo_code.code : null}
                utils={utils}
                autoCorrect={false}
                spellCheck={false}
                onChange={result => {
                    code = result;
                }}/>
            )
        })
    }

    const onUpdateCostBreakdown = async () => {
        let fetchID = `${layerID}:onUpdateCostBreakdown`;
        try {
            if(!reservation.service) {
                return;
            }
            if(utils.fetching.get(fetchID) === true) {
                return;
            }
            utils.fetching.set(fetchID, true);
            let { breakdown } = await abstract.object.getCostBreakdown(utils);

            utils.fetching.set(fetchID, false);
            setCostBreakdown(breakdown);
            setReservation({ ...abstract.object.edits });

        } catch(e) {
            utils.fetching.set(fetchID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating the estimated cost for this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdatePaymentMethod = async () => {
        try {
            let method = await abstract.object.translatePaymentMethod(utils);
            let edits = abstract.object.set({ payment_method: method });
            setReservation(edits);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setReservation(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onValidateFlightNumber = async text => {
        try {
            if(!text) {
                setFlightNumberValid(null);
                return;
            }
            setLoading('flight_number');
            onUpdateTarget({
                special_requests: update(reservation.special_requests, {
                    flight: {
                        $set: text
                    }
                })
            });
            let { valid } = await Request.post(utils, '/resources/', {
                type: 'validate_flight_number',
                flight_number: text
            });
            setLoading(false);
            setFlightNumberValid(valid);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue validating your flight number. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const hasCostEstimateSubText = () => {
        return reservation.service && (reservation.service.disclaimer || reservation.service.preauthorize) ? true : false;
    }

    const getButtons = () => {
        return [{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                abstract.object.edits = historyObject;
                setReservation({ ...historyObject });
            })
        },{
            key: 'confirm',
            text: isNewTarget ? 'Confirm' : 'Save',
            color: 'primary',
            onClick: onReservationSubmit
        }]
    }

    const getContent = () => {
        if(!reservation || !reservation.service || !reservation.vehicle) {
            return (
                <div style={{
                    padding: 20
                }}>
                    {Views.loader()}
                </div>
            )
        }
        return (
            <>
            <LayerItem title={'Cost Estimate'}>
                {getCostBreakdownComponents()}
                {hasCostEstimateSubText() && (
                    <div style={{
                        lineHeight: 1,
                        padding: 12,
                        borderTop: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {typeof(reservation.service.disclaimer) === 'string' && (
                            <span style={{
                                fontSize: 10,
                                color: Appearance.colors.subText()
                            }}>{reservation.service.disclaimer}</span>
                        )}
                        {typeof(reservation.service.preauthorize) === 'string' && (
                            <span style={{
                                fontSize: 10,
                                color: Appearance.colors.subText()
                            }}>{reservation.service.preauthorize}</span>
                        )}
                    </div>
                )}
            </LayerItem>

            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
            </>
        )
    }

    const getCostBreakdownComponents = () => {
        if(!costBreakdown) {
            return (
                <div style={{
                    padding: 20
                }}>
                    {Views.loader()}
                </div>
            )
        }
        return costBreakdown.lineItems.map((item, index) => {
            return (
                <div
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '5px 10px 5px 10px',
                    borderBottom: index !== costBreakdown.lineItems.length - 1 ? (`1px solid ${Appearance.colors.divider()}`) : null
                }}>
                    <span style={Appearance.textStyles.key()}>{item.title}</span>
                    <span style={Appearance.textStyles.value()}>{item.message}</span>
                </div>
            )
        })
    }

    const getFields = () => {
        if(!reservation) {
            return null;
        }
        let fields = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'customer',
                title: 'Customer',
                description: 'The customer selected for this reservation should be the individual who is taking the ride.',
                value: reservation.customer,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ customer: user })
            },{
                key: 'company',
                required: false,
                visible: utils.user.get().level <= User.level.admin,
                title: 'Company',
                description: `Assigning a company to this reservation will apply any discounts available for that company and will allow this reservation to appear in Seeds for the selected company's administrators`,
                value: reservation.company,
                component: 'list',
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: company => {
                    if(company && reservation.customer.company && company.id !== reservation.customer.company.id) {
                        utils.alert.show({
                            title: 'Company',
                            message: `${reservation.customer.full_name} is part of ${reservation.customer.company.name}. Reservations booked for ${reservation.customer.first_name} are automatically tied to ${reservation.customer.company.name}. If you need to book this Reservation without attaching ${reservation.customer.company.name} then you'll need to remove ${reservation.customer.first_name} from ${reservation.customer.company.name}.`
                        });
                        return;
                    }
                    onUpdateTarget({ company: company });
                }
            },{
                key: 'message',
                required: false,
                title: 'Message for Driver',
                description: `This message is shown to the driver when they begin the customer's reservation`,
                value: reservation.special_requests ? reservation.special_requests.message : null,
                component: 'textview',
                onChange: text => {
                    onUpdateTarget({
                        special_requests: update(reservation.special_requests, {
                            message: {
                                $set: text
                            }
                        })
                    });
                }
            }]
        },{
            key: 'locations_and_time',
            title: 'Locations and Time',
            items: [{
                key: 'pickup_date',
                title: 'Date and Time',
                description: 'This will be the date and time that the driver will arrive for the reservation.',
                value: reservation.pickup_date,
                component: 'date_time_picker',
                invalid: getRequirements.pickupDate(),
                onChange: date => onUpdateTarget({ pickup_date: date }),
                props: {
                    filterDateValue: moment().add(reservation.service.min_booking_time || 0, 'seconds'),
                    filterDate: date => {
                        return moment(date) >= moment().add(reservation.service.min_booking_time || 0, 'seconds')
                    }
                }
            },{
                key: 'origin',
                title: 'Pickup',
                description: isNewTarget ? 'This should be the location where the customer will be picked up by the driver.' : 'Changing the pickup location will result in the estimated and final cost for this reservation to change from the cost presented during booking',
                value: reservation.origin,
                component: 'address_lookup',
                onChange: place => {
                    onUpdateTarget({
                        origin: place && {
                            id: place.address.id,
                            name: place.address.name,
                            address: Utils.formatAddress(place.address),
                            location: place.location
                        }
                    });
                }
            },{
                key: 'stops',
                required: false,
                title: 'Stops Along the Way',
                description: `We're here to help if you need to make some stops during your ride. Depending on your needs, we can make these stops in the order that you like or we can try to visit all stops in the fastest and more efficient way possible.`,
                ...getStopsOverview(),
                onEditClick: onManageStops
            },{
                key: 'destination',
                title: 'Drop-Off',
                description: isNewTarget ? 'This should be the location where the customer will be dropped off by the driver.' : 'Changing the drop-off location will result in the estimated and final cost for this reservation to change from the cost presented during booking.',
                value: reservation.destination,
                component: 'address_lookup',
                onChange: place => {
                    onUpdateTarget({
                        destination: place && {
                            id: place.address.id,
                            name: place.address.name,
                            address: Utils.formatAddress(place.address),
                            location: place.location
                        }
                    });
                }
            }]
        },{
            key: 'payments',
            title: 'Payments',
            items: [{
                key: 'method',
                title: 'Method',
                description: 'This will be the payment method used to process the final cost of this reservation.',
                value: reservation.payment_method ? reservation.payment_method.type() : 'Not Selected',
                onEditClick: onSelectedPaymentMethod
            },{
                key: 'credits',
                required: false,
                title: 'Credits',
                description: `We're here to help if you need to make some stops during your ride. Depending on your needs, we can make these stops in the order that you like or we can try to visit all stops in the fastest and more efficient way possible.`,
                value: reservation.credits ? 'Added' : 'Not Added',
                invalid: getRequirements.credits(),
                onEditClick: onSelectCreditsMethod
            },{
                key: 'promo_code',
                required: false,
                title: 'Promo Code',
                description: 'Attaching a promo code to your reservation can help reduce the final billable cost.',
                value: reservation.promo_code ? reservation.promo_code.getSummary() : null,
                onEditClick: onSelectPromoCode
            }]
        },{
            key: 'customize',
            title: 'Customize',
            items: [{
                key: 'service',
                title: 'Service',
                description: 'The service dictates how a reservation is processed after it is completed. Changing the service may change the cost for this reservation',
                value: reservation.service,
                invalid: getRequirements.service(),
                component: 'list',
                items: services.map(service => ({
                    id: service.id,
                    title: service.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        service: item && services.find(service => {
                            return service.id === item.id;
                        })
                    });
                }
            },{
                key: 'vehicle',
                title: 'Vehicle',
                description: 'The vehicle dictates how a many individuals and pieces of luggage can be accomodated for the reservation. Changing the vehicle may change the cost for this reservation',
                value: reservation.vehicle,
                invalid: getRequirements.vehicle(),
                component: 'list',
                items: vehicles.map(vehicle => ({
                    id: vehicle.id,
                    title: vehicle.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        vehicle: item && vehicles.find(vehicle => {
                            return vehicle.id === item.id;
                        })
                    });
                }
            },{
                key: 'passengers',
                title: 'Passengers',
                description: 'The passenger count for a reservation helps a driver know how many riders to expect. Be aware that vehicles can only hold a certain amount of passengers and luggage at one time',
                value: reservation.passengers,
                component: 'textfield',
                onChange: val => onUpdateTarget({ passengers: val }),
                props: {
                    format: 'number'
                }
            },{
                key: 'luggage',
                title: 'Luggage',
                description: 'The luggage count for a reservation helps a driver know how many riders to expect. Be aware that vehicles can only hold a certain amount of passengers and luggage at one time',
                value: reservation.luggage,
                component: 'textfield',
                onChange: val => onUpdateTarget({ luggage: val }),
                props: {
                    format: 'number'
                }
            },{
                key: 'referral_code',
                required: false,
                title: 'Referral Code',
                description: 'Attaching a referral code to your reservation can provide the customer or the company associated with the referral code with additional benefits.',
                value: reservation.referral_code,
                component: 'textfield',
                props: {useDelay: true},
                onChange: val => onUpdateTarget({ referral_code: val })
            },{
                key: 'flight_number',
                required: false,
                title: 'Flight Number',
                description: 'Attaching a flight number to a reservation allows Seeds to track the estimated arrival and departure for that flight. An example flight number would be "AA1090" for American Airlines flight #1090. We do not need to know your gate, terminal, estimated departure, or estimated arrival.',
                value: reservation.special_requests ? reservation.special_requests.flight : null,
                component: 'textfield',
                onChange: onValidateFlightNumber,
                props: {
                    useDelay: true,
                    loading: loading === 'flight_number'
                },
                invalid: flightNumberValid === false && {
                    value: true,
                    text: 'Invalid Flight Number'
                }
            }]
        }];
        return fields;
    }

    const getOverrideButtons = props => {
        if(utils.user.get().level > User.level.admin) {
            return null;
        }
        return {
            buttons: [{
                key: 'override',
                title: 'Admin Override',
                style: 'destructive'
            },{
                key: 'dismiss',
                title: 'Dismiss',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'override') {
                    setOverrides([
                        ...overrides,
                        props
                    ]);
                    return;
                }
            }
        }
    }

    const getRequirements = {
        vehicle: () => {
            if(!reservation.vehicle) {
                if(!overrides.includes('vehicle')) {
                    return {
                        title: 'Vehicle Type',
                        message: 'Please choose a vehicle to continue',
                        ...getOverrideButtons('vehicle')
                    }
                }
            }
        },
        pickupDate: () => {
            if(abstract.object.isAppClipRide()) {
                return null;
            }
            if(!reservation.service) {
                if(!overrides.includes('service')) {
                    return {
                        title: 'Service Type',
                        message: 'Please choose a service to continue',
                        ...getOverrideButtons('service')
                    }
                }
            }
            if(reservation.service.min_booking_time && moment().add(reservation.service.min_booking_time, 'seconds') > moment(reservation.pickup_date)) {
                if(!overrides.includes('service-min-booking-time')) {
                    return {
                        title: reservation.service.name,
                        message: `The "${reservation.service.name}" service requires a minimum of ${Utils.parseDuration(reservation.service.min_booking_time)} of notice before the pickup time and date. Please choose a later pickup time or a different service`,
                        ...getOverrideButtons('service-min-booking-time')
                    }
                }
            }
        },
        service: () => {
            if(!reservation.service) {
                if(!overrides.includes('service')) {
                    return {
                        title: 'Service Type',
                        message: 'Please choose a service to continue',
                        ...getOverrideButtons('service')
                    }
                }
            }
            if(reservation.service.min_distance && reservation.distance.estimate < reservation.service.min_distance) {
                if(!overrides.includes('service-min-distance')) {
                    return {
                        title: reservation.service.name,
                        message: `This service requires a minimum distance ${Utils.distanceConversion(reservation.service.min_distance)} from pickup to drop-off`,
                        ...getOverrideButtons('service-min-distance')
                    }
                }
            }
            if(reservation.service.radius_center && reservation.service.max_distance) {
                if(Utils.linearDistance(reservation.origin.location, reservation.service.radius_center) > reservation.service.max_distance) {
                    if(!overrides.includes('service-max-radius')) {
                        return {
                            title: reservation.service.name,
                            message: `The pickup location for this reservation is ${Utils.distanceConversion(Utils.linearDistance(reservation.origin.location, reservation.service.radius_center) - reservation.service.max_distance)} outside of our operating readius. Please choose a new pickup location or try selecting a different service`,
                            ...getOverrideButtons('service-max-radius')
                        }
                    }
                }
                if(reservation.destination && reservation.destination.location) {
                    if(Utils.linearDistance(reservation.destination.location, reservation.service.radius_center) > reservation.service.max_distance) {

                        if(!overrides.includes('service-max-radius')) {
                            return {
                                title: reservation.service.name,
                                message: `The drop-off location for this reservation is ${Utils.distanceConversion(Utils.linearDistance(reservation.destination.location, reservation.service.radius_center) - reservation.service.max_distance)} outside of our operating readius. Please choose a new drop-off location or try selecting a different service`,
                                ...getOverrideButtons('service-max-radius')
                            }
                        }
                    }
                }
            }
            if(!reservation.destination.location && !reservation.service.bookForLater.allowed) {
                if(!overrides.includes('service-book-for-later')) {
                    return {
                        title: reservation.service.name,
                        message: 'This service does not support choosing your drop-off location at a later date. Please choose a drop-off location or select a new service',
                        ...getOverrideButtons('service-book-for-later')
                    }
                }
            }
            if(reservation.service.billing === 'fixed' && reservation.distance.estimate > reservation.service.max_distance) {
                if(!overrides.includes('service-fixed-max-distance')) {
                    let estimate = parseFloat(reservation.distance.estimate).toFixed(2);
                    return {
                        title: reservation.service.name,
                        message: `This service allows a maximum distance of ${reservation.service.max_distance} ${reservation.service.max_distance === 1 ? 'mile' : 'miles'} and your trip is estimated at ${estimate} ${estimate === 1 ? 'mile' : 'miles'}. Please choose a new service or a different pickup/drop-off location.`,
                        ...getOverrideButtons('service-fixed-max-distance')
                    }
                }
            }
        },
        credits: () => {
            if(reservation.credits && reservation.credits.amount && reservation.service.billing === 'hourly') {
                if(!overrides.includes('credits-hourly')) {
                    return {
                        title: reservation.service.name,
                        message: 'This service is an hourly based service and does not support credits. Please remove the credits from this reservation or choose a different service',
                        ...getOverrideButtons('credits-hourly')
                    }
                }
            }
        }
    }

    const getStopsOverview = () => {
        let { locations } = reservation.stops || {};
        if(!locations || locations.length === 0) {
            return { value: 'Click to make changes...' };
        }
        return {
            value: `${locations.length} ${locations.length === 1 ? 'stop' : 'stops'} selected`,
            content: (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {locations.map((location, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: location.name || 'Name Not Available',
                                subTitle: Utils.formatAddress(location),
                                hideIcon: true,
                                bottomBorder: index !== locations.length - 1
                            })
                        )
                    })}
                </div>
            )
        }
    }

    const fetchListItems = async () => {
        try {
            // fetch all active services
            let { services } = await fetchServices(utils);
            setServices(services);

            // fetch all active vehicle categories
            let { categories } = await fetchVehicleCategories(utils, { show_unavailable: false });
            setVehicles(categories);

            // fetch all acive companies if current user is an administrator
            if(utils.user.get().level <= User.level.admin) {
                let { companies } = await fetchCompanies(utils);
                setCompanies(companies);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services and vehicle categories. ${e.message || 'An unknown error occurred'}`
            })
        }
    }
    const setupTargets = async () => {
        try {

            // setup editing
            let edits = abstract.object.open();
            setReservation(edits);

            // fetch default booking targets
            let { service, vehicle_category } = await Request.get(utils, '/reservation/', {
                type: 'default_booking_targets',
                date: moment.utc().unix()
            });

            // declare props for target edits
            let props = {
                vehicle: vehicle_category && Vehicle.Category.create(vehicle_category),
                service: service && Service.create(service)
            };
            if(utils.user.get().level === User.level.company_admin) {
                props.company = utils.user.get().company;
            }

            // update edits and set history state for edit discards
            edits = abstract.object.set(props);
            setHistoryObject(edits);
            setReservation(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services and vehicle categories. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupTargets();
        fetchListItems();
    }, []);

    useState(() => {
        onUpdatePaymentMethod();
    }, [reservation.customer]);

    useEffect(() => {
        onUpdateCostBreakdown();
    }, [reservation.origin, reservation.destination, reservation.stops, reservation.promo_code, reservation.credits, reservation.vehicle, reservation.service, reservation.company, reservation.referral_code]);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'Confirm New Reservation' : `Editing Reservation #${abstract.getID()}`}
        index={index}
        dropDown={dropDown}
        buttons={getButtons()}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}>
            {getContent()}
        </Layer>
    )
}

export const AddEditRestrictedDateTime = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-restricted-date-time' : `edit-restricted-date-time-${abstract.getID()}`;
    const [focus, setFocus] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [restriction, setRestriction] = useState(null);

    const onSubmit = async () => {

        let required = [{
            title: 'title',
            object: restriction.title
        },{
            title: 'message',
            object: restriction.message
        },{
            title: 'type',
            object: restriction.type
        },{
            title: 'start and end time',
            object: restriction.type === 'time' ? restriction.startTime && restriction.endTime : true
        },{
            title: 'start and end date',
            object: restriction.type === 'date' ? restriction.start_date && restriction.end_date : true
        }]

        let remaining = required.filter(entry => !entry.object)
        if(remaining.length > 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please fill out the ${remaining[0].title} before moving on`
            })
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" restricted ${restriction.type} has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this restricted ${restriction.type}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setRestriction(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!restriction) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for a booking restriction is shown to administrators, drivers, and customers. The name should be a one to two word phrase describing what this restriction means.',
                value: restriction.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'message',
                title: 'Message',
                description: 'The message for a booking restriction is shown to administrators, drivers, and customers. The description should be a sentence or phrase describing why the restriction is in place.',
                value: restriction.message,
                component: 'textview',
                onChange: text => onUpdateTarget({ message: text })
            },{
                key: 'type',
                title: 'Type',
                description: 'A restriction can either be a single date, a date range, or a time range. Dates are helpful for blacking out holidays or company parties. Times are helpful for blocking out early hours of the morning when drivers will be unavailable.',
                value: restriction.type ? (restriction.type === 'time' ? 'Time Range' : 'Single Date or Date Range') : 'Not Set',
                component: 'list',
                onChange: item => onUpdateTarget({ type: item && item.id }),
                items: [{
                    id: 'time',
                    title: 'Time Range'
                },{
                    id: 'date',
                    title: 'Single Date or Date Range'
                }]
            },{
                key: 'times',
                required: restriction.type === 'time',
                visible: restriction.type === 'time',
                title: 'Start and End Time',
                description: 'A restriction can either be a single date, a date range, or a time range. Dates are helpful for blacking out holidays or company parties. Times are helpful for blocking out early hours of the morning when drivers will be unavailable.',
                value: restriction.start_time && restriction.end_time ? `${moment(restriction.start_time, 'HH:mm:ss').format('h:mma')} to ${moment(restriction.end_time, 'HH:mm:ss').format('h:mma')}` : null,
                component: 'time_range_picker',
                onChange: item => onUpdateTarget({ type: item && item.id }),
                props: {
                    selectedStartTime: restriction.start_time,
                    selectedEndTime: restriction.end_time,
                    onStartTimeChange: time => onUpdateTarget({ start_time: time }),
                    onEndTimeChange: time => onUpdateTarget({ end_time: time })
                }
            },{
                key: 'start_date',
                required: restriction.type === 'date',
                visible: restriction.type === 'date',
                title: 'Start Date',
                description: 'The chosen dates can cover anywhere from 1 minute to the whole year. Dates include a day, month, and year as well as a time component.',
                value: restriction.start_date,
                component: 'date_picker',
                onChange: date => onUpdateTarget({ start_date: date })
            },{
                key: 'end_date',
                required: restriction.type === 'date',
                visible: restriction.type === 'date',
                title: 'End Date',
                description: 'The chosen dates can cover anywhere from 1 minute to the whole year. Dates include a day, month, and year as well as a time component.',
                value: restriction.end_date,
                component: 'date_picker',
                onChange: date => onUpdateTarget({ end_date: date })
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setRestriction(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Restricted Time or Date' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setRestriction(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditReservationMood = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-reservation-mood' : `edit-reservation-mood-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [focus, setFocus] = useState(null);
    const [mood, setMood] = useState(null);

    const onSubmit = async () => {
        try {
            if(loading === true) {
                return;
            }
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" mood has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this mood. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setMood(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!mood) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for a mood is shown to administrators, drivers, and customers. The name should be a one to two word phrase describing what this mood offers.',
                value: mood.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'information',
                title: 'Description',
                description: 'The description for a mood is shown to administrators, drivers, and customers. The description should be a sentence or phrase describing what the mood offers.',
                value: mood.information,
                component: 'textview',
                onChange: text => onUpdateTarget({ information: text })
            },{
                key: 'image',
                title: 'Image',
                description: 'The image for a mood is shown to customers during the booking process. The image should be a white square icon, with a transparent background, that illustrates the purpose of the Mood. It is recommened that all mood images follow the same design language.',
                value: mood.image,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ image: image }),
                props: {
                    imageStyle: {
                        padding: 5
                    }
                }
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setMood(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Mood' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setMood(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditOnDemandRadiusGroups = ({ onChange, value }, { index, options, utils }) => {

    const layerID = 'edit_on_demand_radius_groups';

    const [activeGroupIndex, setActiveGroupIndex] = useState(0);
    const [groups, setGroups] = useState(value && value.groups || []);
    const [layerState, setLayerState] = useState(null);
    const [maxBroadcastPlace, setMaxBroadcastPlace] = useState(null);
    const [preferences, setPreferences] = useState(value && value.preferences || {});

    const onChangeLocation = result => {

        // update index for active group to select the most recent group
        setActiveGroupIndex(groups.length);

        // update list of radius groups
        setGroups(groups => {
            return groups.concat([{
                duration: 30,
                place: result,
                radius: 10,
                synchronous: false
            }])
        })
    }

    const onChangeSliderValue = (key, val) => {
        setGroups(groups => {
            return update(groups, {
                [activeGroupIndex]: {
                    [key]: {
                        $set: val
                    }
                }
            });
        });
    }

    const onConfirmClick = () => {
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange({ groups, preferences });
        }
    }

    const onOptionsClick = (index, evt) => {
        evt.stopPropagation();
        utils.sheet.show({
            items: [{
                key: 'status',
                title: groups[index].active === false ? 'Activate' : 'Deactivate',
                style: groups[index].active === false ? 'default' : 'destructive'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                onRemoveGroup(index);
                return;
            }
            if(key === 'status') {
                onSetGroupStatus(index);
                return;
            }
        })
    }

    const onRemoveGroup = index => {
        utils.alert.show({
            title: 'Remove Radius Group',
            message: 'Are you sure that you want to remove this radius group? This can not be undone',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }] ,
            onClick: key => {
                if(key === 'confirm') {

                    // update active group index if currently selected index is the index to be removed
                    if(index === activeGroupIndex) {
                        setActiveGroupIndex(0);
                    }

                    // remove selected groups from list of radius groups
                    setGroups(groups => {
                        return update(groups, {
                            $splice: [[index, 1]]
                        });
                    });
                }
            }
        });
    }

    const onRequestMaxBroadcastPlace = () => {

        let place = null;
        utils.alert.show({
            title: 'Preview Broadcast Radius',
            message: `Search for a name or address below to select a pickup location. We'll use this location for the center of the broadcast preview`,
            component: (
                <div style={{
                    paddingBottom: 15,
                    paddingLeft: 15,
                    paddingRight: 15,
                    width: '100%'
                }}>
                    <AddressLookupField
                    onChange={result => place = result}
                    placeholder={'Search by name or address...'} 
                    utils={utils}/>
                </div>
            ),
            buttons: [{
                key: 'confirm',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }] ,
            onClick: key => {
                if(place && key === 'confirm') {
                    setMaxBroadcastPlace(place.location);
                }
            }
        });
    }

    const onSetGroupStatus = index => {
        utils.alert.show({
            title: `${groups[index].active === false ? 'Activate' : 'Deactivate'} Group`,
            message: `Are you sure that you want to ${groups[index].active === false ? 'activate' : 'deactivate'} this group? This means that this radius group ${groups[index].active === false ? 'will' : 'will no longer'} be used when broadcasting on-demand notifications to drivers`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${groups[index].active === false ? 'Activate' : 'Deactivate'}`,
                style: groups[index].active === false ? 'default' : 'destructive'
            }] ,
            onClick: key => {
                if(key === 'confirm') {
                    setGroups(groups => {
                        return update(groups, {
                            [activeGroupIndex]: {
                                active: {
                                    $set: groups[index].active === false ? true : false
                                }
                            }
                        });
                    });
                }
            }
        });
    }

    const getButtons = () => {
        return [{
            color: 'grey',
            key: 'cancel',
            onClick: setLayerState.bind(this, 'close'),
            text: 'Cancel',
        },{
            color: 'primary',
            key: 'confirm',
            onClick: onConfirmClick,
            text: 'Done'
        }]
    }

    const getCircles = () => {
        
        // prepare circles using the previously defined radius groups
        let circles = groups.map(group => ({
            id: `group-${index}`,
            coordinate: group.place.location,
            radius: group.radius
        }));

        // add a circle to represent the maximum broadcast radius
        if(maxBroadcastPlace) {
            circles.push({
                id: 'max_broadcast_radius',
                color: Appearance.colors.blue,
                coordinate: maxBroadcastPlace,
                radius: preferences.max_broadcast_radius
            });
        }

        return circles;
    }

    const getFields = () => {
        return [{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                key: 'vehicle_match',
                title: 'Require Vehicle Match',
                description: 'If enabled, and if the service allows a vehicle selection, the broadcast will only be received by drivers who are currently driving a vehicle that matches the vehicle requested by the customer.',
                component: 'bool_list',
                value: preferences.vehicle_match || false,
                onChange: val => {
                    setPreferences(prev => ({
                        ...prev,
                        vehicle_match: val
                    }));
                }
            },{
                key: 'synchronous',
                title: 'Broadcast Order',
                description: 'When broadcasting to more than one radius group, the system can notify drivers in one group after another or all groups at one time',
                component: 'list',
                value: {
                    id: preferences.synchronous ? 'synchronous' : 'asynchronous',
                    title: preferences.synchronous ? 'One at a time' : 'All at once'
                },
                items: [{
                    id: 'asynchronous',
                    title: 'All at once'
                },{
                    id: 'synchronous',
                    title: 'One at a time'
                }],
                onChange: item => {
                    setPreferences(prev => ({
                        ...prev,
                        synchronous: item && item.id === 'synchronous' ? true : false
                    }));
                }
            },{
                key: 'max_broadcast_radius',
                title: 'Maximum Broadcast Radius',
                description: 'The maximum broadcast radius, in miles, represents the maximum distance between the pickup location and the edge of a radius group. A radius group that overlaps with the maximum broadcast radius will be considered eligible for a driver broadcast. Click the "Preview" button to see a visualization of your radius.',
                component: 'textfield',
                value: preferences.max_broadcast_radius,
                props: { format: 'number' },
                onChange: val => {
                    setPreferences(prev => ({
                        ...prev,
                        max_broadcast_radius: parseFloat(val)
                    }));
                },
                buttons: [{
                    color: maxBroadcastPlace ? Appearance.colors.grey() : Appearance.colors.blue,
                    onClick: maxBroadcastPlace ? setMaxBroadcastPlace.bind(this, null) : onRequestMaxBroadcastPlace,
                    text: maxBroadcastPlace ? 'Clear Preview' : 'Preview',
                }]
            }]
        }]
    }

    const getGroups = () => {
        return groups.length > 0 && (
            <div style={{
                marginTop: 25,
                paddingLeft: 15,
                paddingRight: 15
            }}>
                <LayerItem 
                collapsed={false}
                shouldStyle={false}
                title={'Radius Groups'}>
                    {groups.map((group, index) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: 8,
                                width: '100%'
                            }}>
                                {Views.entry({
                                    badge: group.active === false && {
                                        color: Appearance.colors.grey(),
                                        text: 'Inactive'
                                    },
                                    hideArrow:true,
                                    hideIcon: true,
                                    key: index,
                                    onClick: setActiveGroupIndex.bind(this, index),
                                    rightContent: (
                                        <img
                                        className={'text-button'}
                                        onClick={onOptionsClick.bind(this, index)}
                                        src={`images/details-button-clear.png`}
                                        style={{
                                            backgroundColor: Appearance.colors.grey(),
                                            borderRadius: 10,
                                            height: 20,
                                            marginLeft: 8,
                                            objectFit: 'contain',
                                            opacity: 1,
                                            width: 20
                                        }} />
                                    ),
                                    subTitle: `Broadcast ${group.radius} mile radius for ${parseDuration(group.duration)}`,
                                    title: group.place && (group.place.name || group.place.address) || 'Name and Address Not Available'
                                })}
                                {index === activeGroupIndex && (
                                    <>
                                    <div style={{
                                        alignItems: 'center',
                                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        paddingBottom: 8,
                                        paddingLeft: 12,
                                        paddingRight: 8,
                                        paddingTop: 8,
                                        width: '100%',
                                    }}>
                                        <CustomSlider
                                        step={1}
                                        min={0}
                                        max={50}
                                        value={group.radius}
                                        onChange={value => onChangeSliderValue('radius', value)} />
                                        <div style={{
                                            alignItems: 'center',
                                            border: `1px solid ${Appearance.colors.divider()}`,
                                            borderRadius: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            marginLeft: 12,
                                            minWidth: 85,
                                            padding: 6
                                        }}>
                                            <span style={{
                                                ...Appearance.textStyles.subTitle(),
                                                color: Appearance.colors.text(),
                                            }}>{`${group.radius} ${group.radius === 1 ? 'mile' : 'miles'}`}</span>
                                        </div>
                                    </div>
                                    <div style={{
                                        alignItems: 'center',
                                        borderTop: `1px solid ${Appearance.colors.divider()}`,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        paddingBottom: 8,
                                        paddingLeft: 12,
                                        paddingRight: 8,
                                        paddingTop: 8,
                                        width: '100%',
                                    }}>
                                        <CustomSlider
                                        step={1}
                                        min={1}
                                        max={120}
                                        value={group.duration}
                                        onChange={value => onChangeSliderValue('duration', value)} />
                                        <div style={{
                                            alignItems: 'center',
                                            border: `1px solid ${Appearance.colors.divider()}`,
                                            borderRadius: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            marginLeft: 12,
                                            minWidth: 85,
                                            padding: 6
                                        }}>
                                            <span style={{
                                                ...Appearance.textStyles.subTitle(),
                                                color: Appearance.colors.text(),
                                            }}>{parseDuration(group.duration)}</span>
                                        </div>
                                    </div>
                                    </>
                                )}
                            </div>
                        )
                    })}
                </LayerItem>
           </div>
        ) 
    }

    const getGroupPreferences = () => {
        return groups.length > 0 && (
            <div style={{
                marginBottom: 15,
                paddingLeft: 15,
                paddingRight: 15
            }}>
                <AltFieldMapper
                fields={getFields()}
                utils={utils} />
            </div>
        )
    }

    const getMapComponents = () => {
        return (
            <div style={{
                borderBottom: groups.length > 0 ? `1px solid ${Appearance.colors.divider()}` : null,
                padding: 12
            }}>
                <Map
                autoViewportUpdates={false}
                circles={getCircles()}
                isRotationEnabled={true}
                isScrollEnabled={true}
                isZoomEnabled={true}
                utils={utils}
                style={{
                    height: 325,
                    marginBottom: 12
                }}/>

                <AddressLookupField
                geocode={true}
                onChange={onChangeLocation}
                placeholder={'Search by name or address to add a new group...'}
                preserveResult={false}
                utils={utils} />
            </div>
        )
    }

    const parseDuration = seconds => {
        if(seconds < 60) {
            return `${seconds}s`;
        }
        let minutes = parseInt(seconds / 60);
        let remainder = Math.floor(seconds % 3600 % 60);
        return `${minutes}m ${remainder > 0 ? `${remainder}s` : ''}`;
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Editing On-Demand Radius Groups'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            removePadding: true
        }}>
            {getMapComponents()}
            {getGroups()}
            {getGroupPreferences()}
        </Layer>
    )
}

export const AddEditService = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-service' : `edit-service-${abstract.getID()}`;
    const tempContent = useRef(null);

    const bookingOptions = ['id', 'service', 'vehicle', 'pickup_date', 'origin', 'stops', 'destination', 'passengers_and_luggage', 'payment', 'mood_and_music', 'flight_number', 'requests', 'promo_code', 'driver_tip'];
    const methods = ['card', 'credits', 'subscription'];

    const [channels, setChannels] = useState([]);
    const [dropDown, setDropDown] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [service, setService] = useState(null);

    const onEditAutoRejectionProps = (key, props) => {
        utils.layer.open({
            id: 'edit_auto_reject_preferences',
            Component: EditAutoRejectionPreferences.bind(this, {
                value: props,
                onChange: result => {
                    onUpdateTarget({ 
                        auto_reject: {
                            ...service.auto_reject,
                            [key]: result
                        } 
                    });
                }
            })
        });
    }

    const onEditOnDemandRadiusGroups = value => {
        utils.layer.open({
            id: 'edit_on_demand_radius_groups',
            Component: AddEditOnDemandRadiusGroups.bind(this, {
                value,
                onChange: result => {
                    onUpdateTarget({ 
                        on_demand: {
                            ...service.on_demand,
                            radius_groups: result
                        } 
                    });
                }
            })
        });
    }

    const onLocationClick = entry => {
        utils.sheet.show({
            items: [{
                key: 'radius',
                title: 'Change Radius',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'radius') {
                onSelectLocationRadius(entry);
                return;
            }
            if(key === 'remove') {
                onRemoveLocation(entry);
                return;
            }
        })
    }

    const onRemoveLocation = entry => {
        utils.alert.show({
            title: 'Remove Location',
            message: 'Are you sure that you want to remove this location?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    setLocations(location => locations.filter(location => {
                        return location.id !== entry.id;
                    }));
                    return;
                }
            }
        })
    }

    const onSelectLocationRadius = entry => {
        let miles = entry.radius;
        utils.alert.show({
            title: 'Location Radius',
            message: 'We use the radius to draw a circle around your service area so we can accept or prevent orders and rides from undesired areas. The radius for a location is measured in miles.',
            component: (
                <div style={{
                    padding: 15,
                    width: '100%'
                }}>
                    <NumberStepper
                    min={0}
                    startingValue={entry.radius}
                    onChange={value => miles = value}/>
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'done') {
                    setLocations(locations => locations.map(location => {
                        if(location.id === entry.id) {
                            location.radius = miles;
                        }
                        return location;
                    }))
                    return;
                }
            }
        })
    }

    const onServiceAreaClick = index => {
        let location = locations[index];
        utils.alert.show({
            title: 'Change Radius',
            message: 'The radius is used to measure the size of your operating area. Please input a radius value in miles below.',
            textFields: [{
                key: 'radius',
                placeholder: '25 Miles',
                value: `${locations[index].radius} ${locations[index].radius === 1 ? 'Mile' : 'Miles'}`
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: ({ radius }) => {
                if(!radius) {
                    return;
                }
                let value = parseFloat(radius.toString().replace(/[^\d.-]/g,''));
                if(value >= 0) {
                    setLocations(locations => update(locations, {
                        [index]: {
                            radius: {
                                $set: value
                            }
                        }
                    }))
                }
            }
        })
    }

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget, {
                locations: locations.map(entry => ({
                    ...entry,
                    location: {
                        lat: entry.location.latitude || entry.location.lat,
                        long: entry.location.longitude || entry.location.long
                    }
                }))
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" service has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this service. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setService(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!service) {
            return [];
        }
        let fields = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a service is shown to administrators, drivers, and customers. The name should be a one to two word phrase describing the service',
                value: service.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'information',
                title: 'Description',
                description: 'The description for a service is shown to administrators, drivers, and customers. The description should be a sentence or phrase describing the service',
                value: service.information,
                component: 'textview',
                onChange: text => onUpdateTarget({ information: text })
            },{
                key: 'channel',
                title: 'Channel',
                description: 'Services are created for the standard method of ride booking by default. If needed, you can set this service to be an option for a specialty channel like QuickScan or orders.',
                value: service.channel,
                component: 'list',
                items: channels.map(channel => ({
                    id: channel.id,
                    title: channel.name
                })),
                onChange: item => {
                    let channel = item && channels.find(channel => channel.id === item.id);
                    onUpdateTarget({
                        channel: channel && channel.id,
                        order_channel: channel && typeof(channel.id) !== 'string' ? channel : null
                    });
                }
            },{
                key: 'default_for_channel',
                visible: service.channel ? true : false,
                title: 'Default for Channel',
                description: 'Enabling will set this service as the fallback service when a customer does not make an explicit selection.',
                value: service.default_for_channel,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ default_for_channel: val })
            }]
        }]

        fields = fields.concat([{
            key: 'booking',
            collapsed: true,
            title: 'Booking',
            items: [{
                key: 'disclaimer',
                title: 'Disclaimer',
                description: 'The disclaimer is shown to a customer during booking. We recommend using the feature to explain any technical details around how and when a customer is billed. An example would be explaining that unexpected weather or driving conditions may change the estimate shown during booking.',
                value: service.disclaimer,
                component: 'textview',
                onChange: text => onUpdateTarget({ disclaimer: text })
            },{
                key: 'should_tip_driver',
                title: 'Driver Tipping',
                description: 'Enabling driver tipping will allow a customer to leave a tip for their driver during the booking process. We recommend enabling this feature.',
                value: service.should_tip_driver,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ should_tip_driver: val })
            },{
                key: 'should_book_return_trip',
                required: false,
                visible: service.channel === 'standard',
                title: 'Return Trips',
                description: 'Enabling return trips allows the customer to quickly book a ride from their destination back to the original pickup without going through the whole booking process again.',
                value: service.should_book_return_trip,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ should_book_return_trip: val })
            },{
                key: 'required_options',
                title: 'Required Options',
                description: 'A booking option is a selection that a customer can make during booking. Not all options are required but you can choose to make some options mandatory. We recommend making the pickup time and date required as well as the payment method.',
                component: 'multiple_list',
                value: service.required_options && service.required_options.map(opt => ({
                    id: opt,
                    title: abstract.object.optionText(opt)
                })),
                items: bookingOptions.map(opt => ({
                    id: opt,
                    title: abstract.object.optionText(opt)
                })),
                onChange: items => {
                    onUpdateTarget({
                        required_options: items && items.map(item => {
                            return item.id;
                        })
                    });
                }
            },{
                key: 'options',
                title: 'Available Options',
                description: 'A booking option is a selection that a customer can make during booking. One or more options can be hidden if they do not fall within your desired customer journey. We recommend making all options available for most scenarios.',
                component: 'multiple_list',
                value: service.options && service.options.map(opt => ({
                    id: opt,
                    title: abstract.object.optionText(opt)
                })),
                items: bookingOptions.map(opt => ({
                    id: opt,
                    title: abstract.object.optionText(opt)
                })),
                onChange: items => {
                    onUpdateTarget({
                        options: items && items.map(item => {
                            return item.id;
                        })
                    });
                }
            }]
        }]);

        fields = fields.concat([{
            key: 'payments',
            collapsed: true,
            title: 'Payments',
            items: [{
                key: 'billing',
                title: 'Billing',
                description: 'Services can be billed by mileage, time, or by the hour. A mileage based service would be recommended for further trips and a time based service would be recommended for areas with heavy trafic. Dynamic services are recommended when needing to bill for the higher of the two (mileage or time). Passive services are recommended when needing to bill for the lower of the two (mileage or time).',
                value: service.billing,
                component: 'list',
                onChange: item => onUpdateTarget({ billing: item && item.id }),
                items: [{
                    id: 'mileage',
                    title: 'Mileage'
                },{
                    id: 'duration',
                    title: 'Duration'
                },{
                    id: 'hourly',
                    title: 'Hourly'
                },{
                    id: 'dynamic',
                    title: 'Dynamic'
                },{
                    id: 'passive',
                    title: 'Passive'
                }]
            },{
                key: 'payment_methods',
                title: 'Accepted Methods',
                description: 'We allow credit and debit cards as well as digital credits as payment methods. We recommend allowing all options for the most flexibility',
                component: 'multiple_list',
                value: service.payment_methods && service.payment_methods.map(method => ({
                    id: method,
                    title: Utils.ucFirst(method)
                })),
                items: methods.map(method => ({
                    id: method.id,
                    title: Utils.ucFirst(method)
                })),
                onChange: items => {
                    onUpdateTarget({
                        payment_methods: items && items.map(item => {
                            return item.id
                        })
                    });
                }
            }]
        }]);

        if(service.channel !== 'orders') {
            fields = fields.concat([{
                key: 'scheduled',
                collapsed: true,
                title: 'Scheduled Rides',
                items: [{
                    key: 'auto_reject',
                    required: false,
                    title: 'Auto-Rejection for Aging Rides',
                    description: `When an ride is submitted, and automatic approvals are disabled, it will enter the system as a pending ride. Auto rejection will automatically decline the ride if it has not been approved after a certain amount of time.`,
                    component: 'auto_rejection_editor',
                    value: service.auto_reject && service.auto_reject.scheduled,
                    onEditClick: onEditAutoRejectionProps.bind(this, 'scheduled', service.auto_reject && service.auto_reject.scheduled)
                },{
                    key: 'should_preauthorize',
                    required: false,
                    title: 'Preauthorizations',
                    description: `Enabling preauthorizations will place a hold on the customer's payment method for the estimated cost of the service. The charge will be captured at the end of the reservation and any overages will be billed as a separate transaction. Preauthorizations may be shown as a charge by some financial institutions.`,
                    value: service.should_preauthorize,
                    component: 'bool_list',
                    onChange: val => onUpdateTarget({ should_preauthorize: val }),
                    props: {
                        enabled: 'Enabled',
                        disabled: 'Disabled'
                    }
                },{
                    key: 'preauthorize',
                    required: service.should_preauthorize,
                    visible: service.should_preauthorize ? true : false,
                    title: 'Preauthorization Disclaimer',
                    description: `The disclaimer for a preauthorization is shown to the customer during booking. This message should explain what a preauthorization means and what the customer should expect.`,
                    value: service.preauthorize,
                    component: 'textview',
                    onChange: text => onUpdateTarget({ preauthorize: text })
                },{
                    key: 'should_decline_on_preauthorization_failure',
                    required: false,
                    visible: service.should_preauthorize ? true : false,
                    title: 'Decline Ride if Preauthorization Fails',
                    description: `When preauthorizations are enabled you have the ability to automatically reject an order or reservation if the customer's payment method is declined by their bank.`,
                    value: service.should_decline_on_preauthorization_failure,
                    component: 'bool_list',
                    onChange: val => onUpdateTarget({ should_decline_on_preauthorization_failure: val })
                }]
            },{
                key: 'on_demand',
                collapsed: true,
                title: 'On-Demand Rides',
                items: [{
                    key: 'enabled',
                    title: 'Enabled',
                    description: `By default, customer's are required to choose a time and date for their scheduled pickup. Enabling this feature will allow a customer to request a ride or order for immediate pickup. This does not remove the ability for a customer to schedule a time and date for their order or reservation.`,
                    value: service.on_demand && service.on_demand.enabled || false,
                    component: 'bool_list',
                    onChange: val => {
                        onUpdateTarget({ 
                            on_demand: {
                                ...service.on_demand,
                                enabled: val
                            } 
                        });
                    }
                },{
                    key: 'booking_default',
                    required: false,
                    visible: service.on_demand && service.on_demand.enabled === true,
                    title: 'Default for Booking',
                    description: `By default, customer's are required to choose a time and date for their scheduled pickup. Enabling this feature will automatically set each new reservation and order booking to immediate pickup. This does not remove the ability for a customer to schedule a time and date for their order or reservation.`,
                    value: service.on_demand && service.on_demand.booking_default,
                    component: 'bool_list',
                    onChange: val => {
                        onUpdateTarget({ 
                            on_demand: {
                                ...service.on_demand,
                                booking_default: val
                            }
                        });
                    }
                },{
                    key: 'auto_reject',
                    required: false,
                    title: 'Auto-Rejection for Aging Rides',
                    description: `When an on-demand ride is submitted, the system will send a broadcast message to all eligible drivers. If not drivers are found, we will prompt the customer to schedule their ride for a specific time an date. If the customer opts to schedule their ride, the ride may be submitted as a pending ride. Auto rejection will automatically decline the ride if it has not been approved after a certain amount of time.`,
                    component: 'auto_rejection_editor',
                    value: service.auto_reject && service.auto_reject.on_demand,
                    onEditClick: onEditAutoRejectionProps.bind(this, 'on_demand', service.auto_reject && service.auto_reject.on_demand)
                },{
                    key: 'radius_groups',
                    required: false,
                    visible: service.on_demand && service.on_demand.enabled === true,
                    title: 'Radius Groups',
                    description: `Driver's will be notified imemdiately when an on-demand order or ride is requested. The system will start from the pickup location and broadcast a message to all drivers within a predefined radius. Defining one or more radius groups tell the system how large the broadcast radius should be and whether or not to broadcast to more than one area. The broadcast to these radius groups can be staggered or they can take place all at once.`,
                    component: 'radius_groups_editor',
                    value: service.on_demand && service.on_demand.radius_groups,
                    onEditClick: onEditOnDemandRadiusGroups.bind(this, service.on_demand && service.on_demand.radius_groups)
                },{
                    key: 'preauthorize',
                    required: false,
                    title: 'Preauthorizations',
                    description: `Enabling preauthorizations will place a hold on the customer's payment method for the estimated cost of the service. The charge will be captured at the end of the reservation and any overages will be billed as a separate transaction. Preauthorizations may be shown as a charge by some financial institutions.`,
                    value: service.on_demand && service.on_demand.preauthorize,
                    component: 'bool_list',
                    onChange: val => {
                        onUpdateTarget({ 
                            on_demand: {
                                ...service.on_demand,
                                preauthorize: val
                            }
                        });
                    },
                    props: {
                        enabled: 'Enabled',
                        disabled: 'Disabled'
                    }
                },{
                    key: 'preauthorization_disclaimer',
                    required: service.on_demand && service.on_demand.preauthorize ? true : false,
                    visible: service.on_demand && service.on_demand.preauthorize ? true : false,
                    title: 'Preauthorization Disclaimer',
                    description: `The disclaimer for a preauthorization is shown to the customer during booking. This message should explain what a preauthorization means and what the customer should expect.`,
                    value: service.on_demand && service.on_demand.preauthorization_disclaimer,
                    component: 'textview',
                    onChange: text => {
                        onUpdateTarget({ 
                            on_demand: {
                                ...service.on_demand,
                                preauthorization_disclaimer: text
                            }
                        });
                    }
                },{
                    key: 'decline_on_preauth_failure',
                    required: false,
                    visible: service.on_demand && service.on_demand.preauthorize ? true : false,
                    title: 'Decline Ride if Preauthorization Fails',
                    description: `When preauthorizations are enabled you have the ability to automatically reject an on-demand ride if the customer's payment method is declined by their bank.`,
                    value: service.on_demand && service.on_demand.decline_on_preauth_failure,
                    component: 'bool_list',
                    onChange: val => {
                        onUpdateTarget({ 
                            on_demand: {
                                ...service.on_demand,
                                decline_on_preauth_failure: val
                            }
                        });
                    }
                }] 
            }])
        }

        if(service.order_channel) {
            fields = fields.concat([{
                key: 'delivery_rates',
                collapsed: true,
                title: 'Delivery Rates',
                items: [{
                    key: 'base_rate',
                    title: 'Base Rate',
                    description: 'The base rate for an order based service determines the minimum cost of the order. This cost should be the minimum amount of revenue desired for any order',
                    value: service.delivery ? service.delivery.base_rate : null,
                    component: 'textfield',
                    props: {
                        prepend: '$'
                    },
                    onChange: text => {
                        onUpdateTarget({
                            delivery: update(service.delivery || {}, {
                                base_rate: {
                                    $set: text
                                }
                            })
                        });
                    }
                },{
                    key: 'mileage_rate',
                    title: 'Per Mile Rate',
                    description: 'The per mile rate for an order based service determines the cost of the order when using a dynamic, passive, or mileage based billing type',
                    value: service.delivery ? service.delivery.mileage_rate : null,
                    component: 'textfield',
                    props: {
                        prepend: '$'
                    },
                    onChange: text => {
                        onUpdateTarget({
                            delivery: update(service.delivery || {}, {
                                mileage_rate: {
                                    $set: text
                                }
                            })
                        });
                    }
                },{
                    key: 'duration_rate',
                    title: 'Per Minute Rate',
                    description: 'The per minute rate for an order based service determines the cost of the order when using a dynamic, passive, or duration based billing type.',
                    value: service.delivery ? service.delivery.duration_rate : null,
                    component: 'textfield',
                    props: {
                        prepend: '$'
                    },
                    onChange: text => {
                        onUpdateTarget({
                            delivery: update(service.delivery || {}, {
                                duration_rate: {
                                    $set: text
                                }
                            })
                        });
                    }
                },{
                    key: 'hourly_rate',
                    title: 'Hourly Rate',
                    description: 'The hourly rate for an order based service determines the cost of the order when using an hourly based billing type.',
                    value: service.delivery ? service.delivery.hourly_rate : null,
                    component: 'textfield',
                    props: {
                        prepend: '$'
                    },
                    onChange: text => {
                        onUpdateTarget({
                            delivery: update(service.delivery || {}, {
                                hourly_rate: {
                                    $set: text
                                }
                            })
                        });
                    }
                }]
            }])
        }

        return fields.concat([{
            key: 'restrictions',
            collapsed: true,
            title: 'Restrictions',
            items: [{
                key: 'min_distance',
                required: false,
                title: 'Minimum Distance',
                description: 'Minimum distance describes the minimum amount of miles allowed from pickup to customer drop-off. This restriction does not apply to rides that fall within one or more service areas. We recommend setting 5 miles to start.',
                value: service.min_distance,
                component: 'textfield',
                onChange: val => onUpdateTarget({ min_distance: val }),
                props: {
                    placeholder: 'Miles'
                }
            },{
                key: 'max_distance',
                required: false,
                title: 'Maximum Distance',
                description: 'Maximum distance describes the maximum amount of miles allowed from pickup to customer drop-off. This restriction does not apply to rides that fall within one or more service areas. We recommend setting 50 miles to start.',
                value: service.max_distance,
                component: 'textfield',
                onChange: val => onUpdateTarget({ max_distance: val }),
                props: {
                    placeholder: 'Miles'
                }
            },{
                key: 'min_booking_time',
                required: false,
                title: 'Booking Lead Time',
                description: 'The lead time for booking refers to the minimum amount of notice required prior to pickup. We recommend setting 45 minutes to start.',
                value: service.min_booking_time,
                component: 'textfield',
                onChange: val => onUpdateTarget({ min_booking_time: val }),
                props: {
                    placeholder: 'Minutes'
                }
            },{
                key: 'service_areas',
                required: false,
                title: 'Service Areas',
                description: `These locations represent your areas of operation. When an order or ride is submitted we'll check to see if the pickup and drop-off locations fall within one of your service areas. All orders and rides will be accepted if no locations are provided.`,
                value: locations,
                component: 'multiple_address_lookup',
                onChange: results => {
                    setLocations(locations => {
                        return results.map(entry => ({
                            id: `${Utils.randomString()}_${moment().unix()}`,
                            name: entry.name,
                            address: entry.address,
                            location: entry.location,
                            radius: 25,
                            ...locations.find(prev_location => {
                                return prev_location.id === entry.id
                            })
                        }));
                    });
                },
                props: {
                    onRenderResult: (location, index, locations, onRemoveClick) => (
                        Views.entry({
                            key: index,
                            title: location.name || 'Name Not Available',
                            subTitle: Utils.formatAddress(location),
                            hideIcon: true,
                            bottomBorder: index !== locations.length - 1,
                            badge: {
                                color: Appearance.colors.primary(),
                                text: `${location.radius} ${location.radius === 1 ? 'Mile' : 'Miles'}`
                            },
                            onClick: onServiceAreaClick.bind(this, index),
                            rightContent: (
                                <img
                                className={'text-button'}
                                src={'images/red-x-icon.png'}
                                onClick={onRemoveClick}
                                style={{
                                    width: 20,
                                    height: 20,
                                    marginLeft: 12
                                }} />
                            )
                        })
                    )
                }
            }]
        }]);
    }

    const fetchLocations = async () => {
        try {
            if(isNewTarget === true) {
                return;
            }
            let { locations } = await Request.get(utils, '/reservations/', {
                type: 'service_locations',
                id: abstract.getID()
            });
            setLocations(locations);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retriving the locations radius restrictions for this service. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTargets = async () => {
        try {

            // prepare local edits for target
            let edits = abstract.object.open();
            setService(edits);

            // fetch order channels 
            let { channels } = await fetchOrderChannels(utils);

            // add quick scan and scheduled ride channels 
            let targets = channels.concat([{
                id: 'quick_scan',
                name: 'QuickScan'
            },{
                id: 'standard',
                name: 'Rides'
            }]);

            // add drive experience channel for clients with that flavor 
            if(utils.client.flavors.enabled('vehicles.drive_experiences') === true) {
                targets.push({
                    id: 'vehicles.drive_experiences',
                    name: 'Drive Experiences'
                });
            }

            // sort channels by name and update state with channel options
            setChannels(targets.sort((a,b) => {
                return a.name.localeCompare(b.name);
            }));
            
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the order channels. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupTargets();
        fetchLocations();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Service' : `Editing "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setService(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const EditAutoRejectionPreferences = ({ onChange, value }, { index, options, utils }) => {

    const layerID = 'edit_auto_reject_preferences';

    const [layerState, setLayerState] = useState(null);
    const [preferences, setPreferences] = useState(value || {});

    const onConfirmClick = () => {

        // verify that at least 60 seconds was provided for the duration 
        if(preferences.enabled === true && preferences.duration < 60) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This minimum duration for an auto-rejection is 60 seconds. Please choose a duration of at least 60 seconds or greater to continue.'
            });
            return;
        }

        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(preferences);
        }
    }

    const onUpdateTarget = props => {
        setPreferences(prev => ({
            ...prev,
            ...props
        }));
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'confirm',
            onClick: onConfirmClick,
            text: 'Done'
        }]
    }

    const getFields = () => {
        return [{
            key: 'preferences',
            title: 'Preferences',
            items: [{
                component: 'bool_list',
                description: 'Enabling Auto Rejection will allow you to automatically decline rides if they have not been approved or declined within a certain period of time.',
                key: 'enabled',
                onChange: val => onUpdateTarget({ enabled: val }),
                title: 'Enabled',
                required: true,
                value: preferences.enabled ? true : false
            },{
                component: 'duration_picker',
                description: 'The auto rejection duration represents the time that the platform will wait before automatically rejecting a ride. The clock restarts each time a change or update is made to the ride.',
                key: 'duration',
                onChange: seconds => onUpdateTarget({ duration: parseInt(seconds) }),
                title: 'Duration',
                required: preferences.enabled ? true : false,
                value: preferences.duration,
                visible: preferences.enabled ? true : false
            },{
                component: 'bool_list',
                description: 'If needed, administrators can be notified through a push notification when an auto rejection has taken place',
                key: 'notify_administrators',
                onChange: val => onUpdateTarget({ notify_administrators: val }),
                title: 'Notify Administrators',
                value: preferences.notify_administrators ? true : false,
                visible: preferences.enabled ? true : false
            }]
        }]
    }

    useEffect(() => {

        // set preferences with a default duration of 60 seconds
        setPreferences({
            ...value,
            duration: value && value.duration > 60 ? value.duration : 60
        });

    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Editing Auto Rejection Preferences'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()}
            utils={utils} />
        </Layer>
    )
}

export const EditReservationAutoApprovals = ({ approvals }, { abstract, index, options, utils }) => {

    const layerID = 'edit-reservation-auto-approvals';
    const [autoApprovals, setAutoApprovals] = useState(null);
    const [dropDown, setDropDown] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);

    const onManageBuffer = () => {
        let props = autoApprovals.buffer || {};
        setDropDown({
            title: 'Buffer',
            message: 'Automatic approvals will approve or decline a reservation based on the service selected for the ride. You can choose as many or as few services as you would like.',
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ buffer: props })
            }],
            content: (
                <>
                <LayerItem
                title={'Type'}
                shouldStyle={false}>
                    <select
                    defaultValue={autoApprovals.buffer ? Utils.ucFirst(autoApprovals.buffer.type) : null}
                    className={`custom-select ${window.theme}`}
                    onChange={evt => {
                        props = {
                            ...props,
                            type: Utils.attributeForKey.select(evt, 'id')
                        }
                    }}>
                        <option>{'No Type Selected'}</option>
                        <option id={'seconds'}>{'Seconds'}</option>
                        <option id={'minutes'}>{'Minutes'}</option>
                        <option id={'hours'}>{'Hours'}</option>
                    </select>
                </LayerItem>

                <LayerItem
                title={'Duration'}
                lastItem={true}
                shouldStyle={false}>
                    <NumberStepper
                    min={0}
                    startingValue={autoApprovals.buffer ? autoApprovals.buffer.duration : null}
                    onChange={val => {
                        props = {
                            ...props,
                            duration: val
                        }
                    }}/>
                </LayerItem>
                </>
            )
        })
    }

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await approvals.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'Your auto approval preferences have been updated',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your automatic approval preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = approvals.set(props);
            setAutoApprovals(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!autoApprovals) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'enabled',
                title: 'Enabled',
                description: `Automatic approvals will look at your preferences and automatically approve or declines rides after they are booked.`,
                value: autoApprovals.enabled,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ enabled: val })
            },{
                key: 'analyze_vehicles',
                visible: autoApprovals.enabled,
                required: autoApprovals.enabled,
                title: 'Analyze Vehicle Inventory',
                description: `Enabling "Analyze Vehicle Inventory" will set the threshold for the amount of approved reservations per timeslot. If there are 5 active vehicles then 5 rides can be approved for the same pickup time if those rides meet the other specified criteria for approval. Disabling will set the maximum to 1 ride per timeslot.`,
                value: autoApprovals.analyze_vehicles,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ analyze_vehicles: val })
            },{
                key: 'buffer',
                visible: autoApprovals.enabled,
                required: autoApprovals.enabled,
                title: 'Buffer',
                description: `Automatic approvals will approve or decline a reservation based on the service selected for the ride. You can choose as many or as few services as you would like.`,
                value: autoApprovals.buffer ? `${autoApprovals.buffer.duration} ${autoApprovals.buffer.type}` : null,
                onEditClick: onManageBuffer
            },{
                key: 'customer_edits',
                visible: autoApprovals.enabled,
                required: autoApprovals.enabled,
                title: 'Customer Changes',
                description: `Automatic approvals can automatically approve changes made by a customer. This is helpful if you do not need to review time or location changes before manually approving a ride.`,
                value: autoApprovals.customer_edits,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ customer_edits: val })
            },{
                key: 'reservations',
                visible: autoApprovals.enabled,
                required: autoApprovals.enabled,
                title: 'New Bookings',
                description: `Automatic approvals can approve or decline a reservation when it is submitted by a customer.`,
                value: autoApprovals.reservations,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ reservations: val })
            },{
                key: 'same_day',
                visible: autoApprovals.enabled,
                required: autoApprovals.enabled,
                title: 'Same Day Bookings',
                description: `Automatic approvals can approve or decline a reservation based on whether or not the pickup date falls on the same day that the ride was booked.`,
                value: autoApprovals.same_day,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ same_day: val })
            },{
                key: 'services',
                visible: autoApprovals.enabled,
                title: 'Services',
                description: `Automatic approvals will approve or decline a reservation based on the service selected for the ride. You can choose as many or as few services as you would like.`,
                component: 'multiple_list',
                items: services.map(service => ({
                    id: service.id,
                    title: service.name
                })),
                value: autoApprovals.services && services.filter(service => {
                    return autoApprovals.services.includes(service.id);
                }).map(service => ({
                    id: service.id,
                    title: service.name
                })),
                onChange: items => {
                    onUpdateTarget({
                        services: items && items.map(item => {
                            return item.id;
                        })
                    });
                }
            }]
        }]
    }

    const setupTargets = async () => {
        try {
            let edits = approvals.open();
            setAutoApprovals(edits);

            let { services } = await fetchServices(utils);
            setServices(services);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the editing process. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'Editing Automatic Approvals'}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setAutoApprovals(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const EditReservationGuideline = ({ resGuideline }, { abstract, index, options, utils }) => {

    const layerID = 'edit-reservation-guideline';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [guideline, setGuideline] = useState(null);

    const onSubmit = async () => {
        if(!guideline.notes) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please provide some notes to describe your changes'
            });
            return;
        }
        try {
            setLoading(true);
            await Utils.sleep(1);
            await resGuideline.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'This guideline has been updated',
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this guideline. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = resGuideline.set(props);
            setGuideline(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!guideline) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: resGuideline.title,
                description: resGuideline.description,
                value: resGuideline.formatValue(guideline),
                component: 'number_stepper',
                onChange: val => onUpdateTarget({ value: resGuideline.key === 'min_lead_time' ? val * 60 : val }),
                props: {
                    min: 0,
                    startingValue: guideline.value ? (resGuideline.key === 'min_lead_time' ? guideline.value / 60 : guideline.value) : null
                }
            },{
                key: 'notes',
                title: 'Notes',
                description: 'Notes are required for any guideline change. These notes should explain why you are changing this guideline',
                value: guideline.notes,
                component: 'textview',
                onChange: text => onUpdateTarget({ notes: text })
            }]
        }];
    }

    const setupTarget = () => {
        let edits = resGuideline.open();
        setGuideline(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Editing ${resGuideline.title}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setGuideline(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />

        </Layer>
    )
}

export const PremiumLocationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `premium_location_details_${abstract.getID()}`;
    const [annotations, setAnnotations] = useState([]);
    const [features, setFeatures] = useState(null);
    const [geojson, setGeoJson] = useState(abstract.object.geojson);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(abstract.object);

    const onDeleteLocation = () => {
        utils.alert.show({
            title: 'Delete Premium Location',
            message: `Are you sure that you want to delete this premium location? This can not be undone`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteLocationConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteLocationConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/reservations/', {
                type: 'delete_premium_location',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('premiumLocations');
            utils.alert.show({
                title: 'All Done!',
                message: 'This premium location has been deleted',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this premium location. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_premium_location_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditPremiumLocation.bind(this, {
                isNewTarget: false
            })
        })
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete Location',
                style: 'destructive'
            },{
                key: 'status',
                title: `${abstract.object.active ? 'Deactivate' : 'Activate'} Location`,
                style: abstract.object.active ? 'destructive' : 'default'
            }]
        }, key => {
            if(key === 'delete') {
                onDeleteLocation();
                return;
            }
            if(key === 'status') {
                onSetActiveStatus();
                return;
            }
        })
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: `${location.active ? 'Deactivate' : 'Activate'} Location`,
            message: `Are you sure that you want to ${location.active ? 'deactivate' : 'activate'} this location?`,
            buttons: [{
                key: 'confirm',
                title: location.active ? 'Deactivate' : 'Activate',
                style: location.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: location.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: location.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/reservations/', {
                type: 'set_premium_location_active_status',
                id: abstract.getID(),
                active: next
            });

            setLoading(false);
            abstract.object.active = next;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `This premium location has been ${next ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this premium location. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            key: 'cancel',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const getCostAmount = () => {
        switch(location.type.code) {
            case PremiumLocation.types.fixed_cost:
            return Utils.toCurrency(location.amount);

            case PremiumLocation.types.no_cost:
            return 'Cover the whole ride cost';

            case PremiumLocation.types.percentage:
            return `${parseInt(location.amount * 100)}%`;
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: location.id
            },{
                key: 'name',
                title: 'Name',
                value: location.name
            },{
                key: 'address',
                title: 'Address',
                value: location.address
            },{
                key: 'radius',
                title: 'Radius',
                value: `${location.radius} ${location.radius === 1 ? 'foot' : 'feet'}`
            },{
                key: 'status',
                title: 'Status',
                value: location.active ? 'Active' : 'Not Active'
            }]
        },{
            key: 'cost',
            title: 'Cost Preferences',
            items: [{
                key: 'type',
                title: 'Type',
                value: location.type && location.type.text
            },{
                key: 'amount',
                title: 'Amount',
                value: getCostAmount()
            }]
        }];
    }

    const onSetupMap = () => {

        // set annnotations for location center
        setAnnotations([{
            key: 'default_location',
            title: location.address,
            location: location.location,
            icon: { type: 'broadcast' }
        }]);

        // set feature data for radius circle
        setFeatures({
            id: 'premium_location_shape',
            region: geojson.region,
            data: geojson.shape,
            layer: {
                type: 'fill',
                paint: {
                    'fill-opacity': 0.25,
                    'fill-color': Appearance.colors.grey()
                }
            }
        });
    }

    useEffect(() => {
        if(geojson) {
            onSetupMap();
        }
    }, [geojson]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'premiumLocations', {
            onUpdate: next => {
                next.compare(abstract, () => {
                    setLocation(next.object);
                    setGeoJson(next.object.geojson);
                });
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for "${abstract.getTitle()}"`}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <div style={{
                position: 'relative'
            }}>
                <LayerMap
                annotations={annotations}
                features={features} 
                utils={utils}/>
            </div>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const ReservationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `reservation-details-${abstract.getID()}`;
    const annotationsRef = useRef([]);
    const limit = 5;
    const reservationRef = useRef(abstract.object);

    const [annotations, setAnnotations] = useState([]);
    const [breakdown, setBreakdown] = useState(null);
    const [dropDown, setDropDown] = useState(null);
    const [estimateLineItems, setEstimateLineItems] = useState([]);
    const [events, setEvents] = useState([]);
    const [flight_data, setFlightData] = useState(null);
    const [invoiceLineItems, setInvoiceLineItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [overlays, setOverlays] = useState({});
    const [paging, setPaging] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(abstract.object.customer.payment_methods);
    const [reservation, setReservation] = useState(abstract.object);

    const onCancelReservationConfirm = async confirm => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { confirmation } = await Request.post(utils, '/reservation/', {
                type: 'cancel',
                reservation_id: reservation.id,
                approve_fees: confirm
            });

            // confirm cancellation fees if applicable
            if(confirmation) {
                setLoading(false);
                utils.alert.show({
                    title: 'Confirm',
                    message: confirmation,
                    buttons: [{
                        key: 'confirm',
                        title: 'Cancel',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Cancel',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onCancelReservationConfirm(true);
                            return;
                        }
                    }
                })
                return;
            }

            setLoading(false);
            abstract.object.status = Reservation.formatStatus(Reservation.status.cancelled);
            utils.content.fetch('reservations');
            utils.alert.show({
                title: 'All Done!',
                message: `Reservation #${abstract.getID()} has been cancelled`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this reservation. ${e.message || 'An unknown error occurred'}`
            })
        }
    }
    
    const onCompanyClick = async () => {
        try {
            setLoading(true);
            let company = await Company.get(utils, reservation.company.id);

            setLoading(false);
            Utils.companies.details(utils, company);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this company. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCreateDriver = async (driverID, location, reservationID) => {
        try {
            // check if driver entry has already been created
            let index = annotationsRef.current.findIndex(annotation => annotation.data.key === `reservation-${reservationID}`);
            if(index >= 0) {
                return;
            }

            // fetch driver details and update annotation
            let driver = await Driver.get(utils, driverID);
            setAnnotations(annotations => {
                return annotations.concat([{
                    data: { key: `reservation-${reservationID}` },
                    location: {
                        latitude: location.lat,
                        longitude: location.long,
                        heading: location.heading,
                        speed: location.speed
                    },
                    subTitle: `Arriving in ${Utils.parseDuration(location.time_to_arrival)}`,
                    supportingTitle: `Reservation #${reservationID}`,
                    title: driver.full_name
                }])
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onDeleteReservation = () => {
        utils.alert.show({
            title: 'Delete Reservation',
            message: 'Are you sure that you want to delete this Reservation? Reservations should only be deleted if they were created by mistake. This can not be undone',
            buttons: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'delete') {
                    onDeleteReservationConfirm();
                    return;
                }
            }
        });
    }

    const onDeleteReservationConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/reservation/', {
                type: 'delete',
                reservation_id: reservation.id
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Reservation #${reservation.id} for ${reservation.customer.full_name} has been deleted`,
                onClick: () => {
                    setLayerState('close');
                    utils.content.fetch('reservations');
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditReservation = () => {
        utils.layer.open({
            id: `reservation-edit-${reservation.id}`,
            abstract: Abstract.create({
                type: 'reservations',
                object: reservation
            }),
            Component: AddEditReservation.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin
            },{
                key: 'status',
                title: 'Set Status',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin
            },{
                key: 'approve',
                title: 'Approve Reservation',
                style: 'default',
                visible: utils.user.get().level <= User.level.admin && (!reservation.status || reservation.status.code === Reservation.status.pending || reservation.status.code === Reservation.status.customer_edited)
            },{
                key: 'decline',
                title: 'Decline Reservation',
                style: 'destructive',
                visible: utils.user.get().level <= User.level.admin && (!reservation.status || reservation.status.code === Reservation.status.pending || reservation.status.code === Reservation.status.customer_edited)
            },{
                key: 'edit',
                title: 'Edit Reservation',
                style: 'default',
                visible: reservation.status.code !== Reservation.status.unpaid && reservation.status.code !== Reservation.status.completed
            },{
                key: 'delete',
                title: 'Delete Reservation',
                style: 'destructive',
                visible: utils.user.get().level <= User.level.admin
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, {
                    type: 'reservations',
                    object: reservation
                });
                return;
            }
            if(key === 'status') {
                onShowEventLog();
                return;
            }
            if(key === 'edit') {
                onEditReservation();
                return;
            }
            if(key === 'delete') {
                onDeleteReservation();
                return;
            }
            if(key === 'approve' || key === 'decline') {
                onSetApprovalStatus(key === 'approve');
                return;
            }
        })
    }

    const onPreauthorizationOptionsClick = () => {

        let buttons = [];
        if(reservation.hasPreauthorization()) {
            buttons = [{
                key: 'remove',
                text: 'Revoke',
                color: 'danger',
                onClick: onRevokePreauthorization
            },{
                key: 'update',
                text: 'Update',
                color: 'primary',
                onClick: onUpdatePreauthorization
            }];
        }
        buttons.push({
            key: 'cancel',
            text: 'Dismiss',
            color: 'grey'
        });

        setDropDown({
            title: 'Preauthorization',
            message: `A hold may be placed on the customer's credit or debit card if the service selected during booking has preauthorization enabled. You can manually create a preauthorization or remove the current authorization if needed. Changing the amount of a preauthorization will cancel the current authorization and create a new one. Preauthorizations are held for no more than 7 days`,
            buttons: buttons,
            content: (
                <PreauthorizationManager
                utils={utils}
                reservation={reservation}
                defaultAuthorization={reservation.data ? reservation.data.preauthorization : null} />
            )
        })
    }

    const onProcessManualPayment = async ({ amount, method }) => {
         try {
             setLoading(true);
             let { status } = await Request.post(utils, '/payment/', {
                 type: 'process_unpaid',
                 amount: amount,
                 card_id: method.id,
                 customer_stripe_id: reservation.customer.stripe_customer_id,
                 reservation_id: reservation.id
             });

             reservation.status = status;
             utils.content.fetch('payments');
             utils.content.update({
                 type: 'reservations',
                 object: reservation
             });

             setLoading(false);
             utils.alert.show({
                 title: 'All Done!',
                 message: `The payment for this reservation has been processed`
             });

         } catch(e) {
             setLoading(false);
             utils.alert.show({
                 title: 'Oops!',
                 message: `There was an issue processing this payment. ${e.message || 'An unknown error occurred'}`
             })
         }
    }

    const onProcessPayment = () => {

        let props = { amount: breakdown.total };
        let target = Abstract.create({
            type: 'users',
            object: reservation.customer
        });

        setDropDown({
            title: 'Process Payment',
            message: `You can manually process the payment for this reservation by choosing one of the customer's payment methods and specifying a price for the transaction. The recommended amount for this transaction has been prefilled for you.`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onAsyncClick: async () => {
                    return new Promise((resolve ,reject) => {
                        try {
                            if(!props.method) {
                                throw new Error('Please select a payment method');
                            }
                            if(!props.amount || props.amount <= 0.5) {
                                throw new Error('Please choose a payment method for this transaction and enter an amount greater than $0.50');
                            }
                            resolve();
                            onProcessManualPayment(props);
                        } catch(e) {
                            reject(e);
                        }
                    });
                }
            }],
            content: (
                <>
                <TextField
                prepend={'$'}
                placeholder={'Amount'}
                value={props.amount}
                onChange={text => props = {
                    ...props,
                    amount: isNaN(text) ? props.amount : text
                }}
                containerStyle={{
                    marginBottom: 8
                }} />

                <PaymentMethodManager
                utils={utils}
                target={target}
                defaultMethod={reservation.payment_method}
                onChange={method => {
                    props = {
                        ...props,
                        method: method
                    }
                }}/>
                </>
            )
        })
    }

    const onRevokePreauthorization = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await reservation.revokePreauthorization(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The preauthorization for the reservation has been revoked'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue revoking this preauthorization. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetApprovalStatus = approve => {
        utils.alert.show({
            title: `${approve ? 'Approve' : 'Decline'} Reservation`,
            message: `Are you sure that you want to ${approve ? 'approve' : 'decline'} this reservation? This will notify the customer via email and push notification with your decision.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: approve ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: `Do Not ${approve ? 'Approve' : 'Decline'}`,
                style: approve ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetApprovalStatusConfirm(approve);
                    return;
                }
            }
        });
    }

    const onSetApprovalStatusConfirm = async (approve, confirm) => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/reservation/', {
                type: 'set_approval',
                status: approve ? Reservation.status.approved : Reservation.status.rejected,
                reservation_id: reservation.id,
                approve_unpaid_rides: confirm
            });

            setLoading(false);
            abstract.object.status = Reservation.formatStatus(approve ? Reservation.status.approved : Reservation.status.rejected);
            utils.content.fetch('reservations');
            utils.alert.show({
                title: 'All Done!',
                message: `Reservation #${abstract.getID()} has been ${approve ? 'approved' : 'declined'}`
            });

        } catch(e) {
            setLoading(false);
            if(e.code === 402) {
                utils.alert.show({
                    title: 'Oops!',
                    message: e.message,
                    buttons: [{
                        key: 'approve',
                        title: 'Approve',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Approve',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'approve') {
                            onSetApprovalStatusConfirm(key, true);
                            return;
                        }
                    }
                })
                return;
            }
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowEventLog = () => {
        setDropDown({
            title: 'Events and Status Changes',
            message: 'The reservation event log shows the journey of the reservation from booking to completion and includes additional information about log events when applicable. Events should only be manually added as a last resort.',
            content: (
                <EventManager
                utils={utils}
                reservation={reservation} />
            )
        })
    }

    const onStopCompleted = data => {
        try {
            let { completion_id, stop_id } = JSON.parse(data);
            reservationRef.current.stops.location = reservationRef.current.stops.locations.map(stop => {
                if(stop.id === stop_id) {
                    stop.completed = completion_id;
                }
                return stop;
            });
            abstract.object = reservationRef.current;
            utils.content.update(abstract);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onUpdateCostEstimate = async () => {
        let fetchID = `${layerID}:onUpdateCostEstimate`;
        try {
            if(utils.fetching.get(fetchID) === true) {
                return;
            }
            utils.fetching.set(fetchID, true);
            let { breakdown, estimate_line_items, invoice_line_items } = await abstract.object.getCostBreakdown(utils);

            utils.fetching.set(fetchID, false);
            setBreakdown(breakdown);
            setEstimateLineItems(estimate_line_items || []);
            setInvoiceLineItems(invoice_line_items || []);

            // update overlays
            setOverlays(abstract.object.getOverlays());

        } catch(e) {
            utils.fetching.set(fetchID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating your cost estimate. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateLocation = async data => {
        try {
            let { driver_id, location, reservation_id } = JSON.parse(data);
            onCreateDriver(driver_id, location, reservation_id);
            setAnnotations(annotations => {
                let index = annotations.findIndex(annotation => annotation.data && annotation.data.key === `reservation-${reservation_id}`);
                if(index < 0) {
                    return annotations;
                }
                return update(annotations, {
                    [index]: {
                        location: {
                            $set: {
                                latitude: location.lat,
                                longitude: location.long,
                                heading: location.heading,
                                speed: location.speed
                            }
                        }
                    }
                });
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateLocations = async () => {
        setAnnotations(abstract.object.getLocations());
        setOverlays(abstract.object.getOverlays());
    }

    const onUpdatePreauthorization = async () => {
        let hasPrevious = reservation.hasPreauthorization();
        try {
            setLoading(true);
            await Utils.sleep(1);
            await reservation.setPreauthorization(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The preauthorization for the reservation has been ${hasPrevious ? 'updated' : 'created'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${hasPrevious ? 'updating' : 'creating'} the preauthorization for this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onViewMessages = () => {
        utils.layer.open({
            id: `reservation-messages-${reservation.id}`,
            abstract: abstract,
            Component: ReservationMessages
        })
    }

    const getBookingChannel = () => {
        switch(reservation.booking_channel) {
            case 'app_clips':
            return 'App Clips';

            case 'quick_scan':
            return 'QuickScan';

            default:
            return null;
        }
    }

    const getButtons = () => {

        let buttons = [];
        if([
            Reservation.status.unpaid,
            Reservation.status.charge_issue,
            Reservation.status.charge_failed
        ].includes(reservation.status.code)) {
            buttons.push({
                key: 'unpaid',
                text: 'Process Payment',
                color: 'secondary',
                onClick: onProcessPayment
            });
        }

        if(reservation.status.code < Reservation.status.completed && reservation.status.code !== Reservation.status.cancelled) {
            buttons.push({
                key: 'cancel',
                text: 'Cancel',
                color: 'danger',
                onClick: utils.alert.show.bind(this, {
                    title: 'Cancel Reservation',
                    message: 'Are you sure that you want to cancel this Reservation?',
                    buttons: [{
                        key: 'confirm',
                        title: 'Cancel',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Not Cancel',
                        style: 'default'
                    }],
                    onClick: key => {
                        if(key === 'confirm') {
                            onCancelReservationConfirm();
                            return;
                        }
                    }
                })
            });
        }

        return buttons.concat({
            key: 'options',
            text: 'Options',
            color: 'primary',
            onClick: onOptionsClick
        })
    }

    const getFields = () => {
        let fields = [{
            key: 'dates_and_time',
            title: 'Dates and Time',
            items: [{
                key: 'date_submitted',
                title: 'Booking Date',
                value: Utils.formatDate(reservation.date_submitted)
            },{
                key: 'pickup_date',
                title: 'Pickup Date',
                value: reservation.special_requests.on_demand ? 'Immediate Pickup' : Utils.formatDate(reservation.pickup_date)
            }]
        },{
            key: 'details',
            title: 'About this Ride',
            items: [{
                key: 'booking_channel',
                title: 'Booking Channel',
                visible: getBookingChannel() ? true : false,
                value: getBookingChannel()
            },{
                key: 'flight_number',
                title: 'Flight Number',
                value: reservation.special_requests.flight,
                visible: reservation.special_requests.flight ? true : false
            },{
                key: 'luggage',
                title: 'Luggage',
                value: reservation.luggage || 0
            },{
                key: 'id',
                title: 'ID',
                value: reservation.id
            },{
                key: 'message',
                title: 'Message for Driver',
                value: reservation.special_requests.message
            },{
                key: 'passengers',
                title: 'Passengers',
                value: reservation.passengers || 0
            },{
                key: 'referral_code',
                title: 'Referral Code',
                value: reservation.referral_code
            },{
                key: 'service',
                title: 'Service',
                value: reservation.service ? reservation.service.name : null
            },{
                key: 'status',
                title: 'Status',
                value: reservation.status ? reservation.status.text : null
            },{
                key: 'vehicle',
                title: 'Vehicle',
                value: reservation.vehicle ? reservation.vehicle.name : null
            }]
        }];
        if(flight_data) {
            fields = fields.concat([{
                key: 'flight_data',
                title: 'Flight Information',
                items: [{
                    key: 'arrival_date',
                    title: 'Arrival Date',
                    value: flight_data.arriving.date ? Utils.formatDate(flight_data.arriving.date) : null
                },{
                    key: 'arrival_airport',
                    title: 'Arrival Airport',
                    value: flight_data.arriving.name
                },{
                    key: 'departure_date',
                    title: 'Departure Date',
                    value: flight_data.departure.date ? Utils.formatDate(flight_data.departure.date) : null
                },{
                    key: 'departure_airport',
                    title: 'Departure Airport',
                    value: flight_data.departure.name
                }]
            }]);
        }

        fields = fields.concat([{
            key: 'payments',
            title: 'Payments',
            items: [{
                key: 'terminal',
                title: 'Captured with Card Reader',
                value: reservation.is_terminal_origin ? 'Yes' : 'No'
            },{
                key: 'payment_method',
                title: 'Payment Method',
                value: getPaymentMethodComponent(),
                visible: reservation.is_terminal_origin ? false : true
            },{
                key: 'preauthorization',
                title: 'Preauthorization',
                visible: utils.user.get().level <= User.level.admin,
                value: reservation.hasPreauthorization() ? 'Click to View' : 'Click to Create',
                onClick: onPreauthorizationOptionsClick
            },{
                key: 'promo_code',
                title: 'Promo Code',
                value: reservation.promo_code ? reservation.promo_code.getSummary() : 'Not Added'
            },{
                key: 'payment_history',
                title: 'Transactions',
                visible: [ Reservation.status.completed, Reservation.status.unpaid, Reservation.status.charge_processing, Reservation.status.charge_posted, Reservation.status.charge_failed ].includes(reservation.status.code),
                value: 'Click to View',
                onClick: getPaymentHistory
            }]
        },{
            key: 'estimate',
            title: 'Pre-Ride Estimate',
            items: estimateLineItems.map((lineItem, index) => ({
                key: index,
                title: lineItem.title,
                value: lineItem.message
            }))
        },{
            key: 'invoice',
            title: 'Post-Ride Invoice',
            items: invoiceLineItems.map((lineItem, index) => ({
                key: index,
                title: lineItem.title,
                value: lineItem.message
            }))
        },{
            key: 'resources',
            title: 'Resources',
            items: [{
                key: 'status_codes',
                title: 'Events and Status Changes',
                visible: utils.user.get().level <= User.level.admin,
                value: 'Click to View',
                onClick: onShowEventLog
            }/*,{
                key: 'directions',
                title: 'Directions',
                value: 'Click to View',
                onClick: onShowDirections
            },{
                key: 'messages',
                title: 'Driver and Customer Messages',
                visible: utils.user.get().level <= User.level.admin,
                value: 'Click to View',
                onClick: onViewMessages

            }*/]
        }]);
        return fields;
    }

    const getLocations = () => {

        // declare list of accepted status codes
        let { approved, cancelled, charge_failed, charge_issue, completed, rejected, returned, to_destination, to_pickup } = Reservation.status;

        // determine if completion status should be shown for locations
        let shouldShowLocationCompletionBadge = [approved, to_destination, to_pickup, completed, charge_issue, charge_failed].includes(reservation.status.code) ? true : false;

        // declare list of reservation locations
        let locations = [{
            key: 'pickup',
            title: 'Pickup',
            value: reservation.origin ? reservation.origin.address : null,
            completed: reservation.status.code > to_pickup
        }];

        // loop through reservation stops and determine if stop has been completed
        if(reservation.stops && reservation.stops.locations && reservation.stops.locations.length > 0) {
            locations = locations.concat(reservation.stops.locations.map((stop, index) => ({
                key: index,
                title: `${Utils.integerToOrdinal(index + 1)} Stop`,
                value: stop.address,
                completed: stop.completed ? true : false
            })));
        }

        // add destination to locations list
        locations.push({
            key: 'destination',
            title: 'Drop-Off',
            value: reservation.destination ? reservation.destination.address : null,
            completed: reservation.status.code > to_destination
        });

        return (
            <LayerItem title={'Locations'}>
                {locations.map((location, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: location.title,
                            subTitle: location.value,
                            hideIcon: true,
                            bottomBorder: index !== locations.length - 1,
                            badge: shouldShowLocationCompletionBadge && {
                                color: location.completed ? Appearance.colors.green : Appearance.colors.grey(),
                                text: location.completed ? 'Completed' : 'Not Completed'
                            }
                        })
                    )
                })}
            </LayerItem>
        )
    }

    const getPaymentMethodComponent = () => {
        if(!paymentMethods) {
            return Views.loader({ justifyContent: 'flex-end' });
        }
        let method = reservation.getCurrentPaymentMethod();
        if(method) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}>
                    <span style={Appearance.textStyles.value()}>{method.summary()}</span>
                    <i
                    className={method.icon()}
                    style={{
                        fontSize: 20,
                        paddingLeft: 8,
                        color: Appearance.colors.text()
                    }}/>
                </div>
            );
        }
        return (
            <span style={Appearance.textStyles.value()}>{'Not Added'}</span>
        )
    }

    const getPaymentAvatar = payment => {
        if(payment.customer) {
            return payment.customer.avatar;
        }
        if(payment.company){
            return payment.company.image;
        }
        return null;
    }

    const getPaymentBadges = payment => {
        if(payment.credits) {
            return {
               color: Appearance.colors.primary(),
               text: `${Utils.toCurrency(payment.credits.amount)} Credits`
           }
        }
        return {
            color: Appearance.colors.grey(),
            text: Utils.toCurrency(payment.amount)
        }
    }

    const getPaymentHistory = async () => {
        try {
            setLoading(true);
            let { payments } = await Request.get(utils, '/payments/', {
                type: 'reservation_payment_history',
                reservation_id: reservation.id
            });

            setLoading(false);
            if(!payments || payments.length === 0) {
                utils.alert.show({
                    title: 'Payment History',
                    message: 'It looks like no payments have been posted for this reservation'
                });
                return;
            }

            let targets = payments.map(payment => Payment.create(payment));
            setDropDown({
                title: 'Payment History',
                message: 'Listed below are the payments made for this reservation. This can include the standard payment made for the estimated cost of the trip, driver tips, and any extra charges incurred throughout the reservation.',
                content: (
                    <div style={{
                        textAlign: 'left',
                        width: '100%'
                    }}>
                        {targets.map((payment, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    ...Appearance.styles.unstyledPanel(),
                                    marginBottom: 8
                                }}>
                                    {Views.entry({
                                        title: payment.getType(),
                                        subTitle: Utils.formatDate(payment.date),
                                        badge: getPaymentBadges(payment),
                                        icon: {
                                            path: getPaymentAvatar(payment)
                                        },
                                        bottomBorder: false,
                                        onClick: Utils.payments.details.bind(this, utils, payment)
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payment history for this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchPaymentMethods = async () => {
        try {
            let { methods } = await reservation.customer.getPaymentMethods(utils);
            setPaymentMethods(methods);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the payment methods for this customer. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupFlights = () => {
        if(!reservation.flight_data || !reservation.flight_data.route) {
            return;
        }
        let { destination, origin, route } = reservation.flight_data;
        let distances = {
            origin: Utils.linearDistance(route.origin_airport, reservation.origin.location),
            destination: Utils.linearDistance(route.destination_airport, reservation.origin.location)
        }
        if(distances.origin < distances.destination) {
            setFlightData({
                arriving: {
                    name: origin.name,
                    date: origin.departure_time
                },
                departing: {
                    name: destination.name,
                    date: destination.arrival_time
                }
            });
            return;
        }
        setFlightData({
            arriving: {
                name: destination.name,
                date: destination.arrival_time
            },
            departing: {
                name: origin.name,
                date: origin.departure_time
            }
        });
    }

    useEffect(() => {
        annotationsRef.current = annotations || [];
    }, [annotations]);

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        fetchPaymentMethods();
    }, [reservation.customer]);

    useEffect(() => {
        onUpdateCostEstimate();
    }, [reservation.origin, reservation.destination, reservation.stops, reservation.vehicle, reservation.service, reservation.credits, reservation.driver_tip, reservation.referral_code]);

    useEffect(() => {
        onUpdateLocations();
    }, [reservation.origin, reservation.stops, reservation.destination, reservation.driver]);

    useEffect(() => {
        reservationRef.current = reservation;
        if(reservation.seeds && reservation.seeds.notes) {
            setNote(reservation.seeds.notes.find(note => note.deleted !== true));
        }
    }, [reservation]);

    useEffect(() => {
        setupFlights();
        utils.sockets.on('reservations', `on_stop_completed_${abstract.getID()}`, onStopCompleted);
        utils.sockets.on('seeds', `on_reservation_location_update_${reservation.id}`, onUpdateLocation);
        utils.content.subscribe(layerID, 'reservations', {
            onUpdate: next => {
                setReservation(next.compare(abstract, () => {
                    fetchSystemEvents();
                    onUpdateLocations();
                }));
            }
        })
        return () => {
            utils.content.unsubscribe(layerID);
            utils.sockets.off('reservations', `on_stop_completed_${abstract.getID()}`, onStopCompleted);
            utils.sockets.off('seeds', `on_reservation_location_update_${reservation.id}`, onUpdateLocation);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            removeMobilePadding: true
        }}>
            <LayerMap
            annotations={annotations}
            overlays={overlays}
            utils={utils}
            showUserLocation={false}
            style={{
                marginTop: Utils.isMobile() ? 12 : 0
            }} />

            {reservation.emissions && (
                <LayerEmissions
                style={{
                    marginBottom: 25
                }}
                items={[{
                    key: 'trees',
                    title: reservation.emissions.trees === 1 ? ' Tree Planted' : ' Trees Planted',
                    placeholder: '0',
                    value: reservation.emissions.trees,
                    image: 'trees-icon-clear.png'
                },{
                    key: 'gas',
                    title: 'Gallons of Gas',
                    placeholder: '0',
                    value: reservation.emissions.gas,
                    image: 'gas-icon-clear.png'
                },{
                    key: 'carbon',
                    title: 'Grams of CO2',
                    placeholder: '0',
                    value: reservation.emissions.carbon,
                    image: 'carbon-icon-clear.png'
                }]} />
            )}

            {reservation.customer && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: reservation.customer.full_name,
                        subTitle: reservation.customer.phone_number,
                        icon: {
                            path: reservation.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, reservation.customer)
                    })}
                </LayerItem>
            )}

            {reservation.company && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: reservation.company.name,
                        subTitle: reservation.company.address,
                        icon: {
                            path: reservation.company.image
                        },
                        bottomBorder: false,
                        onClick: onCompanyClick
                    })}
                </LayerItem>
            )}

            {getLocations()}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getSystemEvents()}

            {utils.user.get().level <= User.level.admin && (
                <LayerNote
                note={note}
                utils={utils}
                abstract={abstract} />
            )}
        </Layer>
    )
}

export const ReservationMoodDetails = ({ abstract, index, options, utils }) => {

    const layerID = `reservation-mood-details-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mood, setMood] = useState(abstract.object);

    const onSetMoodStatus = () => {
        utils.alert.show.bind(this, {
            title: `${mood.active ? 'Deactivate' : 'Activate'} Mood`,
            message: `Are you sure that you want to ${mood.active ? 'deactivate' : 'activate'} this Mood?`,
            buttons: [{
                key: 'confirm',
                title: mood.active ? 'Deactivate' : 'Activate',
                style: mood.active ? 'destructive' : 'default',
            },{
                key: 'cancel',
                title: mood.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: mood.active ? 'default' : 'destructive',
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetMoodStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetMoodStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !mood.active;
            await Request.post(utils, '/resources/', {
                type: 'set_mood_status',
                id: mood.id,
                status: next
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.getTitle()} has been ${mood.active ? 'deactivated' : 'activated'}`,
                onClick: () => {
                    abstract.object.active = next;
                    utils.content.update(abstract);
                    setMood({ ...abstract.object });
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this mood. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'edit',
                title: 'Edit Mood',
                style: 'default'
            },{
                key: 'guidelines',
                title: 'View Image Guidelines',
                style: 'default'
            }]
        }, key => {
            if(key === 'edit') {
                utils.layer.open({
                    id: `edit-reservation-mood-${abstract.getID()}`,
                    abstract: abstract,
                    Component: AddEditReservationMood.bind(this, {
                        isNewTarget: false
                    })
                });
                return;
            }
            if(key === 'guidelines') {
                utils.alert.show({
                    title: 'Image Guidelines',
                    message: 'Your image should fit within a circle, be represented as a single white icon, and should have a transparent background to let your primary theme color show through. You image may need to be re-created if your image does not appear differently than the non-selected image.'
                });
            }
        })
    }

    const getButtons = () => {
        return [{
            key: 'status',
            text: mood.active ? 'Deactivate' : 'Activate',
            color: mood.active ? 'danger' : 'primary',
            onClick: onSetMoodStatus
        },{
            key: 'options',
            text: 'Options',
            color: mood.active ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }]
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: mood.id
            },{
                key: 'title',
                title: 'Title',
                value: mood.title
            },{
                key: 'information',
                title: 'Description',
                value: mood.information
            },{
                key: 'status',
                title: 'Status',
                value: mood.active ? 'Active' : 'Not Active'
            }]
        }]
    }

    useEffect(() => {
        utils.content.subscribe(layerID, 'moods', {
            onUpdate: next => {
                setMood(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginBottom: 8,
                padding: 12
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 8,
                    alignItems: 'center',
                    textAlign: 'center'
                }}>
                    <img
                    src={mood.image}
                    style={{
                        width: 100,
                        height: 100,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        marginBottom: 8,
                        backgroundColor: Appearance.colors.primary()
                    }} />
                    <span style={Appearance.textStyles.panelTitle()}>{'Selected'}</span>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginLeft: 8,
                    textAlign: 'center'
                }}>
                    <img
                    src={mood.image}
                    style={{
                        width: 100,
                        height: 100,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        marginBottom: 8,
                        border: `1px solid ${Appearance.colors.divider()}`,
                        backgroundColor: Appearance.colors.background()
                    }} />
                    <span style={Appearance.textStyles.panelTitle()}>{'Not Selected'}</span>
                </div>
            </div>

            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const RestrictedDateTimeDetails = ({ abstract, index, options, utils }) => {

    const layerID = `restricted-date-time-details-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [restriction, setRestriction] = useState(abstract.object);

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-restricted-date-time-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditRestrictedDateTime.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `${restriction.active ? 'Deactivate' : 'Activate'} Mood`,
            message: `Are you sure that you want to ${restriction.active ? 'deactivate' : 'activate'} this restricted ${restriction.type}?`,
            buttons: [{
                key: 'confirm',
                title: restriction.active ? 'Deactivate' : 'Activate',
                style: restriction.active ? 'destructive' : 'default',
            },{
                key: 'cancel',
                title: restriction.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: restriction.active ? 'default' : 'destructive',
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !restriction.active;
            await Request.post(utils, '/resources/', {
                type: 'set_restricted_dates_times_status',
                id: restriction.id,
                status: next
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.getTitle()} has been ${restriction.active ? 'deactivated' : 'activated'}`,
                onClick: () => {
                    abstract.object.active = next;
                    utils.content.update(abstract);
                    setRestriction({ ...abstract.object });
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this mood. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'status',
            text: restriction.active ? 'Deactivate' : 'Activate',
            color: restriction.active ? 'danger' : 'primary',
            onClick: onSetStatus
        },{
            key: 'edit',
            text: 'Edit',
            color: restriction.active ? 'primary' : 'secondary',
            onClick: onEditClick
        }]
    }

    const getFields = () => {
        let fields = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: restriction.id
            },{
                key: 'type',
                title: 'Type',
                value: Utils.ucFirst(restriction.type)
            },{
                key: 'title',
                title: 'Title',
                value: restriction.title
            },{
                key: 'message',
                title: 'Message',
                value: restriction.message
            },{
                key: 'status',
                title: 'Status',
                value: restriction.active ? 'Active' : 'Not Active'
            }]
        }];
        if(restriction.type !== 'time') {
            fields = fields.concat([{
                key: 'dates',
                title: 'Dates',
                items: [{
                    key: 'start_date',
                    title: 'Start Date',
                    value: moment(restriction.start_date).format('MMMM Do, YYYY')
                },{
                    key: 'end_date',
                    title: 'End Date',
                    value: moment(restriction.end_date).format('MMMM Do, YYYY')
                }]
            }]);
        }
        if(restriction.type === 'time') {
            fields = fields.concat([{
                key: 'times',
                title: 'Times',
                items: [{
                    key: 'start_time',
                    title: 'Start Time',
                    value: moment(restriction.start_time, 'HH:mm:ss').format('h:mma')
                },{
                    key: 'end_time',
                    title: 'End Time',
                    value: moment(restriction.end_time, 'HH:mm:ss').format('h:mma')
                }]
            }]);
        }
        return fields;
    }

    useEffect(() => {
        utils.content.subscribe(layerID, 'restrictedDateTimes', {
            onUpdate: next => {
                setRestriction(next.compare(abstract));
            }
        })
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium',
            layerState: layerState
        }}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const ServiceDetails = ({ abstract, index, options, utils }) => {

    const layerID = `service-details-${abstract.getID()}`;
    const limit = 5;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [events, setEvents] = useState([]);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [service, setService] = useState(abstract.object);

    const onDeleteService = () => {
        if(service.default_for_channel) {
            utils.alert.show({
                title: 'Default Service',
                message: 'This service is currently marked as a default service and can not be deactivated. Please set another service as the default service for this channel before deleting this service.'
            });
            return;
        }
        utils.alert.show({
            title: 'Delete Service',
            message: `Are you sure that you want to delete this service? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteServiceConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteServiceConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            await Request.post(utils, '/reservations/', {
                type: 'delete_service',
                id: service.id
            });

            setLoading(false);
            utils.content.fetch('services');
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.getTitle()}" service has been deleted`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this service. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-service-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditService.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'status',
                title: service.active ? 'Deactivate' : 'Activate',
                style: service.active ? 'destructive' : 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'status') {
                onSetStatus();
                return;
            }
            if(key === 'delete') {
                onDeleteService();
                return;
            }
        })
    }

    const onSetStatus = () => {
        if(service.default_for_channel) {
            utils.alert.show({
                title: 'Default Service',
                message: 'This service is currently marked as a default service and can not be deactivated. Please set another service as the default service for this channel before deactivating this service.'
            });
            return;
        }
        utils.alert.show({
            title: `Set as ${service.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set this service as ${service.active ? 'inactive' : 'active'}? This will ${service.active ? 'no longer allow' : 'allow'} customers to book this service`,
            buttons: [{
                key: 'confirm',
                title: service.active ? 'Deactivate' : 'Activate',
                style: service.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: service.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: service.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !service.active;
            await Request.post(utils, '/reservations/', {
                type: 'set_service_status',
                service_id: service.id,
                active: next
            });

            setLoading(false);
            abstract.object.active = next
            setService({ ...abstract.object });

            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.getTitle()} has been set as ${next ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this service. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getBookingFields = () => {
        return [{
            key: 'options',
            title: 'Booking Options',
            items: [{
                key: 'should_book_return_trip',
                title: 'Return Trip',
                value: service.should_book_return_trip ? 'Enabled' : 'Disabled'
            },{
                key: 'available',
                title: 'Available',
                value: service.options ? Utils.oxfordImplode(service.options.map(o => service.optionText(o)).sort((a,b) => a > b)) : 'Unknown'
            },{
                key: 'required',
                title: 'Required',
                value: service.required_options ? Utils.oxfordImplode(service.required_options.map(o => service.optionText(o)).sort((a,b) => a > b)) : 'Unknown'
            }]
        }];
    }

    const getBookingServiceAreas = () => {
        if(locations.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Service Areas'}>
                {locations.map((location, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: location.name,
                            subTitle: location.address,
                            icon: {
                                path: 'images/location-icon-blue-small.png'
                            },
                            badge: {
                                text: Utils.distanceConversion(location.radius),
                                color: Appearance.colors.primary()
                            },
                            bottomBorder: index !== locations.length - 1
                        })
                    )
                })}
            </LayerItem>
        )
    }

    const getChannelName = () => {

        if(service.order_channel) {
            return service.order_channel.name;
        }
        if(service.channel) {
            switch(service.channel) {
                case 'quick_scan':
                return 'QuickScan';

                default:
                return 'Reservations';
            }
        }
        return 'Not Set';
    }

    const getDeliveryFields = () => {
        return [{
            key: 'delivery',
            title: 'Delivery',
            items: [{
                key: 'base_rate',
                title: 'Base Rate',
                value: service.delivery && service.delivery.base_rate ? Utils.toCurrency(service.delivery.base_rate) : 'Not Set'
            },{
                key: 'mileage_rate',
                title: 'Per Mile Rate',
                value: service.delivery && service.delivery.mileage_rate ? Utils.toCurrency(service.delivery.mileage_rate) : 'Not Set'
            },{
                key: 'duration_rate',
                title: 'Per Minute Rate',
                value: service.delivery && service.delivery.duration_rate ? Utils.toCurrency(service.delivery.duration_rate) : 'Not Set'
            },{
                key: 'hourly_rate',
                title: 'Hourly Rate',
                value: service.delivery && service.delivery.hourly_rate ? Utils.toCurrency(service.delivery.hourly_rate) : 'Not Set'
            }]
        }];
    }

    const getDetailFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: service.id
            },{
                key: 'name',
                title: 'Name',
                value: service.name
            },{
                key: 'description',
                title: 'Description',
                value: service.information
            },{
                key: 'channel',
                title: 'Channel',
                value: getChannelName()
            },{
                key: 'default_for_channel',
                title: 'Default for Channel',
                value: service.default_for_channel ? 'Yes' : 'No'
            },{
                key: 'status',
                title: 'Status',
                value: service.active ? 'Active' : 'Not Active'
            }]
        }];
    }

    const getPaymentFields = () => {
        return [{
            key: 'payment',
            title: 'Payment',
            items: [{
                key: 'billing',
                title: 'Billing',
                value: Utils.ucFirst(service.billing)
            },{
                key: 'accepted_methods',
                title: 'Accepted Payment Methods',
                value: service.payment_methods ? Utils.oxfordImplode(service.payment_methods.map(method => Utils.ucFirst(method))) : null
            },{
                key: 'disclaimer',
                title: 'Disclaimer',
                value: service.disclaimer
            }]
        }];
    }

    const getScheduledRideFields = () => {
        return [{
            key: 'scheduled',
            title: 'Scheduled Rides',
            items: [{
                key: 'auto_reject',
                title: 'Auto-Rejection for Aging Rides',
                value: service.auto_reject && service.auto_reject.scheduled && service.auto_reject.scheduled.enabled ? 'Enabled' : 'Disabled'
            },{
                key: 'should_preauthorize',
                title: 'Preauthorizations',
                value: service.should_preauthorize ? 'Enabled' : 'Disabled'
            },{
                key: 'preauthorize',
                title: 'Preauthorization Disclaimer',
                value: service.preauthorize,
                visible: service.should_preauthorize ? true : false
            },{
                key: 'should_decline_on_preauthorization_failure',
                title: 'Decline Ride if Preauthorization Fails',
                value: service.should_decline_on_preauthorization_failure ? 'Yes' : 'No',
                visible: service.should_preauthorize ? true : false
            }]
        }]
    }

    const getOnDemandFields = () => {

        let { booking_default, decline_on_preauth_failure, enabled, preauthorize, preauthorization_disclaimer, radius_groups } = service.on_demand || {};
        let { groups = [] } = radius_groups || {};
        return [{
            key: 'on_demand',
            title: 'On-Demand Rides',
            items: [{
                key: 'enabled',
                title: 'Enabled',
                value: enabled ? 'Yes' : 'No'
            },{
                key: 'booking_default',
                title: 'Default for Booking',
                value: booking_default ? 'Yes' : 'No',
                visible: enabled ? true : false
            },{
                key: 'radius_groups',
                title: 'Radius Groups',
                value: `${groups.length} ${groups.length === 1 ? 'group' : 'groups'} setup`,
                visible: enabled ? true : false
            },{
                key: 'auto_reject',
                title: 'Auto-Rejection for Aging Rides',
                value: service.auto_reject && service.auto_reject.on_demand && service.auto_reject.on_demand.enabled ? 'Enabled' : 'Disabled'
            },{
                key: 'preauthorize',
                title: 'Preauthorizations',
                value: preauthorize ? 'Enabled' : 'Disabled'
            },{
                key: 'preauthorize',
                title: 'Preauthorization Disclaimer',
                value: preauthorization_disclaimer,
                visible: preauthorize ? true : false
            },{
                key: 'decline_on_preauth_failure',
                title: 'Decline Ride if Preauthorization Fails',
                value: decline_on_preauth_failure ? 'Yes' : 'No',
                visible: preauthorize ? true : false
            }]
        }];
    }

    const getRestrictionFields = () => {
        return [{
            key: 'restrictions',
            title: 'Restrictions',
            items: [{
                key: 'min_distance',
                title: 'Minimum Distance',
                value: service.min_distance ? Utils.distanceConversion(service.min_distance) : 'Not Set'
            },{
                key: 'max_distance',
                title: 'Maximum Distance',
                value: service.max_distance ? Utils.distanceConversion(service.max_distance) : 'Not Set'
            },{
                key: 'min_booking_time',
                title: 'Booking Lead Time',
                value: service.min_booking_time ? Utils.parseDuration(service.min_booking_time) : 'Not Set'
            }]
        }];
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchLocations = async () => {
        try {
            let { locations } = await Request.get(utils, '/reservations/', {
                type: 'service_locations',
                id: abstract.getID()
            });
            setLocations(locations);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retriving the locations radius restrictions for this service. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(service.seeds && service.seeds.notes) {
            setNote(service.seeds.notes.find(note => note.deleted !== true));
        }
    }, [service]);

    useEffect(() => {
        fetchLocations();
        utils.content.subscribe(layerID, 'services', {
            onFetch: fetchLocations,
            onUpdate: next => {
                setService(next.compare(abstract, fetchSystemEvents));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    const getButtons = () => {
        return [{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    return (
        <Layer
        id={layerID}
        buttons={getButtons()}
        index={index}
        title={`Details for "${abstract.getTitle()}"`}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>

            <FieldMapper
            utils={utils}
            fields={getDetailFields()} />

            {!service.order_channel && (
                <FieldMapper
                utils={utils}
                fields={getBookingFields()} />
            )}

            <FieldMapper
            utils={utils}
            fields={getPaymentFields()} />

            <FieldMapper
            utils={utils}
            fields={getScheduledRideFields()} />

            <FieldMapper
            utils={utils}
            fields={getOnDemandFields()} />

            {service.order_channel && (
                <FieldMapper
                utils={utils}
                fields={getDeliveryFields()} />
            )}

            <FieldMapper
            utils={utils}
            fields={getRestrictionFields()} />

            {getBookingServiceAreas()}
            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

// utils
export const fetchServices = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/reservations/',  {
                type: 'services',
                show_inactive: true,
                ...props
            });
            if(props && props.download) {
                resolve(response);
                return;
            }
            resolve({
                paging: response.paging,
                services: response.services.map(service => Service.create(service))
            });
        } catch(e) {
            reject(e);
        }
    })
}
