import moment from 'moment-timezone';
import Request from 'files/Request.js';

class LeadClass {

    address = null;
    customer_user_id = null;
    date = null;
    email_address = null;
    first_name = null;
    full_name = null;
    id = null;
    last_name = null;
    location = null;
    phone_number = null;
    status = null;
    type = null;

    constructor() {
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    close = () => {
        this.address = this.edits.address;
        this.email_address = this.edits.email_address;
        this.first_name = this.edits.first_name;
        this.full_name = `${this.edits.first_name} ${this.edits.last_name}`;
        this.last_name = this.edits.last_name;
        this.location = this.edits.location;
        this.phone_number = this.edits.phone_number;
        this.status = this.edits.status;
        this.type = this.edits.type;
        this.edits = null;
    }

    create = props => {
        this.address = props.address;
        this.customer_user_id = props.customer_user_id;
        this.date = props.date && moment.utc(props.date).local();
        this.email_address = props.email_address;
        this.first_name = props.first_name;
        this.full_name = props.full_name;
        this.id = props.id;
        this.last_name = props.last_name;
        this.location = props.location;
        this.phone_number = props.phone_number;
        this.status = props.status;
        this.type = props.type;
        return this;
    }

    open = () => {
        this.edits = {
            address: this.address,
            email_address: this.email_address,
            first_name: this.first_name,
            last_name: this.last_name,
            location: this.location,
            phone_number: this.phone_number,
            status: this.status,
            type: this.type
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { id, status, type } = await Request.post(utils, '/leads/', {
                    ...this.toJSON(),
                    type: 'new'
                });

                this.close();
                this.id = id;
                this.type = type;
                this.status = status;

                utils.content.fetch('leads');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    toJSON = () => ({
        address: this.edits.address,
        email_address: this.edits.email_address,
        first_name: this.edits.first_name,
        last_name: this.edits.last_name,
        lead_type: this.edits.type && this.edits.type.code,
        location: this.edits.location,
        phone_number: this.edits.phone_number,
        status: this.edits.status && this.edits.status.code,
    })

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { status, type } = await Request.post(utils, '/leads/', {
                    ...this.toJSON(),
                    id: this.id,
                    type: 'update'
                });

                this.close();
                this.type = type;
                this.status = status;

                utils.content.update({
                    object: this,
                    type: 'leads'
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const fetchLead = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { lead } = await Request.get(utils, '/leads/', {
                id: id,
                type: 'details'
            });
            let result = new LeadClass().create(lead);
            resolve(result);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    get: fetchLead,
    new: () => new LeadClass(),
    create: props => new LeadClass().create(props)
}