import React, { useState, useEffect } from 'react';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import { FloatingMenuIndex } from 'structure/Layer.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import { UserDetails } from 'managers/Users.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const UserLookupField = ({ avatarClick, companyID, containerStyle, hideAvatar, icon, inline, levels, onChange, placeholder, preserveResult, user, utils }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState('');

    const onValidate = () => {
        return selected ? true : false
    }

    const onUserClick = () => {
        if(avatarClick === false) {
            return;
        }
        utils.layer.open({
            id: `user-details-${selected.user_id}`,
            abstract: Abstract.create({
                type: 'users',
                object: selected
            }),
            Component: UserDetails
        })
    }

    const onClick = async selectedUser => {
        setResults([]);
        if(preserveResult !== false) {
            setSelected(selectedUser);
            setText(selectedUser.full_name);
        }
        if(preserveResult === false) {
            setText('');
        }

        if(typeof(onChange) === 'function') {
            onChange(selectedUser);
        }
    }

    const onTextChange = text => {
        if(textTimeout) {
            clearTimeout(textTimeout);
        }
        setText(text);
        setLoading(true);
        setSelected(false);

        setTextTimeout(async () => {
            try {
                await Utils.sleep(0.25);
                if(text.length < 3) {
                    setResults([]);
                    setLoading(false);
                    if(typeof(onChange) === 'function') {
                        onChange(null);
                    }
                    return;
                }
                let { users } = await Request.get(utils, '/users/', {
                    company_id: companyID,
                    levels: levels || [],
                    limit: 10,
                    search_text: text,
                    type: 'all'
                })
                setLoading(false);
                setResults(users.map(user => User.create(user)));

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue searching for user accounts. ${e.message || 'An unknown error occurred'}`
                });
            }
        });
    }

    const getStyles = () => {
        if(inline !== true) {
            return {
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden',
                marginTop: 8
            }
        }
        return {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 43,
            backgroundColor: Appearance.colors.panelBackground(),
            border: `1px solid ${Appearance.colors.softBorder()}`,
            borderRadius: 8,
            boxShadow: '0 0.46875rem 1.1875rem rgba(0, 0, 0, 0.1)',
            zIndex: 1100,
            overflow: 'hidden'
        }
    }

    useEffect(() => {
        setSelected(user);
        setText(user ? user.full_name : '');
    }, [user]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }}>
                {selected && hideAvatar !== true && (
                    <img
                    src={selected.avatar}
                    {...avatarClick !== false && {
                        className: 'text-button',
                        onClick: onUserClick
                    }}
                    style={{
                        minWidth: 35,
                        minHeight: 35,
                        width: 35,
                        height: 35,
                        objectFit: 'cover',
                        borderRadius: 17.5,
                        overflow: 'hidden',
                        marginRight: 8
                    }}/>
                )}
                <TextField
                value={text}
                useDelay={true}
                icon={icon || 'search'}
                loading={loading}
                placeholder={placeholder || 'Search by first or last name...'}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                onChange={onTextChange}
                style={{
                    flexGrow: 1,
                    ...Appearance.textStyles.title()
                }}/>
            </div>
            {results.length > 0 && (
                <div style={getStyles()}>
                    {results.map((user, index, users) => {
                        return (
                            Views.entry({
                                key: index,
                                title: user.full_name,
                                subTitle: user.phone_number,
                                icon: {
                                    path: user.avatar
                                },
                                onClick: onClick.bind(this, user),
                                bottomBorder: index !== users.length - 1
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default UserLookupField;
