const Appearance = {
    boxShadow: (props = {}) => {
        let { dark, light } = props;
        return `${dark && dark.offset ? dark.offset.top : 2.5} ${dark && dark.offset ? dark.offset.left : 2.5} ${dark ? dark.radius : 7.5} ${window.theme === 'dark' ? `rgba(0,0,0,0.075)`:'rgba(0,0,0,0.35)'}, ${light && light.offset ? light.offset.top : -2.5} ${light && light.offset ? light.offset.left : -2.5} ${light ? light.radius : 5} ${window.theme === 'dark' ? 'rgba(85,85,85,0.075)' : 'rgba(85,85,85,0.35)'}`;
    },
    colors: {
        alert: () => window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)',
        background: () => window.theme === 'dark' ? 'rgba(25,25,25,1)' : '#F0F1F5',
        blue: '#4A90E2',
        brown: '#674429',
        darkBlue: '#1B579D',
        darkGreen: '#5E905C',
        darkGrey: '#4A4A4A',
        darkRed: '#982F23',
        darkerGrey: '#232323',
        dim: 'rgba(0,0,0,0.45)',
        divider: () => 'rgba(150,150,150,0.15)',
        green: '#6CBF66',
        grey: () => window.theme === 'dark' ? '#606060' : '#9B9B9B',
        layerBackground: () => window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(250,250,250,1)',
        lighterGrey: '#BDBDBD',
        lightGrey: '#9B9B9B',
        orange: '#EE8A2F',
        panelBackground: () => window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(250,250,250,1)',
        primary: () => window.clientParameters ? window.clientParameters.colors.regular : '#6CBF66',
        purple: '#7A5EB3',
        red: '#F45D4C',
        results: () => window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
        secondary: () => window.clientParameters ? window.clientParameters.colors.semi_dark : '#43955D',
        sheet: () => window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)',
        sidebarIcon: () => window.theme === 'dark' ? 'rgba(200,200,200,0.5)' : 'rgba(127,127,127,0.5)',
        soft: () => window.theme === 'dark' ? 'rgba(45,45,45,1)' : 'rgba(245,245,245,1)',
        softBorder: () => window.theme === 'dark' ? 'rgba(85,85,85,1)' : 'rgba(220,220,220,1)',
        subText: () => window.theme === 'dark' ? 'rgba(200,200,200,1)' : 'rgba(150,150,150,1)',
        teal: '#5AD8BC',
        tertiary: () => window.clientParameters ? window.clientParameters.colors.semi_light : '#A9BE70',
        text: () => window.theme === 'dark' ? 'white' : '#323232',
        textField: () => window.theme === 'dark' ? 'rgba(55,55,55,1)' : 'rgba(245,245,245,1)',
        transparent: 'rgba(0,0,0,0)',
        yellow: '#E6C02A',
    },
    icons: {
        overview: props => ({
            width: 50,
            height: 50,
            borderRadius: 25,
        	border: '2px solid white',
        	boxShadow: '0px 3px 10px rgba(0,0,0,0.1)',
        	objectFit: 'cover',
            backgroundColor: Appearance.colors.primary(),
            ...props
        }),
        standard: props => ({
            width: 30,
            height: 30,
            minWidth: 30,
            minHeight: 30,
            borderRadius: 15,
            objectFit: 'cover',
            backgroundColor: Appearance.colors.primary(),
            boxShadow: window.theme === 'dark' ? '3px 3px 7px rgba(0,0,0,0.15), -3px -3px 7px rgba(174,174,174,0.1)' : '3px 3px 7px #c9c8ca, -3px -3px 7px #ffffff',
            ...props
        }),
        padded: props => ({
            imageStyle: {
                objectFit: 'contain',
                padding: 5,
            },
            style: {
                width: 30,
                height: 30,
                borderRadius: 15,
                objectFit: 'contain',
                border: `1px solid ${Appearance.colors.softBorder()}`,
                backgroundColor: Appearance.colors.layerBackground(),
                boxShadow: window.theme === 'dark' ? '3px 3px 7px rgba(0,0,0,0.15), -3px -3px 7px rgba(174,174,174,0.1)' : '3px 3px 7px #c9c8ca, -3px -3px 7px #ffffff',
                ...props
            }
        })
    },
    serialize: props => {
        let values = Object.keys(props).reduce((array, key) => {
            let next = key.replace(/(?:^|\.?)([A-Z])/g, (x,y) => {return '-' + y.toLowerCase()}).replace(/^_/, '')
            array.push(`${next}: ${props[key]}`);
            return array;
        }, []);
        return values.join('; ');
    },
    styles: {
        panel: props => ({
            borderRadius: 15,
            backgroundColor: Appearance.colors.panelBackground(),
            boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25), -5px -5px 15px rgba(85,85,85,0.25)' : '5px 5px 15px rgba(175,175,174,0.25), -5px -5px 15px rgba(255,255,255,0.75)',
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: window.theme === 'dark' ? '#191919' : '#FFFFFF',
            ...props
        }),
        panelHeader: minimized => ({
            css: {
                padding: '5px 10px 5px 10px',
                borderTop: `1px solid ${window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'rgba(255,255,255,0)'}`,
                borderBottom: `1px solid ${window.theme === 'dark' ? 'rgba(35,35,35,1)' : 'rgba(235,235,235,1)'}`,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomLeftRadius: minimized ? 10 : 0,
                borderBottomRightRadius: minimized ? 10 : 0
            },
            buttons: {
                red: '#FF5B51',
                yellow: '#FDC90F',
                green: '#54C22C',
                onHover: {
                    boxShadow: window.theme === 'dark' ? 'inset 2px 2px 4px rgba(174,174,174,0.15), inset -2px -2px 4px rgba(255,255,255,0.15)' : 'inset 2px 2px 4px rgba(174,174,174,0.5), inset -2px -2px 4px rgba(255,255,255,0.5)'
                }
            }
        }),
        unstyledPanel: props => ({
            overflow: 'hidden',
            backgroundColor: window.theme === 'dark' ? 'rgba(60,60,60,1)' : 'rgba(252,252,252,1)',
            borderRadius: 10,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: Appearance.colors.divider(),
            ...props
        })
    },
    textStyles: {
        heavyHeader: props => ({
            color: Appearance.colors.text(),
            fontWeight: 700,
            fontSize: 20,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        key: props => ({
            color: Appearance.colors.text(),
            fontWeight: 600,
            fontSize: 12,
            minWidth: 100,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        layerItemTitle: props => ({
            color: Appearance.colors.text(),
            fontWeight: 800,
            fontSize: 16,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        panelTitle: props => ({
            color: Appearance.colors.text(),
            fontWeight: 800,
            fontSize: 18,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        standard: props => ({
            color: Appearance.colors.text(),
            fontWeight: 600,
            fontSize: 12,
            ...props
        }),
        subHeader: props => ({
            color: Appearance.colors.text(),
            fontWeight: 700,
            fontSize: 15,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        subTitle: props => ({
            color: Appearance.colors.subText(),
            fontWeight: 600,
            fontSize: 12,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        supportingText: props => ({
            color: Appearance.colors.subText(),
            fontWeight: 500,
            fontSize: 12,
            ...props
        }),
        title: props => ({
            color: Appearance.colors.text(),
            fontWeight: 700,
            fontSize: 14,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props
        }),
        value: props => ({
            color: Appearance.colors.subText(),
            fontWeight: 500,
            fontSize: 12,
            textAlign: 'right',
            whiteSpace: 'break-spaces',
            ...props
        }),
    }
}
export default Appearance;
