import React, { useEffect, useState } from 'react';

import { fetchDrivers } from 'managers/Users.js';

import Appearance from 'styles/Appearance.js';
import DriveExperience from 'classes/DriveExperience.js';
import HistoryEvent from 'classes/HistoryEvent.js';
import { LayerItem } from 'structure/Layer.js';
import Order from 'classes/Order.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import Route from 'classes/Route.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const EventManager = ({ driveExperience, order, reservation, route, utils }) => {

    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newStatus, setNewStatus] = useState(false);
    const [showDriverOption, setShowDriverOption] = useState(false);
    const [statusCodes, setStatusCodes] = useState([]);

    const onAddStatus = async () => {

        if(newStatus === false) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please choose an event type to continue'
            })
            return;
        }

        // check if status needs a driver attached for the order
        if(order && ([ Order.status.to_pickup, Order.status.arrived_at_host, Order.status.to_destination ].includes(newStatus))) {
            if(!driver) {
                utils.alert.show({
                    title: 'Oops!',
                    message: 'Please choose a driver for the new event to continue'
                });
                return;
            }
        }

        // check if status needs a driver attached for the ride
        if(reservation && ([ Reservation.status.to_pickup, Reservation.status.to_destination ].includes(newStatus))) {
            if(!driver) {
                utils.alert.show({
                    title: 'Oops!',
                    message: 'Please choose a driver for the new event to continue'
                });
                return;
            }
        }

        try {
            setLoading(true);
            await Utils.sleep(1);
            let { events, response } = await Request.post(utils, getEndpoint(), {
                type: 'set_manual_status',
                status: newStatus,
                ...driveExperience && {
                    drive_experience_id: driveExperience.id,
                },
                ...order && {
                    order_id: order.id,
                    order_channel: order.channel.id,
                    driver_id: (driver ? driver.user_id : null) || (order.driver ? order.driver.user_id : null)
                },
                ...reservation && {
                    reservation_id: reservation.id,
                    driver_id: (driver ? driver.user_id : null) || (reservation.driver ? reservation.driver.user_id : null)
                },
                ...route && {
                    route_id: route.id,
                    driver_id: route.driver.user_id
                }
            });
            if(response === 'status reset') {
                if(driveExperience) {
                    driveExperience.status = DriveExperience.formatStatus(null);
                }
                if(order) {
                    order.status = Order.formatStatus(null);
                }
                if(reservation) {
                    reservation.status = Reservation.formatStatus(null);
                }
                if(route) {
                    route.status = Route.formatStatus(null);
                }
                utils.alert.show({
                    title: 'All Done!',
                    message: 'The status is now set to Pending. Pending history events are not visible in the history event list'
                });
            }

            // update events if applicable
            if(events) {
                let results = events.map(evt => {
                    let target = HistoryEvent.create({
                        ...evt,
                        ...driveExperience && {
                            status: DriveExperience.formatStatus(evt.status)
                        },
                        ...order && {
                            status: Order.formatStatus(evt.status)
                        },
                        ...reservation && {
                            status: Reservation.formatStatus(evt.status)
                        },
                        ...route && {
                            status: Route.formatStatus(evt.status)
                        }
                    });
                    target.user = driver || utils.user.get();
                    return target;
                }).reverse();

                setEvents(prev_events => results.concat(prev_events));
                if(results.length > 0) {
                    if(driveExperience) {
                        driveExperience.status = results[0].status;
                    }
                    if(order) {
                        order.status = results[0].status;
                    }
                    if(reservation) {
                        reservation.status = results[0].status;
                    }
                    if(route) {
                        route.status = results[0].status;
                    }
                }
            }

            // update targets
            if(driveExperience) {
                utils.content.update({
                    type: 'drive_experiences',
                    object: driveExperience
                })
            }
            if(order) {
                utils.content.update({
                    type: 'orders',
                    object: order
                })
            }
            if(reservation) {
                utils.content.update({
                    type: 'reservations',
                    object: reservation
                })
            }
            if(route) {
                utils.content.update({
                    type: 'routes',
                    object: route
                })
            }
            
            setDriver(null);
            setLoading(false);
            setNewStatus(false);
            setShowDriverOption(false);

        } catch(e) {
            setLoading(false);
            if(e.code !== 409) {
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue adding your status event. ${e.message || 'An unknown error occurred'}`
                });
                return;
            }
            utils.alert.show({
                title: 'Just a Second',
                message: `${e.message || 'An unknown error occurred'}`,
                buttons: [{
                    key: 'confirm',
                    title: 'Choose Driver',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        setShowDriverOption(true);
                        fetchDriverAccounts();
                    }
                }
            });
        }
    }

    const onDriverChange = evt => {
        let id = Utils.attributeForKey.select(evt, 'id');
        let selectedDriver = drivers.find(d => d.user_id === parseInt(id));
        setDriver(selectedDriver);
    }

    const onStatusChange = evt => {

        let id = Utils.attributeForKey.select(evt, 'id');
        setNewStatus(id ? parseInt(id) : null);

        let status = parseInt(id);

        // check if status needs a driver attached for the target
        let shouldShowDriverOption = false;
        if(order && [ Order.status.to_pickup, Order.status.arrived_at_host, Order.status.to_destination ].includes(status) === true) {
            shouldShowDriverOption = true;
        }
        if(reservation && [ Reservation.status.to_pickup, Reservation.status.to_destination ].includes(status) === true) {
            shouldShowDriverOption = true;
        }

        // set driver option flag and fetch accounts if applicable
        setShowDriverOption(shouldShowDriverOption);
        if(shouldShowDriverOption === true) {
            if(drivers.length === 0) {
                fetchDriverAccounts();
            }
        }
    }

    const onStripeIconClick = () => {
        utils.alert.show({
            title: 'Message from Stripe',
            message: `The message for this event was generated by Stripe during payment processing.`
        });
    }

    const getDriverSelector = () => {

        if(showDriverOption === false || !(order || reservation)) {
            return null;
        }
        if(loading === 'drivers') {
            return (
                <LayerItem
                title={'Attach a Driver'}
                childrenStyle={{
                    padding: '8px 12px 8px 12px'
                }}>
                    {Views.loader()}
                </LayerItem>
            )
        }
        return (
            <LayerItem
            title={'Attach a Driver'}
            shouldStyle={false}>
                <select
                className={`custom-select ${window.theme}`}
                onChange={onDriverChange}
                style={{
                    flexGrow: 1
                }}>
                    <option>{'Choose a driver...'}</option>
                    {drivers.map((driver, index) => (
                        <option key={index} id={driver.user_id}>{driver.full_name}</option>
                    ))}
                </select>
            </LayerItem>
        )
    }

    const getEndpoint = () => {
        if(driveExperience) {
            return '/drive_experiences/';
        }
        if(order) {
            return '/order/';
        }
        if(reservation) {
            return '/reservation/';
        }
        if(route) {
            return '/quick_scan/'
        }
        return null;
    }

    const getEventIcon = evt => {
        if(evt.data && evt.data.stripe) {
            return {
                onClick: onStripeIconClick,
                path: 'images/stripe-app-icon.jpg',
                style: Appearance.icons.standard()
            }
        }
        return {
            onClick: evt.user ? Utils.users.details.bind(this, utils, evt.user) : null,
            path: evt.user ? evt.user.avatar : 'images/system-user-icon.png',
            style: Appearance.icons.standard()
        }
    }

    const getEventClickHandler = evt => {
        if(evt.data && evt.data.stripe) {
            return onStripeIconClick
        }
        return evt.user && evt.user.user_id !== 1 ? Utils.users.details.bind(this, utils, evt.user) : null
    }

    const getEventMessage = evt => {
        let { message, stripe } = evt.data;
        if(stripe && evt.user) {
            let text = message.substring(message.length - 1, message.length) === '.' ? message.substring(0, message.length - 1) : message;
            return `${text}. This event was initiated by ${evt.user.full_name}.`;
        }
        return evt.data.message;
    }

    const getEventName = evt => {
        if(evt.data && evt.data.stripe) {
            return 'Stripe Payment Processing';
        }
        return evt.user ? evt.user.full_name : 'Name Not Available';
    }

    const getSelectedStatusValue = () => {
        let status = newStatus && statusCodes.find(s => s.code === newStatus);
        return status && status.realText;
    }

    const fetchDriverAccounts = async () => {
        try {
            setLoading('drivers');
            let { drivers } = await fetchDrivers(utils, { show_admin: true });

            setLoading(false);
            setDrivers(drivers);
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the drivers list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchEvents = async () => {
        try {
            if(driveExperience) {
                let { events } = await driveExperience.getHistoryEvents(utils);
                setEvents(events);
            }
            if(order) {
                let { events } = await order.getHistoryEvents(utils);
                setEvents(events);
            }
            if(reservation) {
                let { events } = await reservation.getHistoryEvents(utils);
                setEvents(events);
            }
            if(route) {
                let { events } = await route.getHistoryEvents(utils);
                setEvents(events);
            }
            setLoading(false);
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the events list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEvents();
    }, [driveExperience, order, reservation, route]);

    useEffect(() => {
        if(driveExperience) {
            setStatusCodes(Object.values(DriveExperience.status).map(code => DriveExperience.formatStatus(code)))
        }
        if(order) {
            setStatusCodes(Object.values(Order.status).map(code => Order.formatStatus(code)))
        }
        if(reservation) {
            setStatusCodes(Object.values(Reservation.status).map(code => Reservation.formatStatus(code)))
        }
        if(route) {
            setStatusCodes(Object.values(Route.status).map(code => Route.formatStatus(code)))
        }
    }, []);

    return (
        <div style={{
            textAlign: 'left',
            position: 'relative',
            width: '100%'
        }}>
            {loading === true
                ?
                Views.loader()
                :
                <>
                <LayerItem
                title={'New Event'}
                shouldStyle={false}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <select
                        className={`custom-select ${window.theme}`}
                        onChange={onStatusChange}
                        value={getSelectedStatusValue()}
                        style={{
                            flexGrow: 1
                        }}>
                            <option>{'Choose an event type...'}</option>
                            {statusCodes.map((statusCode, index) => (
                                <option key={index} id={statusCode.code}>{statusCode.realText}</option>
                            ))}
                        </select>
                        {newStatus !== false && (
                            <img
                            className={'text-button'}
                            onClick={onAddStatus}
                            src={'images/next-icon-clear-small.png'}
                            style={{
                                width: 30,
                                height: 30,
                                minWidth: 30,
                                minHeight: 30,
                                borderRadius: 15,
                                overflow: 'hidden',
                                marginLeft: 8,
                                backgroundColor: Appearance.colors.primary()
                            }} />
                        )}
                    </div>
                </LayerItem>

                {getDriverSelector()}

                <LayerItem
                title={'Past Events'}
                lastItem={true}
                shouldStyle={false}
                childrenStyle={{
                    maxHeight: 240,
                    overflowY: 'scroll'
                }}>
                    {events && events.length == 0
                        ?
                        <div style={{
                            ...Appearance.styles.unstyledPanel()
                        }}>
                            {Views.entry({
                                title: 'Nothing Found',
                                subTitle: 'No history log events were found',
                                icon: {
                                    style: Appearance.icons.standard(),
                                    path: 'images/status-pending.png'
                                }
                            })}
                        </div>
                        :
                        events.map((evt, index) => {
                            return (
                                <div
                                key={index}
                                style={{
                                    ...Appearance.styles.unstyledPanel(),
                                    marginBottom: index !== events.length - 1 ? 8 : 0
                                }}>
                                    {Views.entry({
                                        badge: {
                                            text: evt.status.realText,
                                            color: evt.status.color
                                        },
                                        bottomBorder: false,
                                        icon: getEventIcon(evt),
                                        onClick: getEventClickHandler(evt),
                                        subTitle: evt.date.format('MMMM Do, YYYY [at] h:mma'),
                                        title: getEventName(evt)
                                    })}
                                    {evt.data && typeof(evt.data.message) === 'string' && (
                                        <div style={{
                                            padding: '8px 12px 8px 12px',
                                            borderTop: `1px solid ${Appearance.colors.divider()}`
                                        }}>
                                            <span style={{
                                                ...Appearance.textStyles.subTitle(),
                                                display: 'block',
                                                whiteSpace: 'break-spaces'
                                            }}>{getEventMessage(evt)}</span>
                                        </div>
                                    )}
                                </div>
                            )
                        })
                    }
                </LayerItem>
                </>
            }
        </div>
    )
}
export default EventManager;
