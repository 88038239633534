import React, { useEffect, useRef, useState } from 'react';

import { fetchCompanies } from 'managers/Companies.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import DatePickerField from 'views/DatePickerField.js';
import Driver from 'classes/Driver.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import FieldMapper from 'views/FieldMapper.js';
import Here from 'classes/Here.js';
import Layer, { LayerItem, LayerMap, LayerNote } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import { Map } from 'views/MapElements.js';
import NoDataFound from 'views/NoDataFound.js';
import Notification from 'classes/Notification.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import TextField  from 'views/TextField.js';
import User from 'classes/User.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Vehicle from 'classes/Vehicle.js';
import Views from 'views/Main.js';

// panels
export const DeepLinkReport = ({ index, utils }) => {

    const panelID = 'deep_linkReport';
    const chartRef = useRef(null);

    const [date, setDate] = useState(moment());
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ end_date: moment(), modifier: null, start_date: moment().subtract(3, 'months') });
    const [vehicle, setVehicle] = useState(null);
    const [vehicles, setVehicles] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Vehicle Deep Link Report',
                chart: chartRef,
                dates: manager,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/vehicles/', {
                    type: 'usage_reports',
                    ...formatResults(utils, props => ({
                        ...props,
                        modifier: props.modifier && props.modifier.key
                    }))
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows the data harvested through vehicles with DeepLink support. The data represents power, speed, battery state, range, and other information harvested during the day',
            items: [{
                key: 'download',
                title: 'Download Report',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div style={{
                height: 50,
                paddingBottom: 25,
                height: '100%'
            }}>
                <div className={'row p-0 m-0'}>
                    <div className={'col-12 col-md-4 px-0 pb-2 pb-md-0 text-md-left'}>
                        <select
                        className={`custom-select ${window.theme}`}
                        defaultValue={manager.modifier ? manager.modifier.label : null}
                        style={{
                            width: 160
                        }}
                        onChange={evt => {
                            let id = Utils.attributeForKey.select(evt, 'id');
                            setManager('modifier', getModifiers().find(modifier => id === modifier.key));
                        }}>
                            {getModifiers().map((modifier, index) => {
                                return (
                                    <option key={index} id={modifier.key}>{modifier.label}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className={'col-12 col-md-4 px-0 pb-2 pb-md-0 text-left text-md-center'}>
                        <select
                        className={`custom-select ${window.theme}`}
                        defaultValue={vehicle ? vehicle.category.name : null}
                        style={{
                            width: 200
                        }}
                        onChange={evt => {
                            let id = parseInt(Utils.attributeForKey.select(evt, 'id'));
                            setVehicle(vehicles.find(vehicle => id === vehicle.id));
                        }}>
                            <option>{'Choose a Vehicle'}</option>
                            {vehicles.map((vehicle, index) => {
                                return (
                                    <option key={index} id={vehicle.id}>
                                        {`${vehicle.category.name} (VIN ${vehicle.vin.substr(vehicle.vin.length - 6)})`}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div style={{
                height: 330,
                paddingTop: 10,
                paddingBottom: 15
            }}>
                <Line
                ref={chartRef}
                width={500}
                height={140}
                data={{
                    labels: vehicle.logs.map(log => moment(log.date, 'YYYY-MM').format('MMMM YYYY')),
                    datasets: [{
                        data: vehicle.logs.map(log => log[manager.modifier.key] || 0),
                        borderColor: Appearance.colors.primary(),
                        fill: true,
                        backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                        pointBackgroundColor: 'white',
                        pointBorderWidth: 2,
                        pointRadius: 5
                    }]
                }}
                options={{
                    title: {
                        display: false
                    },
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                switch(manager.modifier.key) {
                                    case 'distance':
                                    return `${Utils.numberFormat(tooltipItem.yLabel / 1609.344)} miles`;

                                    case 'duration':
                                    let hours = Utils.numberFormat(parseFloat(tooltipItem.yLabel) / 60 / 60);
                                    return `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;

                                    case 'speed':
                                    return `${parseInt(tooltipItem.yLabel)} mph`;

                                    default:
                                    return tooltipItem.yLabel
                                }
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 20
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: (value, index, values) => {
                                    switch(manager.modifier.key) {
                                        case 'distance':
                                        return Utils.numberFormat(value / 1609.344);

                                        case 'duration':
                                        let hours = Utils.numberFormat(parseFloat(value) / 60 / 60);
                                        return `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;

                                        case 'speed':
                                        return `${parseInt(value)} mph`;

                                        default:
                                        return value
                                    }
                                }
                            }
                        }]
                    }
                }} />
            </div>
            </>
        )
    }

    const getModifiers = () => {
        return [{
            key: 'duration',
            label: 'Hours of Use'
        },{
            key: 'speed',
            label: 'Average Speed'
        },{
            key: 'distance',
            label: 'Miles Travelled'
        }];
    }

    const fetchDeepLink = async () => {
        try {
            let { vehicles } = await Request.get(utils, '/vehicles/', {
                type: 'usage_reports',
                ...formatResults(utils, props => ({
                    ...props,
                    modifier: props.modifier && props.modifier.key
                }))
            });

            let targets = vehicles.map(vehicle => Vehicle.create(vehicle));
            setLoading(false);
            setVehicles(vehicles);
            setVehicle(vehicles.length > 0 ? vehicles[0] : null);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving your vehicle usage reports. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupModifier = () => {
        setManager('modifier', getModifiers()[2]);
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchDeepLink();
    }, [manager]);

    useEffect(() => {
        setupModifier();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Vehicle Deep Link Report'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const StreetLinkIncidents = ({ index, utils }) => {

    const panelID = 'streetLinkIncidents';
    const limit = 5;
    const offset = useRef(0);

    const [annotations, setAnnotations] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [overlays, setOverlays] = useState([]);
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const onAssistClick = key => {
        if(key === 'reset') {
            setSelectedLocation(null);
            return;
        }
        if(key === 'show-all') {
            setAnnotations([ ...annotations ]);
            return;
        }
        if(key === 'explore') {
            utils.layer.open({
                id: 'street-link-address-lookup',
                Component: StreetLinkAddressLookup.bind(this, {
                    onClose: location => {
                        if(location) {
                            setSelectedLocation({
                                address: location.address,
                                lat: location.location.latitude,
                                long: location.location.longitude
                            });
                        }
                    }
                })
            });
            return;
        }
    }

    const onResultClick = result => {
        utils.layer.open({
            id: `street-link-incident-details-${result.id}`,
            Component: StreetLinkIncidentDetails.bind(this, {
                incident: result
            })
        });
    }

    const onSearchTextChange = text => {
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onUpdateResults = () => {
        setLoading(false);
        if(!results) {
            return;
        }

        let text = manager.search_text ? manager.search_text.toLowerCase() : null;
        setResults(results.filter((r, index) => {
            return (r.name ? r.name.toLowerCase().includes(text) : false) || Here.typeToText(r.type).toLowerCase().includes(text);
        }));
    }

    const getAssistProps = () => {
        return {
            message: 'The Street Link Incidents map shows traffic incidents, contruction, and other variables that may delay a Reservation from arriving on time',
            items: [{
                key: 'show-all',
                title: 'Show All Annotations',
                style: 'default'
            },{
                key: 'explore',
                title: 'Explore Other Locations',
                style: 'default'
            },{
                key: 'reset',
                title: 'Reset to Default Location',
                style: 'default',
                visible: selectedLocation ? true : false
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(results.length === 0) {
            return (
                Views.entry({
                    title: 'No Information Found',
                    subTitle: 'There is not traffic, construction, or incidents available to view',
                    borderBottom: false
                })
            )
        }
        return results.filter((result, index) => {
            return paging && (index < manager.offset || index > manager.offset + 4) ? false : true
        }).map((result, index, results) => {
            return (
                Views.entry({
                    key: result.id,
                    title: result.name || 'Name Not Available',
                    subTitle: result.description,
                    badge: result.status !== 'active' && {
                        text: 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    icon: Here.trafficIcon(result.type),
                    bottomBorder: index !== results.length - 1,
                    onClick: onResultClick.bind(this, result)
                })
            )
        });
    }

    const fetchIncidents = async () => {
        try {
            setLoading(true);
            let { locations, paging, results } = await Request.get(utils, '/vehicles/', {
                type: 'here_incidents',
                limit: limit,
                offset: offset.current,
                location: selectedLocation || {
                    lat: window.userLocation.latitude,
                    long: window.userLocation.longitude
                }
            });

            setLoading(false);
            if(results.length === 0) {
                utils.alert.show({
                    title: 'Nothing Found',
                    message: `No incident reports were found for ${selectedLocation ? (selectedLocation.name || selectedLocation.address) : 'your location'}`
                });
                return;
            }

            // fetch paged incident results
            setResults(results);
            setPaging(paging);

            // generate overlays from location entries
            setOverlays(locations.filter(entry => {
                return entry.location && entry.location.coordinates && entry.location.coordinates.length > 1;
            }).map((entry, index) => ({
                color: Appearance.colors.orange,
                coordinates: entry.location.coordinates,
                key: index
            })));

            // generate annotations from location entries
            setAnnotations(locations.filter(entry => {
                return entry.location && entry.location.coordinates && entry.location.coordinates.length > 1;
            }).map((entry, index) => ({
                key: index,
                title: entry.name,
                subTitle: entry.description,
                location: entry.location.start,
                icon: {
                    ...Here.trafficAnnotation(entry.type),
                    imageStyle: {
                        objectFit: 'contain'
                    },
                    style: {
                        boxShadow: null,
                        backgroundColor: Appearance.colors.transparent
                    }
                }
            })))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading StreetLink incidents. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchIncidents();
    }, [selectedLocation]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`StreetLink Incidents ${selectedLocation && selectedLocation.address ? `for ${selectedLocation.address}` : ''}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    offset.current = next;
                    fetchIncidents();
                }
            }
        }}>
            <div style={{
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <Map
                utils={utils}
                center={window.userLocation}
                isZoomEnabled={true}
                isRotationEnabled={true}
                isScrollEnabled={true}
                overlays={overlays}
                annotations={annotations}
                viewportTarget={'annotations'}
                style={{
                    height: 500
                }}/>
            </div>
            {getContent()}
        </Panel>
    )
}

export const StreetLinkTraffic = ({ index, utils }) => {

    const panelID = 'streetLinkTraffic';
    const [annotations, setAnnotations] = useState([]);
    const [flow, setFlow] = useState(null);
    const [loading, setLoading] = useLoading();
    const [offset, setOffset] = useState(0);
    const [results, setResults] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);

    const onAssistClick = key => {
        if(key === 'reset') {
            setSelectedLocation(null);
            return;
        }
        if(key === 'show-all') {
            setAnnotations([ ...annotations ]);
            return;
        }
        if(key === 'explore') {
            utils.layer.open({
                id: 'street-link-address-lookup',
                Component: StreetLinkAddressLookup.bind(this, {
                    onClose: location => {
                        if(location) {
                            setSelectedLocation({
                                address: location.address,
                                lat: location.location.latitude,
                                long: location.location.longitude
                            });
                        }
                    }
                })
            });
            return;
        }
    }

    const getAssistProps = () => {
        return {
            message: 'The Street Link Traffic map shows traffic congestion and other variables that may delay a Reservation from arriving on time',
            items: [{
                key: 'show-all',
                title: 'Show All Annotations',
                style: 'default'
            },{
                key: 'explore',
                title: 'Explore Other Locations',
                style: 'default'
            },{
                key: 'reset',
                title: 'Reset to Default Location',
                style: 'default',
                visible: selectedLocation ? true : false
            }]
        }
    }

    const fetchFlow = async () => {
        try {
            setLoading(true);
            let results = await Here.flow(utils, {
                location: selectedLocation || {
                    lat: window.userLocation.latitude,
                    long: window.userLocation.longitude
                }
            });

            setLoading(false);
            if(results.length === 0) {
                utils.alert.show({
                    title: 'Nothing Found',
                    message: `No traffic reports were found for ${selectedLocation ? (selectedLocation.name || selectedLocation.address) : 'your location'}`
                });
                return;
            }

            setResults(results);
            setFlow(results.filter(r => r.coordinates && r.coordinates.length > 1).map((r, index) => {
                return {
                    key: `street-link-traffic-${index}`,
                    jamFactor: r.jam_factor,
                    coordinates: r.coordinates
                }
            }))

            setAnnotations(results.filter(r => r.coordinates && r.coordinates.length > 1).map((r, index) => {
                return {
                    key: `street-link-traffic-annotation-${index}`,
                    title: r.jam_factor > 7.5 ? 'Heavy Traffic' : 'Moderate Traffic',
                    subTitle: `Average speed of ${r.speed || 0}mph`,
                    location: {
                        latitude: r.coordinates[0][0],
                        longitude: r.coordinates[0][1]
                    },
                    icon: {
                        path: `/images/traffic-flow-annotation-${r.jam_factor > 7.5 ? 'red' : 'yellow'}.png`,
                        style: {
                            boxShadow: null,
                            backgroundColor: Appearance.colors.transparent
                        },
                        imageStyle: {
                            objectFit: 'contain'
                        }
                    }
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading Street Link Traffic. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchFlow();
    }, [selectedLocation]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`StreetLink Traffic ${selectedLocation && selectedLocation.address ? `for ${selectedLocation.address}` : ''}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            <div style={{
                padding: 12,
            }}>
                <Map
                utils={utils}
                center={window.userLocation}
                isZoomEnabled={true}
                isRotationEnabled={true}
                isScrollEnabled={true}
                annotations={annotations}
                flow={flow}
                style={{
                    height: 500
                }}/>
            </div>
        </Panel>
    )
}

export const Vehicles = ({ index, utils }) => {

    const panelID = 'vehicles';
    const limit = 5;

    const [annotations, setAnnotations] = useState(null);
    const [followProps, setFollowProps] = useState(null);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [vehicles, setVehicles] = useState([]);

    const onAddNewVehicle = () => {
        utils.layer.open({
            id: 'new-vehicle',
            abstract: Abstract.create({
                type: 'vehicles',
                object: Vehicle.new()
            }),
            Component: AddEditVehicle.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Vehicles',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/vehicles/', {
                    type: 'list',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onLeaveSockets = async () => {
        try {
            setVehicles(vehicles => {
                vehicles.forEach(vehicle => {
                    utils.sockets.emit('vehicles', 'leave_location_updates', { vehicle_id: vehicle.id });
                    utils.sockets.off('vehicles', `on_location_update_${vehicle.id}`, onUpdateLocation);
                });
                return vehicles;
            });
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onUpdateLocation = data => {
        try {
            let { location, vehicle_id } = JSON.parse(data);
            setAnnotations(annotations => {
                return annotations.map(annotation => {
                    if(annotation.data.key === `vehicle-${vehicle_id}`) {
                        annotation.location = {
                            latitude: location.lat,
                            longitude: location.long,
                            heading: location.heading
                        }
                    }
                    return annotation;
                })
            });

        } catch(e) {
            console.error(e.message);
        }
    }

    const onVehicleClick = vehicle => {
        if(!vehicle.location) {
            Utils.vehicles.details(utils, vehicle);
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'follow',
                title: 'Follow Driver',
                style: 'default'
            },{
                key: 'details',
                title: 'View Vehicle Details',
                style: 'default'
            }]
        }, key => {
            if(key === 'details') {
                Utils.vehicles.details(utils, vehicle);
                return;
            }
            if(key === 'follow') {
                setFollowProps({
                    type: 'driver',
                    key: `vehicle-${vehicle.id}`
                });
                return;
            }
        })
    }

    const getAssistProps = () => {
        return {
            message: `This map shows the active location for each vehicle. The last known location will be shown if the vehicle is not currently attached to a driver's app`,
            items: [{
                key: 'download',
                title: 'Download Vehicles',
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        let buttons = [{
            key: 'new',
            title: 'New Vehicle',
            style: 'primary',
            onClick: onAddNewVehicle
        }];
        if(followProps) {
            buttons = [{
                key: 'reset',
                title: 'Stop Following',
                style: 'secondary',
                onClick: () => setFollowProps(null)
            }].concat(buttons);
        }
        return buttons;
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div
            className={'d-none d-lg-flex'}
            style={{
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <Map
                utils={utils}
                center={window.userLocation}
                follow={followProps}
                mapType={'standard'}
                showsZoomControl={true}
                showsScale={'visible'}
                showsCompass={'visible'}
                showUserLocation={false}
                isZoomEnabled={true}
                isRotationEnabled={true}
                isScrollEnabled={Utils.isMobile() ? false : true}
                removeOldAnnotations={true}
                annotations={annotations}
                style={{
                    height: 500
                }}/>
            </div>
            <div style={{
                width: '100%',
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <TextField
                icon={'search'}
                useDelay={true}
                placeholder={'Search for customer, vin, or vehicle id...'}
                onChange={onSearchTextChange} />
            </div>
            {vehicles.length === 0 && (
                Views.entry({
                    title: 'No Vehicles Found',
                    subTitle: 'There are no vehicle locations available to view',
                    borderBottom: false,
                    icon: {
                        path: `/images/vehicle-icon-${window.theme}.png`,
                        ...Appearance.icons.padded()
                    }
                })
            )}
            {vehicles.map((vehicle, index) => {
                return (
                    Views.entry({
                        key: vehicle.id,
                        ...vehicle.getEntryValues(),
                        subTitle: vehicle.vin,
                        badge: vehicle.hasLocation() && {
                            text: 'Located',
                            color: Appearance.colors.blue
                        },
                        bottomBorder: index !== vehicles.length - 1,
                        onClick: onVehicleClick.bind(this, vehicle)
                    })
                )
            })}
            </>
        )
    }

    const fetchVehicles = async () => {
        try {
            let { paging, vehicles } = await Request.get(utils, '/vehicles/', {
                type: 'list',
                limit: limit,
                ...manager
            });

            let user = utils.user.get();
            let targets = vehicles.map(vehicle => Vehicle.create(vehicle));

            setLoading(false);
            setPaging(paging);
            setVehicles(prev_targets => {
                prev_targets.forEach(vehicle => {
                    utils.sockets.emit('vehicles', 'leave_location_updates', { vehicle_id: vehicle.id });
                    utils.sockets.off('vehicles', `on_location_update_${vehicle.id}`, onUpdateLocation);
                });
                targets.forEach(vehicle => {
                    utils.sockets.emit('vehicles', 'location_updates', {
                        company_id: user.company ? user.company.id : null,
                        user_id: user.user_id,
                        vehicle_id: vehicle.id
                    });
                    utils.sockets.on('vehicles', `on_location_update_${vehicle.id}`, onUpdateLocation);
                });
                return targets;
            });

            setAnnotations(targets.reduce((array, vehicle) => {
                if(vehicle.hasLocation()) {
                    array.push({
                        title: `${vehicle.category.name} (${vehicle.id})`,
                        subTitle: vehicle.vin,
                        location: vehicle.location,
                        icon: {
                            color: Appearance.colors.blue,
                            type: 'broadcast'
                        },
                        onClick: onVehicleClick.bind(this, vehicle),
                        data: {
                            key: `vehicle-${vehicle.id}`,
                            vehicle: vehicle
                        }
                    });
                }
                return array;
            }, []));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the vehicle locations list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchVehicles();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['vehicles', 'vehicleCategories'], {
            onFetch: fetchVehicles,
            onUpdate: abstract => {
                switch(abstract.type) {
                    case 'vehicles':
                    setVehicles(vehicles => {
                        return vehicles.map(vehicle => abstract.compare(vehicle));
                    });
                    break;

                    case 'vehicleCategories':
                    setVehicles(vehicles => {
                        return vehicles.map(vehicle => {
                            vehicle.category = abstract.compare(vehicle.category);
                            return vehicle;
                        });
                    });
                    break;
                }
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
            onLeaveSockets();
        };
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Vehicles'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: getButtons(),
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const VehicleCategories = ({ index, utils }) => {

    const panelID = 'vehicleCategories';
    const limit = 5;

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Vehicle Categories',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchVehicleCategories(utils, {
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewCategory = () => {

        // prepare vehicle category with optional drive experience flavor
        let category = Vehicle.Category.new();
        if(utils.client.flavors.enabled('vehicles.drive_experiences') === true) {
            category.drive_experiences = { enabled: false };
        }

        // open layer for category editing
        utils.layer.open({
            id: 'new-vehicle-category',
            abstract: Abstract.create({
                type: 'vehicleCategories',
                object: category
            }),
            Component: AddEditVehicleCategory.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows the types of vehicles that the company is currently offering. These are not individual vehicles but are categories that hold individual vehicles',
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Categories'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(categories.length === 0) {
            return (
                Views.entry({
                    title: 'No Vehicle Categories Found',
                    subTitle: 'There are no vehicle categories available to view',
                    borderBottom: false,
                    icon: {
                        path: `/images/vehicle-icon-${window.theme}.png`,
                        ...Appearance.icons.padded()
                    }
                })
            )
        }
        return categories.map((category, index) => {
            return (
                Views.entry({
                    key: index,
                    title: category.name,
                    subTitle: `${Utils.toCurrency(category.rate)} per mile`,
                    icon: {
                        path: `/images/vehicle-icon-${window.theme}.png`,
                        ...Appearance.icons.padded()
                    },
                    badge: [{
                        text: category.is_default ? 'Default' : null,
                        color: Appearance.colors.primary()
                    },{
                        text: category.active ? null : 'Not Active',
                        color: Appearance.colors.grey()
                    }],
                    bottomBorder: index !== categories.length - 1,
                    onClick: Utils.vehicles.category.details.bind(this, utils, category)
                })
            )
        });
    }

    const fetchCategories = async () => {
        try {
            let { categories, paging } = await fetchVehicleCategories(utils, {
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCategories(categories.map(category => Vehicle.Category.create(category)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the vehicle categories list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchCategories();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'vehicleCategories', {
            onFetch: fetchCategories,
            onUpdate: abstract => {
                setCategories(categories => {
                    return categories.map(category => abstract.compare(category));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Vehicle Categories'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Category',
                style: 'default',
                onClick: onNewCategory
            }],
            search: {
                placeholder: 'Search by vehicle category name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const VehicleDemand = ({ index, utils }) => {

    const panelID = 'vehicleDemandBreakdown';
    const chartRef = useRef(null);

    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [chart, setChart] = useState(null);
    const [manager, setManager, formatResults] = useResultsManager({ end_date: null, start_date: null });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Vehicle Demand',
                chart: chartRef,
                dates: manager,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/vehicle/', {
                    type: 'booking_times',
                    vehicle_id: category ? category.id : null,
                    ...formatResults(utils),
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows the demand of the vehicles chosen for all reservations. The left side shows the amount of vehicles booked and the bottom shows the time of day',
            items: [{
                key: 'download',
                title: 'Download Report',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
            }}>
                <div className={'col-12 col-lg-6 px-0 pb-2 pt-0 p-lg-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%'
                    }}>
                        <select
                        className={`custom-select ${window.theme}`}
                        defaultValue={category ? category.name : null}
                        style={{
                            maxWidth: Utils.isMobile() ? null : 200
                        }}
                        onChange={evt => {
                            let id = parseInt(Utils.attributeForKey.select(evt, 'id'));
                            setCategory(categories.find(category => id === category.id));
                        }}>
                            <option>{'Choose a Vehicle'}</option>
                            {categories.map((category, index) => {
                                return <option key={index} id={category.id}>{category.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        canRemove={true}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }} />
                    </div>
                </div>
            </div>
            <div style={{
                position: 'relative',
                height: 350,
                padding: 12
            }}>
                {!chart || chart.labels.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of usage are needed for the graph'}/>
                    :
                    <Line
                    ref={chartRef}
                    width={400}
                    height={100}
                    data={{
                        labels: chart.labels,
                        datasets: [{
                            data: chart.data,
                            borderColor: Appearance.colors.primary(),
                            fill: true,
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                            pointBackgroundColor: 'white',
                            pointBorderWidth: 2,
                            pointRadius: 5
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return parseInt(tooltipItem.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + (parseInt(tooltipItem.yLabel) === 1 ? 'Booking' : 'Bookings');
                                }
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }} />
                }
            </div>
            </>
        )
    }

    const fetchCategories = async () => {
        try {
            let { categories } = await fetchVehicleCategories(utils);
            setCategory(categories.length > 0 ? categories[0] : null);
            setCategories(categories);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the vehicle categories. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchDemand = async () => {
        try {
            if(!category) {
                return;
            }
            let { results } = await Request.get(utils, '/vehicle/', {
                type: 'booking_times',
                vehicle_id: category.id,
                ...formatResults(utils)
            });

            setLoading(false);
            setChart({
                labels: [...Array(24)].map((_, index) => moment().startOf('day').add(index, 'hours').format('ha')),
                data: [...Array(24)].map((_, index) => {
                    let entry = results.find(e => e.hour === moment().startOf('day').add(index, 'hours').format('HH'));
                    return entry ? entry.total : 0;
                }),
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the hourly demand breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchDemand();
    }, [category, manager]);

    useEffect(() => {
        fetchCategories();
    }, []);
    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Vehicle Demand'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const VehicleOnboardingApps = ({ channel }, { index, utils }) => {

    const panelID = `onboarding${Utils.ucFirst(channel)}VehicleApps`;
    const limit = 5;

    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `${Utils.ucFirst(channel)} Vehicle Applications`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/vehicles/', {
                    type: 'onboarding_applications_admin',
                    category: channel,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all ${channel} applications that have been submitted. You can click view the details of an application by clicking on it.`,
            items: [{
                key: 'download',
                title: 'Download Applications',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(applications.length === 0) {
            return (
                Views.entry({
                    title: `No ${Utils.ucFirst(channel)} Applications Found`,
                    subTitle: 'There are no applications available to view',
                    borderBottom: false,
                    icon: {
                        path: `/images/vehicle-icon-${window.theme}.png`,
                        ...Appearance.icons.padded()
                    }
                })
            )
        }
        return applications.map((application, index) => {
            return (
                Views.entry({
                    key: index,
                    title: application.company ? application.company.name : application.customer.full_name,
                    subTitle: moment(application.date).format('[Submitted] MMMM Do, YYYY [at] h:mma'),
                    icon: {
                        style: Appearance.icons.standard(),
                        path: application.company ? application.company.image : application.customer.avatar
                    },
                    badge: application.status,
                    bottomBorder: index !== applications.length - 1,
                    onClick: Utils.vehicles.application.details.bind(this, utils, application)
                })
            )
        });
    }

    const fetchApplications = async () => {
        try {
            let { applications, paging } = await Request.get(utils, '/vehicles/', {
                type: 'onboarding_applications_admin',
                limit: limit,
                category: channel,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setApplications(applications.map(app => Vehicle.Application.create(app)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the vehicle applications list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchApplications();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'voApps', {
            onFetch: fetchApplications,
            onUpdate: abstract => {
                setApplications(applications => {
                    return applications.map(app => abstract.compare(app));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${Utils.ucFirst(channel)} Applications`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: `Search by application id or ${channel} name...`,
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditVehicle = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-vehicle' : `edit-vehicle-${abstract.getID()}`;
    const [categories, setCategories] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState(null);
    const [years, setYears] = useState([]);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This vehicle has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this vehicle. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setVehicle(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!vehicle) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'category',
                title: 'Category',
                description: 'The category for a vehicle specifies how the vehicle handles mileage rates, hourly rates, and base rates. These rates are used when generating cost estimates and processing completed reservations.',
                component: 'list',
                value: vehicle.category && {
                    id: vehicle.category.id,
                    title: vehicle.category.name
                },
                items: categories.map(category => ({
                    id: category.id,
                    title: category.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        category: item && categories.find(category => {
                            return category.id === item.id;
                        })
                    });
                },

            },{
                key: 'customer',
                required: false,
                title: 'Customer',
                description: 'Attaching a customer to a vehicle tells our system that the vehicle belongs to that individual. This is important for tracking, invoicing, and reimbursements.',
                value: vehicle.customer,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ customer: user })
            },{
                key: 'company',
                required: false,
                title: 'Company',
                description: 'Attaching a company to a vehicle tells our system that the vehicle belongs to that entity. This is important for tracking, invoicing, and reimbursements.',
                component: 'list',
                value: vehicle.company && {
                    id: vehicle.company.id,
                    title: vehicle.company.name
                },
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        company: item && companies.find(company => {
                            return company.id === item.id
                        })
                    });
                }
            },{
                key: 'color',
                title: 'Body Color',
                description: 'An accurate body color is required for all vehicles. This information helps us catalog vehicle appearance across the entire fleet.',
                value: vehicle.color,
                component: 'color_picker',
                onChange: color => onUpdateTarget({ color: color })
            },{
                key: 'year',
                title: 'Manufacturing Year',
                description: 'An accurate manufacturing year is required for all vehicles. This information helps us catalog vehicle age across the entire fleet.',
                component: 'list',
                onChange: item => onUpdateTarget({ year: item && parseInt(item.id) }),
                value: vehicle.year && {
                    id: parseInt(vehicle.year),
                    title: vehicle.year
                },
                items: years.map(year => ({
                    id: parseInt(year),
                    title: year
                }))
            },{
                key: 'mileage',
                title: 'Mileage',
                description: `An accurate reading for the vehicle's mileage is required for all new vehicles. This information helps us track usage over time.`,
                value: vehicle.mileage,
                component: 'textfield',
                onChange: text => onUpdateTarget({ mileage: text })
            },{
                key: 'plate',
                title: 'License Plate',
                description: `An accurate license plate is required for all vehicles. This information helps us provide security benefits to customers.`,
                value: vehicle.plate,
                component: 'textfield',
                onChange: text => onUpdateTarget({ plate: text })
            },{
                key: 'vin',
                title: 'Vehicle Identification Number',
                description: `An accurate body color is required for all vehicles. This information helps us catalog vehicles across the entire fleet.`,
                value: vehicle.vin,
                component: 'textfield',
                onChange: text => onUpdateTarget({ vin: text })
            }]
        }];
    }

    const setupTargets = async () => {
        try {
            let edits = abstract.object.open();
            setVehicle(edits);

            let { categories } = await fetchVehicleCategories(utils);
            setCategories(categories);

            let { companies } = await fetchCompanies(utils);
            setCompanies(companies);

            let years = [...Array(25)].map((_, index) => {
                return moment().subtract(index, 'years').format('YYYY');
            });
            setYears(years);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the company and vehicle targets. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={abstract.getID() ? `Editing "${abstract.getTitle()}"` : 'New Vehicle'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setVehicle(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditVehicleCategory = ({ isNewTarget, onAddCategory }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-vehicle-category' : `edit-vehicle-category-${abstract.getID()}`;
    const [category, setCategory] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onAddVehicleCategory = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The ${abstract.object.name} vehicle category has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => {
                    setLayerState('close');
                    if(isNewTarget && typeof(onAddCategory) === 'function') {
                        onAddCategory(category);
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this vehicle category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setCategory(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!category) {
            return [];
        }
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a vehicle category is shown to a customer during the booking process to identify the vehicle that they are choosing.',
                value: category.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'information',
                title: 'Description',
                description: 'The description for a vehicle category is shown to a customer during the booking process to identify the features and benefits of the vehicle that they are choosing.',
                value: category.information,
                component: 'textview',
                onChange: text => onUpdateTarget({ information: text })
            },{
                key: 'make',
                title: 'Make/Brand',
                description: 'The make or brand for a vehicle category is shown to a customer during the booking process to identify the brand of the vehicle that they are choosing. Examples would be Tesla, Rivian, Nikola, Ford, etc.',
                value: category.make,
                component: 'textfield',
                onChange: text => onUpdateTarget({ make: text })
            },{
                key: 'model',
                title: 'Model',
                description: 'The model for a vehicle category is shown to a customer during the booking process to identify the specific type of the vehicle that they are choosing. Examples would be Model 3, Model S, Prius, etc.',
                value: category.model,
                component: 'textfield',
                onChange: text => onUpdateTarget({ model: text })
            },{
                key: 'is_default',
                title: 'Default for Booking',
                description: 'Setting this category as the default category will automatically select this category when customers are booking a ride. Customers have the option to choose another category if more than one category is offered. Only one category can be set as the default category.',
                value: category.is_default,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ is_default: val })
            }]
        },{
            key: 'operating_costs',
            title: 'Operating Costs',
            items: [{
                key: 'base_rate',
                title: 'Base Rate',
                description: 'The base rate for a vehicle category is should be set to the minimum amount of funds required for using this vehicle. The base rate will be used for payment processing if the cost of the order or reservation does not exceed the base rate.',
                value: category.base_rate || '0.00',
                component: 'textfield',
                onChange: text => onUpdateTarget({ base_rate: text }),
                props: {
                    prepend: '$',
                    format: 'number'
                }
            },{
                key: 'rate',
                title: 'Per Mile Rate',
                description: 'The mileage rate for a vehicle category is used when processing an order or reservation that contains a mileage based Service.',
                value: category.rate || '0.00',
                component: 'textfield',
                onChange: text => onUpdateTarget({ rate: text }),
                props: {
                    prepend: '$',
                    format: 'number'
                }
            },{
                key: 'duration_rate',
                title: 'Per Minute Rate',
                description: 'The per minute rate for a vehicle category is used when processing a order or reservation that contains a by-the-minute based service.',
                value: category.duration_rate || '0.00',
                component: 'textfield',
                onChange: text => onUpdateTarget({ duration_rate: text }),
                props: {
                    prepend: '$',
                    format: 'number'
                }
            },{
                key: 'hourly_rate',
                title: 'Hourly Rate',
                description: 'The hourly rate for a vehicle category is used when processing an order or reservation that contains an hourly based service.',
                value: category.hourly_rate || '0.00',
                component: 'textfield',
                onChange: text => onUpdateTarget({ hourly_rate: text }),
                props: {
                    prepend: '$',
                    format: 'number'
                }
            }]
        }];

        // add information for drive experiences if applicable
        if(utils.client.flavors.enabled('vehicles.drive_experiences') === true) {
            let exp = category.drive_experiences || {};
            items.push({
                key: 'drive_experiences',
                title: 'Drive Experiences',
                items: [{
                    component: 'bool_list',
                    description: 'Enabling drive experiences will allows new and existing customers to signup for a chance to test drive this vehicle type.',
                    key: 'enabled',
                    onChange: val => {
                        onUpdateTarget({
                            drive_experiences: {
                                ...exp,
                                enabled: val
                            }
                        });
                    },
                    required: false,
                    title: 'Enabled',
                    value: exp.enabled
                },{
                    component: 'address_lookup',
                    description: 'The location for a drive experience should represent where a customer will go when participating in a drive experience. This location is also used when arranging customer transportation to or from the drive experience.',
                    key: 'location',
                    onChange: result => {
                        onUpdateTarget({
                            drive_experiences: {
                                ...exp,
                                location: result && {
                                    address: Utils.formatAddress(result.address),
                                    name: result.address.name,
                                    lat: result.location.latitude,
                                    long: result.location.longitude
                                }
                            }
                        });
                    },
                    required: exp.enabled === true,
                    title: 'Location',
                    value: exp.location && exp.location.address,
                    visible: exp.enabled === true
                }]
            });
        }

        return items;
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setCategory(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={abstract.getID() ? `Editing "${abstract.getTitle()}"` : 'New Vehicle Category'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setCategory(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onAddVehicleCategory
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const StreetLinkAddressLookup = ({ onClose }, { options, index, utils }) => {

    const layerID = 'street-link-address-lookup';
    const [layerState, setLayerState] = useState(null);
    const [location, setLocation] = useState(null);

    const onSelectLocation = () => {
        setLayerState('close');
        if(typeof(onClose) === 'function') {
            onClose(location);
        }
    }

    return (
        <Layer
        id={layerID}
        title={'Explore Other Locations'}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: 'grey',
            onClick: () => setLayerState('close')
        },{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onSelectLocation
        }]}>
            <AddressLookupField
            utils={utils}
            autoCorrect={false}
            spellCheck={false}
            placeholder={'Search for a place or address...'}
            onChange={location => setLocation(location)} />
        </Layer>
    )
}

export const StreetLinkIncidentDetails = ({ incident }, { options, index, utils }) => {

    const layerID = `street-link-incident-details-${incident.id}`;
    const [annotations, setAnnotations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [overlays, setOverlays] = useState(null);

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: incident.id
            },{
                key: 'name',
                title: 'Name',
                value: incident.name
            },{
                key: 'description',
                title: 'Description',
                value: incident.description
            },{
                key: 'status',
                title: 'Status',
                value: Utils.ucFirst(incident.status)
            }]
        },{
            key: 'life_cycle',
            title: 'Estimated Life Cycle',
            items: [{
                key: 'distance',
                title: 'Distance',
                value: incident.distance
            },{
                key: 'start_date',
                title: 'Start Date',
                value: Utils.formatDate(incident.start_date)
            },{
                key: 'end_date',
                title: 'End Date',
                value: Utils.formatDate(incident.end_date)
            }]
        },{
            key: 'ext_details',
            title: 'About this Incident',
            items: [{
                key: 'road_closed',
                title: 'Road Closed',
                value: incident.details.road_closed ? 'Yes' : 'No'
            },{
                key: 'delay_time',
                title: 'Delay Time',
                value: incident.congestion && incident.congestion.delay_time ? Utils.parseDuration(incident.congestion.delay_time) : null
            },{
                key: 'average_speed',
                title: 'Average Speed',
                value: incident.congestion && incident.congestion.average_speed ? `${incident.congestion.average_speed} mph` : null
            },{
                key: 'response_vehicles',
                title: 'Response Vehicles',
                value: incident.details.response_vehicles ? 'Present' : 'Not Present'
            }]
        }];
    }

    const setupTarget = () => {
        if(incident.type === 'CONST' && incident.location.coordinates && incident.location.coordinates.length > 1) {
            setOverlays([{
                key: incident.id,
                color: Appearance.colors.orange,
                coordinates: incident.location.coordinates
            }]);
        }
        setAnnotations([{
            key: `incident-${incident.id}`,
            title: incident.name,
            subTitle: incident.description,
            location: {
                latitude: incident.location.start.latitude,
                longitude: incident.location.start.longitude
            },
            icon: {
                ...Here.trafficAnnotation(incident.type),
                style: {
                    boxShadow: null,
                    backgroundColor: Appearance.colors.transparent
                },
                imageStyle: {
                    objectFit: 'contain'
                }
            }
        },{
            key: `incident-${incident.id}`,
            title: incident.name,
            subTitle: incident.description,
            location: {
                latitude: incident.location.end.latitude,
                longitude: incident.location.end.longitude
            },
            icon: {
                ...Here.trafficAnnotation(incident.type),
                style: {
                    boxShadow: null,
                    backgroundColor: Appearance.colors.transparent
                },
                imageStyle: {
                    objectFit: 'contain'
                }
            }
        }]);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'Incident Report'}
        index={index}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            <LayerMap
            utils={utils}
            overlays={overlays}
            annotations={annotations}
            viewportTarget={'annotations'}/>

            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const VehicleApplicationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `vo-app-details-${abstract.getID()}`;
    const [application, setApplication] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [loading, setLoading] = useState(true);
    const [note, setNote] = useState(null);

    let tempContent = undefined;
    const [dropDown, setDropDown] = useState(null);
    const dropDownMethods = {
        visible: true,
        onClose: () => setDropDown(null)
    }

    const onDeleteApplication = () => {
        utils.alert.show({
            title: `Delete Application`,
            message: `Are you sure that you want to delete this application? Applications should only be deleted when they were submitted by mistake. Please approve or reject the application if possible.`,
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteApplicationConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteApplicationConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/vehicles/', {
                type: 'delete_onboarding_application',
                application_id: application.id
            });

            setLoading(false);
            utils.content.fetch('voApps');
            utils.alert.show({
                title: 'All Done!',
                message: `This application has been deleted`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'approve',
                title: 'Approve',
                style: 'default'
            },{
                key: 'reject',
                title: 'Reject',
                style: 'destructive'
            },{
                key: 'info',
                title: 'Request a Follow Up',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'approve' || key === 'reject') {
                onSetStatus(key === 'approve');
                return;
            }
            if(key === 'info') {
                onRequestFollowUp();
                return;
            }
        })
    }

    const onRequestFollowUp = () => {
        utils.alert.show({
            title: `Request a Follow Up`,
            message: `Are you sure that you want to request a follow up for this application? This will notify ${application.company ? application.company.name : application.customer.full_name} that you will be reaching out to speak with them about their application.`,
            buttons: [{
                key: 'confirm',
                title: 'Send Request',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Send Request',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRequestFollowUpConfirm();
                    return;
                }
            }
        })
    }

    const onRequestFollowUpConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { response } = await Request.post(utils, '/vehicles/', {
                type: 'set_application_status',
                id: application.id,
                status: Vehicle.Application.status.infoNeeded
            });

            abstract.object.status = Vehicle.Application.formatStatus(response.status);
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The follow up request for this application has been sent. Would you like to start your email follow up with ${application.company ? application.company.name : application.customer.full_name}?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        let body = `Hello ${application.company ? application.company.name : application.customer.full_name}, \nWe had a some questions about your vehicle onboarding application for the ${application.application.year} ${application.application.make} ${application.application.model} ending in ${application.application.vin.substring(application.application.vin.length -4)}`;

                        window.open(`mailto:${application.company ? application.company.email_address : application.customer.email_address}?subject=${encodeURIComponent('Your Vehicle Application')}&body=${encodeURIComponent(body)}`)
                    }
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending the follow up request for this application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetStatus = approved => {
        utils.alert.show({
            title: `${approved ? 'Approve' : 'Reject'} Application`,
            message: `Are you sure that you want to ${approved ? 'approve' : 'reject'} this application? We will update ${application.company ? application.company.name : application.customer.full_name} with your decision`,
            buttons: [{
                key: 'confirm',
                title: approved ? 'Approve' : 'Reject',
                style: approved ? 'default' : 'destructive'
            },{
                key: 'cancel',
                title: approved ? 'Do Not Approve' : 'Do Not Reject',
                style: approved ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm(approved);
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async approved => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { response } = await Request.post(utils, '/vehicles/', {
                type: 'set_application_status',
                id: application.id,
                status: approved ? Vehicle.Application.status.approved : Vehicle.Application.status.rejected
            });

            abstract.object.status = Vehicle.Application.formatStatus(response.status);
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This application has been ${approved ? 'approved' : 'rejected'}. ${approved ? `Would you like to add ${application.company ? application.company.name : application.customer.full_name}'s vehicle to the system?` : ''}`,
                buttons: approved ? [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Later',
                    style: 'cancel'
                }] : null,
                onClick: key => {
                    if(key === 'confirm') {

                        // create new vehicle object
                        let app = application.application;
                        let vehicle = Vehicle.new();
                        vehicle.vin = app.vin;
                        vehicle.customer = application.customer;
                        vehicle.company = application.company;
                        vehicle.year = app.year;
                        vehicle.mileage = app.mileage;
                        vehicle.plate = app.plate;
                        vehicle.color = app.color && Utils.ucFirst(app.color.title);

                        // open vehicle editing layer
                        utils.layer.open({
                            id: `edit-vehicle-${abstract.getID()}`,
                            abstract: Abstract.create({
                                object: vehicle,
                                type: 'vehicles'
                            }),
                            Component: AddEditVehicle.bind(this, {
                                isNewTarget: true
                            })
                        });
                    }
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${approved ? 'approving' : 'rejecting'} this application. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchLineItems = async () => {
        try {
            let { line_items } = await Request.get(utils, '/vehicles/', {
                type: 'onboarding_line_items',
                application_id: abstract ? abstract.getID() : null
            });

            setLoading(false);
            setLineItems(line_items ? line_items.sections : null);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the onboarding application options. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(application.seeds && application.seeds.notes) {
            for(var i in application.seeds.notes) {
                if(!application.seeds.notes[i].deleted) {
                    setNote(application.seeds.notes[i]);
                    break;
                }
            }
        }
    }, [application]);

    useEffect(() => {
        fetchLineItems();
        utils.content.subscribe(layerID, 'voApps', {
            onUpdate: next => {
                setApplication(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'delete',
            text: 'Delete',
            color: 'danger',
            onClick: onDeleteApplication
        },{
            key: 'options',
            text: 'Options',
            color: 'primary',
            onClick: onOptionsClick
        }]}>
            {application.company && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: application.company.name,
                        subTitle: application.company.address,
                        icon: {
                            path: application.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, application.company)
                    })}
                </LayerItem>
            )}

            {application.customer && (
                <LayerItem title={application.company ? 'Submitted By' : 'Customer'}>
                    {Views.entry({
                        title: application.customer.full_name,
                        subTitle: application.customer.phone_number,
                        icon: {
                            path: application.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, application.customer)
                    })}
                </LayerItem>
            )}

            {lineItems && lineItems.map((section, index) => {
                if(section.key === 'photos') {
                    return (
                        <LayerItem
                        key={index}
                        title={section.title}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 10,
                                width: '100%'
                            }}>
                                {section.items.map((item, index) => {
                                    return (
                                        <div
                                        key={index}
                                        className={'text-button'}
                                        onClick={() => window.open(item.url)}
                                        style={{
                                            display: 'inline-block',
                                            borderRadius: 5,
                                            backgroundColor: Appearance.colors.softBorder(),
                                            border: `1px solid ${Appearance.colors.softBorder()}`,
                                            marginRight: 8,
                                            height: 40,
                                            overflow: 'hidden'
                                        }}>
                                            <img
                                            src={item.url || 'images/image-not-found.png'}
                                            style={{
                                                width: 'auto',
                                                height: '100%'
                                            }}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </LayerItem>
                    )
                }
                return (
                    <LayerItem
                    key={index}
                    title={section.title}>
                        {section.items.map((item, index, items) => {
                            return (
                                Views.row({
                                    key: index,
                                    label: item.title,
                                    value: item.formatted,
                                    bottomBorder: index !== items.length - 1
                                })
                            )
                        })}
                    </LayerItem>
                )
            })}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const VehicleCategoryDetails = ({ abstract, index, options, utils }) => {

    const layerID = `vehicle-category-details-${abstract.getID()}`;
    const limit = 5;

    const [category, setCategory] = useState(abstract.object);
    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);

    const onDeleteCategory = () => {
        if(category.is_default) {
            utils.alert.show({
                title: 'Default Vehicle Category',
                message: 'This vehicle category is currently marked as the default booking category and can not be deleted. Please set another vehicle category as the default category before deleting this category.'
            });
            return;
        }
        utils.alert.show({
            title: 'Delete Category',
            message: 'Are you sure that you want to delete this category? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCategoryConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteCategoryConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/vehicle/', {
                type: 'delete_option',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('vehicleCategories');
            utils.alert.show({
                title: 'All Done!',
                message: `This vehicle category has been deleted`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this vehicle category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-vehicle-category-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditVehicleCategory.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'delete',
                title: 'Delete Category',
                style: 'destructive'
            },{
                key: 'status',
                title: `${category.active ? 'Deactivate' : 'Activate'} Category`,
                style: category.active ? 'destructive' : 'default',
            }]
        }, key => {
            if(key === 'delete') {
                onDeleteCategory();
                return;
            }
            if(key === 'status') {
                onSetStatus();
                return;
            }
        });
    }

    const onQRCodeClick = url => {
        window.open(url);
    }

    const onSetStatus = () => {
        if(category.is_default) {
            utils.alert.show({
                title: 'Default Vehicle Category',
                message: 'This vehicle category is currently marked as the default booking category and can not be deactivated. Please set another vehicle category as the default category before deactivating this category.'
            });
            return;
        }
        utils.alert.show({
            title: `Set as ${category.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set this vehicle category as ${category.active ? 'inactive' : 'active'}? This will ${category.active ? 'no longer allow' : 'allow'} customers to book a vehicle in this category.`,
            buttons: [{
                key: 'confirm',
                title: category.active ? 'Deactivate' : 'Activate',
                style: category.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: category.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: category.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !category.active;
            await Request.post(utils, '/vehicle/', {
                type: 'set_category_status',
                id: abstract.getID(),
                active: next
            });

            setLoading(false);
            abstract.object.active = next
            utils.content.update(abstract);

            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.getTitle()} has been set as ${next ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this vehicle category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onWebLinkClick = url => {

        // copy url to clipboard
        navigator.clipboard.writeText(url);

        // show notification confirmation
        let notification = Notification.create({ 
            message: 'The web link for this drive experience has been copied to your clipboard',
            title: 'Web Link Copied'
        });
        utils.notification.show(notification);
    }

    const getFields = () => {
        if(!category) {
            return [];
        }
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: category.id
            },{
                key: 'name',
                title: 'Name',
                value: category.name
            },{
                key: 'make',
                title: 'Make',
                value: category.make
            },{
                key: 'model',
                title: 'Model',
                value: category.model
            },{
                key: 'is_default',
                title: 'Default for Booking',
                value: category.is_default ? 'Yes' : 'No'
            }]
        },{
            key: 'operating_costs',
            title: 'Operating Costs',
            items: [{
                key: 'base_rate',
                title: 'Base Rate',
                value: Utils.toCurrency(category.base_rate)
            },{
                key: 'rate',
                title: 'Per Mile Rate',
                value: Utils.toCurrency(category.rate)
            },{
                key: 'duration_rate',
                title: 'Per Minute Rate',
                value: Utils.toCurrency(category.duration_rate)
            },{
                key: 'hourly_rate',
                title: 'Hourly Rate',
                value: Utils.toCurrency(category.hourly_rate)
            }]
        }];

        // add information for drive experiences if applicable
        if(category.drive_experiences) {
            items.push({
                key: 'drive_experiences',
                title: 'Drive Experiences',
                items: [{
                    key: 'enabled',
                    title: 'Enabled',
                    value: category.drive_experiences.enabled ? 'Yes' : 'No'
                },{
                    key: 'location',
                    title: 'Location',
                    value: category.drive_experiences.location && category.drive_experiences.location.address
                },{
                    key: 'qr_codes.sign_up',
                    title: 'QR Code',
                    ...category.drive_experiences.urls && {
                        onClick: category.drive_experiences.qr_codes.sign_up ? onQRCodeClick.bind(this, category.drive_experiences.qr_codes.sign_up) : null,
                        value: category.drive_experiences.qr_codes.sign_up ? 'Click to View' : null
                    }
                },{
                    key: 'urls.sign_up',
                    title: 'Web Link',
                    ...category.drive_experiences.urls && {
                        onClick: category.drive_experiences.urls.sign_up ? onWebLinkClick.bind(this, category.drive_experiences.urls.sign_up) : null,
                        value: category.drive_experiences.urls.sign_up ? 'Click to Copy' : null
                    }
                }]
            });
        }

        return items;
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        utils.content.subscribe(layerID, [ 'vehicleCategories' ], {
            onUpdate: next => {
                setCategory(next.compare(abstract, fetchSystemEvents));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    })

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>

            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 25
            }}>
                {Views.entry({
                    title: category.name,
                    subTitle: `${Utils.toCurrency(category.rate)} per mile`,
                    borderBottom: false,
                    icon: {
                        path: `/images/vehicle-icon-${window.theme}.png`,
                        ...Appearance.icons.padded()
                    }
                })}
            </div>

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getSystemEvents()}
        </Layer>
    )
}

export const VehicleDeepLink = ({ abstract, index, options, utils }) => {

    const layerID = `vehicle-deep-link-${abstract.getID()}`;
    const [charge, setCharge] = useState(null);
    const [climate, setClimate] = useState(null);
    const [details, setDetails] = useState(null);
    const [destinationChargers, setDestinationChargers] = useState([]);
    const [dropDown, setDropDown] = useState(null);
    const [loading, setLoading] = useLoading();
    const [superChargers, setSuperChargers] = useState([]);
    const [vehicle, setVehicles] = useState(abstract.object);

    const onNavigationClick = () => {
        let location = null;
        setDropDown({
            title: 'Navigation',
            message: 'You can search for a location to send directly to the display for this vehicle. This will allow the driver to view driving directions for the location',
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => {
                    if(location) {
                        onSetNavigationAddress(location);
                    }
                }
            }],
            content: (
                <AddressLookupField
                utils={utils}
                autoCorrect={false}
                spellCheck={false}
                placeholder={'Search by name or address...'}
                onChange={place => {
                    location = place;
                }}/>
            )
        })
    }

    const onSetDoors = () => {
        utils.alert.show({
            title: details.locked ? 'Unlock Doors' : 'Lock Doors',
            message: `Are you sure that you want to remotely ${details.locked ? 'unlock' : 'lock'} the doors for this vehicle?`,
            buttons: [{
                key: 'confirm',
                title: details.locked ? 'Unlock' : 'Lock',
                style: details.locked ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: !details.locked ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetDoorsConfirm();
                    return;
                }
            }
        })
    }

    const onSetDoorsConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !details.locked;
            let { response } = await Request.post(utils, '/vehicle/', {
                type: 'deep_link_set_doors',
                locked: next,
                vehicle_id: vehicle.id
            });
            if(!response) {
                throw new Error('We did not receive a response from the vehicle');
            }
            setLoading(false);
            setDetails(details => update(details, {
                locked: {
                    $set: next
                }
            }))
            utils.alert.show({
                title: 'All Done!',
                message: `The doors have been ${next ? 'locked' : 'unlocked'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting the door lock status. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetNavigationAddress = location => {
        utils.alert.show({
            title: location.name,
            message: `${location.address ? `You have selected "${location.address}" as the address to send to this vehicle.` : `You have selected the ${location.name} ${location.type === 'supercharger' ? 'Supercharger' : 'Desination Charger'} to send to the this vehicle.`} Are you ready to send it?`,
            buttons: [{
                key: 'send',
                title: `Send ${location.address ? 'Address' : 'Charger'}`,
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'send') {
                    onSetNavigationAddressConfirm(location);
                    return;
                }
            }
        })
    }

    const onSetNavigationAddressConfirm = async location => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { response } = await Request.post(utils, '/vehicle/', {
                type: 'deep_link_send_address',
                address: location.address || `https://maps.google.com/maps?daddr=${location.coordinate.latitude},${location.coordinate.longitude}`,
                vehicle_id: vehicle.id
            });
            if(!response) {
                throw new Error('We did not receive a response from the vehicle');
            }
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The address has been sent to the vehicle'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue sending the address. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetSentryMode = () => {
        utils.alert.show({
            title: details.sentry_mode ? 'Disable Sentry Mode' : 'Enable Sentry Mode',
            message: `Are you sure that you want to remotely ${details.sentry_mode ? 'disable' : 'enable'} Sentry Mode for this vehicle?`,
            buttons: [{
                key: 'confirm',
                title: details.sentry_mode ? 'Disable' : 'Enable',
                style: details.sentry_mode ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: !details.sentry_mode ? 'destructive' : 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetSentryModeConfirm();
                    return;
                }
            }
        })
    }

    const onSetSentryModeConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !details.sentry_mode;
            let { response } = await Request.post(utils, '/vehicle/', {
                type: 'deep_link_set_sentry_mode',
                enabled: !details.sentry_mode,
                vehicle_id: vehicle.id
            });
            if(!response) {
                throw new Error('We did not receive a response from the vehicle');
            }
            setLoading(false);
            setDetails(details => update(details, {
                sentry_mode: {
                    $set: next
                }
            }));
            utils.alert.show({
                title: 'All Done!',
                message: `Sentry mode has been ${next ? 'enabled' : 'disabled'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting sentry mode. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetTemp = () => {
        utils.alert.show({
            title: 'Set Temperature',
            message: 'What tempature would you like to set for this vehicle? Tempeature changes are made in Fahrenheit but do not require the degrees symbol or the "F" symbol',
            textFields: [{
                key: 'temp',
                placeholder: '75°F'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: response => {
                if(response && response.temp) {
                    let temp = parseInt(response.temp.replace(/\D/g,''));
                    if(isNaN(temp) || !temp) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: 'Please enter a valid temperature between 50°F and 80°F'
                        })
                        return;
                    }
                    onSetTemperatureConfirm();
                    return;
                }
            }
        })
    }

    const onSetTemperatureConfirm = async temp => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { response } = await Request.post(utils, '/vehicle/', {
                type: 'deep_link_set_temperature',
                temperature: temp,
                vehicle_id: vehicle.id
            });
            if(!response) {
                throw new Error('We did not receive a response from the vehicle');
            }
            setLoading(false);
            setClimate(climate => update(climate, {
                driver_temp: {
                    $set: temp
                },
                passenger_temp: {
                    $set: temp
                }
            }));
            utils.alert.show({
                title: 'All Done!',
                message: `The temperature has been set to ${temp}°F`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting the temperature. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSuperChargerClick = charger => {
        utils.sheet.show({
            items: [{
                key: 'send',
                title: 'Send to Vehicle',
                style: 'default'
            }]
        }, key => {
            if(key === 'send') {
                onSetNavigationAddress({
                    name: charger.name,
                    type: 'supercharger',
                    coordinate: charger.location
                });
                return;
            }
        })
    }

    const getCharge = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(charge === false) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    padding: '8px 12px 8px 12px'
                }}>{'Not available at this time'}</span>
            )
        }
        let fields = [{
            key: 'battery_level',
            title: 'Battery Level',
            value: charge.battery_level ? (charge.battery_level + '%') : null
        },{
            key: 'charge_limit',
            title: 'Battery Charge Limit',
            value: charge.charge_limit ? (charge.charge_limit + '%') : null
        },{
            key: 'battery_range',
            title: 'Battery Range',
            value: charge.battery_range ? Utils.distanceConversion(charge.battery_range) : null
        },{
            key: 'time_to_full_charge',
            title: 'Time To Full Charge',
            value: charge.time_to_full_charge ? `${charge.time_to_full_charge} hours` : null
        },{
            key: 'charging_state',
            title: 'Charge State',
            value: details.charging_state
        }]
        return fields.map((field, index) => {
            return (
                Views.row({
                    key: index,
                    label: field.title,
                    value: field.value,
                    bottomBorder: index !== fields.length - 1
                })
            )
        });
    }

    const getClimate = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(climate === false) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    padding: '8px 12px 8px 12px'
                }}>{'Not available at this time'}</span>
            )
        }
        let fields = [{
            key: 'inside_temp',
            title: 'Interior Temperature',
            value: climate.inside_temp ? `${climate.inside_temp}°F` : null
        },{
            key: 'driver_temp',
            title: 'Driver Selection',
            value: climate.driver_temp ? `${climate.driver_temp}°F` : null
        },{
            key: 'passenger_temp',
            title: 'Passenger Selection',
            value: climate.passenger_temp ? (climate.passenger_temp + '°F') : null
        }]
        return fields.map((field, index) => {
            return (
                Views.row({
                    key: index,
                    label: field.title,
                    value: field.value,
                    bottomBorder: index !== fields.length - 1
                })
            )
        });
    }

    const getDestinationChargers = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(destinationChargers === false) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    padding: '8px 12px 8px 12px'
                }}>{'Not available at this time'}</span>
            )
        }
        return destinationChargers.map((charger, index, chargers) => {
            return Views.entry({
                key: index,
                title: charger.name,
                subTitle: Utils.distanceConversion(charger.distance),
                icon: {
                    path: 'images/charger-icon-red.png',
                    style: Appearance.icons.standard()
                },
                bottomBorder: index !== chargers.length - 1,
                onClick: onSuperChargerClick.bind(this, charger)
            })
        })
    }

    const getDetails = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(details === false) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    padding: '8px 12px 8px 12px'
                }}>{'Not available at this time'}</span>
            )
        }
        let fields = [{
            key: 'name',
            title: 'Name',
            value: details.name
        },{
            key: 'odometer',
            title: 'Odometer',
            value: details.odometer ? Utils.distanceConversion(details.odometer) : null
        },{
            key: 'locked',
            title: 'Doors Locked',
            value: details.locked ? 'Yes' : 'No'
        },{
            key: 'is_user_present',
            title: 'Driver Near Vehicle',
            value: details.is_user_present ? 'Yes' : 'No'
        },{
            key: 'sentry_mode',
            title: 'Sentry Mode',
            value: details.sentry_mode ? 'Active' : 'Not Active'
        },{
            key: 'software_version',
            title: 'Software Version',
            value: details.software_version
        }]
        return fields.map((field, index) => {
            return (
                Views.row({
                    key: index,
                    label: field.title,
                    value: field.value,
                    bottomBorder: index !== fields.length - 1
                })
            )
        });
    }

    const getHeatedSeats = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(climate === false) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    padding: '8px 12px 8px 12px'
                }}>{'Not available at this time'}</span>
            )
        }
        let items = [{
            title: 'Driver',
            value: climate.seat_heaters.left
        },{
            title: 'Passenger',
            value: climate.seat_heaters.right
        },{
            title: 'Rear Left',
            value: climate.seat_heaters.rear_left
        },{
            title: 'Rear Center',
            value: climate.seat_heaters.rear_center
        },{
            title: 'Rear Right',
            value: climate.seat_heaters.rear_right
        }];
        return items.map((item, index) => {
            return (
                Views.row({
                    key: index,
                    label: item.title,
                    bottomBorder: index !== items.length - 1,
                    value: (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        }}>
                            {[1,2,3].map((value, index) => {
                                return (
                                    <div
                                    key={index}
                                    className={'text-button'}
                                    style={{
                                        width: 6,
                                        height: 15,
                                        borderRadius: 3,
                                        marginLeft: 4,
                                        backgroundColor: value <= item.value ? Appearance.colors.red : Appearance.colors.softBorder()
                                    }} />
                                )
                            })}
                        </div>
                    )
                })
            )
        })
    }

    const getRightContent = () => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
            }}>
                <img
                src={details && details.sentry_mode === true ? 'images/tesla-sentry-mode-enabled-icon.png' : 'images/tesla-sentry-mode-disabled-icon.png'}
                className={'text-button'}
                onClick={onSetSentryMode}
                style={{
                    ...Appearance.icons.small(),
                    marginRight: 4
                }} />
                <img
                src={'images/vehicle-navigation-icon.png'}
                className={'text-button'}
                style={{
                    ...Appearance.icons.small(),
                    marginRight: 4
                }}
                onClick={onNavigationClick} />
                <img
                src={'images/vehicle-climate-icon.png'}
                className={'text-button'}
                onClick={onSetTemp}
                style={{
                    ...Appearance.icons.small(),
                    marginRight: 4
                }}/>
                <img
                src={details && details.locked !== true ? 'images/vehicle-unlock-icon.png' : 'images/vehicle-lock-icon.png'}
                style={Appearance.icons.small()}
                className={'text-button'}
                onClick={onSetDoors} />
            </div>
        )
    }

    const getSuperChargers = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(superChargers === false) {
            return (
                <span style={{
                    ...Appearance.textStyles.key(),
                    padding: '8px 12px 8px 12px'
                }}>{'Not available at this time'}</span>
            )
        }
        return superChargers.map((charger, index, chargers) => {
            return Views.entry({
                key: index,
                title: charger.name,
                subTitle: `${charger.stalls.available}/${charger.stalls.total} available`,
                badge: {
                    text: Utils.distanceConversion(charger.distance),
                    color: Appearance.colors.grey()
                },
                icon: {
                    path: 'images/supercharger-icon-red.png',
                    style: Appearance.icons.standard()
                },
                bottomBorder: index !== chargers.length - 1,
                onClick: onSuperChargerClick.bind(this, charger)
            })
        });
    }

    const fetchChargingLocations = async () => {
        try {
            let { response } = await Request.get(utils, '/vehicle/', {
                type: 'deep_link_nearby_charging',
                vehicle_id: vehicle.id
            });

            setLoading(false);
            setDestinationChargers(response.destination_charging.map(charger => ({
                name: charger.name,
                distance: charger.distance_miles,
                location: {
                    latitude: charger.location.lat,
                    longitude: charger.location.longitude
                }
            })));
            setSuperChargers(response.superchargers.map(charger => ({
                name: charger.name,
                distance: charger.distance_miles,
                stalls: {
                    total: charger.total_stalls,
                    available: charger.available_stalls
                },
                closed: charger.site_closed,
                location: {
                    latitude: charger.location.lat,
                    longitude: charger.location.longitude
                }
            })));

        } catch(e) {
            setLoading(false);
            setSuperChargers(false);
            setDestinationChargers(false);
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue retrieving charging locations'
            });
        }
    }

    const fetchDeepLinkOverview = async () => {
        try {
            let { response } = await Request.get(utils, '/vehicle/', {
                type: 'deep_link_overview',
                vehicle_id: vehicle.id
            });

            setLoading(false);
            setDetails(response.vehicle);
            setClimate(response.climate_state);
            setCharge(response.charge_state);

        } catch(e) {
            setLoading(false);
            setDetails(false);
            setClimate(false);
            setCharge(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving battery and charging information. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchChargingLocations();
        fetchDeepLinkOverview();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Deep Link for ${abstract.getTitle()}`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 25
            }}>
                {Views.entry({
                    title: vehicle.category.name,
                    subTitle: vehicle.vin,
                    icon: {
                        path: `/images/vehicle-icon-${window.theme}.png`,
                        ...Appearance.icons.padded()
                    },
                    bottomBorder: false,
                    rightContent: getRightContent(vehicle)
                })}
            </div>

            <LayerItem title={'About this Vehicle'}>
                {getDetails()}
            </LayerItem>

            <LayerItem title={'Charge Information'}>
                {getCharge()}
            </LayerItem>

            <LayerItem title={'Climate Information'}>
                {getClimate()}
            </LayerItem>

            <LayerItem title={'Heated Seats'}>
                {getHeatedSeats()}
            </LayerItem>

            <LayerItem title={'Nearby Superchargers'}>
                {getSuperChargers()}
            </LayerItem>

            <LayerItem title={'Nearby Destination Chargers'}>
                {getDestinationChargers()}
            </LayerItem>
        </Layer>
    )
}

export const VehicleDetails = ({ abstract, index, options, utils }) => {

    const layerID = `vehicle-details-${abstract.getID()}`;
    const limit = 5;

    const [annotations, setAnnotations] = useState(null);
    const [driver, setDriver] = useState(null);
    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [vehicle, setVehicle] = useState(abstract.object);

    const onDeleteVehicle = () => {
        utils.alert.show({
            title: 'Delete Vehicle',
            message: 'Are you sure that you want to delete this vehicle? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteVehicleConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteVehicleConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/vehicle/', {
                type: 'delete',
                vehicle_id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('vehicles');
            utils.alert.show({
                title: 'All Done!',
                message: `This vehicle has been deleted`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this vehicle. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDriverAdded = () => {
        fetchDriver();
    }

    const onDriverRemoved = () => {
        setDriver(null);
    }

    const onEditVehicle = () => {
        utils.layer.open({
            id: `edit-vehicle-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditVehicle.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit Vehicle',
                style: 'default'
            },{
                key: 'active',
                title: `Set as ${vehicle.active ? 'Inactive' : 'Active'}`,
                style: vehicle.active ? 'destructive' : 'default'
            },{
                key: 'remove_driver',
                title: 'Remove Driver',
                style: 'destructive',
                visible: driver ? true : false
            }]
        }, key => {
            if(key === 'active') {
                onSetActiveStatus();
                return;
            }
            if(key === 'edit') {
                onEditVehicle();
                return;
            }
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'remove_driver') {
                onRemoveDriver();
                return;
            }
        })
    }

    const onQRCodeClick = url => {
        window.open(url);
    }

    const onRemoveDriver = () => {
        utils.alert.show({
            title: 'Remove Driver',
            message: 'Are you sure that you want to remove the driver from this vehicle?',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveDriverConfirm();
                    return;
                }
            }
        })
    }

    const onRemoveDriverConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/vehicle/', {
                type: 'remove_driver',
                id: abstract.getID()
            });

            setLoading(false);
            setDriver(null);
            utils.alert.show({
                title: 'All Done!',
                message: 'The driver has been removed from this vehicle'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing the driver from this vehicle. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set this vehicle as ${abstract.object.active ? 'inactive' : 'active'}? ${abstract.object.active ? 'This will prevent drivers from using this vehicle in the future' : 'This will allow drivers to attach their account to this vehicle and use it for future orders and reservations'}.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Do Not Change',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/vehicle/', {
                type: 'set_active',
                vehicle_id: abstract.getID(),
                active: next
            });

            setLoading(false);
            abstract.object.active = next;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `This vehicle has been set as ${next ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this vehicle. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onShowDeepLink = () => {
        utils.alert.show({
            title: 'Verification',
            message: 'Please enter your account password to continue',
            textFields: [{
                key: 'password',
                placeholder: 'Password',
                secure: true
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: response => {
                if(response.password) {
                    if(response.password !== utils.user.get().password) {
                        setTimeout(() => {
                            utils.alert.show({
                                title: 'Oops!',
                                message: 'The password that you entered is incorrect'
                            })
                        }, 500)
                        return;
                    }
                    utils.layer.open({
                        id: `vehicle-deep-link-${vehicle.id}`,
                        abstract: Abstract.create({
                            type: 'vehicles',
                            object: vehicle
                        }),
                        Component: VehicleDeepLink
                    });
                }
            }
        })
    }

    const onShowLocations = () => {
        utils.layer.open({
            id: `vehicle-location-logs-${vehicle.id}`,
            abstract: Abstract.create({
                type: 'vehicles',
                object: vehicle
            }),
            Component: VehicleLocationLogs
        });
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onUpdateLocation = data => {
        try {
            let { location } = JSON.parse(data);
            setAnnotations([{
                title: 'Current Location',
                subTitle: `${parseFloat(location.lat).toFixed(6)} x ${parseFloat(location.long).toFixed(6)}`,
                icon: {
                    color: Appearance.colors.blue,
                    type: 'broadcast'
                },
                data: { key: `vehicle-${vehicle.id}` },
                location: {
                    latitude: location.lat,
                    longitude: location.long,
                    heading: location.heading
                }
            }])

        } catch(e) {
            console.error(e);
        }
    }

    const getFields = () => {
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: vehicle.id
            },{
                key: 'name',
                title: 'Name',
                value: vehicle.name
            },{
                key: 'make_and_model',
                title: 'Make and Model',
                value: vehicle.category.make && vehicle.category.model ? `${vehicle.category.make || ''} ${vehicle.category.model || ''}` : null
            },{
                key: 'color',
                title: 'Color',
                value: vehicle.color
            },{
                key: 'year',
                title: 'Year',
                value: vehicle.year
            },{
                key: 'plate',
                title: 'License Plate',
                value: vehicle.plate
            },{
                key: 'vin',
                title: 'VIN',
                value: vehicle.vin
            },{
                key: 'active',
                title: 'Status',
                value: vehicle.active ? 'Active' : 'Inactive'
            }]
        },{
            key: 'resources',
            title: 'Resources',
            items: [{
                key: 'locations',
                title: 'Locations',
                value: 'Click to View',
                onClick: onShowLocations
            },{
                key: 'deep_link',
                title: 'DeepLink',
                value: vehicle.deep_link ? 'Click to View' : 'Not Setup',
                onClick: vehicle.deep_link ? onShowDeepLink : null
            }]
        },{
            key: 'operating_costs',
            title: 'Operating Costs',
            items: [{
                key: 'base_rate',
                title: 'Base Rate',
                value: Utils.toCurrency(vehicle.category.base_rate)
            },{
                key: 'rate',
                title: 'Per Mile Rate',
                value: Utils.toCurrency(vehicle.category.rate)
            },{
                key: 'duration_rate',
                title: 'Per Minute Rate',
                value: Utils.toCurrency(vehicle.category.duration_rate)
            },{
                key: 'hourly_rate',
                title: 'Hourly Rate',
                value: Utils.toCurrency(vehicle.category.hourly_rate)
            }]
        }];

        // add information for drive experiences if applicable
        if(vehicle.category.drive_experiences) {
            items.push({
                key: 'drive_experiences',
                title: 'Drive Experiences',
                items: [{
                    key: 'enabled',
                    title: 'Enabled',
                    value: vehicle.category.drive_experiences.enabled ? 'Yes' : 'No'
                },{
                    key: 'location',
                    title: 'Location',
                    value: vehicle.category.drive_experiences.location && vehicle.category.drive_experiences.location.address
                },{
                    key: 'qr_codes.sign_up',
                    onClick: vehicle.category.drive_experiences.qr_codes.sign_up ? onQRCodeClick.bind(this, vehicle.category.drive_experiences.qr_codes.sign_up) : null,
                    title: 'QR Code',
                    value: vehicle.category.drive_experiences.qr_codes.sign_up ? 'Click to View' : null
                }]
            });
        }
        return items;
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const setupAnnotations = () => {
        if(!vehicle.hasLocation()) {
            return;
        }
        setAnnotations([{
            title: 'Current Location',
            subTitle: `${parseFloat(vehicle.location.latitude).toFixed(6)} x ${parseFloat(vehicle.location.longitude).toFixed(6)}`,
            location: vehicle.location,
            data: { key: `vehicle-${vehicle.id}` },
            icon: { 
                color: Appearance.colors.blue,
                type: 'broadcast' 
            }
        }]);
    }

    const setupSockets = async () => {
        try {
            let user = utils.user.get();
            await utils.sockets.emit('vehicles', 'location_updates', {
                company_id: user.company ? user.company.id : null,
                user_id: user.user_id,
                vehicle_id: abstract.getID(),
            });
            utils.sockets.on('vehicles', `on_add_driver_${abstract.getID()}`, onDriverAdded);
            utils.sockets.on('vehicles', `on_remove_driver_${abstract.getID()}`, onDriverRemoved);
            utils.sockets.on('vehicles', `on_location_update_${abstract.getID()}`, onUpdateLocation);

        } catch(e) {
            console.error(e.message);
        }
    }

    const leaveSockets = async () => {
        try {
            await utils.sockets.emit('vehicles', 'leave_location_updates', { vehicle_id: abstract.getID() });
            utils.sockets.off('vehicles', `on_add_driver_${abstract.getID()}`, onDriverAdded);
            utils.sockets.off('vehicles', `on_remove_driver_${abstract.getID()}`, onDriverRemoved);
            utils.sockets.off('vehicles', `on_location_update_${abstract.getID()}`, onUpdateLocation)
        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchDriver = async () => {
        try {
            let { driver } = await Request.get(utils, '/vehicle/', {
                type: 'driver_details',
                id: abstract.getID()
            });
            setDriver(driver ? User.create(driver) : null);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the driver for this vehicle. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(vehicle.seeds && vehicle.seeds.notes) {
            setNote(vehicle.seeds.notes.find(note => !note.deleted));
        }
    }, [vehicle]);

    useEffect(() => {
        fetchDriver();
        setupSockets();
        setupAnnotations();
        utils.content.subscribe(layerID, 'vehicles', {
            onUpdate: next => {
                setVehicle(next.compare(abstract, fetchSystemEvents));
            }
        });

        return () => {
            leaveSockets();
            utils.content.unsubscribe(layerID);
        };
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'delete',
            text: 'Delete',
            color: 'danger',
            onClick: onDeleteVehicle
        },{
            key: 'options',
            text: 'Options',
            color: 'primary',
            onClick: onOptionsClick
        }]}>

            <LayerMap
            utils={utils}
            showUserLocation={false}
            showsZoomControl={true}
            annotations={annotations} />

            {vehicle.customer && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: vehicle.customer.full_name,
                        subTitle: vehicle.customer.phone_number,
                        icon: {
                            path: vehicle.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, vehicle.customer)
                    })}
                </LayerItem>
            )}

            {vehicle.company && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: vehicle.company.name,
                        subTitle: vehicle.company.address,
                        icon: {
                            path: vehicle.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, vehicle.company)
                    })}
                </LayerItem>
            )}

            {driver && (
                <LayerItem title={'Driver'}>
                    {Views.entry({
                        title: driver.full_name,
                        subTitle: driver.phone_number,
                        icon: {
                            path: driver.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, driver)
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const VehicleLocationLogs = ({ abstract, index, options, utils }) => {

    const layerID = `vehicle-location-logs-${abstract.getID()}`;
    const [date, setDate] = useState(moment());
    const [dates, setDates] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [loading, setLoading] = useLoading();
    const [logs, setLogs] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onLogClick = async log => {
        utils.layer.open({
            id: `vehicle-location-log-details-${log.id}`,
            Component: VehicleLocationLogDetails.bind(this, { log: log })
        });
    }

    const onUpdateLogs = () => {
        setFilteredLogs(logs.filter(log => {
            if(searchText) {
                return log.driver.full_name.toLowerCase().includes(searchText.toLowerCase());
            }
            return moment(log.date).isSame(date, 'day');
        }));
    }

    const getLogContent = () => {
        if(loading === 'init') {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    padding: 12
                }}>
                    {Views.loader()}
                </div>
            )
        }
        return (
            <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                marginBottom: 8
            }}>
                <TextField
                icon={'search'}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={false}
                placeholder={'Search by Driver name...'}
                onChange={text => {
                    setSearchText(text);
                }}
                containerStyle={{
                    flexGrow: 1,
                    height: 34,
                    marginRight: 8
                }}/>
                <DatePickerField
                utils={utils}
                selected={date}
                highlightDates={dates}
                onDateChange={date => setDate(date)}
                style={{
                    width: 250
                }}
                fieldStyle={{
                    textAlign: 'center'
                }}/>
            </div>
            <div style={Appearance.styles.unstyledPanel()}>
                {filteredLogs.length === 0
                    ?
                    Views.entry({
                        title: 'Nothing to see here',
                        subTitle: `There are no logs available to view for ${date.format('MMMM Do, YYYY')}`,
                        bottomBorder: false,
                        icon: {
                            path: 'images/status-pending.png'
                        }
                    })
                    :
                    filteredLogs.map((log, index, logs) => {
                        return (
                            Views.entry({
                                key: index,
                                title: moment(log.date).format('MMMM Do [at] h:mma'),
                                subTitle: log.driver.full_name,
                                icon: {
                                    path: log.driver.avatar,
                                    onClick: Utils.users.details.bind(this, utils, log.driver)
                                },
                                bottomBorder: index !== logs.length - 1,
                                onClick: onLogClick.bind(this, log)
                            })
                        )
                    })
                }
            </div>
            </>
        )
    }

    const fetchLogs = async () => {
        try {
            let { dates, logs } = await Request.get(utils, '/vehicle/', {
                type: 'location_logs',
                vehicle_id: abstract.getID()
            });

            setLoading(false);
            setDates(dates);
            setLogs(logs.map(log => {
                log.driver = Driver.create(log.driver);
                return log;
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the location logs for this vehicle. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        onUpdateLogs();
    }, [logs, date, searchText]);

    useEffect(() => {
        fetchLogs();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Location Logs for ${abstract.getTitle()}`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading
        }}>
            <LayerItem title={'Vehicle'}>
                {Views.entry({
                    title: abstract.object.category.name,
                    subTitle: abstract.object.vin,
                    borderBottom: false,
                    icon: {
                        path: `/images/vehicle-icon-${window.theme}.png`,
                        ...Appearance.icons.padded()
                    }
                })}
            </LayerItem>
            <LayerItem
            title={'Locations'}
            shouldStyle={false}
            lastItem={true}>
                {getLogContent()}
            </LayerItem>
        </Layer>
    )
}

export const VehicleLocationLogDetails = ({ log }, { options, index, utils }) => {

    const layerID = `vehicle-location-log-details-${log.id}`;
    const [loading, setLoading] = useState(false);
    const [lineItems, setLineItems] = useState([]);
    const [overlays, setOverlays] = useState(null);

    const fetchLogDetails = async () => {
        try {
            setLoading(true);
            let response = await Request.get(utils, '/vehicle/', {
                type: 'location_log_details',
                id: log.id,
                driver_id: log.driver_id,
                vehicle_id: log.vehicle_id
            });

            setLoading(false);
            setLineItems(response.line_items);
            try {
                setOverlays([{
                    key: `vehicle-location-log`,
                    coordinates: Utils.decodePolyline(response.log.shape)
                }]);

            } catch(e) {
                throw new Error('Unable to decode locations from driver route');
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the details for this location log. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchLogDetails();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for Location Log #${log.id}`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading
        }}>
            <LayerMap
            utils={utils}
            contentType={'vehicle'}
            overlays={overlays}
            removeOldOverlays={true}
            overlayStyle={{
                strokeColor: Appearance.colors.blue,
                lineDash: [0, 0]
            }} />

            {lineItems.length > 0 && lineItems.map((section, index) => {
                return (
                    <LayerItem
                    key={index}
                    title={section.title}>
                        {section.items.map((item, index) => {
                            return (
                                Views.row({
                                    key: index,
                                    label: item.title,
                                    value: item.formatted,
                                    bottomBorder: index !== section.items.length - 1
                                })
                            )
                        })}
                    </LayerItem>
                )
            })}
        </Layer>
    )
}

// utils
export const fetchVehicleCategories = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/vehicles/', {
                type: 'list_categories',
                show_unavailable: true,
                ...props
            });
            if(props && props.download) {
                resolve(response);
                return;
            }
            resolve({
                paging: response.paging,
                categories: response.categories.map(category => Vehicle.Category.create(category))
            });
        } catch(e) {
            reject(e);
        }
    })
}
