import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

const TimePicker = ({ conformDate, fieldStyle, format, icon, increments = 5, maxHour, minHour, onChange, overrideAlerts, placeholder, requireInteraction, selected, style, utils }) => {

    const [time, setTime] = useState(null);
    const [timeOfDay, setTimeOfDay] = useState(null);
    const [times, setTimes] = useState([]);

    const setupTarget = () => {

        // prepare target dates
        let target = selected ? moment(selected) : moment();
        let next = conformDate === false ? target : Utils.conformDate(target, increments);

        // set time of day regardless of interaction flag
        let tod = next.format('a');
        setTimeOfDay(tod);

        // prevent date and time set if interaction is required
        if(!selected && requireInteraction) {
            return;
        }

        // set time values
        setTime(next.format('h:mm'));
    }

    const setRestrictions = () => {
        if(!minHour && !maxHour) {
            return;
        }

        let current = moment().startOf('day').add(minHour || 0, 'hours').unix();
        let min = moment().startOf('day').add(minHour || 0, 'hours').unix();
        let max = moment().startOf('day').add(maxHour || 23, 'hours').unix();
        setTimes(() => {
            let times = [];
            while(current >= min && current <= max) {
                let next = moment.unix(current);
                if(next.format('a') === timeOfDay) {
                    times.push(next.format('h:mm'));
                }
                current = next.add(increments, 'minutes').unix();
            }
            return times;
        })
    }

    const parseDateTime = () => {
        if(!time || !timeOfDay) {
            return;
        }
        let nextDate = moment(`${time}${timeOfDay}`, 'hh:mma');
        if(typeof(onChange) === 'function') {
            onChange(nextDate);
        }
    }

    useEffect(() => {
        parseDateTime();
    }, [time, timeOfDay]);

    useEffect(() => {
        setRestrictions();
    }, [timeOfDay]);

    useEffect(() => {
        let val = (60 / increments) * 12;
        let times = [...new Array(val)].map((_, index) => {
            return moment().startOf('day').add(Math.ceil(index * increments), 'minutes').format('h:mm');
        });
        setTimes(times);
    }, [increments]);

    useEffect(() => {
        setupTarget();
    }, [selected]);

    return (
        <div
        className={'row p-0'}
        style={{
            width: '100%',
            margin: 0,
            ...style
        }}>
            <div className={'col-12 col-md-6 p-0'}>
                <select
                className={`custom-select ${window.theme}`}
                value={time || 'Time'}
                onChange={e => {
                    let index = Utils.attributeForKey.select(e, 'id');
                    setTime(times[index]);
                }}
                style={{
                    marginRight: 8,
                    color: time ? Appearance.colors.text() : Appearance.colors.subText()
                }}>
                    <option disabled={true}>{'Time'}</option>
                    {times.map((time, index) => {
                        return <option key={index} id={index}>{time}</option>
                    })}
                </select>
            </div>
            <div className={'col-12 col-md-6 px-0 pt-1 pb-0 pt-md-0 pl-md-1'}>
                <select
                className={`custom-select ${window.theme}`}
                value={timeOfDay ? timeOfDay.toUpperCase() : 'AM'}
                onChange={e => {
                    let text = Utils.attributeForKey.select(e, 'id');
                    setTimeOfDay(text);
                }}>
                    <option disabled={true}>{'Time of Day'}</option>
                    {[ 'am', 'pm' ].map((text, index) => {
                        return <option key={index} id={text}>{text.toUpperCase()}</option>
                    })}
                </select>
            </div>
        </div>
    )
}
export default TimePicker;
