import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import HistoryEvent from 'classes/HistoryEvent.js';
import Note from 'classes/Note';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class SupportTicketClass {

    id = null;
    client_id = null;
    title = null;
    message = null;
    date = null;
    status = null;
    attachments = null;
    content = null;
    parameters = null;
    tags = null;
    submitted_by = null;
    seeds = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.client_id = props.parameters ? props.parameters.app_id : null;
        this.title = props.title;
        this.message = props.message;
        this.date = props.date ? moment(props.date) : null;
        this.status = formatStatus(props.status);
        this.attachments = props.attachments || [];
        this.content = props.content || {};
        this.parameters = props.parameters;
        this.tags = props.tags ? props.tags.map(tag => formatTag(tag)) : [];
        this.submitted_by = props.submitted_by ? User.create(props.submitted_by) : null;

        if(props.seeds) {
            this.seeds = {
                ...props.seeds,
                notes: (props.seeds.notes || []).filter(n => {
                    return n.deleted !== true;
                }).map(n => {
                    return Note.create(n)
                })
            };
        }
        return this;
    }

    tagsAndStatus = () => {
        return (this.tags || []).concat([this.status]);
    }

    contentSummary = () => {
        let ticket = this.edits || this;
        if(!ticket.content) {
            return null;
        }
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            client_id: this.client_id || window.client_id,
            title: this.title,
            message: this.message,
            attachments: this.attachments,
            os: this.content ? this.content.os : null,
            device: this.content ? this.content.device : null,
            mobileApp: this.content ? this.content.mobile_app : null,
            reproduce: this.content ? this.content.reproduce : null,
            behavior: this.content ? this.content.behavior : null,
            browser: this.content ? this.content.browser : null
        }
        this.edits.content = {
            os: this.edits.os,
            device: this.edits.device,
            mobile_app: this.edits.mobileApp,
            reproduce: this.edits.reproduce,
            behavior: this.edits.behavior,
            browser: this.edits.browser
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            client_id: props.client_id || this.edits.client_id,
            title: props.title || this.edits.title,
            message: props.message || this.edits.message,
            attachments: props.attachments || this.edits.attachments,
            os: (props.os || this.edits.os) && {
                ...this.edits.os,
                ...props.os
            },
            device: (props.os || this.edits.os) && {
                ...this.edits.device,
                ...props.device
            },
            browser: (props.browser || this.edits.browser) && {
                ...this.edits.browser,
                ...props.browser
            },
            mobile_app: (props.mobile_app || this.edits.mobile_app) && {
                ...this.edits.mobile_app,
                ...props.mobile_app
            },
            behavior: (props.behavior || this.edits.behavior) && {
                ...this.edits.behavior,
                ...props.behavior,
            },
            reproduce: props.reproduce || this.edits.reproduce,
        }
        this.edits.content = {
            os: this.edits.os,
            device: this.edits.device,
            mobile_app: this.edits.mobile_app,
            reproduce: this.edits.reproduce,
            behavior: this.edits.behavior,
            browser: this.edits.browser
        }
        return this.edits;
    }

    getHistoryEvents = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                 let { events } = await Request.get(utils, '/saas/', {
                     type: 'support_ticket_history_log',
                     ticket_id: this.id
                 });
                 resolve({
                     events: events.map(e => {
                         return HistoryEvent.create({ ...e, status: formatStatus(e.status) })
                     })
                 });
            } catch(e) {
                reject(e);
            }
        });
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { attachments, id } = await Request.post(utils, '/saas/', {
                    type: 'new_ticket',
                    app_id: edits.client_id,
                    title: edits.title,
                    message: edits.message,
                    attachments: edits.attachments,
                    content: edits.content
                });

                this.id = id;
                this.client_id = edits.client_id;
                this.title = edits.title;
                this.message = edits.message;
                this.attachments = attachments;
                this.content = edits.content;

                utils.content.fetch('supportTickets');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { attachments } = await Request.post(utils, '/saas/', {
                    type: 'update_ticket',
                    id: this.id,
                    app_id: edits.client_id,
                    title: edits.title,
                    message: edits.message,
                    attachments: edits.attachments,
                    content: edits.content
                });

                this.client_id = edits.client_id;
                this.title = edits.title;
                this.message = edits.message;
                this.attachments = attachments;
                this.content = edits.content;

                utils.content.update({
                    type: 'supportTickets',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const statusCodes = {
    pending: null,
    accepted: 1,
    active: 2,
    closed: 3
}

const tags = {
    bug: 1,
    duplicate: 2,
    enhancement: 3,
    feature: 4,
    highPriority: 5,
    mediumPriority: 6,
    lowPriority: 7,
    question: 8
}

export const formatStatus = status => {
    switch(status) {
        case statusCodes.accepted:
        return {
            text: 'Accepted',
            code: status,
            color: Appearance.colors.green
        }

        case statusCodes.active:
        return {
            text: 'Active',
            code: status,
            color: Appearance.colors.blue
        }

        case statusCodes.closed:
        return {
            text: 'Closed',
            code: status,
            color: Appearance.colors.darkGrey
        }

        default:
        return {
            text: 'Pending',
            code: null,
            color: Appearance.colors.lightGrey
        }
    }
}

const tagToText = tag => {

    let text = '';
    let color = Appearance.colors.grey();

    switch(tag) {
        case tags.bug:
        text = 'Bug';
        color = Appearance.colors.lightGrey;
        break;

        case tags.duplicate:
        text = 'Duplicate';
        color = Appearance.colors.grey();
        break;

        case tags.enhancement:
        text = 'Enhancement';
        color = Appearance.colors.green;
        break;

        case tags.feature:
        text = 'Feature Request';
        color = Appearance.colors.green;
        break;

        case tags.highPriority:
        text = 'High Priority';
        color = Appearance.colors.red;
        break;

        case tags.mediumPriority:
        text = 'Medium Priority';
        color = Appearance.colors.orange;
        break;

        case tags.lowPriority:
        text = 'Low Priority';
        color = Appearance.colors.yellow;
        break;

        case tags.question:
        text = 'Question';
        color = Appearance.colors.grey();
        break;

        default:
        return null;
    }

    return {
        text: text,
        tag: tag,
        color: color
    }
}

const formatTag = tag => {
    let found = Object.values(tags).find((_tag) => tag === _tag);
    return found ? tagToText(found) : null;
}

export default {
    new: () => new SupportTicketClass(),
    create: props => new SupportTicketClass().create(props),
    status: statusCodes,
    tags: tags,
    formatTag: formatTag,
    formatStatus: formatStatus
}
