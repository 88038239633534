import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

class ReservationGuidelineClass {

    key = null;
    title = null;
    notes = null;
    description = null;
    value = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.key = props.key;
        this.title = props.title;
        this.notes = props.notes;
        this.description = props.description;
        this.value = props.value;
        return this;
    }

    formatValue = target => {
        let guideline = target || this;
        switch(this.key) {

            case 'min_distance':
            return Utils.distanceConversion(guideline.value);

            case 'min_lead_time':
            return Utils.parseDuration(guideline.value);

            default:
            return;
        }
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            value: this.value
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            value: props.value !== undefined ? props.value : this.edits.value,
            notes: props.notes || this.edits.notes,
        }
        return this.edits;
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/reservations/', {
                    type: 'update_guideline',
                    key: this.key,
                    value: edits.value,
                    notes: edits.notes
                });

                this.value = edits.value;
                this.notes = edits.notes;
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new ReservationGuidelineClass(),
    create: props => new ReservationGuidelineClass().create(props)
}
