import moment from 'moment-timezone';
import Note from 'classes/Note.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class ICHMethodClass {

    id = null;
    _type = null;
    auth_controls = {};
    brand = null;
    billing_address = null;
    date_created = null;
    currency = null;
    last4 = null;
    status = null;
    expiration = {};
    user = null;
    seeds = {};

    constructor() {
        return this;
    }

    create = props => {
        this.id = props.id;
        this._type = props.type;
        this.auth_controls = props.auth_controls;
        this.brand = props.brand;
        this.billing_address = props.billing;
        this.date_created = props.date_created ? moment(props.date_created) : null;
        this.user = props.user ? User.create(props.user) : null;
        this.currency = props.currency;
        this.last4 = props.last4;
        this.status = props.status;
        this.expiration = {
            month: props.exp_month,
            year: props.exp_year
        }

        if(props.seeds) {
            this.seeds = props.seeds;

            var notes = [];
            if(props.seeds.notes) {
                for(var i in props.seeds.notes) {
                    if(props.seeds.notes[i].deleted) {
                        continue;
                    }
                    let note = Note.create(props.seeds.notes[i]);
                    notes.push(note);
                }
                this.seeds.notes = notes;
            }
        }

        return this;
    }

    summary = () => {
        return this.brand + ' ' + Utils.ucFirst(this._type) + ' Card (' + this.last4 + ')';
    }
    type = () => {
        return this.brand + ' ' + Utils.ucFirst(this._type) + ' Card (' + this.last4 + ')';
    }
    subType = () => {
        return 'Expires on ' + this.expiration.month + '/' + this.expiration.year;
    }

    getFormattedAddress = () => {
        return this.billing_address ? ((this.billing_address.address.line1 ? (this.billing_address.address.line1 + ' ') : '') + (this.billing_address.city ? (this.billing_address.city + ' ') : '') + (this.billing_address.state ? (this.billing_address.state + ' ') : '') + (this.billing_address.postal_code ? (this.billing_address.postal_code + ' ') : '')) : null;
    }

    icon = () => {
        switch(this.brand) {
            case 'Visa':
            return 'fab fa-cc-visa';

            case 'American Express':
            return 'fab fa-cc-amex';

            case 'Discover':
            return 'fab fa-cc-discover';

            case 'MasterCard':
            return 'fab fa-cc-mastercard';

            default:
            return 'far fa-credit-card';
        }
    }
}
export default {
    new: () => new ICHMethodClass(),
    create: props => new ICHMethodClass().create(props)
};
