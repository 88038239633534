import Request from 'files/Request.js';

class PremiumLocationClass {

    id = null;
    category = null;
    name = null;
    address = null;
    location = null;
    radius = null;
    amount = null;
    geojson = null;
    active = null;
    type = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.category = props.category;
        this.name = props.name;
        this.address = props.address;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.radius = props.radius;
        this.amount = props.amount;
        this.geojson = props.geojson;
        this.active = props.active;
        this.type = props.type;
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            name: this.name,
            category: this.category,
            address: this.address,
            location: this.location,
            radius: this.radius,
            amount: this.amount,
            type: this.type
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { geojson, id } = await Request.post(utils, '/reservations/', {
                    type: 'new_premium_location',
                    name: edits.name,
                    category: edits.category,
                    address: edits.address,
                    radius: edits.radius,
                    amount: edits.amount,
                    cost_type: edits.type && edits.type.code,
                    location: edits.location && {
                        lat: edits.location.latitude,
                        long: edits.location.longitude
                    }
                });

                this.id = id;
                this.name = edits.name;
                this.category = edits.category;
                this.address = edits.address;
                this.location = edits.location;
                this.radius = edits.radius;
                this.amount = edits.amount;
                this.type = edits.type;
                this.geojson = geojson;

                utils.content.fetch('premiumLocations');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { geojson } = await Request.post(utils, '/reservations/', {
                    type: 'update_premium_location',
                    id: this.id,
                    name: edits.name,
                    category: edits.category,
                    address: edits.address,
                    radius: edits.radius,
                    amount: edits.amount,
                    cost_type: edits.type && edits.type.code,
                    location: edits.location && {
                        lat: edits.location.latitude,
                        long: edits.location.longitude
                    }
                });

                this.name = edits.name;
                this.category = edits.category;
                this.address = edits.address;
                this.location = edits.location;
                this.radius = edits.radius;
                this.amount = edits.amount;
                this.type = edits.type;
                this.geojson = geojson;

                utils.content.update({
                    type: 'premiumLocations',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new PremiumLocationClass(),
    create: props => new PremiumLocationClass().create(props),
    categories: {
        reservations: 1,
        orders: 2
    },
    types: {
        fixed_cost: 1,
        percentage: 2,
        no_cost: 3
    }
}
