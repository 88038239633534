import React from 'react';
import moment from 'moment-timezone';

import Views from 'views/Main.js';
import Layer from 'structure/Layer.js';
import Utils from 'files/Utils.js';

const FullCalendarOverflow = ({ date, segments, utils }, { index, options }) => {
    return (
        <Layer
        id={`calendar-overflow-${moment(date).unix()}`}
        title={moment(date).format('MMMM Do, YYYY')}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            removePadding: true
        }}>
            <div style={{
                overflowY: 'scroll',
                maxHeight: 313
            }}>
                {segments.map((segment, index) => {
                    let { reservation } = segment.eventRange.def.extendedProps;
                    return (
                        Views.entry({
                            key: index,
                            title: reservation.customer.full_name,
                            subTitle: reservation.special_requests.on_demand ? 'Immediate Pickup' : `Pickup at ${moment(reservation.pickup_date).format('h:mma')}`,
                            icon: {
                                path: reservation.customer.avatar
                            },
                            badge: reservation.status,
                            bottomBorder: index !== segments.length - 1,
                            onClick: Utils.reservations.details.bind(this, utils, reservation)
                        })
                    )
                })}
            </div>
        </Layer>
    )
}
export default FullCalendarOverflow;
