import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';

const ImagePickerField = ({  fileTypes, imageStyle, onChange, requirements, style, utils, value }) => {

    const [image, setImage] = useState(null);
    const [label, setLabel] = useState(null);

    const getFileTypes = () => {
        if(!fileTypes || !Array.isArray(fileTypes)) {
            return 'image/png, image/jpeg';
        }
        return fileTypes.map(type => {
            switch(type) {
                case 'jpg':
                return 'image/jpeg';

                case 'png':
                return 'image/png';

                default:
                return null;
            }
        }).filter(type => {
            return type !== null;
        }).join(', ');
    }

    const onFileChange = evt => {

        let { files } = evt.target;
        if(files && files.length > 0) {

            let file = files[0];
            const reader = new FileReader();
            reader.onload = async data => {
                try {
                    let image = {
                        data: data.target.result,
                        file_name: file.name,
                        file_type: file.type
                    };
                    await onValidateImage(image);

                    setImage(image);
                    setLabel(image.file_name);
                    if(typeof(onChange) === 'function') {
                        onChange(image);
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue processing your image. ${e.message || 'An unknown error occurred'}`
                    });
                }
            };
            reader.readAsDataURL(files[0]);
        }
    }

    const onGenerateImageProps = string => {
        try {
            let fileName = string.split('\\').pop().split('/').pop();
            let fileType = fileName.includes('.png') ? 'image/png' : 'image/jpeg';
            return {
                file_name: fileName,
                file_type: fileType,
                name: string.includes('http') ? 'Already Uploaded' : 'Image Selected',
                url: string,
            }

        } catch(e) {
            console.error(e.message);
            return null;
        }
    }

    const onImageOptions = () => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove Image',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                setImage(null);
                setLabel(null);
                if(typeof(onChange) === 'function') {
                    onChange(null);
                }
            }
        })
    }

    const onParseImage = () => {
        if(!value) {
            setLabel(null);
            setImage(null);
            return;
        }
        if(typeof(value) === 'string') {
            if(value.includes('placeholder')) {
                setLabel(null);
                setImage(null);
                return;
            }
            let props = onGenerateImageProps(value);
            setImage(props);
            return;
        }
        setImage(value);
    }

    const onValidateImage = async image => {
        return new Promise((resolve, reject) => {

            // immediately resolve if no image requirements are found
            if(!requirements) {
                resolve();
                return;
            }

            // check that image falls within dimension requirements if applicable
            if(requirements.dimensions) {
                let { width, height } = requirements.dimensions;
                let component = new Image();
                component.src = image.data;
                component.onload = function() {

                    if(this.width < width || this.height < height) {
                        let error = new Error(`Your image size must be at least ${width} x ${height}. ${image.file_name} is ${this.width} x ${this.height} and is too small to use.`);
                        reject(error);
                        return;
                    }
                    resolve();
                };
                return;
            }
            resolve();
        })
    }

    useEffect(() => {
        onParseImage();
    }, [value]);

    useEffect(() => {
        setLabel(image ? (image.name || image.file_name) : null);
    }, [image]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'left',
            width: '100%',
            ...style
        }}>
            {image && (
                <div
                className={'text-button'}
                onClick={onImageOptions}
                style={{
                    marginRight: 4
                }}>
                    <img
                    src={image.url || image.data}
                    style={{
                        backgroundColor: Appearance.colors.primary(),
                        border: `1px solid ${Appearance.colors.softBorder()}`,
                        borderRadius: 8,
                        height: 35,
                        objectFit: 'cover',
                        width: 35,
                        ...imageStyle
                    }}/>
                </div>
            )}
            <div
            className={`custom-file ${window.theme}`}
            style={{
                flexGrow: 1,
                overflow: 'hidden'
            }}>
                <input
                accept={getFileTypes()}
                className={`custom-file-input ${window.theme}`}
                onChange={onFileChange}
                type={'file'}
                style={{
                    border: `1px solid ${Appearance.colors.softBorder()}`
                }}/>
                <label
                className={`custom-file-label ${window.theme}`}
                style={{
                    ...Appearance.textStyles.subTitle(),
                    border: `1px solid ${Appearance.colors.softBorder()}`,
                    color: image ? Appearance.colors.text() : Appearance.colors.subText()
                }}>{label || 'No image selected...'}</label>
            </div>
        </div>
    )
}

export default ImagePickerField;
