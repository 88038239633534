import moment from 'moment-timezone';
import User from 'classes/User.js';

class HistoryEventClass {

    id = null;
    status = null;
    user = null;
    date = null;
    data = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = parseInt(props.id);
        this.status = props.status;
        this.date = props.date ? moment.utc(props.date).local() : null;
        this.data = props.data;
        this.user = props.user ? User.create(props.user) : null;
        return this;
    }

    getData = () => {
        if(!this.data || !this.data.message) {
            return null;
        }
        return {
            title: 'Note',
            message: this.data.message
        }
    }
}
export default {
    new: () => new HistoryEventClass(),
    create: props => new HistoryEventClass().create(props)
};
