import React, { useEffect, useRef, useState } from 'react';

import 'styles/main.scss';
import 'styles/index.css';

import { getContent } from 'files/Content.js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment-timezone';
import smoothscroll from 'smoothscroll-polyfill';
import update from 'immutability-helper';

import API from 'files/api.js';
import Alert from 'views/Alert.js';
import AlertStack from 'views/AlertStack.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import Cookies from 'js-cookie';
import DatePicker, { DualDatePicker } from 'views/DatePicker.js';
import DesktopNotification from 'views/DesktopNotification.js';
import { EndIndex } from 'structure/Layer.js';
import Loader from 'views/Loader.js';
import LoginCard from 'views/LoginCard.js';
import Notification from 'classes/Notification.js';
import NotificationCenter from 'files/NotificationCenter.js';
import Order from 'classes/Order.js';
import QueryString from 'query-string';
import Reservation from 'classes/Reservation.js';
import Route from 'classes/Route.js';
import SaasAccount from 'classes/SaasAccount.js';
import Sheet from 'views/Sheet.js';
import Sidebar from 'structure/Sidebar.js';
import Sockets from 'managers/Sockets.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import WorkspaceManager from 'views/WorkspaceManager.js';

const ContentManager = Content.new();
const SocketManager = Sockets.new();
const StripeProps = loadStripe('pk_live_fY0CKaRY3MA4TgsZ4au9EFgF');

window.theme = 'light';
window.channel = 'seeds';
window.userLocation = {
    latitude: 32.795894,
    longitude: -96.803960,
    showOnMap: Cookies.get('showLocationOnMap') === 'false' ? false : true
}

const App = () => {

    const fetching = useRef({});
    const userRef = useRef(null);

    const [activeWorkspace, setActiveWorkspace] = useState(0);
    const [alerts, setAlerts] = useState([]);
    const [client_id, setAppID] = useState(null);
    const [container, setContainer] = useState({
        opacity: 0,
        top: -200
    });
    const [content, setContent] = useState(null);
    const [datePicker, setDatePicker] = useState(null);
    const [invalid, setInvalid] = useState(null);
    const [loader, setLoader] = useState(null);
    const [login, setLogin] = useState({ backgroundColor: Appearance.colors.background() });
    const [nonce, setNonce] = useState(moment().unix());
    const [notification, setNotification] = useState(null);
    const [sheet, setSheet] = useState(null);
    const [sidebar, setSidebar] = useState(false);
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [theme, setTheme] = useState(window.theme);
    const [user, setUser] = useState(null);
    const [workspaces, setWorkspaces] = useState([{
        title: 'My Workspace',
        layers: [],
        layerIndex: [],
        panels: {
            workspace: {
                panels: []
            }
        },
        active: {
            view: null
        }
    }]);

    const onAddNewPanel = panel => {

        if(getCurrentWorkspace().panels.workspace.panels.find(prevPanel => prevPanel.key === panel.key)) {
            utils.alert.show({
                title: 'Add Panel',
                message: 'It looks like you already have this Panel open in your Workspace'
            })
            return;
        }

        setWorkspaces(workspaces => update(workspaces, {
            [activeWorkspace]: {
                active: {
                    view: {
                        $set: 'workspace'
                    }
                },
                panels: {
                    workspace: {
                        panels: {
                            $push: [panel]
                        }
                    }
                }
            }
        }))

        window.window.scroll({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });

        if(Cookies.get('onNewPanelAlert') === 'false') {
            return;
        }

        utils.alert.show({
            title: 'Panel Added',
            message: 'Your new Panel has been added to the bottom of your Workspace. You can reorganize your Panels by dragging the top bar to change the order. You can view your Workspace by clicking on the Workspace icon at the top of the screen or by clicking the Workspace option on the right navigation bar.',
            buttons: [{
                key: 'done',
                title: 'Got It',
                style: 'default'
            },{
                key: 'remind',
                title: 'Don\'t Remind Me Again',
                style: 'destructive'
            }],
            onClick: key => {
                if(key === 'remind') {
                    Cookies.set('onNewPanelAlert', 'false');
                }
            }
        })
    }

    const onAssistClick = (props, callback) => {

        let items = props.items || [];
        if(props.message) {
            items.push({
                key: 'help',
                title: 'About this Panel',
                style: 'destructive'
            })
        }

        // show alert if only one item is supplied
        if(items.length === 1 && items[0].key === 'help') {
            onShowAssistHelpMessage(props);
            return;
        }

        // show sheet if more than a single item is found
        utils.sheet.show({
            items: items
        }, key => {
            if(key == 'help') {
                onShowAssistHelpMessage(props);
                return;
            }
            if(typeof(callback) === 'function') {
                callback(key);
            }
        });
    }

    const onCloseAlert = (id, key) => {
        if(key === 'renew_authorization_token') {
            setAlerts([]);
            onRunLogout();
            return;
        }
        setAlerts(alerts => {
            return alerts.filter(alert => id !== alert.id);
        });
    }

    const onCloseLayer = layerID => {
        try {
            // update layer visibiltiy and layer index collection
            let ws = update(workspaces, {
                [activeWorkspace]: {
                    layerIndex: {
                        $apply: ids => ids.filter(id => {
                            return id !== layerID;
                        })
                    },
                    layers: {
                        $apply: layers => layers.map(layer => {
                            if(layer.id === layerID) {
                                layer.visible = false;
                            }
                            return layer;
                        })
                    }
                }
            });

            // clean up after all layers are marked non-visible
            let layers = ws[activeWorkspace].layers.filter(layer => {
                return layer.visible !== false;
            });

            // update workspace with remaining visible layers if applicable
            if(layers.length > 0) {
                setWorkspaces(workspaces => update(workspaces, {
                    [activeWorkspace]: {
                        layers: {
                            $apply: layers => layers.map(layer => {
                                if(layer.id === layerID) {
                                    layer.visible = false;
                                }
                                return layer;
                            })
                        }
                    }
                }));
                return;
            }

            // reset layers array if no more visible layers are found
            setWorkspaces(workspaces => update(workspaces, {
                [activeWorkspace]: {
                    layers: {
                        $set: []
                    }
                }
            }))
            console.log('layers reset');

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `We ran into an error while updating your workspace. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDeleteWorkSpace = index => {
        if(workspaces.length === 1) {
            utils.alert.show({
                title: 'Oops!',
                message: 'It looks like you only have one Workspace. We can not delete your only Workspace'
            })
            return;
        }

        utils.alert.show({
            title: 'Delete Workspace',
            message: 'Are you sure that you want to delete this Workspace? Any unsaved progress will be lost.',
            buttons: [{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'delete') {
                    setActiveWorkspace(0)
                    setWorkspaces(workspaces => update(workspaces, {
                        $apply: workspaces => workspaces.filter((w, i) => i !== index)
                    }))
                }
            }
        })
    }

    const onDiscardEdits = callback => {
        setAlerts(alerts => update(alerts, {
            $push: [{
                id: `${moment().unix()}-${Math.random()}`,
                title: 'Discard Changes',
                message: 'Are you sure that you want to discard your changes? This can not be undone',
                buttons: [{
                    key: 'discard',
                    title: 'Discard',
                    style: 'destructive'
                },{
                    key: 'cancel',
                    title: 'Do Not Discard',
                    style: 'default'
                }],
                onClick: key => {
                    if(key === 'discard') {
                        callback();
                    }
                }
            }]
        }));
    }

    const onExportAllWorkspaces = () => {
        Utils.utils.layer.download.text(`workspaces-${moment().unix()}`, JSON.stringify(onExportWorkspaces()));
    }

    const onExportWorkspace = index => {
        Utils.utils.layer.download.text(`workspaces-${moment().unix()}`, JSON.stringify(onExportWorkspaces(index)));
    }

    const onExportWorkspaces = index => {

        let spaces = workspaces.filter((space, i) => {
            if(isNaN(index)) {
                return true;
            }
            return i === index;

        })
        return spaces.filter(({ title, panels }) => {
            return panels.workspace ? true : false;
        }).map(({ title, panels }) => {
            return {
                title: title,
                panels: panels ? panels.workspace.panels.map(panel => panel.key) : [],
            }
        })
    }

    const onLayerReposition = props => {

        let index = getCurrentWorkspace().layers.findIndex(layer => layer.id === props.id);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setWorkspaces(workspaces => update(workspaces, {
            [activeWorkspace]: {
                layers: {
                    [index]: {
                        position: {
                            $set: props.position
                        }
                    }
                }
            }
        }))
    }

    const onLoadWorkspace = event => {

        let { files } = event.target;
        if(!files || files.length === 0) {
            utils.alert.show({
                title: 'Oops!',
                message: 'There was an issue loading your Workspace file. The file may be incomplete or invalid'
            });
            return;
        }

        let reader = new FileReader();
        reader.onload = (e) => {
            try {

                let { result } = e.target;
                let json = JSON.parse(result);
                if(!Array.isArray(json) || json.some(workspace => {
                    return !workspace.title || !workspace.panels || !Array.isArray(workspace.panels);
                })) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: 'There was an issue loading your Workspace file. The file may be incomplete or invalid'
                    })
                }

                // Merge or overwrite
                let spaces = onParseWorkspaces(json, content);
                utils.alert.show({
                    title: json.length + (json.length === 1 ? ' Workspace' : ' Workspaces'),
                    message: 'Would you like to merge ' + (json.length === 1 ? ' this Workspace' : ' these Workspaces') + ' with your current Workspaces or overwrite them with the loaded ' + (json.length === 1 ? ' Workspace' : ' Workspaces') + '?',
                    buttons: [{
                        key: 'merge',
                        title: 'Merge',
                        style: 'default'
                    },{
                        key: 'overwrite',
                        title: 'Overwrite',
                        style: 'destructive'
                    },{
                        key: 'cancel',
                        title: 'Do Nothing',
                        style: 'cancel'
                    }],
                    onClick: key => {

                        if(key === 'merge' || key === 'overwrite') {
                            setActiveWorkspace(0);
                            setWorkspaces(workspaces => update(workspaces, {
                                [key === 'merge' ? '$unshift' : '$set']: spaces
                            }))
                        }
                    }
                })
                e.target.files = null;

            } catch(e) {
                console.error(e.message);
                utils.alert.show({
                    title: 'Oops!',
                    message: 'There was an issue loading your Workspace file. The file may be incomplete or invalid'
                })
            }
        };
        reader.readAsText(files[0]);
    }

    const onNavigationChange = ({ view, subView }) => {

        // hide sidebar is device is mobile
        if(Utils.isMobile()) {
            setSidebar(false);
        }

        // prevent moving forward if same view/subview combination was selected
        if(view == getCurrentWorkspace().active.view) {
            if(!subView || subView == getCurrentWorkspace().active.subView) {
                return;
            }
        }

        // loop through specialty view types
        switch(view) {
            case 'support':
            onSupportClick();
            return;

            case 'logout':
            onRequestLogout();
            return;
        }

        // update history state with new selection
        setHistoryState({
            view: view,
            subView: subView
        });

        // update workspace with new selection and scroll page to top
        window.scrollTo(0, 0);
        setWorkspaces(workspaces => update(workspaces, {
            [activeWorkspace]: {
                active: {
                    view: {
                        $set: view
                    },
                    subView: {
                        $set: subView
                    }
                }
            }
        }));
    }

    const onNewTarget = (type, data) => {
        utils.content.fetch(type);
    }

    const onNewWorkspace = () => {
        setActiveWorkspace(workspaces.length);
        setWorkspaces(workspaces => update(workspaces, {
            $push: [{
                ...getCurrentWorkspace(),
                title: 'New Workspace',
                layers: [],
                layerIndex: [],
                panels: content,
                active: {
                    view: 'workspace'
                }
            }]
        }))
    }

    const onNotification = data => {
        try {
            let props = JSON.parse(data);
            let notification = Notification.create(props);
            utils.content.fetch('notifications');
            setNotification({ notification: notification });
        } catch(e) {
            console.error(e);
        }
    }

    const onOpenLayer = layer => {

        if(getCurrentWorkspace().layers.find(prevLayer => {
            return prevLayer.id === layer.id && prevLayer.visible !== false
        })) {
            utils.alert.show({
                title: 'Just a Second',
                message: `There is already a layer open for ${layer.abstract ? `"${layer.abstract.getTitle()}"` : 'this content'}`
            });
            return;
        }

        setTimeout(() => {
            setWorkspaces(workspaces => update(workspaces, {
                [activeWorkspace]: {
                    layers: {
                        $push: [layer]
                    },
                    layerIndex: {
                        $unshift: [layer.id]
                    }
                }
            }))
        });
    }

    const onOpenWorkspace = index => {
        setActiveWorkspace(index);
        setWorkspaces(workspaces => update(workspaces, {
            [index]: {
                active: {
                    view: {
                        $set: 'workspace'
                    }
                }
            }
        }))
    }

    const onPanelReorder = (dragIndex, hoverIndex) => {

        let workspace = getCurrentWorkspace();
        if(workspace.active.subView) {

            let panel = workspace.panels[workspace.active.view].subViews[workspace.active.subView].panels[dragIndex];
            setWorkspaces(workspaces => update(workspaces, {
                [activeWorkspace]: {
                    panels: {
                        [workspace.active.view]: {
                            subViews: {
                                [workspace.active.subView]: {
                                    panels: {
                                        $splice: [
                                            [dragIndex, 1],
                                            [hoverIndex, 0, panel]
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            }));
            return;
        }

        let panel = workspace.panels[workspace.active.view].panels[dragIndex];
        setWorkspaces(workspaces => update(workspaces, {
            [activeWorkspace]: {
                panels: {
                    [workspace.active.view]: {
                        panels: {
                            $splice: [
                                [dragIndex, 1],
                                [hoverIndex, 0, panel],
                            ]
                        }
                    }
                }
            }
        }))
    }

    const onParseWorkspaces = (json, content) => {

        // Recursive key search in content object
        const search = (key, views, found = false) => {
            views.forEach((view) => {
                if(view.key === key) {
                    found = view;
                    return found;
                }
                if(view.panels) {
                    found = search(key, view.panels, found)
                } else if(view.subViews) {
                    found = search(key, Object.values(view.subViews), found);
                }
            });
            return found;
        };

        // Build spaces
        let target = json.length > 0 ? json : [content];
        let workspaces = json.map(workspace => {
            return {
                ...workspace,
                layers: [],
                layerIndex: [],
                active: {
                    view: 'workspace'
                },
                panels: {
                    workspace: {
                        ...content.workspace,
                        panels: workspace.panels.map(panel => {
                            return search(panel, Object.values(content))
                        }).filter(panel => panel !== false)
                    },
                    ...Object.keys(content).filter(key => key !== 'workspace').reduce((obj, key) => {
                        obj[key] = content[key];
                        return obj;
                    }, {})
                }
            };
        })
        return workspaces;
    }

    const onRenameWorkspace = index => {
        utils.alert.show({
            title: `Rename "${workspaces[index].title}"`,
            message: 'What would you like to call this Workspace?',
            textFields: [{
                key: 'title',
                placeholder: 'Workspace Name'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: ({ title }) => {
                if(title) {
                    setWorkspaces(workspaces => update(workspaces, {
                        [index]: {
                            title: {
                                $set: title
                            }
                        }
                    }))
                }
            }
        })
    }

    const onRequestLocation = () => {

        utils.alert.show({
            title: 'Location Services',
            message: 'Seeds uses your location to provide custom-tailored search results and relevant Maps for your area. We do not share your location with any third parties or advertisers.',
            buttons: [{
                key: 'approve',
                title: 'Use My Location',
                style: 'default'
            },{
                key: 'decline',
                title: 'No Thanks',
                style: 'destructive'
            }],
            onClick: key => {

                if(key === 'decline') {
                    Cookies.set('useLocation', 'false', { expires: 14 });
                    return;
                }

                if(!navigator.geolocation) {
                    utils.alert.show({
                        title: 'Unsupported',
                        message: 'It looks like your browser does not support location based services',
                        onClick: () => {
                            Cookies.set('useLocation', 'false', { expires: 14 });
                            throw new Error('Browser does not support geolocation');
                        }
                    })
                    return;
                }

                Cookies.set('useLocation', 'true', { expires: 365 });
                navigator.geolocation.getCurrentPosition(({ coords }) => {
                    window.userLocation = {
                        ...coords,
                        accurate: true
                    }

                }, (error) => {
                    if(error.code === 1) {
                        return;
                    }
                    utils.alert.show({
                        title: 'Unsupported',
                        message: 'There was an issue retrieving your location from your browser',
                        buttons: [{
                            key: 'cancel',
                            title: 'Got It',
                            style: 'default'
                        },{
                            key: 'stop',
                            title: 'Don\'t Try Again',
                            style: 'destructive'
                        }],
                        onClick: key => {
                            if(key === 'stop') {
                                Cookies.set('useLocation', 'false', { expires: 14 });
                            }

                        }
                    })
                });
            }
        })
    }

    const onRequestLogout = () => {
        utils.alert.show({
            title: 'Logout',
            message: 'Are you sure that you want to logout of your account?',
            buttons: [{
                key: 'logout',
                title: 'Logout',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Logout',
                style: 'default'
            }],
            onClick: async key => {
                if(key === 'logout') {
                    onRunLogout();
                    return;
                }
            }
        })
    }

    const onRunLogout = async () => {
        try {
            // hide sidebar and main content
            setSidebar(false);
            setContainer(container => update(container, {
                top: {
                    $set: -200
                },
                opacity: {
                    $set: 0
                }
            }));

            // remove user credentials
            await Utils.sleep(0.5);
            setUser(null);

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSetLayerIndex = layerID => {

        let index = getCurrentWorkspace().layers.findIndex(layer => layer.id === layerID);
        let layerIndex = getCurrentWorkspace().layerIndex.findIndex(id => id === layerID);
        if(index < 0) {
            console.log('no layer index');
            return;
        }

        setWorkspaces(workspaces => update(workspaces, {
            [activeWorkspace]: {
                layers: {
                    $apply: layers => layers.map(layer => {
                        layer.moveToFront = layer.id === layerID;
                        return layer;
                    })
                },
                layerIndex: {
                    $splice: [
                        [layerIndex, 1],
                        [0, 0, layerID]
                    ]
                }
            }
        }))
    }

    const onSetNavigation = props => {
        setWorkspaces(workspaces => update(workspaces, {
            [activeWorkspace]: {
                active: {
                    $set: props
                }
            }
        }))
    }

    const onShowAssistHelpMessage = props => {
        utils.alert.show({
            title: 'About this Panel',
            message: props.message,
            buttons: [{
                key: 'about',
                title: `What's a Panel?`,
                style: 'default'
            },{
                key: 'cancel',
                title: 'Got it',
                style: 'cancel'
            }],
            onClick: async key => {
                if(key === 'about') {
                    await Utils.sleep(0.75);
                    utils.alert.show({
                        title: `What's a Panel?`,
                        message: 'A Panel is a visual building block in our platform that displays information. Panels contain information about rides, orders, customers, and just about anything else you can imagine.'
                    });
                    return;
                }
            }
        })
    }

    const onStatusChange = async data => {
        try {
            // construct abstract target
            let target = null;
            let { order_id, reservation_id, route_id } = JSON.parse(data);
            if(order_id) {
                target = { id: order_id, type: 'orders' }
            }
            if(reservation_id) {
                target = { id: reservation_id, type: 'reservations' }
            }
            if(route_id) {
                target = { id: route_id, type: 'routes' }
            }
            if(!target) {
                return;
            }

            // fetch abstract object
            let { abstract } = await Utils.create(utils, target);
            if(!abstract) {
                return;
            }

            // update listeners
            utils.content.update(abstract);
            console.log(`status update for ${abstract.type} ${abstract.getID()}`);

            // request lists to fetch content if target has moved to an in-progress state
            if(abstract.object.status) {
                switch(abstract.type) {
                    case 'routes':
                    if(abstract.object.status.code === Route.status.in_progress) {
                        utils.content.fetch('routes');
                    }
                    break;

                    case 'orders':
                    if([ Order.status.to_pickup, Order.status.to_destination ].includes(abstract.object.status.code)) {
                        utils.content.fetch('orders');
                    }
                    break;

                    case 'reservations':
                    if([ Reservation.status.to_pickup, Reservation.status.to_destination ].includes(abstract.object.status.code)) {
                        utils.content.fetch('reservations');
                    }
                    break;
                }
            }

        } catch(e) {
            console.error(e.message);
        }
    }

    const onSupportClick = () => {
        utils.alert.show({
            title: 'How Can We Help?',
            message: 'We\'re available to assist you via chat or email. Which would you prefer?',
            buttons: [{
                key: 'chat',
                title: 'Chat',
                style: 'default'
            },{
                key: 'email',
                title: 'Email',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                // TODO => add support options
            }
        })
    }

    const fetchClient = async () => {

        // check for explicit app id
        let { app_id } = QueryString.parse(window.location.search);
        window.client_id = app_id || 'ecarra';

        // prevent moving forward if client is ecarra
        if(window.client_id === 'ecarra') {
            setAppID(window.client_id);
            return;
        }

        // fetch client parameters
        try {
            let props = await Utils.getSaaSParameters(utils, app_id);
            window.clientParameters = SaasAccount.create(props);
            if(window.clientParameters.location) {
                window.userLocation = window.clientParameters.location;
            }
            setAppID(window.client_id);

        } catch(e) {
            setInvalid('invalid-client-id');
            utils.alert.show({
                title: 'Oops!',
                message: e.message || 'An unknown error occurred'
            });
        }
    }

    const onSetupUser = async ({ user }) => {
        try {
            // set user account
            setUser(user);
            userRef.current = user;

            // get full saas client parameters after user has logged in
            if(window.clientParameters) {
                let appParams = await Utils.getSaaSParameters(utils, client_id, true);
                window.clientParameters = SaasAccount.create(appParams);
            }

            // connect to sockets and setup listeners
            await SocketManager.connect(utils, user);
            await SocketManager.persistOn('orders', 'on_new', onNewTarget.bind(this, 'orders'));
            await SocketManager.persistOn('reservations', 'on_new', onNewTarget.bind(this, 'reservations'));
            await SocketManager.persistOn('seeds', 'on_notification', onNotification);
            await SocketManager.persistOn('seeds', 'on_status_change', onStatusChange);

            // fetch content with dynamic order channels
            let content = await getContent(utils);
            await utils.loader.hide();
            setContent(content);
            setupWorkspaces(content);
            setBaseRoute({ view: 'dashboard', subView: null });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the content for this view. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setBaseRoute = async ({ view, subView }) => {

        // update container animations
        setContainer(container => update(container, {
            top: {
                $set: 0
            },
            opacity: {
                $set: 1
            }
        }))

        // show sidebar automatically if device is not mobile
        if(Utils.isMobile() === false) {
            setSidebar(true);
        }

        // set history state
        setHistoryState({
            view: view,
            subView: subView
        });

        // update workspace
        setWorkspaces(workspaces => {
            return update(workspaces, {
                [activeWorkspace]: {
                    active: {
                        $set: {
                            view: view,
                            subView: subView
                        }
                    }
                }
            })
        });
        return;

        // Prefill company location from address
        if(user.company) {
            window.userLocation = user.company.location;
        }

        // Check user preferrences
        let useLocation = Cookies.get('useLocation') === 'false' ? false : true;
        if(!useLocation) {
            return;
        }

        onRequestLocation();
    }

    const setHistoryState = ({ view, subView }) => {
        window.history.pushState({
            detail: {
                view: view,
                subView: subView
            }
        }, 'Seeds: Powered by eCarra', `/${API.dev_env ? '' : 'seeds/'}index.html?view=${view}${subView ? `&sub_view=${subView}` : ''}${window.client_id !== 'ecarra' ? `&app_id=${window.client_id}` : ''}`)
    }

    const setRequiredLevels = (levels, callback) => {
        for(var i in levels) {
            let requiredLevel = null;
            switch(levels[i]) {
                case 'admin':
                requiredLevel = User.level.admin;
                break;

                case 'driver':
                requiredLevel = User.level.driver;
                break;

                case 'customer':
                requiredLevel = User.level.customer;
                break;

                case 'company':
                requiredLevel = User.level.company_admin;
                break;

                default:
                return;
            }
            if(user.level <= requiredLevel || (requiredLevel === User.level.company_admin && user.level > User.level.customer)) {
                if(typeof(callback) === 'function') {
                    callback();
                }
                return true;
            }
        }
        return false;
    }

    const setupWorkspaces = content => {

        // Setup fallback workspace
        let spaces = workspaces.map(workspace => {
            workspace.panels = content;
            return workspace;
        })

        // Retrieve saved workspaces
        let savedSpaces = Cookies.get('workspaces');
        if(savedSpaces) {
            try {
                let json = JSON.parse(savedSpaces);
                spaces = onParseWorkspaces(json, content);
            } catch(e) {
                console.error(e.message);
            }
        }
        setWorkspaces(spaces);
    }

    const getAppContent = () => {
        if(invalid) {
            return (
                <div
                className={`root-container ${window.theme}`}
                nonce={nonce}
                style={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: Appearance.colors.background()
                }}>
                    {invalid === 'invalid-client-id' && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: 350
                        }}>
                            <img
                            src={'images/no-data-found.png'}
                            style={{
                                width: '45px',
                                height: '45px',
                                objectFit: 'contain',
                                boxShadow: '3px 3px 7px #c9c8ca, -3px -3px 5px #ffffff',
                                borderRadius: 25,
                                marginBottom: 12
                            }} />
                            <span style={{
                                ...Appearance.textStyles.title(),
                                marginBottom: 4,
                                textAlign: 'center'
                            }}>{'Invalid Client ID'}</span>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                whiteSpace: 'break-spaces',
                                textAlign: 'center'
                            }}>{'The client ID found in the request is either not valid or the client account has been deactivated. Please contact eCarra support if you are the administrator of this client account'}</span>
                        </div>
                    )}
                </div>
            )
        }
        return (
            <div
            className={`root-container ${window.theme}`}
            nonce={nonce}
            style={{
                width: '100%',
                height: size.height,
                backgroundColor: Appearance.colors.background()
            }}>
                <Loader
                utils={utils}
                animate={loader}>
                    {user && onRenderContent()}
                    {!user && onRenderLogin()}
                </Loader>

                {datePicker && (
                    <DatePicker
                    {...datePicker}
                    onClose={setDatePicker.bind(this, null)} />
                )}

                <AlertStack>
                    {alerts.map((alert, index) => (
                        <Alert {...alert}
                        key={index}
                        utils={utils}
                        index={(alerts.length - 1) - index}
                        onClose={onCloseAlert} />
                    ))}
                </AlertStack>

                {sheet && (
                    <Sheet
                    {...sheet}
                    onClose={() => setSheet(null)}/>
                )}
                {notification && (
                    <DesktopNotification
                    notification={notification}
                    onClose={setNotification.bind(this, null)}
                    utils={utils}/>
                )}
            </div>
        )
    }

    const getCurrentWorkspace = () => {
        return workspaces[activeWorkspace];
    }

    const getMobileHeader = () => {
        return (
            <nav
            className={`main-navbar ${theme} navbar navbar-${theme} sticky-top d-flex d-md-none flex-md-nowrap p-0 w-100 text-center`}
            style={{
                backgroundColor: window.theme === 'dark' ? 'rgba(65,65,65,1)' : 'rgba(250,250,250,1)'
            }}>
                <div style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <a
                    href={'#'}
                    className={'nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'}>
                        <img
                        className={'text-button'}
                        src={`/images/navigation-${theme === 'dark' ? 'white' : 'dark-grey'}.png`}
                        onClick={() => setSidebar(true)}
                        style={{
                            width: 32,
                            height: 25,
                            objectFit: 'contain'
                        }} />
                    </a>
                    <img
                    className={'text-button'}
                    src={`/images/logo-${theme}.png`}
                    onClick={() => window.open('https://ecarra.com')}
                    style={{
                        flexGrow: 1,
                        paddingRight: 4,
                        width: 'auto',
                        height: 20,
                        objectFit: 'contain'
                    }} />
                    <div className='nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center'/>
                </div>
            </nav>
        )
    }

    const getPanels = () => {

        let workspace = getCurrentWorkspace();
        if(!workspace || !workspace.active || !workspace.active.view) {
            return null;
        }

        let { active, panels } = workspace;
        if(!panels[active.view]) {
            return null;
        }
        if(!active.subView) {
            return panels[active.view] ? panels[active.view].panels : null;
        }
        if(panels[active.view].subViews) {
            return panels[active.view].subViews[active.subView].panels;
        }
        return panels[active.view].panels;
    }

    const onRenderContent = () => {

        let workspace = getCurrentWorkspace();
        if(!workspace) {
            return null;
        }
        let panels = getPanels();
        if(!panels) {
            return null;
        }

        let { layers } = workspace || {};
        return (
            <div style={{
                backgroundColor: Appearance.colors.background()
            }}>
                {layers && layers.length > 0 && (
                    layers.map((Layer, index, layers) => {
                        if(Layer.visible === false) {
                            return null;
                        }
                        return (
                            <Layer.Component
                            key={index}
                            utils={utils}
                            index={index}
                            abstract={Layer.abstract}
                            options={{
                                ...Layer.options,
                                index: index,
                                zIndex: (EndIndex - workspace.layerIndex.findIndex(id => id === Layer.id)),
                                onClose: onCloseLayer,
                                onSetLayerIndex: onSetLayerIndex,
                                onReposition: onLayerReposition
                            }}/>
                        )
                    })
                )}
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <Sidebar
                        utils={utils}
                        user={user}
                        visible={sidebar}
                        active={workspace.active}
                        content={content}
                        onLogoutClick={onRequestLogout}
                        onMobileClose={() => setSidebar(false)}
                        onNavigationChange={onNavigationChange} />
                        <main
                        className={`main-content ${sidebar.collapsed ? 'col-md-11' : 'col-lg-10 col-md-9 col-sm-12'} p-0 ${sidebar.collapsed ? 'offset-md-1' : 'offset-lg-2 offset-md-3'}`}
                        style={{
                            zIndex: '900'
                        }}>
                            {getMobileHeader()}
                            <div
                            className={'main-content-container container-fluid px-0 pt-0'}
                            style={{
                                position: 'relative',
                                paddingBottom: 45
                            }}>
                                <VelocityComponent
                                easing={[250, 20]}
                                duration={1000}
                                animation={{
                                    opacity: container.opacity,
                                    top: container.top
                                }}>
                                    <div
                                    className={'row w-100 p-0 m-0'}
                                    style={{
                                        position: 'relative'
                                    }}>
                                        {panels && panels.filter(({ visible }) => {
                                            return visible !== false;
                                        }).map(({ column, Component, level }, index) => (
                                            <Component
                                            key={index}
                                            utils={utils}
                                            index={index}
                                            column={column} />
                                        ))}
                                    </div>
                                </VelocityComponent>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        )
    }

    const onRenderLogin = () => {
        if(!client_id) {
            return;
        }
        return (
            <VelocityComponent
            duration={500}
            animation={{
                backgroundColor: login.backgroundColor
            }}>
            <div style={{
                width: '100%',
                height: '100%'
            }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%'
                    }}>
                        <LoginCard
                        utils={utils}
                        onLogin={onSetupUser}/>
                    </div>
                </div>
            </VelocityComponent>
        )
    }

    const onSetStyleSheetProperties = () => {
        setTheme(window.theme);
        document.body.className = window.theme;
        document.documentElement.style.setProperty('--theme', window.theme);
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());
    }

    const onUpdateTheme = evt => {
        window.theme = evt.matches ? 'dark' : 'light';
        onSetStyleSheetProperties();
        setNonce(moment().unix());
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    const getWorkspaceLayers = () => {
        return getCurrentWorkspace().layers;
    }

    const getWorkspaceLayerIndex = () => {
        return getCurrentWorkspace().layerIndex;
    }

    const getWorkspaceManager = () => {
        let workspace = getCurrentWorkspace();
        if(!workspace || !workspace.panels.workspace || user.level > User.level.admin || window.client_id !== 'ecarra') {
            return null;
        }
        return (
            <div
            className={'d-none d-md-block col-12 sticky-top px-3 pt-3 pb-2'}
            style={{
                width: '100%',
                zIndex: 1100,
                backgroundColor: Appearance.colors.background(),
                overflow: 'visible'
            }}>
                <WorkspaceManager
                content={content}
                utils={utils}
                workspaces={workspaces}
                active={activeWorkspace}
                activeWorkspace={workspaces[activeWorkspace]}/>
            </div>
        )
    }

    const utils = {
        alert: {
            show: props => {
                setAlerts(alerts => {
                    return update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            ...props
                        }]
                    });
                })
            },
            dev: () => {
                setAlerts(alerts => {
                    return update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            title: 'In Development',
                            message: 'This feature is currently under development and will be available at a later date.'
                        }]
                    });
                });
            },
            discard: onDiscardEdits
        },
        api: {
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'X-Client': `ID ${window.client_id}`,
                    'X-Timezone': `TZ ${moment.tz.guess()}`,
                    'X-Web': `Build ${API.app_version}`,
                    ...userRef.current && {
                        'Authorization': `Bearer ${userRef.current.api_key}`,
                        'Identification': `User ${userRef.current.user_id}`
                    }
                }
            }
        },
        client: {
            get: () => ({
                flavors: window.clientParameters ? window.clientParameters.flavors : [],
                id: client_id,
                name: window.clientParameters ? window.clientParameters.name : 'eCarra',
                logos: window.clientParameters ? window.clientParameters.logos : null,
                parameters: window.clientParameters
            }),
            flavors: {
                enabled: key => {
                    return window.client_id === 'ecarra' ? true : window.clientParameters.flavors && window.clientParameters.flavors.includes(key);
                }
            },
            refresh: async () => {
                return new Promise(async (resolve, reject) => {
                    try {
                        let appParams = await Utils.getSaaSParameters(utils, client_id, true);
                        window.clientParameters = SaasAccount.create(appParams);
                        setWorkspaces(workspaces => {
                            return [ ...workspaces ]; // trigger a re-render
                        });
                        resolve(appParams);
                    } catch(e) {
                        reject(e)
                    }
                })
            }
        },
        content: ContentManager,
        datePicker: {
            show: props => setDatePicker({
                id: `${moment().unix()}-${Math.random()}`,
                ...props
            }),
            showDualPicker: props => {
                utils.layer.open({
                    id: 'dual-date-picker-alert',
                    Component: DualDatePicker.bind(this, props)
                });
            }
        },
        events: {
            off: (key, callback) => {
                window.removeEventListener(key, callback);
            },
            on: (key, callback) => {
                window.addEventListener(key, callback);
            },
            emit: (key, props = {}) => {
                let evt = new CustomEvent(key, { detail: props });
                dispatchEvent(evt);
            }
        },
        fetching: {
            get: key => {
                return fetching.current[key];
            },
            set: (key, value) => {
                fetching.current[key] = value;
            }
        },
        layer: {
            close: onCloseLayer,
            open: onOpenLayer
        },
        loader: {
            show: async () => {
                return new Promise((resolve, reject) => {
                    setLoader(true);
                    setTimeout(() => {
                        resolve();
                    }, 500)
                })
            },
            hide: async () => {
                return new Promise((resolve, reject) => {
                    setLoader(false);
                    utils.events.on('load_complete', resolve);
                });
            }
        },
        location: {
            request: onRequestLocation
        },
        notification: {
            show: props => {
                ContentManager.fetch('notifications');
                NotificationCenter.notify(utils, props);
                setNotification(props);
            }
        },
        onAssistClick: onAssistClick,
        panel: {
            new: onAddNewPanel
        },
        security: {
            setRequiredLevels: setRequiredLevels
        },
        sheet: {
            show: (sheet, callback) => {
                setSheet({
                    ...sheet,
                    onClick: callback
                })
            }
        },
        sockets: SocketManager,
        stripe: StripeProps,
        structure: {
            navigation: {
                set: onSetNavigation
            }
        },
        timezone: {
            get: () => moment.tz.guess()
        },
        user: {
            get: () => userRef.current,
            set: props => {
                setUser(props);
                ContentManager.update({
                    type: 'users',
                    object: props
                });
            }
        },
        workspace: {
            new: onNewWorkspace,
            open: onOpenWorkspace,
            load: onLoadWorkspace,
            rename: onRenameWorkspace,
            delete: onDeleteWorkSpace,
            export: onExportWorkspace,
            exportAll: onExportAllWorkspaces
        }
    }

    useEffect(() => {
        userRef.current = user;
    }, [user]);

    useEffect(() => {

        // fetch saas client props
        fetchClient();

        // setup scroll polyfill and window listeners
        smoothscroll.polyfill();
        window.addEventListener('resize', onWindowSizeChange);
        window.addEventListener('beforeunload', (e) => {
            (e || window.event).returnValue = null;
            return null;
        });

        // css variables
        document.body.style.overflowY = 'scroll';
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());

        // theme and theme listeners
        window.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.style.setProperty('--theme', window.theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onUpdateTheme);
        onSetStyleSheetProperties();
        setLogin({ opacity: 1 });

        // setup history state
        window.history.pushState(null, 'Seeds: Powered by eCarra', `${API.dev_env ? '' : '/seeds'}/index.html${window.location.search}`)
        window.addEventListener('beforeunload', (e) => {
            (e || window.event).returnValue = null;
            return null;
        });
        window.onpopstate = e => {

            let { sub_view, view } = QueryString.parse(window.location.search);
            setWorkspaces(workspaces => {

                if(!workspaces[activeWorkspace].panels[view]) {
                    console.log('no view');
                    return workspaces;
                }
                if(sub_view && !workspaces[activeWorkspace].panels[view].subViews[sub_view]) {
                    console.log('no subview');
                    return workspaces;
                }

                return update(workspaces, {
                    [activeWorkspace]: {
                        active: {
                            view: {
                                $set: view
                            },
                            subView: {
                                $set: sub_view
                            }
                        }
                    }
                })
            })
        }

    }, []);

    return getAppContent()
}

export default App;
