import React from 'react';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import ProgressBar from 'views/ProgressBar.js';
import Utils from 'files/Utils.js';

const PageControl = ({ description, limit, loading, offset, onClick }) => {

    const onPageClick = direction => {

        // determine next page offset using supplied direction
        let nextOffset = 0;
        switch(direction) {
             case 'next':
             nextOffset = offset + limit;
             break;

             case 'back':
             nextOffset = offset - limit;
             break;

             default:
             // prevent moving forward if next offset === current offset
             if(direction === description.current_page) {
                 return;
             }
             // navigate to specific page if applicable
             if(!isNaN(direction)) {
                 nextOffset = (direction - 1) * limit;
             }
        }

        // notify subscribers of offset change
        if(typeof(onClick) === 'function') {
            onClick(nextOffset < 0 ? 0 : nextOffset);
        }
    }

    const getContent = () => {

        if(!description) {
            return null;
        }

        let { current_page, number_of_pages } = description;
        if(number_of_pages <= 1) {
            return null;
        }

        if(Utils.isMobile()) {
            return (
                <div style={{
                    padding: 10,
                    borderTop: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div className={'row p-0 m-0'}>
                        <div className={'col-6 p-0 pr-1'}>
                            <Button
                            label={'Back'}
                            type={'large'}
                            color={current_page === 1 ? 'grey' : 'secondary'}
                            onClick={current_page === 1 ? 0 : 1 ? onPageClick.bind(this, 'back') : null} />
                        </div>
                        <div className={'col-6 p-0 pl-1'}>
                            <Button
                            label={'Next'}
                            type={'large'}
                            color={current_page !== number_of_pages ? 'primary' : 'grey'}
                            onClick={current_page !== number_of_pages ? onPageClick.bind(this, 'next') : null} />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div style={{
                position: 'relative',
                padding: 10,
                borderTop: `1px solid ${Appearance.colors.divider()}`
            }}>
                {loading && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        overflow: 'hidden',
                        borderRadius: 2,
                        height: 2
                    }}>
                        <ProgressBar/>
                    </div>
                )}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img
                    title={'Back to the Beginning'}
                    src={'images/back-double-arrow-light-grey-small.png'}
                    onClick={onPageClick.bind(this, 0)}
                    {...current_page !== 1 && {
                        className: 'image-button',
                        onClick: onPageClick.bind(this, 0)
                    }}
                    style={{
                        marginRight: 4,
                        opacity: current_page === 1 ? 0 : 0.5,
                        width: 20,
                        height: 15,
                        objectFit: 'contain'
                    }}/>
                    <img
                    title={'Previous Page'}
                    src={'images/back-arrow-light-grey-small.png'}
                    {...current_page !== 1 && {
                        className: 'image-button',
                        onClick: onPageClick.bind(this, 'back')
                    }}
                    style={{
                        marginRight: 8,
                        opacity: current_page === 1 ? 0 : 1,
                        width: 15,
                        height: 15,
                        objectFit: 'contain'
                    }}/>
                    {[...Array(number_of_pages < 5 ? number_of_pages : 5)].map((e, index) => {

                        let pageNumber = current_page > 3 ? ((index + 1) + current_page) - 3 : (index + 1);
                        let highlighted = current_page > 3 ? (index === 2) : ((index + 1) === current_page);
                        if(pageNumber > number_of_pages) {
                            return null;
                        }

                        return (
                            <div
                            key={pageNumber}
                            className={'image-button'}
                            onClick={onPageClick.bind(this, pageNumber)}
                            style={{
                                padding: '0px 4px 0px 4px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    minWidth: 20,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    marginTop: 3
                                }}>
                                    <span
                                    key={pageNumber}
                                    style={{
                                        ...Appearance.textStyles.title(),
                                        display: 'block',
                                        width: '100%',
                                        padding: pageNumber > 9 ? '0px 7px 0px 7px':'0px'
                                    }}>{pageNumber}</span>
                                    <div style={{
                                        width: '100%',
                                        height: 3,
                                        borderRadius: 1.5,
                                        overflow: 'hidden',
                                        backgroundColor: highlighted ? Appearance.colors.primary() : Appearance.colors.transparent
                                    }} />
                                </div>
                            </div>
                        )
                    })}
                    <img
                    title={'Next Page'}
                    src={'images/next-arrow-light-grey-small.png'}
                    {...current_page !== number_of_pages && {
                        className: 'image-button',
                        onClick: onPageClick.bind(this, 'next')
                    }}
                    style={{
                        marginLeft: 8,
                        opacity: current_page !== number_of_pages ? 1:0,
                        width: 15,
                        height: 15,
                        objectFit: 'contain'
                    }}/>
                    <img
                    title={'Very Last Page'}
                    src={'images/next-double-arrow-light-grey-small.png'}
                    {...current_page !== number_of_pages && {
                        className: 'image-button',
                        onClick: onPageClick.bind(this, number_of_pages)
                    }}
                    style={{
                        marginLeft: 4,
                        opacity: current_page !== number_of_pages ? 0.5 : 0,
                        width: 20,
                        height: 15,
                        objectFit: 'contain'
                    }}/>
                </div>
            </div>
        )
    }

    return getContent();
}

export default PageControl;
