import React, { useEffect, useRef, useState } from 'react';

import { animated, useSpring } from 'react-spring';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Cookies from 'js-cookie';
import LottieView from 'views/Lottie.js';
import Order from 'classes/Order.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import QueryString from 'query-string';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const Login = ({ onLogin, utils }) => {

    const credentials = useRef({});
    const [loading, setLoading] = useState(false);
    const [logoAnimation, setLogoAnimation] = useState(null);
    const [password, setPassword] = useState(null);
    const [username, setUsername] = useState(null);

    const [login, setLogin] = useState({
        scale: 0.75,
        opacity: 0
    });

    const [loginCredentials, setLoginCredentials] = useSpring(() => ({
        opacity: 1,
        maxHeight: 250,
        transform: 'scale(1)',
        config: { mass: 1, tension: 180, friction: 12 }
    }));

    const onAppStoreClick = () => {
        window.open(API.apps.ios);
    }

    const onGooglePlayClick = () => {
        window.open(API.apps.android);
    }

    const onResetPasswordClick = () => {
        window.open(`${API.server}/users/reset-password/index.html?version=${API.version}&app_id=${utils.client.get().id}&nonce=${moment().unix()}`);
    }

    const onSetupClientIcon = () => {
        setLogoAnimation(Utils.getClientLottieLogo(utils));
    }

    const onStartLogin = async () => {

        // verify the username and password have been provided
        let { password, username } = credentials.current;
        if(!username || !password) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please enter your username and password to continue'
            })
            return;
        }

        try {
            setLoading(true);
            let user = await Request.post(utils, '/user/', {
                type: 'login',
                ...credentials.current
            });

            // prevent non-eligible accounts from moving forward
            if(!(user.level <= User.level.admin || user.level >= User.level.company_admin)) {
                await utils.loader.hide();
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'It looks like your account is not able to view this information. Please download the eCarra app for iOS and Android to access your account'
                });
                return;
            }

            // animate login out of view
            await Utils.sleep(1);
            setLoading(false);
            setLogin(login => update(login, {
                scale: {
                    $set: 0.75
                },
                opacity: {
                    $set: 0
                }
            }))

            // send account details back to root view
            await utils.loader.show();
            onLogin({ user: User.create({ ...user, password: password }) });

        } catch(e) {
            setLoading(false);
            utils.loader.hide();
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue logging into your account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getContent = () => {
        return (
            <animated.div style={{
                width: '100%',
                ...loginCredentials
            }}>
                <div style={{
                    ...Appearance.styles.panel(),
                    padding: '12px 20px 15px 20px'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <span style={{
                            fontSize: 22,
                            fontWeight: 800,
                            color: Appearance.colors.text(),
                            lineHeight: 1.5
                        }}>{getTitle()}</span>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.subText(),
                            lineHeight: 1.1,
                            marginBottom: 20
                        }}>{'Mobility Powered by eCarra'}</span>
                    </div>
                    <TextField
                    icon={'user'}
                    autoCapitalize={'none'}
                    placeholder={'Username'}
                    value={username}
                    useDelay={false}
                    onChange={text => setUsername(text)}
                    fieldStyle={{
                        color: Appearance.colors.text(),
                        fontSize: 12,
                        fontWeight: '500',
                    }}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width:'100%',
                        height: 25,
                        marginTop: 10,
                        marginBottom: 20
                    }}>
                        <TextField
                        icon={'lock'}
                        isSecure={true}
                        placeholder={'Password'}
                        value={password}
                        useDelay={false}
                        onChange={text => setPassword(text)}
                        fieldStyle={{
                            color: Appearance.colors.text(),
                            fontSize: 12,
                            fontWeight: '500'
                        }}
                        containerStyle={{
                            paddingRight: 10,
                            width: '100%'
                        }} />

                        <img
                        className={'image-button'}
                        onClick={onResetPasswordClick}
                        src={'images/help-button-grey.png'}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain',
                            boxShadow: window.theme === 'dark' ? '2.5px 2.5px 7.5px rgba(0,0,0,0.25), -2.5px -2.5px 5px rgba(255,255,255,0.15)' : '2.5px 2.5px 7.5px rgba(0,0,0,0.075), -2.5px -2.5px 5px rgba(255,255,255,0.35)',
                            borderRadius: 12.5,
                            marginLeft: 8
                        }}/>
                    </div>
                    <Button
                    label={'Login'}
                    type={'transition'}
                    color={'primary'}
                    loading={loading}
                    onClick={onStartLogin}
                    transition={{
                        icon: 'key-icon-white.png'
                    }}/>
                </div>
            </animated.div>
        )
    }

    const getTitle = () => {
        if(window.client_id === 'ecarra') {
            return 'Seeds';
        }
        return `Seeds for ${utils.client.get().name}`;
    }

    useEffect(() => {
        credentials.current = {
            username: username,
            password: password
        }
    }, [username, password]);

    useEffect(() => {
        onSetupClientIcon();
        setLogin(login => update(login, {
            scale: {
                $set: 1
            },
            opacity: {
                $set: 1
            }
        }));
        if(API.dev_env === true) {
            setTimeout(() => {
                credentials.current = {
                    username: 'mikecarp',
                    password: 'redhouse10'
                }
                onStartLogin();
            }, 250);
        }
    }, []);

    return (
        <VelocityComponent
        easing={[250, 20]}
        duration={750}
        animation={{
            scale: login.scale,
            opacity: login.opacity
        }}>
            <div style={{
                width: '100%',
                maxWidth: 450
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    padding: 15,
                    marginBottom: 30,
                    textAlign: 'center'
                }}>
                    {logoAnimation && (
                        <LottieView
                        loop={true}
                        autoPlay={true}
                        source={logoAnimation}
                        style={{
                            width: 250,
                            height: 250
                        }}/>
                    )}
                    {getContent()}
                </div>
            </div>
        </VelocityComponent>
    )
}

export default Login;
