import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Company from 'classes/Company.js';
import Note from 'classes/Note.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class VehicleClass {

    id = null;
	name = null;
	information = null;
    address = null;
    active = null;
	vin = null;
    mileage = null;
    customer = null;
    company = null;
    plate = null;
    color = null;
    year = null;
    location = null;
    driver = null;
    seeds = null;
    deep_link = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = parseInt(props.id);
        this.image = props.image;
        this.vin = props.vin;
        this.plate = props.plate;
        this.color = props.color;
        this.year = props.year;
        this.mileage = props.mileage;
        this.active = Boolean(props.active);
        this.deep_link = props.deep_link;
        this.location = props.location && {
            latitude: parseFloat(props.location.lat),
            longitude: parseFloat(props.location.long)
        };
        this.driver = props.driver ? User.create(props.driver) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.category = props.category ? new VehicleCategoryClass().create(props.category) : null;

        if(props.seeds) {
            this.seeds = props.seeds;

            var notes = [];
            if(props.seeds.notes) {
                for(var i in props.seeds.notes) {
                    if(props.seeds.notes[i].deleted) {
                        continue;
                    }
                    let note = Note.create(props.seeds.notes[i]);
                    notes.push(note);
                }
                this.seeds.notes = notes;
            }
        }
        return this;
    }

    hasLocation = () => {
        return (this.location && this.location.latitude && this.location.longitude) ? true:false;
    }

    getEntryValues = () => {
        if(this.customer) {
            return {
                title: `${this.customer.full_name}'s ${this.category.name}`,
                icon: { path: this.customer.avatar }
            };
        }
        if(this.company) {
            return {
                title: `${this.company.name}'s ${this.category.name}`,
                icon: { path: this.company.image }
            };
        }
        return {
            title: this.category.name,
            icon: {
                path: `/images/vehicle-icon-${window.theme}.png`,
                ...Appearance.icons.padded()
            }
        }
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            category: this.category,
            name: this.name,
            information: this.information,
            image: this.image,
            customer: this.customer,
            company: this.company,
            vin: this.vin,
            mileage: this.mileage,
            plate: this.plate,
            color: this.color,
            year: this.year
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            category: props.category || this.edits.category,
            name: props.name || this.edits.name,
            information: props.information || this.edits.information,
            customer: props.customer !== undefined ? props.customer : this.edits.customer,
            company: props.company !== undefined ? props.company : this.edits.company,
            vin: props.vin || this.edits.vin,
            mileage: props.mileage || this.edits.mileage,
            plate: props.plate || this.edits.plate,
            color: props.color || this.edits.color,
            year: props.year || this.edits.year
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/vehicle/', {
                    type: 'new',
                    category: edits.category ? edits.category.id : null,
                    name: edits.name,
                    information: edits.information,
                    customer: edits.customer ? edits.customer.user_id : null,
                    company: edits.company ? edits.company.id : null,
                    vin: edits.vin,
                    mileage: edits.mileage,
                    plate: edits.plate,
                    color: edits.color,
                    year: edits.year
                });

                this.id = id;
                this.category = edits.category;
                this.name = edits.name;
                this.information = edits.information;
                this.customer = edits.customer;
                this.company = edits.company;
                this.vin = edits.vin;
                this.mileage = edits.mileage;
                this.plate = edits.plate;
                this.colors = edits.color;
                this.year = edits.year;

                utils.content.fetch('vehicles');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/vehicle/', {
                    type: 'update',
                    id: this.id,
                    category: edits.category ? edits.category.id : null,
                    name: edits.name,
                    information: edits.information,
                    customer: edits.customer ? edits.customer.user_id : null,
                    company: edits.company ? edits.company.id : null,
                    vin: edits.vin,
                    mileage: edits.mileage,
                    plate: edits.plate,
                    color: edits.color,
                    year: edits.year
                });

                this.category = edits.category;
                this.name = edits.name;
                this.information = edits.information;
                this.customer = edits.customer;
                this.company = edits.company;
                this.vin = edits.vin;
                this.mileage = edits.mileage;
                this.plate = edits.plate;
                this.colors = edits.color;
                this.year = edits.year;

                utils.content.update({
                    type: 'vehicles',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class VehicleApplicationClass {

    id = null;
    user_id = null;
    customer = null;
    company = null;
    contact = null;
    date = null;
    application = null;
    images = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user_id = props.user_id;
        this.contact = props.contact;
        this.date = props.date ? moment(props.date) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.application = props.application;
        this.images = props.images;
        this.status = props.status ? formatStatus(props.status.code) : null;
        return this;
    }
}

class VehicleCategoryClass {

    active = false;
	base_rate = 0.0;
    drive_experiences = null;
    duration_rate = 0.0;
    hourly_rate = 0.0;
    id = null;
	information = null;
    is_default = false;
    make = null;
    model = null;
	name = null;
    rate = 0.0;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.base_rate = props.base_rate || props.base_rate || 0;
        this.drive_experiences = props.drive_experiences;
        this.duration_rate = props.duration_rate || props.duration_rate || 0;
        this.hourly_rate = props.hourly_rate || props.hourly_rate || 0;
        this.id = parseInt(props.id);
        this.information = props.information;
        this.is_default = props.is_default;
        this.make = props.make;
        this.model = props.model;
        this.name = props.name;
        this.rate = props.rate || 0;
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            base_rate: this.base_rate,
            drive_experiences: this.drive_experiences,
            duration_rate: this.duration_rate,
            hourly_rate: this.hourly_rate,
            information: this.information,
            is_default: this.is_default,
            make: this.make,
            model: this.model,
            name: this.name,
            rate: this.rate
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { drive_experiences, id } = await Request.post(utils, '/vehicle/', {
                    base_rate: edits.base_rate,
                    drive_experiences: edits.drive_experiences,
                    duration_rate: edits.duration_rate,
                    hourly_rate: edits.hourly_rate,
                    information: edits.information,
                    is_default: edits.is_default,
                    make: edits.make,
                    model: edits.model,
                    name: edits.name,
                    rate: edits.rate,
                    type: 'new_category'
                });

                this.base_rate = edits.base_rate;
                this.drive_experiences = drive_experiences;
                this.duration_rate = edits.duration_rate;
                this.name = edits.name;
                this.hourly_rate = edits.hourly_rate;
                this.id = id;
                this.information = edits.information;
                this.is_default = edits.is_default;
                this.make = edits.make;
                this.model = edits.model;
                this.rate = edits.rate;
                this.is_default = edits.is_default;

                utils.content.fetch('vehicleCategories');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { drive_experiences } = await Request.post(utils, '/vehicle/', {
                    base_rate: edits.base_rate,
                    drive_experiences: edits.drive_experiences,
                    duration_rate: edits.duration_rate,
                    hourly_rate: edits.hourly_rate,
                    id: this.id,
                    information: edits.information,
                    is_default: edits.is_default,
                    make: edits.make,
                    model: edits.model,
                    name: edits.name,
                    rate: edits.rate,
                    type: 'update_category'
                });

                // notify listeners to fetch all categories if default value has changed
                if(edits.is_default !== this.is_default) {
                    utils.content.fetch('vehicleCategories');
                }

                this.base_rate = edits.base_rate;
                this.drive_experiences = drive_experiences;
                this.duration_rate = edits.duration_rate;
                this.name = edits.name;
                this.hourly_rate = edits.hourly_rate;
                this.information = edits.information;
                this.is_default = edits.is_default;
                this.make = edits.make;
                this.model = edits.model;
                this.rate = edits.rate;

                utils.content.update({
                    type: 'vehicleCategories',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const statusCodes = {
    pending: null,
    approved: 1,
    rejected: 2,
    cancelled: 3,
    infoNeeded: 4
}

const formatStatus = (c) => {

    let code = c === null ? c : parseInt(c);
    let status = {
        code: code
    };

    if(code === statusCodes.rejected) {
        status.text = 'Rejected';
        status.realText = 'Rejected';
        status.color = Appearance.colors.red;

    } else if(code === statusCodes.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance.colors.primary();

    } else if(code === statusCodes.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';

    } else if(code === statusCodes.infoNeeded) {
        status.text = 'Information Needed';
        status.realText = 'Information Needed';
        status.color = Appearance.colors.grey();

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = Appearance.colors.grey();
    }
    return status;
}

const fetchCategory = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { category } = await Request.get(utils, '/vehicle/', {
                type: 'category_details',
                id: id
            });
            let result = new VehicleCategoryClass().create(category);
            resolve(result);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchVehicle = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { vehicle } = await Request.get(utils, '/vehicle/', {
                type: 'details',
                id: id
            });
            let result = new VehicleClass().create(vehicle);
            resolve(result);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new VehicleClass(),
    get: fetchVehicle,
    create: props => new VehicleClass().create(props),
    Application: {
        new: () => new VehicleApplicationClass(),
        create: props => new VehicleApplicationClass().create(props),
        formatStatus: formatStatus,
        status: statusCodes,
    },
    Category: {
        new: () => new VehicleCategoryClass(),
        get: fetchCategory,
        create: props => new VehicleCategoryClass().create(props)
    }
}
