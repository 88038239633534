import moment from 'moment-timezone';
import Request from 'files/Request.js';

class MoodClass {

    id = null;
    title = null;
    information = null;
    image = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.information = props.information;
        this.image = props.image + '?v=' + moment().unix();
        this.active = Boolean(props.active);
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            information: this.information,
            image: this.image
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            title: props.title || this.edits.title,
            information: props.information || this.edits.information,
            image: props.image || this.edits.image
        }
        if(props.image) {
            this.edits.tmp_image = props.image;
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/resources/', {
                    type: 'new_mood',
                    title: edits.title,
                    information: edits.information,
                    image: edits.tmp_image
                });

                this.id = id;
                this.title = edits.title;
                this.information = edits.information;
                this.image = edits.tmp_image ? edits.tmp_image.data : this.image;

                utils.content.fetch('moods');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/resources/', {
                    type: 'update_mood',
                    id: this.id,
                    title: edits.title,
                    information: edits.information,
                    image: edits.tmp_image
                });

                this.title = edits.title;
                this.information = edits.information;
                this.image = edits.tmp_image ? edits.tmp_image.data : this.image;

                utils.content.update({
                    type: 'moods',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new MoodClass(),
    create: props => new MoodClass().create(props)
}
