import React, { useState } from 'react';
import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

const ColorPicker = ( { color, onChange, style } ) => {

    const [selectedColor, setSelectedColor] = useState(color ? color.toLowerCase() : null);

    const colors = [{
        key: 'pink',
        value: '#E676B4'
    },{
        key: 'red',
        value: '#F45D4C'
    },{
        key: 'orange',
        value: '#F4984C'
    },{
        key: 'yellow',
        value: '#F7B500'
    },{
        key: 'green',
        value: '#6CBF66'
    },{
        key: 'blue',
        value: '#4A90E2'
    },{
        key: 'purple',
        value: '#8F72F7'
    },{
        key: 'brown',
        value: '#845333'
    },{
        key: 'silver',
        value: '#9B9B9B'
    },{
        key: 'grey',
        value: '#4A4A4A'
    },{
        key: 'black',
        value: '#000000'
    },{
        key: 'white',
        value: '#F0F0F0'
    }]

    const setColor = color => {
        setSelectedColor(color.key)
        if(typeof(onChange) === 'function') {
            onChange(Utils.ucFirst(color.key));
        }
    }

    return (
        <div
        key={'color-picker-container'}
        style={{
            ...style
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                flexWrap: 'wrap'
            }}>
                {colors.map((color, index) => {
                    return (
                        <div
                        key={color.key}
                        className={'text-button'}
                        onClick={setColor.bind(this, color)}
                        style={{
                            width: 35,
                            height: 35,
                            minWidth: 35,
                            minHeight: 35,
                            borderRadius: 5,
                            overflow: 'hidden',
                            padding: selectedColor === color.key ? 2 : 0,
                            border: selectedColor === color.key ? `2px solid ${Appearance.colors.softBorder()}` : `1px solid ${Appearance.colors.divider()}`,
                            marginRight: index !== colors.length - 1 ? 4 : 0,
                            marginLeft: index ? 0 : 4
                        }}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 3,
                                background: `linear-gradient(45deg, ${Utils.hexToRGBA(color.value, 0.85)}, ${Utils.hexToRGBA(color.value, 0.7)})`
                            }} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
};
export default ColorPicker;
