import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';
import Utils from 'files/Utils.js';

const AddressLookupField = ({ containerStyle, icon, inline, onChange, onRenderResult, placeholder, preserveResult, removable, showValidation, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [session, setSession] = useState(null);
    const [textTimeout, setTextTimeout] = useState(false);
    const [text, setText] = useState('');

    const onRemoveSelectedLocation = () => {
        setText('');
        setSelected(null);
        if(typeof(onChange) === 'function') {
            onChange(null);
        }
    }

    const onSelectResult = async place => {

        // hide results list and update selected place
        setResults([]);
        setSelected(place);
        setText(place.address);

        // remove result from field if applicable
        if(preserveResult === false) {
            setText('');
            setSelected(null);
        }

        try {
            setLoading(true);
            let { result } = await Utils.geocode(utils, place);

            // session must be reset when a selection has been made
            setSession(uuidv4());

            setLoading(false);
            if(typeof(onChange) === 'function') {
                onChange({
                    ...place,
                    ...result,
                    id: place.place_id || `${Utils.randomString()}_${moment().unix()}`,
                    location: result && result.location && {
                        latitude: result.location.lat,
                        longitude: result.location.long
                    }
                });
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue locating the information for this address. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onTextChange = text => {

        if(textTimeout) {
            clearTimeout(textTimeout);
        }
        setText(text);
        setLoading(true);
        setSelected(false);

        setTextTimeout(async () => {
            try {
                await Utils.sleep(0.25);
                if(text.length < 3) {
                    setResults([]);
                    setLoading(false);
                    return;
                }
                let results = await Utils.addressLookup(utils, text, session);
                setLoading(false);
                setResults(results || []);

            } catch(e) {
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue searching for your location. ${e.message || 'An unknown error occurred'}`
                });
            }

        });
    }

    const onValidate = () => {
        return selected ? true : false
    }

    const getStyles = () => {
        if(inline !== true) {
            return {
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden',
                marginTop: 8
            }
        }
        return {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 43,
            backgroundColor: Appearance.colors.panelBackground(),
            border: `1px solid ${Appearance.colors.softBorder()}`,
            borderRadius: 8,
            boxShadow: '0 0.46875rem 1.1875rem rgba(0, 0, 0, 0.1)',
            zIndex: 1100,
            overflow: 'hidden'
        }
    }

    useEffect(() => {
        if(value && typeof(value) === 'object') {
            setSelected(value);
            setText(Utils.formatAddress(value));
            return;
        }
        setText(value);
        setSelected(null);
    }, [value]);

    useEffect(() => {
        setSession(uuidv4());
    }, [])

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            ...containerStyle
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
            }}>
                <TextField
                value={text}
                useDelay={true}
                icon={icon || 'location'}
                loading={loading}
                onValidate={showValidation ? onValidate : null}
                placeholder={placeholder}
                autoComplete={false}
                autoCorrect={false}
                autoCapitalize={'sentences'}
                onChange={onTextChange}
                style={{
                    flexGrow: 1,
                    ...Appearance.textStyles.title()
                }}/>
                {selected && removable !== false && (
                    <img
                    className={'text-button'}
                    src={'images/close-button-light-small.png'}
                    onClick={onRemoveSelectedLocation}
                    style={{
                        width: 25,
                        height: 25,
                        marginLeft: 8
                    }} />
                )}
            </div>
            {results.length > 0 && (
                <div style={getStyles()}>
                    {results.map((location, index) => {
                        if(typeof(onRenderResult) === 'function') {
                            return onRenderResult(location, index, results);
                        }
                        return (
                            Views.entry({
                                key: index,
                                title: location.name,
                                subTitle: location.address,
                                hideIcon: true,
                                onClick: onSelectResult.bind(this, location),
                                bottomBorder: index !== results.length - 1
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default AddressLookupField;
