import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import ImagePickerField from 'views/ImagePickerField.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem, LayerNote } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import News from 'classes/News.js';
import NoDataFound from 'views/NoDataFound.js';
import { NoteEntry } from 'views/NotesManager.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views from 'views/Main.js';

export const fetchNewsChannels = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { channels, download_props, paging } = await Request.get(utils, '/news/',  {
                type: 'channels',
                ...props
            });
            if(download_props) {
                resolve(download_props);
                return;
            }
            resolve({
                paging: paging,
                channels: channels.map(channel => News.Channel.create(channel))
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchNewsCategories = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/news/',  {
                type: 'categories_admin',
                ...props
            });
            if(props.download) {
                resolve(response);
                return;
            }
            let { categories, paging } = response;
            resolve({
                paging: paging,
                categories: categories.map(category => News.Category.create(category))
            })
        } catch(e) {
            reject(e);
        }
    })
}

// panels
export const NewsActivity = ({ channel }, { index, utils }) => {

    const panelID = `news${channel.id}Activity`;
    const chartRef = useRef(null);

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(3, 'months') });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `${channel.name} Activity`,
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/news/', {
                    type: 'activity',
                    news_channel: channel.id,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: `This graph shows the credits activity for all ${channel.name} views. The left side is the amount of views and the bottom is day those views were recorded. The graph shows the last 3 months by default`,
            items: [{
                key: 'download',
                title: 'Download Activity',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                padding: 12,
                borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
            }}>
                <DualDatePickerField
                utils={utils}
                selectedStartDate={dates.start_date}
                selectedEndDate={dates.end_date}
                style={{
                    maxWidth: Utils.isMobile() === false ? 350 : null
                }}
                onStartDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            start_date: date
                        }
                    })
                }}
                onEndDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            end_date: date
                        }
                    })
                }} />
            </div>
            <div style={{
                position: 'relative',
                height: 350,
                padding: 20
            }}>
                {!chart || chart.data.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of views are needed for the graph'}/>
                    :
                    <Line
                    ref={chartRef}
                    width={500}
                    height={100}
                    data={{
                        labels: chart.labels,
                        datasets: [{
                            data: chart.data,
                            borderColor: Appearance.colors.primary(),
                            fill: true,
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                            pointRadius: 5,
                            pointBorderWidth: 2,
                            pointBackgroundColor: 'white'
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }} />
                }
            </div>
            <div
            className={'row m-0'}
            style={{
                padding: 12
            }}>
                {chart && chart.items.map((item, index) => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-md-4 p-1 text-center'}>
                            <div style={{
                                ...Appearance.styles.unstyledPanel(),
                                padding: 8
                            }}>
                                <img
                                src={`/images/${item.image}`}
                                className={'my-1'}
                                style={{
                                    ...Appearance.icons.overview(),
                                    backgroundColor: index === 1 ? Appearance.colors.secondary() : Appearance.colors.primary()
                                }} />
                                <span
                                className={'d-block mt-2'}
                                style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                <span
                                className={'d-block'}
                                style={Appearance.textStyles.subTitle()}>{item.title}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const fetchBreakdown = async () => {
        try {
            let { news } = await Request.get(utils, '/news/', {
                end_date: moment(dates.end_date).utc().unix(),
                news_channel: channel.id,
                start_date: moment(dates.start_date).utc().unix(),
                type: 'activity'
            });

            setLoading(false);
            let highestVal = news.reduce((object, entry) => {
                return !object.date || object.total < entry.total ? entry : object;
            }, {});

            setChart({
                data: news.map(entry => entry.total),
                labels: news.map(entry => moment(entry.date).format('MMM Do')),
                items: [{
                    key: 'credits',
                    title: 'Total Views',
                    placeholder: '0',
                    value: news.reduce((total, entry) => total += entry.total, 0),
                    image: 'promo-code-large-clear.png'
                },{
                    key: 'highest',
                    title: 'Highest Daily Views',
                    placeholder: 'Not Available',
                    value: highestVal ? highestVal.total : null,
                    image: 'discounts-given-clear.png'
                },{
                    key: 'popular',
                    title: 'Highest Viewing Day',
                    placeholder: 'Not Available',
                    value: highestVal ? moment(highestVal.date).format('MMMM Do') : null,
                    image: 'calendar-increase-clear.png'
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the news item activity breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdown();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${channel.name} Activity`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const NewsCategories = ({ channel }, { index, utils }) => {

    const panelID = `news${channel.id}Categories`;
    const limit = 5;

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `Categories for ${channel.name}`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onCategoryClick = category => {
        utils.layer.open({
            id: `news-category-details-${category.id}`,
            abstract: Abstract.create({
                type: 'newsCategories',
                object: category
            }),
            Component: NewsCategoryDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchNewsCategories(utils, {
                    news_channel: channel.id,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewCategory = () => {
        let category = News.Category.new();
        category.channel = channel;
        utils.layer.open({
            id: 'new-news-category',
            abstract: Abstract.create({
                type: 'newsCategories',
                object: category
            }),
            Component: AddEditNewsCategory.bind(this, {
                isNewTarget: true,
                channel: channel
            })
        })
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all the categories for ${channel.name} that are available when exploring news items. A ${channel.name} category is a collection of ${channel.name} items that are available to explore`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Categories'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(categories.length === 0) {
            return (
                Views.entry({
                    title: `No ${channel.name} Options Found`,
                    subTitle: `There are no ${channel.name} options available to view`,
                    borderBottom: false
                })
            )
        }
        return categories.map((category, index) => {
            return (
                Views.entry({
                    key: category.id,
                    title: category.title,
                    subTitle: category.items && category.items.length > 0 ? `${category.items.length} ${channel.name} ${category.items.length === 1 ? 'Item' : 'Items'}` : `No ${channel.name} Items have been added`,
                    badge: !category.active && {
                        text: 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    bottomBorder: index !== categories.length - 1,
                    onClick: onCategoryClick.bind(this, category)
                })
            )
        });
    }

    const fetchCategories = async () => {
        try {
            setLoading(true);
            let { categories, paging } = await fetchNewsCategories(utils, {
                limit: limit,
                news_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCategories(categories);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the news categories. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['newsCategories', 'newsItems'], {
            onFetch: fetchCategories,
            onRemove: abstract => {
                setCategories(categories => {
                    return categories.filter(category => category.id !== abstract.getID());
                })
            },
            onUpdate: abstract => {
                setCategories(categories => {
                    return categories.map(category => abstract.compare(category));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID)
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Categories for ${channel.name}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Category',
                style: 'default',
                onClick: onNewCategory
            }],
            search: {
                placeholder: 'Search by category name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const NewsItems = ({ channel }, { index, utils }) => {

    const panelID = `news${channel.id}Items`;
    const limit = 5;

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `Items for ${channel.name}`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/news/',  {
                    type: 'items_admin',
                    news_channel: channel.id,
                    ...props,
                    ...manager
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onItemClick = item => {
        utils.layer.open({
            id: `news-item-details-${item.id}`,
            abstract: Abstract.create({
                type: 'newsItems',
                object: item
            }),
            Component: NewsItemDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onNewItem = () => {
        let item = News.Item.new();
        item.channel = channel;
        utils.layer.open({
            id: 'new-news-item',
            abstract: Abstract.create({
                type: 'newsItems',
                object: item
            }),
            Component: AddEditNewsItem.bind(this, {
                isNewTarget: true,
                channel: channel
            })
        })
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all the news items for ${channel.name} that are available to explore. A ${channel.name} item normally holds a name, description, and either a url to an article or a piece of copy to read.`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : `${channel.name} Articles`}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(items.length === 0) {
            return (
                Views.entry({
                    title: `No ${channel.name} Items Found`,
                    subTitle: `There are no ${channel.name} items available to view`,
                    bottomBorder: false
                })
            )
        }
        return items.map((item, index) => {
            return (
                Views.entry({
                    key: item.id,
                    title: item.title,
                    subTitle: item.description,
                    icon: {
                        path: item.image || 'images/news-category-icon.png'
                    },
                    badge: [{
                        text: item.channel ? item.channel.title : null,
                        color: Appearance.colors.grey()
                    },{
                        text: item.featured ? 'Featured' : null,
                        color: Appearance.colors.primary()
                    }],
                    bottomBorder: index !== items.length - 1,
                    onClick: onItemClick.bind(this, item)
                })
            )
        })
    }

    const fetchItems = async download => {
        try {
            let { items, paging } = await Request.get(utils, '/news/',  {
                type: 'items_admin',
                limit: limit,
                news_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setItems(items.map(item => News.Item.create(item)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the new items list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchItems();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'newsItems', {
            onFetch: fetchItems,
            onRemove: abstract => {
                setItems(items => update(items, {
                    $apply: items => items.filter(i => i.id !== abstract.getID())
                }))
            },
            onUpdate: abstract => {
                setItems(items => {
                    return items.map(item => abstract.compare(item));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Articles for ${channel.name}`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: `New ${channel.name} Article`,
                style: 'default',
                onClick: onNewItem
            }],
            search: {
                placeholder: 'Search by item name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditNewsCategory = ({ channel, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-news-category': `edit-news-category-${abstract.getID()}`;
    const [category, setCategory] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${category.title}" category has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setCategory(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!category) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: `The title for a ${channel.name} category will describe the options listed within. An example would be a category called "Partners" that contains options like "Our Favorite Brand" or "Most Beloved Neighboorhood Business".`,
                component: 'textfield',
                value: category.title,
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'description',
                required: false,
                title: 'Description',
                description: `The description for a ${channel.name} category is optional but can be used to describe the options listed within. An example would be a category called "Partners" with a description that reads "These are the brands and entities that we trust".`,
                component: 'textview',
                value: category.description,
                onChange: text => onUpdateTarget({ description: text })
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setCategory(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New ${channel.name} Category` : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setCategory(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditNewsItem = ({ channel, isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-news-item': `edit-news-item-${abstract.getID()}`;
    const [categories, setCategories] = useState([]);
    const [item, setItem] = useState({});
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" item has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this item. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setItem(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!item) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'url',
                title: 'URL',
                description: `The content for a ${channel.name} article is pulled from a url for an online article/webpage. We'll automatically try to fill in the title, description, and image for the content if it comes from an online article/webpage.`,
                component: 'textfield',
                value: item.url,
                onChange: text => onUpdateTarget({ url: text }),
                props: {
                    useDelay: true,
                    loading: loading === 'open_graph'
                }
            },{
                key: 'title',
                title: 'Title',
                description: `The name for a ${channel.name} item will be shown to the customer when selecting this item. An example would be a item named "Top 10 EV Vehicles in the United States".`,
                component: 'textfield',
                value: item.title,
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'description',
                required: false,
                title: 'Description',
                description: `The description for a ${channel.name} item will be shown to the customer when selecting this item. An example would be a item with a description reading "You'll never believe which electric vehicles made our top 10 list".`,
                component: 'textview',
                value: item.description,
                onChange: text => onUpdateTarget({ description: text })
            }]
        },{
            key: 'presentation',
            title: 'Presentation',
            items: [{
                key: 'featured',
                title: 'Featured',
                description: `Setting this ${channel.name} item as featured will pin this item to the top of the screen in the mobile app.`,
                component: 'bool_list',
                value: item.featured,
                onChange: val => onUpdateTarget({ featured: val })
            },{
                key: 'category',
                required: false,
                title: 'Category',
                description: `The category for a ${channel.name} item will determine when and how the item will be shown  An example would be a category named "Partners" that contains an item called "Our Favorite Brand".`,
                component: 'list',
                value: item.category,
                onChange: items => onUpdateTarget({ category: items }),
                items: categories.map(category => ({
                    id: category.id,
                    title: category.title
                }))
            },{
                key: 'image',
                title: 'Image',
                description: `The image for a ${channel.name} item is shown to customers when scrolling through their list of items. The image should be a photo that represents the item's content.`,
                component: 'image_picker',
                value: item.image,
                onChange: image => onUpdateTarget({ image: image })
            }]
        }];
    }

    const fetchCategories = async () => {
        try {
            let { categories } = await fetchNewsCategories(utils, { news_channel: channel.id });
            setCategories(categories);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the news categories. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchOpenGraphData = async () => {
        try {
            if(!item.url || item.url == abstract.object.url) {
                return;
            }
            setLoading('open_graph');
            let { results } = await Request.get(utils, '/news/', {
                type: 'open_graph',
                url: item.url
            });

            setLoading(false);
            if(results) {
                onUpdateTarget({
                    ...results,
                    image: results.image && {
                        file_type: 'url',
                        file_name: results.image.split('\\').pop().split('/').pop(),
                        data: results.image
                    }
                });
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information from the supplied url. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setItem(edits);
    }

    useEffect(() => {
        fetchOpenGraphData();
    }, [item.url]);

    useEffect(() => {
        setupTarget();
        fetchCategories();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? `New ${channel.name} Item` : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setItem(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const NewsCategoryDetails = ({ channel }, { abstract, index, options, utils }) => {

    const layerID = `news-category-details-${abstract.getID()}`;
    const limit = 5;

    const [category, setCategory] = useState(abstract.object);
    const [events, setEvents] = useState([]);
    const [items, setItems] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [note, setNote] = useState(null);
    const [paging, setPaging] = useState(null);

    const onDeleteCategory = () => {
        utils.alert.show({
            title: `Delete ${channel.name} Category`,
            message: `Are you sure that you want to delete this ${channel.name} category? This can not be undone`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCategoryConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteCategoryConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/news/', {
                type: 'delete_category',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('newsCategories');
            utils.alert.show({
                title: 'All Done!',
                message: `This ${channel.name} category has been deleted`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this ${channel.name} category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-news-category-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditNewsCategory.bind(this, {
                isNewTarget: false,
                channel: channel
            })
        });
    }

    const onItemClick = item => {
        utils.layer.open({
            id: `news-item-details-${item.id}`,
            abstract: Abstract.create({
                type: 'newsItems',
                object: item
            }),
            Component: NewsItemDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete Category',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'delete') {
                onDeleteCategory();
                return;
            }
        });
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: category.id
            },{
                key: 'title',
                title: 'Title',
                value: category.title
            },{
                key: 'description',
                title: 'Description',
                value: category.description
            },{
                key: 'date_added',
                title: 'Created',
                value: Utils.formatDate(category.date_added)
            },{
                key: 'status',
                title: 'Status',
                value: category.active ? 'Active' : 'Not Active'
            }]
        }];
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchItems = async () => {
        try {
            let items = await abstract.object.getItems(utils);
            setItems(items);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading ${channel.name} category items. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(category.seeds && category.seeds.notes) {
            setNote(category.seeds.notes.find(note => note.deleted !== true));
        }
    }, [category]);

    useEffect(() => {
        fetchItems();
        utils.content.subscribe(layerID, 'newsCategories', {
            onUpdate: next => {
                setCategory(next.compare(abstract, fetchSystemEvents));
            }
        })
        return () => {
            utils.content.unsubscribe(layerID)
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`"${abstract.getTitle()}" Details`}
        index={index}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {items && items.length > 0 && (
                <LayerItem title={'Items'}>
                    {items.map((item, index, items) => {
                        return (
                            Views.entry({
                                key: index,
                                title: item.title,
                                subTitle: item.description,
                                badge: !item.active && {
                                    text: 'Not Active',
                                    color: Appearance.colors.grey()
                                },
                                icon: {
                                    path: item.image || 'images/news-category-icon.png'
                                },
                                bottomBorder: index !== items.length - 1,
                                onClick: onItemClick.bind(this, item)
                            })
                        )
                    })}
                </LayerItem>
            )}

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const NewsItemDetails = ({ channel }, { abstract, index, options, utils }) => {

    const layerID = `news-item-details-${abstract.getID()}`;
    const limit = 5;

    const [events, setEvents] = useState([]);
    const [item, setItem] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [note, setNote] = useState(null);
    const [paging, setPaging] = useState(null);

    const onCategoryClick = () => {
        utils.layer.open({
            id: `news-category-details-${item.category.id}`,
            abstract: Abstract.create({
                type: 'newsCategories',
                object: item.category
            }),
            Component: NewsCategoryDetails.bind(this, {
                channel: channel
            })
        });
    }

    const onDeleteItem = () => {
        utils.alert.show({
            title: `Delete ${channel.name} Article`,
            message: `Are you sure that you want to delete this ${channel.name} article? This can not be undone`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteItemConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteItemConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/news/', {
                type: 'delete_item',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('newsItems');
            utils.alert.show({
                title: 'All Done!',
                message: `This ${channel.name} article has been deleted`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this ${channel.name} article. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-news-item-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditNewsItem.bind(this, {
                channel: channel,
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete Item',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'delete') {
                onDeleteItem();
                return;
            }
        })
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: item.id
            },{
                key: 'title',
                title: 'Title',
                value: item.title
            },{
                key: 'description',
                title: 'Description',
                value: item.description
            },{
                key: 'featured',
                title: 'Featured',
                value: item.featured ? 'Yes' : 'No'
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(item.date)
            },{
                key: 'status',
                title: 'Status',
                value: item.active ? 'Active' : 'Not Active'
            },{
                key: 'content',
                title: 'Content',
                value: 'Click to View',
                onClick: () => window.open(item.url)
            }]
        }];
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(item.seeds && item.seeds.notes) {
            setNote(item.seeds.notes.find(note => note.deleted !== true));
        }
    }, [item]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'newsItems', {
            onUpdate: next => {
                setItem(next.compare(abstract, fetchSystemEvents));
            }
        })
        return () => {
            utils.content.unsubscribe(layerID);
        };
    }, []);

    return (
        <Layer
        id={layerID}
        title={`"${abstract.getTitle()}" Details`}
        index={index}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {item.category && (
                <LayerItem title={'Category'}>
                    {Views.entry({
                        title: item.category.title,
                        subTitle: item.category.description,
                        bottomBorder: false,
                        icon: {
                            path: 'images/news-category-icon.png'
                        },
                        onClick: onCategoryClick
                    })}
                </LayerItem>
            )}

            <LayerItem
            title={'Image Assets'}
            childrenStyle={{
                display: 'flex',
                flexDirection: 'row',
                padding: 12
            }}>
                <div
                className={'text-button'}
                onClick={() => window.open(item.image)}
                style={{
                    borderRadius: 5,
                    backgroundColor: Appearance.colors.softBorder(),
                    border: `1px solid ${Appearance.colors.softBorder()}`,
                    marginRight: 8,
                    height: 40,
                    overflow: 'hidden'
                }}>
                    <img
                    src={item.image}
                    style={{
                        width: 'auto',
                        height: '100%'
                    }}/>
                </div>
            </LayerItem>

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}
