import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const Sheet = ({ items, message, onClick, onClose, title }) => {

    const [animations, setAnimations] = useSpring(() => ({
        bottom: -500,
        config: { mass: 1, tension: 180, friction: 18 }
    }));
    const [bottom, setBottom] = useState(-500);
    const [opacity, setOpacity] = useState(0);
    const [sheetItems, setSheetItems] = useState([]);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerWidth })

    const onCloseSheet = callback => {
        setOpacity(0);
        setAnimations({ bottom: -500 });
        if(typeof(callback) === 'function') {
            setTimeout(callback, 500);
        }
    }

    const onCloseSheetTap = () => {
        onCloseSheet(onClose);
    }

    const onItemClick = key => {
        onCloseSheet(() => {
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(onClick) === 'function') {
                onClick(key);
            }
        });
    }

    const onSetupSheetItems = () => {
        // add cancel button if a cancel button was not supplied
        if(!items.find(item => item.key === 'cancel')) {
            items.push({
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            })
        }
        // prevent showing sheet if no valid items were supplied
        let next = items.filter(item => item.visible !== false);
        if(next.length === 0) {
            if(typeof(onClose) === 'function') {
                onClose();
            }
        }

        // set sheet items and animate components into view
        setSheetItems(next);
        setTimeout(() => {
            setOpacity(1);
            setAnimations({ bottom: 15 });
        }, 250);
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    const getWidth = () => {
        if(Utils.isMobile() === true) {
            return size.width - 30;
        }
        return size.width > 300 ? 300 : (size.width - 30);
    }

    useEffect(() => {
        onSetupSheetItems();
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, []);

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9500,
            display: 'flex',
            justifyContent: 'center'
        }}>
            <VelocityComponent
            duration={500}
            animation={{
                opacity: opacity
            }}>
                <div
                onClick={onCloseSheetTap}
                style={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                }}>
                    <div style={{
                        backgroundColor: Appearance.colors.dim,
                        width: '100%',
                        height: '100%'
                    }} />
                </div>
            </VelocityComponent>
            <animated.div style={{
                position: 'fixed',
                width: getWidth(),
                borderRadius: 10,
                overflow: 'hidden',
                backgroundColor: Appearance.colors.sheet(),
                textAlign: 'center',
                zIndex: 9999,
                ...animations
            }}>
                {(title || message) && (
                    <div style={{
                        padding: 15,
                        borderBottomWidth: 1,
                        borderBottomColor: Appearance.colors.divider()
                    }}>
                        {typeof(title) === 'string' && (
                            <span style={{
                                ...Appearance.textStyles.panelTitle(),
                                display: 'block',
                                marginBottom: message ? 4 : 0
                            }}>{title}</span>
                        )}
                        {typeof(message) === 'string' && (
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                display: 'block',
                                whiteSpace: 'normal'
                            }}>{message}</span>
                        )}
                    </div>
                )}
                {sheetItems.filter(i => i.visible !== false).map(item => {
                    return (
                        <div
                        key={item.key}
                        className={`sheet-item ${window.theme}`}
                        onClick={onItemClick.bind(this, item.key)}>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderTop: `1px solid ${Appearance.colors.divider()}`
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    width: '100%',
                                    color: (item.style === 'destructive' || item.style === 'cancel') ? Appearance.colors.text() : Appearance.colors.primary(),
                                    textAlign: 'center'
                                }}>{item.title}</span>
                            </div>
                        </div>
                    );
                })}
            </animated.div>
         </div>
    )
}
export default Sheet;
