import moment from 'moment-timezone';
import Request from 'files/Request.js';

class RewardClass {

    id = null;
    title = null;
    description = null;
    category = null;
    amount = null;
    cost = null;
    image = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.category = props.category;
        this.amount = props.amount;
        this.cost = props.cost;
        this.image = props.image;
        this.active = Boolean(props.active);
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            description: this.description,
            cost: this.cost,
            amount: this.amount,
            image: this.image
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            title: props.title || this.edits.title,
            description: props.description || this.edits.description,
            cost: props.cost || this.edits.cost,
            amount: props.amount || this.edits.amount,
            image: this.edits.image
        }
        if(props.image && props.image.data) {
            this.edits.tmp_image = props.image;
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/reward/', {
                    type: 'new',
                    title: edits.title,
                    description: edits.description,
                    cost: edits.cost,
                    amount: edits.amount,
                    image: edits.tmp_image
                });

                this.id = id;
                this.title = edits.title;
                this.description = edits.description;
                this.cost = edits.cost;
                this.amount = edits.amount;
                this.image = edits.tmp_image ? edits.tmp_image.data : null;

                utils.content.fetch('rewards');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/reward/', {
                    type: 'update',
                    id: this.id,
                    title: edits.title,
                    description: edits.description,
                    cost: edits.cost,
                    amount: edits.amount,
                    image: edits.tmp_image
                });

                this.title = edits.title;
                this.description = edits.description;
                this.cost = edits.cost;
                this.amount = edits.amount;
                this.image = edits.tmp_image ? edits.tmp_image.data : null;

                utils.content.update({
                    type: 'rewards',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new RewardClass(),
    create: props => new RewardClass().create(props)
}
