import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Notification from 'classes/Notification.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views from 'views/Main.js';

// panels
export const NotificationsList = ({ today }, { index, utils}) => {

    const panelID = `${today ? 'today' : 'past'}Notifications`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [notifications, setNotifications] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onNotificationClick = notification => {
        utils.layer.open({
            id: `notification-details-${notification.id}`,
            abstract: Abstract.create({
                type: 'notifications',
                object: notification
            }),
            Component: NotificationDetails
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `These are the notifications that have been sent to Administrators and your specific account ${today ? 'for today' : 'prior to today'}`,
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(notifications.length === 0) {
            return (
                Views.entry({
                    title: 'Nothing to see here',
                    subTitle: 'There are no notifications available to view',
                    bottomBorder: false
                })
            )
        }
        return notifications.map((notification, index) => {
            return (
                Views.entry({
                    key: notification.id,
                    title: notification.title,
                    subTitle: notification.message,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: notification.getIcon()
                    },
                    badge: !notification.for_level && utils.security.setRequiredLevels([ 'admin' ]) && {
                        text: 'Your Account',
                        color: Appearance.colors.lightGrey
                    },
                    textStyles: {
                        subTitle: {
                            whiteSpace: 'break-spaces'
                        }
                    },
                    bottomBorder: index !== notifications.length - 1,
                    onClick: onNotificationClick.bind(this, notification)
                })
            )
        });
    }

    const fetchNotifications = async () => {
        try {
            let { notifications, paging } = await Request.get(utils, '/user/', {
                date: today && moment().utc().unix(),
                exclude_date: !today && moment().utc().unix(),
                limit: limit,
                type: 'seeds_notifications',
                ...manager
            });
            setLoading(false);
            setPaging(paging);
            setNotifications(notifications.map(notification => Notification.create(notification)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the notifications list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchNotifications();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'notifications', {
            onFetch: fetchNotifications
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${today ? `Today's` : 'Past'} Notifications`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps()
            },
            search: {
                placeholder: 'Search by notification id, title, or customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const NotificationDetails = ({ abstract, index, options, utils }) => {

    const layerID = `notification-details-${abstract.getID()}`;
    const [notification, setNotification] = useState(abstract.object);

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            lastItem: true,
            items: [{
                key: 'id',
                title: 'ID',
                value: notification.id
            },{
                key: 'title',
                title: 'Title',
                value: notification.title
            },{
                key: 'category',
                title: 'Category',
                value: notification.category
            },{
                key: 'for_level',
                title: 'For Account Type',
                value: notification.for_level ? `${User.formatLevel(notification.for_level).text} or greater` : null
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(notification.date)
            }]
        }];
    }

    const fetchOrder = async () => {
        if(!abstract.object.tmp_order) {
            return;
        }
        try {
            await abstract.object.createOrder(utils);
            setNotification({ ...abstract.object });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the Order for this notification. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchReservation = async () => {
        if(!abstract.object.tmp_reservation) {
            return;
        }
        try {
            await abstract.object.createReservation(utils);
            setNotification({ ...abstract.object });
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the Reservation this notification. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchOrder();
        fetchReservation();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            {notification.reservation && (
                <LayerItem title={'Reservation'}>
                    {Views.entry({
                        key: notification.reservation.id,
                        title: notification.reservation.customer ? notification.reservation.customer.full_name : 'Name Unavailable',
                        subTitle: 'Reservation #' + notification.reservation.id,
                        badge: notification.reservation.status,
                        icon: notification.reservation.customer ? {
                            style: Appearance.icons.standard(),
                            path: notification.reservation.customer.avatar
                        } : null,
                        bottomBorder: false,
                        onClick: Utils.reservations.details.bind(this, utils, notification.reservation)
                    })}
                </LayerItem>
            )}

            {notification.order && (
                <LayerItem title={`${notification.order.channel.name} Order`}>
                    {Views.entry({
                        key: notification.order.id,
                        title: notification.order.customer.full_name,
                        subTitle: notification.order.host ? `Ordered from ${notification.order.host.name}` : 'Destination Not Available',
                        supportingTitle: moment(notification.order.drop_off_date).format('MMMM Do, YYYY [at] h:mma'),
                        badge: notification.order.status,
                        onClick: Utils.orders.details.bind(this, utils, notification.order),
                        bottomBorder: false,
                        icon: {
                            path: notification.order.customer.avatar,
                            style: {
                                ...Appearance.icons.standard(),
                                alignSelf: 'flex-start'
                            }
                        }
                    })}
                </LayerItem>
            )}

            {notification.toUser && (
                <LayerItem title={'Sent To'}>
                    {Views.entry({
                        title: notification.toUser.full_name,
                        subTitle: User.formatLevel(notification.toUser.level).text,
                        icon: {
                            path: notification.toUser.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, notification.toUser)
                    })}
                </LayerItem>
            )}

            {notification.from_user && (
                <LayerItem title={'Sent By'}>
                    {Views.entry({
                        title: notification.from_user.full_name,
                        subTitle: User.formatLevel(notification.from_user.level).text,
                        icon: {
                            path: notification.from_user.avatar
                        },
                        bottomBorder: false,
                        onClick: notification.from_user.user_id !== 1 ? Utils.users.details.bind(this, utils, notification.from_user) : null
                    })}
                </LayerItem>
            )}

            <LayerItem
            title={'Content'}
            childrenStyle={{
                padding: '8px 12px 8px 12px'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    whiteSpace: 'normal'
                }}>{notification.message}</span>
            </LayerItem>

            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}
