import React from 'react';
import API from 'files/api.js';

const Request = {
    build: (url, props) => {
        let values = Object.keys(props).map(key => {
            return `${key}=${typeof(props[key]) === 'object' ? JSON.stringify(props[key]) : encodeURIComponent(props[key])}`;
        }, []);
        return `${url}?${values.join('&')}`;
    },
    get: async (utils, url, props = {}) => {
        return new Promise(async (resolve, reject) => {
            try {

                // prepare url and send request to server
                let response = await fetch(Request.build(`/api/v${API.version}${url}`, props), {
                    method: 'GET',
                    withCredentials: true,
                    headers: utils.api.headers()
                });

                // check if an non-successful status code was returned
                if(response.status !== 200) {

                    // notify subscribers of 440 status code if applicable
                    // this status code represents an expired session
                    if(response.status === 440) {
                        utils.events.emit('session_expired');
                    }

                    // fallback to returning server generated error text
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                }

                // decode json response and resolve
                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    },
    post: async (utils, url, props = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetch(`/api/v${API.version}${url}`, {
                    method: 'POST',
                    withCredentials: true,
                    headers: utils.api.headers(),
                    body: JSON.stringify(props)
                });

                // check for conflicts and allow user to respond
                if(response.status === 409) {
                    let json = await response.json();
                    let error = new Error();
                    error.code = response.status;
                    error.category = json.category;
                    error.message = json.message;
                    error.response = json;
                    throw error;
                    return;
                }

                // check if an non-successful status code was returned
                if(response.status !== 200) {

                    // notify subscribers of 440 status code if applicable
                    // this status code represents an expired session
                    if(response.status === 440) {
                        utils.events.emit('session_expired');
                    }

                    // fallback to returning server generated error text
                    let text = await response.text();
                    throw new Error(text || 'An unknown error occurred');
                }

                // decode json response and resolve
                let json = await response.json();
                resolve(json);

            } catch(e) {
                reject(e);
            }
        })
    }
}
export default Request;
