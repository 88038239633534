import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';

const DatePickerField = props => {

    const [hover, setHover] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const onFieldClick = () => {
        props.utils.datePicker.show({
            date: selectedDate,
            ...props,
            removeable: false,
            onDateChange: onDateChange,
        })
    }

    const onDateChange = date => {
        setSelectedDate(date);
        if(typeof(props.onDateChange) === 'function') {
            props.onDateChange(date);
        }
    }

    const onRemoveDate = () => {
        setSelectedDate(null);
        if(typeof(props.onRemoveDate) === 'function') {
            props.onRemoveDate(null);
        }
    }

    const formatDate = () => {
        if(!selectedDate) {
            return 'Select a date...';
        }
        if(props.dateTime) {
            return moment(selectedDate).format('MMMM Do, YYYY [at] h:mma')
        }
        return moment(selectedDate).format('MMMM Do, YYYY');
    }

    useEffect(() => {
        setSelectedDate(props.selected ? moment(props.selected) : null);
    }, [props.selected]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
        }}>
            <div
            onClick={onFieldClick}
            className={`dummy-field ${window.theme} cursor-pointer`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                flexGrow: 1,
                position: 'relative',
                height: 35,
                backgroundColor: hover ? (window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white') : Appearance.colors.textField(),
                ...props.style
            }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: Appearance.colors.text(),
                    width: '100%',
                    ...props.fieldStyle
                }}>{formatDate()}</span>
            </div>
            {selectedDate && typeof(props.onRemoveDate) === 'function' && (
                <img
                className={'text-button'}
                src={'images/close-button-light-small.png'}
                onClick={onRemoveDate}
                style={{
                    width: 25,
                    height: 25,
                    marginLeft: 8
                }} />
            )}
        </div>
    )
}
export default DatePickerField;
