import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const PreauthorizationManager = ({ utils, defaultAuthorization, order, reservation, user }) => {

    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [methods, setMethods] = useState([]);
    const [preauthorization, setPreauthorization] = useState(null);
    const [selected, setSelected] = useState(null);

    const isDevelopmentModePreauthorization = () => {
        return defaultAuthorization && defaultAuthorization.id.includes('dev_') ? true : false;
    }
    const setupMethods = async () => {
        try {
            let target = order || reservation;
            if(!target) {
                setLoading(false);
                return;
            }
            
            if(isDevelopmentModePreauthorization() === true) {
                utils.alert.show({
                    title: 'Development Mode',
                    message: 'This preauthorization was made in development mode and can not be modified'
                });
            }

            let { methods } = await target.customer.getPaymentMethods(utils);
            let selected = methods.find(m => defaultAuthorization ? defaultAuthorization.id === m.id : false);

            setPreauthorization(defaultAuthorization);
            setAmount(defaultAuthorization ? defaultAuthorization.amount : 0);
            setMethods(methods.map(method => {
                if(defaultAuthorization && defaultAuthorization.card_id === method.id) {
                    target._private.preauthorization = {
                        ...target._private.preauthorization,
                        cardID: method
                    }
                }
                return method;
            }));

            if(defaultAuthorization && !selected) {
                target._private.preauthorization = {
                    ...target._private.preauthorization,
                    id: defaultAuthorization.id
                }
            }

            // retrieve method for preauth
            if(defaultAuthorization && isDevelopmentModePreauthorization() === false && !selected) {
                let preauthMethod = await target.getPaymentMethodForPreauthorization(utils);
                target._private.preauthorization = {
                    ...target._private.preauthorization,
                    cardID: preauthMethod ? preauthMethod.id : null
                }
                setLoading(false);
                setSelected(preauthMethod);
                return;
            }

            // no default preauth found
            setLoading(false);
            setSelected(selected);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the payment methods. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setPreauthorization(defaultAuthorization);
        setAmount(defaultAuthorization ? defaultAuthorization.amount : 0);
    }, [defaultAuthorization]);

    useEffect(() => {
        setupMethods();
    }, []);

    const onClick = method => {
        setSelected(method);
        let target = order || reservation;
        if(target) {
            target._private.preauthorization = {
                ...target._private.preauthorization,
                cardID: method.id
            }
        }
    }

    const onChange = text => {
        if(isNaN(text)) {
            utils.alert.show({
                title: 'Oops!',
                message: "The amount entered is not a valid amount."
            })
            return;
        }

        setAmount(text);
        let target = order || reservation;
        if(target) {
            target._private.preauthorization = {
                ...target._private.preauthorization,
                amount: parseFloat(text)
            }
        }
    }

    return (
        <div style={{
            textAlign: 'left',
            width: '100%'
        }}>
            {loading === true
                ?
                Views.loader()
                :
                <>
                {preauthorization && (
                    <div className={'pb-2 text-center'}>
                        <span style={Appearance.textStyles.title()}>{`Authorized ${Utils.formatDate(preauthorization.date)}`}</span>
                    </div>
                )}
                <div
                className={'mb-2'}
                style={Appearance.styles.unstyledPanel()}>
                    {methods.length === 0
                        ?
                        Views.entry({
                            title: 'Nothing Found',
                            subTitle: `No payment methods are setup for ${user ? user.full_name : 'the customer'}`,
                            icon: {
                                style: Appearance.icons.standard(),
                                path: 'images/rejected-red-small.png'
                            }
                        })
                        :
                        methods.map((method, index) => {
                            return Views.entry({
                                key: index,
                                title: method.type(),
                                subTitle: method.subType(),
                                icon: {
                                    style: {
                                        ...Appearance.icons.standard(),
                                        backgroundColor: selected && selected.id === method.id ? Appearance.colors.primary() : Appearance.colors.grey()
                                    },
                                    path: 'images/payment-icon-clear-small.png'
                                },
                                badge: method.default && {
                                    text: 'Default',
                                    color: Appearance.colors.grey()
                                },
                                onClick: onClick.bind(this, method)
                            })
                        })
                    }
                </div>
                <TextField
                prepend={amount ? '$' : null}
                value={amount}
                onChange={onChange} />
                </>
            }
        </div>
    )
}
export default PreauthorizationManager;
