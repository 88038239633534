import moment from 'moment-timezone';
import Request from 'files/Request.js';

class RestrictedDateTimeClass {

    id = null;
    type = null;
    title = null;
    message = null;
    start_date = null;
    end_date = null;
    start_time = null;
    end_time = null;
    active = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.type = props.type;
        this.title = props.title;
        this.message = props.message;
        this.start_date = props.start_date;
        this.end_date = props.end_date;
        this.start_time = props.start_time;
        this.end_time = props.end_time;
        this.active = Boolean(props.active);
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            message: this.message,
            type: this.type,
            start_date: this.start_date,
            end_date: this.end_date,
            start_time: this.start_time,
            end_time: this.end_time
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            title: props.title || this.edits.title,
            message: props.message || this.edits.message,
            type: props.type || this.edits.type,
            start_date: props.start_date || this.edits.start_date,
            end_date: props.end_date || this.edits.end_date,
            start_time: props.start_time || this.edits.start_time,
            end_time: props.end_time || this.edits.end_time
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/resources/', {
                    type: 'new_restricted_date_time',
                    title: edits.title,
                    message: edits.message,
                    restriction_type: edits.type,
                    start_date: edits.start_date && moment(edits.start_date).utc().unix(),
                    end_date: edits.end_date && moment(edits.end_date).utc().unix(),
                    start_time: edits.start_time && moment(edits.start_time).utc().unix(),
                    end_time: edits.end_time && moment(edits.end_time).utc().unix()
                });

                this.id = id;
                this.title = edits.title;
                this.message = edits.message;
                this.type = edits.type;
                this.start_date = edits.start_date;
                this.end_date = edits.end_date;
                this.start_time = edits.start_time;
                this.end_time = edits.end_time;

                utils.content.fetch('restrictedDateTimes');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/resources/', {
                    type: 'update_restricted_date_time',
                    id: this.id,
                    title: edits.title,
                    message: edits.message,
                    restriction_type: edits.type,
                    start_date: edits.start_date && moment(edits.start_date).utc().unix(),
                    end_date: edits.end_date && moment(edits.end_date).utc().unix(),
                    start_time: edits.start_time && moment(edits.start_time).utc().unix(),
                    end_time: edits.end_time && moment(edits.end_time).utc().unix()
                });

                this.title = edits.title;
                this.message = edits.message;
                this.type = edits.type;
                this.start_date = edits.start_date;
                this.end_date = edits.end_date;
                this.start_time = edits.start_time;
                this.end_time = edits.end_time;

                utils.content.update({
                    type: 'restrictedDateTimes',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new RestrictedDateTimeClass(),
    create: props => new RestrictedDateTimeClass().create(props)
}
