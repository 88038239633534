import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Order from 'classes/Order.js';
import Reservation from 'classes/Reservation.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class NotificationClass {

    id = null;
    title = null;
    message = null;
    date = null;
    read = null;
    globally_read = null;
    needs_approval = null;
    category = null;
    user_info = null;
    for_level = null;
    from_user = null;
    attachment = null;

    tmp_reservation = null;
    reservation = null;

    tmp_order = null;
    order = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.message = props.message;
        this.date = moment(props.date);
        this.read = props.read;
        this.for_level = props.for_level;
        this.globally_read = props.globally_read;
        this.needs_approval = props.needs_approval;
        this.category = props.category;
        this.user_info = props.user_info;
        this.attachment = props.attachment;
        this.tmp_reservation = props.user_info ? props.user_info.reservation_id : null;
        this.tmp_order = props.user_info ? props.user_info.order_id : null;
        this.from_user = props.from_user ? User.create(props.from_user) : null;
        this.toUser = props.to_user ? User.create(props.to_user) : null;
        return this;
    }

    createReservation = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(this.reservation) {
                    resolve(this.reservation);
                    return;
                }
                if(!this.tmp_reservation) {
                    throw new Error('No reservation found');
                }
                this.reservation = await Reservation.get(utils, this.tmp_reservation);
                resolve(this.reservation);
            } catch(e) {
                reject(e);
            }
        })
    }

    createOrder = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(this.order) {
                    resolve(this.order);
                    return;
                }
                if(!this.tmp_order) {
                    throw new Error('No order found');
                }
                this.order = await Order.get(utils, this.tmp_order);
                resolve(this.order);
            } catch(e) {
                reject(e);
            }
        })
    }

    contentOptions = (utils, type) => {

        // Setup for alert or sheet
        let buttons = [];
        switch(this.category) {
            case 'RESERVATION_EDITED':
            if(this.needs_approval) {
                buttons.push({
                    key: 'set_status',
                    title: 'Approve or Decline',
                    style: 'default'
                });
            }
            break;
        }

        if(this.tmp_order) {
            buttons.push({
                key: 'order',
                title: 'View Order',
                style: 'default'
            })
        }
        if(this.tmp_reservation) {
            buttons.push({
                key: 'reservation',
                title: 'View Reservation',
                style: 'default'
            })
        }
        if(this.tmpSubscription) {
            buttons.push({
                key: 'subscription',
                title: 'View Subscription',
                style: 'default'
            })
        }
        if(this.user_info && this.user_info.data) {
            if(this.user_info.data.url) {
                buttons.push({
                    key: 'url',
                    title: `Open Link`,
                    style: 'default'
                })
            }
        }
        if(type === 'alert') {
            buttons = buttons.concat([{
                key: 'cancel',
                title: 'Dismiss',
                style: 'cancel'
            }])
        }

        let callbacks = async key => {

            if(key === 'url') {
                window.open(this.user_info.data.url);
                return;
            }

            if([ 'order', 'reservation', 'subscription' ].includes(key)) {
                try {
                    let { order, reservation, subscription } = await Utils.createMultiple(utils, {
                        orderID: this.tmp_order,
                        reservationID: this.tmp_reservation,
                        subscriptionID: this.tmpSubscription
                    })
                    if(!order && !reservation && !subscription) {
                        throw new Error('Unable to locate details');
                    }
                    if(order) {
                        Utils.orders.details(utils, order)
                    } else if(reservation) {
                        Utils.reservations.details(utils, reservation)
                    } else if(subscription) {
                        Utils.subscription.details(subscription)
                    }

                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving your notification details. ${e.message || 'An unknown error occurred'}`
                    })
                }
            }
        };

        switch(type) {
            case 'notification':
            return {
                title: this.title,
                message: this.message,
                icon: {
                    path: this.from_user ? this.from_user.avatar : this.getIcon(),
                    style: {
                        backgroundColor: Appearance.colors.primary()
                    }
                },
                onClick: () => {
                    if(this.user_info && this.user_info.data) {
                        if(this.user_info.data.url) {
                            utils.layer.webView({
                                id: `notification-${this.id}`,
                                title: this.title,
                                url: this.user_info.data.url
                            });
                            return;
                        }
                    }

                    if(buttons.length > 0) {
                        utils.sheet.show({ items: buttons }, callbacks);
                    }
                }
            }

            case 'alert':
            utils.alert.show({
                title: this.title,
                message: this.message,
                icon: {
                    path: this.from_user ? this.from_user.avatar : this.getIcon(),
                    style: {
                        backgroundColor: Appearance.colors.primary()
                    }
                },
                buttons: buttons,
                onClick: callbacks
            })
            break;

            case 'sheet':
            utils.sheet.show({
                title: this.title,
                message: this.message,
                icon: {
                    path: this.from_user ? this.from_user.avatar : this.getIcon(),
                    style: {
                        backgroundColor: Appearance.colors.primary()
                    }
                },
                items: buttons
            }, callbacks);
            break;
        }
    }

    overview = () => {

        if(['NEW_RIDE', 'UPDATED_RIDE'].includes(this.category)) {
            return !this.needs_approval ? {
                title: this.tmp_reservation.status ? 'Approved' : 'Rejected',
                message: 'This Reservation has already been ' + (this.tmp_reservation.status ? 'approved' : 'rejected') + ' by the system or another Administrative user',
                items: [{
                    key: 'view',
                    title: 'View Reservation',
                    style: 'default'
                }]
            } : {
                title: 'Needs Approval',
                message: 'This Reservation is currently pending admin approval',
                items: [{
                    key: 'approve',
                    title: 'Approve',
                    style: 'default'
                },{
                    key: 'decline',
                    title: 'Decline',
                    style: 'destructive'
                },{
                    key: 'view',
                    title: 'View Reservation',
                    style: 'default'
                }]
            }
        }

        return {
            items: [{
                key: 'view',
                title: 'View Reservation',
                style: 'default'
            }]
        }
    }

    getIcon = () => {
        if(this.from_user && this.from_user.user_id !== 1) {
            return this.from_user.avatar;
        }
        if(this.category === 'NEW_RIDE' || this.category === 'UPDATED_RIDE') {
            if(this.needs_approval) {
                return 'images/warning-button-grey-small.png';
            }
            if(this.tmp_reservation) {
                return this.tmp_reservation.status === Reservation.status.rejected ? 'images/rejected-red-small.png' : 'images/checkmark-button-clear-small.png';
            }
        }
        if(this.category === 'DRIVER_TIP_REMINDER') {
            return this.from_user ? this.from_user.avatar : 'images/payment-icon-grey-small.png';
        }
        if(this.category === 'DRIVER_IN_ROUTE_TO_PICKUP') {
            return 'images/active-icon-blue-small.png';
        }
        if(this.category === 'RESERVATION_STATUS_CHANGE') {
            return this.user_info.approved ? 'images/checkmark-button-clear-small.png' : 'images/rejected-red-small.png';
        }
        if(this.category === 'FEEDBACK_RIDE') {
            return 'images/message-icon-clear-small.png';
        }
        if(this.category === 'FEEDBACK_REMINDER') {
            return 'images/feedback-icon-grey-small.png';
        }
        if(this.category === 'COMPANY_GENERAL' || this.category === 'COMPANY_ADDED') {
            return 'images/company-icon-clear-small.png';
        }
        if(this.category === 'DRIVER_TIP_ADDED') {
            return 'images/payment-icon-clear-small.png';
        }
        if(this.category === 'RESERVATION_AUTHORIZATION_ERROR') {
            return 'images/rejected-red-small.png';
        }
        if(this.category === 'GENERAL') {
            return 'images/activity-icon-clear-small.png';
        }
        if(this.category === 'NEW_FOOD_ORDER') {
            return 'images/order-category-icon.png';
        }
        return 'images/notification-icon-clear-small.png';
    }
}
export default {
    new: () => new NotificationClass(),
    create: props => new NotificationClass().create(props)
}
