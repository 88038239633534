import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Appearance from 'styles/Appearance.js';
import Slider from '@material-ui/core/Slider';

const CustomSlider = withStyles({
    root: {
        color: Appearance.colors.primary(),
        height: 2,
        padding: '2px 0'
    },
    thumb: {
        backgroundColor: '#fff',
        border: `2px solid ${Appearance.colors.grey()}`,
        '&:focus, &:hover, &$active': {
            boxShadow: 'none'
        },
        height: 20,
        marginLeft: -10,
        marginTop: -8,
        width: 20
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        backgroundColor: Appearance.colors.divider(),
        height: 3,
        opacity: 1
    },
    mark: {
        backgroundColor: Appearance.colors.primary(),
        height: 8,
        width: 1
    },
    markActive: {
        backgroundColor: Appearance.colors.green,
        opacity: 1
    }
})(Slider);

const CustomSliderComponent = props => {

    const valueRef = useRef(null);
    const valueTimeout = useRef(null);

    const [value, setValue] = useState(props.value || 0);

    const onSliderChange = (evt, val) => {

        // update slider value for key
        valueRef.current = val;
        setValue(val);

        // clear pending timeout if applicable
        if(valueTimeout.current) {
            clearTimeout(valueTimeout.current);
        }

        // set timeout and determine if value has changed 
        valueTimeout.current = setTimeout(() => {

            // notify subscribers if value has not changed after the timeout
            if(valueRef.current === val) {
                valueTimeout.current = null;
                if(typeof(props.onChange) === 'function') {
                    props.onChange(val);
                }
            }

        }, props.delay ? props.delay * 1000 : 0);
    }

    useEffect(() => {
        setValue(props.value || 0);
    }, [props.value]);

    return (
        <CustomSlider 
        {...props} 
        onChange={onSliderChange} 
        value={value} />
    )
}

export default CustomSliderComponent;
