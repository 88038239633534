import moment from 'moment-timezone';
import User from 'classes/User.js';

class NoteClass {

    id = null;
    type = null;
    title = null;
    message = null;
    date = null;
    submitted_by = null;
    deleted = false;
    attachment = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.type = props.type;
        this.title = props.title;
        this.message = props.message;
        this.date = props.date ? moment(props.date) : null;
        this.deleted = props.deleted;
        this.attachment = props.attachment;
        this.submitted_by = props.submitted_by ? User.create(props.submitted_by) : null;
        return this;
    }
}

export default {
    new: () => new NoteClass(),
    create: props => new NoteClass().create(props)
}
