import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import Driver from 'classes/Driver.js';
import Notification from 'classes/Notification.js';
import SocketIO from 'socket.io-client';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class SocketManagerClass {

    auth = null;
    sockets = {};
    subscribers = {};
    targets = [ 'admin', 'community', 'locations', 'notifications', 'orders', 'reservations', 'seeds', 'sensors', 'system', 'vehicles'  ];

    constructor() {
        return this;
    }

    connect = async (utils, user) => {
        return new Promise(async (resolve, reject) => {
            this.auth = {
                api_key: user.api_key,
                client_id: utils.client.get().id,
                user_id: user.user_id,
                version: API.version
            };

            let sockets = {
                main: new SocketIO(API.sockets, {
                    autoConnect: true,
                    forceNew: false,
                    jsonp: false,
                    query: { auth: JSON.stringify(this.auth) },
                    reconnection: true,
                    reconnectionAttempts: 30,
                    reconnectionDelay: 10000,
                    transports: ['websocket', 'polling']
                })
            };

            sockets.main.on('error', (e) => {
                console.error(e.message);
                let error = new Error('Your device has lost connection to our live update server');
                reject(error);
            })

            sockets.main.on('connect_timeout', () => {
                let error = new Error('The request to connect to our live update server has timed-out');
                reject(error);
            })

            sockets.main.on('connect_error', (e) => {
                console.error(e.message);
                let error = new Error('We ran into an issue while trying to connect to our live update server');
                reject(error);
            })

            sockets.main.on('disconnect', (e) => {
                console.error(e.message);
                let error = new Error('Your device has lost connection to our live update server and we were unable to automatically reconnect');
                reject(error);
            })

            sockets.main.on('connect', async () => {
                this.targets.forEach(target => {
                    sockets[target] = sockets.main.io.socket(`/${target}`, {
                        autoConnect: true,
                        forceNew: false,
                        jsonp: false,
                        query: { auth: JSON.stringify(this.auth) },
                        reconnection: true,
                        reconnectionAttempts: 30,
                        reconnectionDelay: 10000,
                        transports: ['websocket', 'polling']
                    });
                });
                this.sockets = sockets;
                resolve();
            });
        })
    }

    emit = async (socketKey, key, data) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                }
                if(!key) {
                    throw new Error(`Missing required parameters for socket emit`);
                }
                this.sockets[socketKey].emit(this.getClientPath(key), {
                    ...data,
                    auth: this.auth
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getClientPath = path => {
        return `${path}-${window.client_id}`;
    }

    notify = (key, props) => {
        Object.values(this.subscribers).forEach(({ id, callbacks }) => {
            if(typeof(callbacks[key]) === 'function') {
                callbacks[key](props);
            }
        })
    }

    off = async (socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                }
                if(!key || !callback) {
                    throw new Error(`Missing required parameters for socket off`);
                }
                this.sockets[socketKey].off(this.getClientPath(key), callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    on = async (socketKey, key, callback) => {
        return new Promise((resolve, reject) => {
            try {
                if(!this.sockets[socketKey]) {
                    throw new Error(`${Utils.ucFirst(socketKey)} live update channel is not available`);
                }
                if(this.sockets[socketKey].connected !== true) {
                    throw new Error(`Your device has lost connection to our live update server`);
                }
                if(!key || !callback) {
                    throw new Error(`Missing required parameters for socket on`);
                }
                this.sockets[socketKey].on(this.getClientPath(key), callback);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    persistEmit = (socketKey, key, data) => {
        let index = 0;
        let interval = setInterval(async () => {
            try {
                index++;
                await this.emit(socketKey, key, data);
                clearInterval(interval);
                console.log(`data sent to ${socketKey}:${key}`);
            } catch(e) {
                if(index > 10) {
                    clearInterval(interval);
                    console.error(`unable to connect to ${socketKey}:${key}`);
                    return;
                }
                console.log(`waiting for ${socketKey}:${key}`);
            }
        }, 500);
    }

    persistOn = async (socketKey, key, callback) => {
        let index = 0;
        let interval = setInterval(async () => {
            try {
                index++;
                await this.on(socketKey, key, callback);
                clearInterval(interval);
                console.log(`listener added for ${socketKey}:${key}`);
            } catch(e) {
                if(index > 10) {
                    clearInterval(interval);
                    console.error(`unable to add ${socketKey}:${key} listener`);
                    return;
                }
                console.log(`waiting for ${socketKey}:${key}`);
            }
        }, 500);
    }

    subscribe = (id, callbacks) => {
        this.subscribers[id] = {
            id: id,
            callbacks: callbacks
        };
        return this;
    }

    unsubscribe = id => {
        if(!this.subscribers[id]) {
            return;
        }
        delete this.subscribers[id];
    }
}

export default {
    new: () => new SocketManagerClass()
}
