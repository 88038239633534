import React, { useState, useEffect } from 'react';

import { animated, useSpring } from 'react-spring';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Logo from 'files/lottie/logo.json';
import LottieView from 'views/Lottie.js';
import Request from 'files/Request.js';
import { Spring } from 'react-spring';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const Sidebar = ({ active, content, items, onLogoutClick, onMobileClose, onNavigationChange, onSettingsClick, style, user, utils, visible }) => {

    const [animations, setAnimations] = useSpring(() => ({
        left: -window.innerWidth,
        config: { mass: 1, tension: 180, friction: 22 }
    }));
    const [logoAnimation, setLogoAnimation] = useState(null);
    const [openMenus, setOpenMenus] = useState([]);
    const [springToggle, setSpringToggle] = useState(null);

    const onSetupClientIcon = () => {
        setLogoAnimation(Utils.getClientLottieLogo(utils));
    }

    const onToggleMenu = key => {
        if(!openMenus.includes(key)) {
            setOpenMenus(openMenus => update(openMenus, {
                $push: [key]
            }));
            return;
        }
        setOpenMenus(openMenus => update(openMenus, {
            $splice: [
                [openMenus.indexOf(key), 1]
            ]
        }));
    }

    const getContent = () => {
        return (
            <animated.aside
            className={`main-sidebar col-12 col-md-3 col-lg-2 px-0`}
            style={{
                borderWidth: 0,
                zIndex: '1000',
                ...animations
            }}>
                <div style={{
                    position: 'relative',
                    zIndex: 1100,
                    backgroundColor: Utils.isMobile() ? Appearance.colors.background() : null
                }}>
                    <div style={{
                        display: Utils.isMobile() ? 'flex' : 'none',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        padding: 15,
                        zIndex: 1500
                    }}>
                        <img
                        className={'text-button'}
                        src={`/images/close-button-small.png`}
                        onClick={onMobileClose}
                        style={{
                            width: 30,
                            height: 30,
                            objectFit: 'contain'
                        }} />
                    </div>
                    <div
                    className={`nav-wrapper ${window.theme}`}
                    style={{
                        paddingTop: Utils.isMobile() ? 15 : 0,
                        backgroundColor: Appearance.colors.transparent
                    }}>
                        <ul className={'nav flex-column py-md-0 d-block'}>
                            <div
                            className={'pt-0 pt-md-3'}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: 25,
                                paddingBottom: 25,
                                paddingLeft: 15,
                                paddingRight: 15,
                                textAlign: 'center'
                            }}>
                                {logoAnimation && (
                                    <LottieView
                                    loop={true}
                                    autoPlay={true}
                                    source={logoAnimation}
                                    style={{
                                        width: 200,
                                        height: 200
                                    }}/>
                                )}
                                <span style={{
                                    color: Appearance.colors.text(),
                                    fontSize: 22,
                                    fontWeight: 800,
                                    lineHeight: 1.25
                                }}>{getTitle()}</span>
                                <span style={{
                                    color: Appearance.colors.subText(),
                                    fontSize: 14,
                                    fontWeight: 600
                                }}>{getSubTitle()}</span>
                            </div>

                            {Object.values(content).filter(item => {
                                return item.visible !== false;
                            }).map((item, index) => {

                                // check if item contains subviews object
                                if(item.subViews) {
                                    let items = Object.values(item.subViews).filter(item => item.visible !== false);
                                    if(items.length === 0) {
                                        return null;
                                    }
                                    // return standard item if only one subview is visibile
                                    if(items.length === 1) {
                                        return getItem({
                                            key: index,
                                            title: item.title,
                                            icon: item.icon,
                                            isActive: active && active.view === item.key,
                                            onClick: onNavigationChange.bind(this, {
                                                view: item.key,
                                                subView: items[0].key,
                                                action: item.action
                                            })
                                        });
                                    }
                                    // show dropdown if more than 1 visible option is found
                                    return getItem({
                                        ...item,
                                        isActive: active && item.key === active.view,
                                        items: items.map((subView, index) => ({
                                            ...subView,
                                            onClick: onNavigationChange,
                                            isSubViewActive: active && item.key === active.view && active.subView === subView.key
                                        }))
                                    });
                                }

                                // check if at least one panel is visible
                                let panels = item.panels.filter(panel => panel.visible !== false);
                                if(panels.length > 0) {
                                    return getItem({
                                        key: index,
                                        title: item.title,
                                        icon: item.icon,
                                        isActive: active && active.view === item.key,
                                        onClick: onNavigationChange.bind(this, {
                                            view: item.key,
                                            action: item.action
                                        })
                                    });
                                }

                                // fallback to returning nothing
                                return null;
                            })}
                        </ul>
                    </div>
                </div>
            </animated.aside>
        )
    }

    const getIconValue = (icon, isActive) => {

        // return local image asset if applicable
        if(typeof(icon) === 'string') {
            return `/images/${icon}${isActive || window.theme === 'dark' ? '-white' : ''}.png`;
        }

        // return remote image asset if applicable
        if(typeof(icon) === 'object') {
            return isActive || window.theme === 'dark' ? icon.active : icon.inactive;
        }
    }

    const getItem = ({ isActive, icon, items, key, onClick, title }) => {
        return (
            <div
            key={key}
            onClick={items ? onToggleMenu.bind(this, key) : onClick}
            onMouseEnter={() => setSpringToggle(key)}
            onMouseLeave={() => setSpringToggle(null)}
            className={'cursor-pointer'}
            style={{
                marginBottom: 12,
                paddingLeft: 20,
                paddingRight: Utils.isMobile() ? 20 : 0
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <Spring
                    toggle={springToggle === key ? true : false}
                    config={{ mass: 1, tension: 180, friction: 8 }}
                    from={{ transform: 'scale(1)' }}
                    to={{ transform: springToggle === key ? 'scale(1.15)' : 'scale(1.0)' }}>
                    {props => (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 40,
                            height: 40,
                            minWidth: 40,
                            minHeight: 40,
                            padding: 10,
                            borderRadius: 20,
                            overflow: 'hidden',
                            marginRight: 12,
                            backgroundColor: isActive ? Appearance.colors.primary() : Appearance.colors.layerBackground(),
                            boxShadow: '-10px 10px 15px rgba(0,0,0,0.05)',
                            ...props
                        }}>
                            <img
                            src={getIconValue(icon, isActive)}
                            style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%'
                            }} />
                        </div>
                    )}
                    </Spring>

                    <span style={{
                        flexGrow: 1,
                        width: '100%',
                        fontWeight: 500,
                        fontSize: 14,
                        color: isActive ? Appearance.colors.primary() : Appearance.colors.text(),
                        paddingRight: 12,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>{title}</span>
                    <div style={{
                        width: 3,
                        height: 25,
                        borderRadius: 1.5,
                        backgroundColor: isActive ? Appearance.colors.primary() : Appearance.colors.transparent,
                        overflow: 'hidden'
                    }} />
                </div>
                {items && openMenus.includes(key) && (
                    <div style={{
                        marginTop: 8,
                        marginLeft: 30,
                        borderRadius: 10,
                        overflow: 'hidden',
                        backgroundColor: Appearance.colors.layerBackground(),
                        boxShadow: '-10px 10px 15px rgba(0,0,0,0.025)'
                    }}>
                        {items.map((item, index) => {
                            return (
                                <div
                                key={item.key}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    <a
                                    className={`dropdown-item ${window.theme}`}
                                    onClick={() => {
                                        setOpenMenus([]);
                                        item.onClick({
                                            action: item.action,
                                            level: item.level,
                                            subView: item.key,
                                            view: key
                                        });
                                    }}
                                    style={{
                                        borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null,
                                        fontSize: 13,
                                        fontWeight: 400,
                                        color: item.isSubViewActive ? Appearance.colors.primary() : Appearance.colors.text()
                                    }}>{item.title}</a>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const getSubTitle = () => {
        return user && User.formatLevel(user.level).text || 'Unknown account type';
    }

    const getTitle = () => {
        return user ? user.full_name : 'Name Not Available';
    }

    useEffect(() => {
        setAnimations({ left: visible ? 0 : -window.innerWidth });
    },[visible]);

    useEffect(() => {
        onSetupClientIcon();
    }, []);

    return getContent();
}

export default Sidebar;
