import moment from 'moment-timezone';
import Abstract from 'classes/Abstract.js';
import User from 'classes/User.js';

class SystemEventClass {

    id = null;
    user = null;
    title = null;
    alt_title = null;
    date = null;
    action = null;
    props = null;
    target = null;
    diff = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user = props.user ? User.create(props.user) : null;
        this.title = props.title;
        this.alt_title = props.alt_title;
        this.date = props.date ? moment.utc(props.date).local() : null;
        this.action = props.action;
        this.props = props.props;
        this.diff = props.diff;
        this.target = Abstract.create({
            type: props.target.type,
            object: props.target.object
        });
        return this;
    }
}

export default {
    create: props => new SystemEventClass().create(props),
    actions: {
        create: 1,
        update: 2,
        delete: 3,
        warning: 4,
        note: 5
    },
}
