import React, { useRef, useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import AnimatedLoader from 'views/AnimatedLoader.js';
import LottieView from 'views/Lottie.js';
import { VelocityComponent } from 'velocity-react';

const Loader = ({ animate, children, onComplete, utils }) => {

    const onCompleteRef = useRef(onComplete);
    const [opacity, setOpacity] = useState(0);
    const [shouldRender, setShouldRender] = useState(false);

    const onLoadComplete = onComplete => {
        setShouldRender(false);
        utils.events.emit('load_complete');
        if(typeof(onCompleteRef.current) === 'function') {
            onCompleteRef.current();
        }
    }

    useEffect(() => {
        setOpacity(animate ? 0 : 1);
    }, [shouldRender]);

    useEffect(() => {
        onCompleteRef.current = onComplete;
    }, [onComplete])

    useEffect(() => {
        if(animate) {
            setShouldRender(true);
        }
    }, [animate]);

    return (
        <div style={{
            width: '100%',
            height: '100%'
        }}>
            <VelocityComponent
            duration={250}
            animation={{
                opacity: opacity
            }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                }}>
                    {children}
                </div>
            </VelocityComponent>

            {shouldRender && (
                <div style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }}>
                    <AnimatedLoader
                    loading={animate}
                    onLoadComplete={onLoadComplete.bind(this, onComplete)} />
                </div>
            )}
        </div>
    )
}
export default Loader;
