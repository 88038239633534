import React, { useEffect, useState } from 'react';
import Appearance from 'styles/Appearance.js';

const NumberStepper = ({ utils, max, min, onChange, startingValue, value }) => {

    const [text, setText] = useState(startingValue || min || '0');

    const onTextChange = (e) => {

        let newValue = e.target.value;
        setText(newValue);
        if(onChange && typeof(onChange) === 'function') {
            onChange(newValue);
        }
    }

    const onClick = (value) => {

        let newValue = parseInt(text) + parseInt(value);
        if(min !== undefined && newValue < min) {
            return;
        }
        if(max !== undefined && newValue > max) {
            return;
        }
        setText(newValue);
        if(onChange && typeof(onChange) === 'function') {
            onChange(newValue);
        }
    }

    useEffect(() => {
        if(startingValue) {
            setText(parseInt(startingValue));
        }
    }, [startingValue]);

    return (
        <div style={{
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           width: '100%'
       }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 30,
                borderRadius: 15,
                border: `1px solid ${Appearance.colors.softBorder()}`
            }}>
                <img
                className={'hover-pointer'}
                src={'images/minus-icon-grey-small.png'}
                onClick={onClick.bind(this, -1)}
                style={{
                    padding: 9,
                    paddingLeft: 12,
                    width: 30,
                    height: 30,
                    borderRight: `1px solid ${Appearance.colors.softBorder()}`,
                    objectFit: 'contain'
                }} />
                <div style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexGrow: 1,
                    textAlign: 'center',
                    backgroundColor: Appearance.colors.textField()
                }}>
                    <input
                    value={text}
                    onChange={onTextChange}
                    style={{
                        ...Appearance.textStyles.standard(),
                        border: 'none',
                        textAlign: 'center',
                        width: '100%',
                        height: '100%',
                        backgroundColor: Appearance.colors.textField()
                    }} />
                </div>
                <img
                className={'hover-pointer'}
                src={'images/plus-icon-grey-small.png'}
                onClick={onClick.bind(this, 1)}
                style={{
                    padding: 9,
                    paddingRight: 12,
                    width: 30,
                    height: 30,
                    borderLeft: `1px solid ${Appearance.colors.softBorder()}`,
                    objectFit: 'contain'
                }} />
            </div>
        </div>
    )
}
export default NumberStepper;
