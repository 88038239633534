import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Note from 'classes/Note.js';
import User from 'classes/User.js';

class FeedbackResponseClass {

    id = null;
    survey_name = null;
    questions = [];
    answers = [];
    user = null;
    recommendation = null;
    date = null;
    seeds = null;

    constructor() {
        return this;
    }

    create = props => {
        this.id = props.id;
        this.survey_name = props.survey_name;
        this.questions = props.questions;
        this.answers = props.answers;
        this.date = moment(props.date);
        this.user = props.user_data ? User.create(props.user_data) : null;

        if(props.recommendation) {
            let color = Appearance.colors.lightGrey;
            switch(props.recommendation) {
                case 'promoter':
                color = '#6CBF66';
                break;

                case 'passive':
                color = '#F9C939';
                break;

                case 'detractor':
                color = '#F85D57';
                break;
            }
            this.recommendation = {
                color: color,
                text: props.recommendation
            }
        }

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => {
                return n.deleted !== true;
            }).map(n => {
                return Note.create(n);
            })
        }

        return this;
    }
}
export default {
    new: () => new FeedbackResponseClass(),
    create: props => new FeedbackResponseClass().create(props)
};
