import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import PaymentMethodManager from 'views/PaymentMethodManager.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import Utils from 'files/Utils.js';

export const MakePayment = ({ abstract, index, options, utils }) => {

    const layerID = `make-payment-${abstract.getTag()}`;
    const [amount, setAmount] = useState('0');
    const [card, setCard] = useState(null);
    const [email_address, setEmailAddress] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState(null);

    const onSubmitPayment = async () => {
        if(isNaN(amount) || amount === '0') {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please enter an amount for this payment.'
            });
            return;
        }
        if(!card) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please choose an existing payment method to charge or add a new payment method.'
            });
            return;
        }
        if(!notes) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please add some notes describing the reason for this payment. These notes will be visible on the invoice.'
            });
            return;
        }
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/payment/', {
                type: 'new',
                amount: amount,
                card_id: card.id,
                company_id: abstract.type === 'companies' && abstract.getID(),
                email_address: abstract.type === 'users' ? abstract.object.email_address : email_address,
                notes: notes,
                payment_type: 'custom_payment',
                saas_account_id: abstract.type === 'saas' && abstract.getID(),
                token: card.token,
                user_id: abstract.type === 'users' && abstract.getID()
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The payment for ${Utils.toCurrency(amount)} has been processed for ${abstract.getTitle()}`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue processing this payment. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    return (
        <Layer
        id={layerID}
        title={`Make a Payment for ${abstract.getTitle()}`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            layerState: layerState,
            loading: loading,
            removePadding: true
        }}
        buttons={[{
            key: 'submit',
            text: 'Submit',
            color: 'primary',
            onClick: onSubmitPayment
        }]}>
            <div style={{
                padding: 12
            }}>
                <LayerItem
                title={'Amount'}
                shouldStyle={false}>
                    <TextField
                    value={amount}
                    prepend={'$'}
                    icon={'payment'}
                    placeholder={'$0.00'}
                    onChange={text => setAmount(text)}/>
                </LayerItem>

                <LayerItem
                title={'Payment Method'}
                shouldStyle={false}>
                    <PaymentMethodManager
                    utils={utils}
                    target={abstract}
                    addMethodToUser={false}
                    onChange={card => setCard(card)} />
                </LayerItem>

                <LayerItem
                lastItem={true}
                title={'Notes'}
                shouldStyle={false}>
                    <TextView
                    utils={utils}
                    onChange={text => setNotes(text)}
                    placeholder={'Custom payments need a description. This description is included in the payment invoice'} />
                </LayerItem>
            </div>
        </Layer>
    );
}
