import React, { useEffect, useRef, useState } from 'react';

import { fetchDrivers } from 'managers/Users.js';
import { fetchCompanies } from 'managers/Companies.js';
import { fetchVehicleCategories } from 'managers/Vehicles.js';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import { AddEditService, ServiceDetails } from 'managers/Reservations.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import { Calendar, MobileCalendar } from 'views/Calendar.js';
import Company from 'classes/Company.js';
import { CompanyDetails } from 'managers/Companies.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import EventManager from 'views/EventManager.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerMap, LayerEmissions, LayerItem, LayerNote } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import MultipleSelect from 'views/MultipleSelect.js';
import NoDataFound from 'views/NoDataFound.js';
import { NoteEntry } from 'views/NotesManager.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Polyline from '@mapbox/polyline';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import ReservationLookupField from 'views/ReservationLookupField.js';
import Route from 'classes/Route.js';
import Service from 'classes/Service.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views from 'views/Main.js';

// panels
export const QuickScanRoutesCalendar = ({ index, utils }) => {

    const panelID = 'quickScanCalendar';
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ calendar_style: Utils.isMobile() ? 'month' : 'week', calendar_offset: 0 });
    const [monthDateFilter, setMonthDateFilter] = useState(moment());
    const [windowState, setWindowState] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'QuickScan Route Calendar',
                dates: getDates(),
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onChangeCalendarOffset = val => {
        setLoading(true);
        setManager('calendar_offset', manager.calendar_offset + val);
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/quick_scan/', {
                    type: 'calendar',
                    ...formatResults(utils, props => ({
                        ...props,
                        ...getDates()
                    })),
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'Your Routes Calendar includes all applicable QuickScan Routes sorted by their pickup date and time',
            items: [{
                key: 'download',
                title: 'Download Routes',
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        if(manager.calendar_style !== 'week') {
            return [{
                key: 'previous',
                title: 'Previous Month',
                style: 'secondary',
                onClick: onChangeCalendarOffset.bind(this, -1)
            },{
                key: 'next',
                title: 'Next Month',
                style: 'primary',
                onClick: onChangeCalendarOffset.bind(this, 1)
            }]
        }
        return [{
            key: 'previous',
            title: 'Previous Week',
            style: 'secondary',
            onClick: onChangeCalendarOffset.bind(this, -1)
        },{
            key: 'next',
            title: 'Next Week',
            style: 'primary',
            onClick: onChangeCalendarOffset.bind(this, 1)
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        let targets = getEvents();
        return (
            <div style={{
                padding: manager.calendar_style === 'month' ? 0 : 10,
                position: 'relative'
            }}>
                {manager.calendar_style === 'month'
                    ?
                    <>
                    <MobileCalendar
                    utils={utils}
                    events={events}
                    activeDate={moment().startOf('month').add(manager.calendar_offset, 'months').format('YYYY-MM-DD')}
                    showOverflow={windowState === 'fullscreen'}
                    onClick={route => Utils.routes.details(utils, route)}
                    onDateChange={date => setMonthDateFilter(date)}
                    eventFilter={(day, { route }) => {
                        return day.isSame(route.date, 'day');
                    }} />
                    {targets.length > 0 && (
                        <div style={{
                            borderTop: `1px solid ${Appearance.colors.divider()}`
                        }}>
                            {targets.map(({ route }, index) => {
                                return (
                                    Views.entry({
                                        key: route.id,
                                        title: `${route.name || `Route #${route.id}`} for ${route.driver.full_name}`,
                                        subTitle: route.date.format('MMMM Do, YYYY [at] h:mma'),
                                        badge: [{
                                            text: route.status.text,
                                            color: route.status.color
                                        }],
                                        icon: {
                                            path: route.driver.avatar,
                                            style: {
                                                ...Appearance.icons.standard(),
                                                alignSelf: 'flex-start'
                                            }
                                        },
                                        onClick: Utils.routes.details.bind(this, utils, route),
                                        bottomBorder: index !== targets.length - 1,
                                    })
                                )
                            })}
                        </div>
                    )}
                    </>
                    :
                    <div style={{
                        position: 'relative'
                    }}>
                        <Calendar
                        defaultDate={moment().startOf('week').add(manager.calendar_offset, 'weeks').format('YYYY-MM-DD')}
                        events={events}
                        utils={utils}
                        channel={'quick_scan'}
                        eventType={'route'}
                        showOverflow={windowState === 'fullscreen'}
                        onClick={route => Utils.routes.details(utils, route)}/>
                        {events.length === 0 && (
                            <NoDataFound message={'No Routes are booked for this week'} />
                        )}
                    </div>
                }
            </div>
        )
    }

    const getDates = () => {
        let { calendar_offset, calendar_style } = manager || {};
        return {
            ...calendar_style === 'month' && {
                start_date: moment().startOf('month').add(calendar_offset, 'months').format('YYYY-MM-DD'),
                end_date: moment().endOf('month').add(calendar_offset, 'months').format('YYYY-MM-DD')
            },
            ...calendar_style === 'week' && {
                start_date: moment().startOf('week').add(calendar_offset, 'weeks').format('YYYY-MM-DD'),
                end_date: moment().endOf('week').add(calendar_offset, 'weeks').format('YYYY-MM-DD')
            }
        }
    }

    const getEvents = () => {
        if(manager.calendar_style === 'month') {
            return events.filter(({ route }) => {
                return route && route.date.isSame(monthDateFilter, 'day');
            })
        }
        let startDate = moment().startOf('week').add(manager.calendar_offset, 'weeks');
        let endDate = moment().endOf('week').add(manager.calendar_offset, 'weeks');
        return events.filter(({ route }) => {
            return route && route.date >= startDate && route.date <= endDate;
        })
    }

    const getTitle = () => {
        if(manager.calendar_style === 'month') {
            return `QuickScan Route for ${moment().startOf('month').add(manager.calendar_offset, 'months').format('MMMM YYYY')}`
        }
        return 'QuickScan Route Calendar';
    }

    const fetchRoutes = async () => {
        try {
            let { routes } = await Request.get(utils, '/quick_scan/', {
                type: 'calendar',
                ...formatResults(utils, props => ({
                    ...props,
                    ...getDates()
                }))
            });

            setLoading(false);
            setEvents(routes.map(props => {
                let route = Route.create(props);
                return {
                    allDay: true,
                    color: Appearance.colors.transparent,
                    id: route.id,
                    route: route,
                    start: route.date.format('YYYY-MM-DD HH:mm:ss'),
                    stick: true
                }
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the calendar. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchRoutes();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'routes', {
            onFetch: fetchRoutes,
            onRemove: abstract => {
                setEvents(events => {
                    return events.filter(evt => evt.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setEvents(events => {
                    return events.map(evt => {
                        if(evt.id !== abstract.getID()) {
                            return evt;
                        }
                        return {
                            ...evt,
                            route: abstract.object,
                            start: abstract.object.date.format('YYYY-MM-DD HH:mm:ss')
                        }
                    });
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getTitle()}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            onWindowStateChange: state => setWindowState(state),
            buttons: getButtons(),
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const QuickScanRoutesList = ({ category }, { index, utils }) => {

    const panelID = `${category}QuickScanRoutes`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: getInfo().title,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/quick_scan/', {
                    type: 'all_routes_admin',
                    category: category,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewRoute = () => {
        utils.layer.open({
            id: 'new-quick-scan-route',
            abstract: Abstract.create({
                type: 'routes',
                object: Route.new()
            }),
            Component: NewQuickScanRoute
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all ${category} routes in the system. Routes are containers that hold multiple reservations for the same vehicle`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Routes'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(routes.length === 0) {
            return (
                Views.entry({
                    title: 'No Routes Found',
                    subTitle: `There are no ${category} routes available to view`,
                    borderBottom: false
                })
            )
        }
        return routes.map((route, index) => {
            return (
                Views.entry({
                    key: index,
                    title: `${route.name || `Route #${route.id}`}${route.driver ? ` for ${route.driver.full_name}` : ''}`,
                    subTitle: moment(route.date).format('MMMM Do, YYYY [at] h:mma'),
                    badge: [{
                        text: route.status.text,
                        color: route.status.color
                    }],
                    icon: route.driver && {
                        path: route.driver.avatar
                    },
                    onClick: Utils.routes.details.bind(this, utils, route),
                    bottomBorder: index !== routes.length - 1
                })
            )
        });
    }

    const getInfo = () => {
        switch(category) {
            case 'all':
            return {
                title: 'All QuickScan Routes',
                message: ''
            }

            case 'new':
            return {
                title: 'Newly Created QuickScan Routes',
                message: ''
            }

            case 'active':
            return {
                title: 'Active QuickScan Routes',
                message: ''
            }

            default:
            return {
                title: 'QuickScan Routes',
                message: ''
            }
        }
    }

    const fetchRoutes = async () => {
        try {
            let { paging, routes } = await Request.get(utils, '/quick_scan/', {
                type: 'all_routes_admin',
                limit: limit,
                category: category,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setRoutes(routes.map(route => Route.create(route)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${category} routes list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchRoutes();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'routes', {
            onFetch: fetchRoutes,
            onRemove: abstract => {
                setRoutes(routes => {
                    return routes.filter(route => route.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setRoutes(routes => {
                    return routes.map(route => abstract.compare(route));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getInfo().title}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Route',
                style: 'default',
                onClick: onNewRoute
            }],
            search: {
                placeholder: 'Search by route id or driver name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const QuickScanServiceActivity = ({ methods, index, utils }) => {

    const chart = useRef(null);
    const panelID = 'quickScanServiceActivity';

    const [chartData, setChartData] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(12, 'months') });
    const [loading, setLoading] = useLoading();
    const [service, setService] = useState(null);
    const [services, setServices] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'QuickScan Services Activity',
                chart: chart,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/quick_scan/', {
                    type: 'services_activity',
                    id: service ? service.id : services[0].id,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'The graph shows the overall service usage sorted by the month they were used. The left side shows the amount a service was used and the bottom shows the month they were used. The graph shows the last 12 months',
            items: [{
                key: 'download',
                title: 'Download Activity',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div style={{
                position: 'relative',
                height: 350,
                padding: 12
            }}>
                {!chartData || chartData.labels.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of service usage are needed for the graph'} />
                    :
                    <Line
                    ref={chart}
                    width={500}
                    height={100}
                    data={{
                        labels: chartData.labels,
                        datasets: [{
                            data: chartData.data,
                            borderColor: Appearance.colors.primary(),
                            fill: true,
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                            pointRadius: 5,
                            pointBorderWidth: 2,
                            pointBackgroundColor: 'white'
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return tooltipItem.yLabel + (parseInt(tooltipItem.yLabel) === 1 ? ' Booking' : ' Bookings');
                                }
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 12
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value, index, values) => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
        )
    }

    const getDateSelector = () => {
        return (
            <DualDatePickerField
            utils={utils}
            selectedStartDate={dates.start_date}
            selectedEndDate={dates.end_date}
            style={{
                maxWidth: Utils.isMobile() === false ? 350 : null
            }}
            onStartDateChange={date => {
                setDates(dates => {
                    return {
                        ...dates,
                        start_date: date
                    }
                })
            }}
            onEndDateChange={date => {
                setDates(dates => {
                    return {
                        ...dates,
                        end_date: date
                    }
                })
            }} />
        )
    }

    const getSearchField = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div
            className={'row m-0'}
            style={{
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 p-0'}>
                    {getServiceSelector()}
                </div>
                <div className={'col-12 col-lg-6 px-0 pt-2 pb-0 p-lg-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        {getDateSelector()}
                    </div>
                </div>
            </div>
        )
    }

    const getServiceSelector = () => {
        return (
            <select
            className={`custom-select ${window.theme}`}
            defaultValue={service ? service.name : null}
            style={{
                maxWidth: Utils.isMobile() ? null : 150
            }}
            onChange={e => {
                setLoading(true);
                setService(services.find(service => service.id === parseInt(Utils.attributeForKey.select(e, 'id'))))
            }}>
                {services.map((service, index) => {
                    return (
                        <option key={index} id={service.id}>{service.name}</option>
                    )
                })}
            </select>
        )
    }

    const fetchActivity = async () => {
        try {
            if(!service && services.length === 0){
                return;
            }
            let { results } = await Request.get(utils, '/quick_scan/', {
                end_date: moment(dates.end_date).utc().unix(),
                id: service ? service.id : null,
                start_date: moment(dates.start_date).utc().unix(),
                type: 'services_activity'
            });

            setLoading(false);
            setChartData({
                data: results.map(entry => entry.total),
                labels: results.map(entry => moment.utc(entry.date).local().format('MMM YYYY'))
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the services activity breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchServices = async () => {
        try {
            let { services } = await fetchQuickScanServices(utils);
            setServices(services);
            setService(services && services.length > 0 ? services[0] : null);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the quick scan services. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchActivity();
    }, [dates, service]);

    useEffect(() => {
        fetchServices();
        utils.content.subscribe(panelID, ['routes', 'services'], {
            onFetch: fetchActivity,
            onUpdate: abstract => {
                if(abstract.type !== 'services') {
                    return;
                }
                setServices(services => {
                    return services.map(service => abstract.compare(service));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'QuickScan Service Activity'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getSearchField()}
            {getContent()}
        </Panel>
    )
}

export const QuickScanServices = ({ index, utils }) => {

    const panelID = 'quickScanServices';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [services, setServices] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'QuickScan Services Activity',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchQuickScanServices(utils, {
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewService = () => {
        let service = Service.new();
        service.channel = 'quick_scan';
        utils.layer.open({
            id: 'new-service',
            abstract: Abstract.create({
                type: 'services',
                object: service
            }),
            Component: AddEditService.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all active and inactive quick scan services in the system. A quick scan service is a set of guidelines for movements made during a reservation when a customer has chosen to hop in the car and scan the driver's qr code`,
            items: [{
                key: 'download',
                title: 'Download Services',
                style: 'default'
            }]
        }
    }

    const getBadges = service => {
        let badges = [];
        if(service.default_for_channel) {
            badges.push({
                text: 'Default',
                color: Appearance.colors.primary()
            });
        }
        if(service.active === false) {
            badges.push({
                text: 'Not Active',
                color: Appearance.colors.grey()
            });
        }
        return badges;
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(services.length === 0) {
            return (
                Views.entry({
                    title: 'No Services Found',
                    subTitle: 'There are no quick scan services available to view',
                    borderBottom: false
                })
            )
        }
        return services.map((service, index) => {
            return (
                Views.entry({
                    key: index,
                    title: service.name,
                    subTitle: service.information,
                    badge: getBadges(service),
                    bottomBorder: (index !== services.length - 1),
                    onClick: () => {
                        utils.layer.open({
                            id: `service-details-${service.id}`,
                            abstract: Abstract.create({
                                type: 'services',
                                object: service
                            }),
                            Component: ServiceDetails
                        })
                    }
                })
            )
        });
    }

    const fetchServices = async () => {
        try {
            let { paging, services} = await fetchQuickScanServices(utils, {
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setServices(services);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the quick scan services. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchServices();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'services', {
            onFetch: fetchServices,
            onRemove: abstract => {
                setServices(services => {
                    return services.filter(service => {
                        return service.id !== abstract.getID();
                    });
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'QuickScan Services'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Service',
                style: 'default',
                onClick: onNewService
            }],
            search: {
                palceholder: 'Search by service name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const QuickScanCategories = ({ index, utils }) => {

    const panelID = 'quickScanRouteCategories';
    const limit = 5;

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'QuickScan Route Categories',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onCategoryClick = category => {
        utils.layer.open({
            id: `route-category-details-${category.id}`,
            abstract: Abstract.create({
                type: 'routeCategories',
                object: category
            }),
            Component: QuickScanRouteCategoryDetails
        });
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetchQuickScanCategories(utils, {
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewCategory = () => {
        utils.layer.open({
            id: 'new-quick-scan-route-category',
            abstract: Abstract.create({
                type: 'routeCategories',
                object: Route.Category.new()
            }),
            Component: AddEditQuickScanRouteCategory.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all Route Categories that are available during a QuickScan booking. A Route Category is a collection of Route Options that are available during booking',
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Route Categories'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(categories.length === 0) {
            return (
                Views.entry({
                    title: 'No Route Options Found',
                    subTitle: 'There are no quick scan route options available to view',
                    borderBottom: false
                })
            )
        }
        return categories.map((category, index) => {
            return (
                Views.entry({
                    key: index,
                    title: category.name,
                    subTitle: category.options ? `${category.options.length} Route ${category.options.length === 1 ? 'Option' : 'Options'}` : 'No Route Options Found',
                    badge: !category.active && {
                        text: 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    bottomBorder: index !== categories.length - 1,
                    onClick: onCategoryClick.bind(this, category)
                })
            )
        });
    }

    const fetchCategories = async () => {
        try {
            let { categories, paging } = await fetchQuickScanCategories(utils, {
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCategories(categories);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the quick scan categories. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchCategories();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, ['routeOptions', 'routeCategories'], {
            onFetch: fetchCategories,
            onRemove: abstract => {
                if(abstract.type !== 'routeCategories') {
                    return;
                }
                setCategories(categories => {
                    return categories.filter(category => category.id !== abstract.getID())
                });
            },
            onUpdate: abstract => {
                switch(abstract.type) {
                    case 'routeCategories':
                    setCategories(categories => {
                        return categories.map(category => abstract.compare(category));
                    });
                    break;

                    case 'routeOptions':
                    setCategories(categories => {
                        return categories.map(category => {
                            if(abstract.object.category && category.id !== abstract.object.category.id) {
                                return category;
                            }
                            category.options = category.options.filter(opt => opt.id !== abstract.getID());
                            category.options.push(abstract.object);
                            return category;
                        });
                    });
                    break;
                }
            }
        })
        return () => {
            utils.content.unsubscribe(panelID)
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'QuickScan Route Categories'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Category',
                style: 'default',
                onClick: onNewCategory
            }],
            search: {
                placeholder: 'Search by route category name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const QuickScanRouteOptions = ({ index, utils }) => {

    const panelID = 'quickScanRouteOptions';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [options, setOptions] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'QuickScan Route Options',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/quick_scan/',  {
                    type: 'list_route_options_admin',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewOption = () => {
        utils.layer.open({
            id: 'new-quick-scan-route-option',
            abstract: Abstract.create({
                type: 'routeOption',
                object: Route.Option.new()
            }),
            Component: AddEditQuickScanRouteOption.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onOptionClick = option => {
        utils.layer.open({
            id: `route-option-details-${option.id}`,
            abstract: Abstract.create({
                type: 'routeOptions',
                object: option
            }),
            Component: QuickScanRouteOptionDetails
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all Route Options that are available during a QuickScan booking. A Route Option normally holds a name, description, starting/pickup point, and a QuickScan Service that dictates Reservation movements',
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Route Options'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(options.length === 0) {
            return (
                Views.entry({
                    title: 'No Route Options Found',
                    subTitle: 'There are no quick scan route options available to view',
                    borderBottom: false
                })
            )
        }
        return options.map((option, index) => {
            return (
                Views.entry({
                    key: option.id,
                    title: option.name,
                    subTitle: option.category ? option.category.name : 'Unknown Category',
                    badge: !option.active && {
                        text: 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    bottomBorder: index !== options.length - 1,
                    onClick: onOptionClick.bind(this, option)
                })
            )
        });
    }

    const fetchOptions = async () => {
        try {
            let { options, paging } = await Request.get(utils, '/quick_scan/',  {
                type: 'list_route_options_admin',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setOptions(options.map(opt => Route.Option.create(opt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the quick scan options list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOptions();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'routeOptions', {
            onFetch: fetchOptions,
            onRemove: abstract => {
                setOptions(options => {
                    return options.filter(opt => opt.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setOptions(opts => {
                    return opts.map(opt => abstract.compare(opt));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'QuickScan Route Options'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Route Option',
                style: 'default',
                onClick: onNewOption
            }],
            search: {
                text: 'Search by route option name..',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditQuickScanRouteCategory = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-quick-scan-route-category' : `edit-quick-scan-route-category-${abstract.getID()}`;
    const [category, setCategory] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" route category has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this route category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setCategory(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!category) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a QuickScan category is displayed to the customer and driver when they are interacting with categories and category options.',
                value: category.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'information',
                title: 'Description',
                description: 'The description for a QuickScan category is displayed to the customer and driver when they are interacting with categories and category options.',
                value: category.information,
                component: 'textview',
                onChange: text => onUpdateTarget({ information: text })
            },{
                key: 'service',
                required: false,
                title: 'Service',
                description: 'An service can be attached to a route category to override the default service set for all QuickScan routes.',
                value: category.service,
                component: 'list',
                onChange: item => onUpdateTarget({ service: item }),
                items: services.map(service => ({
                    id: service.id,
                    title: service.name
                }))
            },{
                key: 'companies',
                required: false,
                title: 'Companies',
                description: 'Assigning one or more companies to a route category retricts the category to employees of the selected companies.',
                component: 'multiple_list',
                value: category.companies && category.companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: items => {
                    onUpdateTarget({
                        companies: items && companies.filter(company => {
                            return items.find(item => {
                                return item.id === company.id;
                            });
                        })
                    });
                }
            }]
        }];
    }

    const setupTargets = async () => {
        try {
            let edits = abstract.object.open();
            setCategory(edits);

            let { services } = await fetchQuickScanServices(utils);
            setServices(services);

            let { companies } = await fetchCompanies(utils);
            setCompanies(companies);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the quick scan serivces and company targets. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New QuickScan Route Category' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setCategory(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditQuickScanRouteOption = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-quick-scan-route-option' : `edit-quick-scan-route-option-${abstract.getID()}`;
    const [categories, setCategories] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [option, setOption] = useState(null);
    const [services, setServices] = useState([]);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" route option has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this route option. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setOption(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!option) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a QuickScan route option is displayed to the customer and driver when they are interacting with QuickScan rides',
                value: option.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'address',
                title: 'Address',
                description: 'The address for a QuickScan route option is displayed to the customer and driver when they are interacting with QuickScan rides. We use this address to lookup coordinates for routing and payment processing',
                value: option.address,
                component: 'address_lookup',
                onChange: place => {
                    onUpdateTarget({
                        location: place ? place.location : null,
                        address: place ? Utils.formatAddress(place.address) : null
                    });
                }
            },{
                key: 'category',
                title: 'Category',
                description: 'The category for a route option tells us when and where to offer this route option when booking a QuickScan ride.',
                value: option.category,
                component: 'list',
                items: categories.map(category => ({
                    id: category.id,
                    title: category.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        category: item && categories.find(category => {
                            return category.id === item.id
                        })
                    });
                }
            },{
                key: 'service',
                required: false,
                title: 'Service',
                description: 'An service can be attached to a route category to override the default service set for all QuickScan routes',
                value: option.service,
                component: 'list',
                items: services.map(service => ({
                    id: service.id,
                    title: service.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        service: item && services.find(service => {
                            return service.id === item.id
                        })
                    });
                }
            }]
        }];
    }

    const setupTargets = async () => {
        try {
            let edits = abstract.object.open();
            setOption(edits);

            let { categories } = await fetchQuickScanCategories(utils);
            setCategories(categories);

            let { services } = await fetchQuickScanServices(utils);
            setServices(services);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the quick scan categories, serivces, and company targets. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'New QuickScan Route Option'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setOption(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const NewQuickScanRoute = ({ abstract, index, options, utils }) => {

    const layerID = 'new-quick-scan-route';
    const [categories, setCategories] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [route, setRoute] = useState(null);
    const [vehicles, setVehicles] = useState([]);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.submit(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" route has been created`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating this route. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setRoute(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!route) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a QuickScan route is visible to administrators and drivers when interacting with this route in Seeds and the mobile app. Names are optional for a route but it is recommended that each route be given a custom name to help easily find it when needed.',
                value: route.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'driver',
                title: 'Driver',
                description: 'Routes are typically created by an admin or a driver in the mobile app. An admin or a driver must be assigned to a route so we can inform the customer of who will be completing their trip.',
                value: route.driver,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ driver: user }),
                props: {
                    levels: [ User.level.admin, User.level.driver ]
                }
            },{
                key: 'vehicle',
                title: 'Vehicle',
                description: 'The vehicle assigned to a route helps calculate the estimated and final cost for each reservation. The vehicle that is selected should be the vehicle that is driven for the reservation. It is recommended that the friver create the route if you are unsure what vehicle will be used.',
                value: route.vehicle,
                component: 'list',
                items: vehicles.map(vehicle => ({
                    id: vehicle.id,
                    title: vehicle.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        vehicle: item && vehicles.find(vehicle => {
                            return vehicle.id === item.id;
                        })
                    });
                }
            },{
                key: 'categories',
                required: false,
                visible: categories.length > 0,
                title: 'Categories',
                description: 'A route has the ability to be open-ended or restricted to predefined stops. An open-ended route would allow a customer to select any destination within the regular booking parameters for a trip.',
                value: route.categories && route.categories.map(category => ({
                    id: category.id,
                    title: category.name
                })),
                component: 'multiple_list',
                items: categories.map(category => ({
                    id: category.id,
                    title: category.name
                })),
                onChange: items => {
                    onUpdateTarget({
                        categories: items && categories.filter(category => {
                            return items.find(item => {
                                return item.id === category.id;
                            });
                        })
                    });
                }
            }]
        }];
    }

    const setupTargets = async () => {
        try {
            let edits = abstract.object.open();
            setRoute(edits);

            let quickScanCategories = await fetchQuickScanCategories(utils);
            setCategories(quickScanCategories.categories);

            let vehicleCategories = await fetchVehicleCategories(utils, { limit: 100 });
            setVehicles(vehicleCategories.categories);

            let { drivers } = await fetchDrivers(utils, { show_admin: true });
            setDrivers(drivers);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the vehicle categories, quick scan categories, and driver targets. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'New QuickScan Route'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setRoute(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const QRCodeViewer = ({ id, title, url }, { options, index, utils }) => {
    return (
        <Layer
        id={id}
        title={title || 'QR Code'}
        index={index}
        options={{
            ...options,
            sizing: 'small',
            removePadding: true
        }}>
            <img
            src={url}
            style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain'
            }} />
        </Layer>
    )
}

export const QuickScanRouteDetails = ({ abstract, index, options, utils }) => {

    const layerID = `route-details-${abstract.getID()}`;
    const [annotations, setAnnotations] = useState([]);
    const [dropDown, setDropDown] = useState(null);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [overlays, setOverlays] = useState(null);
    const [route, setRoute] = useState(abstract.object);

    const onAddReservation = () => {
        let reservation = null;
        setDropDown({
            title: 'Add a Reservation',
            message: `Reservation are typically booked and added to a route by the customer. If needed, you can manually attach a reservation to this route. Keep in mind that any reservation additions will change the driver's route and directions`,
            buttons: [{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => {
                    if(reservation) {
                        onAddReservationConfirm(reservation);
                    }
                }
            }],
            content: (
                <ReservationLookupField
                utils={utils}
                onChange={selected => reservation = selected}
                resultsFilter={reservation => {
                    return [
                        Reservation.status.approved,
                        Reservation.status.returned,
                        Reservation.status.preauthorized,
                        Reservation.status.preauthorization_failed,
                        Reservation.status.preauthorization_revoked,
                        Reservation.status.admin_updated,
                        Reservation.status.driver_updated,
                        Reservation.status.customer_edited
                    ].includes(reservation.status.code)
                }}/>
            )
        });
    }

    const onAddReservationConfirm = async reservation => {
        try {
            setLoading(true);
            await Request.post(utils, '/quick_scan/', {
                type: 'add_reservation',
                route_id: abstract.getID(),
                reservation_id: reservation.id
            });

            // reload routing and emissions
            await abstract.object.reload(utils);
            setRoute({ ...abstract.object });

            setLoading(false);
            utils.content.update(abstract);
            utils.content.fetch('reservations');
            utils.alert.show({
                title: 'All Done!',
                message: `Reservation #${reservation.id} has been added from this route`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding this reservation. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCancelClick = () => {
        if(route.status.code === Route.status.cancelled) {
            utils.alert.show({
               title: 'Oops!',
               message: 'It looks like this route has already been cancelled'
           });
           return;
        }
        utils.alert.show.bind(this, {
            title: 'Cancel Route',
            message: 'Are you sure you want to cancel this route? This will cancel all non-completed reservations for this route',
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'destructive'
            },{
                key: 'reject',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onCancelRouteConfirm();
                    return;
                }
            }
        });
    }

    const onCancelRouteConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { reservations } = await Request.post(utils, '/quick_scan/', {
                type: 'set_status',
                route_id: route.id,
                status: Route.status.cancelled
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.getTitle()} has been cancelled and will no longer accept new reservations`
            });

            // update reservations for route
            abstract.object.status = Route.status.cancelled;
            if(reservations) {
                if(abstract.object.reservations && Array.isArray(abstract.object.reservations)) {
                    abstract.object.reservations.map(reservation => {
                        reservations.forEach(res => {
                            if(res.id === reservation.id) {
                                reservation.status = Reservation.setStatus(res.status);
                            }
                        })
                        return reservation;
                    });
                }
            }
            setRoute({ ...abstract.object });
            utils.content.update(abstract);
            utils.content.fetch('reservations');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling this route. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCategoryClick = category => {
        utils.layer.open({
            id: `route-category-details-${category.id}`,
            abstract: Abstract.create({
                type: 'routeCategories',
                object: category
            }),
            Component: QuickScanRouteCategoryDetails
        });
    }

    const onDeleteReservation = () => {
        // TODO => implement delete reservation logic
        utils.alert.dev();
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'add',
                title: 'Add Reservation',
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete Route',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, {
                    type: 'routes',
                    object: route
                });
                return;
            }
            if(key === 'add') {
                onAddReservation();
                return;
            }
            if(key === 'delete') {
                onDeleteReservation();
                return;
            }
        });
    }

    const onRemoveReservation = reservation => {
        utils.alert.show({
            title: 'Remove from Route',
            message: 'Are you sure that you want to remove this Reservation from the Route? This should only be done if the Reservation was booked by mistake',
            buttons: [{
                key: 'remove',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'remove') {
                    onRemoveReservationConfirm(reservation);
                    return;
                }
            }
        })
    }

    const onRemoveReservationConfirm = async reservation => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/quick_scan/', {
                type: 'remove_reservation',
                route_id: route.id,
                reservation_id: reservation.id
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Reservation #${reservation.id} has been removed from this route`
            });

            // reload routing and emissions
            await abstract.object.reload(utils);
            abstract.object.reservations = abstract.object.reservations.filter(r => r.id !== reservation.id);
            setRoute({ ...abstract.object });

            // notify subscribers of content change
            utils.content.update(abstract);
            utils.content.fetch('reservations');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this reservation. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onReservationClick = reservation => {
        utils.sheet.show({
            items: [{
                key: 'view',
                title: 'View Reservation',
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove from Route',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'view') {
                Utils.reservations.details(utils, reservation);
                return;
            }
            if(key === 'remove') {
                onRemoveReservation(reservation);
                return;
            }
        });
    }

    const onShowQRCode = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { url } = await Request.get(utils, '/quick_scan/', {
                type: 'get_qr_code',
                route_id: route.id
            });

            setLoading(false);
            let layerID = `qr-code-viewer-${route.id}`;
            utils.layer.open({
                id: layerID,
                Component: QRCodeViewer.bind(this, {
                    id: layerID,
                    title: `QR Code for ${abstract.getTitle()}`,
                    url: url
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the qr code for this route. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateLocations = () => {
        setAnnotations(route.reservations.map(reservation => {
            return {
                title: reservation.customer.full_name,
                subTitle: reservation.destination.address,
                location: reservation.destination.location,
                icon: { type: 'broadcast' },
                data: {
                    key: `reservation-${reservation.id}`
                }
            };
        }));
    }

    const getBottomFields = () => {
        return [{
            key: 'resources',
            title: 'Resources',
            items: [{
                key: 'qr_code',
                title: 'QR Code',
                value: 'Click to View',
                onClick: onShowQRCode
            },{
                key: 'status',
                title: 'Events and Status Changes',
                value: 'Click to View',
                onClick: getEventLog
            },{
                key: 'status',
                title: 'Status',
                value: route.status ? route.status.text : null
            },{
                key: 'vehicle',
                title: 'Vehicle',
                value: route.vehicle ? route.vehicle.name : null
            }]
        }];
    }

    const getEventLog = () => {
        setDropDown({
            title: 'Events',
            message: 'The Route event log shows the journey of the route from creation to completion and includes additional information about log events when applicable. Events should only be manually added as a last resort',
            content: (
                <EventManager
                utils={utils}
                route={route} />
            )
        });
    }

    const getTopFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: route.id
            },{
                key: 'date',
                title: 'Date',
                value: Utils.formatDate(route.date)
            },{
                key: 'status',
                title: 'Status',
                value: route.status ? route.status.text : null
            },{
                key: 'vehicle',
                title: 'Vehicle',
                value: route.vehicle ? route.vehicle.name : null
            }]
        }];
    }

    const fetchDetails = async () => {
        try {
            console.log({
                type: 'details',
                id: abstract.getID()
            })
            let { route } = await Request.get(utils, '/quick_scan/', {
                type: 'details',
                id: abstract.getID()
            })

            // update abstract target
            abstract.object.routing = route.routing;
            abstract.object.emissions = route.emissions;
            utils.content.update(abstract);

            // update overlays if applicable
            if(route.routing && route.routing.optimized) {
                setOverlays(route.routing.optimized.legs.map((leg, index) => {
                    return {
                        key: index,
                        coordinates: Polyline.decode(leg.shape, 6)
                    }
                }));
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the routing details for this route. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(route.seeds && route.seeds.notes) {
            setNote(route.seeds.notes.find(note => note.deleted !== true));
        }
    }, [route]);

    useEffect(() => {
        fetchDetails();
        onUpdateLocations();
    }, [route.reservations]);

    useEffect(() => {
        utils.content.subscribe(layerID, ['routes', 'reservations'], {
            onUpdate: next => {
                switch(next.type) {
                    case 'routes':
                    setRoute(route => next.compare(route));
                    break;

                    case 'reservations':
                    setRoute(route => {
                        route.reservations = route.reservations.map(reservation => {
                            return next.compare(reservation);
                        });
                        return route;
                    });
                    break;
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    })

    return (
        <Layer
        id={layerID}
        title={`${abstract.getTitle()} Details`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: 'danger',
            onClick: onCancelClick
        },{
            key: 'options',
            text: 'Options',
            color: 'primary',
            onClick: onOptionsClick
        }]}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                position: 'relative',
                marginBottom: 25
            }}>
                <LayerMap
                utils={utils}
                annotations={annotations}
                overlays={overlays} />
                {(!route.reservations || route.reservations.length === 0) && (
                    <NoDataFound
                    title={'Routing Not Available'}
                    message={'No reservations have been added to this route'}
                    fill={Appearance.styles.unstyledPanel().backgroundColor}/>
                )}
            </div>

            {route.emissions && route.reservations && route.reservations.length > 1 && (
                <LayerEmissions items={[{
                    key: 'trees',
                    title: route.emissions.trees === 1 ? ' Tree Planted' : ' Trees Planted',
                    placeholder: '0',
                    value: route.emissions.trees,
                    image: 'trees-icon-clear.png'
                },{
                    key: 'gas',
                    title: 'Gallons of Gas',
                    placeholder: '0',
                    value: route.emissions.gas,
                    image: 'gas-icon-clear.png'
                },{
                    key: 'carbon',
                    title: 'Grams of CO2',
                    placeholder: '0',
                    value: route.emissions.carbon,
                    image: 'carbon-icon-clear.png'
                }]} />
            )}

            {route.driver && (
                <LayerItem title={'Driver'}>
                    {Views.entry({
                        key: route.driver.user_id,
                        title: route.driver.full_name,
                        subTitle: route.driver.phone_number,
                        icon: {
                            path: route.driver.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, route.driver)
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getTopFields()} />

            {route.reservations && route.reservations.length > 0 && (
                <LayerItem title={'Reservations'}>
                    {route.reservations.map((reservation, index, reservations) => {
                        return (
                            Views.entry({
                                key: index,
                                title: `${reservation.customer.full_name} (${reservation.id})`,
                                subTitle: `To ${reservation.origin.address}`,
                                badge: reservation.status,
                                icon: {
                                    path: reservation.customer.avatar
                                },
                                bottomBorder: index !== reservations.length - 1,
                                onClick: onReservationClick.bind(this, reservation)
                            })
                        )
                    })}
                </LayerItem>
            )}

            {route.categories && route.categories.length > 0 && (
                <LayerItem title={'Route Categories'}>
                    {route.categories.map((category, index, categories) => {
                        return (
                            Views.entry({
                                key: category.id,
                                title: category.name,
                                subTitle: category.information,
                                bottomBorder: index !== categories.length - 1,
                                onClick: onCategoryClick.bind(this, category)
                            })
                        )
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getBottomFields()} />

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const QuickScanRouteCategoryDetails = ({ abstract, index, options, utils }) => {

    const layerID = `route-category-details-${abstract.getID()}`;
    const limit = 5;

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [routeCategory, setRouteCategory] = useState(abstract.object);

    const onCompanyClick = company => {
        utils.layer.open({
            id: `company-details-${company.id}`,
            abstract: Abstract.create({
                type: 'companies',
                object: company
            }),
            Component: CompanyDetails
        });
    }

    const onOptionClick = option => {
        utils.layer.open({
            id: `route-option-details-${option.id}`,
            abstract: Abstract.create({
                type: 'routeOptions',
                object: option
            }),
            Component: QuickScanRouteOptionDetails
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit Category',
                style: 'default'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, {
                    type: 'routeCategories',
                    object: routeCategory
                });
                return;
            }
            if(key === 'edit') {
                utils.layer.open({
                    id: `edit-quick-scan-route-category-${routeCategory.id}`,
                    abstract: abstract,
                    Component: AddEditQuickScanRouteCategory.bind(this, {
                        isNewTarget: false
                    })
                });
                return;
            }
        });
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `Set as ${routeCategory.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set this route category as ${routeCategory.active ? 'inactive' : 'active'}? This will ${routeCategory.active ? 'no longer allow' : 'allow'} customers to book routes using this option.`,
            buttons: [{
                key: 'confirm',
                title: routeCategory.active ? 'Deactivate' : 'Activate',
                style: routeCategory.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: routeCategory.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: routeCategory.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/quick_scan/', {
                type: 'set_category_status',
                id: routeCategory.id,
                active: next
            });

            setLoading(false);
            abstract.object.active = next;
            utils.content.update(abstract)
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.getTitle()} has been set as ${next ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this route category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getCompanies = () => {
        if(!routeCategory.companies || routeCategory.companies.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Companies'}>
                {routeCategory.companies.map((company, index, companies) => {
                    return (
                        Views.entry({
                            key: index,
                            title: company.name,
                            subTitle: `Member Since: ${moment(company.member_since).format('MMMM Do, YYYY')}`,
                            icon: {
                                style: Appearance.icons.standard(),
                                path: company.image
                            },
                            bottomBorder: index !== companies.length - 1,
                            onClick: onCompanyClick.bind(this, company)
                        })
                    )
                })}
            </LayerItem>
        )
    }

    const getFields = () => {
        let fields = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: routeCategory.id
            },{
                key: 'name',
                title: 'Name',
                value: routeCategory.name
            },{
                key: 'information',
                title: 'Description',
                value: routeCategory.information
            },{
                key: 'active',
                title: 'Status',
                value: routeCategory.active ? 'Active' : 'Not Active'
            }]
        }]
        if(routeCategory.service) {
            fields = fields.concat([{
                key: 'default_service',
                title: 'Default Service',
                items: [{
                    key: 'id',
                    title: 'ID',
                    value: routeCategory.service.id
                },{
                    key: 'name',
                    title: 'Name',
                    value: routeCategory.service.name
                },{
                    key: 'information',
                    title: 'Description',
                    value: routeCategory.service.information
                }]
            }])
        }
        return fields;
    }

    const getOptions = () => {
        if(!routeCategory.options || routeCategory.options.length === 0) {
            return null;
        }
        let fields = [{
            key: 'options',
            title: 'Route Options',
            items: routeCategory.options.map((option, index, options) => ({
                key: index,
                title: option.name,
                value: option.address,
                onClick: onOptionClick.bind(this, option)
            }))
        }];
        return (
            <FieldMapper
            utils={utils}
            fields={fields} />
        )
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = async () => {
        try {
            if(routeCategory.companies.length === 0 && routeCategory.options.length === 0) {
                return;
            }
            let { companies, options } = await Request.get(utils, '/quick_scan/', {
                type: 'category_ext_details',
                id: abstract.getID()
            });
            abstract.object.companies = companies ? companies.map(company => Company.create(company)) : null;
            abstract.object.options = options.map(opt => Route.Option.create(opt));
            setRouteCategory({ ...abstract.object });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue some of the information for this route category. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(routeCategory.seeds && routeCategory.seeds.notes) {
            setNote(routeCategory.seeds.notes.find(note => note.deleted !== true));
        }
    }, [routeCategory]);

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        setupTarget();
        utils.content.subscribe(layerID, ['routeCategories', 'routeOptions'], {
            onFetch: setupTarget,
            onUpdate: next => {
                switch(next.type) {
                    case 'routeCategories':
                    setRouteCategory(next.compare(abstract, fetchSystemEvents));
                    break;

                    case 'routeOptions':
                    if(abstract.object.category && next.id === abstract.object.category.id) {
                        if(!routeCategory.options) {
                            routeCategory.options = [];
                        }
                        abstract.object.options = routeCategory.options.filter(opt => opt.id !== next.getID())
                        abstract.object.options.push(next.object);
                        setRouteCategory({ ...abstract.object });
                    }
                    break;

                    default:
                    return null;
                }
            }
        })
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'status',
            text: routeCategory.active ? 'Deactivate' : 'Activate',
            color: routeCategory.active ? 'danger' : 'primary',
            onClick: onSetStatus
        },{
            key: 'options',
            text: 'Options',
            color: routeCategory.active ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }]}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getCompanies()}
            {getOptions()}
            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const QuickScanRouteOptionDetails = ({ abstract, index, options, utils }) => {

    const layerID = `route-option-details-${abstract.getID()}`;
    const limit = 5;

    const [annotations, setAnnotations] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [routeOption, setRouteOption] = useState(abstract.object);

    const onCategoryClick = option => {
        utils.layer.open({
            id: `route-category-details-${option.category.id}`,
            abstract: Abstract.create({
                type: 'routeCategories',
                object: routeOption.category
            }),
            Component: QuickScanRouteCategoryDetails
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit Route Option',
                style: 'default'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, {
                    type: 'routeOptions',
                    object: routeOption
                });
                return;
            }
            if(key === 'edit') {
                utils.layer.open({
                    id: `edit-quick-scan-route-option-${abstract.getID()}`,
                    abstract: abstract,
                    Component: AddEditQuickScanRouteOption.bind(this, {
                        isNewTarget: false
                    })
                });
                return;
            }
        })
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `Set as ${routeOption.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set this route option as ${routeOption.active ? 'inactive' : 'active'}? This will ${routeOption.active ? 'no longer allow' : 'allow'} customers to book routes with this option`,
            buttons: [{
                key: 'confirm',
                title: routeOption.active ? 'Deactivate' : 'Activate',
                style: routeOption.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: routeOption.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: routeOption.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !routeOption.active;
            await Request.post(utils, '/quick_scan/', {
                type: 'set_option_status',
                id: routeOption.id,
                active: next
            });

            setLoading(false);
            abstract.object.active = next;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `${abstract.getTitle()} has been set as ${next ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this route option. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                kye: 'id',
                title: 'ID',
                value: routeOption.id
            },{
                key: 'name',
                title: 'Name',
                value: routeOption.name
            },{
                key: 'address',
                title: 'Address',
                value: routeOption.address
            },{
                key: 'status',
                title: 'Status',
                value: routeOption.active ? 'Active' : 'Not Active'
            }]
        }];
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = () => {
        setAnnotations([{
            id: 'default-location',
            title: abstract.object.name,
            subTitle: abstract.object.address,
            location: abstract.object.location,
            icon: { type: 'broadcast' }
        }]);
    }

    useEffect(() => {
        if(routeOption.seeds && routeOption.seeds.notes) {
            setNote(routeOption.seeds.notes.find(note => note.deleted !== true));
        }
    }, [routeOption]);

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        setupTarget();
        utils.content.subscribe(layerID, 'routeOptions', {
            onUpdate: next => {
                setRouteOption(next.compare(abstract, fetchSystemEvents));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'status',
            text: routeOption.active ? 'Deactivate' : 'Activate',
            color: routeOption.active ? 'danger' : 'primary',
            onClick: onSetStatus
        },{
            key: 'options',
            text: 'Options',
            color: routeOption.active ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }]}>

            <LayerMap
            utils={utils}
            contentType={'route'}
            annotations={annotations} />

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {routeOption.category && (
                <LayerItem title={'Route Category'}>
                    {Views.entry({
                        key: routeOption.category.id,
                        title: routeOption.category.name,
                        subTitle: routeOption.category.information,
                        bottomBorder: false,
                        onClick: onCategoryClick.bind(this, routeOption)
                    })}
                </LayerItem>
            )}

            {routeOption.service && (
                <LayerItem title={'Service'}>
                    {Views.entry({
                        key: routeOption.service.id,
                        title: routeOption.service.name,
                        subTitle: routeOption.service.information,
                        borderBottom: false
                    })}
                </LayerItem>
            )}

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

// utils
export const fetchQuickScanCategories = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/quick_scan/',  {
                type: 'route_categories_admin',
                ...props
            });
            if(props && props.download) {
                resolve(response);
                return;
            }
            resolve({
                paging: response.paging,
                categories: response.categories.map(category => Route.Category.create(category))
            });
        } catch(e) {
            reject(e);
        }
    })
}

export const fetchQuickScanServices = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/quick_scan/',  {
                type: 'services',
                show_inactive: true,
                ...props
            });
            if(props && props.download) {
                resolve(response);
                return;
            }
            resolve({
                paging: response.paging,
                services: response.services.map(service => Service.create(service))
            });
        } catch(e) {
            reject(e);
        }
    })
}
