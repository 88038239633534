import React, { useState, useEffect } from 'react';
import Utils from 'files/Utils.js';

const DurationPickerField = ({ onChange, seconds, style }) => {

    const [selectedHour, setSelectedHour] = useState(0);
    const [selectedMinutes, setSelectedMinutes] = useState(0);

    const hours = [...new Array(24)].map((_, index) => index);
    const [minutes, setMinutes] = useState([]);

    const getHourValue = () => {
        return `${selectedHour} ${selectedHour === 1 ? 'hour':'hours'}`
    }

    const getMinutesValue = () => {
        return `${selectedMinutes} ${selectedMinutes === 1 ? 'minute' : 'minutes'}`
    }

    const onDurationChange = () => {
        if(minutes.length === 0) {
            return;
        }
        let duration = (selectedHour * 3600) + (selectedMinutes * 60);
        if(typeof(onChange) === 'function') {
            onChange(duration);
        }
    }

    useEffect(() => {
        onDurationChange();
    }, [selectedHour, selectedMinutes]);

    useEffect(() => {

        // setup minutes
        let minuteValues = [0].concat([...new Array(12)].map((_, index) => index === 0 ? 1 : index * 5));
        setMinutes(minuteValues);

        if(seconds === null || isNaN(seconds)) {
            return;
        }
        let hours = Math.floor(seconds / 3600);
        let minutes = (seconds - (hours * 3600)) / 60;
        setSelectedHour(hours);
        setSelectedMinutes(minutes);
    }, []);

    return (
        <div style={style}>
            <div
            className={'row p-0 m-0'}
            style={{
                width: '100%'
            }}>
                <div className={'col-12 col-md-6 p-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    style={{ marginRight: 4 }}
                    value={getHourValue()}
                    onChange={e => {
                        let id = Utils.attributeForKey.select(e, 'id');
                        setSelectedHour(parseInt(id));
                    }}>
                        {hours.map(hour => (
                            <option key={hour} id={hour}>{`${hour} ${hour === 1 ? 'hour':'hours'}`}</option>
                        ))}
                    </select>
                </div>

                <div className={'col-12 col-md-6 p-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    style={{ marginLeft: 4  }}
                    value={getMinutesValue()}
                    onChange={e => {
                        let id = Utils.attributeForKey.select(e, 'id');
                        setSelectedMinutes(parseInt(id));
                    }}>
                        {minutes.map(minute => (
                            <option key={minute} id={minute}>{`${minute} ${minute === 1 ? 'minute' : 'minutes'}`}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default DurationPickerField;
