import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Company from 'classes/Company.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class CreditsCardClass {

    active = null;
    card = null;
    company = null;
    date = null;
    id = null;
    is_company_card = false;
    seeds = {};
    type = null;
    user = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.active = props.card && props.card.active || false;
        this.company = props.company && Company.create(props.company);
        this.date = props.date_created && moment(props.date_created);
        this.id = props.id;
        this.is_company_card = props.type === 'company';
        this.type = props.type;
        this.user = props.user && User.create(props.user);

        let { access_points = [] } = props.card || {};
        this.card = {
            ...props.card,
            access_points: access_points.map(location => new CommunityAccessPointClass().create(location))
        };
        return this;
    }

    getSummary = () => {
        return `${this.type === 'company' ? this.company.name : ''} Credits (${Utils.toCurrency(this.card.balance)})`;
    }

    getType = () => {
        return `${this.type === 'company' ? this.company.name : `${this.user.full_name}'s`} Credits Card`;
    }

    balance = () => {
        return this.card ? this.card.balance : 0;
    }

    icon = () => {
        return 'far fa-credit-card';
    }

    getImage = (type = 'added') => {
        return {
            path: 'images/credits-' + type + '-clear.png',
            style: {
                backgroundColor: type === 'expired' || type === 'remove' ? Appearance.colors.red : Appearance.colors.grey()
            }
        }
    }

    getNotes = key => {

        let entry = this.card && this.card.history ? this.card.history.entries[key] : null;

        let expiration = entry.start_date || entry.expiration_date ? `${entry.start_date ? `. These credits will be available starting ${moment(entry.start_date).format('MMMM Do, YYYY [at] h:mma')}` : ''}${entry.expiration_date ? `. These credits will expire on ${moment(entry.expiration_date).format('MMMM Do, YYYY [at] h:mma')}` : ''}` : '';

        if(entry.notes === 'Reservation surplus') {
            return 'Reservation #' + this.card.history.entries[key].reservation_id + ' had a surplus of ' + Utils.toCurrency(this.card.history.entries[key].amount) + ' on ' + moment(key).format('MMMM Do, YYYY [at] h:mma') + expiration;
        }

        switch(entry.type) {
            case 'charge':
                return Utils.toCurrency(this.card.history.entries[key].amount) + ' charged' + (this.card.history.entries[key].reservation_id ? (' for Reservation #' + this.card.history.entries[key].reservation_id) : '') + ' on ' + moment(key).format('MMMM Do, YYYY [at] h:mma') + expiration;
            case 'return':
                return Utils.toCurrency(this.card.history.entries[key].amount) + ' returned' + (this.card.history.entries[key].reservation_id ? (' for Reservation #' + this.card.history.entries[key].reservation_id) : '') + ' on ' + moment(key).format('MMMM Do, YYYY [at] h:mma') + expiration;
            default:
                return `${entry ? entry.notes : 'No note were added for this entry'}${expiration}`;
        }
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            user: this.user,
            company: this.company
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            user: props.user || this.edits.user,
            company: props.company || this.edits.company
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/credits/', {
                    type: 'new',
                    user_id: edits.user ? edits.user.user_id : null,
                    company_id: edits.company ? edits.company.id : null
                });
                utils.content.fetch('credits');
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }
}

class CommunityAccessPointClass {

    active = null;
    address = null;
    company_id = null;
    geojson = null;
    id = null;
    location = null;
    name = null;
    radius = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.address = props.address;
        this.company_id = props.company_id;
        this.geojson = props.geojson;
        this.id = props.id;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.name = props.name;
        this.radius = props.radius;
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            address: this.address,
            company_id: this.company_id,
            location: this.location,
            name: this.name,
            radius: this.radius
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            address: props.address || this.edits.address,
            location: props.location || this.edits.location,
            name: props.name || this.edits.name,
            radius: props.radius || this.edits.radius
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { geojson, id } = await Request.post(utils, '/credits/', {
                    address: edits.address,
                    company_id: edits.company_id,
                    location: edits.location && {
                        lat: edits.location.latitude,
                        long: edits.location.longitude
                    },
                    name: edits.name,
                    radius: edits.radius,
                    type: 'new_community_access_point'
                });

                this.active = true;
                this.address = edits.address;
                this.geojson = geojson;
                this.id = id;
                this.location = edits.location;
                this.name = edits.name;
                this.radius = edits.radius;
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { geojson } = await Request.post(utils, '/credits/', {
                    address: edits.address,
                    company_id: edits.company_id,
                    id: this.id,
                    location: edits.location && {
                        lat: edits.location.latitude,
                        long: edits.location.longitude
                    },
                    name: edits.name,
                    radius: edits.radius,
                    type: 'update_community_access_point'
                });

                this.address = edits.address;
                this.geojson = geojson;
                this.location = edits.location;
                this.name = edits.name;
                this.radius = edits.radius;
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new CreditsCardClass(),
    create: props => new CreditsCardClass().create(props),
    CommunityAccessPoint: {
        new: () => new CommunityAccessPointClass(),
        create: props => new CommunityAccessPointClass().create(props),
    }
};
