import moment from 'moment-timezone';
import User from 'classes/User.js';

class MessageClass {

    id = null;
    reservation = null;
    from = null;
    to = [];
    date = null;
    text = null;
    attachment = null;
    data = null;
    has_viewed = false;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = parseInt(props.id);
        this.reservation = props.reservation;
        this.text = props.message;
        this.has_viewed = true;
        this.date = props.date ? moment(props.date) : null;
        this.data = props.data;
        this.from = props.sent_by ? User.create(props.sent_by) : null;
        this.attachment = props.attachment ? new MessageAttachmentClass().create(props.attachment) : null;
        return this;
    }
}

class MessageAttachmentClass {

    type = null;
    fileName = null;
    url = null;
    metadata = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.fileName = props.file_name;
        this.url = props.url;
        this.metadata = props.metadata;
        return this;
    }
}

export default {
    new: () => new MessageClass(),
    create: props => new MessageClass().create(props),
    Attachment: {
        new: () => new MessageAttachmentClass(),
        create: props => new MessageAttachmentClass().create(props)
    }
}
