import React, { useState } from 'react';

import Appearance from 'styles/Appearance.js';
import ProgressBar from 'views/ProgressBar.js';
import Utils from 'files/Utils.js';

export const AltBadge = ({ content, style, onClick, onMouseEnter, onMouseLeave }) => {

    const onBadgeClick = evt => {
        evt.stopPropagation();
        if(typeof(onClick) === 'function') {
            onClick();
        }
    }

    const getContent = () => {
        if(Array.isArray(content)) {
            return content.filter(({ text, color, tag }) => {
                return typeof(text) === 'string' && color ? true : false;
            }).map(({ color, lowercase, tag, text }, index) => {
                return (
                    <div
                    key={index}
                    className={onClick ? 'text-button' : ''}
                    onClick={onClick ? onBadgeClick : null}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={{
                        alignItems: 'center',
                        backgroundColor: Utils.hexToRGBA(color, 0.25),
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginRight: 8,
                        padding: '3px 10px 3px 10px',
                        ...style
                    }}>
                        <span style={{
                            color: window.theme === 'dark' ? 'white' : color,
                            fontWeight: 800,
                            fontSize: 10,
                            whiteSpace: 'pre'
                        }}>{lowercase ? text : text.toString().toUpperCase()}</span>
                    </div>
                )
            })
        }
        if(content.text && content.color) {
            return (
                <div
                className={onClick ? 'text-button' : ''}
                onClick={onClick ? onBadgeClick : null}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                style={{
                    alignItems: 'center',
                    backgroundColor: Utils.hexToRGBA(content.color, 0.25),
                    borderRadius: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: 1,
                    padding: '3px 10px 3px 10px',
                    ...style
                }}>
                    <span style={{
                        color: window.theme === 'dark' ? 'white' : content.color,
                        fontSize: 10,
                        fontWeight: 800,
                        whiteSpace: 'pre'
                    }}>{content.lowercase ? content.text : (content.text ? content.text.toString().toUpperCase() : '')}</span>
                </div>
            )
        }
        return null;
    }
    return getContent();
}

const getEntryBorderRadius = props => {
    if(props.singleItem) {
        return {
            borderRadius: 10
        };
    }
    if(props.lastItem) {
        return {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10
        }
    }
    if(props.firstItem) {
        return {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottom: `1px solid ${Appearance.colors.divider()}`
        }
    }
    return props.bottomBorder && {
        borderBottom: `1px solid ${Appearance.colors.divider()}`
    };
}

const Views = {
    loader: props => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center',
                ...props
            }}>
                <span className={'inline-loader'}>
                    <span className={'inline-loader-inner'} />
                </span>
            </div>
        )
    },
    entry: props => {

        let { badge, bottomBorder, bottomContent, hideArrow, hideIcon, icon, key, loading, multiline, onClick, prepend, propStyles, rightContent, rightIcon, style, substring, subTitle, supportingTitle, textStyles, title, useTouchable } = props;

        const getChildComponents = () => {
            return (
                <>
                <div style={{
                    borderBottomColor: Appearance.colors.divider(),
                    borderBottomWidth: bottomBorder === false ? 0 : 1,
                    paddingBottom: 7,
                    paddingLeft: 9,
                    paddingRight: 9,
                    paddingTop: 7,
                    position: 'relative',
                    textAlign: 'left'
                }}>
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '100%',
                    }}>
                        {prepend}
                        {hideIcon !== true && (
                            typeof(icon) === 'function'
                                ?
                                props.icon()
                                :
                                <div
                                onClick={icon ? icon.onClick : null}
                                style={{
                                    position: 'relative'
                                }}>
                                    <div style={{
                                        ...Appearance.icons.standard(),
                                        overflow: 'hidden',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        ...icon && icon.style
                                    }}>
                                        <img
                                        src={icon && icon.path ? icon.path : `/images/location-icon-${window.theme}.png`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            ...icon && icon.imageStyle
                                        }}/>
                                    </div>
                                    {icon && icon.badge && (
                                        <div style={{
                                            width: 20,
                                            height: 20,
                                            minWidth: 20,
                                            minHeight: 20,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            borderRadius: 10,
                                            overflow: 'hidden',
                                            position: 'absolute',
                                            top: -2,
                                            right: -6,
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: typeof(icon.badge) === 'object' && icon.badge.color ? icon.badge.color : Appearance.colors.primary()
                                        }}>
                                            {typeof(icon.badge) === 'object' && icon.badge.text
                                                ?
                                                <span style={{
                                                    width: '100%',
                                                    fontSize: 10,
                                                    fontWeight: 900,
                                                    color: 'white'
                                                }}>{icon.badge.text}</span>
                                                :
                                                <div style={{
                                                    width: 5,
                                                    height: 5,
                                                    backgroundColor: 'white',
                                                    borderRadius: 2.5,
                                                    overflow: 'hidden'
                                                }}/>
                                            }
                                        </div>
                                    )}
                                </div>
                        )}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexShrink: 1,
                            justifyContent: 'center',
                            minWidth: 0,
                            paddingLeft: hideIcon === true ? 2 : 12,
                            width: '100%'
                        }}>
                            {typeof(title) === 'string'
                                ?
                                <span
                                {...propStyles && propStyles.title}
                                style={{
                                    ...Appearance.textStyles.title(),
                                    ...textStyles && textStyles.title,
                                    flexGrow: 1,
                                    lineHeight: 1.25
                                }}>{title}</span>
                                :
                                <div
                                {...propStyles && propStyles.title}
                                style={{
                                    flexGrow: 1,
                                    lineHeight: 1.25
                                }}>{title}</div>}
                            {getSubstringComponent()}
                            {!substring && typeof(subTitle) === 'string' && (
                                <span
                                {...propStyles && propStyles.subTitle}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    ...textStyles && textStyles.subTitle,
                                    lineHeight: 1.25,
                                    marginTop: 2
                                }}>{subTitle}</span>
                            )}
                            {typeof(supportingTitle) === 'string' && (
                                <span
                                {...propStyles && propStyles.supportingTitle}
                                style={{
                                    ...Appearance.textStyles.subTitle(),
                                    ...textStyles && textStyles.supportingTitle,
                                    marginTop: 2
                                }}>{supportingTitle}</span>
                            )}
                        </div>
                        {rightIcon && (
                            <img
                            src={rightIcon.path}
                            style={{
                                ...rightIcon.style,
                                minWidth: 25,
                                minHeight: 25,
                                width: 25,
                                height: 25
                            }}/>
                        )}
                        {badge && (
                            <AltBadge
                            content={badge}
                            onClick={badge.onClick} />
                        )}
                        {rightContent}
                        {hideArrow !== true && onClick && (
                            <img
                            src={'images/next-arrow-grey-small.png'}
                            style={{
                                width: 15,
                                height: 15,
                                objectFit: 'contain',
                                marginLeft: 8,
                                opacity: 1
                            }} />
                        )}
                    </div>
                    {loading === true && (
                        <div style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 2,
                            borderRadius: 1,
                            overflow: 'hidden'
                        }}>
                            <ProgressBar />
                        </div>
                    )}
                </div>
                {bottomContent}
                </>
            )
        }

        const getContent = () => {
            return (
                <div
                key={key}
                className={onClick ? `view-entry ${window.theme}` : ''}
                onClick={onClick}
                style={{
                    overflow: 'hidden',
                    ...getEntryBorderRadius(props),
                    ...style
                }}>
                    {getChildComponents()}
                </div>
            )
        }

        const getSubstringComponent = () => {

            if(!substring) {
                return null;
            }

            let value = subTitle.replace(substring.text, '');
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: 2
                }}>
                    <span
                    {...propStyles && propStyles.subTitle}
                    style={{
                        ...Appearance.textStyles.subTitle(),
                        ...textStyles && textStyles.subTitle
                    }}>{value}
                        <span
                        {...propStyles && propStyles.subTitle}
                        style={{
                            ...Appearance.textStyles.subTitle(),
                            ...textStyles && textStyles.subTitle,
                            ...substring.style
                        }}>{substring.text}</span>
                    </span>
                </div>
            )
        }

        return getContent();
    },
    row: ({ bottomBorder, key, label, onClick, style, value }) => {
        return (
            <div
            key={key}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 12px 8px 12px',
                borderBottom: bottomBorder === false ? null : `1px solid ${Appearance.colors.divider()}`,
                ...style
            }}>
                <span style={{
                    ...Appearance.textStyles.key(),
                    whiteSpace: 'normal'
                }}>{label}</span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    <span style={{
                        ...Appearance.textStyles.value(),
                        ...style && style.value
                    }}>{value !== null && value !== undefined ? value : 'Not Added'}</span>
                    {typeof(onClick) === 'function' && (
                        <img
                        src={'images/next-arrow-grey-small.png'}
                        style={{
                            width: 15,
                            height: 15,
                            objectFit: 'contain',
                            marginLeft: 8,
                            opacity: 1
                        }} />
                    )}
                </div>
            </div>
        )
    }
}
export default Views;

export const AsyncViewComponent = props => {

    const [loading, setLoading] = useState(loading);

    const onItemClick = async () => {
        try {
            setLoading(true);
            await props.onClick();
            setLoading(false);
        } catch(e) {
            console.error(e.message);
        }
    }

    return (
        Views.entry({
            ...props,
            loading: loading,
            onClick: onItemClick
        })
    )
}

export const ViewComponent = props => {
    return Views.entry({
        ...props,
        key: undefined
    });
}
