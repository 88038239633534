import moment from 'moment-timezone';
import Company from 'classes/Company.js';
import Note from 'classes/Note.js';
import Order from 'classes/Order.js';
import Reservation from 'classes/Reservation.js';
import Subscription from 'classes/Subscription.js';
import User from 'classes/User.js';
import Vehicle from 'classes/Vehicle.js';

class PaymentClass {

    amount = null;
    approved = null;
    cancelled = null;
    company = null;
    credits = null;
    customer = null;
    data = null;
    date = null;
    dispute = null;
    gross_amount = null;
    id = null;
    metadata = null;
    net_amount = null;
    notes = null;
    order = null;
    refunded = null;
    reservation = null;
    stripe_id = null;
    subscription = null;
    type = null;
    user_data = null;
    user_id = null;

    constructor() {
        return this;
    }

    create = props => {

        this.approved = props.approved && moment(props.approved);
        this.amount = props.amount;
        this.cancelled = props.cancelled ? moment(props.cancelled) : null;
        this.company = props.company || props.company_data ? Company.create(props.company || props.company_data) : null;
        this.credits = props.credits;
        this.customer = props.customer && User.create(props.customer);
        this.data = props.data;
        this.date = props.date && moment(props.date);
        this.dispute = props.dispute;
        this.gross_amount = props.gross_amount;
        this.id = props.id;
        this.metadata = props.metadata;
        this.net_amount = props.net_amount;
        this.notes = props.notes;
        this.order = props.order && Order.create(props.order);
        this.refunded = props.refunded ? moment(props.refunded) : null;
        this.reservation = props.reservation && Reservation.create(props.reservation);
        this.stripe_id = props.stripe_id;
        this.subscription = props.subscription && Subscription.create(props.subscription);
        this.type = props.type;
        this.user_data = props.user_data;
        this.user_id = props.user_id;
        this.vehicle = this.reservation && Vehicle.Category.create(this.reservation.vehicle);
        return this;
    }

    getOwner = () => {
        if(!this.customer && !this.company) {
            return {};
        }
        return {
            type: this.customer === undefined ? 'company' : 'user',
            object: this.customer === undefined ? this.company : this.customer
        }
    }

    getType = () => {
        switch(this.type) {

            case 'cancellation':
            return 'Cancellation Fee';

            case 'covered':
            return 'No Cost Applied';

            case 'credits':
            return 'Credits Purchase';

            case 'custom_payment':
            return 'Custom Payment';

            case 'driver_tip':
            return 'Driver Tip';

            case 'reservation_fee':
            return 'Reservation Fee';
            
            case 'standard':
            return 'Standard';

            case 'standard_credits':
            return 'Only Credits';

            case 'subscription':
            return 'Subscription';

            default:
            return 'Unavailable';
        }
    }
}
export default {
    new: () => new PaymentClass(),
    create: props => new PaymentClass().create(props)
}
