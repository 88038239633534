import React from 'react';

import AddressLookupField from 'views/AddressLookupField.js';
import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import ColorPicker from 'views/ColorPicker.js';
import DatePickerField from 'views/DatePickerField.js';
import DateDurationPickerField from 'views/DateDurationPickerField.js';
import DurationPickerField from 'views/DurationPickerField.js';
import ImagePickerField from 'views/ImagePickerField.js';
import { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import MultipleAddressLookupFieldField from 'views/MultipleAddressLookupField.js';
import MultipleFileUpload from 'views/MultipleFileUpload.js';
import MultipleListField from 'views/MultipleListField.js';
import MultipleUserLookupField from 'views/MultipleUserLookupField.js';
import NumberStepper from 'views/NumberStepper.js';
import OrderHostLookupField from 'views/OrderHostLookupField.js';
import TextField from 'views/TextField.js';
import TimeRangePicker from 'views/TimeRangePicker.js';
import UserLookupField from 'views/UserLookupField.js';

export const validateRequiredFields = async get => {
    return new Promise((resolve, reject) => {
        let items = get().reduce((array, field) => {
            return array.concat(field.items);
        }, []);
        let required = items.find(item => {
            if(item.required === false) {
                return false;
            }
            return item.value === null || item.value === undefined;
        });
        if(required) {
            let error = new Error(`One or more required fields are incomplete. Please fill out the "${required.title.toLowerCase()}" before moving on`);
            reject(error);
            return;
        }
        resolve();
    })
}

const AltFieldMapper = ({ fields = [], onEditItem, style, utils }) => {

    const onInvalidClick = item => {
        utils.alert.show({
            title: item.invalid.title || item.invalid.text || item.title,
            message: item.invalid.message || item.description
        });
    }

    const onSupportClick = item => {
        utils.alert.show({
            title: `About ${item.title}`,
            message: item.description
        });
    }

    const getAccessoryComponent = item => {
        if(item.invalid && typeof(item.invalid) === 'object' && item.invalid.value === true) {
            return (
                <AltBadge
                className={'text-button'}
                onClick={onInvalidClick.bind(this, item)}
                content={{
                    text: item.invalid.text,
                    color: Appearance.colors.red
                }} />
            )
        }
        if(item.invalid) {
            return (
                <AltBadge
                className={'text-button'}
                onClick={onInvalidClick.bind(this, item)}
                content={{
                    text: 'Issue Detected',
                    color: Appearance.colors.red
                }} />
            )
        }
        if(item.description || item.buttons) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    {item.buttons && item.buttons.map((button, index) => (
                        <AltBadge
                        key={index}
                        className={'text-button'}
                        content={button} 
                        onClick={button.onClick} 
                        style={{ marginLeft: 8 }}/>
                    ))}
                    {typeof(item.description) === 'string' && (
                        <AltBadge
                        className={'text-button'}
                        onClick={onSupportClick.bind(this, item)}
                        style={{ marginLeft: 8 }}
                        content={{
                            color: Appearance.colors.grey(),
                            text: 'Learn More'
                        }} />
                    )}
                </div>
            )
        }
        return null;
    }

    const getComponent = item => {

        let { address, content, component, icon, items, loading, onChange, onEditClick, onValidate, props = {}, value } = item;
        switch(component) {

            case 'address_lookup':
            return (
                <AddressLookupField
                utils={utils}
                icon={icon}
                value={value}
                geocode={true}
                address={address}
                loading={loading}
                {...props}
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}
                onChange={place => {
                    if(typeof(onChange) === 'function') {
                        if(!place) {
                            onChange(null);
                            return;
                        }
                        onChange({
                            location: place.location,
                            address: {
                                address: place.address,
                                city: place.city,
                                state: place.state,
                                zipcode: place.zipcode,
                                country: place.country
                            }
                        });
                    }
                }} />
            )

            case 'auto_rejection_editor':
            return (
                <TextField
                onClick={onEditClick}
                onChange={onChange}
                value={value && value.enabled ? 'Enabled' : 'Disabled'}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            );

            case 'bool_list':
            return (
                <ListField
                deselect={false}
                items={[{
                    id: 'true',
                    title: 'Yes'
                },{
                    id: 'false',
                    title: 'No'
                }]}
                onChange={item => onChange(item && item.id === 'true' ? true : false)}
                value={value ? 'Yes' : 'No'}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'bool_toggle':
            return (
                <BoolToggle
                enabled={'Yes'}
                disabled={'No'}
                isEnabled={value}
                onChange={onChange}
                {...props}
                style={{
                    margin: null,
                    width: '100%',
                    ...props && props.style
                }} />
            );

            case 'color_picker':
            return (
                <ColorPicker
                color={value}
                onChange={onChange}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'date_duration_picker':
            return (
                <DateDurationPickerField
                utils={utils}
                selected={value}
                placeholder={'Date'}
                onChange={onChange}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'date_picker':
            return (
                <DatePickerField
                utils={utils}
                icon={icon}
                value={value}
                selected={value}
                onValidate={onValidate}
                onDateChange={onChange}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'date_time_picker':
            return (
                <DateDurationPickerField
                utils={utils}
                icon={icon || 'calendar'}
                selected={value}
                onChange={onChange}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'duration_picker':
            return (
                <DurationPickerField
                onChange={onChange}
                seconds={value}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'image_picker':
            return (
                <ImagePickerField
                utils={utils}
                value={value}
                onChange={onChange}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'list':
            return (
                <ListField
                items={items}
                value={value}
                onChange={onChange}
                {...props}
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            case 'multiple_address_lookup':
            return (
                <MultipleAddressLookupFieldField
                utils={utils}
                icon={icon}
                locations={value}
                onChange={onChange}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'multiple_file_picker':
            return (
                <MultipleFileUpload
                utils={utils}
                files={value}
                onChange={onChange}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'multiple_list':
            return (
                <MultipleListField
                utils={utils}
                items={items}
                value={value}
                onChange={onChange}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'multiple_user_lookup':
            return (
                <MultipleUserLookupField
                utils={utils}
                users={value}
                onChange={onChange}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'number_stepper':
            return (
                <NumberStepper
                utils={utils}
                onChange={onChange}
                startingValue={value}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'order_host_lookup':
            return (
                <OrderHostLookupField
                utils={utils}
                host={value}
                onChange={onChange}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'radius_groups_editor':
            return (
                <TextField
                onClick={onEditClick}
                onChange={onChange}
                value={value && value.groups ? `${value.groups.length} ${value.groups.length === 1 ? 'group' : 'groups'} setup` : 'No radius groups setup'}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            );

            case 'textfield':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                onValidate={onValidate}
                onChange={onChange}
                useDelay={true}
                {...props} 
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'textview':
            return (
                <TextField
                icon={icon}
                value={value}
                loading={loading}
                expandWithText={true}
                onValidate={onValidate}
                onChange={onChange}
                useDelay={true}
                {...props} 
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'time_range_picker':
            return (
                <TimeRangePicker
                utils={utils}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }}/>
            )

            case 'user_lookup':
            return (
                <UserLookupField
                icon={icon}
                utils={utils}
                user={value}
                loading={loading}
                placeholder={'Search by first or last name...'}
                onChange={onChange}
                {...props} 
                style={{
                    width: '100%',
                    ...props && props.style
                }} />
            )

            default:
            return (
                <>
                <TextField
                value={value || 'Click to make changes...'}
                loading={loading}
                onClick={onEditClick}
                containerStyle={{
                    width: '100%',
                    ...props && props.style
                }}
                {...props} />
                {content}
                </>
            );
        }
    }

    return (
        <div style={{
            width: '100%',
            ...style
        }}>
            {fields.filter(section => {
                return section.visible !== false
            }).map((section, index, sections) => {
                return (
                    <LayerItem
                    key={index}
                    title={section.title}
                    lastItem={typeof(section.lastItem) === 'boolean' ? section.lastItem : index === sections.length - 1}
                    collapsed={section.collapsed || false}
                    shouldStyle={false}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            width: '100%',
                            overflow: 'visible'
                        }}>
                            {section.items && section.items.filter(item => {
                                return item.visible !== false;
                            }).map((item, index, items) => {
                                return (
                                    <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        padding: 12,
                                        borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            textAlign: 'left',
                                            paddingBottom: item.description ? 4 : 0
                                        }}>
                                            {item.required !== false && (item.value === null || item.value === undefined) && (
                                                <div style={{
                                                    width: 5,
                                                    height: 5,
                                                    borderRadius: 2.5,
                                                    overflow: 'hidden',
                                                    backgroundColor: Appearance.colors.red,
                                                    marginRight: 8
                                                }} />
                                            )}
                                            <span style={{
                                                ...Appearance.textStyles.subHeader(),
                                                flexGrow: 1,
                                                display: 'block',
                                                marginBottom: 4
                                            }}>{item.title}</span>
                                            {getAccessoryComponent(item)}
                                        </div>
                                        {getComponent(item)}
                                    </div>
                                )
                            })}
                        </div>
                    </LayerItem>
                )
            })}
        </div>
    )
}

export default AltFieldMapper;
