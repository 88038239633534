import React, { useEffect, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import HistoryEvent from 'classes/HistoryEvent.js';
import { LayerItem } from 'structure/Layer.js';
import Request from 'files/Request.js';
import SupportTicket from 'classes/SupportTicket.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const SupportEventManager = ({ route, ticket, utils }) => {

    const [events, setEvents] = useState([]);
    const [newStatus, setNewStatus] = useState(false);
    const ticketStatusCodeArray = Object.values(SupportTicket.status).map(ticket => SupportTicket.formatStatus(ticket))

    const onAddStatus = async () => {
        try {
            if(newStatus === false) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'Please choose an event type to continue'
                })
                return;
            }
            let { id, response } = await Request.post(utils, '/saas/', {
                type: 'set_support_ticket_status',
                ticket_id: ticket.id,
                status: newStatus === null ? -1 : newStatus
            });
            if(response === 'status reset') {
                utils.alert.show({
                    title: 'All Done!',
                    message: 'The status for this support ticket is now set to pending. Pending history events are not visible in the history event list'
                });
            }

            ticket.status = SupportTicket.formatStatus(newStatus);
            utils.content.update({
                type: 'supportTickets',
                object: ticket
            });
            setNewStatus(false);

            let newEvent = HistoryEvent.create({
                id: id,
                date: moment().format('YYYY-MM-DD HH:mm:ss'),
                status: SupportTicket.formatStatus(newStatus)
            });
            newEvent.user = utils.user.get();
            setEvents(events => update(events, {
                $unshift: [newEvent]
            }));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding your new event. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onStatusChange = evt => {
        let id = Utils.attributeForKey.select(evt, 'id');
        setNewStatus(id === 'none' ? false : parseInt(id));
    }

    const fetchEvents = async () => {
        try {
            let { events } = await ticket.getHistoryEvents(utils);
            setEvents(events);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the events for this support ticket. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEvents();
    }, []);

    return (
        <div style={{
            textAlign: 'left',
            width: '100%'
        }}>
            {!events
                ?
                Views.loader()
                :
                <>
                <LayerItem
                title={'New Event'}
                shouldStyle={false}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <select
                        className={`custom-select ${utils.user.get().theme}`}
                        onChange={onStatusChange}
                        style={{
                            flexGrow: 1
                        }}>
                            <option>{'Choose an event type...'}</option>
                            {ticketStatusCodeArray.map((statusCode, index) => {
                                return (
                                    <option key={index} id={statusCode.code}>{statusCode.text}</option>
                                )
                            })}
                        </select>
                        {newStatus !== false && (
                            <Button
                            label={'Add Event'}
                            color={'dark'}
                            type={'small'}
                            onClick={onAddStatus}
                            style={{
                                marginLeft: 8
                            }}/>
                        )}
                    </div>
                </LayerItem>

                <LayerItem
                title={'Past Events'}
                lastItem={true}
                childrenStyle={{
                    marginBottom: 0,
                    maxHeight: 240,
                    overflowY: 'scroll'
                }}>
                    {events && events.length == 0
                        ?
                        Views.entry({
                            title: 'Nothing Found',
                            subTitle: 'No history log events were found',
                            icon: {
                                style: Appearance.icons.standard(),
                                path: 'images/status-pending.png'
                            }
                        })
                        :
                        events.map((event, index) => {
                            return Views.entry({
                                key: event.id,
                                title: event.user.full_name,
                                subTitle: moment(event.date).format('MMMM Do, YYYY [at] h:mma'),
                                icon: {
                                    style: Appearance.icons.standard(),
                                    path: event.user ? event.user.avatar : 'images/system-user-icon.png',
                                    onClick: event.user ? Utils.users.details.bind(this, utils, event.user) : null
                                },
                                badge: {
                                    text: event.status.text,
                                    color: event.status.color
                                },
                                bottomBorder: index !== events.length - 1
                            })
                        })
                    }
                </LayerItem>
                </>
            }
        </div>
    )
}
export default SupportEventManager;
