import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Company from 'classes/Company.js';
import HistoryEvent from 'classes/HistoryEvent.js';
import Note from 'classes/Note.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import Service from 'classes/Service.js';
import User from 'classes/User.js';
import Vehicle from 'classes/Vehicle.js';

class RouteClass {

    id = null;
    driver = null;
    name = null;
    service = null;
    vehicle = null;
    emissions = null;
    categories = null;
    date = null;
    routing = null;
    status = null;
    seeds = {};

    reservations = [];
    reservation_index = 0;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.date = props.date ? moment(props.date) : null;
        this.status = formatStatus(props.status);
        this.emissions = props.emissions;
        this.routing = props.routing;
        this.driver = props.driver ? User.create(props.driver) : null;
        this.service = props.service ? Service.create(props.service) : null;
        this.vehicle = props.vehicle ? Vehicle.Category.create(props.vehicle) : null;
        this.reservations = props.reservations ? props.reservations.map(reservation => Reservation.create(reservation)) : [];
        this.categories = props.categories ? props.categories.map(category => new RouteCategoryClass().create(category)) : [];

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => n.deleted !== true).map(n => Note.create(n))
        }
        return this;
    }

    getFirstReservation = () => {
        let ordered = this.dropOffOrder();
        return ordered ? ordered.reservations.find(res => [Reservation.status.approved, Reservation.status.returned, Reservation.status.to_destination].includes(res.status)) : null;
    }
    setCurrentReservation = (reservation) => {
        let ordered = this.dropOffOrder();
        this.reservation_index = ordered ? ordered.reservations.findIndex(res => res.id === reservation.id) : 0;
    }
    getCurrentReservation = () => {
        let ordered = this.dropOffOrder();
        return ordered ? ordered.reservations.find((res, index) => index === this.reservation_index) : null;
    }
    getNextReservation = () => {
        let ordered = this.dropOffOrder();
        return ordered ? ordered.reservations.find((res, index) => index > this.reservation_index) : null;
    }
    getRemainingReservations = () => {
        let ordered = this.dropOffOrder();
        return ordered ? ordered.reservations.filter((res, index) => index >= this.reservation_index) : null;
    }

    distanceEstimate = () => {
        if(this.reservations.length === 1) {
            return this.reservations[0].distanceEstimate;
        }
        return this.routing && this.routing.optimized && this.routing.optimized.summary ? this.routing.optimized.summary.length : null;
    }

    durationEstimate = () => {
        if(this.reservations.length === 1) {
            return this.reservations[0].durationEstimate;
        }
        return this.routing && this.routing.optimized && this.routing.optimized.summary ? this.routing.optimized.summary.time : null;
    }

    dropOffOrder = () => {
        if(this.routing && this.routing.optimized && this.routing.optimized.summary) {
            // Filter out first and last locations
            // First and last locations are driver current location
            let reservations = this.routing.optimized.locations.map(location => this.reservations[location.original_index]);

            return {
                reservations: reservations,
                summary: reservations.reduce((string, reservation, index, reservations) => {
                    return string + (index === reservations.length - 1 ? 'then ' : '') + reservation.customer.full_name + (reservations.length < 3 ? ' ' : '') + (index === reservations.length - 1 || reservations.length < 3 ? '' : ', ')
                }, '')
            }
        };
        return {
            reservations: this.reservations
        };
    }

    includesReservation = (id) => {
        return this.reservation.find(reservation => reservation.id === id) ? true:false
    }

    getHistoryEvents = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                 let { events } = await Request.get(utils, '/quick_scan/', {
                     type: 'history_log',
                     route_id: this.id
                 });
                 resolve({
                     events: events.map(e => {
                         return HistoryEvent.create({ ...e, status: formatStatus(e.status) })
                     })
                 });
            } catch(e) {
                reject(e);
            }
        });
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            name: this.name,
            driver: this.driver,
            vehicle: this.vehicle,
            categories: this.categories
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            name: props.name || this.edits.name,
            driver: props.driver || this.edits.driver,
            vehicle: props.vehicle || this.edits.vehicle,
            categories: props.categories || this.edits.categories
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/quick_scan/', {
                    type: 'new_route',
                    name: edits.name,
                    driver_id: edits.driver ? edits.driver.user_id : null,
                    vehicle_id: edits.vehicle ? edits.vehicle.id : null,
                    categories: edits.categories ? edits.categories.map(c => c.id) : null
                });

                this.id = id;
                this.name = edits.name;
                this.driver = edits.driver;
                this.vehicle = edits.vehicle;
                this.categories = edits.categories;

                utils.content.fetch('routes');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/quick_scan/', {
                    type: 'update_route',
                    route_id: this.id,
                    name: edits.name,
                    driver_id: edits.driver ? edits.driver.user_id : null,
                    vehicle_id: edits.vehicle ? edits.vehicle.id : null,
                    categories: edits.categories ? edits.categories.map(c => c.id) : null
                });

                this.name = edits.name;
                this.driver = edits.driver;
                this.vehicle = edits.vehicle;
                this.categories = edits.categories;

                utils.content.update({
                    type: 'routes',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    reload = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { route } = await Request.get(utils, '/quick_scan/', {
                    type: 'get_route_admin',
                    id: this.id
                });
                this.create(route);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class RouteCategoryClass {

    id = null;
    name = null;
    information = null;
    service = null;
    companies = null;
    options = null;
    active = null;
    seeds = {}

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.information = props.information;
        this.active = props.active;
        this.service = props.service ? Service.create(props.service) : null;
        this.companies = props.companies || [];

        this.options = [];
        if(props.options && Array.isArray(props.options)) {
            this.options = props.options.map(opt => {
                let option = new RouteOptionClass().create(opt);
                option.category = {
                    id: this.id,
                    name: this.name,
                    information: this.information,
                    service: this.service,
                    companies: this.companies,
                    active: this.active
                };
                return option;
            });
        }

        if(props.seeds) {
            this.seeds = props.seeds;

            var notes = [];
            if(props.seeds.notes) {
                for(var i in props.seeds.notes) {
                    if(props.seeds.notes[i].deleted) {
                        continue;
                    }
                    let note = Note.create(props.seeds.notes[i]);
                    notes.push(note);
                }
                this.seeds.notes = notes;
            }
        }

        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            name: this.name,
            information: this.information,
            service: this.service,
            companies: this.companies
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            name: props.name || this.edits.name,
            information: props.information || this.edits.information,
            service: props.service !== undefined ? props.service : this.edits.service,
            companies: props.companies !== undefined ? props.companies : this.edits.companies
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/quick_scan/', {
                    type: 'new_category',
                    name: edits.name,
                    information: edits.information,
                    service: edits.service ? edits.service.id : null,
                    companies: edits.companies ? edits.companies.map(c => c.id) : null
                });

                this.id = id;
                this.name = edits.name;
                this.information = edits.information;
                this.service = edits.service;
                this.companies = edits.companies;

                utils.content.fetch('routeCategories');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/quick_scan/', {
                    type: 'update_category',
                    id: this.id,
                    name: edits.name,
                    information: edits.information,
                    service: edits.service ? edits.service.id : null,
                    companies: edits.companies ? edits.companies.map(c => c.id) : null
                });

                this.name = edits.name;
                this.information = edits.information;
                this.service = edits.service;
                this.companies = edits.companies;

                utils.content.update({
                    type: 'routeCategories',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class RouteOptionClass {

    id = null;
    name = null;
    service = null;
    category = null;
    address = null;
    location = null;
    active = null;
    seeds = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.name = props.name;
        this.category = props.category;
        this.address = props.address;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.active = Boolean(props.active);
        this.service = props.service ? Service.create(props.service) : null;
        this.category = props.category ? new RouteCategoryClass().create(props.category) : null;

        if(props.seeds) {
            this.seeds = props.seeds;

            var notes = [];
            if(props.seeds.notes) {
                for(var i in props.seeds.notes) {
                    if(props.seeds.notes[i].deleted) {
                        continue;
                    }
                    let note = Note.create(props.seeds.notes[i]);
                    notes.push(note);
                }
                this.seeds.notes = notes;
            }
        }
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            name: this.name,
            service: this.service,
            category: this.category,
            address: this.address,
            location: this.location
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            name: props.name || this.edits.name,
            service: props.service !== undefined ? props.service : this.edits.service,
            category: props.category !== undefined ? props.category : this.edits.category,
            address: props.address !== undefined ? props.address : this.edits.address,
            location: props.location !== undefined ? props.location : this.edits.location
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/quick_scan/', {
                    type: 'new_option',
                    name: edits.name,
                    service: edits.service ? edits.service.id : null,
                    address: edits.address,
                    category: edits.category ? edits.category.id : null,
                    location: edits.location
                });

                this.id = id;
                this.name = edits.name;
                this.service = edits.service;
                this.address = edits.address;
                this.location = edits.location

                utils.content.fetch('routeOptions');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/quick_scan/', {
                    type: 'update_option',
                    id: this.id,
                    name: edits.name,
                    service: edits.service ? edits.service.id : null,
                    address: edits.address,
                    category: edits.category.id,
                    location: edits.location
                });

                this.name = edits.name;
                this.service = edits.service;
                this.address = edits.address;
                this.location = edits.location

                utils.content.update({
                    type: 'routeOptions',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const statusCodes = {
    pending: null,
    approved: 1,
    returned: 2,
    cancelled: 3,
    in_progress: 5,
    completed: 6,
    unpaid: 7
}

const formatStatus = status => {
    if(!Appearance) {
        return
    }
    if(status === statusCodes.approved) {
        return {
            code: status,
            text: 'Approved',
            realText: 'Approved',
            color: Appearance.colors.primary(),
            image: 'images/status-approved.png'
        };

    } else if(status === statusCodes.returned) {
        return {
            code: status,
            text: 'Returned',
            realText: 'Returned to Queue',
            color: Appearance.colors.primary(),
            image: 'images/status-approved.png'
        };

    } else if(status === statusCodes.cancelled) {
        return {
            code: status,
            text: 'Cancelled',
            realText: 'Cancelled',
            color: Appearance.colors.red,
            image: 'images/status-cancelled.png'
        };

    } else if(status === statusCodes.in_progress) {
        return {
            code: status,
            text: 'Active',
            realText: 'In Progress',
            color: Appearance.colors.blue,
            image: 'images/status-active.png'
        };

    } else if(status === statusCodes.completed) {
        return {
            code: status,
            text: 'Completed',
            realText: 'Completed',
            color: Appearance.colors.darkGrey,
            image: 'images/status-completed.png'
        };

    } else if(status === statusCodes.unpaid) {
        return {
            code: status,
            text: 'Unpaid',
            realText: 'Unpaid',
            color: Appearance.colors.lightGrey,
            image: 'images/status-unpaid.png'
        };

    } else {
        return {
            code: null,
            text: 'Pending',
            realText: 'Pending',
            color: Appearance.colors.lightGrey,
            image: 'images/status-pending.png'
        };
    }
}

const fetchRoute = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/quick_scan/', {
                type: 'details',
                id: id
            });
            let route = new RouteClass().create(response);
            resolve(route);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchCategory = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { category } = await Request.get(utils, '/quick_scan/', {
                type: 'category_details',
                id: id
            });
            let target = new RouteCategoryClass().create(category);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchOption = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { option } = await Request.get(utils, '/quick_scan/', {
                type: 'option_details',
                id: id
            });
            let target = new RouteOptionClass().create(option);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new RouteClass(),
    create: props => new RouteClass().create(props),
    get: fetchRoute,
    formatStatus: formatStatus,
    status: statusCodes,
    Category: {
        new: () => new RouteCategoryClass(),
        get: fetchCategory,
        create: props => new RouteCategoryClass().create(props)
    },
    Option: {
        new: () => new RouteOptionClass(),
        get: fetchOption,
        create: props => new RouteOptionClass().create(props)
    }
}
