import React from 'react';
import Appearance from 'styles/Appearance.js';

const NoDataFound = ({ fill, message, title }) => {
    return (
        <div style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: fill,
            borderRadius: 10
        }}>
            <img
            src={'images/no-data-found.png'}
            style={{
                width: 45,
                height: 45,
                objectFit: 'contain',
                boxShadow: window.theme === 'dark' ?  '3px 3px 7px rgba(0,0,0,0.2), -3px -3px 5px rgba(255,255,255,0.0.5)' : '3px 3px 7px #c9c8ca, -3px -3px 5px #ffffff',
                borderRadius: 22.5
            }} />
            <span style={{
                ...Appearance.textStyles.title(),
                display: 'block',
                marginTop: 8
            }}>{title || 'Nothing to see here'}</span>
            <span style={{
                ...Appearance.textStyles.subTitle(),
                display: 'block',
                maxWidth: 250,
                textAlign: 'center',
                whiteSpace: 'normal'
            }}>{message}</span>
        </div>
    )
}
export default NoDataFound;
