import React, { useCallback, useEffect, useRef, useState } from 'react';

import { fetchSaaSAccounts } from 'managers/Users.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import Company from 'classes/Company.js';
import { CompanyDetails } from 'managers/Companies.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import Email from 'classes/Email.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import { Map } from 'views/MapElements.js';
import News from 'classes/News.js';
import { NewsCategoryDetails, NewsItemDetails } from 'managers/News.js';
import NoDataFound from 'views/NoDataFound.js';
import Order from 'classes/Order.js';
import { OrderDetails, OrderCategoryDetails, OrderHostDetails, OrderOptionDetails } from 'managers/Orders.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import PromoCode from 'classes/PromoCode.js';
import { PromoCodeDetails, SubscriptionPlanDetails } from 'managers/Payments.js';
import { QuickScanRouteDetails, QuickScanRouteCategoryDetails, QuickScanRouteOptionDetails } from 'managers/Routes.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import { ReservationDetails, ServiceDetails } from 'managers/Reservations.js';
import Route from 'classes/Route.js';
import Service from 'classes/Service.js';
import Subscription from 'classes/Subscription.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Vehicle from 'classes/Vehicle.js';
import { VehicleDetails, VehicleCategoryDetails } from 'managers/Vehicles.js';
import { VelocityComponent } from 'velocity-react';
import Views from 'views/Main.js';

// panels
export const SaaSAccountSettings = ({ index, utils }) => {

    const panelID = 'saasAccountSettings';
    const [account, setAccount] = useState(window.clientParameters);

    const getAssistProps = () => {
        return {
            message: 'Your account is how you are represented across the eCarra platform. This may include displaying you name, email address, phone number, profile image, and other identifying factors for your client account'
        }
    }

    const getContent = () => {
        return (
            Views.entry({
                title: account.name,
                subTitle: `Client ID: ${account.client_id}`,
                icon: account.logos.mobile && {
                    path: account.logos.mobile
                },
                bottomBorder: false,
                onClick: Utils.saas.details.bind(this, utils, account)
            })
        )
    }

    useEffect(() => {
        utils.content.subscribe(panelID, 'saas', {
            onUpdate: abstract => {
                setAccount(account => abstract.compare(account));
            }
        });
        return () => {
            utils.content.unsubscribe(panelID, 'saas');
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Account Settings'}
        index={index}
        utils={utils}
        options={{
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SaaSPayoutAccounts = ({ abstract, index, utils }) => {

    const panelID = 'saasPayoutAccounts';
    const [loading, setLoading] = useLoading();
    const [accounts, setAccounts] = useState(null);

    const onAccountClick = account => {
        utils.sheet.show({
            title: account.name,
            message: `Ending in ${account.last4}`,
            items: [{
                key: 'set-default',
                title: 'Set as Default',
                visible: account.default !== true,
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove Account',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                onRemoveAccount(account);
                return;
            }
            if(key === 'set-default') {
                onSetAsDefault(account);
                return;
            }
        })
    }

    const onAddAccount = () => {
        utils.layer.open({
            id: 'add-bank-account',
            Component: AddBankAccount.bind(this, {
                onSubmit: onSubmitNewAccount
            })
        });
    }

    const onRemoveAccount = account => {
        if(account.default) {
            utils.alert.show({
                title: 'Just a Second',
                message: `You can not remove your default bank account. Please add another account and set it as your default account if you wish to remove this account.`
            });
            return;
        }

        if(accounts.length === 1) {
            utils.alert.show({
                title: 'Just a Second',
                message: `It looks like you only have one account added. You'll need to add another account before you can remove this account.`
            });
            return;
        }

        utils.alert.show({
            title: 'Remove Account',
            message: 'Are you sure that you want to remove this account? You will no longer be able to receive future funds using this account but any scheduled payouts will still be deposited',
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveAccountConfirm(account);
                    return;
                }
            }
        })
    }

    const onRemoveAccountConfirm = async account => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.remove(utils, window.clientParameters);

            let parameters = await utils.client.refresh();
            setAccounts(parameters.stripe.payouts.accounts || []);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The account "${account.name}" has been removed and will no longer be available as a payment source or destination for ${utils.client.get().name}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing this bank account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetAsDefault = account => {
        utils.alert.show({
            title: 'Set as Default Account',
            message: 'Setting this account as your default bank account will route all future payments and payouts to this account. This includes all revenue from Orders and Reservations as well as any platform specific service charges or fees.',
            buttons: [{
                key: 'confirm',
                title: 'Set as Default',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetAsDefaultConfirm(account);
                    return;
                }
            }
        })
    }

    const onSetAsDefaultConfirm = async account => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.setAsDefault(utils, window.clientParameters);

            let parameters = await utils.client.refresh();
            setAccounts(parameters.stripe.payouts.accounts || []);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The account "${account.name}" has been set as the default payment source and destination for ${utils.client.get().name}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmitNewAccount = async (props, callback) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { name } = await Request.post(utils, '/saas/', {
                    type: 'add_bank_account',
                    app_id: utils.client.id(),
                    ...props
                });

                let parameters = await utils.client.refresh();
                setAccounts(parameters.stripe.payouts.accounts || []);

                utils.alert.show({
                    title: 'All Done!',
                    message: `The account "${name}" has been added and is now available as a payment source and destination for ${utils.client.get().name}.`
                });
                resolve();

            } catch(e) {
                reject(e);
                setLoading(false);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue adding this bank account. ${e.message || 'An unknown error occurred'}`
                })
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'Your payout account is the destination that we use to deposit your processed payments. These accounts receive the funds brought in from Reservations, Orders, Driver Tips, etc. Funds are deposited on a weekly basis unless otherwise specified',
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(accounts.length === 0) {
            return (
                Views.entry({
                    title: 'No Accounts Found',
                    subTitle: 'There are no accounts available to view',
                    bottomBorder: false
                })
            )
        }
        return accounts.map((account, index) => {
            return (
                Views.entry({
                    key: index,
                    title: account.name,
                    subTitle: `Ending in ${account.last4}`,
                    badge: {
                        text: account.default ? 'Default' : null,
                        color: Appearance.colors.primary()
                    },
                    bottomBorder: index !== accounts.length - 1,
                    onClick: onAccountClick.bind(this, account)
                })
            )
        });
    }

    const setupTarget = () => {
        let parameters = utils.client.get().parameters;
        setAccounts(parameters && parameters.stripe && parameters.stripe.payouts ? (parameters.stripe.payouts.accounts || []) : []);
    }

    useEffect(() => {
        setupTarget();
        utils.content.subscribe(panelID, 'saas', {
            onFetch: setupTarget
        });
        return () => {
            utils.content.unsubscribe(panelID, 'saas');
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Payout Accounts'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps()
            },
            buttons: [{
                key: 'new',
                title: 'Add Bank Account',
                style: 'default',
                onClick: onAddAccount
            }]
        }}>
            {getContent()}
        </Panel>
    )
}

export const SaaSUsageStatistics = ({ category, allClients }, { index, utils }) => {

    const panelID = `saasStats${Utils.ucFirst(category)}`;
    const chartRef = useRef(null);

    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [chart, setChart] = useState(null);
    const [cost, setCost] = useState(null);
    const [dates, setDates] = useState({ start: moment().startOf('month'), end: moment().endOf('month') });
    const [loading, setLoading] = useLoading();

    const getAccountSelector = () => {
        if(window.client_id !== 'ecarra') {
            return null;
        }
        return (
            <>
            <span style={Appearance.textStyles.title()}>{'Account'}</span>
            <select className={`custom-select ${window.theme} mt-1 mb-3`}
            defaultValue={account ? account.name : null}
            onChange={e => {
                let account = accounts.find(a => a.id === parseInt(Utils.attributeForKey.select(e, 'id')))
                setAccount(account);
            }}>
                {accounts.map((account, index) => {
                    return (
                        <option key={index} id={account.id}>{account.name}</option>
                    )
                })}
            </select>
            </>
        )
    }

    const getAssistProps = () => {
        return {
            message: `${getCategory().description}. These costs may include additional service fees and may be rounded for this graph to ensure easier readability`
        }
    }

    const getCategory = () => {
        switch(category) {
            case 'mapbox':
            return {
                title: 'Mapbox Geocoding Requests',
                description: 'We use a service hosted by Mapbox that provides location based information based on latitude and logitude. This feature is located in the mobile apps and inside of Seeds. Each geocoding instance equals one request'
            }

            case 'google':
            return {
                title: 'Google Autocomplete Requests',
                description: 'We use a service hosted by Google that provides location based search results based on user input. This feature is located in the mobile apps and inside of Seeds. Each search attempt equals one request'
            }

            case 'here':
            return {
                title: 'Here Flow and Incident Requests',
                description: 'We use a service hosted by Here Technologies that provides traffic data based on a user location. This feature is located in the mobile apps and inside of Seeds. Each instance of use equals one request'
            }

            case 'two_factor':
            return {
                title: 'Authentication Requests',
                description: 'We use a service hosted by Amazon to send SMS messages to customers during the authentication process. This feature is located in the mobile apps and inside of Seeds. Each instance of use equals one request'
            }

            default:
            return {
                title: 'Unknown Statistic',
                description: 'Description not available'
            }
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(!chart || chart.labels.length < 3) {
            return (
                <NoDataFound message={'At least 3 entries of usage are needed for the graph'} />
            )
        }
        return (
            <Line
            ref={chartRef}
            width={500}
            height={100}
            data={{
                labels: chart.labels,
                datasets: [{
                    data: chart.data,
                    borderColor: Appearance.colors.grey(),
                    fill: true,
                    backgroundColor: Utils.hexToRGBA(Appearance.colors.grey(), 0.25),
                    pointRadius: 5,
                    pointBorderWidth: 2,
                    pointBackgroundColor: 'white'
                }]
            }}
            options={{
                title: {
                    display: false
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            return `${tooltipItem.yLabel} ${parseInt(tooltipItem.yLabel) === 1 ? 'request' : 'requests'} estimated at ${getEstimatedCost(tooltipItem.yLabel)}`
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: Appearance.colors.transparent,
                            display: false
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 20
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: Appearance.colors.transparent,
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                            callback: (value, index, values) => {
                                return Utils.numberFormat(value);
                            }
                        }
                    }]
                }
            }} />
        )
    }

    const getEstimatedCost = requests => {
        if(!cost || !chart || !chart.requests) {
            return 'Not available';
        }
        let estimate = cost * (requests || (chart.requests.reduce((total, request) => {
            return total += request.total;
        }, 0)));
        return estimate < 0.01 ? `$${parseFloat(estimate).toFixed(4)}` : Utils.toCurrency(estimate);
    }

    const fetchAccounts = async () => {
        try {
            if(!allClients) {
                return;
            }
            let { accounts } = await fetchSaaSAccounts(utils);
            setAccounts(accounts);
            setAccount(accounts.length > 0 ? accounts[0] : null);

        } catch(e) {
            console.error(e.message);
        }
    }

    const fetchStats = async () => {
        if(!account) {
            setAccount(allClients ? null : utils.client.get());
            return;
        }
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            setLoading(true);
            utils.fetching.set(panelID, true);
            let { cost, requests } = await Request.get(utils, '/saas/',  {
                app_id: account.client_id,
                category: category,
                end_date: moment(dates.end).utc().unix(),
                start_date: moment(dates.start).utc().unix(),
                type: 'external_usage'
            });

            utils.fetching.set(panelID, false);
            setLoading(false);
            setCost(cost);
            setChart({
                requests: requests,
                data: requests.map(entry => entry.total),
                labels: requests.map(entry => moment(entry.date).format('MM/DD'))
            });

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the statistics for "${getCategory().title}". ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchStats();
    }, [account, dates]);

    useEffect(() => {
        fetchAccounts();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getCategory().title}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            <div
            className={'row p-0 m-0'}
            style={{
                padding: 10
            }}>
                <div
                className={'col-12 col-xl-8'}
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 350,
                    padding: 12,
                    borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
                }}>
                    {getContent()}
                </div>
                <div
                className={'col-12 col-xl-4'}
                style={{
                    padding: '8px 12px 8px 12px',
                    borderLeft: Utils.isMobile() ? null : `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div
                    className={'mb-3'}
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={Appearance.textStyles.title()}>{`Cost per request: $${cost || 0}`}</span>
                        <span style={Appearance.textStyles.subTitle()}>{`Estimated cost for date range: ${getEstimatedCost()}`}</span>
                    </div>

                    {getAccountSelector()}

                    <span
                    className={'my-1'}
                    style={{
                        ...Appearance.textStyles.title(),
                        display: 'block'
                    }}>{'Date Range'}</span>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={dates.start}
                    selectedEndDate={dates.end}
                    onStartDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                start: date
                            }
                        })
                    }}
                    onEndDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                end: date
                            }
                        })
                    }} />
                </div>
            </div>
        </Panel>
    )
}

export const TransactionalEmails = ({ index, utils }) => {

    const panelID = 'transactionalEmails';
    const limit = 5;

    const [emails, setEmails] = useState(null);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onEmailClick = email => {
        utils.layer.open({
            id: `transactional-email-details-${email.id}`,
            abstract: Abstract.create({
                type: 'emails',
                object: email
            }),
            Component: TransactionalEmailDetails
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'These are the templates for transactional emails sent by the system. These templates are customizable to show or hide various pieces of information.'
        }
    }

    const getContent = () => {
        if(loading === 'true') {
            return Views.loader();
        }
        if(emails.length === 0) {
            return (
                Views.entry({
                    title: 'No Emails Found',
                    subTitle: 'There are no transactional emails available to view',
                    bottomBorder: false
                })
            )
        }
        return emails.map((email, index) => {
            return (
                Views.entry({
                    key: email.id,
                    title: email.title,
                    subTitle: `Last Updated: ${moment(email.date).format('MMMM Do, YYYY [at] h:mma')}`,
                    bottomBorder: index !== emails.length - 1,
                    onClick: onEmailClick.bind(this, email)
                })
            )
        });
    }

    const fetchEmails = async download => {
        try {
            let { download_props, emails, paging } = await Request.get(utils, '/resources/',  {
                type: 'transactional_emails',
                limit: limit,
                download: download === true,
                ...manager
            });

            setLoading(false);
            if(download_props) {
                Utils.handleDownload(download_props);
                return;
            }
            setPaging(paging);
            setEmails(emails.map(email => Email.create(email)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the transactional emails list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchEmails();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'emails', {
            onFetch: fetchEmails,
            onRemove: abstract => {
                setEmails(emails => {
                    return emails.filter(email => email.id !== abstract.getID())
                });
            },
            onUpdate: abstract => {
                setEmails(emails => {
                    return emails.map(email => abstract.compare(email));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID, 'emails');
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Transactional Emails'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps()
            },
            search: {
                placeholder: 'Search by email name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const Zones = ({ category, channel }, { index, utils }) => {

    const panelID = `${category}Zones`;
    const defaultResolution = 8;
    const limit = 5;

    const [colors, setColors] = useState([]);
    const [features, setFeatures] = useState({});
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ aggregate_key: 'destination', end_date: null, start_date: null });
    const [place, setPlace] = useState(null);
    const [region, setRegion] = useState(null);
    const [resolution, setResolution] = useState(defaultResolution);
    const [showLegend, setShowLegend] = useState(false);
    const [zones, setZones] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Reservation Zones',
                dates: manager,
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/resources/',  {
                    type: 'zones',
                    category: category,
                    resolution: resolution,
                    ...window.userLocation,
                    ...formatResults(utils),
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onShowZoneDetails = zone => {
        utils.layer.open({
            id: `zone-details-${zone}`,
            Component: ZoneDetails.bind(this, {
                aggregate_key: manager.aggregate_key,
                category: category,
                dates: manager,
                resolution: resolution,
                zone: zone
            })
        });
    }

    const onUpdateZones = useCallback(() => {
        fetchZones(resolution);
    }, [resolution]);

    const onZoomChange = zoom => {

        // do not adjust resolution or fetch new values if a zone_id is present
        if(manager.zone_id) {
            return;
        }

        // calculate resolution value from zoom value
        let val = parseInt(zoom > 15 ? 15 : zoom) - 4;
        val = val < 0 ? 0 : (val > 15 ? 15 : val);

        // use cached values if found
        if(features[val]) {
            setResolution(val);
            return;
        }

        // fetch zone values for resolution
        fetchZones(val);
    }

    const getAnnotations = () => {
        if(!place) {
            return null;
        }
        return [{
            id: 'place',
            title: place.name || place.address,
            location: place.location,
            icon: { 
                color: Appearance.colors.blue,
                type: 'broadcast'
            }
        }]
    }

    const getAssistProps = () => {
        return {
            message: `Zones help visualize how many ${category} have occurred within a specific area of the world. Zoom in and out to see the zones recalculate their size. Hover over a zone to see how many ${category} occurred within the zone. Click on a zone to see each inidividual ${category} within that zone.`,
            items: [{
                key: 'download',
                title: `Download ${category === 'orders' ? `${channel.name} Order` : 'Reservation'} Zones`,
                style: 'default'
            }]
        }
    }

    const getFeatures = () => {
        let data = manager.zone_id ? features[manager.zone_id] : features[resolution];
        if(!data) {
            return null;
        }
        return {
            id: 'reservation_zones',
            data: data,
            region: region,
            layer: {
                type: 'fill',
                paint: {
                    'fill-opacity': 0.5,
                    'fill-color': [ 'get', 'color' ]
                }
            },
            onClick: feature => onShowZoneDetails(feature.properties.zone),
            onHover: feature => {
                let val = feature.properties[manager.aggregate_key];
                let label = category === 'reservations' ? 'ride' : 'order';
                return {
                    component: (
                        Views.entry({
                            title: `${val} ${val === 1 ? label : `${label}s`}`,
                            subTitle: `Zone ${feature.properties.zone}`,
                            icon: {
                                path: 'images/zones-icon-clear.png',
                                style: {
                                    backgroundColor: feature.properties.color
                                }
                            },
                            bottomBorder: false
                        })
                    )
                }
            }
        }
    }

    const getContent = () => {
        if(loading === 'true') {
            return Views.loader();
        }
        return (
            <div
            className={'row p-0 m-0'}
            style={{
                padding: 12
            }}>
                <div
                className={'col-12 col-xl-8 p-3'}
                style={{
                    position: 'relative',
                    borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
                }}>
                    <Map
                    utils={utils}
                    center={window.userLocation}
                    showUserLocation={false}
                    isZoomEnabled={true}
                    isRotationEnabled={true}
                    isScrollEnabled={true}
                    annotations={getAnnotations()}
                    features={getFeatures()}
                    onZoomChange={onZoomChange}
                    style={{
                        height: 500
                    }}/>
                </div>
                <div
                className={'col-12 col-xl-4'}
                style={{
                    padding: '8px 12px 8px 12px',
                    borderLeft: Utils.isMobile() ? null : `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div style={{
                        marginBottom: 25
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            marginBottom: 8
                        }}>{'Zone Lookup'}</span>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <TextField
                            utils={utils}
                            useDelay={true}
                            value={manager.zone_id}
                            placeholder={'Search by zone id...'}
                            onChange={text => setManager('zone_id', text)} />
                            {manager.zone_id && (
                                <img
                                className={'text-button'}
                                src={'images/red-x-icon.png'}
                                onClick={() => setManager('zone_id', null)}
                                style={{
                                    width: 25,
                                    height: 25,
                                    marginLeft: 8
                                }} />
                            )}
                        </div>
                    </div>
                    <div style={{
                        marginBottom: 25
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            marginBottom: 8
                        }}>{'Point of Interest'}</span>
                        <AddressLookupField
                        utils={utils}
                        inline={true}
                        placeholder={'Search by name or address...'}
                        onChange={place => setPlace(place)}
                        containerStyle={{
                            width: '100%'
                        }}/>
                    </div>
                    <div style={{
                        marginBottom: 25
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            marginBottom: 8
                        }}>{'Visualization'}</span>
                        <select
                        className={`custom-select ${window.theme}`}
                        value={manager.aggregate_key === 'origin' ? 'Pickup' : 'Drop-Off'}
                        onChange={evt => {
                            setManager('aggregate_key', Utils.attributeForKey.select(evt, 'id'));
                        }}>
                            <option id={'origin'}>{'Pickup'}</option>
                            <option id={'destination'}>{'Drop-Off'}</option>
                        </select>
                    </div>
                    <div style={{
                        marginBottom: showLegend ? 25 : 6
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            marginBottom: 8
                        }}>{'Date Range'}</span>
                        <DualDatePickerField
                        utils={utils}
                        canRemove={true}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)} />
                    </div>
                    {showLegend === true && colors.length > 0 && (
                        <div style={{
                            marginBottom: 0
                        }}>
                            <span style={{
                                ...Appearance.textStyles.title(),
                                display: 'block',
                                marginBottom: 8
                            }}>{'Legend'}</span>
                            <div style={{
                                ...Appearance.styles.unstyledPanel(),
                                width: '100%'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%'
                                }}>
                                    {colors.map((color, index) => (
                                        <div
                                        key={index}
                                        style={{
                                            flexGrow: 1,
                                            backgroundColor: color,
                                            height: 25
                                        }} />
                                    ))}
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '6px 12px 6px 12px',
                                    width: '100%'
                                }}>
                                    {[0,25,50,75,100].map((value, index, values) => (
                                        <span style={{
                                            ...Appearance.textStyles.subTitle()
                                        }}>{`${value}${index === values.length - 1 ? '+' : ''}`}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const getTitle = () => {
        switch(category) {
            case 'orders':
            return `${channel.name} Order Zones`;

            case 'reservations':
            return 'Reservation Zones';

            default:
            return 'Zones';
        }
    }

    const fetchZones = async resolution => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            utils.fetching.set(panelID, true);
            if(loading !== 'init') {
                setLoading(true);
            }
            let { colors, region, zones } = await Request.get(utils, '/resources/',  {
                type: 'zones',
                category: category,
                resolution: resolution,
                ...window.userLocation,
                ...formatResults(utils)
            });

            setLoading(false);
            utils.fetching.set(panelID, false);

            setZones(zones);
            setColors(colors);
            setRegion(region);
            setResolution(resolution);

            // set feature collection with resolution as key
            // use zone_id as key if a zone_id was supplied
            setFeatures(features => {
                return update(features, {
                    [manager.zone_id || resolution]: {
                        $set: {
                            type: 'FeatureCollection',
                            features: zones.map(entry => {
                                return {
                                    type: 'Feature',
                                    properties: entry.properties,
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: [entry.coordinates]
                                    }
                                }
                            })
                        }
                    }
                })
            });

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${category} zones. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchZones(resolution);
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, category, {
            onUpdate: onUpdateZones
        });
        return () => {
            utils.content.unsubscribe(panelID, category);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getTitle()}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddBankAccount = ({ onSubmit }, { abstract, index, options, utils }) => {

    const layerID = 'add-bank-account';
    const [creds, setCreds] = useState({});
    const [dropDown, setDropDown] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmitNewAccount = async () => {
        try {
            // verify that all required fields are filled out
            await validateRequiredFields(getFields);
            if(creds.account !== creds.account_confirm) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'It looks like the account numbers that you provided do not match'
                });
                return;
            }

            setLoading(true);
            await Utils.sleep(1);
            await onSubmit(creds);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'The bank account has been added',
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
        }
    }

    const onUpdateTarget = props => {
        try {
            setCreds(creds => ({
                ...creds,
                ...props
            }));
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'account_holder_type',
                title: 'Account Type',
                description: 'Please specify whether this account is setup as a business account or if it is structured as the account for an individual.',
                component: 'list',
                value: creds.account_holder_type,
                onChange: item => onUpdateTarget({ account_holder_type: item && item.id }),
                items: [{
                    id: 'company',
                    title: 'Business Account'
                },{
                    id: 'individual',
                    title: 'Personal/Individual Account'
                }]
            },{
                key: 'account_holder_name',
                title: 'Account Holder Name',
                description: 'Please provide the legal name of the primary account holder for this account',
                value: creds.account_holder_name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ account_holder_name: text })
            },{
                key: 'country',
                title: 'Country',
                description: 'This should be the country where this acccount resides. It most scenarios this will be set to the United States.',
                value: creds.country,
                component: 'list',
                onChange: item => onUpdateTarget({ country: item && item.id }),
                items: [{
                    id: 'US',
                    title: 'United States'
                },{
                    id: 'CA',
                    title: 'Canada'
                },{
                    id: 'MX',
                    title: 'Mexico'
                }]
            },{
                key: 'currency',
                title: 'Currency',
                description: 'Please provide the primary currency used for this acccount. It most scenarios this will be set as USD if the account resides in the United States.',
                value: creds.currency,
                component: 'list',
                onChange: item => onUpdateTarget({ currency: item && item.id }),
                items: [{
                    id: 'usd',
                    title: 'USD'
                },{
                    id: 'cad',
                    title: 'CAD'
                },{
                    id: 'mxn',
                    title: 'MXN'
                }]
            },{
                key: 'routing',
                title: 'Routing Number',
                description: 'Please provide the routing number for this account. This information can be found by logging into the online account for this bank account.',
                value: creds.routing,
                component: 'textfield',
                onChange: text => onUpdateTarget({ routing: text })
            },{
                key: 'account',
                title: 'Account Number',
                description: 'Please provide the account number for this account. This information can be found by logging into the online account for this bank account.',
                value: creds.account,
                component: 'textfield',
                onChange: text => onUpdateTarget({ account: text }),
                props: {
                    isSecure: true
                }
            },{
                key: 'account_confirm',
                title: 'Confirm Account Number',
                description: 'Please confirm the account number for this account. This information can be found by logging into the online account for this bank account.',
                value: creds.account_confirm,
                component: 'textfield',
                onChange: text => onUpdateTarget({ account_confirm: text }),
                props: {
                    isSecure: true
                }
            }]
        }];
    }

    return (
        <Layer
        id={layerID}
        title={`Add Bank Account`}
        dropDown={dropDown}
        index={index}
        options={{
            ...options,
            sizing: 'small',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Submit',
            color: 'primary',
            onClick: onSubmitNewAccount
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields} />
        </Layer>
    )
}

export const EditTransactionalEmail = ({ abstract, index, options, utils }) => {

    const layerID = `edit-transactional-email-${abstract.getID()}`;
    const [dropDown, setDropDown] = useState(null);
    const [email, setEmail] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [url, setURL] = useState(`https://ecarra.app/database/templates/v4/${abstract.object.type}.php`);

    const onItemClick = key => {
        let detail = email.data[key];
        let props = detail.data || {};
        setDropDown({
            title: detail.key,
            message: `You can choose ${typeof(detail.value) === 'string' ? 'what you would like this area to say' : 'whether or not to show this area'} below.`,
            buttons: [{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ data: props })
            }],
            content: (
                <>
                {typeof(detail.value) === 'string'
                    ?
                    <TextView
                    value={detail.value}
                    onChange={text => {
                        props = {
                            ...props,
                            key: key,
                            value: text
                        }
                    }}/>
                    :
                    <BoolToggle
                    enabled={'Show'}
                    disabled={'Hide'}
                    isEnabled={detail.value}
                    onChange={enabled => {
                        props = {
                            ...props,
                            key: key,
                            value: enabled
                        }
                    }}/>
                }
                </>
            )
        })
    }

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.update(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" transactional email has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this transactional email. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setEmail(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateURL = () => {
        let payload = {
            app_id: utils.user.get().client_id,
            data: email.data
        }
        let data = btoa(JSON.stringify(payload));
        setURL(`https://ecarra.app/database/templates/v4/${abstract.object.type}.php?d=${data}&theme_style=${window.theme}`);
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setEmail(edits);
    }

    useEffect(() => {
        onUpdateURL();
    }, [email.data]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Editing "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setEmail(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>

            <div style={{
                position: 'relative',
                height: window.innerHeight / 1.5,
                borderRadius: 10,
                overflow: 'hidden',
                boxShadow: Appearance.boxShadow(),
                backgroundColor: Appearance.colors.primary(),
                marginBottom: 12
            }}>
                <VelocityComponent
                duration={250}
                animation={{
                    opacity: opacity
                }}>
                    <iframe
                    src={url}
                    title={'preview'}
                    onLoad={() => setOpacity(1)}
                    frameBorder={0}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}/>
                </VelocityComponent>
            </div>

            {Object.keys(email.data).map((key, index, items) => {
                let detail = email.data[key];
                return (
                    Views.row({
                        key: index,
                        label: detail.key,
                        value: typeof(detail.value) === 'string' ? detail.value : (detail.value ? 'Show' : 'Hide'),
                        bottomBorder: index !== items.length - 1,
                        onClick: onItemClick.bind(this, key)
                    })
                )
            })}
        </Layer>
    )
}

export const SystemEventDetails = ({ abstract, index, options, utils }) => {

    const layerID = `system-event-details-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState(null);

    const onCompanyClick = async () => {
        try {
            utils.layer.open({
                id: `company-details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'companies',
                    object: target
                }),
                Component: CompanyDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this company. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCreatorClick = async () => {
        try {
            let user = await User.get(utils, abstract.object.user.user_id);
            utils.layer.open({
                id: `user-details-${user.user_id}`,
                abstract: Abstract.create({
                    type: 'users',
                    object: user
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewsCategoryClick = async () => {
        try {
            utils.layer.open({
                id: `news-category-details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'newsCategories',
                    object: target
                }),
                Component: NewsCategoryDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this ${target.channel.name} category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewsItemClick = async () => {
        try {
            utils.layer.open({
                id: `news-item-details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'newsItems',
                    object: target
                }),
                Component: NewsItemDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this ${target.channel.name} item. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOrderClick = async () => {
        try {
            utils.layer.open({
                id: `order-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'orders',
                    object: target
                }),
                Component: OrderDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this ${target.channel.name} order. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOrderCategoryClick = async () => {
        try {
            utils.layer.open({
                id: `order-category-details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'orderCategories',
                    object: target
                }),
                Component: OrderCategoryDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this ${target.channel.name} order category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOrderHostClick = async () => {
        try {
            utils.layer.open({
                id: `order-host-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'orderHosts',
                    object: target
                }),
                Component: OrderHostDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this ${target.channel.name} order host. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOrderOptionClick = async () => {
        try {
            utils.layer.open({
                id: `order-option-details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'orderOptions',
                    object: target
                }),
                Component: OrderOptionDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this order option. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onPromoCodeClick = async () => {
        try {
            utils.layer.open({
                id: `promo-code-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'promotions',
                    object: target
                }),
                Component: PromoCodeDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this promotion. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onReservationClick = async () => {
        try {
            utils.layer.open({
                id: `reservation-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'reservations',
                    object: target
                }),
                Component: ReservationDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this reservation. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRouteClick = async () => {
        try {
            utils.layer.open({
                id: `route-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'routes',
                    object: target
                }),
                Component: QuickScanRouteDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this route. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRouteCategoryClick = async () => {
        try {
            utils.layer.open({
                id: `route-category-details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'routeCategories',
                    object: target
                }),
                Component: QuickScanRouteCategoryDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this route category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRouteOptionClick = async () => {
        try {
            utils.layer.open({
                id: `route-option-details_${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'routeOptions',
                    object: target
                }),
                Component: QuickScanRouteOptionDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this route option. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onServiceClick = async () => {
        try {
            utils.layer.open({
                id: `service-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'services',
                    object: target
                }),
                Component: ServiceDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this service. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSubscriptionPlanClick = async () => {
        try {
            utils.layer.open({
                id: `subscription-plan-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'subscriptionPlans',
                    object: target
                }),
                Component: SubscriptionPlanDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this subscription plan. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserClick = async () => {
        try {
            utils.layer.open({
                id: `user-details-${abstract.object.target.object.user_id}`,
                abstract: Abstract.create({
                    type: 'users',
                    object: target
                }),
                Component: UserDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onVehicleClick = async () => {
        try {
            utils.layer.open({
                id: `vehicle-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'vehicles',
                    object: target
                }),
                Component: VehicleDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this vehicle. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onVehicleCategoryClick = async () => {
        try {
            utils.layer.open({
                id: `vehicle-category-details-${abstract.object.target.object.id}`,
                abstract: Abstract.create({
                    type: 'vehicleCategories',
                    object: target
                }),
                Component: VehicleCategoryDetails
            })
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this vehicle category. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getContentDiff = () => {
        let { components } = Utils.getSystemEventProps(abstract.object);
        return (
            <LayerItem
            key={index}
            title={'Details'}
            shouldStyle={false}>
                {components}
            </LayerItem>
        )
    }

    const getTarget = () => {

        let props = {};
        let title = Utils.ucFirst(abstract.object.target.type);

        // return loading placeholder if target is not prepared
        if(!target) {
            return (
                <LayerItem
                title={abstract.object.title}
                childrenStyle={{
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        display: 'block'
                    }}>{'Loading...'}</span>
                </LayerItem>
            );
        }

        // wrap abstract target attemps in a try/catch for objects that are deleted from the database
        // crash will happend otherwise for undefined nested values
        try {
            switch(abstract.object.target.type) {
                case 'companies':
                title = 'Company';
                props = {
                    title: target.name,
                    subTitle: `Member Since: ${Utils.formatDate(target.member_since)}`,
                    icon: { path: target.image },
                    onClick: onCompanyClick
                }
                break;

                case 'newsCategories':
                title = `${target.channel.name} Category`;
                props = {
                    title: target.title,
                    subTitle: target.items && target.items.length > 0 ? `${target.items.length} ${target.channel.name} ${target.items.length === 1 ? 'Item' : 'Items'}` : `No ${target.channel.name} Items have been added`,
                    hideIcon: true,
                    onClick: onNewsCategoryClick
                }
                break;

                case 'newsItems':
                title = `${target.channel.name} Item`;
                props = {
                    title: target.title,
                    subTitle: target.description,
                    icon: { path: target.image },
                    onClick: onNewsItemClick
                }
                break;

                case 'orders':
                title = `${target.channel.name} Order`;
                props = {
                    title: target.customer.full_name,
                    subTitle: Utils.formatDate(target.drop_off_date),
                    icon: { path: target.customer.avatar },
                    onClick: onOrderClick,
                    badge: {
                        text: target.status.text,
                        color: target.status.color
                    }
                }
                break;

                case 'orderCategories':
                title = `${target.channel.name} Order Cartegory`;
                props = {
                    title: `${target.host.name} ${target.title}`,
                    subTitle: target.options && target.options.length > 0 ? `${target.options.length} ${target.channel.name} ${target.options.length === 1 ? 'Option' : 'Options'}` : `No ${target.channel.name} options have been added`,
                    hideIcon: true,
                    onClick: onOrderCategoryClick
                }
                break;

                case 'orderHosts':
                title = `${target.channel.name} Order Host`;
                props = {
                    title: target.name,
                    subTitle: target.description,
                    icon: { path: target.image },
                    onClick: onOrderHostClick
                }
                break;

                case 'orderOptions':
                title = `${target.channel.name} Order Option`;
                props = {
                    title: target.name,
                    subTitle: target.description,
                    icon: { path: target.image },
                    onClick: onOrderOptionClick
                }
                break;

                case 'promotions':
                title = 'Promotion';
                props = {
                    title: `${target.title} (${target.code})`,
                    subTitle: target.description,
                    hideIcon: true,
                    onClick: onPromoCodeClick
                }
                break;

                case 'reservations':
                title = 'Reservation';
                props = {
                    title: target.customer.full_name,
                    subTitle: Utils.formatDate(target.pickup_date),
                    icon: { path: target.customer.avatar },
                    onClick: onReservationClick,
                    badge: {
                        text: target.status.text,
                        color: target.status.color
                    }
                }
                break;

                case 'routes':
                title = 'QuickScan Route';
                props = {
                    title: target.name || abstract.object.title,
                    subTitle: target.phone_number,
                    onClick: onRouteClick,
                    icon: { path: target.driver.avatar },
                    badge: [{
                        text: target.status.text,
                        color: target.status.color
                    }]
                }
                break;

                case 'routeCategories':
                title = 'QuickScan Route Category';
                props = {
                    title: target.name,
                    subTitle: target.options ? `${target.options.length} Route ${target.options.length === 1 ? 'Option' : 'Options'}` : 'No Route Options Found',
                    hideIcon: true,
                    onClick: onRouteCategoryClick
                }
                break;

                case 'routeOptions':
                title = 'QuickScan Route Option';
                props = {
                    title: target.name,
                    subTitle: target.category ? target.category.name : 'Unknown Category',
                    hideIcon: true,
                    onClick: onRouteOptionClick
                }
                break;

                case 'services':
                title = 'Service';
                props = {
                    title: target.name,
                    subTitle: target.information,
                    hideIcon: true,
                    onClick: onServiceClick,
                    badge: [{
                        text: target.default_for_channel ? 'Default' : null,
                        color: Appearance.colors.primary()
                    }]
                }
                break;

                case 'subscriptionPlans':
                title = 'Subscription Plan';
                props = {
                    title: target.name,
                    subTitle: target.description,
                    hideIcon: true,
                    onClick: onSubscriptionPlanClick
                }
                break;

                case 'users':
                title = 'User Account';
                props = {
                    title: target.full_name,
                    subTitle: target.email_address || 'Email address not available',
                    badge: User.formatLevel(target.level),
                    onClick: onUserClick,
                    icon: {
                        path: target.avatar
                    }
                }
                break;

                case 'vehicles':
                title = 'Vehicle';
                props = {
                    title: `${target.customer ? `${target.customer.full_name}'s ` : `${target.company ? `${target.company.name}'s ` : ''}`}${target.category.name}`,
                    subTitle: target.vin,
                    icon: getVehicleIcon(target),
                    onClick: onVehicleClick
                }
                break;

                case 'vehicleCategories':
                title = 'Vehicle Category';
                props = {
                    title: target.name,
                    subTitle: `${Utils.toCurrency(target.rate)} per mile`,
                    hideIcon: true,
                    onClick: onVehicleCategoryClick
                }
                break;

                default:
                return null;
            }

        } catch(e) {
            return null;
        }

        return (
            <LayerItem title={title}>
                {Views.entry({
                    ...props,
                    singleItem: true,
                    bottomBorder: false,
                })}
            </LayerItem>
        );
    }

    const getUser = () => {
        let { user } = abstract.object;
        if(!user) {
            return null;
        }

        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 20
            }}>
                {Views.entry({
                    title: user.full_name,
                    subTitle: user.email_address,
                    badge: {
                        text: Utils.formatDate(moment.utc(abstract.object.date).local()),
                        color: Appearance.colors.primary()
                    },
                    icon: {
                        path: user.avatar
                    },
                    singleItem: true,
                    bottomBorder: false,
                    onClick: onCreatorClick
                })}
            </div>
        )
    }

    const getVehicleIcon = vehicle => {
        if(vehicle.customer) {
            return { path: vehicle.customer.avatar };
        }
        if(vehicle.company) {
            return { path: vehicle.company.image };
        }
        return {
            path: `/images/vehicle-icon-${window.theme}.png`,
            ...Appearance.icons.padded()
        }
    }

    const fetchTarget = async () => {
        try {
            let id = abstract.object.target.object.id;
            switch(abstract.object.target.type) {
                case 'companies':
                let company = await Company.get(utils, id);
                setTarget(company);
                break;

                case 'newsCategories':
                let news_category = await News.Category.get(utils, id);
                setTarget(news_category);
                break;

                case 'newsItems':
                let news_items = await News.Item.get(utils, id);
                setTarget(news_items);
                break;

                case 'orders':
                let order = await Order.get(utils, id);
                setTarget(order);
                break;

                case 'orderCategories':
                let order_category = await Order.Category.get(utils, id);
                setTarget(order_category);
                break;

                case 'orderHosts':
                let order_host = await Order.Host.get(utils, id);
                setTarget(order_host);
                break;

                case 'orderOptions':
                let order_option = await Order.Option.get(utils, id);
                setTarget(order_option);
                break;

                case 'promotions':
                let promotion = await PromoCode.get(utils, id);
                setTarget(promotion);
                break;

                case 'reservations':
                let reservation = await Reservation.get(utils, id);
                setTarget(reservation);
                break;

                case 'routes':
                let route = await Route.get(utils, id);
                setTarget(route);
                break;

                case 'routeCategories':
                let route_category = await Route.Category.get(utils, id);
                setTarget(route_category);
                break;

                case 'routeOptions':
                let route_option = await Route.Option.get(utils, id);
                setTarget(route_option);
                break;

                case 'services':
                let service = await Service.get(utils, id);
                setTarget(service);
                break;

                case 'subscriptionPlans':
                let subscription_plan = await Subscription.Plan.get(utils, id);
                setTarget(subscription_plan);
                break;

                case 'users':
                let user = await User.get(utils, abstract.object.target.object.user_id);
                setTarget(user);
                break;

                case 'vehicles':
                let vehicle = await Vehicle.get(utils, id);
                setTarget(vehicle);
                break;

                case 'vehicleCategories':
                let vehicle_category = await Vehicle.Category.get(utils, id);
                setTarget(vehicle_category);
                break;
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving some of the information for this system event. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            {getUser()}
            {getTarget()}
            {getContentDiff()}
        </Layer>
    )
}

export const TransactionalEmailDetails = ({ abstract, index, options, utils }) => {

    const layerID = `transactional-email-details-${abstract.getID()}`;
    const [email, setEmail] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [opacity, setOpacity] = useState(0);
    const [url, setURL] = useState('https://ecarra.app/database/templates/v4/editing-helper.php');

    const onEditClick = () => {
        setLayerState('close');
        utils.layer.open({
            id: `edit-transactional-email-${abstract.getID()}`,
            abstract: abstract,
            Component: EditTransactionalEmail
        });
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: email.id
            },{
                key: 'title',
                title: 'Title',
                value: email.title
            },{
                key: 'date',
                title: 'Last Updated',
                value: email.updated_by ? `${Utils.formatDate(email.date)} by ${email.updated_by.full_name}` : null
            }]
        }];
    }

    useEffect(() => {
        let data = btoa(JSON.stringify({
            app_id: utils.user.get().client_id,
            data: email.data
        }));
        setURL(`https://ecarra.app/database/templates/v4/${email.type}.php?d=${data}&theme_style=${window.theme}`);
    }, [email]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'transactionalEmails', {
            onUpdate: next => {
                setEmail(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        options={{
            ...options,
            layerState: layerState
        }}
        buttons={[{
            key: 'edit',
            text: 'Customize',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <div style={{
                position: 'relative',
                height: window.innerHeight / 1.5,
                borderRadius: 10,
                overflow: 'hidden',
                boxShadow: Appearance.boxShadow(),
                backgroundColor: Appearance.colors.primary()
            }}>
                <VelocityComponent
                duration={250}
                animation={{
                    opacity: opacity
                }}>
                    <iframe
                    src={url}
                    title={'preview'}
                    onLoad={() => setOpacity(1)}
                    frameBorder={0}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}/>
                </VelocityComponent>
            </div>

            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const ZoneDetails = ({ aggregate_key, category, dates, resolution, zone }, { index, options, utils }) => {

    const layerID = `zone-details-${zone}`;
    const limit = 5;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager(dates || { end_date: null, start_date: null });
    const [paging, setPaging] = useState(null);
    const [targets, setTargets] = useState([]);

    const onDownloadClick = () => {
        Utils.downloads.content(utils, {
            id: layerID,
            title: 'Reservations',
            dates: manager,
            extendedFeatures: ['date_range'],
            onExport: onDownloadContent
        });
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/resources/', {
                    type: 'zone_details',
                    category: category,
                    aggregate_key: aggregate_key,
                    resolution: resolution,
                    zone: zone,
                    ...formatResults(utils),
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(targets.length === 0) {
            return (
                <div style={{
                    padding: 12
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {Views.entry({
                            title: `No ${Utils.ucFirst(category)} Found`,
                            subTitle: `There are no ${category} available to view`,
                            bottomBorder: false
                        })}
                    </div>
                </div>
            )
        }
        return (
            <div style={{
                padding: 12
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {targets.map((target, index) => {
                        switch(category) {
                            case 'orders':
                            return (
                                Views.entry({
                                    key: target.id,
                                    title: target.customer.full_name,
                                    subTitle: Utils.formatDate(target.drop_off_date),
                                    icon: {
                                        path: target.customer.avatar
                                    },
                                    badge: [{
                                        text: target.status.text,
                                        color: target.status.color
                                    },{
                                        text: target.host.name,
                                        color: Appearance.colors.primary()
                                    }],
                                    bottomBorder: index !== targets.length - 1,
                                    onClick: Utils.orders.details.bind(this, utils, target)
                                })
                            )

                            case 'reservations':
                            return (
                                Views.entry({
                                    key: index,
                                    title: target.customer.full_name,
                                    subTitle: target.destination.address,
                                    supportingTitle: moment(target.pickup_date).format('MMMM Do [at] h:mma'),
                                    badge: {
                                        text: target.status.text,
                                        color: target.status.color
                                    },
                                    icon: {
                                        path: target.customer.avatar
                                    },
                                    reservation: target,
                                    bottomBorder: index !== targets.length - 1,
                                    onClick: Utils.reservations.details.bind(this, utils, target)
                                })
                            )

                            default:
                            return null;
                        }
                    })}
                    {paging && (
                        <PageControl
                        description={paging}
                        limit={limit}
                        offset={manager.offset}
                        onClick={next => {
                            setLoading(true);
                            setManager('offset', next);
                        }} />
                    )}
                </div>
            </div>
        )
    }

    const getSearchField = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div style={{
                width: '100%',
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <TextField
                utils={utils}
                useDelay={true}
                icon={'search'}
                placeholder={'Search by customer or id...'}
                onChange={onSearchTextChange}
                containerStyle={{
                    width: '100%',
                    marginBottom: 8
                }}/>
                <DualDatePickerField
                utils={utils}
                selectedStartDate={manager.start_date}
                selectedEndDate={manager.end_date}
                onStartDateChange={date => setManager('start_date', date)}
                onEndDateChange={date => setManager('end_date', date)} />
            </div>
        )
    }

    const fetchDetails = async () => {
        try {
            let { orders, paging, reservations } = await Request.get(utils, '/resources/', {
                type: 'zone_details',
                limit: limit,
                category: category,
                resolution: resolution,
                zone: zone,
                ...formatResults(utils)
            });

            setLoading(false);
            setPaging(paging);
            switch(category) {
                case 'orders':
                setTargets(orders.map(order => Order.create(order)));
                break;

                case 'reservations':
                setTargets(reservations.map(reservation => Reservation.create(reservation)));
                break;
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this zone. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchDetails();
    }, [manager]);

    return (
        <Layer
        id={layerID}
        title={`Details for Zone "${zone}"`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState,
            removePadding: true
        }}
        buttons={[{
            key: 'download',
            text: `Download ${Utils.ucFirst(category)}`,
            color: 'primary',
            onClick: onDownloadClick
        }]}>
            {getSearchField()}
            {getContent()}
        </Layer>
    )
}
