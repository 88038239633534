import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';

const LottieView = React.forwardRef(({ autoPlay, className, loop, onAnimationFinish, source, style }, ref) => {

    const container = useRef(null);
    const animation = useRef(null);

    const play = (start, end) => {
        if(!isNaN(start) && !isNaN(end)) {
            animation.current.playSegments([start, end], true);
            return;
        }
        animation.current.play();
    }

    const reset = () => {
        animation.current.goToAndStop(0, true);
    }

    const reverse = props => {
        animation.current.setDirection(-1);
        play(props);
    }

    useEffect(() => {
        if(ref && ref.current) {
            ref.current.play = play;
            ref.current.reset = reset;
            ref.current.reverse = reverse;
        }
    }, [ref]);

    useEffect(() => {
        animation.current = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: loop,
            autoplay: autoPlay,
            animationData: source,
            rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
        });
        if(onAnimationFinish) {
            animation.current.addEventListener('complete', onAnimationFinish);
        }
    }, [])

    return (
        <div
        ref={ref}
        className={className}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            ...style
        }}>
            <div ref={container} />
        </div>
    )
})
export default LottieView;
