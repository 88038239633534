import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

import Appearance from 'styles/Appearance.js';
import LottieView from 'views/Lottie.js';
import Utils from 'files/Utils.js';

const Button = ({ color, label, loading, onClick, style, transition, type }) => {

    const [hover, setHover] = useState(false);
    const [buttonAnimations, setButtonAnimations] = useSpring(() => ({
        transform: 'scale(1)'
    }))
    const [iconAnimations, setIconAnimations] = useSpring(() => ({
        left: -250,
        opacity: 0,
        config: { mass: 1, tension: 180, friction: 20 }
    }))
    const [labelAnimations, setLabelAnimations] = useSpring(() => ({
        left: 0,
        opacity: 1,
        config: { mass: 1, tension: 180, friction: 20 }
    }))

    const getStyles = () => {
        let styles = {
            ...style,
            fontSize: 10,
            padding: '3px 8px 3px 8px',
            color: 'white',
          	fontWeight: 700,
            boxShadow: window.theme === 'dark' ? '3px 3px 7px rgba(0,0,0,0.25), -3px -3px 7px rgba(255,255,255,0.10), inset -3px -3px 10px rgba(0,0,0,0.15)' : '3px 3px 7px #c9c8ca,-3px -3px 7px #ffffff, inset -3px -3px 10px rgba(0,0,0,0.1)',
            ...getBackgroundStyles()
        };
        switch(type) {
            case 'transition':
            styles = {
                ...styles,
                fontWeight: 700,
                fontSize: 13,
                padding: '8px 25px 8px 25px',
                width: '100%',
                height: 30
            }
            break;

            case 'large':
            styles = {
                ...styles,
                fontWeight: 700,
                fontSize: 13,
                padding: '6px 12px 6px 12px',
                width: '100%',
                height: 30
            }
            break;

            case 'small':
            styles = {
                ...styles,
                height: 19,
                fontSize: 10,
                padding: '3px 8px 3px 8px',
                width: 'auto'
            }
            break;
        }

        return styles;
    }


    const getBackgroundStyles = () => {
        let color = getColor();
        return {
            border: `2px solid ${color}`,
            background: `linear-gradient(45deg, ${Utils.hexToRGBA(color, 0.85)}, ${Utils.hexToRGBA(color, 0.7)})`,
            backgroundColor: hover ? color : null
        }
    }

    const getColor = () => {
        switch(color) {
            case 'danger':
            return Appearance.colors.red;

            case 'grey':
            return Appearance.colors.darkGrey;

            case 'secondary':
            return Appearance.colors.secondary();

            default:
            return Appearance.colors.primary();
        }
    }

    const getContent = () => {
        if(loading) {
            return (
                <LottieView
                loop={true}
                autoPlay={true}
                source={require('files/lottie/dots-white.json')}
                style={{
                    width: 25,
                    height: 25
                }}/>
            )
        }
        if(transition) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 30,
                    width: '100%',
                    textAlign: 'center',
                    position: 'relative'
                }}>
                    <animated.div style={{
                        position: 'absolute',
                        width: '100%',
                        ...iconAnimations
                    }}>
                        <img
                        src={`/images/${transition.icon}`}
                        style={{
                            width: 25,
                            height: 25,
                            objectFit: 'contain'
                        }} />
                    </animated.div>
                    <animated.div style={{
                        position: 'relative',
                        width: '100%',
                        ...labelAnimations
                    }}>
                        <span>{label}</span>
                    </animated.div>
                </div>
            )
        }
        return (
            <span>{label}</span>
        )
    }

    const onButtonClick = () => {
        onMouseLeave();
        if(typeof(onClick) === 'function') {
            onClick();
        }
    }

    const onMouseEnter = () => {
        setHover(true);
        if(type === 'transition') {
            setButtonAnimations({
                transform: 'scale(1.1)'
            });
            setIconAnimations({
                left: 0,
                opacity: 1
            });
            setLabelAnimations({
                left: 250,
                opacity: 0
            });
        }
    }

    const onMouseLeave = () => {
        setHover(false);
        if(type === 'transition') {
            setButtonAnimations({
                transform: 'scale(1)'
            });
            setIconAnimations({
                left: -250,
                opacity: 0
            });
            setLabelAnimations({
                left: 0,
                opacity: 1
            });
        }
    }

    return (
        <animated.div style={{
            ...type !== 'small' && { width: '100%' },
            ...buttonAnimations
        }}>
            <button
            onClick={onButtonClick}
            className={'btn btn-pill'}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{
                ...getStyles(),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden'
            }}>
                {getContent()}
            </button>
        </animated.div>
    )
}
export default Button;
