import React, { useEffect, useState } from 'react';
import Appearance from 'styles/Appearance.js';

const Checkbox = ({ checked, enabled, onChange, style }) => {

    const [active, setActive] = useState(checked || false);

    useEffect(() => {
        setActive(checked || false);
    }, [checked]);

    useEffect(() => {
        if(typeof(onChange) === 'function') {
            onChange(active);
        }
    }, [active]);

    return (
        <div
        className={enabled ? 'text-button' : null}
        onClick={enabled ? () => setActive(!active) : null}
        style={{
            width: 20,
            height: 20,
            padding: 4,
            borderRadius: 5,
            overflow: 'hidden',
            border: `1px solid ${Appearance.colors.softBorder()}`,
            backgroundColor: Appearance.colors.background(),
            opacity: enabled ? 1 : 0.25,
            ...style
        }}>
            {active
                ?
                <div style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 10,
                    overflow: 'hidden',
                    backgroundColor: Appearance.colors.primary()
                }}/>
                :
                null
            }
        </div>
    )
}
export default Checkbox;
