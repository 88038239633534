import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import { Bar, Line } from 'react-chartjs-2';
import NoDataFound from 'views/NoDataFound.js';
import Panel from 'structure/Panel.js'
import Request from 'files/Request.js';
import Utils, { useLoading } from 'files/Utils.js';
import Views from 'views/Main.js';

// panels
export const ActiveUsers = ({ index, utils }) => {

    const panelID = 'activeUsers';
    const [activeUsers, setActiveUsers] = useState(null);
    const [loading, setLoading] = useState(false);

    const onRemoveSocketListeners = async () => {
        try {
            await utils.sockets.off('admin', 'active_users_change', onUpdateSocketTotals);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onUpdateSocketTotals = ({ admin, drivers, customers }) => {
        setLoading(false);
        setActiveUsers([ admin || 0, drivers || 0, customers || 0 ]);
    }

    const getAssistProps = () => {
        return {
            message: 'These are the users that are currently using the platform for iOS, Android, the Customer Web App, and Seeds. This graph updates in real time as a user joins or leaves the platform'
        }
    }

    const getColor = index => {
        let colors = [ Appearance.colors.primary(), Appearance.colors.secondary(), Appearance.colors.tertiary() ];
        return colors[index];
    }

    const getStepSize = () => {
        if(!activeUsers) {
            return 1;
        }
        // return null and allow the chart library to automatically set step size
        return activeUsers[0] > 10 || activeUsers[1] > 10 || activeUsers[2] > 10 ? null : 1;
    }

    const fetchActiveUsers = async () => {
        try {
            await utils.sockets.emit('admin', 'fetch_active_users');
            utils.sockets.on('admin', 'active_users_change', onUpdateSocketTotals)
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        fetchActiveUsers();
        return onRemoveSocketListeners;
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Activity'}
        className={'col-12 col-xl-6'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            assist: {
                props: getAssistProps()
            }
        }}>
            <div style={{
                height: 225
            }}>
                {!activeUsers
                    ?
                    <NoDataFound message={'Awaiting connection...'} />
                    :
                    <div
                    className={'chart active-users-chart'}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Bar
                        width={500}
                        height={125}
                        data={{
                            labels: [
                                'Administrators',
                                'Drivers',
                                'Customers'
                            ],
                            datasets: [{
                                data: activeUsers,
                                borderWidth: 2,
                                borderColor: ({ dataIndex }) => {
                                    return getColor(dataIndex);
                                },
                                backgroundColor: ({ dataIndex }) => {
                                    let color = getColor(dataIndex);
                                    return Utils.hexToRGBA(color, 0.5);
                                }
                            }]
                        }}
                        options={{
                            title: {
                                display: false
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: item => {
                                        return `${item.yLabel} ${parseInt(item.yLabel) === 1 ? 'user' : 'users'} logged in`
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: value => Utils.numberFormat(value),
                                        stepSize: getStepSize()
                                    }
                                }]
                            }
                        }} />
                    </div>
                }
            </div>
        </Panel>
    )
}

export const EmissionsOverview = ({ index, utils }) => {

    const panelID = 'emissionsOverview';
    const [dates, setDates] = useState({ start: moment().startOf('year'), end: moment() });
    const [emissions, setEmissions] = useState(null);
    const [loading, setLoading] = useLoading();

    const getAssistProps = () => {
        return {
            message: 'These are the emission savings for the current year. These savings include saved gallons of gas, trees planted, and grams of carbon reduced for all of the completed rides. You can adjust the date for the overview by selecting a new start or end date on the right'
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-2 pt-1 pb-2 p-lg-0'}>
                    {getOverviewDates()}
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={dates.start}
                    selectedEndDate={dates.end}
                    onStartDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                start: date
                            }
                        })
                    }}
                    onEndDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                end: date
                            }
                        })
                    }} />
                </div>
            </div>
            <div className={'row p-1 m-0'}>
                {getItems().map(item => (
                    <div
                    key={item.key}
                    className={'col-12 col-sm-6 col-lg-3 p-2'}>
                        <div style={{
                            ...Appearance.styles.unstyledPanel(),
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 185
                        }}>
                            <img
                            src={`/images/${item.image}`}
                            style={{
                                width: 45,
                                height: 45,
                                boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
                                borderRadius: 22.5,
                                backgroundColor: Appearance.colors.primary()
                            }} />
                            <span
                            className={'d-block'}
                            style={{
                                ...Appearance.textStyles.title(),
                                marginTop: 8
                            }}>{item.value || item.placeholder}</span>
                            <span
                            className={'d-block'}
                            style={Appearance.textStyles.subTitle()}>{item.title}</span>
                        </div>
                    </div>
                ))}
            </div>
            </>
        )
    }

    const getItems  = () => {
        return [{
            key: 'trees',
            title: 'Trees Planted',
            placeholder: '0',
            value: emissions ? emissions.trees : null,
            image: 'trees-icon-clear.png'
        },{
            key: 'gas',
            title: 'Gallons of Gas',
            placeholder: '0',
            value: emissions ? emissions.gas : null,
            image: 'gas-icon-clear.png'
        },{
            key: 'carbon',
            title: 'Grams of CO2',
            placeholder: '0',
            value: emissions ? emissions.carbon : null,
            image: 'carbon-icon-clear.png'
        },{
            key: 'distance',
            title: 'Miles Driven',
            placeholder: '0',
            value: emissions ? emissions.distance : null,
            image: 'miles-driven-icon-clear.png'
        }]
    }

    const getOverviewDates = () => {

        let elapsedDays = moment(dates.end).diff(moment(dates.start), 'days');
        let label = `${dates.start.format('MMMM Do')} to ${dates.end.format('MMMM Do')}`;
        if(!dates.start.isSame(dates.end, 'year')) {
            label = `${dates.start.format('MMMM Do, YYYY')} to ${dates.end.format('MMMM Do, YYYY')}`
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <span
                style={Appearance.textStyles.title()}>{label}</span>
                <span
                className={'d-block'}
                style={Appearance.textStyles.subTitle()}>{`${elapsedDays} ${elapsedDays === 1 ? 'day' : 'days'} included in overview`}</span>
            </div>
        )
    }

    const fetchEmissions = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            utils.fetching.set(panelID, true);
            let { carbon, distance, gas, trees } = await Request.get(utils, '/reservations/', {
                type: 'all_emissions_admin',
                start_date: moment(dates.start).utc().unix(),
                end_date: moment(dates.end).utc().unix()
            });

            setLoading(false);
            utils.fetching.set(panelID, false);

            let mil = parseFloat(carbon.values.year) > 1000000;
            setEmissions({
                trees: trees.values.year,
                gas: parseFloat(gas.values.year).toFixed(1),
                carbon: parseFloat(carbon.values.year / (mil ? 1000000 : 1000)).toFixed(mil ? 1:0) + (mil ? 'M' : 'K'),
                distance: parseFloat(distance.toFixed(0)).toLocaleString()
            });

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the emissions overview. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(dates) {
            fetchEmissions();
        }
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Emissions'}
        className={'col-12 col-xl-6'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const PerformanceOverview = ({ index, utils }) => {

    const panelID = 'performanceOverview';
    const [dates, setDates] = useState({ start: moment().startOf('month'), end: moment().endOf('month') });
    const [loading, setLoading] = useLoading();
    const [payments, setPayments] = useState(null);
    const [reservations, setReservations] = useState(null);
    const [users, setUsers] = useState(null);

    const getAssistProps = () => {
        return {
            message: 'This overview shows the total reservations booked, the payment totals, and the new user accounts that have been created between the beginning of the month and today'
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader()
        }
        return (
            <>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-2 pt-1 pb-2 p-lg-0'}>
                    {getOverviewDates()}
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={dates.start}
                    selectedEndDate={dates.end}
                    onStartDateChange={date => {
                        setLoading(true);
                        setDates(dates => {
                            return {
                                ...dates,
                                start: date
                            }
                        });
                    }}
                    onEndDateChange={date => {
                        setLoading(true);
                        setDates(dates => {
                            return {
                                ...dates,
                                end: date
                            }
                        });
                    }} />
                </div>
            </div>
            <div className={'row p-1 m-0'}>
                {getItems().map(item => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-md-4 p-2 m-0 text-center'}>
                            <div
                            className={'px-0 py-2'}
                            style={{
                                ...Appearance.styles.unstyledPanel(),
                                height: 185,
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div
                                className={'pr-2'}
                                style={{
                                    height: 125
                                }}>
                                    {!item.chart || item.chart.data.length < 3
                                        ?
                                        <NoDataFound
                                        title={item.title}
                                        message={'Insufficient Data'} />
                                        :
                                        <Line
                                        width={500}
                                        height={125}
                                        data={{
                                            labels: item.chart.labels,
                                            datasets: [{
                                                borderColor: Appearance.colors.primary(),
                                                fill: false,
                                                pointRadius: 0,
                                                data: item.chart.data
                                            }]
                                        }}
                                        options={{
                                            title: {
                                                display: false
                                            },
                                            legend: {
                                                display: false
                                            },
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            scales: {
                                                xAxes: [{
                                                    gridLines: {
                                                        color: Appearance.colors.transparent,
                                                        display: false
                                                    },
                                                    ticks: {
                                                        display: false
                                                    }
                                                }],
                                                yAxes: [{
                                                    gridLines: {
                                                        color: Appearance.colors.transparent,
                                                        display: false
                                                    },
                                                    ticks: {
                                                        display: false
                                                    }
                                                }]
                                            }
                                        }} />
                                    }
                                </div>
                                {item.chart && item.chart.data.length > 3 && (
                                    <>
                                    <span
                                    className={'d-block'}
                                    style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                    <span
                                    className={'d-block'}
                                    style={Appearance.textStyles.subTitle()}>{item.title}</span>
                                    </>
                                )}
                            </div>
                            <div
                            id={`overview-${item.key}-none-found`}
                            className={'text-center w-100'}
                            style={{
                                display: 'none',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 40
                            }}>
                              <img
                              src={'images/no-data-found.png'}
                              className={'no-data-found'}
                              style={{
                                  height: '100%',
                                  maxWidth: 75,
                                  objectFit: 'contain'
                              }} />
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const getItems = () => {
        return [{
            key: 'reservations',
            title: 'Reservations',
            placeholder: '0',
            value: reservations ? reservations.total : null,
            chart: reservations
        },{
            key: 'payments',
            title: 'Payments',
            placeholder: '$0.00',
            value: payments ? Utils.toCurrency(payments.total) : null,
            chart: payments
        },{
            key: 'users',
            title: 'New User Accounts',
            placeholder: '0',
            value: users ? users.total : null,
            chart: users
        }]
    }

    const getOverviewDates = () => {

        let elapsedDays = moment(dates.end).diff(moment(dates.start), 'days');
        let label = `${dates.start.format('MMMM Do')} to ${dates.end.format('MMMM Do')}`;
        if(!dates.start.isSame(dates.end, 'year')) {
            label = `${dates.start.format('MMMM Do, YYYY')} to ${dates.end.format('MMMM Do, YYYY')}`
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <span
                style={Appearance.textStyles.title()}>{label}</span>
                <span
                className={'d-block'}
                style={Appearance.textStyles.subTitle()}>{`${elapsedDays} ${elapsedDays === 1 ? 'day' : 'days'} included in overview`}</span>
            </div>
        )
    }

    const fetchOverview = () => {
        fetchPayments();
        fetchReservations();
        fetchUsers();
    }

    const fetchPayments = async () => {
        let fetchID = `${panelID}:fetchPayments`;
        try {
            if(utils.fetching.get(fetchID) === true) {
                return;
            }
            utils.fetching.set(fetchID, true);
            let { data } = await Request.get(utils, '/payments/',  {
                end_date: moment(dates.end).utc().unix(),
                limit: 5,
                start_date: moment(dates.start).utc().unix(),
                type: 'monthly_payments_overview'
            });

            utils.fetching.set(fetchID, false);
            setLoading(false);
            setPayments({
                total: data.reduce((total, entry) => total += entry.total, 0),
                labels: data.map(entry => entry.date),
                data: data.map(entry => entry.total)
            });

        } catch(e) {
            setLoading(false);
            utils.fetching.set(fetchID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `here was an issue loading the payments overview. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchReservations = async () => {
        let fetchID = `${panelID}:fetchReservations`;
        try {
            if(utils.fetching.get(fetchID) === true) {
                return;
            }
            utils.fetching.set(fetchID, true);
            let { data } = await Request.get(utils, '/reservations/',  {
                end_date: moment(dates.end).utc().unix(),
                limit: 5,
                start_date: moment(dates.start).utc().unix(),
                type: 'monthly_reservations_overview'
            });

            utils.fetching.set(fetchID, false);
            setLoading(false);
            setReservations({
                total: data.reduce((total, entry) => total += entry.total, 0),
                labels: data.map(entry => entry.date),
                data: data.map(entry => entry.total)
            });

        } catch(e) {
            setLoading(false);
            utils.fetching.set(fetchID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `here was an issue loading the reservations overview. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchUsers = async () => {
        let fetchID = `${panelID}:fetchUsers`;
        try {
            if(utils.fetching.get(fetchID) === true) {
                return;
            }
            utils.fetching.set(fetchID, true);
            let { data } = await Request.get(utils, '/users/',  {
                end_date: moment(dates.end).utc().unix(),
                limit: 5,
                start_date: moment(dates.start).utc().unix(),
                type: 'monthly_users_overview'
            });

            utils.fetching.set(fetchID, false);
            setLoading(false);
            setUsers({
                total: data.reduce((total, entry) => total += entry.total, 0),
                labels: data.map(entry => entry.date),
                data: data.map(entry => entry.total)
            });
        } catch(e) {
            setLoading(false);
            utils.fetching.set(fetchID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the users overview. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchOverview();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Performance'}
        className={'col-12 col-xl-6'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removeOverflow: true,
            removePadding: true,
            assist: {
                props: getAssistProps()
            }
        }}>
            {getContent()}
        </Panel>
    )
}
