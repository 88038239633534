import moment from 'moment-timezone';
class AbstractClass {

    type = null;
    object = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.type = props.type;
        this.object = props.object;
        return this;
    }

    compare = (target, callback) => {

        // compare two astract objects if applicable
        if(typeof(target.getID) === 'function') {
            if(this.getTag() === target.getTag()) {
                if(typeof(callback) === 'function') {
                    callback();
                }
                return this.object;
            }
            return target.object;
        }

        // compare and abstract object and a standard javascript object
        if(this.getID() !== target.id) {
            return target;
        }
        if(typeof(callback) === 'function') {
            callback();
        }
        return this.object;
    }

    getID = () => {
        return this.type === 'users' ? this.object.user_id : (this.object.id || this.object.tmp_id);
    }

    getTag = () => {
        return `${this.type}-${this.getID()}`;
    }

    getTitle = () => {
        switch(this.type) {
            case 'campaigns':
            return this.object.title;

            case 'companies':
            return this.object.name || 'this company';

            case 'communityAccessPoints':
            return this.object.name || 'Community Access Point';

            case 'credits':
            return (this.object.type === 'company' && this.object.company ? this.object.company.name : (this.object.user ? this.object.user.full_name : 'Customer')) + '\'s Credits Card';

            case 'doApps':
            return `${this.object.customer.full_name}'s Driver Application`;

            case 'drive_experiences':
            return `Drive Experience for ${this.object.primary.full_name}`;

            case 'emails':
            return this.object.title;

            case 'feedback':
            return `Feedback from ${this.object.user ? this.object.user.full_name : 'an Anonymous User'}`;

            case 'ichMethod':
            return this.object.summary();

            case 'moods':
            return this.object.title;

            case 'newsCategories':
            return this.object.title;

            case 'newsItems':
            return this.object.title;

            case 'notifications':
            return this.object.title;

            case 'orders':
            return `Order #${this.object.id ? this.object.id : moment().unix()}`;

            case 'orderCategories':
            return this.object.title;

            case 'orderHosts':
            return this.object.name;

            case 'orderOptions':
            return this.object.name;

            case 'orderOptionItems':
            return this.object.title;

            case 'orderOptionItemInteractions':
            return this.object.title;

            case 'payments':
            return `Payment #${this.object.id} for ${this.object.customer ? this.object.customer.full_name : `${this.object.company ? this.object.company.name : 'Unknown Company'}`}`;

            case 'premiumLocations':
            return this.object.name ? `${this.object.name} Premium Location` : 'Premium Location';

            case 'promotions':
            return this.object.title;

            case 'reservations':
            return `Reservation #${this.object.id}`;

            case 'restrictedDateTimes':
            return this.object.title;

            case 'routes':
            return this.object.name ? `"${this.object.name}" Route` : `Route #${this.object.id}`

            case 'routeCategories':
            return this.object.name;

            case 'routeOptions':
            return this.object.name;

            case 'rewards':
            return `${this.object.title} Reward`;

            case 'saas':
            return this.object.name || 'this account';

            case 'services':
            return this.object.name;

            case 'subscriptions':
            return `${this.object.company ? this.object.company.name : this.object.customer.full_name}'s Subscription`;

            case 'subscriptionPlans':
            return this.object.name;

            case 'supportTickets':
            return this.object.title;

            case 'surveys':
            return this.object.title;

            case 'surveyQuestions':
            return this.object.title;

            case 'systemEvents':
            return `System Event #${this.object.id}`;

            case 'treeRequests':
            return `${this.object.customer ? this.object.customer.full_name : this.object.company.name}'s Tree Request`;

            case 'users':
            return this.object.full_name || 'this user';

            case 'vehicles':
            return `${this.object.category.name} (${this.object.vin.substring(this.object.vin.length - 4)})`;

            case 'vehicleCategories':
            return this.object.name;

            case 'voApps':
            return `${this.object.company ? this.object.company.name : this.object.customer.full_name}'s Vehicle Application`;

            default:
            return 'Unknown Object';
        }
    }
}
export default {
    create: props => new AbstractClass().create(props)
};
