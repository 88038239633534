import React, { Component } from 'react';
import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

class SearchableReservationsList extends Component {

    state = {
        text: null,
        results: []
    }

    componentWillReceiveProps(props) {
        this.props = props;
    }

    onChange = text => {

        var results = [];
        let searchText = text.toLowerCase();
        for(var i in this.props.reservations) {

            let reservation = this.props.reservations[i];
            if(parseInt(searchText) !== reservation.id
            && !reservation.origin.address.toLowerCase().includes(searchText)
            && !(reservation.destination.address || '').toLowerCase().includes(searchText)) {
                continue;
            }
            results.push(reservation);
        }
        this.setState({
            text: text,
            results: results
        });
    }

    onClick = (user) => {
        if(this.props.onClick && typeof(this.props.onClick) === 'function') {
            this.props.onClick(user);
        }
    }

    render() {

        return (
            <div key={'searchable-reservations-list'} style={{
                width: '100%'
            }}>
                <div style={{
                    padding: 12,
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                    <TextField
                        placeholder={'Search by ID or pickup/drop off address...'}
                        icon={'search'}
                        onChange={this.onChange}/>
                </div>
                <table style={{
                    tableLayout: 'fixed',
                    width: '100%'
                }}>
                    <tbody>
                        {this.state.text
                            ?
                            this.state.results.length === 0
                                ?
                                Views.entry({
                                    title: 'Nothing to see here',
                                    subTitle: 'No reservations were found from your search',
                                    bottomBorder: false
                                })
                                :
                                this.state.results.map((reservation, index) => {
                                    if(this.props.format) {
                                        return (
                                            Views.entry({
                                                key: index,
                                                ...this.props.format(reservation),
                                                bottomBorder: index !== this.state.results.length - 1,
                                                onClick: this.onClick.bind(this, reservation)
                                            })
                                        )
                                    }
                                    return (
                                        Views.entry({
                                            key: reservation.id,
                                            title: (reservation.destination.address || 'Destination Not Chosen') + ' (' + reservation.id + ')',
                                            subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                                            icon: {
                                                path: reservation.customer.avatar
                                            },
                                            badge: reservation.status,
                                            bottomBorder: index !== this.state.results.length - 1,
                                            onClick: this.onClick.bind(this, reservation)
                                        })
                                    )
                                })
                            :
                            this.props.reservations.length === 0
                                ?
                                Views.entry({
                                    title: 'Nothing to see here',
                                    subTitle: 'No reservations were found',
                                    bottomBorder: false
                                }, this.props.utils)
                                :
                                this.props.reservations.map((reservation, index) => {
                                    if(this.props.format) {
                                        return (
                                            Views.entry({
                                                key: index,
                                                ...this.props.format(reservation),
                                                bottomBorder: index !== this.state.results.length - 1,
                                                onClick: this.onClick.bind(this, reservation)
                                            })
                                        )
                                    }
                                    return (
                                        Views.entry({
                                            key: reservation.id,
                                            title: (reservation.destination.address || 'Destination Not Chosen') + ' (' + reservation.id + ')',
                                            subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                                            icon: {
                                                path: reservation.customer.avatar
                                            },
                                            badge: reservation.status,
                                            bottomBorder: index !== this.props.reservations.length - 1,
                                            onClick: this.onClick.bind(this, reservation)
                                        })
                                    )
                                })
                            }
                    </tbody>
                </table>
            </div>
        )
    }
}
export default SearchableReservationsList;
