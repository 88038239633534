import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';

const TimeRangePicker = props => {

    const [selectedStartTime, setSelectedStartTime] = useState(null);
    const [selectedEndTime, setSelectedEndTime] = useState(null);

    const onFieldClick = field => {
        props.utils.datePicker.show({
            ...props,
            time: true,
            date: field === 'start' ? selectedStartTime : selectedEndTime,
            onDateChange: date => onTimeChange(field, date)
        })
    }

    const onTimeChange = (field, date) => {

        if(field === 'start') {
            if(selectedEndTime && moment(date).unix() > moment(selectedEndTime).unix()) {
                props.utils.alert.show({
                    title: 'Just a Second',
                    message: 'The start time can not be after the end time'
                });
                return;
            }
            setSelectedStartTime(date);
            if(typeof(props.onStartTimeChange) === 'function') {
                props.onStartTimeChange(moment(date).format('HH:mm:ss'));
            }
        }
        if(field === 'end') {
            if(selectedStartTime && moment(date).unix() < moment(selectedStartTime).unix()) {
                props.utils.alert.show({
                    title: 'Just a Second',
                    message: 'The end time can not be before the start time'
                });
                return;
            }
            setSelectedEndTime(date);
            if(typeof(props.onEndTimeChange) === 'function') {
                props.onEndTimeChange(moment(date).format('HH:mm:ss'));
            }
        }
    }

    useEffect(() => {
        setSelectedStartTime(props.selectedStartTime ? moment(props.selectedStartTime, 'HH:mm:ss') : null);
        setSelectedEndTime(props.selectedEndTime ? moment(props.selectedEndTime, 'HH:mm:ss') : null);
    }, [props.selectedStartTime, props.selectedEndTime]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
            ...props.style
        }}>
            <div
            onClick={onFieldClick.bind(this, 'start')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                minWidth: 90,
                height: 35,
                ...props.style
            }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: Appearance.colors.text(),
                    textAlign: 'center',
                    width: '100%'
                }}>{selectedStartTime ? moment(selectedStartTime, 'HH:mm:ss').format('h:mma') : 'Start Time'}</span>
            </div>
            <img src={'images/next-arrow-grey.png'} style={{
                width: 35,
                height: 35,
                minWidth: 35,
                minHeight: 35,
                padding: 10,
                objectFit: 'contain'
            }} />
            <div
            onClick={onFieldClick.bind(this, 'end')}
            className={`dummy-field ${window.theme} cursor-pointer`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                minWidth: 90,
                height: 35,
                ...props.style
            }}>
                <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: Appearance.colors.text(),
                    textAlign: 'center',
                    width: '100%'
                }}>{selectedEndTime ? moment(selectedEndTime, 'HH:mm:ss').format('h:mma') : 'End Time'}</span>
            </div>
        </div>
    )
}
export default TimeRangePicker;
