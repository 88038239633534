import Request from 'files/Request.js';

class AutoApproveClass {

    analyze_vehicles = null;
    buffer = null;
    customer_edits = null;
    enabled = null;
    reservations = null;
    same_day = null;
    services = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.analyze_vehicles = Boolean(props.analyze_vehicles);
        this.customer_edits = props.customer_edits;
        this.enabled = Boolean(props.enabled);
        this.reservations = Boolean(props.reservations);
        this.same_day = Boolean(props.same_day);
        this.services = props.services || [];
        this.buffer = {
            duration: props.buffer ? props.buffer.duration : 0,
            type: props.buffer ? props.buffer.type : 'minutes'
        };
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            analyze_vehicles: this.analyze_vehicles || false,
            buffer: this.buffer,
            customer_edits: this.customer_edits || false,
            enabled: this.enabled || false,
            reservations: this.reservations || false,
            services: this.services,
            same_day: this.same_day || false
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            analyze_vehicles: props.analyze_vehicles !== undefined ? props.analyze_vehicles : this.edits.analyze_vehicles,
            customer_edits: props.customer_edits !== undefined ? props.customer_edits : this.edits.customer_edits,
            enabled: props.enabled !== undefined ? props.enabled : this.edits.enabled,
            reservations: props.reservations !== undefined ? props.reservations : this.edits.reservations,
            services: props.services !== undefined ? props.services : this.edits.services,
            same_day: props.same_day !== undefined ? props.same_day : this.edits.same_day,
            buffer: (props.buffer || this.edits.buffer) && {
                ...this.edits.buffer,
                ...props.buffer
            }
        }
        return this.edits;
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/reservations/', {
                    type: 'update_reservation_auto_approval',
                    ...edits
                });

                this.analyze_vehicles = this.edits.analyze_vehicles;
                this.customer_edits = this.edits.customer_edits;
                this.enabled = this.edits.enabled;
                this.reservations = this.edits.reservations;
                this.services = this.edits.services;
                this.buffer = this.edits.buffer;
                this.same_day = this.edits.same_day;

                utils.content.fetch('autoApprovals');
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    create: props => new AutoApproveClass().create(props)
};
