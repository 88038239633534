import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import Company from 'classes/Company';
import EmailHistoryEvent from 'classes/EmailHistoryEvent.js';
import HistoryEvent from 'classes/HistoryEvent.js';
import Message from 'classes/Message.js';
import Note from 'classes/Note.js';
import PaymentMethod from 'classes/PaymentMethod.js';
import PromoCode from 'classes/PromoCode.js';
import Request from 'files/Request.js';
import Service from 'classes/Service.js';
import Subscription from 'classes/Subscription.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class OrderClass {

    _private = {};

    id = null;
    tmp_id = moment().unix();
    user_id = null;
    other_users = null;
    customer = null;
    company = null;
    service = null;
    host = null;
    origin = null;
    stops = null;
    destination = null;
    options = [];
    drop_off_date = null;
    date_submitted = null;
    options = null;
    status = null;
    data = null;
    requests = null;
    driver_tip = null;
    credits = null;
    subscription = null;
    promo_code = null;
    messages = null;
    emissions = null;
    polyline = null;
    multi_leg_polyline = null;
    cost_line_items = null;
    distance = {
        real: null,
        estimate: null
    };
    duration = {
        real: null,
        estimate: null
    };
    unpaid = 0;
    seeds = {};
    valhalla = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user_id = props.user_id;
        this.other_users = props.other_users ? props.other_users.map(u => User.create(u)) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.channel = props.channel ? new OrderChannelClass().create(props.channel) : null;
        this.host = props.host ? new OrderHostClass().create(props.host) : null;
        this.service = props.service ? Service.create(props.service) : null;
        this.options = props.options;
        this.distance = props.distance;
        this.duration = props.duration;
        this.options = props.options ? props.options.map(o => new OrderOptionClass().create(o)) : [];
        this.drop_off_date = props.drop_off_date ? moment.utc(props.drop_off_date).local() : null;
        this.date_submitted = props.date_submitted ? moment.utc(props.date_submitted).local() : null;
        this.status = formatStatus(props.status);
        this.polyline = props.polyline ? Utils.decodePolyline(props.polyline, 6) : null;
        this.multi_leg_polyline = props.multi_leg_polyline && props.multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
        this.promo_code = props.promo_code ? PromoCode.create(props.promo_code) : null;
        this.subscription = props.subscription ? Subscription.create(props.subscription) : null;
        this.driver_tip = props.driver_tip;
        this.requests = props.requests;
        this.emissions = props.emissions;
        this.cost_line_items = props.cost;
        this.data = props.data;
        this.credits = props.credits;
        this.unpaid = props.unpaid;

        this.origin = {
            name: props.origin.name,
            address: props.origin.address,
            location: {
                latitude: props.origin.lat,
                longitude: props.origin.long
            }
        };
    	this.destination = {
            name: props.destination.name,
            address: props.destination.address,
            location: props.destination.lat && props.destination.long && {
                latitude: props.destination.lat,
                longitude: props.destination.long
            }
        };

        this.stops = props.stops && {
            ...props.stops,
            locations: props.stops.locations ? props.stops.locations.map(stop => {
                return {
                    id: stop.id,
                    name: stop.name,
                    address: stop.address,
                    completed: stop.completed,
                    location: {
                        latitude: stop.lat,
                        longitude: stop.long
                    }
                }
            }) : []
        };

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => n.deleted !== true).map(n => Note.create(n));
        }
        return this;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, isNewTarget, props) : this.update(utils, isNewTarget, props);
    }

    open = () => {
        this.edits = {
            customer: this.customer,
            channel: this.channel,
            other_users: this.other_users,
            company: this.company,
            service: this.service,
            host: this.host,
            origin: this.origin,
            destination: this.destination,
            stops: this.stops,
            options: this.options || [],
            drop_off_date: this.drop_off_date,
            distance: this.distance,
            duration: this.duration,
            promo_code: this.promo_code,
            driver_tip: this.driver_tip,
            credits: this.credits,
            payment_method: this.payment_method,
            requests: this.requests || {}
        }
        return this.edits;
    }

    set = props => {

        // credits and payment method need to be removed if the customer is changed
        if(props.customer) {
            if(this.edits && this.edits.customer.user_id !== props.customer.user_id) {
                props.credits = null;
                props.payment_method = null;
                this.edits.requests.card_id = null;
            }
        }

        this.edits = {
            customer: props.customer || this.edits.customer,
            channel: props.channel || this.edits.channel,
            other_users: props.other_users || this.edits.other_users,
            company: props.company !== undefined ? props.company : this.edits.company,
            service: props.service || this.edits.service,
            host: props.host || this.edits.host,
            origin: props.origin || this.edits.origin,
            destination: props.destination || this.edits.destination,
            options: props.options || this.edits.options,
            drop_off_date: props.drop_off_date || this.edits.drop_off_date,
            distance: props.distance || this.edits.distance,
            duration: props.duration || this.edits.duration,
            promo_code: props.promo_code !== undefined ? props.promo_code : this.edits.promo_code,
            driver_tip: props.driver_tip !== undefined ? props.driver_tip : this.edits.driver_tip,
            credits: props.credits !== undefined ? props.credits : this.edits.credits,
            payment_method: props.payment_method !== undefined ? props.payment_method : this.edits.payment_method,
            requests: props.requests || this.edits.requests || {},
            stops: (props.stops || this.edits.stops) && {
                ...this.edits.stops,
                ...props.stops && {
                    ...props.stops,
                    locations: props.stops.locations.map(stop => ({
                        ...stop,
                        location: stop.location || {
                            latitude: stop.lat,
                            longitude: stop.long
                        }
                    }))
                }
            }
        }

        // attach customers company if applicable
        if(props.customer && props.customer.company) {
            this.edits.company = props.customer.company;
        }

        // set payment method card id in requests if applicable
        if(props.payment_method) {
            this.edits.requests = {
                ...this.edits.requests,
                card_id: props.payment_method.id
            }
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id, multi_leg_polyline, polyline } = await Request.post(utils, '/order/', {
                    type: 'new',
                    user_id: edits.customer.user_id,
                    other_users: edits.other_users,
                    order_channel: edits.channel.id,
                    company: edits.company ? edits.company.id : null,
                    service: edits.service ? edits.service.id : null,
                    host: edits.host ? edits.host.id : null,
                    drop_off_date: moment(edits.drop_off_date).utc().unix(),
                    options: edits.options.map(opt => opt.toJSON()),
                    distance_estimate: edits.distance ? edits.distance.estimate : null,
                    duration_estimate: edits.duration ? edits.duration.estimate : null,
                    promo_code: edits.promo_code ? edits.promo_code.id : null,
                    driver_tip: edits.driver_tip,
                    credits: edits.credits,
                    requests: edits.requests,
                    origin: {
                        name: edits.origin.name,
                        address: edits.origin.address,
                        lat: edits.origin.location.latitude,
                        long: edits.origin.location.longitude
                    },
                    destination: {
                        name: edits.destination.name,
                        address: edits.destination.address,
                        lat: edits.destination.location.latitude,
                        long: edits.destination.location.longitude
                    },
                    stops: edits.stops && edits.stops.locations && {
                        ...edits.stops,
                        locations: edits.stops.locations.map(stop => {
                            return {
                                name: stop.name,
                                address: stop.address,
                                lat: stop.location.latitude,
                                long: stop.location.longitude
                            }
                        })
                    },
                    ...props
                });

                this.id = id;
                this.user_id = edits.customer.user_id;
                this.customer = edits.customer.user_id;
                this.channel = edits.channel;
                this.other_users = edits.other_users;
                this.company = edits.company;
                this.service = edits.service;
                this.host = edits.host;
                this.drop_off_date = edits.drop_off_date;
                this.options = edits.options;
                this.origin = edits.origin;
                this.stops = edits.stops;
                this.destination = edits.destination;
                this.distance = edits.distance;
                this.duration = edits.duration;
                this.promo_code = edits.promo_code;
                this.driver_tip = edits.driver_tip;
                this.credits = edits.credits;
                this.requests = edits.requests;
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));

                utils.content.fetch('orders');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { multi_leg_polyline, polyline } = await Request.post(utils, '/order/', {
                    type: 'update',
                    id: this.id,
                    user_id: edits.customer.user_id,
                    other_users: edits.other_users,
                    order_channel: edits.channel.id,
                    company: edits.company ? edits.company.id : null,
                    service: edits.service ? edits.service.id : null,
                    host: edits.host ? edits.host.id : null,
                    drop_off_date: moment(edits.drop_off_date).utc().unix(),
                    options: edits.options.map(opt => opt.toJSON()),
                    distance_estimate: edits.distance ? edits.distance.estimate : null,
                    duration_estimate: edits.duration ? edits.duration.estimate : null,
                    promo_code: edits.promo_code ? edits.promo_code.id : null,
                    driver_tip: edits.driver_tip,
                    credits: edits.credits,
                    requests: edits.requests,
                    origin: {
                        name: edits.origin.name,
                        address: edits.origin.address,
                        lat: edits.origin.location.latitude,
                        long: edits.origin.location.longitude
                    },
                    destination: {
                        name: edits.destination.name,
                        address: edits.destination.address,
                        lat: edits.destination.location.latitude,
                        long: edits.destination.location.longitude
                    },
                    stops: edits.stops && edits.stops.locations && {
                        ...edits.stops,
                        locations: edits.stops.locations.map(stop => {
                            return {
                                name: stop.name,
                                address: stop.address,
                                lat: stop.location.latitude,
                                long: stop.location.longitude
                            }
                        })
                    },
                    ...props
                });

                this.user_id = edits.customer.user_id;
                this.customer = edits.customer;
                this.channel = edits.channel;
                this.other_users = edits.other_users;
                this.company = edits.company;
                this.service = edits.service;
                this.host = edits.host;
                this.drop_off_date = edits.drop_off_date;
                this.options = edits.options;
                this.origin = edits.origin;
                this.stops = edits.stops;
                this.destination = edits.destination;
                this.distance = edits.distance;
                this.duration = edits.duration;
                this.promo_code = edits.promo_code;
                this.driver_tip = edits.driver_tip;
                this.credits = edits.credits;
                this.requests = edits.requests;
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));

                utils.content.update({
                    type: 'orders',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getCostBreakdown = (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits || this;
                let { calculations, multi_leg_polyline, polyline, route } = await Request.post(utils, '/order/', {
                    type: 'cost',
                    order_id: this.id,
                    company: edits.company ? edits.company.id : null,
                    service: edits.service ? edits.service.id : null,
                    promo_code: edits.promo_code ? edits.promo_code.id : null,
                    driver_tip: edits.driver_tip ? edits.driver_tip.amount : null,
                    credits: edits.credits,
                    driver_tip: edits.driver_tip,
                    options: edits.options ? edits.options.map(opt => opt.toJSON()) : [],
                    origin: edits.origin && edits.origin.location && {
                        lat: edits.origin.location.latitude,
                        long: edits.origin.location.longitude
                    },
                    destination: edits.destination && edits.destination.location && {
                        lat: edits.destination.location.latitude,
                        long: edits.destination.location.longitude
                    },
                    stops: edits.stops && edits.stops.locations && {
                        ...edits.stops,
                        locations: edits.stops.locations.map(stop => {
                            return {
                                lat: stop.location.latitude,
                                long: stop.location.longitude
                            }
                        })
                    },
                    ...props
                });

                if(!calculations || !calculations.transaction_data) {
                    throw new Error('An unknown error occurred');
                }

                // update route estimates if applicable
                if(route) {
                    this.route = route;
                    edits.distance.estimate = route.distance;
                    edits.duration.estimate = route.duration;
                }

                // update polylines if applicable
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
                
                resolve({
                    breakdown: {
                        total: calculations.cost,
                        physicalCost: calculations.cost,
                        service: calculations.cost,
                        credits: calculations.transaction_data.credits || 0,
                        driver_tip: calculations.transaction_data.driver_tip || 0,
                        promo_code: calculations.transaction_data.promo_code || 0,
                        lineItems: calculations.transaction_data.line_items || [],
                        companyDiscount: calculations.transaction_data.company_discount || 0
                    }
                })

            } catch(e) {
                reject(e);
            }
        });
    }

    getCurrentPaymentMethod = () => {
        if(this.customer.payment_methods) {
            let cardID = this.requests ? this.requests.card_id : null;
            for(var i in this.customer.payment_methods) {
                if((cardID && this.customer.payment_methods[i].id === cardID) || (!cardID && this.customer.payment_methods[i].default)) {
                    return this.customer.payment_methods[i];
                }
            }
         }
        return null;
    }

    getEmailHistory = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                 let { history } = await Request.get(utils, '/order/', {
                     type: 'email_history',
                     order_id: this.id
                 });
                 resolve({ history: history.map(evt => EmailHistoryEvent.create(evt)) });
            } catch(e) {
                reject(e);
            }
        });
    }

    getHistoryEvents = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                 let { events } = await Request.get(utils, '/order/', {
                     type: 'history_log',
                     order_id: this.id
                 });
                 resolve({
                     events: events.map(e => {
                         return HistoryEvent.create({ ...e, status: formatStatus(e.status) })
                     })
                 });
            } catch(e) {
                reject(e);
            }
        });
    }

    getLocations = () => {
        let order = this.edits || this;
        let locations = [{
            ...order.origin,
            id: 'origin',
            title: 'Pickup Location',
            subTitle: order.origin.address,
            icon: { type: 'broadcast' }
        }];

        if(order.stops && order.stops.locations && order.stops.locations.length > 0) {
            order.stops.locations.forEach((stop, index) => {
                locations.push({
                    ...stop,
                    title: `Stop #${index + 1}`,
                    subTitle: stop.address,
                    icon: { 
                        color: Appearance.colors.grey(),
                        type: 'broadcast' 
                    },
                })
            });
        }

        if(order.destination) {
            locations.push({
                ...order.destination,
                id: 'destination',
                title: 'Drop-Off Location',
                subTitle: order.destination.address,
                icon: { type: 'broadcast' }
            })
        }
        return locations;
    }

    getMessages = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                 let { messages } = await Request.get(utils, '/messages/', {
                     type: 'orders_all',
                     order_id: this.id
                 });
                 this.messages = messages.map(message => Message.create(message));
                 resolve(this.messages);
            } catch(e) {
                reject(e);
            }
        });
    }

    getOverlays = () => {
        try {
            if(this.polyline) {
                return [{
                    id: 'order-route',
                    coordinates: this.polyline
                }];
            }
            if(this.multi_leg_polyline) {
                return this.multi_leg_polyline.map((shape, index) => ({
                    id: `order-route-${index}`,
                    coordinates: shape
                }))
            }
            return null;

        } catch(e) {
            console.error(e.message);
        }
    }

    getPaymentMethodForPreauthorization = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!this.customer.stripe_customer_id || !this.hasPreauthorization()) {
                    resolve(null);
                    return;
                }

                let { method } = await Request.get(utils, '/payment/', {
                    type: 'payment_method_for_preauthorization',
                    customer_id: this.customer.user_id,
                    stripe_id: this.customer.stripe_customer_id,
                    preauth_id: this.data.preauthorization.id,
                    order_id: this.id
                });

                let target = PaymentMethod.create(method);
                resolve(target);

            } catch(e) {
                reject(e);
            }
        })
    }

    hasPreauthorization = () => {
        return this.data && this.data.preauthorization && !this.data.preauthorization.revoked ? true : false;
    }

    revokePreauthorization = utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/payment/', {
                    type: 'revoke_preauthorization',
                    preauth_id: this.data.preauthorization.id,
                    order_id: this.id
                });
                delete this.data.preauthorization;
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    setPreauthorization = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!this._private.preauthorization || !this._private.preauthorization.amount || (this.hasPreauthorization() && this.data.preauthorization.amount === this._private.preauthorization.amount)) {
                    throw new Error('It looks like the preauthorization amount has not been set or is the same amount that it was previously');
                }
                if(!this._private.preauthorization.cardID) {
                    throw new Error('A payment method needs to be selected to create or update a preauthorization');
                }

                let response = await Request.post(utils, '/payment/', {
                    type: 'set_preauthorization',
                    amount: this._private.preauthorization.amount,
                    card_id: this._private.preauthorization.cardID,
                    customer_id: this.customer.user_id,
                    order_id: this.id,
                    preauth_id: this.hasPreauthorization() ? this.data.preauthorization.id : null
                });

                this._private.preauthorization = undefined;
                this.data = {
                    ...this.data,
                    preauthorization: response
                }
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    translatePaymentMethod = async utils => {
        return new Promise(async (resolve ,reject) => {
            try {
                // declare order target and card_id
                let order = this.edits || this;
                let cardID = order.requests ? order.requests.card_id : null;

                // fetch methods and return matching card
                // fallback to default payment method if applicable
                let { methods } = await order.customer.getPaymentMethods(utils);;
                resolve(cardID ? methods.find(method => method.id === cardID) : methods.find(method => method.default));

            } catch(e) {
                reject(e);
            }
        })
    }
}

class OrderCategoryClass {

    id = null;
    title = null;
    description = null;
    host = null;
    channel = null;
    options = null;
    active = null;
    seeds = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.description = props.description;
        this.host = props.host ? new OrderHostClass().create(props.host) : null;
        this.channel = props.channel ? new OrderChannelClass().create(props.channel) : null;
        this.active = Boolean(props.active);
        this.options = props.options ? props.options.map(o => {
            let option = new OrderOptionClass().create(o);
            option.category = this;
            return option;
        }).sort((a, b) => a.name > b.name) : null

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => n.deleted !== true).map(n => Note.create(n))
        }
        return this;
    }

    getOptions = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/orders/', {
                    type: 'options',
                    category_id: this.id,
                    order_channel: this.channel.id
                });
                resolve(response.options.map(opt => new OrderOptionClass().create(opt)));
            } catch(e) {
                reject(e);
            }
        })
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            description: this.description,
            host: this.host,
            options: this.options || []
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            title: props.title || this.edits.title,
            description: props.description || this.edits.description,
            host: props.host || this.edits.host,
            options: props.options !== undefined ? props.options : this.edits.options
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/orders/', {
                    type: 'new_category',
                    title: edits.title,
                    description: edits.description,
                    order_channel: this.channel.id,
                    host: edits.host ? edits.host.id : null,
                    options: edits.options ? edits.options.map(o => o.id) : null
                });

                this.id = id;
                this.title = edits.title;
                this.description = edits.description;
                this.host = edits.host;
                this.options = edits.options;

                utils.content.fetch('orderCategories');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/orders/', {
                    type: 'update_category',
                    id: this.id,
                    title: edits.title,
                    description: edits.description,
                    order_channel: this.channel.id,
                    host: edits.host ? edits.host.id : null,
                    options: edits.options ? edits.options.map(o => o.id) : null
                });

                if(this.edits.options.length !== this.options.length) {
                    utils.content.fetch('orderCategories');
                }

                this.title = edits.title;
                this.description = edits.description;
                this.host = edits.host;
                this.options = edits.options;

                utils.content.update({
                    type: 'orderCategories',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class OrderChannelClass {

    active = null;
    added_by = null;
    date = null;
    description = null;
    icon = null;
    id = null;
    name = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.active = props.active;
        this.added_by = props.added_by && User.create(props.added_by);
        this.date = props.date && moment.utc(props.date).local();
        this.description = props.description;
        this.icon = props.icon;
        this.id = props.id;
        this.name = props.name;
        return this;
    }
}

class OrderHostClass {

    active = null;
    categories = null;
    channel = null;
    companies = null;
    cover_image = null;
    date_added = null;
    description = null;
    direct_payments = null;
    id = null;
    image = null;
    locations = null;
    name = null;
    options = null;
    parameters = null;
    promotions = null;
    seeds = {};
    service = null;
    service_locations = null;

    constructor() {
        return this;
    }

    create = props => {

        this.active = props.active;
        this.channel = props.channel && new OrderChannelClass().create(props.channel);
        this.companies = props.companies && props.companies.map(company => Company.create(company));
        this.cover_image = props.cover_image;
        this.date_added = props.date_added && moment.utc(props.date_added).local();
        this.description = props.description;
        this.direct_payments = props.direct_payments;
        this.id = props.id;
        this.image = props.image;
        this.locations = props.locations ? props.locations.map(location => ({
            address: location.address,
            id: location.id,
            location: {
                latitude: location.lat,
                longitude: location.long
            },
            name: location.name
        })) : [];
        this.name = props.name;
        this.options = props.options;
        this.parameters = this.formatRemoteParameters(props.parameters);
        this.promotions = props.promotions ? props.promotions.map(p => PromoCode.create(p)) : [];
        this.service = props.service && Service.create(props.service);

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => n.deleted !== true).map(n => Note.create(n))
        }
        return this;
    }

    getCategories = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { categories } = await Request.get(utils, '/orders/', {
                    type: 'categories',
                    host_id: this.id,
                    order_channel: this.channel.id
                });
                this.categories = categories.map(category => new OrderCategoryClass().create(category));
                resolve(this.categories);
            } catch(e) {
                reject(e);
            }
        })
    }

    getBusinessHours = () => {
        return this.parameters && this.parameters.operations && this.parameters.operations.hours;
    }

    formatLocalParameters = parameters => {
        
        // convert business hours to utc times
        if(parameters.operations && parameters.operations.hours) {
            parameters.operations.hours = parameters.operations.hours.map(entry => ({
                ...entry,
                end: moment(entry.end, 'HH:mm:ss').utc().format('HH:mm:ss'),
                start: moment(entry.start, 'HH:mm:ss').utc().format('HH:mm:ss')
            }));
        }
        return parameters;
    }

    formatRemoteParameters = parameters => {
        
        // convert business hours from utc times
        if(parameters && parameters.operations && parameters.operations.hours) {
            parameters.operations.hours = parameters.operations.hours.map(entry => ({
                ...entry,
                end: moment.utc(entry.end, 'HH:mm:ss').local(),
                formatted: `${moment.utc(entry.start, 'HH:mm:ss').local().format('h:mma')} to ${moment.utc(entry.end, 'HH:mm:ss').local().format('h:mma')}`,
                start: moment.utc(entry.start, 'HH:mm:ss').local()
            }));
        }
        return parameters;
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils, props) : this.update(utils, props);
    }

    open = () => {
        this.edits = {
            companies: this.companies || [],
            cover_image: this.cover_image,
            description: this.description,
            direct_payments: this.direct_payments || {},
            image: this.image,
            locations: this.locations || [],
            name: this.name,
            options: this.options,
            parameters: this.parameters,
            promotions: this.promotions || [],
            service: this.service
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props,
            companies: props.companies !== undefined ? props.companies : this.edits.companies,
            cover_image: props.cover_image ? props.cover_image.data : this.edits.cover_image,
            image: props.image ? props.image.data : this.edits.image,
            locations: props.locations !== undefined ? props.locations : this.edits.locations,
            promotions: props.promotions !== undefined ? props.promotions : this.edits.promotions
        }
        if(props.image && props.image.data) {
            this.edits.tmp_image = props.image;
        }
        if(props.cover_image && props.cover_image.data) {
            this.edits.tmp_cover_image = props.cover_image;
        }
        this.edits.parameters = {
            ...this.edits.parameters,
            ...props.parameters,
            operations: {
                ...this.edits.parameters && this.edits.parameters.operations,
                ...props.parameters && props.parameters.operations
            }
        }
        return this.edits;
    }

    submit = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { cover_image, id, image } = await Request.post(utils, '/orders/', {
                    ...this.edits,
                    companies: edits.companies && edits.companies.map(c => c.id),
                    cover_image: edits.tmp_cover_image,
                    image: edits.tmp_image,
                    locations: edits.locations && edits.locations.map(place => ({
                        id: place.id,
                        name: place.name,
                        address: Utils.formatAddress(place),
                        lat: place.location.latitude,
                        long: place.location.longitude
                    })),
                    order_channel: this.channel.id,
                    parameters: this.formatLocalParameters(edits.parameters),
                    promotions: edits.promotions && edits.promotions.map(p => p.id),
                    service: edits.service && edits.service.id,
                    type: 'new_host',
                    ...props
                });

                this.companies = this.edits.companies;
                this.cover_image = cover_image || this.cover_image;
                this.description = this.edits.description;
                this.id = id;
                this.image = image || this.image;
                this.locations = this.edits.locations;
                this.name = this.edits.name;
                this.options = this.edits.options;
                this.parameters = this.formatRemoteParameters(this.edits.parameters);
                this.promotions = this.edits.promotions;
                this.service = this.edits.service;

                utils.content.fetch('orderHosts');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { cover_image, direct_payments, image } = await Request.post(utils, '/orders/', {
                    ...this.edits,
                    companies: this.edits.companies && this.edits.companies.map(c => c.id),
                    cover_image: this.edits.tmp_cover_image,
                    direct_payments: this.edits.direct_payments && {
                        account: this.edits.direct_payments.account,
                        discounts: this.edits.direct_payments.discounts,
                        user_id: this.edits.direct_payments.user && this.edits.direct_payments.user.user_id
                    },
                    id: this.id,
                    image: this.edits.tmp_image,
                    locations: this.edits.locations && this.edits.locations.map(place => ({
                        id: place.id,
                        name: place.name,
                        address: Utils.formatAddress(place),
                        lat: place.location.latitude,
                        long: place.location.longitude
                    })),
                    order_channel: this.channel.id,
                    parameters: this.formatLocalParameters(this.edits.parameters),
                    promotions: this.edits.promotions && this.edits.promotions.map(p => p.id),
                    service: this.edits.service && this.edits.service.id,
                    type: 'update_host',
                    ...props
                });

                this.companies = this.edits.companies;
                this.cover_image = cover_image || this.cover_image;
                this.description = this.edits.description;
                this.direct_payments = direct_payments;
                this.image = image || this.image;
                this.locations = this.edits.locations;
                this.name = this.edits.name;
                this.options = this.edits.options;
                this.parameters = this.formatRemoteParameters(this.edits.parameters);
                this.promotions = this.edits.promotions;
                this.service = this.edits.service;

                utils.content.update({
                    type: 'orderHosts',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class OrderOptionClass {

    id = null;
    copy_id = null;
    name = null;
    description = null;
    channel = null;
    host = null;
    category = null;
    cost = null;
    options = null;
    parameters = null;
    featured = null;
    image = null;
    active = null;
    seeds = {};

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.copy_id = `${props.id}-${moment().unix()}`;
        this.name = props.name;
        this.description = props.description;
        this.host = props.host ? new OrderHostClass().create(props.host) : null;
        this.channel = props.channel ? new OrderChannelClass().create(props.channel) : null;
        this.category = props.category;
        this.cost = props.cost;
        this.image = props.images ? props.images[0] : null;
        this.parameters = props.parameters;
        this.featured = props.featured;
        this.active = props.active;
        this.options = props.options ? props.options.map(o => new OrderOptionItemClass().create(o)) : null;

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => n.deleted !== true).map(n => Note.create(n))
        }
        return this;
    }

    copy = (props = {}) => {
        this.id = props.id;
        this.copy_id = `${props.id}-${moment().unix()}`;
        this.name = props.name;
        this.description = props.description;
        this.host = props.host;
        this.category = props.category;
        this.cost = props.cost;
        this.image = props.image;
        this.parameters = props.parameters;
        this.featured = props.featured;
        this.active = props.active;
        this.options = props.options ? props.options.map(o => new OrderOptionItemClass().copy(o)) : null;
        this.seeds = props.seeds;
        return this;
    }

    toJSON = () => {
        return {
            id: this.id,
            options: this.options && this.options.map(option => ({
                id: option.id,
                ...option.value && {
                    value: option.value
                },
                ...option.options && {
                    options: option.options.filter(o => o.selected).map(o => {
                        return {
                            id: o.id
                        }
                    })
                }
            }))
        }
    }

    getCustomizations = () => {
        if(!this.options) {
            return [];
        }
        return this.options.reduce((array, option) => {
            switch(option.type) {
                case 'list':
                return option.options.reduce((array, opt) => {
                    switch(opt.type) {
                        case 'amount':
                        if(opt.value > 0) {
                            array.push({
                                cost: opt.cost * opt.value,
                                name: opt.title,
                                title: `Quantity: ${opt.value}`,
                            });
                        }
                        return array;

                        case 'checkbox':
                        if(opt.selected) {
                            array.push({
                                cost: opt.cost,
                                name: option.title,
                                title: opt.title
                            });
                        }
                        return array;

                        default:
                        return array;
                    }
                }, array);

                case 'text-area':
                array.push({
                    cost: option.cost,
                    name: option.title,
                    value: option.value
                });
                break;
            }
            return array;
        }, []);
    }

    getCustomizedCost = () => {
        if(!this.options) {
            return this.cost;
        }
        return this.options.reduce((total, option) => {
            switch(option.type) {
                case 'list':
                return option.options.reduce((total, opt) => {
                    let cost = opt.cost ? opt.cost : 0;
                    switch(opt.type) {
                        case 'amount':
                        return total += (opt.value > 0 ? (cost * opt.value) : 0);

                        case 'checkbox':
                        return total += (opt.selected ? cost : 0);

                        default:
                        return total;
                    }
                }, total);

                case 'text-area':
                total += (option.cost || 0);
                break;
            }
            return total;
        }, this.cost);
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            name: this.name,
            description: this.description,
            host: this.host,
            category: this.category,
            cost: this.cost,
            featured: this.featured || false,
            options: this.options,
            parameters: this.parameters,
            image: this.image
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            name: props.name || this.edits.name,
            description: props.description || this.edits.description,
            host: props.host || this.edits.host,
            category: props.category || this.edits.category,
            cost: props.cost || this.edits.cost,
            featured: props.featured !== null && props.featured !== undefined ? props.featured : this.edits.featured,
            options: props.options || this.edits.options,
            parameters: props.parameters || this.edits.parameters,
            image: props.image || this.edits.image
        }
        if(props.image && props.image.data) {
            this.edits.tmp_image = props.image;
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id, image } = await Request.post(utils, '/orders/', {
                    type: 'new_option',
                    name: edits.name,
                    description: edits.description,
                    order_channel: this.channel.id,
                    host: edits.host ? edits.host.id : null,
                    category: edits.category ? edits.category.id : null,
                    cost: edits.cost,
                    featured: edits.featured,
                    image: edits.tmp_image,
                    parameters: edits.parameters,
                    options: edits.options ? edits.options.map(opt => opt.toJSON()) : null
                });

                this.id = id;
                this.name = edits.name;
                this.description = edits.description;
                this.host = edits.host;
                this.category = edits.category;
                this.cost = edits.cost;
                this.featured = edits.featured;
                this.options = edits.options;
                this.parameters = edits.parameters;
                this.image = image;

                utils.content.fetch('orderOptions');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { image } = await Request.post(utils, '/orders/', {
                    type: 'update_option',
                    id: this.id,
                    name: edits.name,
                    description: edits.description,
                    order_channel: this.channel.id,
                    host: edits.host ? edits.host.id : null,
                    category: edits.category ? edits.category.id : null,
                    cost: edits.cost,
                    featured: edits.featured,
                    image: edits.tmp_image,
                    parameters: edits.parameters,
                    options: edits.options ? edits.options.map(opt => opt.toJSON()) : null
                });

                this.name = edits.name;
                this.description = edits.description;
                this.host = edits.host;
                this.category = edits.category;
                this.cost = edits.cost;
                this.featured = edits.featured;
                this.options = edits.options;
                this.parameters = edits.parameters;
                this.image = image || this.image;

                utils.content.update({
                    type: 'orderOptions',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

class OrderOptionItemClass {

    description = null;
    id = null;
    options = null;
    required = false;
    restrictions = null;
    title = null;
    type = null;
    value = null;

    constructor() {
        return this;
    }

    create = props => {
        this.description = props.description;
        this.id = props.id;
        this.options = props.options ? props.options.map(opt => new OrderOptionItemInteractionClass().create(opt)) : null;
        this.required = props.required || false;
        this.restrictions = props.restrictions;
        this.title = props.title;
        this.type = props.type;
        this.value = props.value;
        return this;
    }

    copy = props => {
        this.id = props.id;
        this.title = props.title;
        this.type = props.type;
        this.value = props.value;
        this.description = props.description;
        this.options = props.options;
        this.required = props.required;
        this.restrictions = props.restrictions;
        return this;
    }

    replace = props => {
        this.id = props.id;
        this.title = props.title;
        this.type = props.type;
        this.value = props.value;
        this.description = props.description;
        this.options = props.options;
        this.required = props.required;
        this.restrictions = props.restrictions;
        return this;
    }

    toJSON = () => {
        let option = this.edits || this;
        return {
            id: option.id,
            type: option.type,
            title: option.title,
            value: option.value,
            options: option.options ? option.options.map(opt => opt.toJSON()) : null,
            description: option.description,
            required: option.required,
            restrictions: option.restrictions
        }
    }

    getSummary = () => {
        switch(this.type) {
            case 'text-area':
            return orderOptionItemTypes.find(t => t.key === 'text-area').title;

            case 'list':
            if(this.restrictions && this.restrictions.min && this.restrictions.max) {
                return this.restrictions.min === this.restrictions.max ? `Choose up to ${this.restrictions.max}` : `Choose between ${this.restrictions.min} and ${this.restrictions.max}`
            }
            return this.description;
        }
    }

    open = () => {
        this.edits = {
            id: this.id || moment().unix(),
            title: this.title,
            type: this.type,
            description: this.description,
            options: this.options,
            required: this.required,
            restrictions: this.restrictions
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            id: this.edits.id,
            title: props.title || this.edits.title,
            type: props.type || this.edits.type,
            description: props.description || this.edits.description,
            options: props.options || this.edits.options,
            required: props.required !== null && props.required !== undefined ? props.required : this.edits.required,
            restrictions: props.restrictions || this.edits.restrictions && {
                ...this.edits.restrictions,
                ...props.restrictions
            },
            ...props.type === 'text-area' && {
                options: undefined,
                restrictions: undefined
            }
        }
        return this.edits;
    }

    close = () => {
        this.title = this.edits.title;
        this.type = this.edits.type;
        this.description = this.edits.description;
        this.options = this.edits.options;
        this.required = this.edits.required;
        this.restrictions = this.edits.restrictions;
    }
}

class OrderOptionItemInteractionClass {

    automations = null;
    cost = null;
    id = null;
    title = null;
    type = null;
    value = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.automations = props.automations || [];
        this.cost = props.cost;
        this.id = props.id;
        this.title = props.title;
        this.type = props.type;
        this.value = props.value;
        return this;
    }

    copy = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.type = props.type;
        this.cost = props.cost;
        this.automations = props.automations || [];
        return this;
    }

    replace = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.type = props.type;
        this.cost = props.cost;
        this.automations = props.automations || [];
        return this;
    }

    toJSON = () => {
        let item = this.edits || this;
        return {
            id: item.id,
            type: item.type,
            title: item.title,
            cost: item.cost,
            automations: item.automations
        }
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            id: this.id || moment().unix(),
            title: this.title,
            type: this.type,
            cost: this.cost,
            automations: this.automations
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            id: this.edits.id,
            title: props.title || this.edits.title,
            type: props.type || this.edits.type,
            cost: props.cost || this.edits.cost,
            automations: props.automations || this.edits.automations
        }
        return this.edits;
    }
}

const orderOptionItemTypes = [{
    key: 'text-area',
    title: 'Show a text area to write a response'
},{
    key: 'list',
    title: 'Show a list to select one or more items'
}];

const orderOptionItemSubTypes = [{
    key: 'amount',
    title: 'Show an option to choose a specific amount'
},{
    key: 'checkbox',
    title: 'Show a checkbox to select the item'
}];

const statusCodes = {
    pending: null,
    rejected: 0,
    approved: 1,
    returned: 2,
    cancelled: 3,
    to_pickup: 4,
    arrived_at_host: 5,
    to_destination: 6,
    completed: 7,
    unpaid: 8,
    preauthorized: 9,
    preauthorization_failed: 10,
    preauthorization_revoked: 11,
    admin_updated: 12,
    driver_updated: 13,
    customer_edited: 14,
    charge_processing: 15,
    charge_posted: 16,
    charge_failed: 17,
    charge_issue: 18
}

const formatStatus = (c) => {
    let code = c === null || c === undefined ? null : parseInt(c);
    let status = {
        code: code
    };

    if(!Appearance) {
        return status;
    }

    if(code === statusCodes.rejected) {
        status.text = 'Declined';
        status.realText = 'Rejected';
        status.color = Appearance.colors.red;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance.colors.primary();
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.returned) {
        status.text = 'Returned to Queue';
        status.realText = 'Returned to Queue';
        status.color = Appearance.colors.primary();
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';
        status.image = 'images/status-cancelled.png';

    } else if(code === statusCodes.to_pickup || code === statusCodes.arrived_at_host || code === statusCodes.to_destination) {
        status.text = 'In Progress';
        status.realText = code === statusCodes.to_pickup ? 'In Route To Pickup':(code === statusCodes.arrived_at_host ? 'Arrived at Host' : 'In Route To Destination');
        status.color = Appearance.colors.blue;
        status.image = 'images/status-active.png';

    } else if(code === statusCodes.completed) {
        status.text = 'Completed';
        status.realText = 'Completed';
        status.color = Appearance.colors.darkGrey;
        status.image = 'images/status-completed.png';

    } else if(code === statusCodes.unpaid) {
        status.text = 'Unpaid';
        status.realText = 'Unpaid';
        status.color = Appearance.colors.lightGrey;
        status.image = 'images/status-completed.png';

    } else if(code === statusCodes.preauthorized) {
        status.text = 'Preauthorized';
        status.realText = 'Preauthorized';
        status.color = Appearance.colors.lightGrey;
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.preauthorization_failed) {
        status.text = 'Preauthorization Failed';
        status.realText = 'Preauthorization Failed';
        status.color = Appearance.colors.red;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.preauthorization_revoked) {
        status.text = 'Preauthorization Released';
        status.realText = 'Preauthorization Released';
        status.color = Appearance.colors.red;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.admin_updated) {
        status.text = 'Admin Updated';
        status.realText = 'Admin Updated';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.driver_updated) {
        status.text = 'Driver Updated';
        status.realText = 'Driver Updated';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.customer_edited) {
        status.text = 'Customer Edited';
        status.realText = 'Customer Edited';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.charge_processing) {
        status.text = 'Charge Processing';
        status.realText = 'Charge Processing';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.charge_posted) {
        status.text = 'Charge Posted';
        status.realText = 'Charge Posted';
        status.color = Appearance.colors.darkGrey;
        status.image = 'images/status-completed.png';

    } else if(code === statusCodes.charge_failed) {
        status.text = 'Charge Failed';
        status.realText = 'Charge Failed';
        status.color = Appearance.colors.red;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.charge_issue) {
        status.text = 'Charge Issue';
        status.realText = 'Charge Issue';
        status.color = Appearance.colors.red;
        status.image = 'images/status-rejected.png';

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';
    }

    return status;
}

const fetchOrder = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { order } = await Request.get(utils, '/order/', {
                type: 'details',
                id: id
            });
            let target = new OrderClass().create(order);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchOrderCategory = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { category } = await Request.get(utils, '/orders/', {
                type: 'category_details',
                id: id
            });
            let target = new OrderCategoryClass().create(category);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchOrderHost = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { host } = await Request.get(utils, '/orders/', {
                type: 'host_details',
                id: id
            });
            let target = new OrderHostClass().create(host);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

const fetchOrderOption = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { option } = await Request.get(utils, '/orders/', {
                type: 'option_details',
                id: id
            });
            let target = new OrderOptionClass().create(option);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new OrderClass(),
    create: props => new OrderClass().create(props),
    get: fetchOrder,
    status: statusCodes,
    formatStatus: formatStatus,
    Category: {
        new: () => new OrderCategoryClass(),
        get: fetchOrderCategory,
        create: props => new OrderCategoryClass().create(props)
    },
    Channel: {
        new: () => new OrderChannelClass(),
        create: props => new OrderChannelClass().create(props)
    },
    Host: {
        new: () => new OrderHostClass(),
        get: fetchOrderHost,
        create: props => new OrderHostClass().create(props)
    },
    Option: {
        new: () => new OrderOptionClass(),
        get: fetchOrderOption,
        copy: props => new OrderOptionClass().copy(props),
        create: props => new OrderOptionClass().create(props),
        Item: {
            new: () => new OrderOptionItemClass(),
            create: props => new OrderOptionItemClass().create(props),
            types: orderOptionItemTypes,
            Interaction: {
                new: () => new OrderOptionItemInteractionClass(),
                create: props => new OrderOptionItemInteractionClass().create(props),
                types: orderOptionItemSubTypes
            }
        }
    }
}
