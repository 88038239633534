import React, { useEffect, useState } from 'react';
import Appearance from 'styles/Appearance.js';
import Views from 'views/Main.js';
import Request from 'files/Request.js';
import Utils from 'files/Utils.js';

class RouteManueverClass {

    type = null;
    distance = null;
    duration = null;
    instruction = null;
    voice_alert = null;
    hasToll = false;
    travel = {
        mode: null,
        type: null
    }

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.type = this.getType(props.type);
        this.hasToll = props.toll || false;
        this.duration = props.time;
        this.distance = props.length;
        this.instruction = props.instruction;
        this.voice_alert = props.verbal_transition_alert_instruction;
        this.travel = {
            mode: props.travel_mode,
            type: props.travel_type
        }
        return this;
    }

    getType = type => {
        let key = Object.keys(maneuverTypes).find(key => {
            return maneuverTypes[key] === type;
        });
        return key ? maneuverTypes[key] : null;
    }
}

class RouteClass {

    legs = null;
    polyline = null;
    distance = null;
    duration = null;
    region = null;
    steps = [];

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.duration = props.summary.time;
        this.distance = props.summary.length;
        this.polyline = props.shape ? Utils.decodePolyline(props.shape) : null;
        this.steps = props.maneuvers ? props.maneuvers.map(maneuver => new RouteManueverClass().create(maneuver)) : [];
        this.legs = props.legs ? props.legs.map(leg => ({
            ...leg,
            shape: Utils.decodePolyline(leg.shape),
            maneuvers: leg.maneuvers.map(maneuver => new RouteManueverClass().create(maneuver))
        })) : [];
        return this;
    }
}

const maneuverTypes = {
    kNone: 0,
    kStart: 1,
    kStartRight: 2,
    kStartLeft: 3,
    kDestination: 4,
    kDestinationRight: 5,
    kDestinationLeft: 6,
    kBecomes: 7,
    kContinue: 8,
    kSlightRight: 9,
    kRight: 10,
    kSharpRight: 11,
    kUturnRight: 12,
    kUturnLeft: 13,
    kSharpLeft: 14,
    kLeft: 15,
    kSlightLeft: 16,
    kRampStraight: 17,
    kRampRight: 18,
    kRampLeft: 19,
    kExitRight: 20,
    kExitLeft: 21,
    kStayStraight: 22,
    kStayRight: 23,
    kStayLeft: 24,
    kMerge: 25,
    kRoundaboutEnter: 26,
    kRoundaboutExit: 27,
    kFerryEnter: 28,
    kFerryExit: 29,
    kTransit: 30,
    kTransitTransfer: 31,
    kTransitRemainOn: 32,
    kTransitConnectionStart: 33,
    kTransitConnectionTransfer: 34,
    kTransitConnectionDestination: 35,
    kPostTransitConnectionDestination: 36,
    kMergeRight: 37,
    kMergeLeft: 38
}

const getMultiRoute = async (utils, coordinates) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Request.get(utils, '/valhalla/', {
                type: 'multi_route',
                locations: coordinates.map(c => ({
                    lat: c.location.latitude,
                    lon: c.location.longitude
                }))
            });
            let route = new RouteClass().create({
                ...response,
                maneuvers: response.legs.reduce((array, leg) => array.concat(leg.maneuvers), []),
                polyline: response.legs.reduce((array, leg) => {
                    return array.concat(Utils.decodePolyline(leg.shape));
                }, [])
            });
            resolve(route);
        } catch(e) {
            reject(e);
        }
    })
}

export default {
    Route: {
        new: () => new RouteClass(),
        create: props => new RouteClass().create(props),
        maneuverTypes: maneuverTypes
    },
    getRoute: async (utils, coordinates) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/valhalla/', {
                    type: 'route',
                    locations: coordinates.map(coordinate => ({
                        lat: coordinate.location ? coordinate.location.latitude : coordinate.latitude,
                        lon: coordinate.location ? coordinate.location.longitude: coordinate.longitude
                    }))
                });
                let route = new RouteClass().create(response);
                resolve(route);
            } catch(e) {
                reject(e);
            }
        })
    },
    getMultiRoute: getMultiRoute,
    getOptimizedRoute: async (utils, coordinates) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/valhalla/', {
                    type: 'optimized_route',
                    locations: coordinates.map(c => ({
                        lat: c.location.latitude,
                        lon: c.location.longitude
                    }))
                });
                let route = new RouteClass().create({
                    ...response,
                    shape: response.legs && response.legs.length > 0 ? response.legs[0].shape : null
                });
                resolve(route);
            } catch(e) {
                reject(e);
            }
        })
    }
}

export const DirectionsComponent = ({ reservation, utils }) => {

    const [route, setRoute] = useState(null);

    const onCalculateDirections = async () => {
        try {
            let result = getMultiRoute(utils, reservation.getLocations());
            reservation.route = result;
            setRoute(result);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue calculating the route directions for this Reservation. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getContent = () => {
        if(!route) {
            return Views.loader();
        }
        return route.steps.map((step, index) => {
            return (
                <div
                key={index}
                style={{
                    display: 'block',
                    padding: '8px 12px 8px 12px',
                    borderBottom: index !== route.steps.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                }}>
                    <span style={{
                        ...Appearance.textStyles.standard(),
                        display: 'block'
                    }}>{step.instruction}</span>
                    {step.distance > 0 && (
                        <span style={{
                            ...Appearance.textStyles.supportingText(),
                            display: 'block'
                        }}>{'Approaching in ' + Utils.distanceConversion(step.distance)}</span>
                    )}
                </div>
            )
        })
    }

    useEffect(() => {
        onCalculateDirections();
    }, []);

    return getContent();
}
