import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import ListField from 'views/ListField.js';
import Utils from 'files/Utils.js';

const MultipleListField = ({ items = [], onChange, placeholder, style, utils, value }) => {

    const [selectedItems, setSelectedItems] = useState([]);

    const onItemChange = item => {
        if(!item) {
            return;
        }
        let next = update(selectedItems, { $push: [ item ] });
        setSelectedItems(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const onRemoveItem = index => {
        let next = update(selectedItems, { $splice: [[ index, 1 ]] });
        setSelectedItems(next);
        if(typeof(onChange) === 'function') {
            onChange(next);
        }
    }

    const getItems = () => {
        if(!items || !Array.isArray(items)) {
            return null;
        }
        return items.map((item, index) => {
            return (
                <option
                key={index}
                id={item.id || item.code}
                disabled={item.disabled || false}>{item.title || item.text}</option>
            )
        })
    }

    useEffect(() => {
        setSelectedItems(value || []);
    }, [value]);

    return (
        <div style={{
            width: '100%',
            ...style
        }}>
            <ListField
            items={items}
            placeholder={placeholder || `${Utils.isMobile() ? 'Tap' : 'Click'} to choose an item...`}
            value={placeholder || `${Utils.isMobile() ? 'Tap' : 'Click'} to choose an item...`}
            onChange={onItemChange} />

            {selectedItems.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {selectedItems.map((item, index, items) => {
                        return (
                            <div
                            key={index}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '8px 12px 8px 12px',
                                borderBottom: index !== items.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                            }}>
                                <span style={{
                                    ...Appearance.textStyles.subTitle(),
                                    color: Appearance.colors.text()
                                }}>{item.title}</span>
                                <img
                                className={'text-button'}
                                onClick={onRemoveItem.bind(this, index)}
                                src={'images/red-x-icon.png'}
                                style={{
                                    width: 20,
                                    height: 20
                                }} />
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MultipleListField;
