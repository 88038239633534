import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Company from 'classes/Company.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import { EditDirectPaymentPreferences } from 'managers/Payments.js';
import FieldMapper from 'views/FieldMapper.js';
import Layer, { LayerEmissions, LayerItem, LayerNote } from 'structure/Layer.js';
import { Line } from 'react-chartjs-2';
import MultipleUserLookupField from 'views/MultipleUserLookupField.js';
import NoDataFound from 'views/NoDataFound.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import PaymentMethodManager from 'views/PaymentMethodManager.js';
import Request from 'files/Request.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import User from 'classes/User.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views from 'views/Main.js';

// panels
export const Companies = ({ index, utils }) => {

    const panelID = 'companies';
    const limit = 5;

    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Companies',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/companies/', {
                    type: 'all',
                    ...props,
                    ...manager
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewCompany = () => {
        utils.layer.open({
            id: 'new-company',
            abstract: Abstract.create({
                type: 'companies',
                object: Company.new()
            }),
            Component: AddEditCompany.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all companies in the eCarra platform. They are sorted by their company name. You can view or edit a company by clicking the View More button on the right',
            items: [{
                key: 'download',
                title: 'Download Companies',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(companies.length === 0) {
            return (
                Views.entry({
                    title: 'No Companies Found',
                    subTitle: 'There are no companies available to view',
                    bottomBorder: false
                })
            )
        }
        return companies.map((company, index) => {
            return (
                Views.entry({
                    key: index,
                    title: company.name,
                    subTitle: `Member Since: ${moment(company.member_since).format('MMMM Do, YYYY')}`,
                    icon: {
                        path: company.image
                    },
                    bottomBorder: index !== companies.length - 1,
                    onClick: Utils.companies.details.bind(this, utils, company)
                })
            )
        })
    }

    const fetchAllCompanies = async () => {
        try {
            let { companies, paging } = await Request.get(utils, '/companies/', {
                type: 'all',
                limit: limit,
                ...manager,
            });
            setLoading(false);
            setPaging(paging);
            setCompanies(companies.map(company => Company.create(company)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the companies list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchAllCompanies();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'companies', {
            onFetch: fetchAllCompanies,
            onRemove: abstract => {
                setCompanies(companies => {
                    return companies.filter(company => company.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setCompanies(companies => {
                    return companies.map(company => abstract.compare(company));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Companies'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Company',
                style: 'default',
                onClick: onNewCompany
            }],
            search: {
                placeholder: 'Search by company name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const CompaniesReport = ({ category }, { index, utils }) => {

    const panelID = `companies${Utils.ucFirst(category)}Report`;
    const chartRef = useRef(null);

    const [chart, setChart] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ company_id: null, end_date: moment(), modifier: null, start_date: moment().subtract(6, 'months') })

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: getInfo().title,
                chart: chartRef,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/companies/', {
                    type: getInfo().endpoint,
                    ...formatResults(utils),
                    ...props
                });

                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: `This graph shows the ${category} report for the selected company within the specified date range. The date range defaults to 6 months in the past to today's date`,
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div
            className={'row p-0 m-0'}
            style={{
                padding: 12
            }}>
                <div
                className={'col-12 col-xl-8 p-3'}
                style={{
                    height: 350,
                    position: 'relative',
                    borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
                }}>
                    {!chart || chart.labels.length < 3
                        ?
                        <NoDataFound message={'At least 3 entries of company activity are needed for this graph'}/>
                        :
                        <Line
                        ref={chartRef}
                        data={chart}
                        width={400}
                        height={100}
                        options={{
                            title: {
                                display: false
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (tooltipItem, data) => {
                                        let label = '';
                                        switch(category) {
                                            case 'reservations':
                                            label = (parseInt(tooltipItem.yLabel) === 1 ? 'Reservation' : 'Reservations');
                                            break;

                                            case 'emissions':
                                            switch(manager.modifier) {
                                                case 'carbon':
                                                label = 'Grams of Carbon';
                                                break;

                                                case 'gas':
                                                label = 'Gallons of Gas';
                                                break;

                                                case 'trees':
                                                label = (parseInt(tooltipItem.yLabel) === 1 ? 'Tree Planted' : 'Trees Planted');
                                                break;

                                                default:
                                                return tooltipItem.yLabel;
                                            }
                                            break;

                                            case 'payments':
                                            return parseFloat(tooltipItem.yLabel).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                                        }
                                        return parseInt(tooltipItem.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + label;
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: (value, index, values) => {
                                            let prefix = (category === 'payments') ? '$' : '';
                                            value = parseFloat(value);
                                            if(value >= 1000000) {
                                                return prefix + (value / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'M';
                                            }
                                            if(value < 1000) {
                                                return prefix + value;
                                            }
                                            return prefix + (value / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'K';
                                        }
                                    }
                                }]
                            }
                        }}/>
                    }
                </div>
                <div
                className={'col-12 col-xl-4'}
                style={{
                    padding: '8px 12px 8px 12px',
                    borderLeft: Utils.isMobile() ? null : `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div style={{
                        marginBottom: 8
                    }}>
                        <span style={Appearance.textStyles.title()}>{'Company'}</span>
                        <select
                        className={`mt-1 mb-3 custom-select ${window.theme}`}
                        onChange={evt => {
                            setManager('company_id', Utils.attributeForKey.select(evt, 'id'));
                        }}>
                            <option disabled={true}>{'Choose a Company'}</option>
                            {companies.map((company, index) => {
                                return (
                                    <option key={index} id={company.id}>{company.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div style={{
                        marginBottom: 8
                    }}>
                        <span style={Appearance.textStyles.title()}>{getInfo().options.title}</span>
                        <select
                        className={`mt-1 mb-3 custom-select ${window.theme}`}
                        onChange={(e) => {
                            setManager('modifier', Utils.attributeForKey.select(e, 'id'));
                        }}>
                            {getInfo().options.items.map((option, index) => {
                                return (
                                    <option key={index} id={option.key}>{option.title}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div style={{
                        marginBottom: 8
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            marginBottom: '.25rem'
                        }}>{'Date Range'}</span>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)} />
                    </div>
                </div>
            </div>
        )
    }

    const getInfo = () => {
        switch(category) {
            case 'reservations':
            return {
                endpoint: 'reservations_performance',
                title: 'Reservations',
                filter: 1,
                options: {
                    title: 'Status Type',
                    items: [{
                        key: 1,
                        title: 'Approved'
                    },{
                        key: 0,
                        title: 'Rejected'
                    },{
                        key: 3,
                        title: 'Cancelled'
                    },{
                        key: 4,
                        title: 'Started'
                    },{
                        key: 5,
                        title: 'Picked Up'
                    },{
                        key: 6,
                        title: 'Completed'
                    },{
                        key: 13,
                        title: 'Edited by Customer'
                    }]
                }
            }

            case 'payments':
            return {
                endpoint: 'payments_performance',
                title: 'Payments',
                options: {
                    title: 'Transaction Outcome',
                    items: [{
                        key: 'date',
                        title: 'Completed'
                    },{
                        key: 'refunded',
                        title: 'Refunded'
                    }]
                }
            }

            case 'emissions':
            return {
                endpoint: 'emissions_performance',
                title: 'Emissions',
                options: {
                    title: 'Savings Type',
                    items: [{
                        key: 'carbon',
                        title: 'Carbon'
                    },{
                        key: 'gas',
                        title: 'Gasoline'
                    },{
                        key: 'trees',
                        title: 'Trees Planted'
                    }]
                }
            }

            default:
            return {
                title: 'Report',
                options: {}
            }
        }
    }

    const fetchAllCompanies = async () => {
        try {
            let { companies } = await Request.get(utils, '/companies/',  {
                type: 'list'
            });
            setLoading(false);
            setCompanies(companies.map(company => Company.create(company)));
            setManager('company_id', companies.length > 0 && companies[0].id);
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the companies list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchPerformance = async () => {
        try {
            if(!getInfo().endpoint || !manager.company_id) {
                return;
            }
            if(loading !== 'init') {
                setLoading(true);
            }
            let { data, labels } = await Request.get(utils, '/companies/', {
                type: getInfo().endpoint,
                modifier: getInfo().options.items[0].key,
                ...formatResults(utils)
            });

            setLoading(false);
            setChart({
                labels: labels,
                datasets: [{
                    data: data.map(d => (category === 'emissions' ? d[manager.modifier || getInfo().options.items[0].key] : d.total) || 0),
                    fill: true,
                    pointRadius: 5,
                    pointBorderWidth: 2,
                    pointBackgroundColor: 'white',
                    borderColor: category === 'emissions' ?  Appearance.colors.secondary() : Appearance.colors.primary(),
        			backgroundColor: Utils.hexToRGBA(category === 'emissions' ?  Appearance.colors.secondary() : Appearance.colors.primary(), 0.25),
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${category} report. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupFilter = () => {
        let items = getInfo().options.items;
        setManager('modifier', items[0].key);
    }

    useEffect(() => {
        fetchPerformance();
    }, [manager]);

    useEffect(() => {
        setupFilter();
        fetchAllCompanies();
        utils.content.subscribe(panelID, 'companies', {
            onFetch: fetchPerformance,
            onRemove: abstract => {
                setCompanies(companies => {
                    return companies.filter(company => company.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setCompanies(companies => {
                    return companies.map(company => abstract.compare(company));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={getInfo().title}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditCompany = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-company' : `edit-company-${abstract.getID()}`;
    const [company, setCompany] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onEditDirectPaymentPreferences = () => {
        utils.layer.open({
            id: 'edit_direct_payment_preferences',
            abstract: abstract,
            Component: EditDirectPaymentPreferences.bind(this, {
                account: company.direct_payments && company.direct_payments.account || {},
                discounts: company.direct_payments && company.direct_payments.discounts || {},
                onChange: result => {
                    onUpdateTarget({
                        direct_payments: update(company.direct_payments, {
                            account: {
                                commission: {
                                    $set: result.account.commission
                                },
                                revenue_share: {
                                    $set: result.account.revenue_share
                                }
                            },
                            discounts: {
                                $set: result.discounts
                            }
                        })
                    });
                }
            })
        });
    }

    const onSubmit = async () => {
        try {

            // start loading and set small timeout
            setLoading(true);
            await Utils.sleep(0.5);

            // validate required fields before moving on
            await validateRequiredFields(getFields);

            // verify that referral code and signup code are different
            if(company.referral_code.toLowerCase() === company.key.toLowerCase()) {
                throw new Error('The company signup code and referral code can not be the same.');
            }

            // submit request to server
            await abstract.object.apply(utils, isNewTarget);

            // end loading and show user confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" company has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this company. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setCompany(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!company) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'information',
                title: 'Description',
                description: 'The description for a company is shown to customers and administrators when representing the company in the system.',
                value: company.information,
                component: 'textview',
                onChange: text => onUpdateTarget({ information: text })
            },{
                key: 'discount_rate',
                title: 'Discount Rate',
                description: `A company's discount rate is applied when payments are processed on their behalf. Discounts are an optional feature for companies and are expressed as percentages.`,
                value: company.discount_rate,
                component: 'textfield',
                onChange: text => onUpdateTarget({ discount_rate: parseFloat(text) }),
                props: {format: 'percentage'}
            },{
                key: 'image',
                title: 'Image',
                description: `The image for a company is shown to customers and administrators when representing the company in the system. It is recommended to use the logo for a company as the company's image.`,
                value: company.image,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ image: image })
            },{
                key: 'name',
                title: 'Name',
                description: 'The name for a company is shown to customers and administrators when representing the company in the system.',
                value: company.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'referral_code',
                title: 'Referral Code',
                description: 'The referral code for a company is a public code used by a customer when a ride or order is booked. If enabled, rides and orders processed with this referral code will have a portion of the revenue transfered to this company. Referral codes not case insensitive and can not contain special characteres.',
                value: company.referral_code,
                component: 'textfield',
                onChange: text => onUpdateTarget({ referral_code: text }),
                props: {
                    autoCapitalize: false,
                    autoComplete: false,
                    autoCorrect: false
                }
            },{
                key: 'key',
                title: 'Signup Code',
                description: `The signup code for a company is used by all company employees. This is the code needed to attach a company to a user's account for discounts, analytics, etc. Signup codes not case insensitive and can not contain special characteres.`,
                value: company.key,
                component: 'textfield',
                onChange: text => onUpdateTarget({ key: text })
            }]
        },{
            key: 'contact',
            title: 'Contact',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                description: `We use this address to send invoices, marketing updates, and other important digital communications. An email address is required for every company that has a payment method on file`,
                value: company.email_address,
                component: 'textfield',
                onChange: text => onUpdateTarget({ email_address: text })
            },{
                key: 'address',
                title: 'Physical Address',
                description: `The location for a company can include up to a street address, city, state, and zipcode. Only city, state, and zipcode are required`,
                value: company.address,
                component: 'address_lookup',
                onChange: place => {
                    onUpdateTarget({
                        location: place ? place.location : null,
                        address: place ? Utils.formatAddress(place.address) : null
                    });
                }
            }]
        },{
            key: 'direct_payments',
            visible: isNewTarget === false && utils.user.get().level <= User.level.admin,
            title: 'Direct Payments',
            items: [{
                key: 'user',
                required: false,
                title: 'Account Holder',
                description: `The account holder is tasked with managing the direct payments account. This means the account holder is required to provide a bank account or a debit card, registered in their name, for external account transfers. The account holder must also be registered as a company admin for this company.`,
                value: company.direct_payments && company.direct_payments.user,
                component: 'user_lookup',
                props: {
                    companyID: company.id,
                    levels: [User.level.company_admin]
                },
                onChange: user => {
                    onUpdateTarget({ 
                        direct_payments: update(company.direct_payments || {}, {
                            user: {
                                $set: user
                            }
                        }) 
                    });
                }
            },{
                key: 'enabled',
                required: false,
                visible: company.direct_payments && company.direct_payments.account ? true : false,
                title: 'Enabled',
                description: `Enabling direct payments will allow this company to participate in revenue share payments. This will allow users to receieve revenue share payouts if the company referral code is associated with a ride or order where a revenue share is applicable.`,
                value: company.direct_payments && company.direct_payments.account && company.direct_payments.account.enabled,
                component: 'bool_list',
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(company.direct_payments, {
                            account: {
                                enabled: {
                                    $set: val
                                }
                            }
                        }) 
                    });
                }
            },{
                key: 'revenue_share',
                required: false,
                visible: company.direct_payments && company.direct_payments.account ? true : false,
                title: 'Revenue Share Preferences',
                description: 'Setting revenue share rates will determine if we automatically transfer funds to this company when an eligible delivery or ride has been completed.',
                onEditClick: onEditDirectPaymentPreferences
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setCompany(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'New Company'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setCompany(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const CompanyDetails = ({ abstract, index, options, utils }) => {

    const layerID = `company-details-${abstract.getID()}`;
    const limit = 5;

    const [company, setCompany] = useState(abstract.object);
    const [dropDown, setDropDown] = useState(null);
    const [emissions, setEmissions] = useState(null);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useLoading();
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [note, setNote] = useState(null);

    const onDeleteCompany = () => {
        utils.alert.show({
            title: 'Delete Company',
            message: `Are you sure that you want to delete this company? This can not be undone.`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteCompanyConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteCompanyConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/companies/', {
                type: 'delete',
                id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('companies');
            utils.alert.show({
                title: 'All Done!',
                message: 'This company has been deleted'
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this company. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-company-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCompany.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onManagePaymentMethods = () => {
        setDropDown({
            title: 'Payment Methods',
            message: `The payment methods attached to an account are used for anything payment related across the platform. Every account must have at least one payment method.`,
            content: (
                <PaymentMethodManager
                onMethodsChange={setPaymentMethods}
                utils={utils}
                target={abstract} />
            )
        });
    }

    const onManageUsers = async admin => {
        try {

            // fetch users from server
            setLoading(true);
            let { users } = await company.getUsers(utils);

            // filter users based on type of user list requested
            setLoading(false);
            let targets = users.filter(user => admin ? user.level === User.level.company_admin : true);

            // present drop down with list of users
            setDropDown({
                title: 'Company Users',
                message: `Attaching a user will allow them to take advantage of any discounts or promotions granted to this company. ${admin ? `Administrators also have the ability to view a limited breakdown of the company's operations through Seeds` : ''} Click on a user to view their details or to remove them from the company.`,
                buttons: [{
                    key: 'dismiss',
                    text: 'Dismiss',
                    color: 'grey'
                },{
                    key: 'done',
                    text: 'Done',
                    color: 'primary',
                    onClick: () => {
                        onUpdateUsers({
                            admin: admin,
                            users: targets.map(user => user.user_id)
                        });
                    }
                }],
                content: (
                    <div style={{
                        maxHeight: 344,
                        overflowY: 'scroll',
                        width: '100%'
                    }}>
                        <MultipleUserLookupField
                        utils={utils}
                        users={targets}
                        companyID={company.id}
                        onChange={results => targets = results} />
                    </div>
                )
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the list of users for this company. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'payment',
                title: 'Make a Payment',
                style: 'default'
            },{
                key: 'admin',
                title: `Manage Administrators`,
                style: 'default'
            },{
                key: 'employee',
                title: `Manage Employees`,
                style: 'default'
            },{
                key: 'methods',
                title: `Manage Payment Methods`,
                style: 'default'
            },{
                key: 'delete',
                title: 'Delete Company',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'admin' || key === 'employee') {
                onManageUsers(key === 'admin');
                return;
            }
            if(key === 'payment') {
                Utils.payments.make(utils, abstract);
                return;
            }
            if(key === 'methods') {
                onManagePaymentMethods();
                return;
            }
            if(key === 'delete') {
                onDeleteCompany();
                return;
            }
        });
    }

    const onPaymentMethodClick = async method => {
        try {
            await Utils.getPaymentMethodOptions(utils, abstract, method);
            setPaymentMethods(methods => methods.length > 0 ? [ ...methods] : []);
        } catch(e) {
            console.error(e.message);
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const onUpdateUsers = async props => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { admins, users } = await Request.post(utils, '/companies/', {
                type: 'update_users',
                company_id: abstract.getID(),
                ...props
            });

            setLoading(false);
            abstract.object.total_admins = admins;
            abstract.object.total_users = users;

            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `The users for this company have been updated`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the users for this company. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUserClick = async userID => {
        try {
            setLoading(true);
            let user = await User.get(utils, userID);

            setLoading(false);
            Utils.users.details(utils, user);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this user account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'information',
                title: 'Description',
                value: company.information
            },{
                key: 'id',
                title: 'ID',
                value: company.id
            },{
                key: 'member_since',
                title: 'Member Since',
                value: Utils.formatDate(company.member_since)
            },{
                key: 'name',
                title: 'Name',
                value: company.name
            },{
                key: 'referral_code',
                title: 'Referral Code',
                value: company.referral_code
            },{
                key: 'key',
                title: 'Signup Code',
                value: company.key
            },{
                key: 'active',
                title: 'Status',
                value: company.active ? 'Active' : 'Inactive'
            }]
        },{
            key: 'contact',
            title: 'Contact',
            items: [{
                key: 'email_address',
                title: 'Email Address',
                value: company.email_address
            },{
                key: 'address',
                title: 'Physical Address',
                value: company.address
            }]
        },{
            key: 'payments',
            title: 'Payments',
            items: [{
                key: 'discount_rate',
                title: 'Discount Rate',
                value: company.discount_rate && `${parseFloat(company.discount_rate * 100).toFixed(2)}%` || '0%'
            },{
                key: 'payment_methods',
                title: 'Payment Methods',
                value: getPaymentMethods()
            }]
        }];

        // prevent moving forward with direct payment logic if no direct payments account has been setup
        let { account, discounts, user } = company.direct_payments || {};
        let { balance, enabled, external_accounts, revenue_share } = account || {};
        items.push({
            key: 'direct_payments_account',
            title: 'Direct Payments Preferences',
            visible: utils.user.get().level <= User.level.company_admin ? true : false,
            items: [{
                key: 'user',
                title: 'Account Holder',
                value: user && user.full_name || 'Not Setup',
                onClick: user && onUserClick.bind(this, user.user_id)
            },{
                key: 'external_accounts',
                title: 'Destinations',
                value: external_accounts && external_accounts.length > 0 ? 'Setup' : 'Not Setup'
            },{
                key: 'enabled',
                title: 'Status',
                value: enabled ? 'Enabled' : 'Disabled'
            }]
        });

        // add entry for direct payments account balances
        items.push({
            key: 'direct_payments_account_balances',
            title: 'Direct Payments Account Balance',
            visible: balance && utils.user.get().level <= User.level.company_admin ? true : false,
            items: [{
                key: 'available_balance',
                title: 'Available Balance',
                value: balance && Utils.toCurrency(balance.available),
                visible: balance && isNaN(balance.available) === false ? true : false,
                ...balance && balance.available < 0 && {
                    color: Appearance.colors.red
                }
            },{
                key: 'instant_balance',
                title: 'Instant Balance',
                value: balance && Utils.toCurrency(balance.instant),
                visible: balance && isNaN(balance.instant) === false ? true : false,
                ...balance && balance.instant < 0 && {
                    color: Appearance.colors.red
                }
            },{
                key: 'pending_balance',
                title: 'Pending Balance',
                value: balance && Utils.toCurrency(balance.pending),
                visible: balance && isNaN(balance.pending) === false ? true : false,
                ...balance && balance.pending < 0 && {
                    color: Appearance.colors.red
                }
            }]
        })

         // add direct payments revenue share if applicable
         if(revenue_share) {
            items.push({
                key: 'revenue_share',
                title: 'Referral Revenue Share',
                items: [{
                    key: 'discounts.enabled',
                    title: 'Discounts',
                    value: discounts && discounts.enabled ? 'Enabled' : 'Disabled'
                },{
                    key: 'discounts.amount',
                    title: 'Discount Amount',
                    value: discounts && discounts.amount ? `${discounts.amount * 100}%` : '0%'
                },{
                    key: 'enabled',
                    title: 'Enabled',
                    value: revenue_share.enabled ? 'Yes' : 'No'
                },{
                    key: 'orders',
                    title: 'Delivery Revenue Share',
                    value: getRevenueShareRate('orders')
                },{
                    key: 'reservations',
                    title: 'Ride Revenue Share',
                    value: getRevenueShareRate('reservations')
                },{
                    key: 'discounts.public',
                    title: 'Share Discount with Public',
                    value: discounts && discounts.public ? 'Yes' : 'No'
                }]
            });
        }
        
        return items;
    }

    const getPaymentMethods = () => {
        if(loading === 'init') {
            return Views.loader({ justifyContent: 'flex-end' });
        }
        if(paymentMethods.length === 0) {
            return 'No payment methods found';
        }
        return paymentMethods.map((method, index, methods) => {
            return (
                <div
                key={index}
                className={'text-button'}
                onClick={onPaymentMethodClick.bind(this, method)}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingBottom: index === methods.length - 1 ? 0:4
                }}>
                    <span style={Appearance.textStyles.value()}>{method.summary()}</span>
                    <i
                    className={method.icon()}
                    style={{
                        color: Appearance.colors.text(),
                        fontSize: 20,
                        paddingLeft: 8
                    }} />
                </div>
            )
        })
    }

    const getRevenueShareRate = category => {

        // prevent moving forward if no payments account or revenue shares are setup
        if(!company.direct_payments || !company.direct_payments.account || !company.direct_payments.account.revenue_share) {
            return 'Not Setup';
        }

        // define default revenue share rate key
        let key = company.direct_payments.account.revenue_share[category].default;
        return `${company.direct_payments.account.revenue_share[category][key] ? company.direct_payments.account.revenue_share[category][key] * 100 : 0}%`;
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const setupTargets = async () => {
        try {

            // fetch company payment methods
            setLoading(true);
            let { methods } = await company.getPaymentMethods(utils);
            setPaymentMethods(methods);

            // fetch all time emissions overview
            let emissions = await company.getEmissionsOverview(utils);
            setEmissions(emissions);
            setLoading(false);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue setting up the payment methods, emissions overview, and users for this company. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(company.seeds && company.seeds.notes) {
            setNote(company.seeds.notes.find(note => !note.deleted));
        }
    }, [company]);

    useEffect(() => {
        setupTargets();
        utils.content.subscribe(layerID, 'companies', {
            onUpdate: next => {
                setCompany(next.compare(abstract, () => {
                    fetchSystemEvents();
                    setupTargets();
                }));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 25
            }}>
                {Views.entry({
                    title: company.name,
                    subTitle: company.address || 'Address Not Available',
                    icon: {
                        path: company.image
                    },
                    bottomBorder: false
                })}
            </div>

            {emissions && (
                <LayerEmissions items={[{
                    key: 'trees',
                    title: emissions.trees === 1 ? ' Tree Planted' : ' Trees Planted',
                    placeholder: '0',
                    value: emissions.trees,
                    image: 'trees-icon-clear.png'
                },{
                    key: 'gas',
                    title: 'Gallons of Gas',
                    placeholder: '0',
                    value: Utils.numberFormat(emissions.gas),
                    image: 'gas-icon-clear.png'
                },{
                    key: 'carbon',
                    title: 'Grams of CO2',
                    placeholder: '0',
                    value: Utils.numberFormat(emissions.carbon),
                    image: 'carbon-icon-clear.png'
                }]} />
            )}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

// utils
export const fetchCompanies = async (utils, props) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { companies } = await Request.get(utils, '/companies/',  {
                type: 'list',
                ...props
            });
            resolve({ companies: companies.map(company => Company.create(company)) });

        } catch(e) {
            reject(e);
        }
    })
}
