import moment from 'moment-timezone';
import Appearance from 'styles/Appearance';
import Company from 'classes/Company';
import CreditsCard from 'classes/CreditsCard.js';
import ICHMethod from 'classes/ICHMethod.js';
import Note from 'classes/Note';
import Order from 'classes/Order.js';
import PaymentMethod from 'classes/PaymentMethod.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation';
import Service from 'classes/Service';
import Utils from 'files/Utils.js';

class UserClass {

    user_id = null;
    username = null;
    password = null;
    api_key = null;
    level = null;
    first_name = null;
    last_name = null;
    full_name = null;
    services = [];
    company = null;
    order_host = null;
    gender = null;
    location = null;
    address = null;
    city = null;
    state = null;
    zipcode = null;
    country = null;
    email_address = null;
    phone_number = null;
    member_since = null;
    lastLogin = null;
    location = null;
    avatar = null;
    referral_code = null;

    client_id = null;
    platform_fee = null;
    accounts = [];
    referrals = [];
    version = null;

    availability = [];
    schedule = [];
    driver_breakdown = [];

	stripe_connect_id = null;
	stripe_customer_id = null;
    stripe_customer_data = null;
    stripe_card_holder_id = null;
    stripe_card_holder_data = null;

	payments = [];
    credit_methods = null;
    payment_methods = null;
    ich_methods = null;
    total_spent = null;
	reservations = null;

    seeds = null;

    constructor() {
        return this;
    }

	create = (props = {}) => {
		this.user_id = props.user_id;
		this.username = props.username;
		this.password = props.password;
		this.api_key = props.api_key;
		this.level = props.level;
		this.first_name = props.first_name;
		this.last_name = props.last_name;
		this.full_name = props.full_name || (props.first_name && props.last_name ? `${props.first_name} ${props.last_name}` : 'Name Not Provided');
        this.company = props.company ? Company.create(props.company) : null;
        this.order_host = props.order_host ? Order.Host.create(props.order_host) : null;
        this.services = props.services ? props.services.map(s => Service.create(s)) : [];
		this.gender = props.gender;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.address = props.address;
		this.city = props.city;
		this.state = props.state;
		this.zipcode = props.zipcode;
		this.country = props.country;
		this.email_address = props.email_address;
		this.phone_number = props.phone_number ? Utils.formatPhoneNumber(props.phone_number) : null;
        this.total_spent = props.total_spent;
        this.lastLogin = props.last_login ? moment(props.last_login) : null;
		this.member_since = moment(props.member_since);
		this.avatar = props.avatar;
        this.theme = props.theme_style || 'light';
        this.referral_code = props.referral_code;
        this.referrals = props.referrals ? props.referrals.map(r => {
            return new UserClass().create(r)
        }).sort((a, b) => a.last_name > b.last_name) : [];

        this.client_id = props.app_id || window.client_id || 'ecarra';
        this.platform_fee = props.platform_fee;
        this.admin = (this.level === 1 || this.level === 2 || this.level === 3 || this.level > 4) ? true : false;
        this.ecarra_admin = (this.level <= 2 && window.client_id === 'ecarra') ? true:false;

        this.availability = props.availability;
        this.schedule = props.schedule;
        this.driver_breakdown = props.driver_breakdown || [];

        this.stripe_connect_id = props.stripe_connect_id;
		this.stripe_customer_id = props.stripe_customer_id;
		this.stripe_customer_data = props.stripe_customer_data;
        this.stripe_card_holder_id = props.stripe_card_holder_id;
        this.stripe_card_holder_data = props.stripe_card_holder_data;

        if(props.seeds) {
            this.seeds = {
                ...props.seeds,
                notes: (props.seeds.notes || []).filter(n => {
                    return n.deleted !== true;
                }).map(n => {
                    return Note.create(n)
                })
            };
        }
        return this;
	}

    getVersion = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { version } = await Request.get(utils, '/user/', {
                    type: 'version',
                    user_id: this.user_id
                });

                this.version = version;
                resolve(version);

            } catch(e) {
                reject(e);
            }
        })
    }

    apply = (utils, isNewTarget, props) => {
        return isNewTarget ? this.submit(utils) : this.update(utils, props);
    }

    open = () => {
        this.edits = {
            username: this.username,
            password: this.password,
            first_name: this.first_name,
            last_name: this.last_name,
            full_name: this.full_name,
            level: this.level,
            company: this.company,
            gender: this.gender,
            location: this.location,
            address: this.address,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            country: this.country,
            email_address: this.email_address,
            phone_number: this.phone_number,
            avatar: this.avatar,
            referral_code: this.referral_code,
            direct_payments_account: this.direct_payments_account,
            payment_methods: this.payment_methods
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            username: props.username || this.edits.username,
            password: props.password || this.edits.password,
            first_name: props.first_name || this.edits.first_name,
            last_name: props.last_name || this.edits.last_name,
            level: props.level || this.edits.level,
            company: props.company !== undefined ? props.company : this.edits.company,
            gender: props.gender || this.edits.gender,
            location: props.location || this.edits.location,
            address: props.address !== undefined ? props.address : this.edits.address,
            city: props.city !== undefined ? props.city : this.edits.city,
            state: props.state !== undefined ? props.state : this.edits.state,
            zipcode: props.zipcode !== undefined ? props.zipcode : this.edits.zipcode,
            country: props.country !== undefined ? props.country : this.edits.country,
            email_address: props.email_address || this.edits.email_address,
            phone_number: props.phone_number || this.edits.phone_number,
            referral_code: props.referral_code || this.edits.referral_code,
            avatar: props.avatar ? props.avatar : this.edits.avatar,
            direct_payments_account: props.direct_payments_account || this.edits.direct_payments_account
        }

        // set full name
        this.edits.full_name = this.edits.first_name && this.edits.last_name ? `${this.edits.first_name} ${this.edits.last_name}` : null;

        // add avatar image data if applicable
        if(props.avatar && props.avatar.data) {
            this.edits.tmp_avatar = props.avatar;
        }

        // update level value if applicable
        if(this.edits.company && [ levelCodes.admin, levelCodes.driver ].includes(this.edits.level)) {
            this.edits.level = levelCodes.customer;
        }
        if(!this.edits.company && this.edits.level === levelCodes.company_admin) {
            this.edits.level = levelCodes.customer;
        }

        // added to parent (outside of edits) when changes are made
        if(props.payment_methods) {
            this.payment_methods = props.payment_methods;
            this.edits.payment_methods = props.payment_methods;
        }
        return this.edits;
    }

    close = props => {
        let target = props || this.edits;
        this.username = target.username || this.username
        this.password = target.password || this.password
        this.first_name = target.first_name || this.first_name
        this.last_name = target.last_name || this.last_name
        this.full_name = target.full_name || this.full_name
        this.company = target.company || this.company
        this.gender = target.gender || this.gender
        this.location = target.location || this.location
        this.address = target.address || this.address
        this.city = target.city || this.city
        this.state = target.state || this.state
        this.zipcode = target.zipcode || this.zipcode
        this.country = target.country || this.country
        this.email_address = target.email_address || this.email_address
        this.phone_number = target.phone_number || this.phone_number
        this.avatar = target.avatar || this.avatar
        this.referral_code = target.referral_code || this.referral_code
        this.direct_payments_account = target.direct_payments_account || this.direct_payments_account
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { avatar, user_id } = await Request.post(utils, '/user/', {
                    type: 'new',
                    first_name: edits.first_name,
                    last_name: edits.last_name,
                    company: edits.company ? edits.company.id : null,
                    username: edits.username,
                    level: edits.level,
                    location: edits.location && {
                        lat: edits.location.latitude,
                        long: edits.location.longitude
                    },
                    address: edits.address,
                    city: edits.city,
                    state: edits.state,
                    zipcode: edits.zipcode,
                    email_address: edits.email_address,
                    phone_number: edits.phone_number,
                    referral_code: edits.referral_code,
                    avatar: edits.tmp_avatar
                });

                this.user_id = user_id;
                this.first_name = edits.first_name;
                this.last_name = edits.last_name;
                this.full_name = edits.first_name + ' ' + edits.last_name;
                this.company = edits.company;
                this.username = edits.username;
                this.level = edits.level;
                this.location = edits.location;
                this.address = edits.address;
                this.city = edits.city;
                this.state = edits.state;
                this.zipcode = edits.zipcode;
                this.email_address = edits.email_address;
                this.phone_number = edits.phone_number;
                this.referral_code = edits.referral_code;
                this.avatar = avatar;

                utils.content.fetch('users');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { avatar } = await Request.post(utils, '/user/', {
                    type: 'update',
                    user_id: this.user_id,
                    first_name: edits.first_name,
                    last_name: edits.last_name,
                    company: edits.company ? edits.company.id : null,
                    username: edits.username,
                    level: edits.level,
                    location: edits.location && {
                        lat: edits.location.latitude,
                        long: edits.location.longitude
                    },
                    address: edits.address,
                    city: edits.city,
                    state: edits.state,
                    zipcode: edits.zipcode,
                    email_address: edits.email_address,
                    phone_number: edits.phone_number,
                    referral_code: edits.referral_code,
                    avatar: edits.tmp_avatar,
                    direct_payments_account: edits.direct_payments_account,
                    ...props
                });

                this.first_name = edits.first_name;
                this.last_name = edits.last_name;
                this.full_name = `${edits.first_name} ${edits.last_name}`;
                this.company = edits.company;
                this.username = edits.username;
                this.level = edits.level;
                this.location = edits.location;
                this.address = edits.address;
                this.city = edits.city;
                this.state = edits.state;
                this.zipcode = edits.zipcode;
                this.email_address = edits.email_address;
                this.phone_number = edits.phone_number;
                this.referral_code = edits.referral_code;
                this.avatar = avatar || this.avatar;

                utils.content.update({
                    type: 'users',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    getFormattedAddress = () => {
        let target = this.edits || this;
        return Utils.formatAddress(target);
    }

    getAddressComponents = () => {
        let { address, city, location, state, zipcode } = this.edits || this;
        if(!address && !city && !state && !zipcode) {
            return null;
        }
        return {
            address: address,
            city: city,
            state: state,
            zipcode: zipcode,
            location: location
        }
    }

    levelText = () => {
        if(this.level == 1) {
            return 'System Administrator';
        } else if(this.level == 2) {
            return 'Administrator';
        } else if(this.level > 4) {
            return `${this.company ? this.company.name : 'Company'} Administrator`;
        }

        return this.getFormattedAddress();
    }

    getICHMethods = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(this.ich_methods || !this.stripe_card_holder_id) {
                    this.ich_methods = !this.stripe_card_holder_id ? [] : this.ich_methods;
                    resolve(this.ich_methods);
                    return;
                }
                let { cards } = await Request.post(utils, '/cards/', {
                    type: 'cards_for_holder',
                    user_id: this.user_id,
                    stripe_card_holder_id: this.stripe_card_holder_id
                });
                this.ich_methods = cards.map(c => ICHMethod.create(c));
                resolve(this.ich_methods);

            } catch(e) {
                reject(e);
            }
        })
    }

    getPaymentMethods = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { credits, sources, default_source } = await Request.get(utils, '/user/', {
                    type: 'payment_sources',
                    user_id: this.user_id,
                    stripe_id: this.stripe_customer_id
                })

                this.credit_methods = credits ? credits.map(card => CreditsCard.create(card)) : [];
                this.payment_methods = sources && sources.data ? sources.data.map(method => {
                    return PaymentMethod.create({
                        ...method,
                        default: default_source === method.id
                    });
                }) : [];
                resolve({
                    credits: this.credit_methods,
                    methods: this.payment_methods
                });

            } catch(e) {
                reject(e);
            }
        })
    }

    getEmissions = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/user/', {
                    type: 'emissions',
                    user_id: this.user_id
                });
                this.emissions = response;
                resolve(this.emissions);

            } catch(e) {
                reject(e);
            }
        })
    }

    getOrders = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { orders } = await Request.post(utils, '/orders/', {
                    type: 'all_orders',
                    user_id: this.user_id,
                    ...props
                });
                resolve({ orders: orders.map(o => Order.create(o)) });
            } catch(e) {
                reject(e);
            }
        })
    }

    getReservations = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { reservations } = await Request.post(utils, '/reservations/', {
                    type: 'all',
                    user_id: this.user_id
                });
                resolve({ reservations: reservations.map(reservation => Reservation.create(reservation)) });
            } catch(e) {
                reject(e);
            }
        })
    }
}

export const levelCodes = {
    system_admin: 1000,
    admin: 2000,
    company_admin: 2500,
    driver: 3000,
    customer: 4000
}

export const formatLevel = level => {
    switch(level) {
        case levelCodes.system_admin:
        return {
            code: level,
            text: 'System Administrator',
            color: Appearance.colors.secondary()
        }

        case levelCodes.admin:
        return {
            code: level,
            text: 'Administrator',
            color: Appearance.colors.primary()
        }

        case levelCodes.company_admin:
        return {
            code: level,
            text: 'Company Administrator',
            color: Appearance.colors.tertiary()
        }

        case levelCodes.driver:
        return {
            code: level,
            text: 'Driver',
            color: Appearance.colors.darkGrey
        }

        case levelCodes.customer:
        return {
            code: level,
            text: 'Customer',
            color: Appearance.colors.lightGrey
        }

        default:
        return {
            code: level,
            text: 'Unknown Account Type',
            color: Appearance.colors.lightGrey
        }
    }
}

const fetchUser = async (utils, userID) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { user } = await Request.get(utils, '/user/', {
                type: 'details',
                user_id: userID
            });
            let result = new UserClass().create(user);
            resolve(result);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new UserClass(),
    get: (utils, userID) => fetchUser(utils, userID),
    create: props => new UserClass().create(props),
    level: levelCodes,
    formatLevel: formatLevel
}
