export const app_version = '2024.03.01.15.30';
export const dev_env = false;
export const server = dev_env ? 'http://ecarra.local.192.168.4.158.nip.io' : 'https://ecarra.app';
export const version = 5;

export default {
    app_version: app_version,
    dev_env: dev_env,
    version: version,
    server: server,
    url: `${server}/api/v${version}`,
    sockets: dev_env ? `${server}:4000` : 'https://websockets.ecarra.app',
    stripe: 'pk_live_fY0CKaRY3MA4TgsZ4au9EFgF',
    valhalla: 'b434e2e3b2fda2b2581321d0eEoP1ff3b0',
    vehicle_link: 'b43e234ff3b0da2d0e23250e0e82d8ffda',
    encryption: 'agac69yviYWiscoDWae514iTaeHtycpjc',
    mapbox: 'pk.eyJ1IjoibWlrZWNhcnAiLCJhIjoiY2pvZXF5ZnVjMDFhejNwbW1yaDRoNnpoNSJ9.l0qpJf-6yGDYmTXE0JFfVg',
    mapkit: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjMzVkY1NlJEODIifQ.eyJpYXQiOjE2MTEwOTAzNTcsImV4cCI6MTYyNjg2NzM1NywiaXNzIjoiUlQ0VThGN1FGQiJ9.I9OsJtUc0pLkQ3xQAQA_ZYwSNtlu_7Fn0HjHyEAWU3Zh0uT_i2dNomWkOqkcSv3-GJY0ST7rQOFBOM-eWd0J9w',
    seeds: {
        token: 'a2d0e2321e3b22b194ff3b08b43e58ffd',
        user: 'e2342ba2b21e92ffff3343e588bdb0d01'
    }
}

