import moment from 'moment-timezone';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class CampaignClass {

    id = null;
    title = null;
    message = null;
    data = null;
    created_by = null;
    date = null;
    history = null;
    notes = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.title = props.title;
        this.message = props.message;
        this.data = props.data;
        this.date = props.date ? moment(props.date) : null;
        this.history = props.history;
        this.notes = props.notes;
        this.created_by = props.created_by ? User.create(props.created_by) : null;
        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            title: this.title,
            message: this.message,
            data: this.data || {}
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            title: props.title || this.edits.title,
            message: props.message || this.edits.message,
            data: {
                ...this.edits.data,
                ...props.data
            }
        }
        return this.edits;
    }

    run = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/campaign/', {
                    type: 'run',
                    id: this.id
                });
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    submit = utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id } = await Request.post(utils, '/campaign/', {
                    type: 'new',
                    title: edits.title,
                    message: edits.message,
                    companies: edits.data && edits.data.companies ? edits.data.companies.map(c => c.id) : null,
                    platform: edits.data ? edits.data.platform : null,
                    city: edits.data ? edits.data.city : null,
                    state: edits.data ? edits.data.state : null,
                    zipcode: edits.data ? edits.data.zipcode : null,
                    users: edits.data && edits.data.users ? edits.data.users.map(u => u.user_id) : null
                });

                this.id = id;
                this.title = edits.title;
                this.message = edits.message;
                this.data = edits.data;
                utils.content.fetch('campaigns');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                await Request.post(utils, '/campaign/', {
                    type: 'update',
                    id: this.id,
                    title: edits.title,
                    message: edits.message,
                    companies: edits.data && edits.data.companies ? edits.data.companies.map(c => c.id) : null,
                    platform: edits.data ? edits.data.platform : null,
                    city: edits.data ? edits.data.city : null,
                    state: edits.data ? edits.data.state : null,
                    zipcode: edits.data ? edits.data.zipcode : null,
                    users: edits.data && edits.data.users ? edits.data.users.map(u => u.user_id) : null
                });

                this.title = edits.title;
                this.message = edits.message;
                this.data = edits.data;
                utils.content.update({
                    type: 'campaigns',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

export default {
    new: () => new CampaignClass(),
    create: props => new CampaignClass().create(props)
};
