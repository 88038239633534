import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';

import Abstract from 'classes/Abstract.js';
import AddressLookupField from 'views/AddressLookupField.js';
import AltFieldMapper from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Delaunator from 'delaunator';
import DualDatePickerField from 'views/DualDatePickerField.js';
import FieldMapper from 'views/FieldMapper.js';
import ImagePickerField from 'views/ImagePickerField.js';
import Layer, { LayerItem, LayerNote } from 'structure/Layer.js';
import { Line, Bar } from 'react-chartjs-2';
import { Map } from 'views/MapElements.js';
import NoDataFound from 'views/NoDataFound.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Reward from 'classes/Reward.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextField.js';
import TreeRequest from 'classes/TreeRequest.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views from 'views/Main.js';

// panels
export const DailyEmissions = ({ index, utils }) => {

    const panelID = 'dailyEmissionsBreakdown';
    const chart = useRef(null);

    const [emissions, setEmissions] = useState(null);
    const [filter, setFilter] = useState('carbon');
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ end_date: moment(), start_date: moment().subtract(1, 'months') });

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Weekly Emissions Breakdown',
                chart: chart,
                dates: manager,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/reservations/', {
                    type: 'emissions_date_range',
                    filter: 'week',
                    ...formatResults(utils),
                    ...props
                });
                Utils.handleDownload(response);
                resolve(true);

            } catch(e) {
                reject(e);
            }
        })
    }

    const onFormatLabel = item => {
        let label = '';
        switch(filter) {
            case 'trees':
            label = (parseInt(item.yLabel) === 1 ? 'Tree' : 'Trees') + ' Planted';
            break;

            case 'carbon':
            label = 'Grams of Carbon';
            break;

            case 'gas':
            label = 'Gallons of Gas';
            break;

            default:
            return null;
        }
        return parseInt(item.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + label;
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows the overall emissions breakdown for the specified date range. You can change the emissions type using the menu on the right',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getChart = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div style={{
                position: 'relative',
                height: 350,
                padding: 15
            }}>
                {!emissions || emissions.dataset.data.length < 3
                    ?
                    <NoDataFound message={'At least 3 days worth of emissions data are needed for the graph'}/>
                    :
                    <Line
                    ref={chart}
                    width={500}
                    height={100}
                    data={{
                        labels: emissions.labels,
                        datasets: [emissions.dataset]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: onFormatLabel
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: value => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
        )
    }

    const getFilters = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-0 pt-0 pb-2 p-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    defaultValue={filter}
                    style={{
                        width: Utils.isMobile() ? '100%' : 135
                    }}
                    onChange={(e) => {
                        setFilter(Utils.attributeForKey.select(e, 'id'));
                    }}>
                        <option id={'carbon'}>{'Carbon'}</option>
                        <option id={'gas'}>{'Gasoline'}</option>
                        <option id={'trees'}>{'Trees Planted'}</option>
                    </select>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }} />
                    </div>
                </div>
            </div>
        )
    }

    const fetchEmissions = async () => {
        try {
            if(loading !== 'init') {
                setLoading(true);
            }
            let { results } = await Request.get(utils, '/reservations/', {
                type: 'emissions_date_range',
                filter: 'week',
                ...formatResults(utils)
            });

            setLoading(false);
            setEmissions({
                labels: results.map(d => moment(d.date, 'YYYY-MM-D H').format(results.length > 10 ? 'MM/DD' : 'MMM Do')),
                dataset: {
                    fill: true,
                    label: Utils.ucFirst(filter),
                    borderColor: filter === 'gas' ? '#256D1F' : '#6CBF66',
                    data: results.map(d => d[filter]),
                    backgroundColor: Utils.hexToRGBA(filter === 'gas' ? '#256D1F' : '#6CBF66', 0.25),
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    pointRadius: 5
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the weekly performance breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchEmissions();
    }, [filter, manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Daily Emissions'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getFilters()}
            {getChart()}
        </Panel>
    )
}

export const EmissionRewards = ({ index, utils }) => {

    const panelID = 'emissionRewards';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [rewards, setRewards] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Rewards',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/rewards/',  {
                    type: 'all_admin',
                    ...props,
                    ...manager
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    const onNewReward = () => {
        utils.layer.open({
            id: 'new-reward',
            abstract: Abstract.create({
                type: 'rewards',
                object: Reward.new()
            }),
            Component: AddEditReward.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onRewardClick = reward => {
        utils.layer.open({
            id: `reward-details-${reward.id}`,
            abstract: Abstract.create({
                type: 'rewards',
                object: reward
            }),
            Component: RewardDetails
        })
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all of emission rewards in the system. Rewards are discounts and benefits that customers can receive by redeeming their Emission points.',
            items: [{
                key: 'download',
                title: 'Download Rewards',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(rewards.length === 0) {
            return (
                Views.entry({
                    title: 'No Rewards Found',
                    subTitle: 'There are no emissions rewards in the system',
                    borderBottom: false
                })
            )
        }
        return rewards.map((reward, index) => {
            return (
                Views.entry({
                    key: index,
                    title: reward.title,
                    subTitle: reward.description,
                    badge: {
                        text: reward.active ? null : 'Not Active',
                        color: Appearance.colors.grey()
                    },
                    icon: {
                        path: reward.image,
                        ...Appearance.icons.padded({ backgroundColor: Appearance.colors.primary() })
                    },
                    bottomBorder: index !== rewards.length - 1,
                    onClick: onRewardClick.bind(this, reward)
                })
            )
        });
    }

    const fetchRewards = async () => {
        try {
            let { rewards } = await Request.get(utils, '/rewards/',  {
                type: 'all_admin',
                limit: limit,
                ...manager
            });
            setLoading(false);
            setRewards(rewards.map(reward => Reward.create(reward)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the rewards list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchRewards();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'rewards', {
            onFetch: fetchRewards,
            onUpdate: abstract => {
                setRewards(rewards => {
                    return rewards.map(reward => abstract.compare(reward));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Rewards'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Reward',
                style: 'default',
                onClick: onNewReward
            }],
            search: {
                placeholder: 'Search by name or id...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const MonthlyEmissions = ({ index, utils }) => {

    const panelID = 'monthlyEmissionsBreakdown';
    const chart = useRef(null);

    const [emissions, setEmissions] = useState(null);
    const [filter, setFilter] = useState('carbon');
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ end_date: moment(), start_date: moment().subtract(1, 'years') });

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Monthly Emissions',
                chart: chart,
                dates: manager,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/reservations/', {
                    type: 'emissions_date_range',
                    filter: 'month',
                    ...formatResults(utils),
                    ...props
                });
                Utils.handleDownload(response);
                resolve(true);

            } catch(e) {
                reject(e);
            }
        })
    }

    const onFormatLabel = (tooltipItem, data) => {
        let label = '';
        switch(filter) {
            case 'trees':
            label = (parseInt(tooltipItem.yLabel) === 1 ? 'Tree' : 'Trees') + ' Planted';
            break;

            case 'carbon':
            label = 'Grams of Carbon';
            break;

            case 'gas':
            label = 'Gallons of Gas';
            break;

            default:
            return null;
        }
        return parseInt(tooltipItem.yLabel).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + label;
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows the overall emissions breakdown for the specified date range. You can change the emissions type using the menu on the right',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getChart = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div style={{
                position: 'relative',
                height: 350,
                padding: 15
            }}>
                {!emissions || emissions.labels.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of emissions data are needed for the graph'}/>
                    :
                    <Bar
                    ref={chart}
                    width={500}
                    height={100}
                    data={{
                        labels: emissions.labels,
                        datasets: [{
                            data: emissions.dataset,
                            borderWidth: 2,
                            borderColor: ({ dataIndex }) => {
                                return dataIndex % 2 === 0 ? Appearance.colors.primary() : Appearance.colors.secondary()
                            },
                            backgroundColor: ({ dataIndex }) => {
                                let color = dataIndex % 2 === 0 ? Appearance.colors.primary() : Appearance.colors.secondary();
                                return Utils.hexToRGBA(color, 0.5);
                            }
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: onFormatLabel
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: value => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
        )
    }

    const getFilters = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-0 pt-0 pb-2 p-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    defaultValue={filter}
                    style={{
                        width: Utils.isMobile() ? '100%' : 135
                    }}
                    onChange={(e) => {
                        let id = Utils.attributeForKey.select(e, 'id');
                        setFilter(id);
                    }}>
                        <option id={'carbon'}>{'Carbon'}</option>
                        <option id={'gas'}>{'Gasoline'}</option>
                        <option id={'trees'}>{'Trees Planted'}</option>
                    </select>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={manager.start_date}
                        selectedEndDate={manager.end_date}
                        onStartDateChange={date => setManager('start_date', date)}
                        onEndDateChange={date => setManager('end_date', date)}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }} />
                    </div>
                </div>
            </div>
        )
    }

    const fetchEmissions = async () => {
        try {
            let months = moment(manager.end_date).diff(moment(manager.start_date), 'months') + 1;
            let { results } = await Request.get(utils, '/reservations/',  {
                type: 'emissions_date_range',
                filter: 'month',
                ...formatResults(utils)
            });

            setLoading(false);
            setEmissions({
                labels: [...Array(months)].map((_, index) => moment(manager.start_date).add(index, 'months').format(months > 12 ? 'MM/YYYY' : 'MMMM')),
                dataset: [...Array(months)].map((_, index) => {
                    let entry = results.find(e => e.date === moment(manager.start_date).add(index, 'months').format('YYYY-MM'));
                    return entry ? entry[filter] : 0;
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the weekly performance breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchEmissions();
    }, [filter, manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Monthly Emissions'}
        index={index}
        utils={utils}
        options={{
            removePadding: true,
            loading: loading,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getFilters()}
            {getChart()}
        </Panel>
    )
}

export const SeedPodAirQuality = ({ index, utils }) => {

    const panelID = 'seedPodAirQuality';
    const [collection, setCollection] = useState(null);
    const [collectionFeatureType, setCollectionFeatureType] = useState('2D');
    const [loading, setLoading] = useLoading();
    const [place, setPlace] = useState(null);
    const [readingKey, setReadingKey] = useState('pm25');

    const onChangeCollectionFeatureType = async () => {
        try {
            setLoading(true);
            setCollectionFeatureType(null);
            await Utils.sleep(1);

            let next = collectionFeatureType === '2D' ? '3D' : '2D';
            setCollectionFeatureType(next);
            setLoading(false);

        } catch(e) {
            console.error(e.message);
        }
    }

    const onShowZoneDetails = properties => {
        if(readingKey !== 'length') {
            utils.layer.open({
                id: `aq-zone-details-${properties.zone}`,
                Component: AQZoneDetails.bind(this, {
                    ...properties,
                    reading_key: readingKey
                })
            });
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'pm25',
                title: 'PM2.5 Readings',
                style: 'default'
            },{
                key: 'pm10',
                title: 'PM10 Readings',
                style: 'default'
            }]
        }, key => {
            utils.layer.open({
                id: `aq-zone-details-${properties.zone}`,
                Component: AQZoneDetails.bind(this, {
                    ...properties,
                    reading_key: key
                })
            });
        });
    }

    const getAnnotations = () => {
        if(!place) {
            return null;
        }
        return [{
            id: 'manual_place',
            title: place.name,
            subTitle: place.address,
            location: place.location,
            icon: { 
                color: Appearance.colors.grey(),
                type: 'broadcast'  
            }
        }];
    }

    const getAssistProps = () => {
        return {
            message: `These are all the air quality readings harvested with SeedPod. Each reading is aggregated into a specific zone that represents and polygon on the earth's surface. When in the 3D mode, hold the control key on your keyboard while clicking and dragging to change the camera angle.`
        }
    }

    const getButtons = () => {
        if(Utils.isMobile() === true) {
            return null;
        }
        return [{
            key: 'collection-feature-type',
            title: `Switch to ${collectionFeatureType === '2D' ? '3D' : '2D'}`,
            style: collectionFeatureType === '2D' ? 'secondary' : 'primary',
            onClick: onChangeCollectionFeatureType
        }]
    }

    const getCameraProps = () => {
        let threeDimensional = Utils.isMobile() === false && collectionFeatureType === '3D';
        return {
            terrain: threeDimensional,
            pitch: threeDimensional ? 50 : 0
        }
    }

    const getData = () => {
        if(!collectionFeatureType) {
            return null;
        }
        return {
            id: `seedpod-${collectionFeatureType}`,
            data: collection,
            layer: getLayer(),
            onClick: feature => onShowZoneDetails(feature.properties),
            onHover: feature => {
                try {
                    let { length, pm1, pm25, pm10 } = feature.properties;
                    let values = [{
                        key: 'length',
                        title: 'Readings in Zone',
                        value: Utils.softNumberFormat(length)
                    },{
                        key: 'pm25',
                        title: 'PM2.5',
                        value: `${pm25} μg/m\xB3`
                    },{
                        key: 'pm10',
                        title: 'PM10',
                        value: `${pm10} μg/m\xB3`
                    }]
                    return {
                        component: (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {values.map((entry, index) => {
                                    return (
                                        Views.row({
                                            key: index,
                                            label: entry.title,
                                            value: entry.value,
                                            bottomBorder: index !== values.length - 1
                                        })
                                    )
                                })}
                            </div>
                        )
                    }
                } catch(e) {
                    console.error(e.message);
                }
            }
        }
    }

    const getLayer = () => {
        if(Utils.isMobile() === false && collectionFeatureType === '3D') {
            return {
                id: `seedpod-layer-${collectionFeatureType}`,
                type: 'fill-extrusion',
                paint: {
                    'fill-extrusion-base': 1,
                    'fill-extrusion-height': [ '*', ['get', readingKey], 50 ],
                    'fill-extrusion-color': ['get', `${readingKey}_color`]
                }
            }
        }
        return {
            id: `seedpod-layer-${collectionFeatureType}`,
            type: 'fill',
            paint: {
                'fill-opacity': 0.75,
                'fill-color': ['get', `${readingKey}_color`]
            }
        }
    }

    const getReadingKeySelector = () => {
        let items = [{
            key: 'pm25',
            title: 'PM2.5'
        },{
            key: 'pm10',
            title: 'PM10'
        },{
            key: 'length',
            title: 'Readings Per Zone'
        }];
        let selected = items.find(item => item.key === readingKey);
        return (
            <select
            className={`custom-select ${window.theme}`}
            value={selected ? selected.title : 'PM2.5'}
            onChange={evt => setReadingKey(Utils.attributeForKey.select(evt, 'id'))}>
                {items.map((item, index) => {
                    return (
                        <option key={index} id={item.key}>{item.title}</option>
                    )
                })}
            </select>
        )
    }

    const fetchEntries = async () => {
        try {
            setLoading(true);
            let response = await Request.get(utils, '/seedpod/', {
                type: 'aq_readings',
                collection_feature_type: 'polygon'
            });

            setLoading(false);
            setCollection(response);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the SeedPod air quality readings. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchEntries();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'SeedPod Air Quality'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            buttons: getButtons(),
            assist: {
                props: getAssistProps()
            }
        }}>
            <div
            className={'row m-0'}
            style={{
                width: '100%',
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg p-0'}>
                    <AddressLookupField
                    utils={utils}
                    inline={true}
                    placeholder={'Search by name or address...'}
                    onChange={address => setPlace(address)}
                    containerStyle={{
                        width: '100%'
                    }}/>
                </div>
                <div className={'col-12 col-lg-2 px-0 pb-0 pt-2 py-lg-0 pl-lg-2 pr-lg-0'}>
                    {getReadingKeySelector()}
                </div>
            </div>
            <div style={{
                padding: 12
            }}>
                <Map
                {...getCameraProps()}
                utils={utils}
                center={window.userLocation}
                mapType={'standard'}
                showsZoomControl={true}
                showsScale={'visible'}
                showsCompass={'visible'}
                showsUserLocationControl={true}
                isZoomEnabled={true}
                isRotationEnabled={true}
                isScrollEnabled={true}
                removeOldAnnotations={true}
                annotations={getAnnotations()}
                features={getData()}
                style={{
                    height: 500
                }}/>
            </div>
        </Panel>
    )
}

export const TreePlantingRequests = ({ index, utils }) => {

    const panelID = 'treePlantingRequests';
    const chart = useRef(null);
    const limit = 5;

    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({
        end_date: moment(),
        filter: TreeRequest.status.accepted,
        start_date: moment().subtract(12, 'months')
    });
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [trees, setTrees] = useState([]);

    const statusCodes = [{
        key: TreeRequest.status.accepted,
        title: 'Accepted'
    },{
        key: TreeRequest.status.rejected,
        title: 'Rejected'
    },{
        key: TreeRequest.status.cancelled,
        title: 'Cancelled'
    },{
        key: TreeRequest.status.completed,
        title: 'Completed'
    }];

    const onAssistClick = key => {
        if(key === 'completed') {
            onSetMultipleRequests();
            return;
        }
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Tree Planting Requests',
                chart: chart,
                dates: manager,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            })
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/trees/',  {
                    type: 'all_admin',
                    ...formatResults(utils),
                    ...props
                });

                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    const onFetchMultipleRequests = async ({ start, end }) => {
        try {
            setLoading(true);
            let { trees } = await Request.get(utils, '/trees/', {
                type: 'all_within_range',
                start_date: moment(start).utc().unix(),
                end_date: moment(end).utc().unix()
            });

            setLoading(false);
            if(trees.length === 0) {
                utils.alert.show({
                    title: 'No Requests Found',
                    message: 'There were no uncompleted requests found for the date range that you submitted.'
                });
                return;
            }

            let total = trees.reduce((total, entry) => total += entry.count, 0);
            utils.alert.show({
                title: `${total} ${total === 1 ? 'Tree' : 'Trees'}`,
                message: `There ${trees.length === 1 ? 'is' : 'are'} ${trees.length} ${trees.length === 1 ? 'request' : 'requests'} with a total of ${total} ${total === 1 ? 'tree' : 'trees'} planted. Would you like to mark these ${trees.length} ${trees.length === 1 ? 'request' : 'requests'} as completed?`,
                buttons: [{
                    key: 'confirm',
                    title: 'Yes',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Maybe Layer',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'confirm') {
                        onSetMultipleAsCompleted(start, end);
                        return;
                    }
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the matching requests for the date range. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRequestClick = request => {
        utils.layer.open({
            id: `tree-request-details-${request.id}`,
            abstract: Abstract.create({
                type: 'treeRequests',
                object: request
            }),
            Component: TreeRequestDetails
        })
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            ...manager,
            offset: 0,
            search_text: text
        });
    }

    const onSelectDates = () => {
        utils.datePicker.showDualPicker({
            startDate: manager.start_date,
            endDate: manager.end_date,
            onDateChange: onFetchMultipleRequests
        })
    }

    const onSetMultipleRequests = () => {
        utils.alert.show({
            title: 'Set Requests as Completed',
            message: `If needed, you can mark multiple requests as completed without having to visit each request individually. Select a date range, we'll let you know how many trees need to be planted, and we'll mark those requests as completed.`,
            buttons: [{
                key: 'dates',
                title: 'Select Dates',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Later',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'dates') {
                    onSelectDates();
                    return;
                }
            }
        })
    }

    const onSetMultipleAsCompleted = async (start, end) => {
        try {
            setLoading(true);
            let { count } = await Request.post(utils, '/trees/', {
                type: 'all_within_range',
                process: true,
                start_date: moment(start).utc().unix(),
                end_date: moment(end).utc().unix()
            });

            await Utils.sleep(1);
            setLoading(false);
            utils.content.fetch('treeRequests');
            utils.alert.show({
                title: 'All Done!',
                message: `${count} ${count === 1 ? 'request' : 'requests'} have been marked as completed`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating these requests. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all of the tree planting requests in the system. These requests can be issued as a result of a completed Order, Reservation, Subscription, or submitted by a customer or company who wish to make an additional paid donation.',
            items: [{
                key: 'download',
                title: 'Download Tree Requests',
                style: 'default'
            },{
                key: 'completed',
                title: 'Set Requests as Completed',
                style: 'default'
            }]
        }
    }

    const getChart = () => {
        if(!chartData || chartData.labels.length < 3) {
            return null;
        }
        return (
            <div style={{
                position: 'relative',
                height: 350,
                padding: 15
            }}>
                <Line
                ref={chart}
                width={500}
                height={100}
                data={{
                    labels: chartData.labels,
                    datasets: [chartData.dataset]
                }}
                options={{
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        callbacks: {
                            label: item => {
                                return `${item.yLabel} ${parseInt(item.yLabel) === 1 ? 'tree' : 'trees'}`
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 20
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                color: Appearance.colors.transparent,
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: value => Utils.numberFormat(value)
                            }
                        }]
                    }
                }} />
            </div>
        )
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(trees.length === 0) {
            return (
                Views.entry({
                    title: 'No Tree Planting Requests Found',
                    subTitle: 'There are no tree planting requests in the system',
                    borderBottom: false
                })
            )
        }
        return trees.map((tree, index) => {
            return (
                Views.entry({
                    key: index,
                    title: getTitle(tree),
                    subTitle: getDescription(tree),
                    badge: tree.status,
                    icon: {
                        path: getIcon(tree),
                        style: Appearance.icons.standard()
                    },
                    bottomBorder: index !== trees.length - 1,
                    onClick: onRequestClick.bind(this, tree)
                })
            )
        });
    }

    const getDescription = tree => {
        let target = 'from a paid donation';
        if(tree.order_id) {
            target = `for Order #${tree.order_id}`;
        } else if(tree.reservation_id) {
            target = `for Reservation #${tree.reservation_id}`;
        } else if(tree.subscription_id) {
            target = `for Subscription #${tree.subscription_id}`;
        }
        return `${tree.count} ${tree.count === 1 ? 'tree' : 'trees'} planted ${target}`;
    }

    const getFilters = () => {
        return (
            <div
            className={'row m-0'}
            style={{
                padding: 12,
                width: '100%',
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-0 pt-0 pb-2 p-lg-0'}>
                    <TextField
                    icon={'search'}
                    useDelay={true}
                    placeholder={'Search by customer, company, reservation, order, or request id...'}
                    onChange={onSearchTextChange}
                    containerStyle={{
                        flexGrow: 1
                    }} />
                </div>
                <div className={'col-12 col-lg-2 px-0 pt-0 pb-2 pl-lg-2 pr-lg-0 py-lg-0'}>
                    <select
                    className={`custom-select ${window.theme}`}
                    defaultValue={manager.filter ? statusCodes.find(code => code.key === manager.filter).title : null}
                    onChange={e => {
                        setManager('filter', parseInt(Utils.attributeForKey.select(e, 'id')));
                    }}>
                        {statusCodes.map((code, index) => (
                            <option key={index} id={code.key}>{code.title}</option>
                        ))}
                    </select>
                </div>
                <div className={'col-12 col-lg-4 p-0 pl-lg-2 pr-lg-0 py-lg-0'}>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={manager.start_date}
                    selectedEndDate={manager.end_date}
                    onStartDateChange={date => setManager('start_date', date)}
                    onEndDateChange={date => setManager('end_date', date)}/>
                </div>
            </div>
        )
    }

    const getIcon = tree => {
        if(tree.customer) {
            return tree.customer.avatar;
        }
        if(tree.company) {
            return tree.company.image;
        }
        return null;
    }

    const getTitle = tree => {
        if(tree.customer) {
            return tree.customer.full_name;
        }
        if(tree.company) {
            return tree.company.id;
        }
        return 'Tree Planting Request';
    }

    const fetchTrees = async () => {
        try {
            let { paging, trees } = await Request.get(utils, '/trees/',  {
                type: 'all_admin',
                limit: limit,
                ...formatResults(utils)
            });

            setLoading(false);
            setPaging(paging);
            setTrees(trees.map(tree => TreeRequest.create(tree)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the trees list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchTreesGraph = async () => {
        try {
            let { trees } = await Request.get(utils, '/trees/',  {
                type: 'all_admin',
                graph: true,
                limit: limit,
                ...formatResults(utils)
            });

            let status = TreeRequest.formatStatus(manager.filter);
            let color = status ? status.color : Appearance.colors.primary();
            setLoading(false);
            setChartData({
                labels: trees.map(t => moment(t.date, 'YYYY-MM').format('MM/YYYY')),
                dataset: {
                    fill: true,
                    label: 'Tree Planting Requests',
                    borderColor: color,
                    data: trees.map(t => t.total),
                    backgroundColor: Utils.hexToRGBA(color, 0.25),
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    pointRadius: 5
                }
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the trees graph. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchTrees();
        fetchTreesGraph();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'treeRequests', {
            onFetch: () => {
                fetchTrees();
                fetchTreesGraph();
            },
            onUpdate: abstract => {
                setTrees(trees => {
                    return trees.map(tree => abstract.compare(tree));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Tree Planting Requests'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getChart()}
            {getFilters()}
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditReward = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-reward' : `edit-reward-${abstract.getID()}`
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reward, setReward] = useState(null);

    const onSubmit = async () => {

        if(loading === true) {
            return;
        }
        let required = [{
            title: 'title',
            object: reward.title
        },{
            title: 'description',
            object: reward.description
        },{
            title: 'amount',
            object: reward.amount
        },{
            title: 'cost',
            object: reward.cost
        },{
            title: 'image',
            object: reward.image || reward.tmpImage
        }]

        let remaining = required.filter(entry => !entry.object)
        if(remaining.length > 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please fill out the "${remaining[0].title}" before moving on`
            })
            return;
        }
        try {
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${reward.title}" reward has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this reward. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setReward(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!reward) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for a reward is shown to a customer when redeeming their points. The title should be a short phrase describing what the reward offers.',
                value: reward.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'description',
                title: 'Description',
                description: 'The description for a reward is shown to a customer when redeeming their points. The description should be a sentence or phrase describing what the reward offers.',
                value: reward.description,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            },{
                key: 'cost',
                title: 'Emission Points Cost',
                description: `The cost for a reward defines the amount of emission points required to gain the reward's discounts and benefits`,
                value: reward.cost,
                component: 'number_stepper',
                onChange: text => onUpdateTarget({ cost: text })
            },{
                key: 'amount',
                title: 'Amount of Credits',
                description: `The amount for a reward defines the total credits gifted to the customer upon reward redemption. It is recommended that a "1 credit equals 1 cent" conversion or something similar is used to determine the amount of credits to be gifted.`,
                value: reward.amount,
                component: 'number_stepper',
                onChange: text => onUpdateTarget({ amount: text })
            },{
                key: 'image',
                title: 'Image',
                description: 'The image for a reward is shown to customers during the redemption process. The image should be a white square icon, with a transparent background, that illustrates the purpose of the reward. It is recommened that all reward images follow the same design language.',
                value: reward.image,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ image: image })
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setReward(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Reward' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setReward(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AQZoneDetails = ({ reading_key, resolution, zone }, { index, options, utils }) => {

    const layerID = `aq-zone-details-${zone}`;
    const limit = 10;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [readings, setReadings] = useState([]);

    const onDownloadClick = () => {
        Utils.downloads.content(utils, {
            id: layerID,
            title: 'Air Quality Readings',
            onExport: onDownloadContent
        });
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/seedpod/', {
                    type: 'zone_details',
                    reading_key: reading_key,
                    resolution: resolution,
                    zone: zone,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(readings.length === 0) {
            return (
                <div style={{
                    padding: 12
                }}>
                    <div style={{
                        ...Appearance.styles.unstyledPanel()
                    }}>
                        {Views.entry({
                            title: `No Readings Found`,
                            subTitle: `There are no readings available to view`,
                            bottomBorder: false
                        })}
                    </div>
                </div>
            )
        }
        return (
            <div style={{
                padding: 12
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {readings.map((reading, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: Utils.formatDate(reading.date),
                                subTitle: `${reading.lat} x ${reading.long}`,
                                hideIcon: true,
                                badge: {
                                    text: `${reading.value} μg/m\xB3`,
                                    color: reading.color,
                                    lowercase: true
                                },
                                bottomBorder: index !== readings.length - 1
                            })
                        )
                    })}
                    {paging && (
                        <PageControl
                        description={paging}
                        limit={limit}
                        offset={manager.offset}
                        onClick={next => {
                            setLoading(true);
                            setManager('offset', next);
                        }} />
                    )}
                </div>
            </div>
        )
    }

    const fetchDetails = async () => {
        try {
            let { paging, readings } = await Request.get(utils, '/seedpod/', {
                type: 'zone_details',
                limit: limit,
                reading_key: reading_key,
                resolution: resolution,
                zone: zone,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setReadings(readings);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the information for this zone. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchDetails();
    }, [manager]);

    return (
        <Layer
        id={layerID}
        title={`Details for Zone "${zone}"`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState,
            removePadding: true
        }}
        buttons={[{
            key: 'download',
            text: `Download Air Quality Readings`,
            color: 'primary',
            onClick: onDownloadClick
        }]}>
            {getContent()}
        </Layer>
    )
}

export const RewardDetails = ({ abstract, index, options, utils }) => {

    const layerID = `reward-details-${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [reward, setReward] = useState(abstract.object);

    const onEditReward = () => {
        utils.layer.open({
            id: `edit-reward-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditReward.bind(this, {
                isNewTarget: false
            })
        })
    }

    const onSetStatus = () => {
        utils.alert.show({
            title: `${reward.active ? 'Deactivate' : 'Activate'} Reward`,
            message: `Are you sure that you want to ${reward.active ? 'deactivate' : 'activate'} this reward? ${reward.active ? 'Deactivating this reward will prevent customers and companies from taking advantage of these discounts and benefits' : 'Activating this award will allow customers and companies to immediately take advantage of these discounts and benefits'}.`,
            buttons: [{
                key: 'confirm',
                title: reward.active ? 'Deactivate' : 'Activate',
                style: reward.active ? 'destructive' : 'default',
            },{
                key: 'cancel',
                title: reward.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: reward.active ? 'default' : 'destructive',
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetStatusConfirm = async code => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !reward.active;
            await Request.post(utils, '/reward/', {
                type: 'set_status',
                id: reward.id,
                active: next
            });

            abstract.object.active = next
            utils.content.update(abstract);
            setReward(abstract.object);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This reward has been ${next? 'activated and will become immediately available for redemption' : 'deactivated and will no longer be available for redemption'}.`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this reward. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: reward.id
            },{
                key: 'active',
                title: 'Status',
                value: reward.active ? 'Active' : 'Not Active'
            },{
                key: 'title',
                title: 'Title',
                value: reward.title
            },{
                key: 'description',
                title: 'Description',
                value: reward.description
            }]
        },{
            key: 'cost_and_benefits',
            title: 'Cost and Benefits',
            items: [{
                key: 'cost',
                title: 'Cost',
                value: `${reward.cost} ${reward.cost === 1 ? 'point' : 'points'}`
            },{
                key: 'amount',
                title: 'Redemption Amount',
                value: `${reward.amount} ${reward.amount === 1 ? 'credit' : 'credits'}`
            }]
        }];
    }

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading
        }}
        buttons={[{
            key: 'status',
            text: reward.active ? 'Deactivate' : 'Activate',
            color: reward.active ? 'danger' : 'primary',
            onClick: onSetStatus
        },{
            key: 'edit',
            text: 'Edit',
            color: reward.active ? 'primary' : 'secondary',
            onClick: onEditReward
        }]}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const TreeRequestDetails = ({ abstract, index, options, utils }) => {

    const layerID = `tree-request-details-${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [reservation, setReservation] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [treeRequest, setTreeRequest] = useState(abstract.object);

    const onSetStatus = () => {
        utils.sheet.show({
            items: [{
                key: TreeRequest.status.accepted,
                title: 'Accepted',
                style: 'default'
            },{
                key: TreeRequest.status.completed,
                title: 'Completed',
                style: 'default'
            },{
                key: TreeRequest.status.rejected,
                title: 'Rejected',
                style: 'destructive'
            },{
                key: TreeRequest.status.cancelled,
                title: 'Cancelled',
                style: 'destructive'
            }]
        }, key => {
            if(isNaN(key)) {
                return;
            }
            onSetStatusConfirm(key);
        })
    }

    const onSetStatusConfirm = async code => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            let { status } = await Request.post(utils, '/trees/', {
                type: 'set_status',
                id: treeRequest.id,
                status: code
            });

            setLoading(false);
            abstract.object.status = TreeRequest.formatStatus(status);
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `The status for this request has been set to "${abstract.object.status.text.toLowerCase()}"`
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this tree request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: treeRequest.id
            },{
                key: 'count',
                title: 'Count',
                value: `${treeRequest.count} ${treeRequest.count === 1 ? 'tree' : 'trees'}`
            },{
                key: 'date',
                title: 'Submitted',
                value: Utils.formatDate(treeRequest.date)
            },{
                key: 'status',
                title: 'Status',
                value: treeRequest.status ? treeRequest.status.text : null
            }]
        }];
    }

    const fetchTarget = async () => {
        try {
            let { order, reservation, subscription } = await Utils.createMultiple(utils, {
                orderID: treeRequest.order_id,
                reservationID: treeRequest.reservation_id,
                subscriptionID: treeRequest.subscription_id
            })
            setOrder(order);
            setReservation(reservation);
            setSubscription(subscription);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the target for this request. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTarget();
        utils.content.subscribe(layerID, 'treeRequests', {
            onUpdate: next => {
                setTreeRequest(next.compare(abstract));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle()}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading
        }}
        buttons={[{
            key: 'status',
            text: 'Set Status',
            color: 'primary',
            onClick: onSetStatus
        }]}>
            {treeRequest.customer && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: treeRequest.customer.full_name,
                        subTitle: treeRequest.customer.phone_number,
                        icon: {
                            path: treeRequest.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, treeRequest.customer)
                    })}
                </LayerItem>
            )}

            {treeRequest.company && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: treeRequest.company.name,
                        subTitle: treeRequest.company.address,
                        icon: {
                            path: treeRequest.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, treeRequest.company)
                    })}
                </LayerItem>
            )}

            {order && (
                <LayerItem title={`${order.channel.name} Order`}>
                    {Views.entry({
                        title: order.customer.full_name,
                        subTitle: order.host ? `Ordered from ${order.host.name}` : 'Destination Not Available',
                        supportingTitle: moment(order.drop_off_date).format('MMMM Do, YYYY [at] h:mma'),
                        badge: order.status,
                        icon: {
                            path: order.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.orders.details.bind(this, utils, order)
                    })}
                </LayerItem>
            )}

            {reservation && (
                <LayerItem title={'Reservation'}>
                    {Views.entry({
                        title: reservation.customer.full_name,
                        subTitle: moment(reservation.pickup_date).format('MMMM Do, YYYY [at] h:mma'),
                        badge: reservation.status,
                        icon: {
                            path: reservation.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.reservations.details.bind(this, utils, reservation)
                    })}
                </LayerItem>
            )}

            {subscription && (
                <LayerItem title={'Subscription'}>
                    {Views.entry({
                        title: subscription.customer.full_name,
                        subTitle: subscription.plan.name,
                        badge: {
                            text: subscription.active ? null : 'Not Active',
                            style: Appearance.colors.grey()
                        },
                        icon: {
                            path: subscription.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.subscriptions.details.bind(this, utils, subscription)
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

        </Layer>
    )
}
