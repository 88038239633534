import React, { useRef, useState } from 'react';
import moment from 'moment-timezone';

import Appearance from 'styles/Appearance.js';
import BoolToggle from 'views/BoolToggle.js';
import Button from 'views/Button.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import ListField from 'views/ListField.js';
import MultipleUserLookupField from 'views/MultipleUserLookupField.js';
import Utils from 'files/Utils.js';

const DownloadManager = ({ chart, dates, extendedTypes, extendedFeatures = [], id, onExport, override, title }, { options, index, utils }) => {

    const datePicker = useRef(null);
    const [endDate, setEndDate] = useState(dates && dates.end_date ? moment(dates.end_date) : null);
    const [fileType, setFileType] = useState('xls');
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [includeChart, setIncludeChart] = useState(true);
    const [startDate, setStartDate] = useState(dates && dates.start_date ? moment(dates.start_date) : null);
    const [users, setUsers] = useState([]);

    const items = [{
        id: 'ics',
        title: 'iCal',
        visible: extendedTypes && extendedTypes.includes('calendar') ? true : false
    },{
        id: 'csv',
        title: 'CSV'
    },{
        id: 'xls',
        title: 'Excel'
    },{
        id: 'pdf',
        title: 'PDF'
    }];

    const onClearClick = () => {
        setEndDate(moment());
        setStartDate(moment().subtract(1, 'months'));
        setFileType('xls');
        setUsers([]);
    }

    const onDownloadClick = () => {
        if(!fileType) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'Please choose a file type to continue.'
            })
            return;
        }
        utils.alert.show({
            title: 'Verification',
            message: 'Please enter your account password to continue.',
            textFields: [{
                key: 'password',
                secure: true,
                placeholder: 'Password'
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: async response => {
                if(response.key === 'done') {
                    onStartDownload(response);
                    return;
                }
            }
        })
    }

    const onStartDownload = async response => {
        try {
            setLoading('download');
            await onValidatePassword(response);
            let props = {
                download: {
                    file_type: fileType,
                    users: users && users.map(user => user.user_id)
                }
            };
            if(extendedFeatures.includes('chart')) {
                if(includeChart && chart.current) {
                    props.download.chart_data = chart.current.chartInstance.toBase64Image();
                }
            }
            if(extendedFeatures.includes('date_range')) {
                if(startDate) {
                    props.download.start_date = moment(startDate).utc().unix();
                }
                if(endDate) {
                    props.download.end_date = moment(endDate).utc().unix();
                }
            }
            if(typeof(onExport) === 'function') {
                await onExport(props);
            }
            setLoading(false);
            setLayerState('close');

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: e.message || 'An unknown error occurred'
            });
        }
    }

    const onValidatePassword = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                await Utils.sleep(0.5);
                if(!props || !props.password) {
                    throw new Error('Please enter your password to continue');
                }
                if(props.password !== utils.user.get().password) {
                    throw new Error('Your password is incorrect');
                }
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    return (
        <Layer
        id={id}
        title={override && override.includes('title') ? title : `Download ${title}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'clear',
            text: 'Clear',
            color: 'grey',
            visible: extendedFeatures.length > 0,
            onClick: onClearClick
        },{
            key: 'download',
            text: 'Download',
            color: 'primary',
            onClick: onDownloadClick
        }]}>
            <LayerItem
            title={'File Type'}
            shouldStyle={false}>
                <ListField
                utils={utils}
                items={items}
                value={fileType && items.find(item => item.id === fileType)}
                onChange={item => setFileType(item && item.id)} />
            </LayerItem>

            <LayerItem
            title={'Send A Copy'}
            shouldStyle={false}
            lastItem={extendedFeatures.length === 0}>
                <MultipleUserLookupField
                utils={utils}
                users={users}
                onChange={users => setUsers(users)} />
            </LayerItem>

            {extendedFeatures.includes('date_range') && (
                <LayerItem
                title={'Date Range'}
                shouldStyle={false}
                lastItem={extendedFeatures.length === 1}>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={startDate}
                    selectedEndDate={endDate}
                    onStartDateChange={date => setStartDate(date)}
                    onEndDateChange={date => setEndDate(date)} />
                </LayerItem>
            )}

            {extendedFeatures.includes('chart') && (
                <LayerItem
                shouldStyle={false}
                title={'Include Image of Chart'}
                subTitle={'Export a zip file with the image and the document'}
                lastItem={true}>
                    <BoolToggle
                    isEnabled={includeChart}
                    enabled={'Yes'}
                    disabled={'No'}
                    onChange={val => setIncludeChart(val)} />
                </LayerItem>
            )}
        </Layer>
    )
}
export default DownloadManager;
