import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import Appearance from 'styles/Appearance.js';

const MultipleFileUpload = ({ files, utils, image, onChange, style }) => {

    const [selectedFiles, setSelectedFiles] = useState(null);
    const [label, setLabel] = useState('0 Files Selected');

    useEffect(() => {
        if(!selectedFiles || selectedFiles.length === 0) {
            setSelectedFiles(files || []);
            setLabel((files || []).length + ((files || []).length === 1 ? ' File ' : ' Files ') + 'Selected');
        }
    }, [files]);

    const onFileChange = (e) => {

        if(e.target && e.target.files && e.target.files.length > 0) {

            let selections = [...selectedFiles];
            let files = Object.values(e.target.files).filter(f => f !== e.target.files.length);
            files.forEach(f => {

                const reader = new FileReader();
                reader.onload = (data) => {

                    selections = update(selections, {
                        $push: [{
                            file_name: f.name || '',
                            data: data.target.result,
                            file_type: f.type || ''
                        }]
                    });

                    setSelectedFiles(selections);
                    setLabel(selections.length + (selections.length === 1 ? ' File ' : ' Files ') + 'Selected');
                    if(onChange && typeof(onChange) === 'function') {
                        onChange(selections);
                    }
                };
                reader.readAsDataURL(f);
            });
        }
    }

    const onFileOptions = (index) => {
        utils.sheet.show({
            items: [{
                key: 'remove',
                title: 'Remove File',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'remove') {
                let selections = update(selectedFiles, {
                    $splice: [
                        [index, 1]
                    ]
                });
                setSelectedFiles(selections);
                setLabel(selections.length + (selections.length === 1 ? ' File ' : ' Files ') + 'Selected');

                if(onChange && typeof(onChange) === 'function') {
                    onChange(selections);
                }
            }
        })
    }

    return (
        <div style={{
            ...style,
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            width: '100%'
        }}>
            <div
            className={`custom-file ${window.theme}`}
            style={{
                flexGrow: 1
            }}>
                <input
                type={'file'}
                className={`custom-file-input ${window.theme}`}
                accept={'image/png, image/jpeg, video/mp4'}
                multiple={true}
                onChange={onFileChange}/>
                <label className={`custom-file-label ${window.theme}`}>{label}</label>
            </div>

            {selectedFiles && selectedFiles.length > 0 && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                    marginTop: 8
                }}>
                    {selectedFiles.map((f, index) => {
                        return (
                            <div
                            key={index}
                            className={'text-button'}
                            onClick={onFileOptions.bind(this, index)}
                            style={{
                                marginRight: 4
                            }}>
                                <img
                                src={f.file_type.includes('image') ? f.data : ''}
                                style={{
                                    width: 45,
                                    height: 35,
                                    borderRadius: '0.25rem',
                                    objectFit: 'cover',
                                    border: `1px solid ${Appearance.colors.divider()}`
                                }}/>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default MultipleFileUpload;
