import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Company from 'classes/Company.js';
import User from 'classes/User.js';

class TreeRequestClass {

    id = null;
    customer = null;
    company = null;
    date = null;
    count = null;
    order_id = null;
    reservation_id = null;
    subscription_id = null;
    status = null;

    constructor() {
        return this;
    }

    create = props => {
        this.id = props.id;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.company = props.company ? Company.create(props.company) : null;
        this.date = props.date ? moment(props.date) : null;
        this.count = props.count;
        this.order_id = props.order_id;
        this.reservation_id = props.reservation_id;
        this.subscription_id = props.subscription_id;
        this.status = formatStatus(props.status ? props.status.code : null);
        return this;
    }
}

const statusCodes = {
    rejected: 0,
    accepted: 1,
    cancelled: 2,
    charge_processing: 3,
    charge_failed: 4,
    charge_issue: 5,
    completed: 6
}

export const formatStatus = code => {
    switch(code) {
        case statusCodes.rejected:
        return {
            code: code,
            text: 'Rejected',
            realText: 'Rejected',
            color: Appearance.colors.red
        }

        case statusCodes.accepted:
        return {
            code: code,
            text: 'Accepted',
            realText: 'Accepted',
            color: Appearance.colors.darkGrey
        }

        case statusCodes.cancelled:
        return {
            code: code,
            text: 'Cancelled',
            realText: 'Cancelled',
            color: '#812222'
        }

        case statusCodes.charge_processing:
        return {
            code: code,
            text: 'Charge Processing',
            realText: 'Charge Processing',
            color: Appearance.colors.grey()
        }

        case statusCodes.charge_failed:
        return {
            code: code,
            text: 'Charge Failed',
            realText: 'Charge Failed',
            color: Appearance.colors.red
        }

        case statusCodes.charge_issue:
        return {
            code: code,
            text: 'Charge Issue',
            realText: 'Charge Issue',
            color: Appearance.colors.red
        }

        case statusCodes.completed:
        return {
            code: code,
            text: 'Completed',
            realText: 'Completed',
            color: Appearance.colors.primary()
        }

        default:
        return {
            text: 'Pending',
            realText: 'Pending',
            color: Appearance.colors.lightGrey
        }
    }
}

export default {
    new : () => new TreeRequestClass(),
    create: props => new TreeRequestClass().create(props),
    status: statusCodes,
    formatStatus: formatStatus
}
