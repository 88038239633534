import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import AltFieldMapper from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { DndProvider } from 'react-dnd';
import DragAndDrop from 'views/DragAndDrop.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import FeedbackResponse from 'classes/FeedbackResponse.js';
import FieldMapper from 'views/FieldMapper.js';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Layer, { LayerItem, LayerNote } from 'structure/Layer.js';
import MultipleSelect from 'views/MultipleSelect.js';
import NoDataFound from 'views/NoDataFound.js';
import { NoteEntry } from 'views/NotesManager.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Request from 'files/Request.js';
import Survey from 'classes/Survey.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import User from 'classes/User.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import Views from 'views/Main.js';

// panels
export const FeedbackQuestions = ({ index, utils }) => {

    const panelID = 'allSurveyQuestions';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            /*
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Feedback Questions',
                onExport: result => {
                    setLoading(true);
                    fetchQuestions(result);
                }
            });
            */
            return;
        }
    }

    const onQuestionClick = question => {
        utils.layer.open({
            id: `survey-question-${question.id}`,
            abstract: Abstract.create({
                type: 'surveyQuestions',
                object: question
            }),
            Component: SurveyQuestionDetails
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the individual questions that are used in the surveys for customer feedback',
            items: [{
                key: 'download',
                title: 'Download Questions',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(questions.length === 0) {
            return (
                Views.entry({
                    title: 'No Questions Found',
                    subTitle: 'There are no survey questions available to view',
                    bottomBorder: false
                })
            )
        }
        return questions.map((question, index) => {
            return (
                Views.entry({
                    key: question.id,
                    title: question.title,
                    subTitle: question.message,
                    bottomBorder: index !== questions.length - 1,
                    onClick: onQuestionClick.bind(this, question)
                })
            )
        })
    }

    const fetchQuestions = async download => {
        try {
            let { download_props, paging, questions } = await Request.get(utils, '/feedback/', {
                limit: limit,
                download: download === true,
                ...manager
            });

            setLoading(false);
            if(download_props) {
                Utils.handleDownload(download_props);
                return;
            }
            setPaging(paging);
            setQuestions(questions.map(question => Survey.Question.create(question)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the survey questions list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchQuestions();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Feedback Survey Questions'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by question name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const FeedbackResponses = ({ index, utils }) => {

    const panelID = 'feedbackResponses';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [responses, setResponses] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Feedback Responses',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/feedback/',  {
                    type: 'report',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the feedback responses that have been received. You can view a response by clicking the Details button on the right',
            items: [{
                key: 'download',
                title: 'Download Responses',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader()
        }
        if(responses.length === 0) {
            return (
                Views.entry({
                    title: 'No Responses Found',
                    subTitle: 'There are no responses available to view',
                    bottomBorder: false
                })
            )
        }
        return responses.map((response, index) => {
            return (
                Views.entry({
                    key: response.id,
                    title: response.user ? response.user.full_name : 'Anonymous Feedback',
                    subTitle: `Submitted on ${moment(response.date).format('MMMM Do, YYYY [at] h:mma')}`,
                    icon: response.user && {
                        style: Appearance.icons.standard(),
                        path: response.user.avatar
                    },
                    badge: response.recommendation,
                    bottomBorder: index !== responses.length - 1,
                    onClick: Utils.feedback.details.bind(this, utils, response)
                })
            )
        })
    }

    const fetchResponses = async download => {
        try {
            let { paging, responses } = await Request.get(utils, '/feedback/',  {
                type: 'report',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setResponses(responses.map(response => FeedbackResponse.create(response)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the customer responses list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchResponses();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Feedback Responses'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const FeedbackSurveys = ({ index, utils }) => {

    const panelID = 'allSurveys';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [surveys, setSurveys] = useState([]);

    const onAssistClick = key => {
        /*
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Feedback Surveys',
                onExport: result => {
                    setLoading(true);
                    fetchSurveys(result);
                }
            });
            return;
        }
        */
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onSurveyClick = survey => {
        utils.layer.open({
            id: `survey-details-${survey.id}`,
            abstract: Abstract.create({
                type: 'surveys',
                object: survey
            }),
            Component: SurveyDetails
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the individual surveys that are used for customer feedback. You can view the survey questions and other information by clicking the Details button on the right',
            items: [{
                key: 'download',
                title: 'Download Surveys',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(surveys.length === 0) {
            return (
                Views.entry({
                    title: 'No Surveys Found',
                    subTitle: 'There are no surveys available to view',
                    bottomBorder: false
                })
            )
        }
        return surveys.map((survey, index) => {
            return (
                Views.entry({
                    key: survey.id,
                    title: survey.title,
                    subTitle: `${survey.questions.length} ${survey.questions.length == 1 ? ' Question' : ' Questions'}`,
                    bottomBorder: index !== surveys.length - 1,
                    onClick: onSurveyClick.bind(this, survey)
                })
            )
        })
    }

    const fetchSurveys = async download => {
        try {
            let { download_props, paging, surveys } = await Request.get(utils, '/feedback/',  {
                type: 'surveys',
                limit: limit,
                download: download === true,
                ...manager
            });

            setLoading(false);
            if(download_props) {
                Utils.handleDownload(download_props);
                return;
            }
            setPaging(paging);
            setSurveys(surveys.map(survey => Survey.create(survey)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the surveys list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchSurveys();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Feedback Surveys'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by survey name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const NetPromoter = ({ index, utils }) => {

    const chartRef = useRef(null);
    const panelID = 'netPromoterBreakdown';

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(12, 'months') });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Net Promoter Breakdown',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/feedback/', {
                    type: 'recommendation_breakdown',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    const onFormatLabel = (tooltipItem, data) => {
        let val = parseInt(tooltipItem.yLabel);
        if(tooltipItem.datasetIndex == 0) {
            return `${val} ${val ===1 ? 'Promoter' : 'Promoters'}`;
        }
        if(tooltipItem.datasetIndex == 1) {
            return `${val} Passive`;
        }
        if(tooltipItem.datasetIndex == 2) {
            return `${val} ${val ===1 ? 'Detractor' : 'Detractors'}`;
        }
        return tooltipItem.yLabel;
    }

    const getAssistProps = () => {
        return {
            message: 'The graph shows the likelihood to recommend our service to others based on customer feedback. Promoters are considered a customer response with a rating of 9 or 10 out of 10. Passives are a customer response at 7 or 8 out of 10. Detractors are anything less than 7',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(!chart || chart.labels.length < 3) {
            return (
                <div
                className={'p-2'}
                style={{
                    minHeight: 150,
                    position: 'relative',
                }}>
                    <NoDataFound message={'At least 3 entries of customer responses are needed for the graph'}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={dates.start_date}
                        selectedEndDate={dates.end_date}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }}
                        onStartDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    start_date: date
                                }
                            })
                        }}
                        onEndDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    end_date: date
                                }
                            })
                        }} />
                    </div>
                </div>
            )
        }
        return (
            <>
            <div
            className={'row m-0 p-2'}
            style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div className={'col-12 col-lg-6 px-2 pt-1 pb-2 p-lg-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span
                        className={'d-block'}
                        style={Appearance.textStyles.title()}>{`Net Promoter Score: ${chart.score}`}</span>
                        <span
                        className={'d-block'}
                        style={Appearance.textStyles.subTitle()}>{chart.responses + ' Customer '+ (chart.responses === 1 ? 'Response' : 'Responses')}</span>
                    </div>
                </div>
                <div className={'col-12 col-lg-6 p-0'}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={dates.start_date}
                        selectedEndDate={dates.end_date}
                        style={{
                            maxWidth: Utils.isMobile() === false ? 350 : null
                        }}
                        onStartDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    start_date: date
                                }
                            })
                        }}
                        onEndDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    end_date: date
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
            <div style={{
                position: 'relative',
                height: 350,
                padding: 12
            }}>
                {!chart || chart.labels.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of customer response are needed for the graph'}/>
                    :
                    <Line
                    ref={chartRef}
                    data={{
                        labels: chart.labels,
                        datasets: chart.dataset
                    }}
                    width={500}
                    height={100}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                           mode: 'label',
                           label: 'mylabel',
                           callbacks: {
                               label: onFormatLabel
                           },
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 12
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value, index, values) => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
            </>
        )
    }

    const fetchNPS = async () => {
        try {
            let { detractor, labels, passive, promoter, responses, score } = await Request.get(utils, '/feedback/', {
                end_date: moment(dates.end_date).utc().unix(),
                start_date: moment(dates.start_date).utc().unix(),
                type: 'recommendation_breakdown'
            });

            setLoading(false);
            if(labels.length < 3) {
                setChart(false);
                return;
            }
            setChart({
                score: score,
                responses: responses,
                labels: labels.map(label => moment(label, 'YYYY-MM').format('MMMM')),
                dataset: [{
                    label: 'Promoter',
                    data: Object.values(promoter),
                    borderColor: '#6CBF66',
                    fill: true,
                    backgroundColor: Utils.hexToRGBA('#6CBF66', 0.25),
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    pointRadius: 5
                },{
                    label: 'Passive',
                    data: Object.values(passive),
                    borderColor: Appearance.colors.yellow,
                    fill: true,
                    backgroundColor: Utils.hexToRGBA(Appearance.colors.yellow, 0.25),
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    pointRadius: 5
                },{
                    label: 'Detractor',
                    data: Object.values(detractor),
                    borderColor: Appearance.colors.red,
                    fill: true,
                    backgroundColor: Utils.hexToRGBA(Appearance.colors.red, 0.25),
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    pointRadius: 5
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the net promoter breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchNPS();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Net Promoter'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const RatingsBreakdown = ({ index, utils }) => {

    const chartRef = useRef(null);
    const panelID = 'ratingsBreakdown';

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(1, 'years') });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Ratings Breakdown',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/feedback/', {
                    type: 'recommendation_breakdown',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    const onRatingsClick = async value => {
        utils.layer.open({
            id: `rating-value-details-${value}`,
            Component: RatingsValueDetails.bind(this, {
                value: value,
                ...dates
            })
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows all of the ratings received through the mobile app. These ratings range from one star to five stars and are grouped by total star ratings',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div className={'row p-0 m-0'}>
                <div
                className={'col-12 col-lg-6 p-3'}
                style={{
                    height: 350
                }}>
                    {!chart || chart.labels.length < 3
                        ?
                        <NoDataFound message={'At least 3 entries of customer activity are needed for the graph'}/>
                        :
                        <Bar
                        ref={chartRef}
                        width={500}
                        height={100}
                        data={{
                            labels: chart.labels,
                            datasets: [{
                                data: chart.dataset,
                                borderWidth: 2,
                                borderColor: ({ dataIndex }) => {
                                    let rating = chart.ratings[dataIndex];
                                    return getRatingsColor(rating);
                                },
                                backgroundColor: ({ dataIndex }) => {
                                    let rating = chart.ratings[dataIndex];
                                    let color = getRatingsColor(rating);
                                    return Utils.hexToRGBA(color, 0.5);
                                }
                            }]
                        }}
                        options={{
                            title: {
                                display: false
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: item => {
                                        return `${item.yLabel} ${parseInt(item.yLabel) === 1 ? 'Rating' : 'Ratings'}`
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: value => Utils.numberFormat(value)
                                    }
                                }]
                            }
                        }} />
                    }
                </div>
                <div
                className={'col-12 col-lg-6'}
                style={{
                    padding: 12,
                    borderTop: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
                }}>
                    <DualDatePickerField
                    utils={utils}
                    selectedStartDate={dates.start_date}
                    selectedEndDate={dates.end_date}
                    onStartDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                start_date: date
                            }
                        })
                    }}
                    onEndDateChange={date => {
                        setDates(dates => {
                            return {
                                ...dates,
                                end_date: date
                            }
                        })
                    }}/>

                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        width: '100%',
                        marginTop: 8,
                        maxHeight: 358
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            padding: 8,
                            textAlign: 'center',
                            borderBottom: `1px solid ${Appearance.colors.divider()}`
                        }}>{getRatingsOverview()}</span>
                        <div style={{
                            maxHeight: 304,
                            overflowY: 'scroll'
                        }}>
                            {getRatingsList()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getRatingsColor = rating => {
        switch(rating) {
            case 1:
            return Appearance.colors.red;

            case 2:
            return Appearance.colors.orange;

            case 3:
            return Appearance.colors.yellow;

            case 4:
            return '#87DA81';

            default:
            return '#57AC51';
        }
    }

    const getRatingsList = () => {
        if(!chart || !chart.data) {
            return null;
        }
        let ratings = [{
            key: 5,
            title: 'Five Stars',
            color: '#57AC51',
            value: chart.data.find(entry => entry.rating === 5)
        },{
            key: 4,
            title: 'Four Stars',
            color: '#87DA81',
            value: chart.data.find(entry => entry.rating === 4)
        },{
            key: 3,
            title: 'Three Stars',
            color: Appearance.colors.yellow,
            value: chart.data.find(entry => entry.rating === 3)
        },{
            key: 2,
            title: 'Two Stars',
            color: Appearance.colors.orange,
            value: chart.data.find(entry => entry.rating === 2)
        },{
            key: 1,
            title: 'One Star',
            color: Appearance.colors.red,
            value: chart.data.find(entry => entry.rating === 1)
        }];

        return ratings.map((rating, index) => {
            let { total = 0 } = rating.value || {};
            return (
                Views.entry({
                    key: index,
                    title: rating.title,
                    subTitle: `${total} customer ${total === 1 ? 'response' : 'responses'}`,
                    bottomBorder: index !== rating.length - 1,
                    onClick: total > 0 && onRatingsClick.bind(this, rating.key),
                    icon: {
                        path: 'images/feedback-icon-clear-small.png',
                        style: {
                            ...Appearance.icons.standard(),
                            backgroundColor: rating.color
                        }
                    }
                })
            )
        })
    }

    const getRatingsOverview = () => {
        if(!chart) {
            return `No Ratings Received`;
        }
        let total = chart.data.reduce((total, entry) => {
            return total += entry.total;
        }, 0);
        return `${total} ${total === 1 ? 'Rating' : 'Ratings'} Received`;
    }

    const fetchBreakdown = async () => {
        try {
            let { ratings } = await Request.get(utils, '/feedback/', {
                end_date: moment(dates.end_date).utc().unix(),
                start_date: moment(dates.start_date).utc().unix(),
                type: 'ratings',
            });

            setLoading(false);
            setChart({
                data: ratings,
                ratings: ratings.map(rating => rating.rating),
                labels: ratings.map(rating => rating.name),
                dataset: ratings.map(rating => rating.total)
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ratings breakdown. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdown();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Ratings Breakdown'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const RatingResponses = ({ index, utils }) => {

    const panelID = 'ratingResponses';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [ratings, setRatings] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        /*
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Feedback Responses',
                onExport: result => {
                    setLoading(true);
                    fetchResponses(result);
                }
            });
            return;
        }
        */
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the feedback responses that have been received. You can view a response by clicking the Details button on the right',
            items: [{
                key: 'download',
                title: 'Download Responses',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(ratings.length === 0) {
            return (
                Views.entry({
                    title: 'No Responses Found',
                    subTitle: 'There are no responses available to view',
                    bottomBorder: false
                })
            )
        }
        return ratings.map((rating, index) => {
            return (
                Views.entry({
                    key: rating.id,
                    title: rating.user ? rating.user.full_name : 'Anonymous Feedback',
                    subTitle: `Submitted on ${moment(rating.date).format('MMMM Do, YYYY [at] h:mma')}`,
                    icon: rating.user && {
                        style: Appearance.icons.standard(),
                        path: rating.user.avatar
                    },
                    badge: rating.recommendation,
                    bottomBorder: index !== ratings.length - 1,
                    onClick: Utils.feedback.details.bind(this, utils, rating)
                })
            )
        })
    }

    const fetchResponses = async download => {
        try {
            let { download_props, paging, responses } = await Request.get(utils, '/feedback/',  {
                type: 'all_ratings',
                limit: limit,
                download: download === true,
                ...manager
            });

            setLoading(false);
            if(download_props) {
                Utils.handleDownload(download_props);
                return;
            }

            setPaging(paging);
            //setRatings(responses.map(response => RatingResponse.create(response)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ratings list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchResponses();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Rating Responses'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const RecentFeedback = ({ index, utils }) => {

    const panelID = 'recentFeedback';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [responses, setResponses] = useState([]);

    const onAssistClick = key => {
        if(key === 'view') {
            utils.structure.navigation.set({
                view: 'feedback',
                subView: null
            });
            return;
        }
    }

    const getAssistProps = () => {
        return {
            message: 'These are the users that are currently using the platform for iOS, Android, the Customer Portal, and the Admin portal. This graph updates in real time as a user joins or leaves the platform',
            items: [{
                key: 'view',
                title: 'View All Feedback',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(responses.length === 0) {
            return (
                Views.entry({
                    title: 'No Feedback Found',
                    subTitle: 'There are no customer responses available to view',
                    bottomBorder: false
                })
            )
        }
        return responses.map((response, index) => {
            return (
                Views.entry({
                    key: response.id,
                    title: response.user ? response.user.full_name : 'Anonymous Feedback',
                    subTitle: `Submitted on ${moment(response.date).format('MMMM Do, YYYY [at] h:mma')}`,
                    badge: response.recommendation,
                    icon: response.user && {
                        path: response.user.avatar
                    },
                    bottomBorder: index !== responses.length - 1,
                    onClick: Utils.feedback.details.bind(this, utils, response)
                })
            )
        })
    }

    const fetchFeedback = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            setLoading(true);
            utils.fetching.set(panelID, true);
            let { responses } = await Request.get(utils, '/feedback/',  {
                type: 'report',
                limit: limit
            });

            utils.fetching.set(panelID, false);
            setLoading(false);
            setResponses(responses.map(response => FeedbackResponse.create(response)));

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading recent customer feedback responses. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchFeedback();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Recent Feedback'}
        className={'col-12 col-xl-6'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddEditSurvey = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = `add-edit-survey-${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [opacity, setOpacity] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [survey, setSurvey] = useState(abstract.object);

    const onSubmit = async () => {
        // TODO => implement save function
        utils.alert.dev();
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setSurvey(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setSurvey(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }];
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: `The title for a feedback survey is shown across Seeds and to the customer when the customer is filling out the survey's questions`,
                value: survey.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'information',
                title: 'Description',
                description: `The description for a survey is shown across Seeds when representing the survey and to the customer when the customer is filling out the survey's questions`,
                value: survey.information,
                component: 'textview',
                onChange: text => onUpdateTarget({ information: text })
            },{
                key: 'questions',
                title: 'Questions',
                description: `The questions for a survey are shown to a customer when they are interacting with this survey. This will be how you can connect with your customer and learn more about their experience.`,
                value: survey.questions,
                component: 'multiple_list',
                onChange: items => {
                    onUpdateTarget({ questions: items ? items.map(item => item.id) : [] });
                },
                items: questions.map(question => ({
                    id: question.id,
                    title: question.title
                }))
            }]
        }];
    }

    const fetchSurveyQuestions = async () => {
        try {
            let { questions } = await Request.get(utils, '/feedback/');
            setQuestions(questions);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the survey questions list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSurveyQuestions();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Feedback Survey' : `Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={options}
        buttons={getButtons()}>

            <AltFieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerItem title={'Order of Questions'}>
                <DndProvider backend={HTML5Backend}>
                    {survey.questions.map((question, index) => {
                        return (
                            <DragAndDrop
                            key={index}
                            index={index}
                            id={question.id}
                            onReorder={(dragIndex, hoverIndex) => {
                                setSurvey(survey => update(survey, {
                                    questions: {
                                        $splice: [[ dragIndex, 1 ],[ hoverIndex, 0, question ]]
                                    }
                                }))
                            }}>
                                <div className={'mb-2'}>
                                    <div style={{
                                        ...Appearance.styles.unstyledPanel(),
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '8px 12px 8px 12px'
                                    }}>
                                        <span style={Appearance.textStyles.title()}>{question.title}</span>
                                        <span style={Appearance.textStyles.subTitle()}>{question.message}</span>
                                    </div>
                                </div>
                            </DragAndDrop>
                        )
                    })}
                </DndProvider>
            </LayerItem>
        </Layer>
    )
}

export const FeedbackDetails = ({ abstract, index, options, utils }) => {

    const layerID = `feedback-details-${abstract.getID()}`;
    const [dropDown, setDropDown] = useState(null);
    const [feedback, setFeedback] = useState(abstract.object);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);

    const onContactClick = () => {
        window.location.href = `mailto:${feedback.user.email_address}`;
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
        });
    }

    const getButtons = () => {
        let buttons = [{
           key: 'options',
           text: 'Options',
           color: 'secondary',
           onClick: onOptionsClick
       }];
       if(feedback.user && feedback.user.email_address) {
           buttons.push({
               key: 'contact',
               text: `Contact ${feedback.user.first_name}`,
               color: 'primary',
               onClick: onContactClick
           });
       }
       return buttons;
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Survey',
            items: [{
                key: 'name',
                title: 'Name',
                value: feedback.survey_name
            },{
                key: 'date',
                title: 'Date Submitted',
                value: Utils.formatDate(feedback.date)
            }]
        },{
            key: 'response',
            title: `Response${feedback.user ? ` from ${feedback.user.first_name}` : ''}`,
            items: feedback.questions.map((entry, index) => ({
                key: index,
                title: entry,
                value: feedback.answers[index] || 'No Answer Provided'
            }))
        }];
    }

    useEffect(() => {
        if(feedback.seeds && feedback.seeds.notes) {
            for(var i in feedback.seeds.notes) {
                if(!feedback.seeds.notes[i].deleted) {
                    setNote(feedback.seeds.notes[i]);
                    break;
                }
            }
        }
    }, [feedback]);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        buttons={getButtons()}
        options={{
            ...options,
            sizing: 'medium'
        }}>
            {feedback.user && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: feedback.user ? feedback.user.full_name : 'Anonymous Feedback',
                        subTitle: feedback.user ? feedback.user.email_address : 'Email Address Not Available',
                        icon: feedback.user && {
                            path: feedback.user.avatar
                        },
                        badge: feedback.recommendation,
                        bottomBorder: false,
                        onClick: feedback.user ? Utils.users.details.bind(this, utils, feedback.user) : null
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const RatingsValueDetails = ({ end_date, start_date, value }, { index, options, utils }) => {

    const layerID = `rating-value-details-${value}`;
    const limit = 10;

    const [loading, setLoading] = useLoading();
    const [manager, setManager, formatResults] = useResultsManager({ end_date: end_date, start_date: start_date });
    const [paging, setPaging] = useState(null);
    const [results, setResults] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            ...manager,
            offset: 0,
            search_text: text
        });
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader()
        }
        if(results.length === 0) {
            return (
                <LayerItem
                title={`Users Who Left ${value} ${value === 1 ? 'Star' : 'Stars'}`}
                childrenStyle={{
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.subTitle(),
                        color: Appearance.colors.text(),
                        display: 'block'
                    }}>{'No users are available to view'}</span>
                </LayerItem>
            )
        }
        return (
            <LayerItem title={`Users Who Left ${value} ${value === 1 ? 'Star' : 'Stars'}`}>
                {results.map((result, index) => {
                    return (
                        Views.entry({
                            key: index,
                            title: result.user.full_name,
                            subTitle: Utils.formatDate(result.date),
                            icon: {
                                path: result.user.avatar
                            },
                            bottomBorder: index !== results.length - 1,
                            onClick: Utils.users.details.bind(this, utils, result.user)
                        })
                    )
                })}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={manager.offset}
                    onClick={next => {
                        setLoading('true');
                        setManager('offset', next);
                    }} />
                )}
            </LayerItem>
        )
    }

    const getSearchField = () => {
        if(loading === 'init') {
            return null;
        }
        return (
            <div style={{
                width: '100%',
                padding: 15,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <TextField
                icon={'search'}
                useDelay={true}
                placeholder={'Search by first or last name...'}
                onChange={onSearchTextChange}
                containerStyle={{
                    marginBottom: 8
                }}/>

                <DualDatePickerField
                utils={utils}
                selectedStartDate={manager.start_date}
                selectedEndDate={manager.end_date}
                onStartDateChange={date => setManager('start_date', date)}
                onEndDateChange={date => setManager('end_date', date)} />
            </div>
        )
    }

    const fetchUsers = async () => {
        try {
            let { paging, results } = await Request.get(utils, '/feedback/', {
                type: 'users_for_rating',
                rating: value,
                limit: limit,
                ...formatResults(utils)
            });
            setLoading(false);
            setPaging(paging);
            setResults(results.map(result => ({
                date: moment(result.date),
                user: User.create(result.user)
            })));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the users who left these ratings. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchUsers();
    }, [manager]);

    return (
        <Layer
        id={layerID}
        title={`Ratings Breakdown Details`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            removePadding: true
        }}>
            {getSearchField()}
            <div style={{
                padding: 15,
                width: '100%'
            }}>
                {getContent()}
            </div>
        </Layer>
    )
}

export const SurveyDetails = ({ abstract, index, options, utils }) => {

    const layerID = 'survey-details-' + abstract.getID();
    const [loading, setLoading] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [note, setNote] = useState(null);
    const [survey, setSurvey] = useState(abstract.object);
    const [url, setURL] = useState(null);

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit Survey',
                style: 'default'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'edit') {
                utils.layer.open({
                    id: `add-edit-survey-${abstract.getID()}`,
                    abstract: abstract,
                    Component: AddEditSurvey.bind(this, {
                        isNewTarget: false
                    })
                })
                return;
            }
        })
    }

    const onUpdateURL = () => {
        let data = btoa(JSON.stringify({
            app_id: utils.user.get().client_id,
            questions: survey.questions.map(q => q.toJSON())
        }));
        setURL(`${API.server}/feedback/mobile/index.php?v=${API.version}&app_id=${window.client_id}&survey_id=${abstract.getID()}&theme_style=${window.theme}`);
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: survey.id
            },{
                key: 'title',
                title: 'Title',
                value: survey.title
            },{
                key: 'information',
                title: 'Description',
                value: survey.information
            },{
                key: 'date',
                title: 'Submitted',
                value: Utils.formatDate(survey.date)
            },{
                key: 'active',
                title: 'Status',
                value: survey.active ? 'Active' : 'Not Active'
            },{
                key: 'url',
                title: 'URL',
                value: survey.url
            }]
        }];
    }

    useEffect(() => {
        onUpdateURL();
        if(survey.seeds && survey.seeds.notes) {
            setNote(survey.seeds.notes.find(note => note.deleted !== true));
        }
    }, [survey]);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={options}
        buttons={[{
            key: 'status',
            text: survey.active ? 'Deactivate' : 'Activate',
            color: survey.active ? 'danger' : 'primary',
            onClick: null
        }, {
            key: 'options',
            text: 'Options',
            color: survey.active ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }]}>
            <div style={{
                position: 'relative',
                height: 400,
                borderRadius: 10,
                overflow: 'hidden',
                boxShadow: Appearance.boxShadow(),
                backgroundColor: Appearance.colors.primary()
            }}>
                <VelocityComponent
                duration={250}
                animation={{
                    opacity: opacity
                }}>
                    <iframe
                    src={url}
                    onLoad={() => setOpacity(1)}
                    frameBorder={0}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}/>
                </VelocityComponent>
            </div>

            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const SurveyQuestionDetails = ({ abstract, index, options, utils }) => {

    const layerID = `survey-question-${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState(abstract.object);

    const onEditSurvey = () => {
        // TODO => implement edit survey logic
        utils.alert.dev();
    }

    const onDeleteSurvey = () => {
        // TODO => implement delete survey logic
        utils.alert.dev();
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: question.id
            },{
                key: 'type',
                title: 'Type',
                value: question.typeToText()
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(question.id)
            },{
                key: 'title',
                title: 'Title',
                value: question.title
            },{
                key: 'message',
                title: 'Message',
                value: question.message
            }]
        }];
    }

    return (
        <Layer
        id={layerID}
        title={'Details for "' + abstract.getTitle() + '"'}
        index={index}
        options={{
            ...options,
            sizing: 'medium'
        }}
        buttons={[{
            key: 'delete',
            text: 'Delete',
            color: 'danger',
            onClick: onDeleteSurvey
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditSurvey
        }]}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}
