import React, { useEffect, useState } from 'react';
import { getIcon } from 'views/TextField.js';

import Appearance from 'styles/Appearance.js';
import Order from 'classes/Order.js';
import Request from 'files/Request.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

const HostLookupField = ({ className, channel, host, inline, containerStyle, icon, locationFilter, onChange, value, showLocations, showLocationText, utils }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(false);
    const [text, setText] = useState(null);

    const onHostClick = host => {
        setResults([]);
        setSelected(host);
        onUpdateFieldLabel(host);
        if(typeof(onChange) === 'function') {
            onChange(host);
        }
    }

    const onTextChange = async text => {
        try {

            if(selected && showLocationText === true) {
                text = selected.name;
            }
            setText(text);
            if(text.length === 0) {
                setResults([]);
                return;
            }
            setLoading(true);
            let { hosts } = await Request.get(utils, '/orders/', {
                type: 'all_hosts_admin',
                search_text: text,
                order_channel: channel.id,
                show_locations: showLocations || false
            });

            let targets = hosts.map(host => Order.Host.create(host));
            setLoading(false);
            setResults(() => {
                if(showLocations !== true) {
                    return targets;
                }
                return targets.sort((a,b) => {
                    return Utils.linearDistance(a.locations[0].location, window.userLocation, Utils.linearDistance(b.locations[0].location, window.userLocation)) ? 1 : -1;
                });
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateFieldLabel = host => {
        if(!host) {
            return;
        }
        if(showLocationText !== true) {
            setText(host.name);
            return;
        }
        setText(`${host.name}: ${host.locations[0].address || 'Address Not Available'}`);
    }

    const getStyles = () => {
        if(inline !== true) {
            return {
                ...Appearance.styles.unstyledPanel(),
                overflow: 'hidden',
                marginTop: 8
            }
        }
        return {
            position: 'absolute',
            left: 0,
            right: 0,
            top: 43,
            backgroundColor: Appearance.colors.panelBackground(),
            border: `1px solid ${Appearance.colors.softBorder()}`,
            borderRadius: 8,
            boxShadow: '0 0.46875rem 1.1875rem rgba(0, 0, 0, 0.1)',
            zIndex: 1100,
            overflow: 'hidden'
        }
    }

    useEffect(() => {
        setSelected(host);
        onUpdateFieldLabel(host);
    }, [host]);

    return (
        <div style={{
            textAlign: 'left',
            position: 'relative',
            width: '100%'
        }}>
            <TextField
            utils={utils}
            value={text}
            loading={loading}
            useDelay={true}
            placeholder={'Search by host name...'}
            onChange={onTextChange} />
            {results.length > 0 && (
                <div style={getStyles()}>
                    {results.map((host, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: host.name,
                                subTitle: host.locations.length === 1 ? host.locations[0].address : `${host.locations.length} Locations`,
                                icon: {
                                    path: host.image
                                },
                                badge: host.locations[0] && {
                                    text: `${Utils.distanceConversion(Utils.linearDistance(host.locations[0].location, window.userLocation))} away`,
                                    color: index === 0 ? Appearance.colors.primary() : Appearance.colors.grey()
                                },
                                onClick: onHostClick.bind(this, host),
                                bottomBorder: index !== results.length - 1
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default HostLookupField;
