import React, { useEffect, useRef, useState } from 'react';

import { fetchCompanies } from 'managers/Companies.js';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import { Bar, Line } from 'react-chartjs-2';
import Button from 'views/Button.js';
import CreditsCard from 'classes/CreditsCard.js';
import DatePickerField from 'views/DatePickerField.js';
import DualDatePickerField from 'views/DualDatePickerField.js';
import FieldMapper from 'views/FieldMapper.js';
import ICHMethod from 'classes/ICHMethod.js';
import ICHTransaction from 'classes/ICHTransaction.js';
import Layer, { LayerItem, LayerMap, LayerNote } from 'structure/Layer.js';
import NoDataFound from 'views/NoDataFound.js';
import Note from 'classes/Note.js';
import Order from 'classes/Order.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Payment from 'classes/Payment.js';
import PaymentMethodManager from 'views/PaymentMethodManager.js';
import PromoCode from 'classes/PromoCode.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import Subscription from 'classes/Subscription.js';
import SystemEvent from 'classes/SystemEvent.js';
import { SystemEventDetails } from 'managers/Resources.js';
import TextField from 'views/TextField.js';
import TextView from 'views/TextView.js';
import TimeRangePicker from 'views/TimeRangePicker.js';
import User from 'classes/User.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils, { useLoading, useResultsManager } from 'files/Utils.js';
import Views from 'views/Main.js';

// panels
export const CompanyCards = ({ index, utils }) => {

    const panelID = 'companyCards';
    const limit = 5;

    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Company Cards',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/cards/',  {
                    type: 'list_cards',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the company cards that are in the system',
            items: [{
                key: 'download',
                title: 'Download Cards',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(cards.length === 0) {
            return (
                Views.entry({
                    title: 'No Cards Found',
                    subTitle: 'There are no cards available to view',
                    borderBottom: false
                })
            )
        }
        return cards.map((card, index) => {
            return (
                Views.entry({
                    key: card.id,
                    title: card.user.full_name,
                    subTitle: card.summary(),
                    icon: {
                        path: card.user.avatar
                    },
                    badge: card.status !== 'active' && {
                        text: 'Not Active',
                        color: Appearance.colors.red
                    },
                    bottomBorder: index !== cards.length - 1,
                    onClick: Utils.ichMethod.details.bind(this, card)
                })
            )
        });
    }

    const fetchCards = async () => {
        try {
            let { cards, paging } = await Request.get(utils, '/cards/',  {
                type: 'list_cards',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCards(cards.map(card => ICHMethod.create(card)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the cards list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchCards();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'All Company Cards'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by id or card holder name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const CompanyCardHolders = ({ index, utils }) => {

    const panelID = 'companyCardHolders';
    const limit = 5;

    const [cardHolders, setCardHolders] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Promotions',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/cards/',  {
                    type: 'card_holders',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the promotions that are in the system',
            items: [{
                key: 'download',
                title: 'Download Promotions',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(cardHolders.length === 0) {
            return (
                Views.entry({
                    title: 'No Card Holders Found',
                    subTitle: 'There are no card holders available to view',
                    borderBottom: false
                })
            )
        }
        return cardHolders.map((cardHolder, index) => {
            return (
                Views.entry({
                    key: cardHolder.user_id,
                    title: cardHolder.full_name,
                    subTitle: cardHolder.email_address,
                    icon: {
                        path: cardHolder.avatar
                    },
                    badge: cardHolder.stripe_card_holder_data && cardHolder.stripe_card_holder_data.status !== 'active' && {
                        text: 'Not Active',
                        color: Appearance.colors.red
                    },
                    bottomBorder: index !== cardHolders.length - 1,
                    onClick: Utils.users.details.bind(this, utils, cardHolder)
                })
            )
        });
    }

    const fetchCardHolders = async () => {
        try {
            let { card_holders, paging } = await Request.get(utils, '/cards/',  {
                type: 'card_holders',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCardHolders(card_holders.map(user => {
                return User.create({
                    ...user,
                    cardHolderStatus: user.status
                })
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the card holders list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchCardHolders();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Company Card Holders'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by first or last name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const CompanyCardTransactions = ({ index, utils }) => {

    const panelID = 'companyCardTransactions';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [transactions, setTransactions] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Company Card Transactions',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/cards/',  {
                    type: 'transactions',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the transactions made on Company cards that are in the system',
            items: [{
                key: 'download',
                title: 'Download Transcations',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(transactions.length === 0) {
            return (
                Views.entry({
                    title: 'No Transactions Found',
                    subTitle: 'There are no transactions available to view',
                    borderBottom: false
                })
            )
        }
        return transactions.map((t, index) => {
            return (
                Views.entry({
                    key: t.id,
                    title: t.user.full_name,
                    subTitle: moment(t.date).format('MMMM Do, YYYY [at] h:mma'),
                    icon: {
                        path: t.user.avatar
                    },
                    badge: {
                        text: Utils.toCurrency(t.amount),
                        color: Appearance.colors.primary()
                    },
                    bottomBorder: index !== transactions.length - 1
                })
            )
        });
    }

    const fetchTransactions = async () => {
        try {
            let { paging, transactions } = await Request.get(utils, '/cards/',  {
                type: 'transactions',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            // TODO => setting user to current user needs to be revisted
            setTransactions(transactions.map(transaction => {
                return ICHTransaction.create({
                    ...transaction,
                    user: utils.user.get()
                })
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the transactions list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchTransactions();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Company Card Transactions'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by id or card holder name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const CreditsActivity = ({ index, utils }) => {

    const chartRef = useRef(null);
    const panelID = 'creditsActivity';

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(3, 'months') });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Credits Activity',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
             return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/credits/',  {
                    type: 'activity',
                    display: 'graph',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows the credits activity for all Orders and Reservations. The left side is the amount of credits used and the bottom is day those credits were used. The graph shows the last 3 months by default',
            items: [{
                key: 'download',
                title: 'Download Activity',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                padding: 12,
                borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
            }}>
                <DualDatePickerField
                utils={utils}
                selectedStartDate={dates.start_date}
                selectedEndDate={dates.end_date}
                style={{
                    maxWidth: Utils.isMobile() === false ? 350 : null
                }}
                onStartDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            start_date: date
                        }
                    })
                }}
                onEndDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            end_date: date
                        }
                    })
                }} />
            </div>
            <div style={{
                position: 'relative',
                height: 350,
                padding: 20
            }}>
                {!chart || chart.data.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of usage are needed for the graph'}/>
                    :
                    <Line
                    ref={chartRef}
                    width={500}
                    height={100}
                    data={{
                        labels: chart.labels,
                        datasets: [{
                            data: chart.data,
                            borderColor: Appearance.colors.primary(),
                            fill: true,
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                            pointRadius: 5,
                            pointBorderWidth: 2,
                            pointBackgroundColor: 'white'
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => Utils.toCurrency(tooltipItem.yLabel)
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value, index, values) => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
            <div
            className={'row m-0'}
            style={{
                padding: 12
            }}>
                {chart && chart.items.map((item, index) => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-md-4 p-1 text-center'}>
                            <div style={{
                                ...Appearance.styles.unstyledPanel(),
                                padding: 8
                            }}>
                                <img
                                src={`/images/${item.image}`}
                                className={'my-1'}
                                style={{
                                    ...Appearance.icons.overview(),
                                    backgroundColor: index == 1 ? Appearance.colors.secondary() : Appearance.colors.primary()
                                }} />
                                <span
                                className={'d-block mt-2'}
                                style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                <span
                                className={'d-block'}
                                style={Appearance.textStyles.subTitle()}>{item.title}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const fetchBreakdown = async () => {
        try {
            let { credits } = await Request.get(utils, '/credits/',  {
                display: 'graph',
                end_date: moment(dates.end_date).utc().unix(),
                start_date: moment(dates.start_date).utc().unix(),
                type: 'activity'
            });

            let highestVal = credits.reduce((object, entry) => {
                return !object.date || object.total < entry.total ? entry : object;
            }, {});

            setLoading(false);
            setChart({
                data: credits.map(entry => entry.total),
                labels: credits.map(entry => moment.utc(entry.date).local().format('MMM Do')),
                items: [{
                    key: 'credits',
                    title: 'Total Credits Used',
                    placeholder: '$0.00',
                    value: Utils.toCurrency(credits.reduce((total, entry) => total += entry.total, 0)),
                    image: 'promo-code-large-clear.png'
                },{
                    key: 'highest',
                    title: 'Highest Amount',
                    placeholder: 'Not Available',
                    value: highestVal ? Utils.toCurrency(highestVal.total) : null,
                    image: 'discounts-given-clear.png'
                },{
                    key: 'popular',
                    title: 'Highest Usage Day',
                    placeholder: 'Not Available',
                    value: highestVal && moment.utc(highestVal.date).local().format('MMMM Do'),
                    image: 'calendar-increase-clear.png'
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the subscriptions activity. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdown();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Credits Activity'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const CreditsList = ({ category }, { index, utils }) => {

    const panelID = `${category}Credits`;
    const limit = 5;

    const [credits, setCredits] = useState([]);
    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `${Utils.ucFirst(category)} Credits`,
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/credits/',  {
                    type: 'list',
                    category: category,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewCreditsAccount = () => {
        let card = CreditsCard.new();
        card.type = category;
        utils.layer.open({
            id: `add-new-credits-card-${category}`,
            abstract: Abstract.create({
                type: 'credits',
                object: card
            }),
            Component: AddNewCreditsCard.bind(this, {
                category: category,
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all reservations that had partial or full credits used during processing',
            items: [{
                key: 'download',
                title: `Download ${Utils.ucFirst(category)} Credits`,
                style: 'default'
            }]
        }
    }

    const getButtons = () => {
        let { level } = utils.user.get();
        if(category === 'company' && level !== User.level.admin) {
            return null;
        }
        return [{
            key: 'new',
            title: `New Credits Account`,
            style: 'default',
            onClick: onNewCreditsAccount
        }];
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(credits.length === 0) {
            return (
                Views.entry({
                    title: `No ${Utils.ucFirst(category)} Credits Accounts Found`,
                    subTitle: 'There are no credit accounts available to view'
                })
            )
        }
        return credits.map((credit, index) => {
            return (
                Views.entry({
                    key: credit.id,
                    title: credit.type === 'company' ? credit.company.name : credit.user.full_name,
                    subTitle: `Created: ${moment(credit.date).format('MMMM Do, YYYY [at] h:mma')}`,
                    badge: credit.card && [{
                        text: credit.active ? null : 'Deactivated',
                        color: Appearance.colors.red
                    },{
                        text: `Balance ${Utils.toCurrency(credit.card.balance)}`,
                        color: credit.card.balance < 0 ? Appearance.colors.red : Appearance.colors.grey()
                    }],
                    icon: {
                        style: Appearance.icons.standard(),
                        path: credit.type === 'company' ? credit.company.image : credit.user.avatar
                    },
                    bottomBorder: index != credits.length - 1,
                    onClick: Utils.credits.details.bind(this, utils, credit)
                })
            )
        });
    }

    const fetchCredits = async () => {
        try {
            let { credits, paging } = await Request.get(utils, '/credits/',  {
                type: 'list',
                limit: limit,
                category: category,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setCredits(credits.map(act => CreditsCard.create(act)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the ${category} credits list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchCredits();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'credits', {
            onFetch: fetchCredits,
            onRemove: abstract => {
                setCredits(credits => {
                    return credits.filter(act => act.id !== abstract.getID());
                });
            },
            onUpdate: abstract => {
                setCredits(credits => {
                    return credits.map(act => abstract.compare(act));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, [])

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${category === 'company' ? 'Company' : 'Customer'} Credits Accounts`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: getButtons(),
            search: {
                onChange: onSearchTextChange,
                placeholder: `Search by ${category === 'user' ? 'first or last name' : 'company name'}...`
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrdersWithCredits = ({ channel }, { index, utils }) => {

    const panelID = 'ordersWithCredits';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [orders, setOrders] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Orders with Credits',
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/credits/',  {
                    type: 'order_activity',
                    order_channel: channel.id,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all orders that had partial or full credits used during processing',
            items: [{
                key: 'download',
                title: 'Download Orders',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(orders.length === 0) {
            return (
                Views.entry({
                    title: 'No Orders Found',
                    subTitle: 'There are no orders available to view',
                    borderBottom: false
                })
            )
        }
        return orders.map((order, index) => {
            let { credits = {} } = order || {};
            let amount = credits.amount ? `${Utils.toCurrency(credits.amount)} of credits used` : 'Credits information not available';
            return (
                Views.entry({
                    key: order.id,
                    title: `${order.customer.full_name} (${order.id})`,
                    subTitle: amount,
                    badge: order.status,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: order.customer.avatar
                    },
                    bottomBorder: index !== orders.length - 1,
                    onClick: Utils.orders.details.bind(this, utils, order)
                })
            )
        });
    }

    const fetchOrders = async () => {
        try {
            let { orders, paging } = await Request.get(utils, '/credits/',  {
                type: 'order_activity',
                limit: limit,
                order_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setOrders(orders.map(order => Order.create(order)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the orders with credits list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOrders();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${channel.name} Orders with Credits`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by order id or customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const OrdersWithSubscriptions = ({ channel }, { index, utils }) => {

    const panelID = 'ordersWithSubscriptions';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [orders, setOrders] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Orders with Subscriptions',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/subscriptions/',  {
                    type: 'order_activity',
                    order_channel: channel.id,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all Orders that are taking advantage of a subscription',
            items: [{
                key: 'download',
                title: 'Download Orders',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(orders.length === 0) {
            return (
                Views.entry({
                    title: `No ${channel.name} Orders Found`,
                    subTitle: 'There are no orders available to view'
                })
            )
        }
        return orders.map((order, index) => {
            return (
                Views.entry({
                    key: order.id,
                    title: `${order.customer.full_name} (${order.id})`,
                    subTitle: order.subscription.plan.name,
                    badge: order.status,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: order.customer.avatar
                    },
                    bottomBorder: index !== orders.length - 1,
                    onClick: Utils.orders.details.bind(this, utils, order)
                })
            )
        });
    }

    const fetchOrders = async () => {
        try {
            let { orders, paging } = await Request.get(utils, '/subscriptions/',  {
                type: 'order_activity',
                limit: limit,
                order_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setOrders(orders.map(order => Order.create(order)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the orders with subscriptions list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOrders();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`${channel.name} Orders with Subscriptions`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by order id or customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const Payments = ({ index, utils }) => {

    const panelID = 'payments';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [payments, setPayments] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Payments',
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/payments/',  {
                    type: 'list',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text,
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all payments that have been processed. You can search for a payment by typing a customer name or reservation id into the search field. You can view a payment's information by clicking on it`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Payments'}`,
                style: 'default'
            }]
        }
    }

    const getBadge = payment => {
        if(payment.type === 'driver_tip') {
            return {
                text: `${Utils.toCurrency(payment.amount)} Driver Tip`,
                color: Appearance.colors.secondary()
            }
        }

        let amount = Utils.toCurrency(payment.amount);
        if(payment.data && payment.data.refund_amount) {
            amount = Utils.toCurrency(payment.data.refund_amount);
        } else if(payment.amount === 0) {
            amount = 'Covered';
        }

        return [{
            text: amount,
            color: payment.refunded ? Appearance.colors.grey() : Appearance.colors.primary()
        },{
            text: payment.refunded ? 'Refunded' : null,
            color: Appearance.colors.red
        }]
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        let targets = payments.filter(payment => payment.getOwner() ? true : false);
        if(targets.length === 0) {
            return (
                Views.entry({
                    title: 'No Payments Found',
                    subTitle: 'There are no payments available to view',
                    borderBottom: false
                })
            )
        }
        return targets.map((payment, index) => {
            let { object, type } = payment.getOwner();
            if(!object || !type) {
                return null;
            }
            return (
                Views.entry({
                    key: index,
                    title: type === 'user' ? object.full_name : object.name,
                    subTitle: getDescription(payment),
                    badge: getBadge(payment),
                    icon: {
                        style: Appearance.icons.standard(),
                        path: type === 'user' ? object.avatar : object.image
                    },
                    bottomBorder: index !== payments.length - 1,
                    onClick: Utils.payments.details.bind(this, utils, payment)
                })
            )
        });
    }

    const getDescription = payment => {
        if(payment.order) {
            return `${payment.order.channel.name} Order #${payment.order.id}`
        }
        if(payment.reservation) {
            return `Reservation #${payment.reservation.id}`
        }
        if(payment.subscription) {
            console.log(payment);
            return `${payment.subscription ? payment.subscription.plan.name : 'Unknown'} Subscription`
        }
        return payment.getType();
    }

    const fetchPayments = async () => {
        try {
            let { paging, payments } = await Request.get(utils, '/payments/',  {
                type: 'list',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setPayments(payments.map(payment => Payment.create(payment)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payments list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchPayments();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Payments'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by customer, order, or reservation, or payment id...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const PaymentDisputes = ({ index, utils }) => {

    const panelID = 'paymentDisputes';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [payments, setPayments] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Payment Disputes',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/payments/',  {
                    type: 'disputes',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all disputed payments that have been submitted by customers. A disputed payment is considered a scenario where a customer has contacted their bank and marked a transaction as fraudulent or unrecognized. You can search for a dispute by typing a customer name, Order ID, or Reservation ID into the search field. You can view a diputes\'s information by clicking on it',
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Disputes'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(payments.length === 0) {
            return (
                Views.entry({
                    title: 'No Disputes Found',
                    subTitle: 'There are no payment disputes available to view',
                    borderBottom: false
                })
            )
        }
        return payments.map((payment, index) => {
            return (
                Views.entry({
                    key: index,
                    title: payment.customer.full_name,
                    subTitle: getDescription(payment),
                    badge: [{
                        text: payment.dispute.status.text,
                        color: typeof(Appearance.colors[payment.dispute.status.color]) === 'function' ? Appearance.colors[payment.dispute.status.color]() : (Appearance.colors[payment.dispute.status.color] || Appearance.colors.grey())
                    },{
                        text: payment.dispute.reason.text,
                        color: Appearance.colors.grey()
                    }],
                    icon: {
                        style: Appearance.icons.standard(),
                        path: payment.customer.avatar
                    },
                    bottomBorder: index !== payments.length - 1,
                    onClick: Utils.payments.details.bind(this, utils, payment)
                })
            )
        });
    }

    const getDescription = payment => {
        if(payment.order) {
            return `${payment.order.channel.name} Order #${payment.order.id}`
        }
        if(payment.reservation) {
            return `Reservation #${payment.reservation.id}`
        }
        if(payment.subscription) {
            return `${payment.subscription ? payment.subscription.plan.name : 'Unknown'} Subscription`
        }
        return payment.getType();
    }

    const fetchDisputes = async () => {
        try {
            let { disputes, paging } = await Request.get(utils, '/payments/',  {
                type: 'disputes',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setPayments(disputes.map(dispute => Payment.create(dispute)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payment disputes list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchDisputes();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'payments', {
            onFetch: fetchDisputes,
            onUpdate: abstract => {
                setPayments(payments => {
                    return payments.map(payment => abstract.compare(payment));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Payment Disputes'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by id or customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const PaymentsMonthlyBreakdown = ({ index, utils }) => {

    const chartRef = useRef(null);
    const panelID = 'paymentsMothlyBreakdown';

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment().endOf('year'), start_date: moment().startOf('year') });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Monthly Payments Breakdown',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/payments/', {
                    type: 'total_month',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This graph shows the total income from the payments that were processed for each month. This calculation shows all processed payments including any refunds that may have been issued',
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div style={{
                padding: 12
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '100%',
                    height: 50,
                    paddingBottom: 25
                }}>
                    <select
                    className={`custom-select ${window.theme}`}
                    defaultValue={dates.start_date.format('YYYY')}
                    style={{
                        width: Utils.isMobile() ? '100%' : 85
                    }}
                    onChange={evt => {
                        let year = Utils.attributeForKey.select(evt, 'id');
                        setDates({
                            start_date: moment(`${year}-01-01`),
                            end_date: moment(`${year}-12-31`)
                        });
                    }}>
                        {chart && chart.years.map((year, index) => (
                            <option key={index} id={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div style={{
                    display: 'block',
                    height: 350
                }}>
                    {!chart || chart.labels.length < 3
                        ?
                        <NoDataFound message={'At least 3 entries of payment data are needed for the graph'}/>
                        :
                        <Bar
                        ref={chartRef}
                        width={500}
                        height={100}
                        data={{
                            labels: chart.labels,
                            datasets: [{
                                data: chart.dataset,
                                borderWidth: 2,
                                borderColor: ({ dataIndex }) => {
                                    return dataIndex % 2 === 0 ? Appearance.colors.primary() : Appearance.colors.secondary()
                                },
                                backgroundColor: ({ dataIndex }) => {
                                    let color = dataIndex % 2 === 0 ? Appearance.colors.primary() : Appearance.colors.secondary();
                                    return Utils.hexToRGBA(color, 0.5);
                                }
                            }]
                        }}
                        options={{
                            title: {
                                display: false
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: item => Utils.toCurrency(item.yLabel)
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: value => Utils.numberFormat(value)
                                    }
                                }]
                            }
                        }} />
                    }
                </div>
            </div>
        )
    }

    const fetchBreakdown = async () => {
        try {
            let months = moment(dates.end_date).diff(moment(dates.start_date), 'months') + 1;
            let { results } = await Request.get(utils, '/payments/', {
                end_date: moment(dates.end_date).utc().unix(),
                start_date: moment(dates.start_date).utc().unix(),
                type: 'total_month'
            });

            setLoading(false);
            setChart({
                labels: [...Array(months)].map((_, index) => moment(dates.start_date).add(index, 'months').format(months > 12 ? 'MM/YYYY' : 'MMMM')),
                dataset: [...Array(months)].map((_, index) => {
                    let entry = results.find(e => e.date === moment(dates.start_date).add(index, 'months').format('YYYY-MM'));
                    return entry ? entry.total : 0;
                }),
                years: [...Array(moment().diff(moment('2018-01-01'), 'years') + 1)].map((_, i) => {
                    return moment().startOf('year').subtract(i, 'years').format('YYYY');
                }),
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the monthly overview. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdown();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Monthly Payments Breakdown'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const PaymentPreferences = ({ index, utils }) => {

    const panelID = 'paymentPreferences';
    const [loading, setLoading] = useLoading();
    const [preferences, setPreferences] = useState(null);

    const onAssistClick = key => {
        if(key === 'edit') {
            onEditPreferences();
            return;
        }
    }

    const onEditPreferences = () => {
        utils.layer.open({
            id: 'edit-payment-preferences',
            Component: EditPaymentPreferences.bind(this, {
                preferences: preferences,
                onChange: setPreferences

            })
        });
    }

    const getAssistProps = () => {
        return {
            message: 'Payment preferences illustrate decisions you can make around when and how the system processes payments. This includes whether or not direct payments can be automatically transfered to one or more users.',
            items: [{
                key: 'edit',
                title: 'Edit Payment Preferences',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(!preferences) {
            return (
                Views.entry({
                    title: 'No Payment Preferences Found',
                    subTitle: 'We were unable to retrieve your platform payment preferences',
                    bottomBorder: false
                })
            )
        }
        return getItems().map((item, index, items) => {
            return (
                Views.entry({
                    key: index,
                    title: item.title,
                    subTitle: item.value,
                    icon: item.icon,
                    bottomBorder: index !== items.length - 1,
                    onClick: onEditPreferences
                })
            )
        })
    }

    const getItems = () => {
        return [{
            key: 'enabled',
            title: 'Direct Payments',
            value: preferences.direct_payments.enabled ? 'Enabled' : 'Not Enabled',
            icon: {
                path: preferences.direct_payments.enabled ? 'images/checkmark-green.png' : 'images/red-x-icon.png'
            }
        },{
            key: 'commission',
            title: 'Commission',
            value: preferences.direct_payments.commission.enabled ? 'Enabled' : 'Not Enabled',
            icon: {
                path: preferences.direct_payments.commission.enabled ? 'images/checkmark-green.png' : 'images/red-x-icon.png'
            }
        },{
            key: 'revenue_share',
            title: 'Referral Revenue Share',
            value: preferences.direct_payments.revenue_share.enabled ? 'Enabled' : 'Not Enabled',
            icon: {
                path: preferences.direct_payments.revenue_share.enabled ? 'images/checkmark-green.png' : 'images/red-x-icon.png'
            }
        },{
            key: 'order_commission',
            title: 'Delivery Commission',
            value: preferences.direct_payments ? `${preferences.direct_payments.commission.orders.percentage * 100}%` : null,
            icon: {
                path: 'images/payment-preferences-orders-clear.png',
                style: {
                    backgroundColor: preferences.direct_payments && preferences.direct_payments.commission.orders.percentage > 0 ? Appearance.colors.secondary() : Appearance.colors.grey()
                }
            }
        },{
            key: 'reservation_commission',
            title: 'Ride Commission',
            value: preferences.direct_payments ? `${preferences.direct_payments.commission.reservations.percentage * 100}%` : null,
            icon: {
                path: 'images/payment-preferences-reservations-clear.png',
                style: {
                    backgroundColor: preferences.direct_payments && preferences.direct_payments.commission.reservations.percentage > 0 ? Appearance.colors.primary() : Appearance.colors.grey()
                }
            }
        },{
            key: 'order_revenue_share',
            title: 'Delivery Referral Revenue Share',
            value: preferences.direct_payments ? `${preferences.direct_payments.revenue_share.orders.percentage * 100}%` : null,
            icon: {
                path: 'images/payment-preferences-orders-clear.png',
                style: {
                    backgroundColor: preferences.direct_payments && preferences.direct_payments.revenue_share.orders.percentage > 0 ? Appearance.colors.secondary() : Appearance.colors.grey()
                }
            }
        },{
            key: 'reservation_revenue_share',
            title: 'Ride Referral Revenue Share',
            value: preferences.direct_payments ? `${preferences.direct_payments.revenue_share.reservations.percentage * 100}%` : null,
            icon: {
                path: 'images/payment-preferences-reservations-clear.png',
                style: {
                    backgroundColor: preferences.direct_payments && preferences.direct_payments.revenue_share.reservations.percentage > 0 ? Appearance.colors.primary() : Appearance.colors.grey()
                }
            }
        }]
    }

    const fetchPreferences = async () => {
        try {
            let { preferences } = await Request.get(utils, '/resources/', {
                type: 'platform_payment_preferences'
            });
            setLoading(false);
            setPreferences(preferences);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the payment preferences list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }
    useEffect(() => {
        fetchPreferences();
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Payment Preferences'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const PromoCodes = ({ index, utils }) => {

    const panelID = 'promotions';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [promo_codes, setPromoCodes] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'new') {
            onNewCode();
            return;
        }
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Promotions',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/promotions/',  {
                    type: 'list',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    const onNewCode = () => {
        utils.layer.open({
            id: 'new-promo-code',
            abstract: Abstract.create({
                type: 'promotions',
                object: PromoCode.new()
            }),
            Component: AddEditPromoCode.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the promotions that are in the system',
            items: [{
                key: 'download',
                title: 'Download Promotions',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(promo_codes.length === 0) {
            return (
                Views.entry({
                    key: 'none-found',
                    title: 'No Promotions Found',
                    subTitle: 'There are no promotions available to view',
                    borderBottom: false
                })
            )
        }
        return promo_codes.map((code, index, codes) => {
            return (
                Views.entry({
                    key: index,
                    title: `${code.title} (${code.code})`,
                    subTitle: code.description,
                    badge: !code.active && {
                        text: 'Not Active',
                        color: Appearance.colors.lightGrey
                    },
                    bottomBorder: index != codes.length - 1,
                    onClick: Utils.promotions.details.bind(this, utils, code)
                })
            )
        });
    }

    const fetchPromoCodes = async () => {
        try {
            let { paging, promo_codes } = await Request.get(utils, '/promotions/',  {
                type: 'list',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setPromoCodes(promo_codes.map(code => PromoCode.create(code)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the promo codes list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchPromoCodes();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Promotions'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Promotion',
                style: 'default',
                onClick: onNewCode
            }],
            search: {
                placeholder: 'Search by name or redemption code...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const PromoCodesActivity = ({ index, utils }) => {

    const chartRef = useRef(null);
    const panelID = 'promoCodesActivity';

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(6, 'months') });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Promotions Activity',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/promotions/',  {
                    type: 'activity',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    const getAssistProps = () => {
        return {
            message: 'The graph shows the overall promo code usage sorted by the month they were used. The left side shows the amount of codes used and the bottom shows the month they were used. The graph shows the last 6 months by default',
            items: [{
                key: 'download',
                title: 'Download Activity',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                padding: 12,
                borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
            }}>
                <DualDatePickerField
                utils={utils}
                selectedStartDate={dates.start_date}
                selectedEndDate={dates.end_date}
                style={{
                    maxWidth: Utils.isMobile() === false ? 350 : null
                }}
                onStartDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            start_date: date
                        }
                    })
                }}
                onEndDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            end_date: date
                        }
                    })
                }} />
            </div>
            <div style={{
                position: 'relative',
                padding: 12,
                height: 350
            }}>
                {!chart || chart.labels.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of activity are needed for the graph'}/>
                    :
                    <Line
                    ref={chartRef}
                    width={500}
                    height={100}
                    data={{
                        labels: chart.labels,
                        datasets: [{
                            data: chart.data,
                            borderColor: Appearance.colors.primary(),
                            fill: true,
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                            pointRadius: 5,
                            pointBorderWidth: 2,
                            pointBackgroundColor: 'white'
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => Utils.toCurrency(tooltipItem.yLabel)
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value, index, values) => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
            <div
            className={'row m-0'}
            style={{
                padding: 12
            }}>
                {chart && chart.items.map((item, index) => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-md-4 p-1 text-center'}>
                            <div style={{
                                ...Appearance.styles.unstyledPanel(),
                                padding: 8
                            }}>
                                <img
                                src={`/images/${item.image}`}
                                className={'my-1'}
                                style={{
                                    ...Appearance.icons.overview(),
                                    backgroundColor: index == 1 ? Appearance.colors.secondary() : Appearance.colors.primary()
                                }} />
                                <span
                                className={'d-block mt-2'}
                                style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                <span
                                className={'d-block'}
                                style={Appearance.textStyles.subTitle()}>{item.title}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const fetchBreakdown = async () => {
        try {
            let response = await Request.get(utils, '/promotions/',  {
                end_date: moment(dates.end_date).utc().unix(),
                start_date: moment(dates.start_date).utc().unix(),
                type: 'activity'
            });

            setLoading(false);
            setChart({
                data: response.dates.map(entry => entry.total),
                labels: response.dates.map(entry => moment.utc(entry.date).local().format('MMM Do')),
                items: [{
                    key: 'active',
                    title: 'Active Promotions',
                    placeholder: '0',
                    value: response.active,
                    image: 'promo-code-large-clear.png'
                },{
                    key: 'discounts',
                    title: 'Discounts Given',
                    placeholder: '0',
                    value: response.used,
                    image: 'discounts-given-clear.png'
                },{
                    key: 'popular',
                    title: 'Most Popular Code',
                    placeholder: 'Unknown',
                    value: response.popular && response.popular.title,
                    image: 'popular-icon-clear.png'
                }]
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the promo codes activity breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdown();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Promotions Activity'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationsWithCredits = ({ index, utils }) => {

    const panelID = 'reservationsWithCredits';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Reservations with Credits',
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/credits/',  {
                    type: 'reservation_activity',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all Reservations that had partial or full credits used during processing',
            items: [{
                key: 'download',
                title: 'Download Reservations',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(reservations.length === 0) {
            return (
                Views.entry({
                    title: 'No Reservations Found',
                    subTitle: 'There are no reservations available to view',
                    borderBottom: false
                })
            )
        }
        return reservations.map((reservation, index) => {
            let amount = reservation.credits.amount ? `${Utils.toCurrency(reservation.credits.amount)} of credits used` : reservation.credits.charge ? `${Utils.toCurrency(reservation.credits.charge.amount)} of credits used` : 'Credits information not available';
            return (
                Views.entry({
                    key: reservation.id,
                    title: `${reservation.customer.full_name} (${reservation.id})`,
                    subTitle: amount,
                    badge: reservation.status,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: reservation.customer.avatar
                    },
                    bottomBorder: index != reservations.length - 1,
                    onClick: Utils.reservations.details.bind(this, utils, reservation)
                })
            )
        });
    }

    const fetchReservations = async download => {
        try {
            let { paging, reservations } = await Request.get(utils, '/credits/',  {
                type: 'reservation_activity',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setReservations(reservations.map(reservation => Reservation.create(reservation)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the reservations with credits list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchReservations();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Reservations with Credits'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by reservation id or customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const ReservationsWithSubscriptions = ({ index, utils }) => {

    const panelID = 'reservationsWithSubscriptions';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Reservations with Subscriptions',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/subscriptions/',  {
                    type: 'reservation_activity',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all Reservations that are taking advantage of a subscription',
            items: [{
                key: 'download',
                title: 'Download Reservations',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(reservations.length === 0) {
            return (
                Views.entry({
                    title: 'No Reservations Found',
                    subTitle: 'There are no reservations available to view',
                    borderBottom: false
                })
            )
        }
        return reservations.map((reservation, index) => {
            return (
                Views.entry({
                    key: reservation.id,
                    title: `${reservation.customer.full_name} (${reservation.id})`,
                    subTitle: reservation.subscription ? reservation.subscription.plan.name : 'Plan name not available',
                    badge: reservation.status,
                    icon: {
                        style: Appearance.icons.standard(),
                        path: reservation.customer.avatar
                    },
                    bottomBorder: (index != reservations.length - 1),
                    onClick: Utils.reservations.details.bind(this, utils, reservation)
                })
            )
        });
    }

    const fetchReservations = async download => {
        try {
            let { paging, reservations } = await Request.get(utils, '/subscriptions/',  {
                type: 'reservation_activity',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setReservations(reservations.map(reservation => Reservation.create(reservation)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the reservations with subscriptions list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }
    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchReservations();
    }, [manager]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Reservations with Subscriptions'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by reservation id or customer...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const Subscriptions = ({ index, utils }) => {

    const panelID = 'subscriptions';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Subscriptions',
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/subscriptions/',  {
                    type: 'all_admin',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewSubscription = () => {
        utils.layer.open({
            id: 'new-subscription',
            abstract: Abstract.create({
                type: 'subscriptions',
                object: Subscription.new()
            }),
            Component: NewSubscription
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the customer Subscriptions that are in the system. A subscription is an agreement to honor a set of discounts for a specific account for Order and Reservation bookings.',
            items: [{
                key: 'download',
                title: 'Download Subscriptions',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(subscriptions.length === 0) {
            return (
                Views.entry({
                    title: 'No Subscriptions Found',
                    subTitle: 'There are no subscriptions available to view',
                    borderBottom: false
                })
            )
        }
        return subscriptions.map((subscription, index) => {
            return (
                Views.entry({
                    key: index,
                    title: `${subscription.customer.full_name} (${subscription.id})`,
                    subTitle: subscription.plan.name,
                    icon: {
                        path: subscription.customer.avatar
                    },
                    badge: {
                        text: subscription.active ? null : 'Not Active',
                        color: Appearance.colors.lightGrey
                    },
                    bottomBorder: (index != subscriptions.length - 1),
                    onClick: Utils.subscriptions.details.bind(this, utils, subscription)
                })
            )
        });
    }

    const fetchSubscriptions = async () => {
        try {
            let { paging, subscriptions } = await Request.get(utils, '/subscriptions/',  {
                type: 'all_admin',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setSubscriptions(subscriptions.map(subscription => Subscription.create(subscription)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the subscriptions list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchSubscriptions();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, [ 'subscriptions', 'subscriptionPlans' ], {
            onFetch: fetchSubscriptions,
            onUpdate: abstract => {
                setSubscriptions(subscriptions => {
                    if(!subscriptions) {
                        return subscriptions;
                    }
                    let index = subscriptions.findIndex(s => {
                        switch(abstract.type) {
                            case 'subscriptions':
                            return s.id === abstract.getID();

                            case 'subscriptionPlans':
                            return s.plan.id === abstract.getID();
                        }
                        return false;
                    });
                    if(index < 0) {
                        return subscriptions;
                    }
                    switch(abstract.type) {
                        case 'subscriptions':
                        return update(subscriptions, {
                            [index]: {
                                $set: abstract.object
                            }
                        })

                        case 'subscriptionPlans':
                        return update(subscriptions, {
                            [index]: {
                                plan: {
                                    $set: abstract.object
                                }
                            }
                        })
                        default:
                        return subscriptions;
                    }
                })

            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Subscriptions'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Subscription',
                style: 'default',
                onClick: onNewSubscription
            }],
            search: {
                placeholder: 'Search by id, customer, or subscription name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SubscriptionActivity = ({ index, utils }) => {

    const chartRef = useRef(null);
    const panelID = 'subscriptionActivity';

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(6, 'months') });
    const [loading, setLoading] = useLoading();

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Subscriptions Activity',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.post(utils, '/subscriptions/',  {
                    type: 'activity',
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: 'The graph shows the overall subscription usage sorted by the month they were used. The left side shows the amount of subscriptions used and the bottom shows the month they were used. The graph shows the last 6 months by default',
            items: [{
                key: 'download',
                title: 'Download Activity',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                padding: 12,
                borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
            }}>
                <DualDatePickerField
                utils={utils}
                selectedStartDate={dates.start_date}
                selectedEndDate={dates.end_date}
                style={{
                    maxWidth: Utils.isMobile() === false ? 350 : null
                }}
                onStartDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            start_date: date
                        }
                    })
                }}
                onEndDateChange={date => {
                    setDates(dates => {
                        return {
                            ...dates,
                            end_date: date
                        }
                    })
                }} />
            </div>
            <div style={{
                position: 'relative',
                height: 350,
                padding: 12
            }}>
                {!chart || chart.labels.length < 3
                    ?
                    <NoDataFound message={'At least 3 entries of activity are needed for the graph'}/>
                    :
                    <Line
                    ref={chartRef}
                    width={500}
                    height={100}
                    data={{
                        labels: chart.labels,
                        datasets: [{
                            data: chart.data,
                            borderColor: Appearance.colors.primary(),
                            fill: true,
                            backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                            pointRadius: 5,
                            pointBorderWidth: 2,
                            pointBackgroundColor: 'white'
                        }]
                    }}
                    options={{
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return `${tooltipItem.yLabel} ${parseInt(tooltipItem.yLabel) === 1 ? 'subscription' : 'subscriptions'} used`;
                                }
                            }
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 20
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    color: Appearance.colors.transparent,
                                    display: false
                                },
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value, index, values) => Utils.numberFormat(value)
                                }
                            }]
                        }
                    }} />
                }
            </div>
            <div
            className={'row m-0'}
            style={{
                padding: 12
            }}>
                {chart && chart.items.map((item, index) => {
                    return (
                        <div
                        key={item.key}
                        className={'col-12 col-md-4 p-1 text-center'}>
                            <div style={{
                                ...Appearance.styles.unstyledPanel(),
                                padding: 8
                            }}>
                                <img
                                className={'my-1'}
                                src={`/images/${item.image}`}
                                style={{
                                    ...Appearance.icons.overview(),
                                    backgroundColor: index == 1 ? Appearance.colors.secondary() : Appearance.colors.primary()
                                }} />
                                <span
                                className={'d-block mt-2'}
                                style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                <span
                                className={'d-block'}
                                style={Appearance.textStyles.subTitle()}>{item.title}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
        )
    }

    const fetchBreakdown = async () => {
        try {
            let response = await Request.get(utils, '/subscriptions/',  {
                end_date: moment(dates.end_date).utc().unix(),
                start_date: moment(dates.start_date).utc().unix(),
                type: 'activity'
            });

            setLoading(false);
            setChart({
                data: response.usage.map(entry => entry.total),
                labels: response.usage.map(entry => moment(entry.date, 'YYYY-MM-DD').format('MMM Do')),
                items: [{
                    key: 'active',
                    title: 'Active Subscriptions',
                    placeholder: '0',
                    value: response.active.length,
                    image: 'promo-code-large-clear.png'
                },{
                    key: 'discounts',
                    title: 'Subscriptions Used',
                    placeholder: '0',
                    value: response.used,
                    image: 'discounts-given-clear.png'
                },{
                    key: 'popular',
                    title: 'Most Popular Subscription',
                    placeholder: 'Unknown',
                    value: response.popular ? response.popular.name : null,
                    image: 'popular-icon-clear.png'
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the subscriptions activity. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchBreakdown();
    }, [dates]);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Subscription Activity'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SubscriptionPlans = ({ index, utils }) => {

    const panelID = 'subscriptionPlans';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [plans, setPlans] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Subscription Plans',
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/subscriptions/',  {
                    type: 'all_plans_admin',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onNewPlan = () => {
        utils.layer.open({
            id: 'new-subscription-plan',
            abstract: Abstract.create({
                type: 'subscriptionPlans',
                object: Subscription.Plan.new()
            }),
            Component: AddEditSubscriptionPlan.bind(this, {
                isNewTarget: true
            })
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const onSubscriptionPlanClick = plan => {
        utils.layer.open({
            id: `subscription-plan-details-${plan.id}`,
            abstract: Abstract.create({
                type: 'subscriptionPlans',
                object: plan
            }),
            Component: SubscriptionPlanDetails
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all the Subscription Plans that are in the system',
            items: [{
                key: 'download',
                title: 'Download Subscription Plans',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(plans.length === 0) {
            return (
                Views.entry({
                    title: 'No Subscription Plans Found',
                    subTitle: 'There are no subscription plans available to view',
                    borderBottom: false
                })
            )
        }
        return plans.map((plan, index) => {
            return (
                Views.entry({
                    key: index,
                    title: plan.name,
                    subTitle: plan.description,
                    badge: {
                        text: plan.active ? null : 'Not Active',
                        color: Appearance.colors.lightGrey
                    },
                    icon: {
                        path: plan.image,
                        ...Appearance.icons.padded({ backgroundColor: Appearance.colors.primary() })
                    },
                    bottomBorder: index !== plans.length - 1,
                    onClick: onSubscriptionPlanClick.bind(this, plan)
                })
            )
        });
    }

    const fetchSubscriptions = async () => {
        try {
            let { paging, subscriptions } = await Request.get(utils, '/subscriptions/',  {
                type: 'all_plans_admin',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setPlans(subscriptions.map(plan => Subscription.Plan.create(plan)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the subscription plans list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchSubscriptions();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, 'subscriptionPlans', {
            onFetch: fetchSubscriptions,
            onUpdate: abstract => {
                setPlans(plans => {
                    return plans.map(plan => abstract.compare(plan));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Subscription Plans'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            buttons: [{
                key: 'new',
                title: 'New Subscription Plan',
                style: 'default',
                onClick: onNewPlan
            }],
            search: {
                placeholder: 'Search by id or name...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const SubscriptionPlansAcitivty = ({ index, utils }) => {

    const chartRef = useRef(null);
    const panelID = `subscriptionPlansActivity`;

    const [chart, setChart] = useState(null);
    const [dates, setDates] = useState({ end_date: moment(), start_date: moment().subtract(6, 'months') });
    const [loading, setLoading] = useLoading();
    const [plans, setPlans] = useState([]);
    const [plan, setPlan] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Subscription Plans Activity',
                chart: chartRef,
                dates: dates,
                extendedFeatures: [ 'chart', 'date_range' ],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/subscriptions/',  {
                    type: 'plan_activity',
                    plan_id: plan ? plan.id : null,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const getAssistProps = () => {
        return {
            message: `This graph shows the usage for the selected subscription plan within the specified date range. The date range defaults to 6 months in the past to today's date`,
            items: [{
                key: 'download',
                title: 'Download Breakdown',
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        return (
            <div
            className={'row p-0 m-0'}
            style={{
                padding: 12
            }}>
                <div
                className={'col-12 col-xl-8 p-3'}
                style={{
                    height: 350,
                    borderBottom: Utils.isMobile() ? `1px solid ${Appearance.colors.divider()}` : null
                }}>
                    {!chart || chart.labels.length < 3
                        ?
                        <NoDataFound message={'At least 3 entries of usage are needed for the graph'}/>
                        :
                        <Line
                        ref={chartRef}
                        data={chart}
                        width={400}
                        height={100}
                        options={{
                            title: {
                                display: false
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            tooltips: {
                                callbacks: {
                                    label: (tooltipItem, data) => {
                                        return `Used ${parseInt(tooltipItem.yLabel)} ${parseInt(tooltipItem.yLabel) === 1 ? 'time' : 'times'}`
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                }],
                                yAxes: [{
                                    gridLines: {
                                        color: Appearance.colors.transparent,
                                        display: false
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        callback: (value, index, values) => Utils.numberFormat(value)
                                    }
                                }]
                            }
                        }}/>
                    }
                </div>
                <div
                className={'col-12 col-xl-4'}
                style={{
                    padding: '8px 12px 8px 12px',
                    borderLeft: Utils.isMobile() ? null : `1px solid ${Appearance.colors.divider()}`
                }}>
                    <div style={{
                        marginBottom: 8
                    }}>
                        <span style={Appearance.textStyles.title()}>{'Subscription Plan'}</span>
                        <select
                        className={`mt-1 mb-3 custom-select ${window.theme}`}
                        onChange={(e) => {
                            let id = Utils.attributeForKey.select(e, 'id');
                            setPlan(plans.find(plan => plan.id === parseInt(id)));
                        }}>
                            {plans.length > 0 && (
                                plans.map((plan, index) => {
                                    return (
                                        <option key={index} id={plan.id}>{plan.name}</option>
                                    )
                                })
                            )}
                            {plans.length === 0 && (
                                <option disabled={true}>{'No Subscription Plans Found'}</option>
                            )}
                        </select>
                    </div>
                    <div style={{
                        marginBottom: 8
                    }}>
                        <span style={{
                            ...Appearance.textStyles.title(),
                            display: 'block',
                            marginBottom: '.25rem'
                        }}>{'Date Range'}</span>
                        <DualDatePickerField
                        utils={utils}
                        selectedStartDate={dates.start_date}
                        selectedEndDate={dates.end_date}
                        style={{
                            width: '100%'
                        }}
                        fieldStyle={{
                            width: '100%'
                        }}
                        onStartDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    start_date: date
                                }
                            })
                        }}
                        onEndDateChange={date => {
                            setDates(dates => {
                                return {
                                    ...dates,
                                    end_date: date
                                }
                            })
                        }} />
                    </div>
                </div>
            </div>
        )
    }

    const fetchActivity = async () => {
        if(!plan) {
            return;
        }
        try {
            let { usage } = await Request.get(utils, '/subscriptions/',  {
                end_date: moment(dates.end_date).utc().unix(),
                plan_id: plan && plan.id,
                start_date: moment(dates.start_date).utc().unix(),
                type: 'plan_activity'
            });

            setLoading(false);
            setChart({
                labels: usage.map(entry => {
                    return moment(entry.date).isSame(moment(), 'year') ? moment(entry.date).format('MMM Do') : moment(entry.date).format('MMM Do YYYY')
                }),
                datasets: [{
                    data: usage.map(entry => entry.total),
                    fill: true,
                    pointRadius: 5,
                    pointBorderWidth: 2,
                    pointBackgroundColor: 'white',
                    borderColor: Appearance.colors.primary(),
        			backgroundColor: Utils.hexToRGBA(Appearance.colors.primary(), 0.25),
                }]
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the subscription plan breakdown. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchSubscriptions = async () => {
        try {
            let { subscriptions } = await Request.get(utils, '/subscriptions/',  {
                type: 'all_plans_admin'
            });

            let targets = subscriptions.map(plan => Subscription.Plan.create(plan));
            setLoading(false);
            setPlans(targets);
            setPlan(targets.length > 0 ? targets[0] : null);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the subscription plans list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchActivity();
    }, [plan, dates]);

    useEffect(() => {
        fetchSubscriptions();
        utils.content.subscribe(panelID, 'subscriptionPlans', {
            onFetch: () => {
                fetchActivity();
                fetchSubscriptions();
            },
            onUpdate: abstract => {
                setPlans(plans => {
                    return plans.map(plan => abstract.compare(plan));
                });
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Subscription Plans Activity'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            removeOverflow: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const UnpaidOrders = ({ channel }, { index, utils }) => {

    const panelID = `unpaidOrders${channel.id}`;
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [orders, setOrders] = useState([]);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: `Unpaid ${channel.name} Orders`,
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/payments/',  {
                    type: 'unpaid_orders_admin',
                    order_channel: channel.id,
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: `This list shows all completed but unpaid ${channel.name} orders. You can process the payment for the order by clicking on it`,
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : `${channel.name} Orders`}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(orders.length === 0) {
            return (
                Views.entry({
                    title: `No Unpaid ${channel.name} Orders Found`,
                    subTitle: `There are no unpaid ${channel.name} orders available to view`
                })
            )
        }
        return orders.map((order, index) => {
            return (
                Views.entry({
                    key: order.id,
                    title: order.customer ? order.customer.full_name : 'Name Unavailable',
                    subTitle: `${channel.name} Order #${order.id}`,
                    badge: {
                        text: Utils.toCurrency(order.unpaid),
                        color: Appearance.colors.grey()
                    },
                    icon: order.customer && {
                        style: Appearance.icons.standard(),
                        path: order.customer.avatar
                    },
                    bottomBorder: (index !== orders.length - 1),
                    onClick: Utils.orders.details.bind(this, utils, order)
                })
            )
        });
    }

    const fetchOrders = async () => {
        try {
            let { orders, paging } = await Request.get(utils, '/payments/',  {
                type: 'unpaid_orders_admin',
                limit: limit,
                order_channel: channel.id,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setOrders(orders.map(order => Order.create(order)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the unpaid ${channel.name} orders list. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchOrders();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, [ 'payments', 'orders' ], {
            onFetch: fetchOrders,
            onUpdate: abstract => {
                if(abstract.type !== 'orders') {
                    return;
                }
                setOrders(orders => {
                    return orders.map(order => abstract.compare(order));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={`Unpaid ${channel.name} Orders`}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by customer or reservation id...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

export const UnpaidReservations = ({ index, utils }) => {

    const panelID = 'unpaidReservations';
    const limit = 5;

    const [loading, setLoading] = useLoading();
    const [manager, setManager] = useResultsManager();
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [reservations, setReservations] = useState([]);

    const onAssistClick = key => {
        if(key === 'download') {
            Utils.downloads.content(utils, {
                id: panelID,
                title: 'Unpaid Reservations',
                extendedFeatures: ['date_range'],
                onExport: onDownloadContent
            });
            return;
        }
    }

    const onDownloadContent = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/payments/',  {
                    type: 'unpaid_admin',
                    ...manager,
                    ...props
                });
                Utils.handleDownload(response);
                resolve();
            } catch(e) {
                reject(e);
            }
        });
    }

    const onSearchTextChange = text => {
        setLoading(true);
        setManager({
            offset: 0,
            search_text: text
        });
    }

    const getAssistProps = () => {
        return {
            message: 'This list shows all completed but unpaid reservations. You can process the payment for the reservation by clicking on it',
            items: [{
                key: 'download',
                title: `Download ${searchText ? 'Search Results' : 'Reservations'}`,
                style: 'default'
            }]
        }
    }

    const getContent = () => {
        if(loading === 'init') {
            return Views.loader();
        }
        if(reservations.length === 0) {
            return (
                Views.entry({
                    title: 'No Unpaid Reservations Found',
                    subTitle: 'There are no unpaid reservations available to view',
                    borderBottom: false
                })
            )
        }
        return reservations.map((reservation, index) => {
            return (
                Views.entry({
                    key: reservation.id,
                    title: reservation.customer ? reservation.customer.full_name : 'Name Unavailable',
                    subTitle: `Reservation #${reservation.id}`,
                    badge: {
                        text: reservation.status.realText,
                        color: reservation.status.color
                    },
                    icon: reservation.customer && {
                        style: Appearance.icons.standard(),
                        path: reservation.customer.avatar
                    },
                    bottomBorder: index !== reservations.length - 1,
                    onClick: Utils.reservations.details.bind(this, utils, reservation)
                })
            )
        })
    }

    const fetchReservations = async () => {
        try {
            let { paging, reservations } = await Request.get(utils, '/payments/',  {
                type: 'unpaid_admin',
                limit: limit,
                ...manager
            });

            setLoading(false);
            setPaging(paging);
            setReservations(reservations.map(reservation => Reservation.create(reservation)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the unpaid reservations list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(loading !== 'init') {
            setLoading(true);
        }
        fetchReservations();
    }, [manager]);

    useEffect(() => {
        utils.content.subscribe(panelID, [ 'payments', 'reservations' ], {
            onFetch: fetchReservations,
            onUpdate: abstract => {
                if(abstract.type !== 'reservations') {
                    return;
                }
                setReservations(reservations => {
                    return reservations.map(reservation => abstract.compare(reservation));
                });
            }
        })
        return () => {
            utils.content.unsubscribe(panelID);
        }
    }, []);

    return (
        <Panel
        key={panelID}
        panelID={panelID}
        name={'Unpaid Reservations'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            removePadding: true,
            assist: {
                props: getAssistProps(),
                onClick: onAssistClick
            },
            search: {
                placeholder: 'Search by customer or reservation id...',
                onChange: onSearchTextChange
            },
            paging: paging && {
                limit: limit,
                offset: manager.offset,
                description: paging,
                onClick: next => {
                    setLoading(true);
                    setManager('offset', next);
                }
            }
        }}>
            {getContent()}
        </Panel>
    )
}

// layers
export const AddCredits = ({ abstract, index, options, utils }) => {

    const layerID = `add-credits-${abstract.getID()}`;
    const [amount, setAmount] = useState('0.00');
    const [creditsCard, setCreditsCard] = useState(abstract.object);
    const [dates, setDates] = useState({ start: null, end: null });
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState(null);
    const [notes, setNotes] = useState(null);

    const onAddCredits = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            // require at least $0.50 for each transaction
            if(isNaN(amount) || amount <= 0.50) {
                throw new Error('Please choose an amount greater than $0.50');
            }

            // require that notes are added before moving on
            if(!notes) {
                throw new Error('Notes must be added to explain why these credits were added. Notes are visible by anyone with access to this credits account');
            }

            // require a payment method if user is not an administrator
            if(!method && utils.user.get().level > User.level.admin) {
                throw new Error('Please choose a payment method to pay for these credits');
            }

            // send request to server
            let { card } = await Request.post(utils, '/credits/', {
                type: 'add',
                amount: amount,
                category: creditsCard.type,
                end_date: dates.end && moment(dates.end).utc().unix(),
                notes: notes,
                source_id: method && method.id,
                start_date: dates.start && moment(dates.start).utc().unix(),
                stripe_id: creditsCard.type === 'company' ? creditsCard.company.stripe_customer_id : creditsCard.user.stripe_customer_id,
                ...creditsCard.type === 'company' && {
                    company_id: creditsCard.company.id
                },
                ...creditsCard.type === 'user' && {
                    user_id: creditsCard.user.user_id
                }
            });

            // update abstract target with new card details
            abstract.object.card = {
                ...abstract.object.card,
                ...card
            };

            // notify subscribers of content change
            utils.content.update(abstract);
            utils.content.fetch('credits');

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `${Utils.toCurrency(amount)} worth of credits has been added to ${abstract.getTitle()}. ${dates.start ? `These credits will be available starting ${Utils.formatDate(dates.start)}.` : ''} ${dates.end ? `These credits will expire on ${Utils.formatDate(dates.end)}.` : ''}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding the credits to this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = async () => {
        if(!method || !amount) {
            onAddCredits();
            return;
        }
        utils.alert.show({
            title: 'Buy Credits',
            message: `Are you sure that you want to charge ${Utils.toCurrency(amount)} worth of credits to the selected card?`,
            buttons: [{
                key: 'confirm',
                title: 'Confirm',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Do Not Charge',
                style: 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onAddCredits();
                    return;
                }
            }
        });
    }

    return (
        <Layer
        id={layerID}
        title={`Add Credits to ${abstract.getTitle()}`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onSubmit
        }]}>

            <LayerItem
            title={'Amount'}
            shouldStyle={false}>
                <TextField
                prepend={'$'}
                value={amount}
                onChange={text => setAmount(text)}/>
            </LayerItem>

            <LayerItem
            shouldStyle={false}
            title={`Customer Payment Method ${utils.user.get().level <= User.level.admin ? '(optional)' : ''}`}>
                <PaymentMethodManager
                utils={utils}
                onChange={setMethod}
                target={Abstract.create({
                    type: abstract.object.type === 'company' ? 'companies' : 'users',
                    object: abstract.object.type === 'company' ? abstract.object.company : abstract.object.user
                })} />
            </LayerItem>

            <LayerItem
            shouldStyle={false}
            title={'Start Date (optional)'}>
                <DatePickerField
                utils={utils}
                removeable={true}
                selected={dates.start}
                filterDateValue={moment().startOf('day')}
                filterDate={date => date >= moment().startOf('day')}
                onDateChange={date => {
                    setDates(dates => update(dates, {
                        start: {
                            $set: date
                        }
                    }))
                }}
                onRemoveDate={date => {
                    setDates(dates => update(dates, {
                        start: {
                            $set: date
                        }
                    }))
                }}/>
            </LayerItem>

            <LayerItem
            shouldStyle={false}
            title={'End Date (optional)'}>
                <DatePickerField
                utils={utils}
                removeable={true}
                selected={dates.end}
                filterDateValue={moment().startOf('day')}
                filterDate={date => date >= moment().startOf('day')}
                onDateChange={date => {
                    setDates(dates => update(dates, {
                        end: {
                            $set: date
                        }
                    }))
                }}
                onRemoveDate={date => {
                    setDates(dates => update(dates, {
                        end: {
                            $set: date
                        }
                    }))
                }} />
            </LayerItem>

            <LayerItem
            shouldStyle={false}
            title={'Notes'}>
                <TextView
                value={notes}
                useDelay={true}
                onChange={text => setNotes(text)}
                fieldStyle={{
                    height: 200
                }}/>
            </LayerItem>
        </Layer>
    )
}

export const AddEditCommunityAccessPoint = ({ isNewTarget, onCreate }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new_community_access_point' : `edit_community_access_point_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null);

    const onSubmit = async () => {

        // declare required values
        let required = [{
            title: 'name',
            object: location.name
        },{
            title: 'address',
            object: location.address
        }];

        // prevent moving forward if an item was not provided
        let remaining = required.filter(entry => !entry.object)
        if(remaining.length > 0) {
            utils.alert.show({
                title: 'Oops!',
                message: `Please fill out the "${remaining[0].title}" before moving on`
            })
            return;
        }

        try {

            // submit request to the server
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.apply(utils, isNewTarget);

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The community access point for ${abstract.object.name || abstract.object.address} has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: setLayerState.bind(this, 'close')
            });

            // notify subscribers if applicable
            if(isNewTarget && typeof(onCreate) === 'function') {
                onCreate(abstract.object);
            }
            if(isNewTarget === false) {
                utils.content.update(abstract);
            }

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this community access point. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setLocation(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!location) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for an access point is used to identify this location across the entire platform. This name may be shown to customers as a line item when their ride or order interacts with this location.',
                value: location.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'address',
                title: 'Address',
                description: 'The address for an access point is used to identify where this location exists in the world. This address may be shown to customers as a line item when their ride or order interacts with this location.',
                value: location.address,
                component: 'address_lookup',
                onChange: place => {
                    onUpdateTarget({
                        address: place && Utils.formatAddress(place.address),
                        location: place && place.location
                    });
                }
            },{
                key: 'radius',
                title: 'Radius',
                description: 'We use the radius to draw a circle around the center of your access point. Rides and orders that fall within this radius will be considered "inside the operating area" and will allow company users to utilize the community pool of credits. The radius for a location is measured in feet.',
                value: location.radius,
                component: 'number_stepper',
                onChange: val => onUpdateTarget({ radius: val })
            }]
        }];
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setLocation(edits);
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Community Access Point' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setLocation(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddEditPromoCode = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? 'new-promo-code' : `edit-promo-code-${abstract.getID()}`;
    const [available, setAvailable] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState(null);

    const types = [{
        text: 'Fixed Cost',
        code: PromoCode.types.fixed_cost
    },{
        text: 'Precentage',
        code: PromoCode.types.percentage
    },{
        text: 'Full Cost',
        amount: '100%',
        code: PromoCode.types.full_cost
    },{
        text: 'Credits Redemption',
        code: PromoCode.types.credits
    }];

    const onChangePromoCode = async code => {
        try {
            if(!code || code === abstract.object.code) {
                setAvailable(true);
                return;
            }
            let { available } = await Request.get(utils, '/promotions/',  {
                type: 'available',
                code: code
            });
            setAvailable(available || false);
            onUpdateTarget({ code: code });

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue checking if this code is available. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.title}" promo code has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this promo code. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setPromoCode(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getDiscountAppendValue = () => {
        if([ PromoCode.types.full_cost, PromoCode.types.percentage ].includes(promoCode.discount_type)) {
            return '%'
        }
        return promoCode.discount_type === PromoCode.types.credits ? ' credits' : null;
    }

    const getDiscountPrependValue = () => {
        return [ PromoCode.types.fixed_cost ].includes(promoCode.discount_type) ? '$' : null
    }

    const getFields = () => {
        if(!promoCode) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'title',
                title: 'Title',
                description: 'The title for a promo code is shown to the customer and displayed within Seeds. The title should be a short name describing the purpose of this promotion.',
                value: promoCode.title,
                component: 'textfield',
                onChange: text => onUpdateTarget({ title: text })
            },{
                key: 'description',
                title: 'Description',
                description: 'The description for a promo code is shown to the customer and displayed within Seeds. The description should be one to two sentences describing the purpose of this promotion.',
                value: promoCode.description,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            },{
                key: 'code',
                title: 'Redemption Code',
                description: 'The code for a promo code is a unique phrase that identifies a promotion. The code will be used by customers when redeeming the promotion.',
                value: promoCode.code,
                component: 'textfield',
                onChange: onChangePromoCode,
                props: {
                    useDelay: true
                },
                invalid: available === false && {
                    value: true,
                    text: 'Not Available'
                }
            }]
        },{
            key: 'discounts',
            title: 'Discounts',
            items: [{
                key: 'type',
                title: 'Type',
                description: 'The type of added to a promo code defines how a discount is given. Discounts can include fixed amounts, percentages of a total cost, full cost, and redemption of credits.',
                value: promoCode.discount_type,
                component: 'list',
                onChange: item => onUpdateTarget({ discount_type: item && item.id }),
                items: types.map(type => ({
                    id: type.code,
                    title: type.text
                }))
            },{
                key: 'discount',
                visible: promoCode.discount_type ? true : false,
                title: 'Amount',
                description: 'The amount for a promo code describes what a user account gains when they redeem the code. This can include a fixed dollar amount, a percentage of their ride cost, full ride cost coverage, or a credits redemption.',
                value: promoCode.discount,
                component: 'textfield',
                onChange: text => onUpdateTarget({ discount: text }),
                props: {
                    format: 'number',
                    append: getDiscountAppendValue(),
                    prepend: getDiscountPrependValue()
                }
            }]
        },{
            key: 'restrictions',
            title: 'Restrictions',
            items: [{
                key: 'start_date',
                required: false,
                title: 'Start Date',
                description: 'The start date for a promo code dictates when a promo code becomes active.',
                value: promoCode.start_date,
                component: 'date_time_picker',
                onChange: date => onUpdateTarget({ start_date: date && Utils.conformDate(date, 5) })
            },{
                key: 'end_date',
                required: false,
                title: 'End Date',
                description: 'The end date for a promo code dictates when a promo code expires.',
                value: promoCode.end_date,
                component: 'date_time_picker',
                onChange: date => onUpdateTarget({ end_date: date && Utils.conformDate(date, 5) })
            },{
                key: 'max_redemption',
                title: 'Max Redemption',
                description: 'Max redemption refers to the overall amount of times that the promo code can be used. This number can range from 1 to infinity.',
                value: promoCode.max_redemption,
                component: 'number_stepper',
                onChange: val => onUpdateTarget({ max_redemption: val })
            },{
                key: 'max_redemption_per_user',
                title: 'Max Redemption Per User',
                description: 'Max redemption per user refers to the total amount of times that the promo code can be used by the same account. This number can range from 1 to infinity.',
                value: promoCode.max_redemption_per_user,
                component: 'number_stepper',
                onChange: val => onUpdateTarget({ max_redemption_per_user: val })
            },{
                key: 'company',
                required: false,
                title: 'Company',
                description: 'Assigning a company to a promo code will restrict unauthorized use of the promotion and limit redemptions to users within the company',
                component: 'list',
                value: promoCode.company && {
                    id: promoCode.company.id,
                    title: promoCode.company.name
                },
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        company: item && companies.find(company => {
                            return company.id === item.id;
                        })
                    });
                }
            },{
                key: 'for_users',
                required: false,
                title: 'For Specific Customers',
                description: 'If needed, you can restrict this promo code to specific customers. This is helpful if you need to target a discount towards one or more customers but do not want the general public to have access.',
                value: promoCode.for_users,
                component: 'multiple_user_lookup',
                onChange: users => onUpdateTarget({ for_user: users })
            }]
        }];
    }

    const fetchAllCompanies = async () => {
        try {
            let { companies } = await fetchCompanies(utils);
            setCompanies(companies);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the companies list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setPromoCode(edits);
    }

    useEffect(() => {
        setupTarget();
        fetchAllCompanies();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Promo Code' : `Editing "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            removeOverflow: true,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setPromoCode(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const AddNewCreditsCard = ({ category }, { abstract, index, options, utils }) => {

    const layerID = `add-new-credits-card-${category}`;
    const [card, setCard] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {

        let required = [{
            title: 'customer',
            object: category === 'user' ? card.user : true
        },{
            title: 'company',
            object: category === 'company' ? card.company : true
        }]

        let remaining = required.filter(entry => !entry.object)
        if(remaining.length > 0) {
            utils.alert.show({
                title: 'Oops!',
                message: 'Please fill out the ' + remaining[0].title + ' for this Credits card before moving on'
            })
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(1);
            await abstract.object.submit(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The credits account for ${category === 'company' ? card.company.name : card.user.full_name} has been created`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating this credits account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchAllCompanies = async () => {
        try {
            if(category === 'user') {
                return;
            }
            let { companies } = await fetchCompanies(utils);
            setCompanies(companies);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the companies list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setCard(edits);
    }

    useEffect(() => {
        setupTarget();
        fetchAllCompanies();
    }, []);

    return (
        <Layer
        id={layerID}
        title={`New ${category === 'company' ? 'Company' : 'Customer'} Credits Account`}
        index={index}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setCard(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }]}>
            {category === 'company' && (
                <LayerItem
                title={'Company'}
                lastItem={true}
                shouldStyle={false}>
                    <select
                    value={card && card.company ? card.company.name : null}
                    className={`custom-select ${utils.user.get().theme}`}
                    onChange={evt => {
                        let id = Utils.attributeForKey.select(evt, 'id');
                        for(var i in companies) {
                            if(companies[i].id === parseInt(id)) {
                                abstract.object.set({ company: companies[i] });
                                setCard({ ...abstract.object.edits })
                                break;
                            }
                        }
                    }}>
                        <option>{'Choose a Company'}</option>
                        {companies.map((company, index) => {
                            return <option key={index} id={company.id}>{company.name}</option>
                        })}
                    </select>
                </LayerItem>
            )}
            {category === 'user' && (
                <LayerItem
                title={'Customer'}
                lastItem={true}
                shouldStyle={false}>
                    <UserLookupField
                    utils={utils}
                    user={card ? card.user : null}
                    placeholder={'Search by first or last name...'}
                    onChange={user => {
                        let edits = abstract.object.set({ user: user });
                        setCard(edits);
                    }}/>
                </LayerItem>
            )}
        </Layer>
    )
}

export const AddEditSubscriptionPlan = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new-subscription-plan` : `edit-subscription-plan-${abstract.getID()}`;
    const [companies, setCompanies] = useState([]);
    const [dropDown, setDropDown] = useState(null);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState(null);

    const billingTypes = [{
        key: 1,
        title: 'Daily'
    },{
        key: 2,
        title: 'Weekly'
    },{
        key: 3,
        title: 'Bi-Weekly'
    },{
        key: 4,
        title: 'Monthly'
    },{
        key: 5,
        title: 'Quarterly'
    },{
        key: 6,
        title: 'Yearly'
    }];

    const onManageRewards = () => {
        let rewards = plan.rewards || {};
        setDropDown({
            title: 'Rewards',
            message: 'Each subscription plan has an optional set of rewards that can be given each time the subscription is renewed. These rewards are automatically credited to the account holder upon plan reneweal.',
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => onUpdateTarget({ rewards: rewards })
            }],
            content: (
                <>
                <LayerItem
                title={'Credits'}
                shouldStyle={false}>
                    <TextField
                    prepend={'$'}
                    format={'number'}
                    value={rewards.credits}
                    onChange={text => {
                        rewards = {
                            ...rewards,
                            credits: text
                        }
                    }}/>
                </LayerItem>

                <LayerItem
                title={'Trees Planted'}
                shouldStyle={false}
                lastItem={true}>
                    <TextField
                    format={'number'}
                    value={rewards.trees}
                    onChange={text => {
                        rewards = {
                            ...rewards,
                            trees: text
                        }
                    }}/>
                </LayerItem>
                </>
            )
        })
    }

    const onOptionClick = () => {
        if(plan.options.length === 0) {
            onShowOptionPreferences('default');
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'new',
                title: 'New Option',
                style: 'default'
            }].concat(plan.options.sort((a, b) => {
                if(a.default) {
                    return -1;
                }
                if(a.start && b.start) {
                    return moment(a.start, 'HH:mm:ss').unix() > moment(b.start, 'HH:mm:ss').unix() ? 1 : -1;
                }
                return 1;

            }).map(option => {
                return {
                    key: option.start && option.end ? `${option.start}-${option.end}` : 'default',
                    title: `Edit ${option.start && option.end ? `${moment(option.start, 'HH:mm:ss').format('h:mma')} to ${moment(option.end, 'HH:mm:ss').format('h:mma')}` : 'Default Option'}`,
                    style: 'default'
                }
            }))
        }, key => {
            if(key === 'cancel') {
                return;
            }
            onShowOptionPreferences(key);
        });
    }

    const onShowOptionPreferences = key => {

        // find matching options
        let options = plan.options.find(option => {
            if(key === 'default' && option.default) {
                return true;
            }
            return key === `${option.start}-${option.end}`
        });

        // prepare mutable object for option selections
        options = {
            key: key,
            start: null,
            end: null,
            ...options,
            discounts: {
                orders: 0,
                reservations: 0,
                ...options && options.discounts
            }
        }

        let { discounts, restrictions } = options;
        let fields = [{
            key: 'orders',
            title: 'Orders',
            items: [{
                key: 'discount',
                title: 'Discount',
                description: 'This discount is a general discount given to all orders that qualify for subscription benefits.',
                value: discounts && discounts.orders ? parseFloat(discounts.orders * 100).toFixed(1) : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        discounts: {
                            ...options.discounts,
                            orders: isNaN(text) ? null : parseFloat(text) / 100
                        }
                    }
                }
            },{
                key: 'max_booking',
                title: 'Max Booking Total',
                description: 'This represents the maximum orders allowed within a subscription billing cycle.',
                value: restrictions && restrictions.orders ? restrictions.orders.max_booking : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            orders: {
                                ...options.restrictions && options.restrictions.orders,
                                max_booking: parseInt(text)
                            }
                        }
                    }
                }
            },{
                key: 'min_distance',
                title: 'Minimum Distance',
                description: 'This represents the minimum distance (in miles) that must be travelled for an order to be considered eligible for subscription benefits.',
                value: restrictions && restrictions.orders ? restrictions.orders.min_distance : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            orders: {
                                ...options.restrictions && options.restrictions.orders,
                                min_distance: parseInt(text)
                            }
                        }
                    }
                }
            },{
                key: 'max_distance',
                title: 'Maximum Distance',
                description: 'This represents the minimum distance (in miles) that must be travelled for an order to be considered eligible for subscription benefits.',
                value: restrictions && restrictions.orders ? restrictions.orders.max_distance : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            orders: {
                                ...options.restrictions && options.restrictions.orders,
                                max_distance: parseInt(text)
                            }
                        }
                    }
                }
            },{
                key: 'max_duration',
                title: 'Maximum Duration',
                description: 'This represents the maximum time (in seconds) allowed for the duration of an order for an order to be considered eligible for subscription benefits.',
                value: restrictions && restrictions.orders ? restrictions.orders.max_duration : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            orders: {
                                ...options.restrictions && options.restrictions.orders,
                                max_duration: parseInt(text)
                            }
                        }
                    }
                }
            }]
        },{
            key: 'reservations',
            title: 'Reservations',
            items: [{
                key: 'discount',
                title: 'Discount',
                description: 'This discount is a general discount given to all reservations that qualify for subscription benefits.',
                value: discounts && discounts.reservations ? parseFloat(discounts.reservations * 100).toFixed(1) : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        discounts: {
                            ...options.discounts,
                            reservations: isNaN(text) ? null : parseFloat(text) / 100
                        }
                    }
                }
            },{
                key: 'max_booking',
                title: 'Max Booking Total',
                description: 'This represents the maximum reservations allowed within a subscription billing cycle.',
                value: restrictions && restrictions.reservations ? restrictions.reservations.max_booking : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            reservations: {
                                ...options.restrictions && options.restrictions.reservations,
                                max_booking: parseInt(text)
                            }
                        }
                    }
                }
            },{
                key: 'min_distance',
                title: 'Minimum Distance',
                description: 'This represents the minimum distance (in miles) that must be travelled for a reservation to be considered eligible for subscription benefits.',
                value: restrictions && restrictions.reservations ? restrictions.reservations.min_distance : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            reservations: {
                                ...options.restrictions && options.restrictions.reservations,
                                min_distance: parseInt(text)
                            }
                        }
                    }
                }
            },{
                key: 'max_distance',
                title: 'Maximum Distance',
                description: 'This represents the minimum distance (in miles) that must be travelled for a reservation to be considered eligible for subscription benefits.',
                value: restrictions && restrictions.reservations ? restrictions.reservations.max_distance : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            reservations: {
                                ...options.restrictions && options.restrictions.reservations,
                                max_distance: parseInt(text)
                            }
                        }
                    }
                }
            },{
                key: 'max_duration',
                title: 'Maximum Duration',
                description: 'This represents the maximum time (in seconds) allowed for the duration of an reservation for an reservation to be considered eligible for subscription benefits.',
                value: restrictions && restrictions.reservations ? restrictions.reservations.max_duration : null,
                component: 'textfield',
                props: { format: 'number' },
                onChange: text => {
                    options = {
                        ...options,
                        restrictions: {
                            ...options.restrictions,
                            reservations: {
                                ...options.restrictions && options.restrictions.reservations,
                                max_duration: parseInt(text)
                            }
                        }
                    }
                }
            }]
        }];

        setDropDown({
            title: 'Options',
            message: `Each subscription plan has an optional set of restrictions that allow you to control the minimum and maximum distance for Orders and Reservations as well as the ability to control discounts per channel. All fields are optional except for the Order and Reservations discount amounts. ${key !== 'default' ? 'A start and end time are required for options that are not set as the default option' : ''}`,
            buttons: [{
                key: 'dismiss',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onAsyncClick: () => onUpdateOption(key, options)
            }],
            content: (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    {key !== 'default' && (
                        <LayerItem
                        title={'Start and End Time'}
                        shouldStyle={false}>
                            <TimeRangePicker
                            utils={utils}
                            selectedStartTime={options.start}
                            selectedEndTime={options.end}
                            onStartTimeChange={time => {
                                options = {
                                    ...options,
                                    start: time
                                }
                            }}
                            onEndTimeChange={time => {
                                options = {
                                    ...options,
                                    end: time
                                }
                            }} />
                        </LayerItem>
                    )}
                    <AltFieldMapper
                    utils={utils}
                    fields={fields} />
                </div>
            )
        })
    }

    const onSubmitSubscriptionPlan = async () => {
        try {
            if(loading === true) {
                return;
            }
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.apply(utils, isNewTarget);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${abstract.object.name}" subscription plan has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this subscription plan. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateOption = async (key, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                // apply changes to existing option if found
                let opts = abstract.object.edits.options.map(option => {
                    let identifier = option.start && option.end ? `${option.start}-${option.end}` : 'default';
                    return key === identifier ? props : option;
                })
                // add new option to array if applicable
                if(key === 'new') {
                    if(!props.start || !props.end) {
                        throw new Error('A start time and an end time are required before adding a new restriction');
                    }
                    opts = abstract.object.edits.options.concat([ props ]);
                }
                // update target
                await onValidateTimeslot(opts, props);
                onUpdateTarget({ options: opts });
                resolve();
            } catch(e) {
                reject(e);
                utils.alert.show({
                    title: 'Oops!',
                    message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
                });
            }
        });
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setPlan(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onValidateTimeslot = async (opts, props) => {
        return new Promise((resolve, reject) => {
            try {
                let overlap = opts.find(option => {
                    let { start, end } = option;
                    if(!start || !end) {
                        return false;
                    }
                    start = moment(start, 'HH:mm:ss');
                    end = moment(end, 'HH:mm:ss');
                    return opts.find(o => {
                        if(o.default) {
                            return false;
                        }
                        let startMatch = start > moment(o.start, 'HH:mm:ss') && start < moment(o.end, 'HH:mm:ss');
                        let endMatch = end > moment(o.start, 'HH:mm:ss') && end < moment(o.end, 'HH:mm:ss');
                        return startMatch || endMatch;
                    });
                })
                if(overlap) {
                    throw new Error(`Your new timeslot for ${moment(props.start, 'HH:mm:ss').format('h:mma')} to ${moment(props.end, 'HH:mm:ss').format('h:mma')} overlaps with one of your previous timeslots`);
                }
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    const getBillingText = code => {
        let billing = billingTypes.find(type => type.key === code);
        return billing ? billing.title : null;
    }

    const getFields = () => {
        if(!plan) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'name',
                title: 'Name',
                description: 'The name for a subscription plan is shown to a customer during the signup process and should relate to the services offered by the subscription plan.',
                value: plan.name,
                component: 'textfield',
                onChange: text => onUpdateTarget({ name: text })
            },{
                key: 'description',
                title: 'Description',
                description: 'The description for a subscription plan is shown to a customer during the signup process and should describe the services offered by the subscription plan.',
                value: plan.description,
                component: 'textview',
                onChange: text => onUpdateTarget({ description: text })
            },{
                key: 'companies',
                required: false,
                title: 'Companies',
                description: 'Assigning a one or more companies to this subscription plan will restrict this plan to employees of the selected companies. If one or more companies are selected then this plan will not be considered a private plan.',
                value: plan.companies,
                component: 'multiple_list',
                onChange: items => onUpdateTarget({ companies: items }),
                items: companies.map(company => ({
                    id: company.id,
                    title: company.name
                }))
            },{
                key: 'image',
                title: 'Image',
                description: 'The image for a subscription plan should be a white icon with a transparent background that describes the main use-case of the subscription.',
                value: plan.image || plan.tmpImage,
                component: 'image_picker',
                onChange: image => onUpdateTarget({ tmpImage: image }),
                props: {
                    imageStyle: {
                        padding: 5
                    }
                }
            }]
        },{
            key: 'ext_details',
            title: 'About this Plan',
            items: [{
                key: 'options',
                required: false,
                title: 'Options',
                description: 'The billing cycle dictates the duration on which discounts and benefits are offered. This cycle also dictates when and how frequency payments are processed for the subscription plan.',
                value: plan.options && `${plan.options.length} ${plan.options.length === 1 ? 'option' : 'options'} added`,
                onEditClick: onOptionClick
            },{
                key: 'rewards',
                title: 'Rewards',
                description: 'The billing cycle dictates the duration on which discounts and benefits are offered. This cycle also dictates when and how frequency payments are processed for the subscription plan.',
                value: getRewardsOverview(),
                onEditClick: onManageRewards
            }]
        },{
            key: 'payments',
            title: 'Payments',
            items: [{
                key: 'cost',
                title: 'Cost',
                description: 'The cost for a subscription plan will be the total amount billed to the customer at the end of their billing cycle.',
                value: plan.cost,
                component: 'textfield',
                onChange: text => onUpdateTarget({ cost: text }),
                props: {
                    format: 'number',
                    prepend: '$'
                }
            },{
                key: 'billing',
                title: 'Billing',
                description: 'The billing cycle dictates the duration on which discounts and benefits are offered. This cycle also dictates when and how frequency payments are processed for the subscription plan.',
                value: plan.billing,
                component: 'list',
                onChange: item => onUpdateTarget({ billing: item && item.id }),
                items: billingTypes.map(type => ({
                    id: type.key,
                    title: type.title
                }))
            }]
        }];
    }

    const getRewardsOverview = () => {
        let { credits, trees } = plan.rewards || {};
        return `${credits || 0} ${(credits || 0) === 1 ? 'credit' : 'credits'} and ${trees || 0} ${(trees || 0) === 1 ? 'tree' : 'trees'}`;
    }

    const setupTargets = async () => {
        try {
            let edits = abstract.object.open();
            setPlan(edits);

            let { companies } = await fetchCompanies(utils);
            setCompanies(companies);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the companies list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setupTargets();
    }, []);

    return (
        <Layer
        id={layerID}
        title={isNewTarget ? 'New Subscription Plan' : `Editing "${abstract.getTitle()}"`}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState,
            removeOverflow: true
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setPlan(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmitSubscriptionPlan
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const CommunityAccessPointDetails = ({ abstract, index, options, utils }) => {

    const layerID = `community_access_point_details_${abstract.getID()}`;
    const [annotations, setAnnotations] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(abstract.object);

    const onDeleteAccessPoint = () => {
        utils.alert.show({
            title: 'Delete Community Access Point',
            message: `Are you sure that you want to delete this community access point? This can not be undone`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteAccessPointConfirm();
                    return;
                }
            }
        })
    }

    const onDeleteAccessPointConfirm = async () => {
        try {

            // send request to the server
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/credits/', {
                type: 'delete_community_access_point',
                id: abstract.getID()
            });

            // notify subscribers of content change
            utils.content.remove(abstract);

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This community access point has been deleted`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this community access point. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {
        utils.layer.open({
            id: `edit_community_access_point_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditCommunityAccessPoint.bind(this, {
                isNewTarget: false
            })
        })
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'status',
                title: abstract.object.active ? 'Deactivate' : 'Activate',
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'delete',
                title: 'Delete',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'delete') {
                onDeleteAccessPoint();
                return;
            }
            if(key === 'status') {
                onSetActiveStatus();
                return;
            }
        })
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: `${location.active ? 'Deactivate' : 'Activate'} Community Access Point`,
            message: `Are you sure that you want to ${location.active ? 'deactivate' : 'activate'} this community access point?`,
            buttons: [{
                key: 'confirm',
                title: location.active ? 'Deactivate' : 'Activate',
                style: location.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: location.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: location.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/credits/', {
                type: 'set_community_access_point_active_status',
                id: abstract.getID(),
                active: next
            });

            setLoading(false);
            abstract.object.active = next;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `This community access point has been ${next ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this community access point. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSetAnnotations = () => {
        setAnnotations([{
            key: 'default_location',
            title: abstract.object.address,
            location: abstract.object.location,
            icon: { type: 'broadcast' }
        }]);
    }

    const getButtons = () => {
        return [{
            key: 'cancel',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const getFeatures = () => {
        return {
            id: 'community_access_point_shape',
            region: abstract.object.geojson.region,
            data: abstract.object.geojson.shape,
            layer: {
                type: 'fill',
                paint: {
                    'fill-opacity': 0.25,
                    'fill-color': Appearance.colors.grey()
                }
            }
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: location.id
            },{
                key: 'name',
                title: 'Name',
                value: location.name
            },{
                key: 'address',
                title: 'Address',
                value: location.address
            },{
                key: 'radius',
                title: 'Radius',
                value: `${location.radius} ${location.radius === 1 ? 'foot' : 'feet'}`
            },{
                key: 'status',
                title: 'Status',
                value: location.active ? 'Active' : 'Not Active'
            }]
        }];
    }

    useEffect(() => {
        onSetAnnotations();
        utils.content.subscribe(layerID, 'communityAccessPoints', {
            onUpdate: onSetAnnotations
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for "${abstract.getTitle()}"`}
        options={{
            ...options,
            loading: loading,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <div style={{
                position: 'relative'
            }}>
                <LayerMap
                utils={utils}
                annotations={annotations}
                features={getFeatures()} />
            </div>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const CreditsCardDetails = ({ abstract, index, options, utils }) => {

    const layerID = `credits-card-details-${abstract.getID()}`;
    const [accessPoints, setAccessPoints] = useState([]);
    const [authorizedUsers, setAuthorizedUsers] = useState([]);
    const [creditsCard, setCreditsCard] = useState(abstract.object);
    const [dropDown, setDropDown] = useState(null);
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const onAccessPointClick = location => {
        utils.layer.open({
            id: `community_access_point_details_${location.id}`,
            abstract: Abstract.create({
                object: location,
                type: 'communityAccessPoints'
            }),
            Component: CommunityAccessPointDetails
        });
    }

    const onAddAccessPointClick = () => {

        // create new access point
        let location = CreditsCard.CommunityAccessPoint.new();
        location.company_id = creditsCard.company.id;

        // present layer to configure properties
        utils.layer.open({
            id: 'new_community_access_point',
            abstract: Abstract.create({
                object: location,
                type: 'communityAccessPoints'
            }),
            Component: AddEditCommunityAccessPoint.bind(this, {
                isNewTarget: true,
                onCreate: location => {
                    setAccessPoints(locations => update(locations, {
                        $push: [location],
                        $apply: locations => locations.sort((a,b) => {
                            return a.name.localeCompare(b.name);
                        })
                    }));
                }
            })
        });
    }

    const onAddUserClick = () => {
        let user = null;
        setDropDown({
            title: 'Authorized Users',
            message: 'Company credits have a list of users who are authorized to access and use the credits. You can add as many or as few users as you would like for this credits account.',
            buttons: [{
                key: 'cancel',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Done',
                color: 'primary',
                onClick: () => {
                    if(user) {
                        onAddAuthorizedUser(user);
                    }
                }
            }],
            content: (
                <UserLookupField
                utils={utils}
                users={setAuthorizedUsers}
                placeholder={'Search by first or last name...'}
                onChange={result => user = result} />
            )
        })
    }

    const onAddAuthorizedUser = async user => {
        try {
            setLoading(true);
            await Request.post(utils, '/credits/', {
                type: 'add_authorized_user',
                card_id: creditsCard.card.id,
                company_id: creditsCard.company.id,
                user_id: user.user_id
            });

            setLoading(false);
            setAuthorizedUsers(users => update(users, {
                $push: [user]
            }));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue adding ${user.full_name} as an authorized user. ${e.message || 'An unknown error occcurred'}`
            });
        }
    }

    const onAuthorizedUserClick = user => {
        utils.sheet.show({
            items: [{
                key: 'details',
                title: `About ${user.first_name}`,
                style: 'default'
            },{
                key: 'remove',
                title: 'Remove as Authorized User',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'details') {
                Utils.users.details(utils, user);
                return;
            }
            if(key === 'remove') {
                onRemoveAuthorizedUser(user);
                return;
            }
        })
    }

    const onChangeCommunityAccess = async () => {
        try {
            setLoading(true);
            let next = creditsCard.card.community_access.enabled ? false : true;
            await Request.post(utils, '/credits/', {
                access: next,
                card_id: creditsCard.card.id,
                company_id: creditsCard.company.id,
                type: 'set_community_access'
            });

            // update abstract target and notify subscribers of change
            abstract.object.card.community_access = { enabled: next };
            utils.content.update(abstract);

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Community Access for this credits account has been ${next ? 'enabled' : 'disabled'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the community access for this credits account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onCreateInvoice = async (props, download) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Request.get(utils, '/credits/',  {
                    type: 'invoice',
                    card_id: abstract.object.card.id,
                    category: abstract.object.type,
                    ...props,
                    ...download
                });
                Utils.handleDownload(response);
                resolve();

            } catch(e) {
                reject(e);
            }
        });
    }

    const onEventClick = entry => {
        if(!entry.reservation_id && !entry.order_id) {
            utils.alert.show({
                title: `${Utils.ucFirst(entry.type)} Credits`,
                message: entry.notes
            });
            return;
        }
        utils.sheet.show({
            items: [{
                key: 'view',
                title: `View ${entry.reservation_id ? 'Reservation' : 'Order'}`,
                style: 'default'
            }]
        }, async key => {
            if(key === 'view') {
                if(entry.order_id) {
                    try {
                        let order = await Order.get(utils, entry.order_id);
                        Utils.orders.details(utils, order);
                    } catch(e) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue loading this order. ${e.message || 'An unknown error occurred'}`
                        });
                    }
                    return;
                }
                if(entry.reservation_id) {
                    try {
                        let reservation = await Reservation.get(utils, entry.reservation_id);
                        Utils.reservations.details(utils, reservation);
                    } catch(e) {
                        utils.alert.show({
                            title: 'Oops!',
                            message: `There was an issue loading this reservation. ${e.message || 'An unknown error occurred'}`
                        });
                    }
                }
                return;
            }
        });
    }

    const onOptionsClick = () => {

        // prevent moving forward if credits account is deactivated
        if(!creditsCard.active) {
            utils.alert.show({
                title: 'Just at Second',
                message: 'It looks like this credits account has been deactivated. Please reactivate this account before moving on.',
                buttons: [{
                    key: 'activate',
                    title: 'Activate',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Dismiss',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'activate') {
                        setTimeout(onSetActiveStatus, 1000);
                        return;
                    }
                }
            });
            return;
        }

        // present options to manage credits account
        let isAdmin = utils.user.get().level <= User.level.admin ? true : false;
        utils.sheet.show({
            items: [{
                key: 'add',
                title: isAdmin ? 'Add or Buy Credits' : 'Buy Credits',
                style: 'default'
            },{
                key: 'invoice',
                title: 'Create Invoice',
                style: 'default'
            },{
                key: 'charge',
                title: 'New Charge',
                style: 'default',
                visible: isAdmin
            },{
                key: 'notes',
                title: 'Open Notes',
                style: 'default',
                visible: isAdmin
            },{
                key: 'community_access',
                title: `${creditsCard.card.community_access.enabled ? 'Disable' : 'Enable'} Community Access`,
                style: creditsCard.card.community_access.enabled ? 'destructive' : 'default',
                visible: isAdmin && creditsCard.is_company_card === true
            }].sort((a,b) => {
                return a.title.localeCompare(b.title);
            })
        }, key => {
            if(key === 'add') {
                utils.layer.open({
                    id: `add-credits-${abstract.getID()}`,
                    abstract: abstract,
                    Component: AddCredits
                });
                return;
            }
            if(key === 'charge') {
                utils.layer.open({
                    id: `new-credits-charge-${abstract.getID()}`,
                    abstract: abstract,
                    Component: NewCreditsCharge
                });
                return;
            }
            if(key === 'community_access') {
                onChangeCommunityAccess();
                return;
            }
            if(key === 'invoice') {
                Utils.downloads.content(utils, {
                    id: layerID,
                    title: 'Credits Invoice',
                    extendedFeatures: ['date_range'],
                    onExport: onCreateInvoice.bind(this, {
                        id: creditsCard.card.id,
                        category: creditsCard.type
                    })
                });
                return;
            }
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
        });
    }

    const onPaymentClick = (entry, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Payment Completed',
            message: `A payment was processed for ${Utils.toCurrency(entry.amount)} before adding these credits to the account. The confirmation for these credits is listed under the external payment id ${entry.stripe_id}`
        });
    }

    const onRemoveAuthorizedUser = user => {
        utils.alert.show({
            title: `Remove ${user.first_name}`,
            message: `Are you sure that you want to remove ${user.full_name} as an authorized user for this Credits card? ${user.first_name} will no longer be able to use these Credits.`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveAuthorizedUseronfirm();
                    return;
                }
            }
        });
    }

    const onRemoveAuthorizedUseronfirm = async user => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/credits/', {
                type: 'remove_authorized_user',
                card_id: creditsCard.card.id,
                user_id: user.user_id,
                company_id: creditsCard.company.id
            });

            setLoading(false);
            setAuthorizedUsers(users => {
                return users.filter(prev_user => {
                    return prev_user.user_id !== user.user_id;
                });
            });

            utils.alert.show({
                title: 'All Done!',
                message: `${user.full_name} has been removed as an authorized user for this credits account`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing ${user.full_name} as an authorized user. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetAccessPointStatus = location => {
        utils.alert.show({
            title: `${location.active ? 'Deactivate' : 'Activate'} Community Access Point`,
            message: `Are you sure that you want to ${location.active ? 'deactivate' : 'activate'} this community access point? ${location.active ? 'Deactivating with no longer show this location as an eligible pickup or drop-off' : 'Activating with show this location as an eligible pickup and drop-off'}.`,
            buttons: [{
                key: 'confirm',
                title: location.active ? 'Deactivate' : 'Activate',
                style: location.active ? 'destructive' : 'default',
            },{
                key: 'cancel',
                title: location.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: location.active ? 'default' : 'destructive',
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetAccessPointStatusConfirm(location);
                    return;
                }
            }
        })
    }

    const onSetAccessPointStatusConfirm = async location => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            // send request to the server
            let next = !location.active;
            await Request.post(utils, '/credits/', {
                active: next,
                id: location.id,
                type: 'set_community_access_point_status'
            });

            // update abstract target and notify subscribers
            abstract.object.active = next
            utils.content.update(abstract);

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This community access point has been ${next? 'activated and will become immediately available for booking' : 'deactivated and will no longer be available for booking'}.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating this community access point. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: `${creditsCard.active ? 'Deactivate' : 'Activate'} Account`,
            message: `Are you sure that you want to ${creditsCard.active ? 'deactivate' : 'activate'} this credits account? ${creditsCard.active ? 'This will prevent all users from using the credits associated with this account.' : 'This will allow authorized useres to start using the credits associated with this account.'}`,
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: creditsCard.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: `Do Not ${creditsCard.active ? 'Deactivate' : 'Activate'}`,
                style: creditsCard.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        });
    }

    const onSetActiveStatusConfirm = async () => {
        try {

            // set loading flag and request timeout
            setLoading(true);
            await Utils.sleep(0.5);

            // send request to server
            let next = abstract.object.active ? false : true;
            await Request.post(utils, '/credits/', {
                active: next,
                card_id: creditsCard.card.id,
                type: 'set_active_status'
            });

            // update abstract target with new active status
            abstract.object.active = next;
            utils.content.update(abstract);

            // show confirmation to user
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This credits account has been ${next ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this credits account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getAccessPoints = () => {
        if(creditsCard.type !== 'company' || creditsCard.card.community_access.enabled === false) {
            return null;
        }
        return (
            <LayerItem
            title={'Community Access Points'}
            childrenStyle={{
                ...accessPoints.length === 0 && {
                    padding: '8px 12px 8px 12px'
                }
            }}
            rightContent={(
                <Button
                label={'Add Access Point'}
                type={'small'}
                color={'dark'}
                onClick={onAddAccessPointClick} />
            )}>
                {accessPoints.length === 0 && (
                    <span style={Appearance.textStyles.key()}>{`No access points have been added for ${abstract.getTitle()}`}</span>
                )}
                {accessPoints.map((location, index) => (
                    Views.entry({
                        badge: {
                            color: Appearance.colors.grey(),
                            text: location.active ? null : 'Not Active'
                        },
                        bottomBorder: index !== accessPoints.length - 1,
                        icon: {
                            path: 'images/community-credits-access-point-clear.png',
                            style: {
                                backgroundColor: location.active ? Appearance.colors.primary() : Appearance.colors.grey()
                            }
                        },
                        key: index,
                        onClick: onAccessPointClick.bind(this, location),
                        subTitle: location.address,
                        title: location.name
                    })
                ))}
            </LayerItem>
        )
    }

    const getAuthorizedUsers = () => {
        if(creditsCard.type !== 'company' || creditsCard.card.community_access.enabled === true) {
            return null;
        }
        return (
            <LayerItem
            title={'Authorized Users'}
            childrenStyle={{
                ...authorizedUsers.length === 0 && {
                    padding: '8px 12px 8px 12px'
                }
            }}
            rightContent={(
                <Button
                label={'Add User'}
                type={'small'}
                color={'dark'}
                onClick={onAddUserClick} />
            )}>
                {authorizedUsers.length === 0 && (
                    <span style={Appearance.textStyles.key()}>{`No authorized users have been added for ${abstract.getTitle()}`}</span>
                )}
                {authorizedUsers.map((user, index) => (
                    Views.entry({
                        badge: User.formatLevel(user.level),
                        bottomBorder: index !== authorizedUsers.length - 1,
                        icon: { path: user.avatar },
                        key: user.user_id,
                        onClick: onAuthorizedUserClick.bind(this, user),
                        subTitle: user.phone_number,
                        title: user.full_name
                    })
                ))}
            </LayerItem>
        )
    }

    const getButtons = () => {
        return [{
            key: 'active',
            text: creditsCard.active ? 'Deactivate' : 'Activate',
            color: creditsCard.active ? 'danger' : 'primary',
            visible: utils.user.get().level <= User.level.admin,
            onClick: onSetActiveStatus
        },{
            key: 'options',
            text: 'Options',
            color: creditsCard.active ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }];
    }

    const getFields = () => {
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: creditsCard.id
            },{
                key: 'balance',
                title: 'Balance',
                value: creditsCard.card ? Utils.toCurrency(creditsCard.card.balance) : null
            },{
                key: 'date',
                title: 'Created',
                value: Utils.formatDate(creditsCard.date)
            },{
                key: 'status',
                title: 'Status',
                value: creditsCard.active ? 'Activated' : 'Deactivated'
            },{
                key: 'community_access',
                title: 'Community Access',
                value: creditsCard.card.community_access.enabled ? 'Enabled' : 'Disabled',
                visible: creditsCard.is_company_card === true
            }]
        }];

        if(creditsCard.card.community_access.enabled) {
            items.push({
                key: 'community_access',
                title: 'Community Access',
                items: [{
                    key: 'status',
                    title: 'Status',
                    value: 'Enabled'
                },{
                    key: 'auto_select',
                    title: 'Auto Select for Eligible Locations',
                    value: creditsCard.card.community_access.auto_select ? 'Enabled' : 'Disabled'
                }]
            });
        }
        return items;
    }

    const getHistoryEvents = () => {

        return history.filter((entry, index, entries) => {
            if(!searchText) {
                return true;
            }
            let text = searchText.toLowerCase();
            return entry.title.toLowerCase().includes(text)
            || entry.user.full_name.toLowerCase().includes(text)
            || entry.amount.toString().includes(text)
            || entry.notes.toLowerCase().includes(text)
            || moment(entry.date).format('MMMM Do, YYYY [at] h:mma').toLowerCase().includes(text);

        }).map((entry, index, entries) => {
            return (
                <div
                key={index}
                style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: index !== entries.length - 1 ? 8 : 0
                }}>
                    {Views.entry({
                        badge: {
                            text: Utils.toCurrency(entry.amount),
                            color: Utils.apply(entry.color, {
                                primary: () => Appearance.colors.primary(),
                                danger: () => Appearance.colors.red,
                                default: () => Appearance.colors.grey()
                            })
                        },
                        bottomBorder: entry.notes ? true : false,
                        icon: { path: entry.user.avatar },
                        key: index,
                        onClick: onEventClick.bind(this, entry),
                        title: `${entry.title} by ${entry.user.full_name}`,
                        rightContent: entry.stripe_id && (
                            <img
                            className={'text-button'}
                            onClick={onPaymentClick.bind(this, entry)}
                            src={'images/payment-icon-clear.png'}
                            style={{
                                backgroundColor: Utils.apply(entry.color, {
                                    primary: () => Appearance.colors.primary(),
                                    danger: () => Appearance.colors.red,
                                    default: () => Appearance.colors.grey()
                                }),
                                borderRadius: 10,
                                height: 20,
                                marginLeft: 8,
                                minWidth: 20,
                                overflow: 'hidden',
                                width: 20
                            }} />
                        ),
                        subTitle: moment(entry.date).format('MMMM Do, YYYY [at] h:mma'),
                    })}
                    {typeof(entry.notes) === 'string' && entry.notes.length > 0 && (
                        <div style={{
                            padding: '8px 12px 8px 12px'
                        }}>
                            <span style={{
                                ...Appearance.textStyles.subTitle(),
                                color: Appearance.colors.text(),
                                display: 'block',
                                whiteSpace: 'normal'
                            }}>{entry.notes}</span>
                        </div>
                    )}
                </div>
            )
        })
    }

    const fetchAccessPoints = async () => {
        try {
            if(creditsCard.type !== 'company' || creditsCard.card.community_access.enabled === false) {
                return;
            }
            let { locations } = await Request.get(utils, '/credits/',  {
                type: 'community_access_points',
                company_id: creditsCard.company.id
            });

            // format locations and update target
            let targets = locations.map(location => CreditsCard.CommunityAccessPoint.create(location));
            abstract.object.card.access_points = targets;

            // notify subscribers of content change
            utils.content.update(abstract);

            // update state with new formatted locations
            setLoading(false);
            setAccessPoints(targets);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the community access points for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchAuthorizedUsers = async () => {
        try {
            if(creditsCard.type !== 'company' || creditsCard.card.community_access.enabled === true) {
                return;
            }
            let { users } = await Request.get(utils, '/credits/',  {
                type: 'authorized_users',
                company_id: creditsCard.company.id
            });
            setLoading(false);
            setAuthorizedUsers(users.map(user => User.create(user)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the authorized users for this account. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const fetchContentTargets = () => {
        //fetchAccessPoints();
        //fetchAuthorizedUsers();
        fetchHistory();
    }

    const fetchHistory = async () => {
        try {
            let { entries } = await Request.get(utils, '/credits/',  {
                type: 'invoice',
                card_id: abstract.object.card.id,
                category: abstract.object.type,
                identity: true
            });
            setLoading(false);
            setHistory(entries);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the history events for this credits card. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        if(creditsCard.seeds && creditsCard.seeds.notes) {
            setNote(creditsCard.seeds.notes.find(note => note.deleted !== true));
        }
    }, [creditsCard]);

    useEffect(() => {

        // fetch and setup supporting data
        fetchContentTargets();

        // subscribe to content changes
        utils.content.subscribe(layerID, ['credits', 'communityAccessPoints'], {
            onRemove: abstract => {
                if(abstract.type === 'communityAccessPoints') {
                    setAccessPoints(locations => {
                        return locations.filter(location => {
                            return location.id !== abstract.getID();
                        }).sort((a,b) => {
                            return a.name.localeCompare(b.name);
                        });
                    });
                }
            },
            onUpdate: next => {
                if(next.type === 'credits') {
                    setCreditsCard(next.compare(abstract, fetchContentTargets));
                }
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle()}
        index={index}
        utils={utils}
        dropDown={dropDown}
        buttons={getButtons()}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            {creditsCard.type === 'user' && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        key: creditsCard.id,
                        title: creditsCard.user.full_name,
                        subTitle: creditsCard.user.phone_number,
                        icon: {
                            path: creditsCard.user.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, creditsCard.user)
                    })}
                </LayerItem>
            )}

            {creditsCard.type === 'company' && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        key: creditsCard.id,
                        title: creditsCard.company.name,
                        subTitle: creditsCard.company.address,
                        icon: {
                            path: creditsCard.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, creditsCard.company)
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {getAuthorizedUsers()}
            {getAccessPoints()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />

            <LayerItem
            title={'Events'}
            shouldStyle={false}>
                {history.length === 0
                    ?
                    <div style={{
                        ...Appearance.styles.unstyledPanel(),
                        borderRadius: 15,
                        padding: '8px 12px 8px 12px'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.key(),
                            display: 'block'
                        }}>{`No events were found for ${abstract.getTitle()}`}</span>
                    </div>
                    :
                    <>
                    <TextField
                    icon={'search'}
                    useDelay={false}
                    autoComplete={false}
                    autoCorrect={false}
                    autoCapitalize={false}
                    placeholder={'Search by type, date, amount, or reservation id...'}
                    onChange={text => {
                        setSearchText(text ? text.toLowerCase() : text);
                    }}
                    containerStyle={{
                        marginBottom: 8
                    }}/>
                    {getHistoryEvents()}
                    </>
                }
            </LayerItem>
        </Layer>
    )
}

export const EditDirectPaymentPreferences = ({ account, discounts, onChange }, { abstract, index, options, utils }) => {

    const layerID = 'edit_direct_payment_preferences';
    const [edits, setEdits] = useState({ account: account, discounts: discounts || {} });
    const [layerState, setLayerState] = useState(null);

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev,
            ...props
        }));
    }
    
    const getButtons = () => {
        return [{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                setEdits({ account: account, discounts: discounts });
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: () => {
                setLayerState('close');
                if(typeof(onChange) === 'function') {
                    onChange(edits);
                }
            }
        }];
    }

    const getFields = () => {
        return [{
            key: 'commission',
            title: 'Commission',
            visible: abstract.type === 'users' ? true : false,
            items: [{
                key: 'enabled',
                required: false,
                title: 'Enabled',
                description: 'Enabling commissions will determine how much, if any, of the delivery or ride revenue will be automatically transfered to this user when an eligible order or reservation has been completed with their referral code.',
                component: 'bool_list',
                value: edits.account.commission ? edits.account.commission.enabled : false,
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({
                        account: update(edits.account, {
                            commission: {
                                enabled: {
                                    $set: val
                                }
                            }
                        })
                    });
                }
            },{
                key: 'orders',
                required: false,
                title: 'Deliveries',
                description: 'Setting a delivery commission will determine how much, if any, of the devivery revenue will be automatically transfered to this user when an eligible order has been completed.',
                component: 'textfield',
                value: edits.account.commission ? edits.account.commission.orders.percentage.toString() : null,
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({
                        account: update(edits.account, {
                            commission: {
                                orders: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            },{
                key: 'reservations',
                required: false,
                title: 'Rides',
                description: 'Setting a ride commission will determine how much, if any, of the ride revenue will be automatically transfered to this user when an eligible ride has been completed.',
                component: 'textfield',
                value: edits.account.commission ? edits.account.commission.reservations.percentage.toString() : null,
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({
                        account: update(edits.account, {
                            commission: {
                                reservations: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            }]
        },{
            key: 'revenue_share',
            title: 'Revenue Share',
            items: [{
                key: 'discounts.enabled',
                required: false,
                visible: abstract.type === 'companies' && edits.discounts && utils.user.get().level <= User.level.admin,
                title: 'Discounts',
                description: 'Enabling revenue share discounts will allow you to apply a discount to deliveries and rides where an eligible referral code is used',
                component: 'bool_list',
                value: edits.discounts && edits.discounts.enabled || false,
                onChange: val => {
                    onUpdateTarget({
                        discounts: update(edits.discounts, {
                            enabled: {
                                $set: val
                            }
                        })
                    });
                }
            },{
                key: 'discounts.amount',
                required: false,
                visible: abstract.type === 'companies' && edits.discounts && edits.discounts.enabled && utils.user.get().level <= User.level.admin,
                title: 'Discount Amount',
                description: 'Enabling referral revenue share discounts will allow you to apply a discount to deliveries and rides where an eligible referral code is used',
                component: 'textfield',
                value: edits.discounts && edits.discounts.amount,
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({
                        discounts: update(edits.discounts, {
                            amount: {
                                $set: val
                            }
                        })
                    });
                }
            },{
                key: 'enabled',
                required: false,
                title: 'Enabled',
                description: 'Enabling revenue share will determine how much, if any, of the delivery or ride revenue will be automatically transfered to this user when an eligible order or reservation has been completed with their referral code.',
                component: 'bool_list',
                value: edits.account.revenue_share ? edits.account.revenue_share.enabled : false,
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({
                        account: update(edits.account, {
                            revenue_share: {
                                enabled: {
                                    $set: val
                                }
                            }
                        })
                    });
                }
            },{
                key: 'orders',
                required: false,
                title: 'Deliveries',
                description: 'Setting a delivery referral revenue share will determine how much, if any, of the delivery revenue will be automatically transfered to this user when an eligible order has been completed with their referral code.',
                component: 'textfield',
                value: edits.account.revenue_share ? edits.account.revenue_share.orders.percentage.toString() : null,
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({
                        account: update(edits.account, {
                            revenue_share: {
                                orders: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            },{
                key: 'reservations',
                required: false,
                title: 'Rides',
                description: 'Setting a ride referral revenue share will determine how much, if any, of the ride revenue will be automatically transfered to this user when an eligible ride has been completed with their referral code.',
                component: 'textfield',
                value: edits.account.revenue_share ? edits.account.revenue_share.reservations.percentage.toString() : null,
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({
                        account: update(edits.account, {
                            revenue_share: {
                                reservations: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            },{
                key: 'discounts.public',
                required: false,
                visible: abstract.type === 'companies' && edits.discounts && utils.user.get().level <= User.level.admin,
                title: 'Share Discount with Public',
                description: 'Sharing the referral revenue share discount with the public will allow users outside of this company to take advantage of the discount.',
                component: 'bool_list',
                value: edits.discounts && edits.discounts.public || false,
                onChange: val => {
                    onUpdateTarget({
                        discounts: update(edits.discounts, {
                            public: {
                                $set: val
                            }
                        })
                    });
                }
            }]
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Editing Commission and Revenue Share'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const EditPaymentPreferences = ({ onChange, preferences }, { index, options, utils }) => {

    const layerID = 'edit-payment-preferences';
    const [edits, setEdits] = useState({ ...preferences });
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/resources/', {
                data: edits,
                type: 'update_platform_payment_preferences'
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: 'Your payment preferences have been updated',
                onClick: () => {
                    setLayerState('close');
                    if(typeof(onChange) === 'function') {
                        onChange(edits);
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating your payment preferences. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onUpdateTarget = props => {
        setEdits(prev => ({
            ...prev,
            ...props
        }));
    }
    
    const getButtons = () => {
        return [{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                setEdits(preferences);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmit
        }];
    }

    const getFields = () => {
        if(!edits) {
            return [];
        }
        return [{
            key: 'direct_payments',
            title: 'Direct Payments',
            items: [{
                key: 'enabled',
                title: 'Direct Payments',
                description: `Enabling direct payments will allow users to receieve automatic payouts from the system if their account is eligible.`,
                value: edits.direct_payments.enabled,
                component: 'bool_list',
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(edits.direct_payments, {
                            enabled: {
                                $set: val
                            }
                        })
                    });
                }
            },{
                key: 'commission',
                title: 'Commission',
                description: `Enabling commission payments will allow users to receieve commission driven payouts if they are associated with a ride or order where a commission applies to them.`,
                value: edits.direct_payments.commission.enabled,
                component: 'bool_list',
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(edits.direct_payments, {
                            commission: {
                                enabled: {
                                    $set: val
                                }
                            }
                        })
                    });
                }
            },{
                key: 'revenue_share',
                title: 'Revenue Share',
                description: `Enabling revenue share payments will allow users to receieve revenue share payouts if their referral code is associated with a ride or order where a revenue share is applicable.`,
                value: edits.direct_payments.revenue_share.enabled,
                component: 'bool_list',
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(edits.direct_payments, {
                            revenue_share: {
                                enabled: {
                                    $set: val
                                }
                            }
                        })
                    });
                }
            },{
                key: 'order_commission',
                visible: edits.direct_payments.enabled && edits.direct_payments.commission.enabled ? true : false,
                required: edits.direct_payments.enabled && edits.direct_payments.commission.enabled ? true : false,
                title: 'Delivery Commission',
                description: `The delivery commission percentage is used when calculating the amount of funds to transfer to a driver when a delivery has been completed. The chosen percentage will be used, in the event that the driver account does not have an explicit percentage set, to calculate against the order's final cost.`,
                value: edits.direct_payments.commission.orders.percentage,
                component: 'textfield',
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(edits.direct_payments, {
                            commission: {
                                orders: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            },{
                key: 'order_revenue_share',
                visible: edits.direct_payments.enabled && edits.direct_payments.revenue_share.enabled ? true : false,
                required: edits.direct_payments.enabled && edits.direct_payments.revenue_share.enabled ? true : false,
                title: 'Delivery Referral Revenue Share',
                description: `The delivery referral revenue share percentage is used when calculating the amount of funds to transfer to a user when a delivery has been completed. This transfer occurs if the customer who booked the order used the user's referral code during the booking process. The chosen percentage will be used, in the event that the user account does not have an explicit percentage set, to calculate against the order's final cost.`,
                value: edits.direct_payments.revenue_share.orders.percentage,
                component: 'textfield',
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(edits.direct_payments, {
                            revenue_share: {
                                orders: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            },{
                key: 'ride_commission',
                visible: edits.direct_payments.enabled && edits.direct_payments.commission.enabled ? true : false,
                required: edits.direct_payments.enabled && edits.direct_payments.commission.enabled ? true : false,
                title: 'Ride Commission',
                description: `The ride commission percentage is used when calculating the amount of funds to transfer to a driver when a ride has been completed. The chosen percentage will be used, in the event that the driver account does not have an explicit percentage set, to calculate against the reservation's final cost.`,
                value: edits.direct_payments.commission.reservations.percentage,
                component: 'textfield',
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(edits.direct_payments, {
                            commission: {
                                reservations: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            },{
                key: 'ride_revenue_share',
                visible: edits.direct_payments.enabled && edits.direct_payments.revenue_share.enabled ? true : false,
                required: edits.direct_payments.enabled && edits.direct_payments.revenue_share.enabled ? true : false,
                title: 'Ride Referral Revenue Share',
                description: `The ride referral revenue share percentage is used when calculating the amount of funds to transfer to a user when a ride has been completed. This transfer occurs if the customer who booked the ride used the user's referral code during the booking process. The chosen percentage will be used, in the event that the user account does not have an explicit percentage set, to calculate against the ride's final cost.`,
                value: edits.direct_payments.revenue_share.reservations.percentage,
                component: 'textfield',
                props: { format: 'percentage' },
                onChange: val => {
                    onUpdateTarget({ 
                        direct_payments: update(edits.direct_payments, {
                            revenue_share: {
                                reservations: {
                                    percentage: {
                                        $set: val
                                    }
                                }
                            }
                        })
                    });
                }
            }]
        }]
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Editing Payment Preferences'}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const ICHMethodDetails = ({ abstract, index, options, utils }) => {

    const layerID = 'ich-method-details-' + abstract.getID();
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState(abstract.object);
    const [note, setNote] = useState(null);

    const [dropDown, setDropDown] = useState(null);
    const dropDownMethods = {
        visible: true,
        onClose: () => setDropDown(null)
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'lost',
                title: 'Report as Lost',
                style: 'destructive'
            },{
                key: 'stolen',
                title: 'Report as Stolen',
                style: 'destructive'
            },{
                key: 'close',
                title: 'Close Card',
                style: 'destructive'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
        })
    }

    const onRevealCardNumber = async () => {
        try {
            setLoading(true);
            let { card_number, note } = await Request.get(utils, '/card/', {
                type: 'virtual_card_details',
                token: API.seeds.token,
                card_id: method.id,
                user_id: method.user.user_id,
                stripe_card_holder_id: method.user.stripe_card_holder_id
            });
            if(!card_number) {
                throw new Error('An unknown error occurred');
            }
            utils.alert.show({
                title: 'Card Number',
                message: Utils.formatCardNumber(card_number)
            })

            // update notes with new entry
            if(!abstract.object.seeds || !abstract.object.seeds.notes) {
                abstract.object.seeds = {
                    ...abstract.object.seeds,
                    notes: []
                }
            }

            let target = Note.create(note);
            target.submitted_by = utils.user.get();
            abstract.object.seeds.notes.push(target);

            setLoading(false);
            setNote(target);
            setMethod(abstract.object);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the card number for ${abstract.getTitle()}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSetStatus = () => {
        // TODO => implement set status logic
        utils.alert.dev();
    }

    const onViewVirtualCardNumber = () => {
        if(loading === true) {
            return;
        }
        utils.alert.show({
            title: 'Verification',
            message: 'Please enter your account password to continue',
            textFields: [{
                key: 'password',
                placeholder: 'Password',
                secure: true
            }],
            buttons: [{
                key: 'done',
                title: 'Done',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: async response => {
                if(!response || !response.password) {
                    return;
                }
                if(response.password !== utils.user.get().password) {
                    await Utils.sleep(0.5);
                    utils.alert.show({
                        title: 'Oops!',
                        message: 'The password that you entered is incorrect'
                    });
                    return;
                }
                onRevealCardNumber();
            }
        })
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Card Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: method.id
            },{
                key: 'status',
                title: 'Status',
                value: Utils.ucFirst(method.status)
            },{
                key: 'brand',
                title: 'Brand',
                value: method.brand
            },{
                key: 'currency',
                title: 'Currency',
                value: method.currency ? method.currency.toUpperCase() : 'Unknown'
            },{
                key: 'expiration',
                title: 'Expiration',
                value: method.expiration.month && method.expiration.year ? `${method.expiration.month}/${method.expiration.year}` : 'Not Available'
            },{
                key: 'last4',
                title: method.type === 'virtual' ? 'Card Number' : 'Card Number Last 4',
                value: method.type !== 'virtual' && method.method.last4,
                onClick: method.type === 'virtual' && onViewVirtualCardNumber
            }]
        },{
            key: 'controls',
            title: 'Controls',
            items: [{
                key: 'allowed_categories',
                title: 'Allowed Categories',
                value: method.auth_controls.allowed_categories ? Utils.oxfordImplode(method.auth_controls.allowed_categories) : 'All Categories'
            },{
                key: 'blocked_categories',
                title: 'Blocked Categories',
                value: method.auth_controls.blocked_categories && Utils.oxfordImplode(method.auth_controls.blocked_categories)
            },{
                key: 'spending_limits',
                title: 'Spending Limits',
                value: method.auth_controls.spending_limits && Utils.oxfordImplode(method.auth_controls.spending_limits.map(l => {
                    return `${Utils.toCurrency(l.amount / 100)} ${Utils.ucFirst(l.interval)}`;
                }))
            }]
        }];
    }

    useEffect(() => {
        if(method.seeds && method.seeds.notes) {
            setNote(method.seeds.notes.find(note => note.deleted !== true));
        }
    }, [method]);

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle()}
        index={index}
        dropDown={dropDown}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'status',
            text: method.status === 'active' ? 'Deactivate' : 'Activate',
            color: method.status === 'active' ? 'danger' : 'primary',
            onClick: onSetStatus
        },{
            key: 'options',
            text: 'Options',
            color: method.status === 'active' ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }]}>

            <LayerItem title={'Card Holder'}>
                {Views.entry({
                    title: method.user.full_name,
                    subTitle: method.user.phone_number,
                    icon: {
                        path: method.user.avatar
                    },
                    bottomBorder: false,
                    onClick: Utils.users.details.bind(this, utils, method.user)
                })}
            </LayerItem>

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const NewCreditsCharge = ({ abstract, index, options, utils }) => {

    const layerID = `new-credits-charge-${abstract.getID()}`;
    const [amount, setAmount] = useState('0.00');
    const [creditsCard, setCreditsCard] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState(null);

    const onChargeCredits = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            // require at least $0.50 for each transaction
            if(isNaN(amount) || amount <= 0.50) {
                throw new Error('Please choose an amount greater than $0.50');
            }

            // require that notes are added before moving on
            if(!notes) {
                throw new Error('Notes must be added to explain this charge. Notes are visible by admininstrators and anyone with access to this credits card');
            }

            // verify that company card objects are setup correctly if applicable
            if(creditsCard.is_company_card === true && !creditsCard.company) {
                throw new Error('We were unable to locate the company details for this credits account. Please verify that the credits account is setup correctly.');
            }

            // verify that company card objects are setup correctly if applicable
            if(creditsCard.is_company_card === false && !creditsCard.user) {
                throw new Error('We were unable to locate the user details for this credits account. Please verify that the credits account is setup correctly.');
            }

            // send request to the server
            let { card } = await Request.post(utils, '/credits/', {
                type: 'remove',
                amount: amount,
                category: creditsCard.type,
                company_id: creditsCard.is_company_card && creditsCard.company.id,
                notes: notes,
                user_id: !creditsCard.is_company_card && creditsCard.user.user_id
            });

            // update abstract target with new card details
            abstract.object.card = {
                ...abstract.object.card,
                ...card
            };

            // notify subscribers of data change
            utils.content.update(abstract);
            utils.content.fetch('credits');

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `A charge for ${Utils.toCurrency(amount)} has been processed and deducted from this credits account`,
                onClick: setLayerState.bind(this, 'close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating this charge. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            lastItem: true,
            items: [{
                key: 'amount',
                title: 'Amount',
                description: 'This is the amount of credits that you would like to deduct from this account.',
                value: amount,
                component: 'textfield',
                onChange: val => setAmount(val),
                props: {
                    prepend: '$'
                }
            },{
                key: 'notes',
                title: 'Notes',
                description: 'Notes are required for any charge made to a customer or company credits account. Anyone with visibility to this account will be able to view these notes.',
                value: notes,
                component: 'textview',
                onChange: text => setNotes(text)
            }]
        }]
    }

    return (
        <Layer
        id={layerID}
        title={`Credits Charge for ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onChargeCredits
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const NewSubscription = ({ abstract, index, options, utils }) => {

    const layerID = 'new-subscription';
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [subscription, setSubscription] = useState(abstract.object.edits);

    const onSubmitSubscription = async () => {
        try {
            if(loading === true) {
                return;
            }
            setLoading(true);
            await Utils.sleep(1);
            await validateRequiredFields(getFields);
            await abstract.object.submit(utils);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `The "${subscription.plan.name}" subscription has been added to the account for ${subscription.customer.full_name}`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating this subscription. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUpdateTarget = props => {
        try {
            let edits = abstract.object.set(props);
            setSubscription(edits);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue saving your selection. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getFields = () => {
        if(!subscription) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'customer',
                title: 'Customer',
                description: 'The customer for a subscription will be the individual who gets to take advantage of the discounts provided by the subscription. This subscription will appear in their account as an available booking option.',
                value: subscription.customer,
                component: 'user_lookup',
                onChange: user => onUpdateTarget({ customer: user })
            },{
                key: 'plan',
                title: 'Subscription Plan',
                description: 'The plan for a subscription dictates which benefits and discounts are made available to the customer.',
                value: subscription.plan,
                component: 'list',
                items: plans.map(plan => ({
                    id: plan.id,
                    title: plan.name
                })),
                onChange: item => {
                    onUpdateTarget({
                        plan: item && plans.find(plan => {
                            return plan.id === item.id
                        })
                    });
                }
            },{
                key: 'should_activate',
                title: 'Activate on Creation',
                description: 'You can choose whether or not this subscription is activated immediately following your submission. The recommended route is to automatically actiate the subscription.',
                value: subscription.should_activate,
                component: 'bool_list',
                onChange: val => onUpdateTarget({ should_activate: val })
            }]
        }];
    }

    const fetchSubscriptionPlans = async () => {
        try {
            let { subscriptions } = await Request.get(utils, '/subscriptions/',  {
                type: 'all_plans_admin'
            });
            setLoading(false);
            setPlans(subscriptions.map(plan => Subscription.Plan.create(plan)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the subscription plans list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setSubscription(edits);
    }

    useEffect(() => {
        setupTarget();
        fetchSubscriptionPlans();
    }, []);

    return (
        <Layer
        id={layerID}
        title={'New Subscription'}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'medium',
            loading: loading,
            layerState: layerState,
            removeOverflow: true
        }}
        buttons={[{
            key: 'cancel',
            text: 'Discard',
            color: 'grey',
            onClick: utils.alert.discard.bind(this, () => {
                let edits = abstract.object.open();
                setSubscription(edits);
            })
        },{
            key: 'save',
            text: 'Save',
            color: 'primary',
            onClick: onSubmitSubscription
        }]}>
            <AltFieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const PaymentDetails = ({ abstract, index, options, utils }) => {

    const layerID = `payment-details-${abstract.getID()}`;
    const [dropDown, setDropDown] = useState(null);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [payment, setPayment] = useState(abstract.object);

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                visible: utils.user.get().level <= User.level.admin,
                style: 'default'
            },{
                key: 'receipt',
                title: 'Resend Receipt',
                style: 'default'
            },{
                key: 'dispute',
                title: 'Submit Dispute Evidence',
                visible: payment.dispute && payment.dispute.evidence && utils.user.get().level <= User.level.admin ? true : false,
                style: 'default'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'receipt') {
                onResendReceipt();
                return;
            }
            if(key === 'dispute') {
                onPrepareDispute();
                return;
            }
        })
    }

    const onPrepareDispute = () => {

        if(!payment.dispute.evidence.can_submit) {
            utils.alert.show({
                title: 'Just a Second',
                message: `The period to submit evidence for this dispute has passed. The resolution has been posted and can not be reversed`
            });
            return;
        }

        if(payment.dispute.evidence.submitted) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Evidence has already been submitted for this dispute. Updates to the dispute will become available as the customer, the customer's financial institution, and our payment processer make progress towards a resolution.`
            });
            return;
        }

        utils.alert.show({
            title: 'Disputes and Evidence',
            message: `This charge was marked as "${payment.dispute.reason.text}" by ${payment.customer.full_name} when it appeared on ${payment.customer.first_name}'s bank statement. We can automatically submit evidence for this charge to the customer's financial institution if you believe that this charge was posted in good faith. If no action is taken then the funds acquired through this transaction will automatically be deducted from your account.`,
            buttons: [{
                key: 'confirm',
                title: 'Submit Evidence',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Maybe Layer',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSubmitEvidence();
                }
            }
        });
    }

    const onProcessRefund = async props => {
        try {
            setLoading(true);
            await Request.post(utils, '/payment/', {
                type: 'refund',
                charge_id: payment.stripe_id,
                ...props
            });

            setLoading(false);
            abstract.object.refunded = moment();
            setPayment(abstract.object);

            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `A refund for ${Utils.toCurrency(props.amount)} has been processed for ${abstract.getTitle()}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue processing this refund. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onRefundPayment = () => {
        if(payment.data && payment.data.refund_amount) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This payment has already been refunded. We are not able to process an additional refund for this transaction'
            });
            return;
        }

        let props = { amount: payment.amount }
        setDropDown({
            title: 'Refund Payment',
            message: `You can submit a partial or full refund to be credited back to the original payment method used for an order or reservation. Refunds can take up to 5-7 days to appear on a customer's account`,
            buttons: [{
                key: 'cancel',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'done',
                text: 'Submit',
                color: 'primary',
                onAsyncClick: () => onValidateRefundProps(props)
            }],
            content: (
                <div style={{
                    width: '100%'
                }}>
                    <TextField
                    prepend={'$'}
                    value={props.amount.toString()}
                    containerStyle={{
                        marginBottom: 8
                    }}
                    onChange={text => {
                        props = {
                            ...props,
                            amount: isNaN(text) ? props.amount : text
                        }
                    }} />

                    <select
                    className={`custom-select ${utils.user.get().theme}`}
                    onChange={evt => {
                        props = {
                            ...props,
                            reason: Utils.attributeForKey.select(evt, 'id')
                        }
                    }}>
                        <option disabled={true}>{'Select a Reason'}</option>
                        <option id={'fraudulent'}>{'Fraudulent'}</option>
                        <option id={'duplicate'}>{'Duplicate'}</option>
                        <option id={'requested_by_customer'}>{'Requested by Customer'}</option>
                    </select>
                </div>
            )
        })
    }

    const onResendReceipt = () => {

        let email_address = false;
        if(payment.order) {
            email_address = payment.order.customer.email_address;
        }
        if(payment.reservation) {
            email_address = payment.reservation.customer.email_address;
        }
        if(payment.subscription) {
            email_address = payment.subscription.customer.email_address;
        }

        let props = { email_address: email_address }
        setDropDown({
            title: 'Resend Receipt',
            message: `You can resend the receipt for this transaction to the original customer or to another recipient. We have prefilled the original customer's email address below`,
            buttons: [{
                key: 'cancel',
                text: 'Dismiss',
                color: 'grey'
            },{
                key: 'send',
                text: 'Send',
                color: 'primary',
                onAsyncClick: () => onValidateReceiptProps(props)
            }],
            content: (
                <div style={{
                    width: '100%'
                }}>
                    <TextField
                    value={props.email_address}
                    onChange={text => {
                        props = { email_address: text }
                    }} />
                </div>
            )
        })
    }

    const onSendReceipt = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading('send');
                await Request.post(utils, '/payment/', {
                    type: 'resend_receipt',
                    email_address: props.email_address,
                    order_id: payment.order ? payment.order.id : null,
                    reservation_id: payment.reservation ? payment.reservation.id : null,
                    subscription_id: payment.subscription ? payment.subscription.id : null
                });
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `We have resent this receipt to "${props.email_address}"`
                });
                resolve();

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    const onSubmitEvidence = async () => {
        try {
            setLoading(true);
            await Request.post(utils, '/payment/', {
                type: 'dispute_evidence',
                payment_id: payment.id,
                dispute_id: payment.dispute.id
            });

            setLoading(false);
            utils.content.fetch('payments');
            utils.alert.show({
                title: 'All Done!',
                message: `The evidence for this dispute has been submitted. Updates to this dispute will become available as progress is made between the customer, the customer's financial institution, and our payment processor`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue submitting the dispute evidence. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onUserClick = async userID => {
        try {
            setLoading(true);
            let user = await User.get(utils, userID);

            setLoading(false);
            Utils.users.details(utils, user);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the details for this user account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onValidateReceiptProps = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!props.email_address) {
                    throw new Error('Please enter an email address before sending the receipt');
                }
                await onSendReceipt(props);
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    const onValidateRefundProps = async props => {
        return new Promise((resolve, reject) => {
            try {
                if(!props.reason) {
                    throw new Error('Please enter a reason for submitting this refund');
                }
                if(!props.amount || isNaN(props.amount) || props.amount < 0.5) {
                    throw new Error('Please enter an amount greater than $0.50');
                }
                if(props.amount > payment.amount) {
                    throw new Error('The refund amount can not exceed the total amount for this transaction');
                }
                resolve();
                onProcessRefund(props);
            } catch(e) {
                reject(e);
            }
        })
    }

    const getFields = () => {
        let fields = [];
        if(payment.dispute) {
            fields = fields.concat([{
                key: 'dispute',
                title: 'Dispute Details',
                items: [{
                    key: 'amount',
                    title: 'Amount',
                    value: Utils.toCurrency(payment.dispute.amount)
                },{
                    key: 'message',
                    title: 'Message',
                    value: payment.dispute.message
                },{
                    key: 'date',
                    title: 'Date',
                    value: Utils.formatDate(payment.dispute.date)
                },{
                    key: 'reason',
                    title: 'Reason',
                    value: payment.dispute.reason ? payment.dispute.reason.text : null
                },{
                    key: 'status',
                    title: 'Status',
                    value: payment.dispute.status ? payment.dispute.status.text : null
                }]
            },{
                key: 'dispute_evidence',
                title: 'Dispute Evidence',
                items: [{
                    key: 'submitted',
                    title: 'Submitted',
                    value: payment.dispute.evidence.submitted ? 'Yes' : 'No'
                },{
                    key: 'due_date',
                    title: 'Due Date',
                    value: Utils.formatDate(payment.dispute.evidence.due_date)
                },{
                    key: 'receipt_documentation',
                    title: 'Receipt Documentation',
                    visible: payment.dispute.evidence.receipt ? true : false,
                    value: 'Click to View',
                    onClick: () => window.open(payment.dispute.evidence.receipt)
                },{
                    key: 'service_documentation',
                    title: 'Service Documentation',
                    visible: payment.dispute.evidence.service ? true : false,
                    value: 'Click to View',
                    onClick: () => window.open(payment.dispute.evidence.service)
                },{
                    key: 'documentation_description',
                    title: 'Documentation Description',
                    visible: payment.dispute.evidence.description ? true : false,
                    value: payment.dispute.evidence.description
                }]
            }])
        }

        fields.push({
            key: 'transaction',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: payment.id
            },{
                key: 'type',
                title: 'Processing Type',
                value: payment.getType()
            },{
                key: 'gross_amount',
                title: 'Gross Pay',
                value: Utils.toCurrency(payment.gross_amount)
            },{
                key: 'net_amount',
                title: 'Net Pay',
                value: Utils.toCurrency(payment.net_amount)
            },{
                key: 'submitted',
                title: 'Submission Date',
                visible: payment.date ? true : false,
                value: payment.date && Utils.formatDate(payment.date)
            },{
                key: 'approved',
                title: 'Approval Date',
                visible: payment.approved ? true : false,
                value: payment.approved && Utils.formatDate(payment.approved)
            },{
                key: 'refunded',
                title: 'Refund Date',
                visible: payment.refunded ? true : false,
                value: payment.refunded && Utils.formatDate(payment.refunded)
            },{
                key: 'refund_amount',
                title: 'Refund Amount',
                visible: payment.data && payment.data.refund_amount ? true : false,
                value: payment.data ? Utils.toCurrency(payment.data.refund_amount) : null
            }]
        })

        if(payment.metadata) {

            // add cost calculation line items for transparency
            if(payment.metadata.line_items) {

                // prepare invoice items
                let invoiceItems = payment.metadata.line_items.map((item, index) => ({
                    key: index,
                    title:  item.title,
                    value: item.message
                }));

                // add transfer line item if applicable
                if(payment.metadata.transfer) {

                    // prepare estimated total value
                    let total = invoiceItems[invoiceItems.length - 1];
                    total = total && total.value.replace(/[^\d.-]/g,'') || 0;

                    // add transfer amount and new total to list of items
                    invoiceItems = invoiceItems.concat([{
                        key: 'transfer',
                        title: 'Direct Payments Transfer',
                        value: `-${Utils.toCurrency(payment.metadata.transfer.amount)}`
                    },{
                        key: 'post_transfer_total',
                        title: 'Post Transfer Total',
                        value: `${Utils.toCurrency(total - payment.metadata.transfer.amount)}`
                    }])
                }

                // add invoice items to list of field sections
                fields.push({
                    key: 'invoice',
                    title: 'Cost Calculation',
                    items: invoiceItems
                });
            }

            // add preauthorization data if applicable
            if(payment.metadata.captured || payment.metadata.released) {
                fields.push({
                    key: 'preauthorization',
                    title: 'Preauthorization',
                    items: [{
                        key: 'authorized',
                        title: 'Authorized',
                        value: Utils.toCurrency(payment.metadata.authorized || 0)
                    },{
                        key: 'captured',
                        title: 'Captured',
                        value: Utils.toCurrency(payment.metadata.captured || 0),
                        visible: payment.metadata.captured ? true : false
                    },{
                        key: 'released',
                        title: 'Released',
                        value: Utils.toCurrency(payment.metadata.released || 0),
                        visible: payment.metadata.released ? true : false
                    },{
                        key: 'overage',
                        title: 'Overage',
                        value: Utils.toCurrency(payment.metadata.overage || 0),
                        visible: payment.metadata.overage ? true : false
                    },{
                        key: 'stripe_compliance_fee',
                        title: 'Stripe Compliance Fee',
                        value: Utils.toCurrency(payment.metadata.stripe_compliance_fee || 0),
                        visible: payment.metadata.stripe_compliance_fee ? true : false
                    }]
                });
            }

            // add transfer data if applicable
            if(payment.metadata.transactions) {
                fields = fields.concat(payment.metadata.transactions.map((transaction, index) => {

                    let { client_transfer, commission_transfer, direct_transfer, driver_tip_transfer, order_host_transfer, processing_fee, referral_transfer, service_fee } = transaction;

                    // prepare list of transaction metadata items
                    let items = [{
                        key: 'stripe_id',
                        title: 'ID',
                        value: transaction.stripe_id
                    },{
                        key: 'target_amount',
                        title: 'Charge Amount',
                        value: Utils.toCurrency(transaction.amount || 0),
                        visible: transaction.amount ? true : false
                    }];

                    // add processing fee to list of metadata items if applicable
                    if(processing_fee) {
                        items.push({
                            key: 'processing_fee',
                            title: 'Processing Fee',
                            value: Utils.toCurrency(processing_fee)
                        })
                    }

                    // add platform service fee to list of metadata items if applicable
                    if(service_fee) {
                        items.push({
                            key: 'service_fee',
                            title: 'Platform Fee',
                            value: Utils.toCurrency(service_fee)
                        });
                    }

                    // add order host transfer entry to list of metadata items if applicable
                    if(order_host_transfer) {
                        items.push({
                            key: 'order_host_transfer',
                            title: `${payment.order.host.name} Transfer`,
                            value: Utils.toCurrency(order_host_transfer.amount)
                        });
                    }

                    // add driver tip transfer entry to list of metadata items if applicable
                    if(driver_tip_transfer) {
                        items.push({
                            key: 'driver_tip_transfer',
                            title: 'Driver Tip Transfer',
                            value: Utils.toCurrency(driver_tip_transfer.amount)
                        });
                    }

                    // add commission transfer entry to list of metadata items if applicable
                    if(commission_transfer || direct_transfer) {
                        let transfer = commission_transfer || direct_transfer;
                        items.push({
                            key: 'commission_transfer',
                            title: transfer.rate > 0 ? `Commission Transfer (${transfer.rate * 100}%)` : 'Commission Transfer',
                            value: Utils.toCurrency(transfer.amount)
                        });
                    }

                    // add driver tip transfer entry to list of metadata items if applicable
                    if(referral_transfer) {
                        items.push({
                            key: 'referral_transfer',
                            title: referral_transfer.rate > 0 ? `Referral Revenue Share Transfer (${referral_transfer.rate * 100}%)` : 'Referral Revenue Share Transfer',
                            value: Utils.toCurrency(referral_transfer.amount)
                        });
                    }

                    // add driver tip transfer entry to list of metadata items if applicable
                    if(client_transfer) {
                        items.push({
                            key: 'client_transfer',
                            title: `${utils.client.get().name} Transfer`,
                            value: Utils.toCurrency(client_transfer.amount)
                        });
                    }

                    return {
                        key: `transaction_${index}`,
                        title: `${Utils.integerToOrdinal(index + 1)} Transaction`,
                        items: items
                    }
                }));
            }
        }

        return fields;
    }

    useEffect(() => {
        if(payment.seeds && payment.seeds.notes) {
            setNote(payment.seeds.notes.find(note => !note.deleted))
        }
    }, [payment]);

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle()}
        dropDown={dropDown}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            removeMobilePadding: true
        }}
        buttons={[{
            key: 'refund',
            text: 'Refund',
            color: 'danger',
            visible: utils.user.get().level <= User.level.admin,
            onClick: onRefundPayment
        },{
            key: 'options',
            text: 'Options',
            color: 'primary',
            onClick: onOptionsClick
        }]}>

            {payment.customer && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: payment.customer.full_name,
                        subTitle: payment.customer.phone_number,
                        icon: {
                            path: payment.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, payment.customer)
                    })}
                </LayerItem>
            )}

            {payment.company && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: payment.company.name,
                        subTitle: payment.company.address,
                        icon: {
                            path: payment.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, payment.company)
                    })}
                </LayerItem>
            )}

            {payment.order && (
                <LayerItem title={`${payment.order.channel.name} Order`}>
                    {Views.entry({
                        title: `${payment.order.customer.full_name} (${payment.order.id})`,
                        subTitle: `Ordered from ${payment.order.host.name}`,
                        supportingTitle: moment(payment.order.drop_off_date).format('MMMM Do [at] h:mma'),
                        icon: {
                            style: Appearance.icons.standard(),
                            path: payment.order.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.orders.details.bind(this, utils, payment.order)
                    })}
                </LayerItem>
            )}

            {payment.reservation && (
                <LayerItem title={'Reservation'}>
                    {Views.entry({
                        title: `${payment.reservation.customer.full_name} (${payment.reservation.id})`,
                        subTitle: `To ${payment.reservation.origin.address}`,
                        supportingTitle: moment(payment.reservation.pickup_date).format('MMMM Do [at] h:mma'),
                        badge: {
                            text: payment.reservation.vehicle.name,
                            color: Appearance.colors.primary()
                        },
                        icon: {
                            style: Appearance.icons.standard(),
                            path: payment.reservation.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.reservations.details.bind(this, utils, payment.reservation)
                    })}
                </LayerItem>
            )}

            {payment.subscription && (
                <LayerItem title={'Subscription'}>
                    {Views.entry({
                        title: payment.subscription ? payment.subscription.plan.name : 'Unknown Subscription',
                        subTitle: `Started on ${moment(payment.subscription.date).format('MMMM Do, YYYY [at] h:mma')}`,
                        badge: {
                            text: payment.subscription.active ? null : 'Not Active',
                            color: Appearance.colors.grey()
                        },
                        icon: {
                            style: Appearance.icons.standard(),
                            path: payment.subscription.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.subscriptions.details.bind(this, utils, payment.subscription)
                    })}
                </LayerItem>
            )}

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const PromoCodeDetails = ({ abstract, index, options, utils }) => {

    const layerID = `promo-code-details-${abstract.getID()}`;
    const limit = 5;

    const [code, setCode] = useState(abstract.object);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);

    const onEditClick = () => {
        utils.layer.open({
            id: `edit-promo-code-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditPromoCode.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                style: 'default'
            },{
                key: 'status',
                title: `${code.active ? 'Deactivate' : 'Activate'} Promo Code`,
                style: code.active ? 'destructive' : 'default'
            }]
        }, key => {
            if(key === 'notes') {
                Utils.notes(utils, abstract);
                return;
            }
        })
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: code.id
            },{
                key: 'title',
                title: 'Title',
                value: code.title
            },{
                key: 'code',
                title: 'Redemption Code',
                value: code.code
            },{
                key: 'description',
                title: 'Description',
                value: code.description
            },{
                key: 'public',
                title: 'Public',
                value: code.public ? 'Yes' : 'No'
            },{
                key: 'status',
                title: 'Status',
                value: code.active ? 'Active' : 'Not Active'
            }]
        },{
            key: 'discount',
            title: 'Discount',
            items: [{
                key: 'type',
                title: 'Type',
                value: code.getDiscountInformation().text
            },{
                key: 'amount',
                title: 'Amount',
                value: code.getDiscountInformation().amount
            },{
                key: 'start_date',
                title: 'Start Date',
                visible: code.start_date ? true : false,
                value: code.start_date ? Utils.formatDate(code.start_date, true) : null
            },{
                key: 'end_date',
                title: 'End Date',
                visible: code.end_date ? true : false,
                value: code.end_date ? Utils.formatDate(code.end_date, true) : null
            }]
        }];
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(code.seeds && code.seeds.notes) {
            setNote(code.seeds.notes.find(note => note.deleted !== true))
        }
    }, [code]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'promotions', {
            onUpdate: next => {
                setCode(next.compare(abstract, fetchSystemEvents));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading
        }}
        buttons={[{
            key: 'options',
            text: 'Options',
            color: 'secondary',
            onClick: onOptionsClick
        },{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }]}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {code.for_users && (
                <LayerItem title={'For Customers'}>
                    {code.for_users.map((user, index) => {
                        return (
                            Views.entry({
                                title: user.full_name,
                                subTitle: user.email_address,
                                icon: {
                                    path: user.avatar
                                },
                                bottomBorder: index !== code.for_users.length - 1,
                                onClick: Utils.users.details.bind(this, utils, user)
                            })
                        )
                    })}
                </LayerItem>
            )}

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />
        </Layer>
    )
}

export const RemoveCredits = ({ abstract, index, options, utils }) => {

    const layerID = `remove-credits-${abstract.getID()}`;
    const [amount, setAmount] = useState('0.00');
    const [creditsCard, setCreditsCard] = useState(abstract.object);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState(null);

    const onRemoveCredits = async () => {
        try {
            setLoading(false);
            await Utils.sleep(1);

            if(amount > creditsCard.card.balance) {
                throw new Error(`The amount can not be greater than the card balance of ${Utils.toCurrency(creditsCard.card.balance)}`);
            }
            if(!notes) {
                throw new Error('Notes must be added to explain why these Credits were removed. Notes are visible by admininstrators and anyone with access to this Credits card');
            }

            let { card } = await Request.post(utils, '/credits/', {
                type: 'remove',
                amount: amount,
                notes: notes,
                category: creditsCard.type,
                user_id: creditsCard.is_company_card ? null : creditsCard.user.user_id,
                company_id: creditsCard.is_company_card ? creditsCard.company.id : null
            });

            setLoading(false);
            abstract.object.card = {
                ...abstract.object.card,
                ...card
            };
            utils.content.update(abstract);
            utils.content.fetch('credits');

            utils.alert.show({
                title: 'All Done!',
                message: `${Utils.toCurrency(amount)} worth of credits have been removed from this credits account`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue removing the credits from this account. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onSubmit = async () => {
        if(isNaN(amount) || amount <= 0.50) {
            utils.alert.show({
                title: 'Oops!',
                message: 'The amount must be greater than $0.50'
            });
            return;
        }
        utils.alert.show.bind(this, {
            title: 'Remove Credits',
            message: `Are you sure that you want to remove ${Utils.toCurrency(amount)} worth of the remaining credits from this account?`,
            buttons: [{
                key: 'confirm',
                title: 'Remove',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Remove',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onRemoveCredits();
                    return;
                }
            }
        });
    }

    const getFields = () => {
        return [{
            key: 'amount',
            title: 'Amount',
            value: amount,
            component: 'textfield',
            onChange: val => setAmount(val),
            props: {
                prepend: '$'
            }
        },{
            key: 'notes',
            title: 'Notes',
            value: notes,
            component: 'textview',
            onChange: text => setNotes(text)
        }];
    }

    return (
        <Layer
        id={layerID}
        title={`Remove Credits from ${abstract.getTitle()}`}
        index={index}
        utils={utils}
        options={{
            ...options,
            sizing: 'small',
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onSubmit
        }]}>
            <FieldMapper
            utils={utils}
            fields={getFields()} />
        </Layer>
    )
}

export const SubscriptionDetails = ({ abstract, index, options, utils }) => {

    const layerID = `subscription-details-${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [subscription, setSubscription] = useState(abstract.object);

    const onCancelSubscription = () => {
        utils.alert.show({
            title: 'Cancel Subscription',
            message: `Are you sure that you want to cancel this subscription? This will effect the cost of any upcoming Orders or Reservations that ${subscription.customer.full_name} have booked with this subscription.`,
            buttons: [{
                key: 'confirm',
                title: 'Cancel',
                style: 'cancel'
            },{
                key: 'cancel',
                title: 'Do Not Cancel',
                style: 'default'
            }],
            onClick: async key => {
                if(key === 'confirm') {
                    onCancelSubscriptionConfirm();
                    return;
                }
            }
        })
    }

    const onCancelSubscriptionConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);
            await Request.post(utils, '/subscription/', {
                type: 'cancel',
                subscription_id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('subscriptions');
            utils.alert.show({
                title: 'All Done!',
                message: `Your subscription has been cancelled`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue cancelling your subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                visible: utils.user.get().level <= User.level.admin,
                style: 'default'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
        })
    }

    const fetchLineItems = async () => {
        try {
            setLoading(true);
            let { line_items } = await Request.get(utils, `/subscription/`, {
                type: 'line_items',
                id: abstract.getID()
            });
            setLoading(false);
            setLineItems(line_items);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the details for this subscription. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        if(subscription.seeds && subscription.seeds.notes) {
            setNote(subscription.seeds.notes.find(note => !note.deleted))
        }
    }, [subscription]);

    useEffect(() => {
        fetchLineItems();
    }, []);

    return (
        <Layer
        id={layerID}
        title={abstract.getTitle()}
        index={index}
        options={{
            ...options,
            layerState: layerState,
            removeMobilePadding: true
        }}
        buttons={[{
            key: 'cancel',
            text: 'Cancel',
            color: 'danger',
            onClick: onCancelSubscription
        },{
            key: 'options',
            text: 'Options',
            color: 'primary',
            onClick: onOptionsClick
        }]}>
            {subscription.customer && (
                <LayerItem title={'Customer'}>
                    {Views.entry({
                        title: subscription.customer.full_name,
                        subTitle: subscription.customer.phone_number,
                        icon: {
                            path: subscription.customer.avatar
                        },
                        bottomBorder: false,
                        onClick: Utils.users.details.bind(this, utils, subscription.customer)
                    })}
                </LayerItem>
            )}

            {subscription.company && (
                <LayerItem title={'Company'}>
                    {Views.entry({
                        title: subscription.company.name,
                        subTitle: subscription.company.address,
                        icon: {
                            path: subscription.company.image
                        },
                        bottomBorder: false,
                        onClick: Utils.companies.details.bind(this, utils, subscription.company)
                    })}
                </LayerItem>
            )}

            {lineItems.length > 0 && lineItems.map((section, index) => {
                return (
                    <LayerItem
                    key={index}
                    title={section.title}>
                        {section.items && section.items.map((item, index, items) => {
                            return (
                                Views.row({
                                    key: index,
                                    label: item.title,
                                    value: item.formatted,
                                    bottomBorder: index !== items.length - 1
                                })
                            )
                        })}
                    </LayerItem>
                )
            })}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />

        </Layer>
    )
}

export const SubscriptionPlanDetails = ({ abstract, index, options, utils }) => {

    const layerID = `subscription-plan-details-${abstract.getID()}`;
    const limit = 5;

    const [events, setEvents] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [lineItems, setLineItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [plan, setPlan] = useState(abstract.object);

    const restrictionTargets = [{
        channel: 'orders',
        title: 'Maximum Order Bookings',
        value: 'max_booking'
    },{
        channel: 'orders',
        title: 'Minimum Order Distance',
        value: 'min_distance'
    },{
        channel: 'orders',
        title: 'Maximum Order Distance',
        value: 'max_distance'
    },{
        channel: 'orders',
        title: 'Maximum Order Duration',
        value: 'max_duration'
    },{
        channel: 'reservations',
        title: 'Maximum Reservations Bookings',
        value: 'max_booking'
    },{
        channel: 'reservations',
        title: 'Minimum Reservations Distance',
        value: 'min_distance'
    },{
        channel: 'reservations',
        title: 'Maximum Reservations Distance',
        value: 'max_distance'
    },{
        channel: 'reservations',
        title: 'Maximum Reservations Duration',
        value: 'max_duration'
    }];

    const onEditSubscriptionPlan = () => {
        utils.layer.open({
            id: `edit-subscription-plan-${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditSubscriptionPlan.bind(this, {
                isNewTarget: false
            })
        });
    }

    const onOptionsClick = () => {
        utils.sheet.show({
            items: [{
                key: 'notes',
                title: 'Open Notes',
                visible: utils.user.get().level <= User.level.admin,
                style: 'default'
            },{
                key: 'edit',
                title: 'Edit Subscription Plan',
                visible: utils.user.get().level <= User.level.admin,
                style: 'default'
            }]
        }, key => {
            if(key === 'notes'){
                Utils.notes(utils, abstract);
                return;
            }
            if(key === 'edit') {
                onEditSubscriptionPlan();
                return;
            }
        })
    }

    const onSetActiveStatus = () => {
        utils.alert.show({
            title: plan.active ? 'Deactivate Plan' : 'Activate Plan',
            message: `Are you sure that you want to ${plan.active ? 'deactivate' : 'activate'} this subscription plan? This will ${plan.active ? 'deactivate all subscriptions that take advantage of this plan' : 'allow customers and companies to signup for new subscriptions using this plan'}.`,
            buttons: [{
                key: 'confirm',
                title: plan.active ? 'Deactivate' : 'Activate',
                style: plan.active ? 'destructive' : 'default',
            },{
                key: 'cancel',
                title: plan.active ? 'Do Not Deactivate' : 'Do Not Activate',
                style: plan.active ? 'default' : 'destructive',
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onSetActiveStatusConfirm();
                    return;
                }
            }
        })
    }

    const onSetActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(1);

            let next = !abstract.object.active;
            await Request.post(utils, '/subscription/', {
                type: 'set_plan_status',
                id: abstract.getID(),
                active: next
            });

            setLoading(false);
            abstract.object.active = next;
            utils.content.update(abstract);
            utils.alert.show({
                title: 'All Done!',
                message: `This subscription plan has been ${next ? 'activated and will become immediately available for new subscription signups.' : 'deactivated and will no longer be available for use.'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the status for this subscription plan. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onSystemEventClick = evt => {
        utils.layer.open({
            id: `system-event-details-${evt.id}`,
            abstract: Abstract.create({
                type: 'systemEvents',
                object: evt
            }),
            Component: SystemEventDetails
        });
    }

    const getDiscount = channel => {
        if(!plan.options || !plan.options) {
            return 'Not Setup';
        }
        let entry = plan.options.find(option => option.default);
        if(!entry) {
            return 'Not Setup';
        }
        let { discounts } = entry;
        if(!discounts) {
            return 'Not Setup';
        }
        return discounts[channel] ? `${parseFloat(parseFloat(discounts[channel]) * 100).toFixed(1)}%` : 'Not Setup';
    }

    const getFields = () => {
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: plan.id
            },{
                key: 'name',
                title: 'Name',
                value: plan.name
            },{
                key: 'description',
                title: 'Description',
                value: plan.description
            },{
                key: 'active',
                title: 'Status',
                value: plan.active ? 'Yes' : 'No'
            }]
        },{
            key: 'billing',
            title: 'Billing',
            items: [{
                key: 'cost',
                title: 'Cost',
                value: Utils.toCurrency(plan.cost)
            },{
                key: 'frequency',
                title: 'Frequency',
                value: Subscription.Plan.getBillingText(plan.billing, true)
            }]
        },{
            key: 'discounts',
            title: 'Discounts',
            items: [{
                key: 'orders',
                title: 'Orders',
                value: getDiscount('orders')
            },{
                key: 'reservations',
                title: 'Reservations',
                value: getDiscount('reservations')
            }]
        },{
            key: 'rewards',
            title: 'Rewards',
            items: [{
                key: 'credits',
                title: 'Credits',
                value: plan.rewards && plan.rewards.credits ? Utils.toCurrency(plan.rewards.credits) : 'Not Setup'
            },{
                key: 'trees_planted',
                title: 'Trees Planted',
                value: plan.rewards && plan.rewards.trees ? `${plan.rewards.trees} ${plan.rewards.trees === 1 ? 'tree' : 'trees'}` : 'Not Setup'
            }]
        }];
    }

    const getRestriction = (key, channel, prop) => {
        if(!plan.options || !plan.options) {
            return;
        }
        let entry = plan.options.find(option => {
            let optionKey = option.start && option.end ? `${option.start}-${option.end}` : 'default';
            return key === optionKey;
        });
        if(!entry) {
            return;
        }
        let { restrictions } = entry;
        if(!restrictions) {
            return;
        }

        let value = restrictions[channel] ? restrictions[channel][prop] : null;
        if(!value) {
            return;
        }
        switch(prop) {
            case 'max_booking':
            return `${value} ${value === 1 ? (channel === 'orders' ? 'order' : 'reservation') : channel}`;

            case 'min_distance':
            case 'max_distance':
            return Utils.distanceConversion(value);

            case 'max_duration':
            return Utils.parseDuration(value);

            default:
            return value;
        }
    }

    const getRestrictions = option => {
        if(!option.restrictions) {
            return (
                <LayerItem
                key={index}
                title={getRestrictionTitle(option)}
                childrenStyle={{
                    padding: '8px 12px 8px 12px'
                }}>
                    <span style={{
                        ...Appearance.textStyles.key(),
                        display: 'block'
                    }}>{'No restrictions have been setup'}</span>
                </LayerItem>

            )
        }
        return (
            <LayerItem
            key={index}
            title={getRestrictionTitle(option)}>
                {restrictionTargets.filter(target => {
                    let key = option.start && option.end ? `${option.start}-${option.end}` : 'default';
                    return getRestriction(key, target.channel, target.value) ? true : false;
                }).map((target, index, targets) => {
                    let key = option.start && option.end ? `${option.start}-${option.end}` : 'default';
                    let value = getRestriction(key, target.channel, target.value);
                    if(!value) {
                        return null;
                    }
                    return (
                        <div
                        key={index}
                        className={'px-3 py-2'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: index !== targets.length - 1 ? `1px solid ${Appearance.colors.divider()}` : null
                        }}>
                            <span style={Appearance.textStyles.key()}>{target.title}</span>
                            <span style={Appearance.textStyles.value()}>{value}</span>
                        </div>
                    )
                })}
            </LayerItem>
        )
    }

    const getRestrictionTitle = option => {
        return option.start && option.end ? `${moment(option.start, 'HH:mm:ss').format('h:mma')} to ${moment(option.end, 'HH:mm:ss').format('h:mma')} Restrictions` : 'Default Restrictions';
    }

    const getSystemEvents = () => {
        if(events.length === 0) {
            return null;
        }
        return (
            <LayerItem title={'Recent Changes'}>
                {Utils.getSystemEventsList(events, onSystemEventClick)}
                {paging && (
                    <PageControl
                    description={paging}
                    limit={limit}
                    offset={offset}
                    onClick={next => setOffset(next)} />
                )}
            </LayerItem>
        )
    }

    const fetchSystemEvents = async () => {
        try {
            setLoading(true);
            let { events, paging } = await Request.get(utils, '/resources/', {
                type: 'system_events',
                limit: limit,
                offset: offset,
                target_id: abstract.getID(),
                target_type: abstract.type
            });

            setLoading(false);
            setPaging(paging);
            setEvents(events.map(evt => SystemEvent.create(evt)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the system events list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchSystemEvents();
    }, [offset]);

    useEffect(() => {
        if(plan.seeds && plan.seeds.notes) {
            setNote(plan.seeds.notes.find(note => !note.deleted))
        }
    }, [plan]);

    useEffect(() => {
        utils.content.subscribe(layerID, 'subscriptionPlans', {
            onUpdate: next => {
                setPlan(next.compare(abstract, fetchSystemEvents));
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        id={layerID}
        title={`Details for "${abstract.getTitle()}"`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            layerState: layerState
        }}
        buttons={[{
            key: 'status',
            text: plan.active ? 'Deactivate' : 'Activate',
            color: plan.active ? 'danger' : 'primary',
            onClick: onSetActiveStatus
        },{
            key: 'options',
            text: 'Options',
            color: plan.active ? 'primary' : 'secondary',
            onClick: onOptionsClick
        }]}>

            <FieldMapper
            utils={utils}
            fields={getFields()} />

            {plan.options.map((option, index) => {
                return getRestrictions(option);
            })}

            {getSystemEvents()}

            <LayerNote
            note={note}
            utils={utils}
            abstract={abstract} />

        </Layer>
    )
}
