import moment from 'moment-timezone';
import BankAccount from 'classes/BankAccount.js';
import Note from 'classes/Note.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class SaasAccountClass {

    active = false;
    client_id = null;
    colors = null;
    email_address = null;
    facebook = null;
    flavors = [];
    id = null;
    instagram = null;
    location = null;
    login_url = null;
    logos = {};
    name = null;
    owner = null;
    phone_number = null;
    preferences = null;
    privacy = null;
    seeds = false;
    stripe = null;
    subscription = null;
    tagline = null;
    twitter = null;
    website = null;

    constructor() {
        return this;
    }

    create = props => {

        this.active = props.active;
        this.client_id = props.app_id;
        this.colors = props.colors;
        this.email_address = props.contact.email_address;
        this.facebook = props.social.facebook;
        this.flavors = props.flavors;
        this.id = props.id;
        this.instagram = props.social.instagram;
        this.location = props.location && {
            ...props.location,
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.login_url = props.login_url;
        this.logos = props.logo;
        this.name = props.name;
        this.owner = props.owner && User.create(props.owner);
        this.phone_number = props.contact.phone_number;
        this.preferences = props.preferences || {};
        this.privacy = props.privacy;
        this.stripe = props.stripe;
        this.subscription = props.subscription;
        this.tagline = props.tagline;
        this.twitter = props.social.twitter;
        this.website = props.website;
        
        if(this.stripe && this.stripe.payouts && this.stripe.payouts.accounts) {
            this.stripe.payouts.accounts.forEach((account, index) => {
                this.stripe.payouts.accounts[index] = BankAccount.create(account);
            })
        }

        if(props.seeds) {
            this.seeds = props.seeds;
            this.seeds.notes = (props.seeds.notes || []).filter(n => {
                return n.deleted !== true;
            }).map(n => {
                return Note.create(n);
            })
        }

        return this;
    }

    getPaymentMethods = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!this.stripe || !this.stripe.account) {
                    resolve({
                        credits: [],
                        methods: []
                    });
                    return;
                }

                let { sources, default_source } = await Request.get(utils, '/saas/', {
                    type: 'payment_sources',
                    stripe_id: this.stripe.account
                })
                this.payment_methods = sources && sources.data ? sources.data.map(d => {
                    return BankAccount.create({
                        ...d,
                        default: default_source === d.id
                    });
                }) : [];
                resolve({ methods: this.payment_methods });

            } catch(e) {
                reject(e);
            }
        })
    }

    getFormattedAddress = () => {
        let account = this.edits || this;
        let { address, city, state, zipcode } = account.location || {};
        if(!address || !city || !state || !zipcode) {
            return null;
        }
        return `${address ? `${address} ` : ''}${city ? `${city} ` : ''}${state ? `${state} ` : ''}${zipcode ? `${zipcode} ` : ''}`;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            colors: this.colors,
            email_address: this.email_address,
            facebook: this.facebook,
            instagram: this.instagram,
            location: this.location,
            logos: this.logos,
            name: this.name,
            owner: this.owner,
            phone_number: this.phone_number,
            preferences: this.preferences,
            privacy: this.privacy,
            tagline: this.tagline,
            twitter: this.twitter,
            website: this.website
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            ...props
        }
        return this.edits;
    }

    close = props => {
        let target = props || this.edits;
        this.colors = target.colors;
        this.email_address = target.email_address;
        this.facebook = target.facebook;
        this.instagram = target.instagram;
        this.location = target.location;
        this.logos = target.logos;
        this.name = target.name;
        this.owner = this.owner;
        this.phone_number = target.phone_number;
        this.preferences = target.preferences;
        this.privacy = target.privacy;
        this.tagline = target.tagline;
        this.twitter = target.twitter;
        this.website = target.website;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to server
                let { app_id, colors, id, logos } = await Request.post(utils, '/saas/', {
                    color: this.edits.colors && this.edits.colors.regular,
                    type: 'new',
                    ...this.edits
                })

                // set local vales and close edits
                this.client_id = app_id;
                this.colors = colors;
                this.id = id;
                this.logos = logos;
                this.close();

                // notify subscribers that new data is available
                utils.content.fetch('saas');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {

                // send request to server
                let { colors } = await Request.post(utils, '/saas/', {
                    ...this.edits,
                    app_id: this.client_id,
                    color: this.edits.colors ? this.edits.colors.regular : null,
                    type: 'update'
                });

                // update local colors and close edits
                this.colors = colors;
                this.close();

                // notify subscribers of data change
                utils.content.update({
                    type: 'saas',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}
export default {
    new: () => new SaasAccountClass(),
    create: props => new SaasAccountClass().create(props)
};
