import React, { useEffect, useRef, useState } from 'react';

import { animated, useSpring } from 'react-spring';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import LottieView from 'views/Lottie.js';
import PageControl from 'views/PageControl.js';
import ProgressBar from 'views/ProgressBar.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';

const Panel = ({ children, className, headerStyle, index, id, layer, name, onClose, options = {}, style, subTitle, utils }) => {

    const { assist, buttons, callout, download, includeStyles, loading, maxWidth, onSizeChange, paging, removeOverflow, removePadding, rightContent, search } = options || {};

    const contentContainer = useRef(null);
    const headerContainer = useRef(null);
    const searchContainer = useRef(null);

    const [animations, setAnimations] = useSpring(() => ({
        top: -50,
        opacity: 0,
        config: { mass: 1, tension: 180, friction: 12 }
    }));
    const [icon, setIcon] = useState(null);
    const [onHover, setOnHover] = useState(null);
    const [panelState, setPanelState] = useState({ action: null, frame: null });

    const onButtonClick = button => {
        if(loading === true) {
            return;
        }
        if(typeof(button.onClick) === 'function') {
            button.onClick();
        }
    }

    const onDownloadClick = async () => {
        try {
            let url = await download();
            window.open(url);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your downloaded content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onMobileButtonClick = () => {
        let targets = getButtonTargets();
        if(targets.length === 0) {
            return;
        }
        if(targets.length === 1) {
            let { onClick } = targets[0] || {};
            if(typeof(onClick) === 'function') {
                onClick();
            }
            return;
        }
        utils.sheet.show({
            items: targets
        }, key => {
            let { onClick } = targets.find(button => button.key === key) || {};
            if(typeof(onClick) === 'function') {
                onClick();
            }
        });
    }

    const onPanelSizeChange = () => {
        onSizeChange({
            width: contentContainer.current.clientWidth,
            height: contentContainer.current.clientHeight
        })
    }

    const onSetupIcon = () => {
        setIcon(Utils.getClientLottiePanelIcon(utils));
    }

    const getButtons = () => {
        let buttons = getButtonTargets();
        if(Utils.isMobile() === true) {
            return (
                <AltBadge
                onClick={onMobileButtonClick}
                content={{
                    text: 'Options',
                    color: Appearance.colors.grey()
                }}
                style={{
                    padding: '5px 16px 5px 16px',
                    marginLeft: 8
                }}/>
            )
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                {buttons.filter(button => {
                    return button.visible !== false;
                }).map((button, index, buttons) => {

                    let color = Appearance.colors.grey();
                    switch(button.style) {
                        case 'default':
                        case 'primary':
                        color = Appearance.colors.primary();
                        break;

                        case 'secondary':
                        color = Appearance.colors.secondary();
                        break;

                        case 'destructive':
                        color = Appearance.colors.red;
                        break;
                    }

                    return (
                        <AltBadge
                        key={index}
                        button={true}
                        onClick={onButtonClick.bind(this, button)}
                        content={{
                            text: button.title,
                            color: color
                        }}
                        style={{
                            padding: '5px 16px 5px 16px',
                            marginLeft: 8
                        }}/>
                    )
                })}
            </div>
        )
    }

    const getButtonTargets = () => {
        let targets = [];
        if(buttons) {
            targets = targets.concat(buttons);
        }
        if(download) {
            targets.push({
                key: 'download',
                title: 'Download',
                style: 'default',
                onClick: onDownloadClick
            });
        }
        if(assist) {
            targets.push({
                key: 'options',
                title: Utils.isMobile() === true ? 'More Options' : (assist.props.items ? 'Options' : 'Learn More'),
                style: 'grey',
                onClick: utils.onAssistClick.bind(this, { title: name, ...assist.props }, assist.onClick)
            });
        }
        return targets;
    }

    const getSearchFields = () => {

        if(!search || !utils || loading === 'init') {
            return null;
        }
        let user = utils.user.get();
        let { onChange, placeholder, props, rightContent } = search;
        return (
            <div
            ref={searchContainer}
            className={'row m-0'}
            style={{
                width: '100%',
                padding: 12,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                {typeof(onChange) === 'function' && (
                    <div className={'col-12 col-lg p-0'}>
                        <TextField
                        icon={'search'}
                        useDelay={true}
                        placeholder={placeholder || 'Search for something...'}
                        onChange={onChange}
                        containerStyle={{
                            flexGrow: 1
                        }} />
                    </div>
                )}
                {rightContent && (
                    <div className={'col-12 col-lg-2 px-0 pb-0 pt-2 py-lg-0 pl-lg-2 pr-lg-0'}>
                        {rightContent}
                    </div>
                )}
            </div>
        )
    }

    const runAnimations = async () => {
        try {
            await Utils.sleep(0.2 * index);
            setAnimations({
                top: 0,
                opacity: 1
            })
        } catch(e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        if(contentContainer.current && typeof(onSizeChange) === 'function') {
            onPanelSizeChange();
            window.addEventListener('resize', onPanelSizeChange);
            return () => {
                window.removeEventListener('resize', onPanelSizeChange);
            }
        }
    }, [contentContainer.current]);

    useEffect(() => {
        runAnimations();
        onSetupIcon();
    }, []);

    return (
        <animated.div
        className={`panel window ${className || 'col-12'} p-3`}
        style={{
            position: 'relative',
            ...animations
        }}>
            <div style={{
                position: 'relative',
                width: '100%',
                maxWidth: maxWidth,
                paddingTop: name && Utils.isMobile() ? 46 : 0,
                ...style
            }}>
                {/* mobiles panel title */}
                {typeof(name) === 'string' && Utils.isMobile() && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 15,
                        right: 15,
                        height: 50,
                        padding: '12px 8px 12px 12px',
                        backgroundColor: window.theme === 'light' ? 'rgba(235,235,235,1)' : Appearance.colors.divider(),
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        border: `4px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                        textAlign: 'center'
                    }}>
                        <span style={{
                            color: Appearance.colors.text(),
                            fontSize: 16,
                            fontWeight: '800',
                            textAlign: 'center',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{name}</span>
                        {getButtons()}
                    </div>
                )}

                {/* panel container */}
                <div style={{
                    height: '100%',
                    overflow: removeOverflow ? 'visible' : 'hidden',
                    ...includeStyles !== false && Appearance.styles.panel()
                }}>
                    {/* hide top bar if mobile */}
                    <div
                    ref={headerContainer}
                    style={{
                        borderBottom: `1px solid ${window.theme === 'dark' ? 'rgba(35,35,35,1)' : 'rgba(235,235,235,1)'}`,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        display: Utils.isMobile() || !name ? 'none' : 'block',
                        padding: Utils.isMobile() ? '8px 10px 8px 10px' : 10,
                        ...headerStyle
                    }}>
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1,
                                alignItems: 'center'
                            }}>
                                {icon && (
                                    <LottieView
                                    loop={true}
                                    autoPlay={true}
                                    source={icon}
                                    style={{
                                        width: 35,
                                        height: 35,
                                        marginRight: 12
                                    }} />
                                )}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        ...Appearance.textStyles.panelTitle(),
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        lineHeight: 1.1,
                                        marginBottom: 2
                                    }}>{name}</span>
                                    {typeof(subTitle) === 'string' && (
                                        <span style={{
                                            ...Appearance.textStyles.title(),
                                            color: Appearance.colors.subText(),
                                            maxWidth: '100%',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            lineHeight: 1.1
                                        }}>{subTitle}</span>
                                    )}
                                </div>
                            </div>
                            {getButtons()}
                        </div>
                    </div>
                    <div
                    className={'card-body p-0'}
                    style={{
                        position: 'relative'
                    }}>
                        {getSearchFields()}
                        {loading === true && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: 2,
                                borderRadius: 1,
                                overflow: 'hidden'
                            }}>
                                <ProgressBar />
                            </div>
                        )}
                        <div
                        ref={contentContainer}
                        className={`card-body-content`}
                        style={{
                            padding: removePadding || includeStyles === false ? 0 : 15,
                            overflowY: 'scroll',
                            ...removeOverflow && {
                                overflowX: 'visible',
                                overflowY: 'visible'
                            }
                        }}>
                            {children}
                        </div>
                    </div>
                    <PageControl {...paging} />
                </div>
            </div>
        </animated.div>
    )
}
export default Panel;

export const Container = ({ children, column, index, onPanelReorder, panelID, title }) => {

    const ref = useRef(null);
    const [opacity, setOpacity] = useState(0);
    const [top, setTop] = useState(-25);

    const [_, drag, preview] = useDrag({
        type: 'panel',
        item: { type: 'panel', panelID, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        })
    });

    const [, drop] = useDrop({
        accept: 'panel',
        hover(item, monitor) {
          if(!ref.current) {
              return
          }
          const dragIndex = item.index
          const hoverIndex = index
          if(dragIndex === hoverIndex) {
              return;
          }
          const hoverBoundingRect = ref.current.getBoundingClientRect();
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
          const clientOffset = monitor.getClientOffset();
          const hoverClientY = clientOffset.y - hoverBoundingRect.top
          if(dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return;
          }
          if(dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
              return;
          }
          onPanelReorder(dragIndex, hoverIndex);
          item.index = hoverIndex
        }
    })

    useEffect(() => {

        drag(drop(ref));
        setTimeout(() => {
            setTop(0);
            setOpacity(1);
        }, 250 + ((index || 0) * 150));
    }, [])

    return (
        <div
        className={`window ${column || 'col-12'} p-3`}
        style={{
            position: 'relative'
        }}>
            <DragPreviewImage
            connect={preview}
            src={`/images/drag-preview-${window.theme}.png`} />
            <VelocityComponent
                easing={[250, 20]}
                duration={1000}
                animation={{
                    top: top,
                    opacity: opacity
                }}>
                {children}
            </VelocityComponent>
            <div
            ref={ref}
            className={'hover-grab'}
            style={{
                position: 'absolute',
                top: 8,
                left: 85,
                right: 85,
                height: 30,
                backgroundColor: Appearance.colors.transparent
            }} />
        </div>
    )
}
