import React, { useEffect, useRef, useState } from 'react';

import { animated, useSpring } from 'react-spring';
import update from 'immutability-helper';

import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import Draggable from 'react-draggable';
import { Map } from 'views/MapElements.js';
import { NoteEntry } from 'views/NotesManager.js';
import ProgressBar from 'views/ProgressBar.js';
import ResizeObserver from 'react-resize-observer';
import Utils from 'files/Utils.js';
import { VelocityComponent } from 'velocity-react';
import Views, { AltBadge } from 'views/Main.js';

export const CalloutIndex = 4950;
export const EndIndex = 4990;
export const FloatingMenuIndex = 4550;
export const FrontIndex = EndIndex + 10;

export const getLayerSizingWidth = sizing => {
    switch(sizing) {
        case 'small':
        return 400;

        case 'medium':
        return 550;

        case 'extra_large':
        return 1000;

        default:
        return 700;
    }
}

const Layer = ({ buttons, children, dropDown, header, id, index, options = {}, title, utils }) => {

    let { layerState, loading, onActionChange, onClose, onReposition, onSetLayerIndex, onUpdateHeight, position, removePadding, sizing, sticky, zIndex } = options;

    const buttonContainer = useRef(null);
    const headerRef = useRef(null);
    const layerRef = useRef(null);
    const toolbarContainer = useRef(null);

    const [dragging, setDragging] = useState(false);
    const [dropDownProps, setDropDownProps] = useState(null);
    const [dropDownAnimations, setDropDownAnimations] = useSpring(() => ({
        top: -window.innerHeight,
        config: { mass: 1, tension: 180, friction: 22 }
    }));
    const [height, setHeight] = useState(0);
    const [layerPosition, setLayerPosition] = useState(position);
    const [onHover, setOnHover] = useState(null);
    const [opacity, setOpacity] = useState(0); // for tablet and desktop
    const [scale, setScale] = useState(0.75); // for tablet and desktop
    const [shouldShowDropDown, setShouldShowDropDown] = useState(false);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [top, setTop] = useState(window.innerHeight); // for mobile
    const [windowState, setWindowState] = useState({ action: null, frame: null });
    const [zIndexVal, setZIndexVal] = useState(zIndex);

    const onClickDropDownButton = async (button, index) => {
        try {
            if(button && typeof(button.onClick) === 'function') {
                button.onClick();
            }
            if(button && typeof(button.onAsyncClick) === 'function') {
                await button.onAsyncClick();
            }
            setShouldShowDropDown(false);
            await Utils.sleep(0.75);
            setDropDownProps(null);
            if(typeof(dropDownProps.onClose) === 'function') {
                dropDownProps.onClose();
            }
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: e.message || 'An unknown error occurred'
            });
        }
    }
    const onDragEnded = (evt, node) => {

        // mobile
        if(Utils.isMobile()) {

            // default to page height incase mobile device does not return a Y value
            let y = layerRef.current ? layerRef.current.getBoundingClientRect().y : window.innerHeight;
            setTop(y - 36);
            setTimeout(() => {
                setDragging(false);
                if(Utils.isMobile() && y > window.innerHeight / 3) {
                    onWindowActionChange('close');
                } else {
                    setTop(0);
                }
            }, 50);
            return;
        }

        // tablet and desktop
        setDragging(false);
        setLayerPosition({
            y: node.y,
            x: Utils.isMobile() ? 12 : node.x
        });
        if(typeof(onReposition) === 'function') {
            onReposition({
                id: id,
                position: {
                    x: node.x,
                    y: node.y
                }
            });
        }
    }

    const onDragMoved = () => {
        if(!dragging) {
            setDragging(true);
        }
    }

    const onDragStarted = () => {
        onLayerPress();
    }

    const onHeightChange = rect => {
        setHeight(rect.height);
        if(typeof(onUpdateHeight) === 'function') {
            onUpdateHeight(rect.height);
        }
    }

    const onLayerAction = ({ detail }) => {
        if(detail.layerID === id) {
            onWindowActionChange(detail.action);
        }
    }

    const onLayerPress = () => {
        setZIndexVal(FrontIndex);
        if(typeof(onSetLayerIndex) === 'function') {
            onSetLayerIndex(id);
        }
    }

    const onMouseEnter = () => {
        document.body.style.overflowY = 'hidden';
    }

    const onMouseLeave = () => {
        document.body.style.overflowY = 'scroll';
    }

    const onWindowActionChange = action => {
        if(action === 'close') {
            setOpacity(0);
            setScale(0.75);
            setTop(window.innerHeight);
            document.body.style.overflowY = 'scroll';

            if(typeof(onClose) === 'function') {
                setTimeout(onClose.bind(this, id), 500);
            }
            return;
        }
        setWindowState(windowState => update(windowState, {
            action: {
                $set: action === windowState.action ? null : action
            }
        }));
    }

    const onWindowSizeChange = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    const getDropDownComponent = () => {
        if(!dropDownProps) {
            return null;
        }
        let buttons = dropDownProps.buttons || [{
            key: 'cancel',
            text: 'Dismiss',
            color: 'grey',
            onClick: onClickDropDownButton
        }]
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: Appearance.colors.transparent,
                zIndex: FloatingMenuIndex
            }}>
                <VelocityComponent
                duration={750}
                animation={{
                    opacity: shouldShowDropDown ? 1 : 0
                }}>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: Appearance.colors.dim
                    }} />
                </VelocityComponent>
                <animated.div style={{
                    position: 'absolute',
                    backgroundColor: Appearance.colors.layerBackground(),
                    width: '100%',
                    maxWidth: 'calc(100% - 30px)',
                    borderRadius: 15,
                    ...dropDownAnimations
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: 15,
                        width: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.panelTitle(),
                            marginBottom: 4
                        }}>{dropDownProps.title}</span>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            whiteSpace: 'normal',
                            marginBottom: 15
                        }}>{dropDownProps.message}</span>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            overflowY: 'scroll',
                            maxHeight: layerRef.current ? layerRef.current.clientHeight / 2 : 250
                        }}>
                            {dropDownProps.content}
                        </div>
                    </div>
                    <div
                    className={'row m-0'}
                    style={{
                        width: '100%',
                        padding: 15,
                        borderTop: `1px solid ${Appearance.colors.divider()}`
                    }}>
                        {buttons.filter(b => {
                            return b.visible !== false;
                        }).map((button, index, buttons) => {
                            let paddingX = 'px-0';
                            if(buttons.length === 2) {
                                paddingX = index === 0 ? 'pl-0 pr-1' : 'pl-1 pr-0';
                            } else if(buttons.length > 2) {
                                paddingX = index === 1 ? 'px-2' : 'px-0';
                            }
                            return (
                                <div
                                key={index}
                                className={`col-${parseInt(12 / buttons.length)} py-0 ${paddingX}`}>
                                    <Button
                                    {...button}
                                    type={'large'}
                                    label={button.text}
                                    loading={button.loading || loading === button.key}
                                    onClick={onClickDropDownButton.bind(this, button, index)} />
                                </div>
                            )
                        })}
                    </div>
                </animated.div>
            </div>
        )
    }

    const getHeaderContent = () => {
        return (
            <div
            className={dragging ? 'cursor-grabbing' : 'cursor-grab'}
            style={{
                position: 'relative'
            }}>
                <div
                ref={headerRef}
                style={{
                    borderBottom: `1px solid ${window.theme === 'dark' ? 'rgba(35,35,35,1)' : 'rgba(235,235,235,1)'}`,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    padding: Utils.isMobile() ? '8px 10px 8px 10px' : 10
                }}>
                    {header}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <div
                        className={'not-draggable'}
                        style={{
                            width: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center'
                        }}>
                            <AltBadge
                            onClick={onWindowActionChange.bind(this, 'close')}
                            content={{
                                text: 'Close',
                                color: Appearance.colors.red
                            }}
                            style={{
                                width: 60
                            }}/>
                        </div>

                        <span style={{
                            ...Utils.isMobile() === false && Appearance.textStyles.panelTitle(),
                            ...Utils.isMobile() === true && Appearance.textStyles.layerItemTitle(),
                            flexGrow: 1,
                            textAlign: 'center',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}>{title}</span>

                        <div
                        ref={toolbarContainer}
                        style={{
                            width: 100,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center'
                        }} />
                    </div>
                </div>
                {loading === true && (
                    <div style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: 2,
                        overflow: 'hidden',
                        borderRadius: 1,
                    }}>
                        <ProgressBar/>
                    </div>
                )}
            </div>
        )
    }

    const getLayerHeight = () => {
        return window.innerHeight - (buttonContainer.current ? buttonContainer.current.clientHeight : 0) - (headerRef.current ? headerRef.current.clientHeight : 0) - 36;
    }

    const getLayerWidth = () => {
        if(Utils.isMobile() === true) {
            return window.innerWidth - 24;
        }
        let width = getWidthForSizing();
        return size.width > width ? width : size.width - 60;
    };

    const getWidthForSizing = () => {
        return getLayerSizingWidth(sizing);
    }

    useEffect(() => {
        setDropDownProps(dropDown);
    }, [dropDown]);

    useEffect(() => {
        setShouldShowDropDown(dropDownProps ? true : false);
    }, [dropDownProps]);

    useEffect(() => {
        setDropDownAnimations({ top: shouldShowDropDown ? 15 : -window.innerHeight });
    }, [shouldShowDropDown]);

    useEffect(() => {
        setZIndexVal(zIndex);
    }, [options]);

    useEffect(() => {
        if(position) {
            setLayerPosition({
                y: position.y,
                x: Utils.isMobile() ? 12 : position.x
            })
        }
    }, [position]);

    useEffect(() => {
        switch(layerState) {
            case 'close':
            if(opacity !== 0 && scale !== 0.75) {

                setOpacity(0);
                setScale(0.75);
                setTop(window.innerHeight);
                document.body.style.overflowY = 'scroll';

                if(typeof(onClose) === 'function') {
                    setTimeout(onClose.bind(this, id), 500);
                }
            }
            break;
        }

    }, [layerState])

    useEffect(() => {
        if(!layerRef.current) {
            return
        }
        setTimeout(() => {
            setTop(0);
            setScale(1);
            setOpacity(1);
            setHeight(layerRef.current.clientHeight);
        }, 250);

        window.addEventListener('onLayerAction', onLayerAction);
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('onLayerAction', onLayerAction);
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, [layerRef.current]);

    useEffect(() => {
        if(windowState.action === 'close') {
            return;
        }
        if(typeof(onActionChange) === 'function') {
            onActionChange({
                action: windowState.action,
                height: getLayerHeight() - headerRef.current.clientHeight
            })
        }
    }, [windowState])

    return (
        <Draggable
        key={id}
        cancel={'.not-draggable'}
        onStart={onDragStarted}
        onDrag={onDragMoved}
        onStop={onDragEnded}
        defaultPosition={layerPosition || {
            x: (size.width / 2) - (getLayerWidth() / 2),
            y: height ? ((size.height / 2) - (height / 2)) : 50
        }}
        position={layerPosition || {
            x: (size.width / 2) - (getLayerWidth() / 2),
            y: height ? ((size.height / 2) - (height / 2)) : 50
        }}
        {...Utils.isMobile() === true && {
            axis: 'y',
            onTouchEnd: onDragEnded,
            defaultPosition: {
                x: 12,
                y: 12
            },
            position: {
                x: 12,
                y: 12
            }
        }}>
            <div
            id={id}
            style={{
                position: 'fixed',
                zIndex: zIndexVal
            }}>
                <VelocityComponent
                easing={[250, 20]}
                duration={dragging ? 0 : 750}
                animation={{
                    top: top
                }}>
                    <div
                    onClick={onLayerPress}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    style={{
                        position: 'fixed',
                        width: getLayerWidth(),
                        height: 'auto',
                        zIndex: zIndexVal,
                        overflow: 'hidden',
                        boxShadow: '0px 0px 50px rgba(0,0,0,0.2)',
                        border: `3px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)' : 'white'}`,
                        borderRadius: 10,
                        backgroundColor: Appearance.colors.layerBackground(),
                    }}>
                        <ResizeObserver onResize={onHeightChange}/>
                        <div
                        ref={layerRef}
                        style={{
                            flexGrow: 1,
                            padding: 0,
                            margin: 0
                        }}>
                            {getHeaderContent()}
                            <div
                            className={'not-draggable'}
                            style={{
                                position: 'relative'
                            }}>
                                {sticky && sticky.top}
                                <div style={{
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'scroll',
                                    padding: removePadding ? 0 : 12,
                                    maxHeight: getLayerHeight(),
                                }}>
                                    {children}
                                </div>
                                {sticky && sticky.bottom}
                                {buttons && (
                                    <div
                                    ref={buttonContainer}
                                    style={{
                                        padding: 12,
                                        borderTop: `1px solid ${Appearance.colors.divider()}`
                                    }}>
                                        <div style={{
                                            maxWidth: 700,
                                            margin: 'auto'
                                        }}>
                                            <div className={'row p-0 m-0'}>
                                                {buttons.filter(b => {
                                                    return b.visible !== false;
                                                }).map((button, index, buttons) => {
                                                    let paddingX = 'px-0';
                                                    if(buttons.length === 2) {
                                                        paddingX = index === 0 ? 'pl-0 pr-1' : 'pl-1 pr-0';
                                                    } else if(buttons.length > 2) {
                                                        paddingX = index === 1 ? 'px-2' : 'px-0';
                                                    }
                                                    return (
                                                        <div
                                                        key={index}
                                                        className={`col-${parseInt(12 / buttons.length)} py-0 ${paddingX}`}>
                                                            <Button
                                                            type={'large'}
                                                            label={button.text}
                                                            color={button.color}
                                                            loading={button.loading || loading === button.key}
                                                            onClick={button.onClick}/>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {getDropDownComponent()}
                            </div>
                        </div>
                    </div>
                </VelocityComponent>
            </div>
        </Draggable>
    )
}

export const LayerItem = ({ badge, children, childrenStyle, collapsed, headerStyle, lastItem, onVisibilityChange, required, rightContent, shouldStyle, style, subTitle, title }) => {

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        opacity: collapsed ? 0 : 1,
        maxHeight: collapsed ? 0 : 1500,
        config: { mass: 1, tension: 180, friction: 30 }
    }));

    const onCollapseClick = () => {
        let next = !_collapsed;
        _setCollapsed(next);
        if(typeof(onVisibilityChange) === 'function') {
            onVisibilityChange(next);
        }
    }

    const isCollapseEnabled = () => {
        return typeof(_collapsed) === 'boolean' ? true : false;
    }

    const getAnimationsStyles = () => {
        if(isCollapseEnabled()) {
            return {
                overflowX: 'hidden',
                overflowY: 'scroll',
                ...animations
            };
        }
        return null;
    }

    const getCollapseStyles = () => {
        if(!isCollapseEnabled() || _collapsed === false) {
            return {
                marginBottom: lastItem ? 0 : 25
            };
        }
        if(lastItem !== true) {
            return {
                paddingBottom: 2,
                marginBottom: _collapsed ? 10 : 25,
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
            }
        }
        return null;
    }

    useEffect(() => {
        setAnimations({
            opacity: _collapsed ? 0 : 1,
            maxHeight: _collapsed ? 0 : 1500
        });
    }, [_collapsed]);

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    return (
        <div style={{
            width: '100%',
            ...getCollapseStyles(),
            ...style
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                textAlign: 'left'
            }}>
                {required && (
                    <div style={{
                        width: 8,
                        height: 8,
                        minWidth: 8,
                        minHeight: 8,
                        borderRadius: 4,
                        overflow: 'hidden',
                        backgroundColor: Appearance.colors.red,
                        marginRight: 8
                    }} />
                )}
                <div style={{
                    display: 'block',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    jusitfyContent: 'space-between',
                    alignItems: 'center',
                    minWidth: 0,
                    ...headerStyle
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1
                    }}>
                        <span style={{
                            ...Appearance.textStyles.layerItemTitle(),
                            display: 'block',
                            lineHeight: 1.5
                        }}>{title}</span>
                        {typeof(subTitle) === 'string' && (
                            <span style={{
                                display: 'block',
                                color: Appearance.colors.subText(),
                                fontWeight: 600,
                                fontSize: 14,
                                lineHeight: 1.25
                            }}>{subTitle}</span>
                        )}
                    </div>
                    {badge && (
                        <AltBadge
                        content={badge}
                        style={{
                            top: 0,
                            marginLeft: 8,
                            marginRight: 0
                        }} />
                    )}
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    {rightContent}
                    {isCollapseEnabled() && (
                        <CollapseArrow
                        collapsed={_collapsed}
                        onClick={onCollapseClick} />
                    )}
                </div>
            </div>
            <animated.div style={{
                width: '100%',
                marginTop: 8,
                ...shouldStyle !== false && {
                    ...Appearance.styles.unstyledPanel(),
                    borderRadius: 15
                },
                ...childrenStyle,
                ...getAnimationsStyles()
            }}>
                {children}
            </animated.div>
        </div>
    )
}

export const CollapseArrow = ({ collapsed, color, onClick, onWaypointVisible, style, waypoint }) => {

    const [_collapsed, _setCollapsed] = useState(collapsed);
    const [animations, setAnimations] = useSpring(() => ({
        transform: `rotate(${collapsed ? '0deg' : '180deg'})`,
        config: { mass: 1, tension: 180, friction: 16 }
    }));

    const onCollapseClick = evt => {
        let nextCollapse = !_collapsed;
        if(typeof(onClick) === 'function') {
            onClick(nextCollapse, evt);
        }
        _setCollapsed(nextCollapse);
    }

    const getImage = () => {
        switch(color) {
            case Appearance.colors.primary():
            return 'images/down-arrow-blue-small.png';

            default:
            return 'images/down-arrow-grey-small.png';
        }
    }

    useEffect(() => {
        _setCollapsed(collapsed);
    }, [collapsed]);

    useEffect(() => {
        setAnimations({ transform: `rotate(${_collapsed ? '0deg' : '180deg'})` });
    }, [_collapsed]);

    return (
        <div syle={{
            width: 15,
            height: 15,
            ...style
        }}>
            <animated.img
            className={'text-button'}
            onClick={onCollapseClick}
            src={getImage()}
            style={{
                width: 15,
                height: 15,
                objectFit: 'contain',
                ...style,
                ...animations
            }} />
        </div>
    )
}

export default Layer;

export const LayerShell = ({ layerID, children, title, layerOptions, buttons }, { index, options }) => {
    return (
        <Layer
        id={layerID}
        title={title}
        index={index}
        options={{
            ...options,
            ...layerOptions,
            removePadding: true
        }}
        buttons={buttons}>
            {children}
        </Layer>
    )
}

export const LayerMap = (props = {}) => {
    return (
        <Map
        isZoomEnabled={true}
        isRotationEnabled={true}
        isScrollEnabled={true}
        {...props}
        style={{
            height: 350,
            marginBottom: 25,
            ...props.style
        }} />
    )
}

export const LayerEmissions = ({ items, style }) => {

    const getContent = () => {
        if(!items) {
            return (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 12,
                    height: 100
                }}>
                    {Views.loader()}
                </div>
            )
        }

        return (
            <div className={'row p-0 m-0'}>
                {items.map((item, index) => {
                    return (
                        <div
                        key={item.key}
                        className={`col-12 col-md-4 pb-2 px-0 pb-md-0 px-md-1 ${index === 0 ? 'pl-md-0 pr-md-2' : ''} ${index == items.length - 1 ? 'pr-md-0 pl-md-2' : ''} py-md-0`}>
                            <div style={Appearance.styles.unstyledPanel()}>
                                <div className={'card-body p-1 text-center'}>
                                    <img
                                    src={`/images/${item.image}`}
                                    className={'my-1'}
                                    style={{
                                        width: '50%',
                                        maxWidth: 40,
                                        boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
                                        borderRadius: '50%',
                                        backgroundColor: '#88CF82'
                                    }} />
                                    <span
                                    className={'d-block mt-1'}
                                    style={Appearance.textStyles.title()}>{item.value || item.placeholder}</span>
                                    <span
                                    className={'d-block mb-1'}
                                    style={Appearance.textStyles.subTitle()}>{item.title}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <LayerItem
        title={'Emissions'}
        shouldStyle={false}>
            {getContent()}
        </LayerItem>
    )
}

export const LayerNote = ({ abstract, note, utils }) => {
    return (
        <LayerItem
        title={'Recent Notes'}
        shouldStyle={note ? false : true}
        childrenStyle={!note && {
            padding: '8px 12px 8px 12px'
        }}>
            {note && (
                <NoteEntry
                utils={utils}
                note={note}
                options={{
                    showDeleteButton: false
                }} />
            )}
            {!note && (
                <span style={{
                    ...Appearance.textStyles.key(),
                    display: 'block',
                    color: Appearance.colors.text()
                }}>{`No notes have been left for "${abstract.getTitle()}"`}</span>
            )}
        </LayerItem>
    )
}
