import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';
import Utils from 'files/Utils.js';

class DriverClass {

    user_id = null;
    level = null;
    first_name = null;
    last_name = null;
    full_name = null;
    gender = null;
    email_address = null;
    phone_number = null;
    member_since = null;
    avatar = null;

    constructor() {
        return this;
    }

	create = (props = {}) => {
		this.user_id = props.user_id;
		this.level = props.level;
		this.first_name = props.first_name;
		this.last_name = props.last_name;
		this.full_name = props.full_name;
		this.gender = props.gender;
		this.email_address = props.email_address;
		this.phone_number = props.phone_number ? Utils.formatPhoneNumber(props.phone_number) : null;
		this.member_since = moment(props.member_since);
		this.avatar = props.avatar;
        return this;
	}
}

class DriverApplicationClass {

    id = null;
    user_id = null;
    customer = null;
    contact = null;
    date = null;
    application = null;
    images = null;
    status = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
        this.id = props.id;
        this.user_id = props.user_id;
        this.contact = props.contact;
        this.date = props.date ? moment(props.date) : null;
        this.customer = props.customer ? User.create(props.customer) : null;
        this.application = props.application;
        this.images = props.images;
        this.status = props.status ? formatStatus(props.status.code) : null;
        return this;
    }
}

const statusCodes = {
    pending: null,
    approved: 1,
    rejected: 2,
    cancelled: 3,
    infoNeeded: 4
}

const formatStatus = (c) => {

    let code = c === null ? c : parseInt(c);
    let status = {
        code: code
    };

    if(code === statusCodes.rejected) {
        status.text = 'Rejected';
        status.realText = 'Rejected';
        status.color = Appearance.colors.red;

    } else if(code === statusCodes.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance.colors.primary();

    } else if(code === statusCodes.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';

    } else if(code === statusCodes.infoNeeded) {
        status.text = 'Information Needed';
        status.realText = 'Information Needed';
        status.color = Appearance.colors.grey();

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = Appearance.colors.grey();
    }
    return status;
}

const fetchDriver = async (utils, user_id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { driver } = await Request.get(utils, '/driver/', {
                type: 'details',
                user_id: user_id
            });
            let target = new DriverClass().create(driver);
            resolve(driver);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: new DriverClass(),
    create: props => new DriverClass().create(props),
    get: fetchDriver,
    Application: {
        new: new DriverApplicationClass(),
        create: props => new DriverApplicationClass().create(props),
        get: fetchDriver,
        status: statusCodes,
        formatStatus: formatStatus
    }
};
