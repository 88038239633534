import React, { useEffect, useState } from 'react';
import Appearance from 'styles/Appearance.js';
import Request from 'files/Request.js';
import Reservation from 'classes/Reservation.js';
import TextField from 'views/TextField.js';
import Views from 'views/Main.js';

const ReservationLookupField = ({ containerStyle, onChange, placeholder, resultsFilter, utils, value }) => {

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(null);
    const [text, setText] = useState(null);

    const onTextChange = async text => {
        setText(text);
        setSelected(false);
        if(text.length === 0) {
            setResults([]);
            return;
        }
        try {
            setLoading(true);
            let { reservations } = await Request.get(utils, '/reservations/', {
                type: 'all_admin',
                limit: 5,
                search_text: text
            });

            let targets = reservations.map(reservation => Reservation.create(reservation));
            if(typeof(resultsFilter) === 'function') {
                targets = targets.filter(resultsFilter);
            }
            setLoading(false);
            setResults(targets);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing your search. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onReservationClick = reservation => {
        setSelected(reservation);
        if(typeof(onChange) === 'function') {
            onChange(reservation);
        }
    }

    return (
        <div style={{
            textAlign: 'left',
            width: '100%'
        }}>
            <TextField
            loading={loading}
            onChange={onTextChange}
            placeholder={placeholder || 'Search by reservation id...'}
            useDelay={true}
            utils={utils}
            value={text} />
            {results.length > 0 && (
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginTop: 8
                }}>
                    {results.map((result, index) => {
                        return (
                            Views.entry({
                                key: result.id,
                                title: `${result.customer.full_name} (${result.id})`,
                                subTitle: result.destination.address,
                                bottomBorder: index !== results.length - 1,
                                onClick: onReservationClick.bind(this, result),
                                icon: {
                                    path: result.customer.avatar
                                },
                                badge: selected && selected.id === result.id && {
                                    text: 'Selected',
                                    color: Appearance.colors.primary()
                                }
                            })
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default ReservationLookupField;
