import Request from 'files/Request.js';

export default {
    flow: async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let { results } = await Request.get(utils, '/vehicles/', {
                    type: 'here_flow',
                    ...props
                });
                resolve(results || []);
            } catch(e) {
                reject(e);
            }
        })
    },
    trafficAnnotation: type => {
        switch(type) {
            case 'CONST':
            return { path: 'images/construction-annotation-orange.png' }

            case 'SLOW':
            return { path: 'images/slow-traffic-annotation-yellow.png' }

            case 'ACC':
            return { path: 'images/vehicle-accident-annotation-red.png' }

            case 'CLOS':
            return { path: 'images/road-closed-annotation-red.png' }

            default:
            return { path: 'images/traffic-annotation-grey.png' }
        }
    },
    trafficIcon: type => {
        switch(type) {
            case 'CONST':
            return { path: 'images/construction-icon-orange.png' }

            case 'SLOW':
            return { path: 'images/slow-traffic-icon-yellow.png' }

            case 'ACC':
            return { path: 'images/vehicle-accident-icon-red.png' }

            case 'CLOS':
            return { path: 'images/road-closed-icon-red.png' }

            default:
            return { path: 'images/traffic-icon-grey.png' }
        }
    },
    typeToText: type => {
        switch(type) {
            case 'CONST':
            return 'Constrsuction';

            case 'SLOW':
            return 'Slow Traffic';

            case 'ACC':
            return 'Accident';

            case 'CLOS':
            return 'Road Closure';

            default:
            return 'Unknown'
        }
    }
}
