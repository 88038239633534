import moment from 'moment-timezone';
import CreditsCard from 'classes/CreditsCard.js';
import Note from 'classes/Note.js';
import PaymentMethod from 'classes/PaymentMethod.js';
import Request from 'files/Request.js';
import User from 'classes/User.js';

class CompanyClass {

    active = null;
    added_by = null;
	address = null;
    credits = null;
    credit_methods = [];
    direct_payments = null;
	discount_rate = null;
    email_address = null;
    emissions = null;
    id = null;
	image = null;
	information = null;
    location = {};
	key = null;
	member_since = null;
	name = null;
    payment_methods = [];
    referral_code = null;
    seeds = null;
    stripe_customer_id = null;
	total_admins = null;
    total_users = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {
      
        this.active = props.active;
        this.address = props.address;
        this.credits = props.credits;
        this.discount_rate = props.discount_rate;
        this.email_address = props.email_address;
        this.image = props.image || 'images/company-placeholder-clear.png';
        this.id = props.id;
        this.information = props.information;
        this.location = props.location && {
            latitude: props.location.lat,
            longitude: props.location.long
        };
        this.key = props.key;
        this.member_since = props.member_since && moment.utc(props.member_since).local();
        this.name = props.name;
        this.referral_code = props.referral_code;
        this.stripe_customer_id = props.stripe_customer_id;
        this.total_admins = props.total_admins || 0;
        this.total_users = props.total_users || 0;

        this.direct_payments = props.direct_payments && {
            ...props.direct_payments,
            discounts: props.direct_payments.discounts || {}
        };

        this.seeds = props.seeds;
        if(props.seeds) {
            this.seeds.notes = props.seeds.notes.filter(note => !note.deleted).map(note => Note.create(note))
        }
        return this;
    }

    getUsers = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { users } = await Request.get(utils, '/companies/', {
                    type: 'users',
                    company_id: this.id
                });
                resolve({
                    company: this,
                    users: users.map(user => User.create(user))
                });

            } catch(e) {
                reject(e);
            }
        })
    }

    getPaymentMethods = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                // prevent moving forward if no stripe id is set
                if(!this.stripe_customer_id) {
                    resolve({ credits: [], methods: [] });
                    return;
                }

                // send request to server
                let { credits = [], sources = [], default_source } = await Request.get(utils, '/companies/', {
                    type: 'payment_sources',
                    stripe_id: this.stripe_customer_id
                });

                // format credit accounts and payment methods
                this.credit_methods = credits.map(c => CreditsCard.create(c));
                this.payment_methods = sources.data && sources.data.map(d => PaymentMethod.create({ ...d, default: default_source === d.id })) || [];

                resolve({
                    credits: this.credit_methods || [],
                    methods: this.payment_methods || []
                });

            } catch(e) {
                reject(e);
            }
        });
    }

    getEmissionsOverview = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { data } = await Request.get(utils, '/companies/', {
                    type: 'emissions_performance',
                    company_id: this.id
                });
                this.emissions = {
                    gas: data.reduce((total, entry) => {
                        return total += parseFloat(entry.gas)
                    }, 0),
                    carbon: data.reduce((total, entry) => {
                        return total += parseFloat(entry.carbon)
                    }, 0),
                    trees: data.reduce((total, entry) => {
                        return total += parseFloat(entry.trees)
                    }, 0)
                };
                resolve(this.emissions);

            } catch(e) {
                reject(e);
            }
        })
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            address: this.address,
            direct_payments: this.direct_payments || {},
            discount_rate: this.discount_rate,
            email_address: this.email_address,
            key: this.key,
            image: this.image,
            information: this.information,
            location: this.location,
            name: this.name,
            payment_methods: this.payment_methods,
            referral_code: this.referral_code,
            total_admins: this.total_admins,
            total_users: this.total_users
        }
        return this.edits;
    }

    set = props => {
        this.edits = {
            ...this.edits,
            address: props.address || this.edits.address,
            direct_payments: props.direct_payments || this.edits.direct_payments,
            discount_rate: props.discount_rate !== undefined ? props.discount_rate : this.edits.discount_rate,
            email_address: props.email_address || this.edits.email_address,
            image: props.image ? props.image : this.edits.image,
            information: props.information || this.edits.information,
            location: props.location || this.edits.location,
            key: props.key || this.edits.key,
            name: props.name || this.edits.name,
            payment_methods: this.edits.payment_methods,
            referral_code: props.referral_code || this.edits.referral_code,
            total_admins: props.total_admins !== null && props.total_admins !== undefined ? props.total_admins : this.edits.total_admins,
            total_users: props.total_users !== null && props.total_users !== undefined ? props.total_users : this.edits.total_users
        }

        if(props.image && props.image.data) {
            this.edits.tmp_image = props.image;
        }

        // automatically apply internal changes when payment methods change
        if(props.payment_methods) {
            this.payment_methods = props.payment_methods;
            this.edits.payment_methods = props.payment_methods;
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id, image } = await Request.post(utils, '/companies/', {
                    address: edits.address,
                    discount_rate: edits.discount_rate,
                    email_address: edits.email_address,
                    image: edits.tmp_image,
                    key: edits.key,
                    location: edits.location,
                    information: edits.information,
                    name: edits.name,
                    referral_code: edits.referral_code,
                    type: 'new'
                });

                // update local values with selected edits
                this.address = edits.address;
                this.discount_rate = edits.discount_rate;
                this.email_address = edits.email_address;
                this.id = id;
                this.image = image;
                this.information = edits.information;
                this.key = edits.key;
                this.location = edits.location;
                this.name = edits.name;
                this.referral_code = edits.referral_code;

                // notify subscribers of company addition
                utils.content.fetch('companies');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { direct_payments, image } = await Request.post(utils, '/companies/', {
                    address: edits.address,
                    direct_payments: edits.direct_payments && {
                        account: edits.direct_payments.account,
                        discounts: edits.direct_payments.discounts,
                        user_id: edits.direct_payments.user && edits.direct_payments.user.user_id
                    },
                    discount_rate: edits.discount_rate,
                    email_address: edits.email_address,
                    id: this.id,
                    image: edits.tmp_image,
                    key: edits.key,
                    location: edits.location,
                    information: edits.information,
                    name: edits.name,
                    referral_code: edits.referral_code,
                    type: 'update',
                });

                // update local values with selected edits
                this.address = edits.address;
                this.direct_payments = direct_payments;
                this.discount_rate = edits.discount_rate;
                this.email_address = edits.email_address;
                this.image = image || this.image;
                this.information = edits.information;
                this.key = edits.key;
                this.location = edits.location;
                this.name = edits.name;
                this.referral_code = edits.referral_code;

                // notify subscribers of company data change
                utils.content.update({
                    type: 'companies',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const fetchCompany = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { company } = await Request.get(utils, '/companies/', {
                type: 'details',
                id: id
            });
            let result = new CompanyClass().create(company);
            resolve(result);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new CompanyClass(),
    get: (utils, id) => fetchCompany(utils, id),
    create: props => new CompanyClass().create(props)
};
