import moment from 'moment-timezone';
import Appearance from 'styles/Appearance.js';
import Company from 'classes/Company';
import EmailHistoryEvent from 'classes/EmailHistoryEvent.js';
import HistoryEvent from 'classes/HistoryEvent.js';
import Message from 'classes/Message';
import Mood from 'classes/Mood';
import PaymentMethod from 'classes/PaymentMethod.js';
import PromoCode from 'classes/PromoCode';
import Request from 'files/Request.js';
import Service from 'classes/Service';
import Subscription from 'classes/Subscription.js';
import User from 'classes/User';
import Utils from 'files/Utils';
import Vehicle from 'classes/Vehicle';

class ReservationClass {

    _private = {};

    assigned_to = null;
    booking_channel = null;
    date_submitted = null;
    company = null;
    cost = null;
    credits = null;
    customer = null;
    data = null;
    destination = null;
    distance = {};
    driver_id = null;
    driver_tip = null;
    duration = { };
    emissions = null;
    flight_data = null;
    id = null;
    is_terminal_origin = false;
    location = null;
    luggage = null;
    mood = null;
    multi_leg_polyline = null;
    origin = null;
    passengers = null;
    payment_method = null;
    pickup_date = null;
    polyline = null;
    promo_code = null;
    referral_code = null;
    service = null;
    special_requests = {};
    status = null;
    stops = null;
    subscription = null;
    unpaid = 0;
	user_id = null;
    vehicle = null;

    constructor() {
        return this;
    }

    create = (props = {}) => {

        this.assigned_to = props.assigned_to;
        this.booking_channel = props.booking_channel;
        this.company = props.company && Company.create(props.company);
        this.cost = props.cost;
        this.credits = props.credits;
        this.customer = props.customer && User.create(props.customer);
        this.data = props.data;
        this.date_submitted = moment.utc(props.date_submitted).local();
        this.destination = {
            name: props.destination.name,
            address: props.destination.address,
            location: props.destination.lat && props.destination.long && {
                latitude: props.destination.lat,
                longitude: props.destination.long
            }
        };
        this.distance = props.distance;
        this.driver_id = props.driver_id;
        this.driver_tip = props.driver_tip;
        this.duration = props.duration;
        this.emissions = props.emissions;
        this.flight_data = props.flight_data;
        this.id = props.id;
        this.is_terminal_origin = props.is_terminal_origin;
        this.location = props.location;
        this.luggage = props.luggage;
        this.mood = props.mood && Mood.create(props.mood);
        this.multi_leg_polyline = props.multi_leg_polyline && props.multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
        this.origin = {
            name: props.origin.name,
            address: props.origin.address,
            location: {
                latitude: props.origin.lat,
                longitude: props.origin.long
            }
        };
        this.passengers = props.passengers;
        this.pickup_date = moment.utc(props.pickup_date).local();
        this.polyline = props.polyline && Utils.decodePolyline(props.polyline, 6);
        this.promo_code = props.promo_code && PromoCode.create(props.promo_code);
        this.referral_code = props.referral_code;
        this.service = props.service && Service.create(props.service);
        this.special_requests = props.special_requests;
        this.status = props.status && formatStatus(props.status.code);
        this.stops = props.stops && {
            ...props.stops,
            locations: props.stops.locations ? props.stops.locations.map(stop => {
                return {
                    id: stop.id,
                    name: stop.name,
                    address: stop.address,
                    completed: stop.completed,
                    location: {
                        latitude: stop.lat,
                        longitude: stop.long
                    }
                }
            }) : []
        };
        this.subscription = props.subscription && Subscription.create(props.subscription);
        this.unpaid = props.unpaid;
        this.vehicle = props.vehicle && Vehicle.Category.create(props.vehicle);

        return this;
    }

    apply = (utils, isNewTarget) => {
        return isNewTarget ? this.submit(utils) : this.update(utils);
    }

    open = () => {
        this.edits = {
            company: this.company,
            credits: this.credits,
            customer: this.customer,
            destination: this.destination,
            distance: this.distance,
            duration: this.duration,
            id: this.id,
            luggage: this.luggage || 0,
            mood: this.mood,
            origin: this.origin,
            passengers: this.passengers || 1,
            payment_method: this.payment_method,
            pickup_date: this.pickup_date,
            promo_code: this.promo_code,
            referral_code: this.referral_code,
            route: this.route,
            service: this.service,
            special_requests: this.special_requests,
            status: this.status,
            stops: this.stops,
            subscription: this.subscription,
            user_id: this.user_id,
            vehicle: this.vehicle
        }
        return this.edits;
    }

    set = (props = {}) => {

        // credits and payment method need to be removed if the customer is changed
        if(props.customer) {
            if(this.edits && this.edits.customer.user_id !== props.customer.user_id) {
                props.credits = null;
                props.payment_method = null;
                this.edits.special_requests.card_id = null;
            }
        }

        // set standard variables
        // undefined check for objects that can be completely removed
        this.edits = {
            id: this.id,
            company: props.company !== undefined ? props.company : this.edits.company,
            cost: props.cost || this.edits.cost,
            credits: props.credits !== undefined ? props.credits : this.edits.credits,
            customer: props.customer || this.edits.customer,
            destination: props.destination || this.edits.destination,
            distance: props.distance || this.edits.distance,
            duration: props.duration || this.edits.duration,
            luggage: props.luggage !== undefined ? props.luggage : this.edits.luggage,
            mood: props.mood !== undefined ? props.mood : this.edits.mood,
            origin: props.origin || this.edits.origin,
            payment_method: props.payment_method !== undefined ? props.payment_method : this.edits.payment_method,
            passengers: props.passengers || this.edits.passengers,
            pickup_date: props.pickup_date || this.edits.pickup_date,
            promo_code: props.promo_code !== undefined ? props.promo_code : this.edits.promo_code,
            referral_code: props.referral_code !== undefined ? props.referral_code : this.edits.referral_code,
            service: props.service || this.edits.service,
            special_requests: props.special_requests || this.edits.special_requests || {},
            status: props.status || this.edits.status,
            stops: (props.stops || this.edits.stops) && {
                ...this.edits.stops,
                ...props.stops && {
                    ...props.stops,
                    locations: props.stops.locations.map(stop => ({
                        ...stop,
                        location: stop.location || {
                            latitude: stop.lat,
                            longitude: stop.long
                        }
                    }))
                }
            },
            subscription: props.subscription || this.edits.subscription,
            vehicle: props.vehicle || this.edits.vehicle
        }

        // attach customer company if applicable
        if(props.customer && props.customer.company) {
            this.edits.company = props.customer.company;
        }

        // set new payment method
        if(props.payment_method) {
            this.edits.payment_method = props.payment_method;
            this.edits.special_requests.card_id = props.payment_method.id;
        }

        // set new flight
        if(props.flight) {
            this.edits.special_requests.flight = props.flight;
        }

        // set new driver message
        if(props.requests) {
            this.edits.special_requests.message = props.requests;
        }
        return this.edits;
    }

    submit = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { id, multi_leg_polyline, polyline } = await Request.post(utils, '/reservation/', {
                    company: edits.company && edits.company.id,
                    credits: edits.credits,
                    destination: {
                        address: edits.destination.address,
                        lat: edits.destination.location.latitude,
                        long: edits.destination.location.longitude,
                        name: edits.destination.name
                    },
                    distance_estimate: edits.distance.estimate,
                    driver_tip: edits.driver_tip,
                    duration_estimate: edits.duration.estimate,
                    luggage: edits.luggage,origin: {
                        address: edits.origin.address,
                        lat: edits.origin.location.latitude,
                        long: edits.origin.location.longitude,
                        name: edits.origin.name
                    },
                    other_users: edits.other_users && edits.other_users.map(user => user.user_id),
                    passengers: edits.passengers,
                    pickup_date: moment(edits.pickup_date).utc().unix(),
                    promo_code: edits.promo_code && edits.promo_code.id,
                    referral_code: edits.referral_code,
                    service: edits.service.id,
                    special_requests: edits.special_requests,
                    stops: edits.stops && edits.stops.locations && {
                        ...edits.stops,
                        locations: edits.stops.locations.map(stop => ({
                            id: stop.id,
                            name: stop.name,
                            address: stop.address,
                            lat: stop.location.latitude,
                            long: stop.location.longitude
                        }))
                    },
                    subscription: edits.subscription && edits.subscription.id,
                    type: 'new',
                    user_id: edits.customer.user_id,
                    vehicle: edits.vehicle.id
                });

                this.id = id;
                this.customer = edits.customer;
                this.company = edits.company;
                this.vehicle = edits.vehicle;
                this.service = edits.service;
                this.pickup_date = moment(edits.pickup_date);
                this.distance = edits.distance;
                this.duration = edits.duration;
                this.origin = edits.origin;
                this.destination = edits.destination;
                this.stops = edits.stops;
                this.passengers = edits.passengers;
                this.luggage = edits.luggage;
                this.mood = edits.mood;
                this.driver_tip = edits.driver_tip;
                this.credits = edits.credits;
                this.subscription = edits.subscription;
                this.special_requests = edits.special_requests;
                this.other_users = edits.other_users;
                this.music_choice = edits.music_choice
                this.promo_code = edits.promo_code;
                this.referral_code = edits.referral_code;
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));

                utils.content.fetch('reservations');
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    update = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits;
                let { multi_leg_polyline, polyline } = await Request.post(utils, '/reservation/', {
                    company: edits.company && edits.company.id,
                    credits: edits.credits,
                    destination: {
                        address: edits.destination.address,
                        lat: edits.destination.location.latitude,
                        long: edits.destination.location.longitude,
                        name: edits.destination.name
                    },
                    distance_estimate: edits.distance.estimate,
                    driver_tip: edits.driver_tip,
                    duration_estimate: edits.duration.estimate,
                    luggage: edits.luggage,
                    mood: edits.mood && edits.mood.id,
                    origin: {
                        address: edits.origin.address,
                        lat: edits.origin.location.latitude,
                        long: edits.origin.location.longitude,
                        name: edits.origin.name
                    },
                    other_users: edits.other_users && edits.other_users.map(user => user.user_id),
                    passengers: edits.passengers,
                    pickup_date: moment(edits.pickup_date).utc().unix(),
                    promo_code: edits.promo_code && edits.promo_code.id,
                    referral_code: edits.referral_code,
                    reservation_id: this.id,
                    service: edits.service.id,
                    special_requests: edits.special_requests,
                    stops: edits.stops && edits.stops.locations && {
                        ...edits.stops,
                        locations: edits.stops.locations.map(stop => ({
                            address: stop.address,
                            id: stop.id,
                            lat: stop.location.latitude,
                            long: stop.location.longitude,
                            name: stop.name
                        }))
                    },
                    subscription: edits.subscription && edits.subscription.id,
                    type: 'update',
                    user_id: edits.customer.user_id,
                    vehicle: edits.vehicle.id
                });

                this.company = edits.company;
                this.credits = edits.credits;
                this.customer = edits.customer;
                this.destination = edits.destination;
                this.distance = edits.distance;
                this.driver_tip = edits.driver_tip;
                this.duration = edits.duration;
                this.luggage = edits.luggage;
                this.mood = edits.mood;
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));
                this.music_choice = edits.music_choice
                this.origin = edits.origin;
                this.other_users = edits.other_users;
                this.passengers = edits.passengers;
                this.pickup_date = edits.pickup_date;
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.promo_code = edits.promo_code;
                this.referral_code = edits.referral_code;
                this.service = edits.service;
                this.special_requests = edits.special_requests;
                this.stops = edits.stops;
                this.subscription = edits.subscription;
                this.vehicle = edits.vehicle;

                utils.content.update({
                    type: 'reservations',
                    object: this
                });
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }

    isAppClipRide = () => {
        return this.booking_channel === 'app_clips';
    }

    getCostBreakdown = async (utils, props) => {
        return new Promise(async (resolve, reject) => {
            try {
                let edits = this.edits || this;
                let { calculations, estimate_line_items, invoice_line_items, multi_leg_polyline, polyline, route } = await Request.post(utils, '/reservation/', {
                    company: edits.company && edits.company.id,
                    credits: edits.credits,
                    destination: edits.destination && edits.destination.location && {
                        lat: edits.destination.location.latitude,
                        long: edits.destination.location.longitude
                    },
                    driver_tip: edits.driver_tip && edits.driver_tip.amount,
                    origin: edits.origin && edits.origin.location && {
                        lat: edits.origin.location.latitude,
                        long: edits.origin.location.longitude
                    },
                    promo_code: edits.promo_code && edits.promo_code.id,
                    referral_code: edits.referral_code,
                    reservation_id: this.id,
                    service: edits.service && edits.service.id,
                    stops: edits.stops && edits.stops.locations && {
                        ...edits.stops,
                        locations: edits.stops.locations.map(stop => ({
                            lat: stop.location.latitude,
                            long: stop.location.longitude
                        }))
                    },
                    subscription: edits.subscription && edits.subscription.id,
                    type: 'cost',
                    vehicle: edits.vehicle && edits.vehicle.id,
                    ...props
                });

                if(!calculations || !calculations.transaction_data) {
                    throw new Error('An unknown error occurred');
                }

                // update route estimate if applicable
                if(route) {
                    this.route = route;
                    edits.distance.estimate = route.distance;
                    edits.duration.estimate = route.duration;
                }

                // update polylines if applicable
                this.polyline = polyline && Utils.decodePolyline(polyline, 6);
                this.multi_leg_polyline = multi_leg_polyline && multi_leg_polyline.map(shape => Utils.decodePolyline(shape, 6));

                resolve({
                    estimate_line_items, 
                    invoice_line_items,
                    breakdown: {
                        companyDiscount: calculations.transaction_data.company_discount || 0,
                        credits: calculations.transaction_data.credits || 0,
                        driver_tip: calculations.transaction_data.driver_tip || 0,
                        lineItems: calculations.transaction_data.line_items || [],
                        physicalCost: calculations.cost,
                        promo_code: calculations.transaction_data.promo_code || 0,
                        service: calculations.cost,
                        total: calculations.cost
                    }
                })

            } catch(e) {
                reject(e);
            }
        })
    }

    getCurrentPaymentMethod = () => {
        if(this.customer.payment_methods) {
            let cardID = this.special_requests ? this.special_requests.card_id : null;
            for(var i in this.customer.payment_methods) {
                if((cardID && this.customer.payment_methods[i].id === cardID) || (!cardID && this.customer.payment_methods[i].default)) {
                    return this.customer.payment_methods[i];
                }
            }
         }
        return null;
    }

    getEmailHistory = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                 let { history } = await Request.get(utils, '/reservation/', {
                     type: 'email_history',
                     reservation_id: this.id
                 });
                 resolve({ history: history.map(evt => EmailHistoryEvent.create(evt)) });
            } catch(e) {
                reject(e);
            }
        });
    }

    getHistoryEvents = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                let { events } = await Request.get(utils, '/reservation/', {
                    reservation_id: this.id,
                    type: 'history_log'
                });
                resolve({
                    events: events.map(e => {
                        return HistoryEvent.create({ ...e, status: formatStatus(e.status) })
                    })
                });
            } catch(e) {
                reject(e);
            }
        });
    }

    getLocations = () => {

        // declare target where variables are pulled from
        let reservation = this.edits || this;

        // start locations array with origin entry
        let locations = [{
            ...reservation.origin,
            id: 'origin',
            title: 'Pickup Location',
            subTitle: reservation.origin.address,
            icon: { type: 'broadcast' }
        }];

        // loop through stops and add to locations if applicable
        if(reservation.stops && reservation.stops.locations && reservation.stops.locations.length > 0) {
            reservation.stops.locations.forEach((stop, index) => {
                locations.push({
                    ...stop,
                    title: `Stop #${index + 1}`,
                    subTitle: stop.address,
                    icon: { 
                        color: Appearance.colors.grey(),
                        type: 'broadcast'  
                    },
                })
            });
        }

        // add destination to list of locations
        locations.push({
            ...reservation.destination,
            id: 'destination',
            title: 'Drop-Off Location',
            subTitle: reservation.destination.address,
            icon: { type: 'broadcast' }
        });

        // add driver location to list of locations if applicable
        if(reservation.location) {
            let { driver, location } = reservation.location;
            locations.push({
                id: 'driver',
                location: {
                    heading: location.heading || -1,
                    latitude: location.lat || location.latitude,
                    longitude: location.long || location.longitude,
                    speed: location.speed
                },
                subTitle: location.time_to_arrival ? `Arriving in ${Utils.parseDuration(location.time_to_arrival)}` : 'Waiting on estimated arrival...',
                title: driver.full_name
            });
        }
        return locations;
    }

    getMessages = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                 let { messages } = await Request.get(utils, '/messages/', {
                     type: 'orders_all',
                     reservation_id: this.id
                 });
                 this.messages = messages.map(message => Message.create(message));
                 resolve(this.messages);
            } catch(e) {
                reject(e);
            }
        });
    }

    getOverlays = () => {
        try {
            if(this.polyline && this.polyline.length > 0) {
                return [{
                    id: 'reservation-route',
                    coordinates: this.polyline
                }];
            }
            if(this.multi_leg_polyline) {
                return this.multi_leg_polyline.map((shape, index) => ({
                    id: `reservation-route-${index}`,
                    coordinates: shape
                }))
            }
            return null;

        } catch(e) {
            console.error(e.message);
        }
    }

    getPaymentMethodForPreauthorization = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!this.customer.stripe_customer_id || !this.hasPreauthorization()) {
                    resolve(null);
                    return;
                }

                let { method } = await Request.get(utils, '/payment/', {
                    type: 'payment_method_for_preauthorization',
                    customer_id: this.customer.user_id,
                    stripe_id: this.customer.stripe_customer_id,
                    preauth_id: this.data.preauthorization.id,
                    reservation_id: this.id
                });

                let target = PaymentMethod.create(method);
                resolve(target);

            } catch(e) {
                reject(e);
            }
        })
    }

    hasPreauthorization = () => {
        return this.data && this.data.preauthorization && !this.data.preauthorization.revoked ? true : false;
    }

    translatePaymentMethod = utils => {
        return new Promise(async (resolve ,reject) => {
            try {
                // declare reservation target and card_id
                let reservation = this.edits || this;
                let cardID = reservation.special_requests ? reservation.special_requests.card_id : null;

                // fetch methods and return matching card
                // fallback to default payment method if applicable
                let { methods } = await reservation.customer.getPaymentMethods(utils);;
                resolve(cardID ? methods.find(method => method.id === cardID) : methods.find(method => method.default));

            } catch(e) {
                reject(e);
            }
        })
    }

    revokePreauthorization = utils => {
        return new Promise(async (resolve, reject) => {
            try {
                await Request.post(utils, '/payment/', {
                    type: 'revoke_preauthorization',
                    preauth_id: this.data.preauthorization.id,
                    reservation_id: this.id
                });
                delete this.data.preauthorization;
                resolve();
            } catch(e) {
                reject(e);
            }
        })
    }

    setPreauthorization = async utils => {
        return new Promise(async (resolve, reject) => {
            try {
                if(!this._private.preauthorization || !this._private.preauthorization.amount || (this.hasPreauthorization() && this.data.preauthorization.amount === this._private.preauthorization.amount)) {
                    throw new Error('It looks like the preauthorization amount has not been set or is the same amount that it was previously');
                }
                if(!this._private.preauthorization.cardID) {
                    throw new Error('A payment method needs to be selected to create or update a preauthorization');
                }

                let response = await Request.post(utils, '/payment/', {
                    type: 'set_preauthorization',
                    amount: this._private.preauthorization.amount,
                    card_id: this._private.preauthorization.cardID,
                    customer_id: this.customer.user_id,
                    reservation_id: this.id,
                    preauth_id: this.hasPreauthorization() ? this.data.preauthorization.id : null
                });

                this._private.preauthorization = undefined;
                this.data = {
                    ...this.data,
                    preauthorization: response
                }
                resolve();

            } catch(e) {
                reject(e);
            }
        })
    }
}

const statusCodes = {
    pending: null,
    rejected: 0,
    approved: 1,
    returned: 2,
    cancelled: 3,
    to_pickup: 4,
    to_destination: 5,
    completed: 6,
    unpaid: 7,
    preauthorized: 8,
    preauthorization_failed: 9,
    preauthorization_revoked: 10,
    admin_updated: 11,
    driver_updated: 12,
    customer_edited: 13,
    charge_processing: 14,
    charge_posted: 15,
    charge_failed: 16,
    charge_issue: 17
}

const formatStatus = (c) => {

    let code = c === null || c === undefined ? null : parseInt(c);
    let status = {
        code: code
    };

    if(code === statusCodes.rejected) {
        status.text = 'Declined';
        status.realText = 'Rejected';
        status.color = Appearance ? Appearance.colors.red : null;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.approved) {
        status.text = 'Approved';
        status.realText = 'Approved';
        status.color = Appearance ? Appearance.colors.primary() : null;
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.returned) {
        status.text = 'Returned to Queue';
        status.realText = 'Returned to Queue';
        status.color = Appearance ? Appearance.colors.primary() : null;
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.cancelled) {
        status.text = 'Cancelled';
        status.realText = 'Cancelled';
        status.color = '#812222';
        status.image = 'images/status-cancelled.png';

    } else if(code === statusCodes.to_pickup || code === statusCodes.to_destination) {
        status.text = 'In Progress';
        status.realText = code === statusCodes.to_pickup ? 'In Route To Pickup' : 'In Route To Destination';
        status.color = Appearance ? Appearance.colors.blue : null;
        status.image = 'images/status-active.png';

    } else if(code === statusCodes.completed) {
        status.text = 'Completed';
        status.realText = 'Completed';
        status.color = Appearance ? Appearance.colors.darkGrey : null;
        status.image = 'images/status-completed.png';

    } else if(code === statusCodes.unpaid) {
        status.text = 'Unpaid';
        status.realText = 'Unpaid';
        status.color = Appearance ? Appearance.colors.lightGrey : null;
        status.image = 'images/status-completed.png';

    } else if(code === statusCodes.preauthorized) {
        status.text = 'Preauthorized';
        status.realText = 'Preauthorized';
        status.color = Appearance ? Appearance.colors.lightGrey : null;
        status.image = 'images/status-approved.png';

    } else if(code === statusCodes.preauthorization_failed) {
        status.text = 'Preauthorization Failed';
        status.realText = 'Preauthorization Failed';
        status.color = Appearance ? Appearance.colors.red : null;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.preauthorization_revoked) {
        status.text = 'Preauthorization Released';
        status.realText = 'Preauthorization Released';
        status.color = Appearance ? Appearance.colors.red : null;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.admin_updated) {
        status.text = 'Admin Updated';
        status.realText = 'Admin Updated';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.driver_updated) {
        status.text = 'Driver Updated';
        status.realText = 'Driver Updated';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.customer_edited) {
        status.text = 'Customer Edited';
        status.realText = 'Customer Edited';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.charge_processing) {
        status.text = 'Charge Processing';
        status.realText = 'Charge Processing';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';

    } else if(code === statusCodes.charge_posted) {
        status.text = 'Charge Posted';
        status.realText = 'Charge Posted';
        status.color = Appearance ? Appearance.colors.darkGrey : null;
        status.image = 'images/status-completed.png';

    } else if(code === statusCodes.charge_failed) {
        status.text = 'Charge Failed';
        status.realText = 'Charge Failed';
        status.color = Appearance ? Appearance.colors.red : null;
        status.image = 'images/status-rejected.png';

    } else if(code === statusCodes.charge_issue) {
        status.text = 'Charge Issue';
        status.realText = 'Charge Issue';
        status.color = Appearance ? Appearance.colors.orange : null;
        status.image = 'images/status-rejected.png';

    } else {
        status.text = 'Pending';
        status.realText = 'Pending';
        status.color = '#BEBEBE';
        status.image = 'images/status-pending.png';
    }

    return status;
}

export const fetchReservation = async (utils, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            let { reservation } = await Request.get(utils, '/reservation/', {
                type: 'details',
                id: id
            });
            let target = new ReservationClass().create(reservation);
            resolve(target);

        } catch(e) {
            reject(e);
        }
    })
}

export default {
    new: () => new ReservationClass(),
    create: props => new ReservationClass().create(props),
    get: fetchReservation,
    status: statusCodes,
    formatStatus: formatStatus
}
